import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { VueConstructor } from 'vue/types/umd'
import { mapGetters, mapMutations } from 'vuex'
import {
  TOrgContainerItemAddMutationPayload,
  TOrgContainerItemDelMutationPayload,
  TWishContainerSelectAllPayload,
  TWishContainerSelectPayload,
  TOrgContainerActivateMutationPayload
} from '../store/mutations'
import { WishList } from '../components'
import * as types from '../store/types'
import { i18n } from '@appbase/util/i18n-setup'

@Component({
  components: { WishList },
  methods: {
    ...mapMutations({
      registerContainer: types.ORG_CONTAINER_REGISTER,
      addToContainer: types.ORG_CONTAINER_ITEM_ADD,
      deleteFromContainer: types.ORG_CONTAINER_ITEM_DEL,
      select: types.ORG_CONTAINER_ITEM_SELECT,
      selectAll: types.ORG_CONTAINER_ITEM_SELECT_ALL,
      activateContainer: types.ORG_CONTAINER_ACTIVATE,
    }),
  },
  computed: {
    ...mapGetters({
      wishContainers: types.ORG_CONTAINER_REGISTER,
    }),
  },
})
export default class WishListContainer extends Vue {

  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: Object }) item!: orgmap.TWishContainerOptionItem

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: M U T A T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  registerContainer!: (item: orgmap.TWishContainerOptionItem) => void
  select!: (payload: TWishContainerSelectPayload) => void
  selectAll!: (payload: TWishContainerSelectAllPayload) => void
  addToContainer!: (payload: TOrgContainerItemAddMutationPayload) => void
  deleteFromContainer!: (payload: TOrgContainerItemDelMutationPayload) => void
  activateContainer!: (payload: TOrgContainerActivateMutationPayload) => void

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  wishContainers!: orgmap.TWishContainer[]

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //

  get container() {
    const { id } = this.item
    return this.wishContainers.find((c) => c.id === id)
  }

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //


  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  created() {
    this.registerContainer(this.item)
    
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  handleAdd() {
    console.log(`${this.item.name}` + i18n.t('컨테이너에_추가').toString());
    this.addToContainer({ containerId: this.item.id })
  }

  handleDeleteOne(item: orgmap.TWishContainerItem) {
    this.handleSelectAll(false);
    this.handleSelect(item);
    this.deleteFromContainer({ containerId: this.item.id })
  }

  /**
   * @param item? Wish List Item 우측에 있는 X 버튼 클릭 시 전달되는 아이템 객체
   */
  handleDelete(item?: orgmap.TWishContainerItem) {
    console.log(`${this.item.name} 컨테이너에 제거`)
    this.deleteFromContainer({ containerId: this.item.id })
  }

  handleSelect(item: orgmap.TWishContainerItem) {
    this.select({
      id: this.item.id,
      item,
    })
  }

  handleSelectAll(select: boolean) {
    this.selectAll({
      id: this.item.id,
      select: select,
    })
  }

  handleActivate(){
    this.activateContainer({ containerId: this.item.id });
  }

}
