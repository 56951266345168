import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import WebpartBase from '@webparts/views/WebpartBase'
import { NoData } from '@webparts/components/common'
import api from '@webparts/api'
import * as dateHelper from '@appbase/util/date-helper'
import {open, OpenType} from '@webparts/util/open-handler'
import menuHelper from '@appbase/util/menu-helper'
import modalHelper from '@appbase/util/modal-helper';
import ZinusBoardModal from "@webparts/views/Zinus/Modal/ZinusBoardModal.vue"

 

///보드 관련된 공통
///다른 컴포넌트에서 mixin 으로 추가해서 사용
/// 이미 webpartBase 가 있어서 상속받아서는 사용하지 못함 vue 가 상속안되었기 때문에
/// NOTICE / COMPANY NOTICE

export interface ArticleItem {
    isNew: boolean
    articleId: number
    subject: string
    createdDate: string

}

export interface TabData {
    tabNo: number
    boardId: number
    tabName: string
    articles: Array<ArticleItem>
    isShow  : boolean
    isNew: boolean
}

export interface ComNotiData{
    boardId: number,
    boardName: string,
    articleId: number,
    subject: string,
    createdDate: Date,
    displayDate: string,
    isNew: boolean
}

@Component({
    components: {
        NoData
    }
})
export default class ZinusBoardBase extends WebpartBase {
    values: Array<TabData> = []
    comNotiData: Array<ComNotiData> = []
    articleCount: number = 6 //default 5
    selectedTabNo: number = 0
    isLoading : boolean = false

 initSkeleton(isCompanyNotice : boolean){
    this.isLoading = true
     //법인 게시판 정보 조회
        // skeleton data
        if(isCompanyNotice==true){
            this.values = [{
                tabNo: 0,
                boardId: 0,
                tabName: "ㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁ",
                articles: [
                ],
                isShow: true,
                isNew: false
            }]
        } 
        

        for (let temp of [1, 2, 3, 4]) {
            this.values[0].articles.push({
                isNew: false,
                articleId: 0,
                subject: "ㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁ",
                createdDate: "ㅁㅁㅁㅁ-ㅁㅁ-ㅁㅁ"
            })
        }
 }


    onClickItem(boarId: number, articleId: number, title:string) {
        //alert(`board id : ${boarId}\narticle id : ${articleId}\ntype : ${this.isTeams ? 'modaltype' : 'popuptype'}`)

        if(this.isTeams){
            modalHelper.show({ component: ZinusBoardModal, force: true, value: { boardId: boarId, articleId: articleId, title:title } });
        }else{
            open(OpenType.PopupRead,
                {
                    boardId: boarId,
                    articleId: articleId,
                    baseUrl: (process.env.NODE_ENV === 'production' ? '/po':'' ) + ("/BoardPopup/")
                });
        }

    
    }

    goBoardList(boardId: number) {
        //메뉴아이디도 찾아서 넣어줘야될걸?
        //alert(`${boardId} 게시판으로 이동합니다`)
        let menuId =  menuHelper.findOneBySysId(boardId, 'BB')
        this.$router.push({
            name : "boardmodule-listview",
            query : {"MENUID" : menuId?.menuId.toString(), "SYSID": boardId.toString() }
        })
    }

    initArticleData() {
        
        // boardId: number,
        // displayCount: number,
        // pageNumber: number,
        // includeContent: boolean,
        // useNotice: boolean
        // 필요개수 가져오기 articleCount 기본값 5
        let requests = []

        for (var i of this.values) {
            requests.push(api.webPart.getArticles(i.boardId, this.articleCount, 1, false, true))
        }




        Promise.all(requests).then(result => {
            let idxNo = 0
            for (var i of result) {
                this.values[idxNo].articles = (i as any).data.map((item :any)=>{
                    return{
                        isNew : this.isNewArticle(item.createdDate.toString()),
                        articleId : item.articleId,
                        subject : item.title,
                        createdDate : dateHelper.uGetDateFormat("yyyy-MM-DD",new Date(item.createdDate.toString()))
                    }
                })

                if (this.values[idxNo].articles.find(item => item.isNew == true)){
                    this.values[idxNo].isNew = true;
                }

                idxNo++
            }
        }).finally(()=>{
            this.isLoading = false
        })


        //데이터 가져오기
        // 해당 법인 찾아서 법인 뉴스게시판 boardId 가져오기
        // then(reulst=>{
        // api 콜해서 boarditem 초기화

        // })
    }

    isNewArticle(baseDate: Date) {
        var oneDay = 1000 * 60 * 60 * 24;
        var createdDate = new Date(baseDate.toString());
        var today = new Date();

        var timeSpan = today.getTime() - createdDate.getTime();


        if (timeSpan <= oneDay) {
            return true;
        } else {
            return false;
        }
    }

    getComNotiWebpartData(){
        this.comNotiData = [];
        api.webPart.getComNoticeWebpartList(this.articleCount).then(res => {
           this.comNotiData = (res as Array<ComNotiData>);   

            this.comNotiData.forEach(item => {
               item.displayDate =  dateHelper.uGetDateFormat("yyyy-MM-DD",new Date(item.createdDate.toString())),
               item.isNew = this.isNewArticle(item.createdDate)
            });
        }).finally(()=>{
            this.isLoading = false
        });
    }
}
