import { RouteConfig } from 'vue-router';

const Survey = () =>
	import(/* webpackChunkName: "survey" */ '@survey/Index.vue');
const SurveyMaster = () => import('@survey/views/Master.vue');
const SurveyPopupMaster = () => import('@/modules/survey/views/PopupViews/SurveyPopupMaster.vue');
const SurveyList = () => import('@survey/views/SurveyListView.vue');
const SurveyWrite01 = () => import('@survey/views/SurveyWriteS1View.vue');
const SurveyWrite02 = () => import('@survey/views/SurveyWriteS2View.vue');
const SurveyRead = () => import('@survey/views/SurveyReadView.vue');
const SurveyStatistics = () => import('@survey/views/SurveyStatisticsView.vue');


const routes: RouteConfig[] = [
	{
		path: '/survey',
		component: Survey,
		children: [
			{
				path: '',
				name: "SurveyMaster",
				component: SurveyMaster,
				children: [
					{
						path: '',
						redirect: 'List'
					},
					{
						path: 'List',
						name: "SurveyList",
						component: SurveyList,
						children: []
					},
					{
						path: 'WriteStep-01',
						name: "SurveyWriteStep01",
						component: SurveyWrite01,
						children: []
					},
					{
						path: 'WriteStep-02',
						name: "SurveyWriteStep02",
						component: SurveyWrite02,
						children: []
					},
					{
						path: 'Read',
						name: "SurveyRead",
						component: SurveyRead,
						props: (data)=>{
							return {
								isPreview : data.params.isPreview == 'true' ? true : false
							}
						} ,
						children: []
					},
					{
						path: 'Statistics',
						name: "SurveyStatistics",
						component: SurveyStatistics,
						props: true,
						children: []
					}
				]
			},
			{
				path: 'Popup',
				name: "SurveyPopupMaster",
				component: SurveyPopupMaster,
				children: [
					{
						path: 'Read',
						name: "SurveyPopupRead",
						component: SurveyRead,
						children: []
					},
					{
						path: 'WriteStep-01',
						name: "SurveyPopupWriteStep01",
						component: SurveyWrite01,
						children: []
					},
					{
						path: 'WriteStep-02',
						name: "SurveyPopupWriteStep02",
						component: SurveyWrite02,
						children: []
					},
					{
						path: 'Statistics',
						name: "SurveyPopupStatistics",
						component: SurveyStatistics,
						props: true,
						children: []
					}
				]
			}








		]
	}
];
export default routes;

 