/**
 * If you need detailed documentation on TinyMCE, see: TinyMCE Documentation.
 *  => https://www.tiny.cloud/docs/
 * For the TinyMCE Vue Quick Start, see: TinyMCE Documentation - Vue Integration.
 *  => https://www.tiny.cloud/docs/integrations/vue/#tinymcevuejsintegrationquickstartguide
 * For the TinyMCE Vue Technical Reference, see: TinyMCE Documentation - TinyMCE Vue Technical Reference.
 *  => https://www.tiny.cloud/docs/integrations/vue/#tinymcevuejstechnicalreference
 * For our quick demos, check out the TinyMCE Vue Storybook.
 *  => https://tinymce.github.io/tinymce-vue/?path=/story/tinymce-vue--iframe-editor
 */
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Editor from '@tinymce/tinymce-vue'
import tinyWriterOptions from './tiny-writer-options.sample'
import tinyViewerOptions from './tiny-viewer-options.sample'

import * as accountTypes from "@account/store/types"


declare var tinymce: any




@Component({
    components: { Editor },
    computed: {
        ...mapGetters({

            userCurrent: accountTypes.CURRENT
        }),
    },
})
export default class TinyEditor extends Vue implements editor.IEumEditor {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    // 바인딩 데이터
    @Prop({ type: String, default: '' }) value!: string
    @Prop({ type: String, default: 'editor' }) mode!: editor.EditorMode
    // 초기화 옵션
    @Prop() tinyopts: any
    // 초기화 시 표시할 기본 컨텐츠
    @Prop({ type: String, default: '' }) initialValue!: string
    // 비활성 여부
    @Prop({ type: Boolean, default: false }) disabled!: boolean
    // v-model 아웃풋 포멧
    @Prop({ type: String, default: 'html' }) outputFormat!: string

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    content: string = ''
    editorOptions: any = {}

    userCurrent!: account.TCurrent

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Watch('value')
    onValueChanged(newValue: string) {
        this.content = newValue
    }

    @Watch('content')
    emitContent(val:string){
       this.$emit('input', val)
    }
    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    created() {

        ///메뉴 바 제

        const _this = this
        this.content = this.value
        if (this.tinyopts) {
           if(this.tinyopts.content_style) {
             // 지누스 중국/인도 폰트 적용
             this.tinyopts.content_style = `@import url('/cm/gres/faux/fonts/zinus/index.css');` + this.tinyopts.content_style
           }
            var userSetup = this.tinyopts.setup

            this.editorOptions = {
                ...(this.mode === 'view' ? tinyViewerOptions : tinyWriterOptions),
                ...this.tinyopts,
                menubar: false, // 메뉴바 사용 X
                language: this.getLangCode()
            }





            this.editorOptions.setup = (ed: any) => {


                //아래 옵션 때문에 ed.on(click) 는 작동이 안됨
                //해당 옵션이 false 일 때는 작동함
                if (this.mode === 'view') { ed.settings.readonly = true }



                ed.on('init', function (e: any) {
                    // TODO: setContextMenu(ed, _this.editorOptions.language)
                    //읽기 모드의 경우만 보더 제거
                    //읽기 모드의 경우만 링크 클릭 시 바로 작동 함수 적용
                    if (_this.mode === 'view') {
                        var divElem = e.target.editorContainer
                        divElem.classList.add('tox-mce-noborder')

                        /////////////////////////////////////// A 링크 클릭 시 바로 오픈////////////////////////
                        // let els =  e.taget.querySelectorAll("a")
                        // console.log(els)
                        let alinks: Array<HTMLAnchorElement> = ed.getDoc().querySelectorAll('a')
                        alinks.forEach(link => {
                            //href 없는경우 "" (빈값)

                            if (link.href != "") {
                                link.addEventListener("click", () => {
                                    const createEl = window.document.createElement('a')
                                    createEl.href = link.href
                                    createEl.target = "_blank"
                                    createEl.rel = 'noopener';
                                    window.document.body.appendChild(createEl);
                                    createEl.click();
                                    window.document.body.removeChild(createEl);
                                })
                            }
                        })


                    }
                    else {
                        e.target.editorCommands.execCommand("fontName", false, "Arial");
                    }
                })
                ed.on('blur', function (e: any) {
                    _this.emitInput(e, ed)
                })
                // 사용자 지정 setup 핸들러 호출
                if (userSetup) {
                    userSetup(ed)
                }
            }
        }
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    emitInput(e: any, editor: any) {
        var content = editor.getContent()
        this.$emit('input', content)
    }

    /**
     * 에디터에서 컨텐츠를 가져옵니다.
     */
    public getBodyValue(): string {
        // const editor = tinymce.activeEditor
        // return editor.getContent()

        return this.content  // 2022-06-09 v-model 값 바로 전달
    }

    getLangCode() {
        let langCode = this.userCurrent.userConfig.language


        //https://www.tiny.cloud/docs/configure/localization/
        // Chinese (China)	zh_CN
        // Japanese	ja

        // Korean (Korea)	ko_KR
        // en 영어

        switch (langCode as string) {
            case "zh-CN":
            case "zh":
                return "zh_CN"
            case "ko-KR":
            case "ko":
                return "ko_KR"
            case "en-US":
            case "en":
                return "en";
            case "ja-JP":
            case "ja":
                return "ja"
            default:
                return langCode.replace("-", "_")
        }
    }
}
