import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown,Checkbox} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@gnb/store/types'
import * as accountTypes from '@account/store/types'

@Component({
    components:
    {
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        Dropdown,
        Checkbox
    },
    methods:{
        ...mapActions({
        saveSetting: types.SETTINGS_MYSETTING_SAVE,
        })
    },
    computed:{
        ...mapGetters({
            user: accountTypes.USER,

        })
    }
})
export default class Signature extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({ type: Object }) settingInfo!: gnb.TA_MYSETTING;
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    name: string = this.$t('서명').toString();
    path: string | undefined = '#';

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    user!: account.User;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    saveSetting!: (payload: { data: gnb.TA_MYSETTING, isFirst: boolean }) => Promise<boolean>;
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    change(e: Event) {
        var input = e.target || e.currentTarget;
        var files = (input as any).files;
        if (files && files.length) {
        var file = files[0] as any;      
        var reader = new FileReader();
        reader.onload = (e: any) => {
            this.settingInfo.signaturePath = reader.result?.toString();
            this.path = URL.createObjectURL(file);
        }
        reader.readAsDataURL(file);
        }
    }

    save(){
        this.settingInfo.updatedId = this.user.personCode;
        this.settingInfo.updatedDate = new Date();
        this.$emit('save')
    }

    @Watch('settingInfo')
    onChangedSetting(){
        if (this.settingInfo.signaturePath && 
            this.settingInfo.signaturePath != '#') {
              this.path = `/cm/gres/signature/${this.settingInfo.signaturePath}?t=${Date.now()}`;
        }
    }
}