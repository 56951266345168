import { FlowFile } from '@appbase/libs/flowjs'
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import FileListItem from '../FileListItem/FileListItem.vue'
import flowHelper from '../flow-helper'

@Component({
  components: { FileListItem },
})
export default class FileList extends Vue {
  @Prop({ type: Array, required: true }) files!: FlowFile[]
  @Prop({ type: Number }) totalSizeLimit!: number

  get selectedAll() {
    const total = this.files.length
    if (total > 0) {
      return this.files.filter((x) => x.selected).length === total
    } else {
      return false
    }    
  }

  get totalSize() {
    const nTotal = this.files.reduce((sum, x) => sum + x.size, 0)
    return flowHelper.readablizeBytes(nTotal)    
  }

  get totalCount() {
    return this.files.length
  }

  get maxSize() {
    return flowHelper.readablizeBytes(this.totalSizeLimit)
  }

  get totalPercent() {
    const totalSize = this.files.reduce((sum, x) => sum + x.size, 0)
    const uploadedSize = this.files.reduce((sum, x) => {
      if (typeof x.sizeUploaded === 'function') {
        return sum + x.sizeUploaded()
      } else {
        return sum + x.size
      }
    }, 0)
    return Math.floor((uploadedSize * 100) / totalSize)
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  handleSelectAll() {
    const to = !this.selectedAll
    for (var file of this.files) {
      file.selected = to
    }
  }
}
