import Vue, { VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { MemberList, DepartmentInfo } from '@orgmap/components'
import api from '@orgmap/api'
import * as types from '@orgmap/store/types'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { OrgMemberListActionPayload } from '@orgmap/store/actions'
import { OrgMemberSelectMutationPayload } from '@orgmap/store/mutations'
import { DetailCard } from '@orgmap/components'

@Component({
  name: 'MemeberListContainer',
  components: { MemberList, DepartmentInfo, DetailCard },
  computed: mapGetters({
    members: types.ORG_MEMBER_LIST,
    userCurrent: types.ACCOUNT_CURRENT,
    selectTreeItem : types.ORG_SELECT_TREE_ITEM,
    options: types.ORG_OPTION_GET,
  }),
  methods: {
    ...mapActions({
      loadMemberList: types.ORG_MEMBER_LIST,
      searchMember: types.ORG_MEMBER_SEARCH,
    }),
    ...mapMutations({
      select: types.ORG_MEMBER_SELECT,
      selectAll: types.ORG_MEMBER_SELECT_ALL,
      setSelectTreeItem : types.ORG_SELECT_TREE_ITEM
    }),
  },
})
export default class MemeberListContainer extends Vue {
  @Prop({ type: String }) deptCode!: string
  @Prop({ type: String }) deptName!: string
  @Prop({ type: String }) comCode!: string
  @Prop({ type: String }) userlang!: string
  @Prop({ type: String }) companyName!: string

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: A C T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  loadMemberList!: (payload: OrgMemberListActionPayload) => Promise<void>
  searchMember!: (payload: { option: orgmap.SearchOptions; comCode: string }) => Promise<void>
  setSelectTreeItem! : (data:boolean) => void
  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: M U T A T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  select!: (payload: OrgMemberSelectMutationPayload) => void
  selectAll!: (payload: boolean) => void

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  selectTreeItem! : boolean

  members!: orgmap.TMemberList
  options!: orgmap.TWishContainerOptions; 

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  loading: boolean = false
  searching: boolean = false
  keyword: string = ''
  hitMember: orgmap.TMemberItem | null = null
  retiredFlag: string = '1';
  userCurrent!: orgmap.account.TCurrent;
  

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //

  get totalCount() {
    return this.members?.totalCount || 0

 
  }

  get name() {
    return this.searching ? this.$t('W_Orgmap_SearchResults').toString() : this.deptName
  }

  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: W A T C H : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Watch('deptCode')
  onDeptCodeChanged(newValue: string) {
    this.load()
  }

  @Watch('members.items')
  onChangeMemberItems(){
    if (this.searching && this.members && this.members.items.length > 0){
      this.handleSelect(this.members.items[0]);
    }
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  async created() {
    await this.load()
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  async load() {
    try {
      this.loading = true
      await this.loadMemberList({
        comCode: this.comCode,
        deptCode: this.deptCode,
      })

     
    } catch (e) {
      throw e
    }
    this.loading = false
  }

  handleSelectOne(user: orgmap.TMemberItem, isSingle: boolean) {
    this.handleSelectAll(false);
    this.handleSelect(user);
    if (isSingle) {
      var btnApply = document.querySelector('#btnApply');
      if (btnApply) {
        (btnApply as HTMLButtonElement).click();
      }
    } else {
      var btnAdd = document.querySelector('#btnAdd');
      if (btnAdd) {
        (btnAdd as HTMLButtonElement).click();
      }
    }
  }

  handleSelect(user: orgmap.TMemberItem) {
    this.hitMember = user
    this.select({
      items: [user],
      select: !user.selected,
    })
  }

  handleSelectAll(select: boolean) {
    this.selectAll(select)
  }

  async search() {
    if (this.keyword.length) {
      try {
        this.loading = true
        this.searching = true
        var data = {
          option: {
            query: this.keyword,
            includeAllFlag: false,
            retiredFlag: this.retiredFlag == '1' ? false : true,
          } as orgmap.SearchOptions,
          comCode: this.comCode,
        }
      
        await this.searchMember(data)
        this.setSelectTreeItem(false)
      } catch (e) {
        throw e
      }
      this.loading = false
    }
  }

  refresh() {
    this.keyword = ''
    this.searching = false
    this.load()
  }

  @Watch("selectTreeItem")
  onChangeSelectTreeItem(){
    if(this.selectTreeItem==true){
      this.searching = false
    }
  }
}
