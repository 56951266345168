
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'


@Component({
  components: {  },
})
export default class ModalSlotLayout extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: Boolean, default: false }) isModal!: boolean
  @Prop({ type: String, default: '' }) modalStyleStr?: string
  @Prop({ type: String, default: '' }) modalClassStr?: string

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  modalClass(){
    var classStr = "";

    if(this.isModal){
      classStr += 'modal ';
    }
    
    classStr += this.modalClassStr;

    return classStr
  }

  modalStyle(){
    return this.modalStyleStr;
  }

}
