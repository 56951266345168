import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
// components
import { ModalContainer } from '../../containers'

@Component({
  components: { ModalContainer },
})
export default class BaseLayout extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: String }) classNames!: string
}
