import menuHelper from '@/modules/@appbase/util/menu-helper'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ListHeaderRight } from './index'

@Component({
  components: {
    ListHeaderRight,
  },
})
export default class ListHeader extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //
  @Prop({ type: Object }) item!: any
  @Prop({ type: Boolean, default: true }) pagingShow?: boolean
  @Prop({ type: Boolean, default: true }) refreshShow?: boolean
  @Prop({ type: Boolean, default: false }) isPreview?: boolean

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  More() {
    if (this.isPreview) return
    const { boardId } = this.item
    const menu = menuHelper.findOneBySysId(boardId, 'BB')
    if (menu) {
      menuHelper.next(menu)
    }
  }
  Prev() {
    this.$emit('Prev')
  }
  Next() {
    this.$emit('Next')
  }
  Refresh() {
    this.$emit('Refresh')
  }
}
