import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import TreeListItem from './TreeListItem.vue';

@Component({
    components: { TreeListItem },
})
export default class TreeList extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({ type: Array }) items!: orgmap.TreeListItem;
    @Prop({ type: String, default: 'name' }) headerField!: string;
    @Prop({ type: String, default: 'children' }) childrenField!: string;
    @Prop({ type: [String, Function] }) hasChildrenField!:
        | string
        | orgmap.EvaluateTreeListItemHasChildrenFuction;
}
