<template>
  <div class="fux-popup">
    <div id="pop-wrapper">
      <div class="pop-header">
        <div class="dp-flex">
          <div class="mr-auto">
            <h6 class="title">{{$t('정보수정')}}</h6>
          </div>
          <div class="ml-auto">
            <!-- 설명 : optional-close 버튼은 PC에서는 숨기고, Teams에서만 활성화합니다. -->
            <div class="optional-close" style="display: none">
              <a class="btn ico-btn ico-m ft-white">
                <i class="icon-fux-close"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="pop-content">
        <div class="pop-size optional-pop-padding">
          <div class="box-blue">
            <table width="100%" class="tbl-bdr-view">
              <colgroup>
                <col width="10%" />
                <col width="10%" />
                <col width="30%" />
                <col width="10%" />
                <col width="40%" />
              </colgroup>
              <tbody>
                <tr>
                  <td rowspan="6" class="vertical-top">
                    <div class="space-s"></div>
                    <div class="user-profile-uplode">
                      <img src="/cm/gres/faux/images/ghost_profile.png" alt="" />
                      <button
                        class="btn"
                        onclick="document.getElementById('id01').style.display='block'"
                      >
                        {{$t('프로필_이미지')}}<br />{{$t('업로드')}}<br />
                      </button>
                    </div>
                  </td>
                  <th>{{$t('이름')}}</th>
                  <td>박서준</td>
                  <th>{{$t('영문_이름')}}</th>
                  <td>
                    <input
                      type="text"
                      class="fm-def"
                      placeholder="영문이름"
                      style="width: 120px"
                      required=""
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{$t('직급')}}</th>
                  <td>부장</td>
                  <th>{{$t('소속')}}</th>
                  <td>네트워크사업부</td>
                </tr>
                <tr>
                  <th>{{$t('입사일')}}</th>
                  <td>2020-02-02</td>
                </tr>
                <tr>
                  <th class="vertical-top">{{$t('W_Settings_Addrress')}}</th>
                  <td colspan="3">
                    <div class="fm-group-input vertical-top">
                      <input type="text" class="fm-def" readonly="" />
                      <button class="btn sub-btn btn-basic-outline">{{$t('W_Contact_PostCode')}}</button>
                      <input type="text" class="fm-def flex-3 m-l-s" />
                    </div>
                    <div class="space-s"></div>
                    <input
                      type="text"
                      class="fm-def w-100"
                      placeholder="상세주소를 입력해 주세요."
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{$t('W_Contact_Email')}}</th>
                  <td>
                    psj123@feelanet.com
                    <!--<input type="text" class="fm-def" style="width: 120px;" required>
                                        <span class="ft-gray">@ feelanet.com</span>-->
                  </td>
                  <th>{{$t('이메일2')}}</th>
                  <td>
                    <input type="text" class="fm-def" style="width: 90px" required="" />
                    <span class="ft-gray">@ feelanet.com</span>
                  </td>
                </tr>
                <tr>
                  <th>{{$t('생년월일')}}</th>
                  <td>1999.02.21</td>
                  <th>{{t('휴대폰번호')}}</th>
                  <td>
                    <input type="text" class="fm-def" style="width: 120px" required="" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="pop-footer">
        <div class="dp-flex">
          <div class="ml-auto">
            <button class="btn page-btn btn-basic" onclick="window.close()">{{$t('적용')}}</button>
            <button class="btn page-btn btn-basic" onclick="window.close()">{{$t('W_Cancel')}}</button>
          </div>
        </div>
      </div>
    </div>
    <div id="id01" class="modal" style="display: none">
      <div class="modal-flex">
        <div class="modal-flex-inner">
          <div class="modal-header">
            <div class="dp-flex">
              <div class="mr-auto">
                <h6 class="title">{{$t('프로필_이미지_업로드')}}</h6>
              </div>
              <div class="ml-auto">
                <a
                  class="btn ico-btn ico-m"
                  onclick="document.getElementById('id01').style.display='none'"
                >
                  <i class="icon-fux-close"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-content">
            <!-- 설명 : 모달의 너비와 높이는 modal-size 태그에서 조절합니다. -->
            <div class="modal-size optional-modal-padding">
              <div class="box-gray">
                <div class="input-file">
                  <label>
                    {{$t('W_Contact_Find')}}
                    <input
                      type="file"
                      onchange="javascript:document.getElementById('file_route').value=this.value"
                    />
                  </label>
                  <input
                    type="text"
                    class="ft-m ft-300"
                    readonly="readonly"
                    title="file_route Route"
                    id="file_route"
                    placeholder="첨부된 파일이 없습니다."
                  />
                </div>
              </div>
              <div class="space-m"></div>
              <ul class="ft-s">
                <li class="ft-gray">
                  <span class="bullet bullet-blue"></span> 사진파일은 10MB 미만의 JPG, GIF 파일만
                  업로드 가능합니다.
                </li>
                <li class="ft-gray">
                  <span class="bullet bullet-blue"></span> 사진사이즈는 100*140 픽셀이 제일 알맞게
                  보입니다.
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <div class="dp-flex">
              <div class="ml-auto">
                <button
                  class="btn page-btn btn-basic"
                  onclick="document.getElementById('id01').style.display='none'"
                >
                  {{$t('저장')}}
                </button>
                <button
                  class="btn page-btn btn-basic"
                  onclick="document.getElementById('id01').style.display='none'"
                >
                  {{$t('W_Cancel')}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
