import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapMutations } from 'vuex'
import * as types from '@orgmap/store/types'
@Component({
  name: 'MemberListItem',
  computed: {
    ...mapGetters({
      options: types.ORG_OPTION_GET
    }),
  }
})
export default class MemberListItem extends Vue {
  @Prop({ type: Object }) item!: orgmap.TMemberItem
  @Prop({ type: String }) userlang!: string
  @Prop({ type: Function }) onSelect!: (item: orgmap.TMemberItem) => void
  @Prop({ type: Function }) onSelectOne!: (item: orgmap.TMemberItem, isSingle: boolean) => void

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  selected: boolean = false

  photoUrl: string = ''

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  options!: orgmap.TWishContainerOptions; 

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: W A T C H E S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  @Watch('item.selected')
  onSelectedChanged(newVal: boolean) {
    this.selected = newVal
  }

  onPhotoError(event: Event){
    this.photoUrl = '/cm/gres/orgchart/images/id_photo.jpg';
  }

  mounted() {
    this.selected = this.item.selected
    this.photoUrl = (this.item.photo ? this.item.photo : '');
  }
}
