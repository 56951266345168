import api from '@/api'

interface ResponseWrapper<TResult> {
    result: TResult
    code: string
    message: string
    error: string | null
}

export default {
    createEvent(data: ewsResource.api.DynamicApiOptions,): Promise<string> {
        return api.post({
            url: '/api/ews/Calendar/create?funcName=createEvent',
            data: data
        });
    },

    editEvent(data: ewsResource.api.DynamicApiOptions): Promise<any> {
        return api.post({
            url: '/api/ews/Calendar/set?funcName=editEvent',
            data: data
        })
    },

    cancelEvent(id: string) {
        return api.post<any>({
            url: '/api/ews/Calendar/cancel?funcName=cancelEvent',
            data: { 
                id: id
            }
        });
    },    

    getContent(id: string, resource: string): Promise<string> {
        return api.get<string[]>({
            url: `/api/reservation/getContent?itemId=${encodeURIComponent(id)}&resource=${resource}`,
        }).then((res) => {
            return res.map((chunk) => {
                var decodeBase64 = atob(chunk);
                var html = decodeURIComponent(decodeBase64);
                return html;
            });
        }).then(function (htmls) {
            return htmls.join("");
        });
    },

    getOriginalAppointment(iCalUid: string, start?: string, end?: string): Promise<any> {
        return api.post({
            url: '/api/reservation/getOriginalAppointment',
            data: {
                contract: {
                    fields: [
                        { key: "Id" },
                        { key: "ICalUid" },                    
                        { key: "ParentFolderId" },
                        { key: "AllowedResponseActions" },
                        { key: "EVENT_STATUS", as: 'status' },
                        { key: "IS_BIG_FILE", as: 'hasBigFiles' },
                        { key: "Subject" },
                        { key: "DisplayTo" },
                        { key: "TimeZone" },
                        { key: "Sensitivity" },
                        { key: "Importance" },
                        { key: "ReminderDueBy" },
                        { key: "Location" },
                        { key: "LegacyFreeBusyStatus" },
                        { key: "Organizer", format: "obj:[name,address]" },
                        // { key: "hasAttachments" },
                        { key: "MyResponseType" },
                        { key: "IsRecurring" },
                        { key: "IsFromMe" },
                        { key: "IsCancelled" },
                        { key: "IsAllDayEvent" },
                        { key: "Start", "format": "yyyy-MM-dd HH:mm:ss" },
                        { key: "End", "format": "yyyy-MM-dd HH:mm:ss" },
                        { key: "Duration", "as": "ScheduleDuration" },
                        { key: "DateTimeCreated", "format": "yyyy-MM-dd" },
                        { key: "RequiredAttendees", format: "obj:[name,address]", as: "requiredAttendees" },
                        { key: "OptionalAttendees", format: "obj:[name,address]", as: "optionalAttendees" },
                        { key: "Categories" },
                        { key: "PidLidRecurrenceType" },
                        { key: "PidLidRecurrencePattern" },
                        { key: "PidLidRecurring" },
                        { key: "Recurrence" },
                        { key: "ReminderMinutesBeforeStart"},
                        { key: "Resources", format: "obj:[name,address]" },
                        { key: "AppointmentType" },
                        {
                            key: 'Attachments',
                            format: 'obj:[id,name,contentType,size]',
                            filters: [
                                {
                                    key: 'isInline',
                                    value: false,
                                    operator: 'eq',
                                },
                            ],
                        },
                    ],
                    itemId: iCalUid,
                    type: "Calendar"
                },
                occurrenceStart: start,
                occurrenceEnd: end
            }
        });
    },

    getRecurringMasterID(id: string) {
        return api.post<string>({
            url: '/api/ews/Calendar/getmasterid?funcName=getRecurringMasterID',
            data: {
                id: id
            }
        });
    },

    getDetail(id: string, resource: string): Promise<any> {
        return api.post({
            url: `/api/reservation/getDetail?resource=${resource}`,
            data: {
                fields: [
                    { key: "Id" },
                    { key: "ICalUid" },                    
                    { key: "ParentFolderId" },
                    { key: "AllowedResponseActions" },
                    { key: "EVENT_STATUS", as: 'status' },
                    { key: "IS_BIG_FILE", as: 'hasBigFiles' },
                    { key: "Subject" },
                    { key: "DisplayTo" },
                    { key: "TimeZone" },
                    { key: "Sensitivity" },
                    { key: "Importance" },
                    { key: "ReminderDueBy" },
                    { key: "Location" },
                    { key: "LegacyFreeBusyStatus" },
                    { key: "Organizer", format: "obj:[name,address]" },
                    // { key: "hasAttachments" },
                    { key: "MyResponseType" },
                    { key: "IsRecurring" },
                    { key: "IsFromMe" },
                    { key: "IsCancelled" },
                    { key: "IsAllDayEvent" },
                    { key: "Start", "format": "yyyy-MM-dd HH:mm:ss" },
                    { key: "End", "format": "yyyy-MM-dd HH:mm:ss" },
                    { key: "Duration", "as": "ScheduleDuration" },
                    { key: "DateTimeCreated", "format": "yyyy-MM-dd" },
                    { key: "RequiredAttendees", format: "obj:[name,address]", as: "requiredAttendees" },
                    { key: "OptionalAttendees", format: "obj:[name,address]", as: "optionalAttendees" },
                    { key: "Categories" },
                    { key: "PidLidRecurrenceType" },
                    { key: "PidLidRecurrencePattern" },
                    { key: "PidLidRecurring" },
                    { key: "Recurrence" },
                    { key: "ReminderMinutesBeforeStart"},
                    { key: "Resources", format: "obj:[name,address]" },
                    { key: "AppointmentType" },
                    {
                        key: 'Attachments',
                        format: 'obj:[id,name,contentType,size]',
                        filters: [
                            {
                                key: 'isInline',
                                value: false,
                                operator: 'eq',
                            },
                        ],
                    },
                ],
                itemId: id,
                type: "Calendar",
            }
        })
    },

    getSubject(id: string): Promise<any> {
        return api.post<any>({
            url: '/api/ews/dynamic/get',
            data: {
                fields: [
                    { key: "Id" },
                    { key: "Subject" },
                ],
                itemId: id,
                type: "EmailMessage",
            }
        })
    },

    getAttendeesList (id: string, resource: string): Promise<any> {
        return api.post({
            url: `/api/reservation/getDetail?resource=${resource}`,
            data: {
                fields: [
                    { key: "Organizer", format: "obj:[name,address]" },
                    { key: "RequiredAttendees", format: "obj:[name,address]", as: "requiredAttendees" },
                    { key: "OptionalAttendees", format: "obj:[name,address]", as: "optionalAttendees" },
                ],
                itemId: id,
                type: "Calendar",
            }
        })
    },
}