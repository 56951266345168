import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
  ContentItem,
  Dropdown,
} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@gnb/store/types'
import api from '@gnb/api/common'

@Component({
  components: {
    ModalHeader,
    ModalContent,
    ModalFooter,
    ContentItem,
    PreferenceContentTemplate,
    Dropdown,
  },
  methods: {
    ...mapActions({
      languageLoad: types.LANGUAGE_LOAD,
      langChange: types.LANGUAGE_TIME_CHANGE,
    }),
  },
  computed: {
    ...mapGetters({
      language: types.LANGUAGE_LOAD,
    }),
  },
})
export default class Language extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //
  @Prop({ type: Object }) user!: account.User

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  selectedLang: string = ''
  name: string = this.$t('언어').toString()
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: A C T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  languageLoad!: () => void
  langChange!: (item: { personCode: string; language: string }) => Promise<void>

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  language!: gnb.LanguageFields

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  select(selectedItem: string) {
    this.selectedLang = selectedItem
  }

  save() {
    this.langChange({
      personCode: this.user.personCode,
      language: this.selectedLang,
    }).then(() => {})
  }

  getUserLanguage() {
    api.getUserConfig(this.user.personCode).then((res) => {
      this.selectedLang = res[0].language
    })
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  created() {
    this.languageLoad()
    this.getUserLanguage()
  }
}
