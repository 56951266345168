import { GetterTree } from 'vuex'
import * as types from './types'

export default <GetterTree<appbase.state.AppbaseState, RootState>>{
  [types.MODAL_CURRENT](state) {
    return state.modal
  },

  [types.TEAMS](state) {
    return state.teams
  },

  [types.PAGE_CURRENT](state) {
    return state.page.current
  },
}
