const tinyViewerOptions = {
  content_style: `
p { font-family: Arial; }
body {font-family: Arial ; font-size: 10pt; line-height: 1.2;}
body#tinymce p {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
}`,
  plugins: [
    'advlist autoresize autolink lists link image charmap print preview anchor textcolor',
    'searchreplace visualblocks advcode fullscreen',
    'insertdatetime  table contextmenu powerpaste ',
    'formatpainter permanentpen pageembed checklist casechange', 
    //wordcount mediaembed media 모듈 삭제
  ],
  font_formats:
    '맑은 고딕=Malgun Gothic;' +
    '굴림=gulim;' +
    '굴림체=GulimChe;' +
    '궁서=Gungsuh;' +
    '궁서체=GungsuhChe;' +
    '돋움=dotum;' +
    '돋움체=DotumChe;' +
    '바탕=Batang;' +
    '바탕체=BatangChe;' +
    '새굴림=ngulim;' +
    '함초롬돋움=HCR Dotum;' +
    '함초롬바탕=HCR Batang;' +
    'Arial=arial,helvetica,sans-serif;' +
    'Arial Black=arial black,avant garde;' +
    'Comic Sans MS=comic sans ms, sans-serif;' +
    'Courier New=courier new, courier;' +
    'Times New Roman=times new roman,times;',
  fontsize_formats:
    '7pt 8pt 9pt 10pt 11pt 12pt 14pt 15pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 36pt 48pt 72pt',
  inline_styles: true,
  menubar: false,
  toolbar: false,
  resize: false,
  statusbar: false,
  autoresize_on_init: true,
  setup: function (editor: any) {
    editor.settings.readonly = true
    editor.on('init', function (e: any) {
      var divElem = editor.editorContainer
      divElem.classList.add('tox-mce-noborder')
    })
  },
}

export default tinyViewerOptions