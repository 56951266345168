import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown,Checkbox} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import { mapActions } from 'vuex'
import * as types from '@gnb/store/types'

@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        Dropdown,
        Checkbox
    }
    ,methods:{
        ...mapActions({
        saveSetting: types.SETTINGS_MYSETTING_SAVE,
        })
    }
})
export default class DocumentMaintenance extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    name:string = this.$t('임시_저장_문서_유지').toString();
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({ type: Object }) settingInfo!: gnb.TA_MYSETTING;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    saveSetting!: (payload: { data: gnb.TA_MYSETTING, isFirst: boolean }) => Promise<boolean>;
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    change(item:boolean){
        this.settingInfo.isApmReuse = item;
    }

    save(){
        this.$emit('save', this.settingInfo)
    }
  
}