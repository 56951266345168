import Vue, { Component } from 'vue'
import { dispatchEventBus } from '@appbase/util/eventBus'
import HrView from './hr/HrView.vue'
import PersonalAddressBookView from './personal/PersonalAddressBookView.vue'
import PublicAddressBookView from './public/PublicAddressBookView.vue'

export type TTabNames = 'hr' | 'personal' | 'public'

export interface IOrgmapMain {
  options: orgmap.TWishContainerOptions
  event: string
  propsOption: orgmap.TWishItemPropsOptions | null
}

export abstract class OrgmapMainBase extends Vue implements IOrgmapMain {
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  containers!: orgmap.TWishContainer[]


  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //
  // ?event=test&option=%5B%7B%22id%22%3A0%2C%22name%22%3A%22%EB%B0%9B%EB%8A%94%EC%82%AC%EB%9E%8C%22%2C%22height%22%3A%223%22%7D%2C%7B%22id%22%3A1%2C%22name%22%3A%22%EC%B0%B8%EC%A1%B0%22%2C%22height%22%3A%222%22%7D%2C%7B%22id%22%3A2%2C%22name%22%3A%22%EC%88%A8%EC%9D%80%EC%B0%B8%EC%A1%B0%22%2C%22height%22%3A%221%22%7D%5D&props=%7B%22props%22%3A%5B%22id%22%2C%22name%22%2C%22personCode%22%5D%7D
  // ?event=%24orglist%2Forg%2Fpopup&option={"containers"%3A[{"id"%3A0,"name"%3A"권한멤버","height"%3A"1"}]}

  options: orgmap.TWishContainerOptions = {
    addableDept: false,
    single: false,
    personal: true,
    public: true,
    containers: [],
  }
  event: string = ''
  propsOption: orgmap.TWishItemPropsOptions | null = null
  tab: TTabNames = 'hr'

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //

  get contentComponent(): Component {
    switch (this.tab) {
      case 'hr':
        return HrView

      case 'personal':
        return PersonalAddressBookView

      case 'public':
        return PublicAddressBookView

      default:
        throw new Error('[NOT_SUPPORTED_ERROR] ' + this.tab)
    }
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  processTabSelect(name: TTabNames) {
    this.tab = name
  }

  processTabActive(name: TTabNames) {
    return this.tab === name
  }

  processOk(dispatchTarget: any) {
    const { propsOption } = this
    console.log(2)
    if (propsOption) {
      console.log('props', propsOption)
      var returnData = this.containers.map((c) => {
        const items = c.items
        return {
          id: c.id,
          items: items.map((x: { [key: string]: any }) => {
            const keys = propsOption.props
            var val: { [key: string]: any } = { text: x.text, value: x.value }
            keys.forEach((key) => {
              if (x[key]) val[key] = x[key]
            })
            return val
          }),
        }
      })
      if (this.event) {
        //2022-04-12 모달의 경우 opener 가 없음 분기 처리
        //모달은 opener null 임
        let target = null

        if (window.opener == null) {
          target = window
        } else {
          target = window.opener
        }

        (target as any).dispatchEvent(new CustomEvent(this.event, { detail: (returnData as any) }));

        dispatchEventBus(dispatchTarget, this.event, returnData)
      }
    } else {
      // query string에 props가 정의되지 않은 경우 모든 필드 값을 반환
      if (this.event) {
        var wholeData = this.containers.map((c) => {
          const items = c.items
          return {
            id: c.id,
            items: items.map((x) => {
              return { ...x, text: x.text, value: x.value }
            }),
          }
        });

        window.opener.dispatchEvent(new CustomEvent(this.event, { detail: (wholeData as any) }));
        dispatchEventBus(dispatchTarget, this.event, wholeData);
      }
    }
    this.handleClose()
  }

  abstract handleClose(): void
}
