import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {i18n} from '@appbase/util/i18n-setup'
import {initializeAsync as initBoardAsync} from '@board/module'
import {initializeAsync as initAppBaseAsync} from '@appbase/module'
import * as typesAppbase from '@appbase/store/types'
import * as msTeams from '@microsoft/teams-js'

// Microsoft Teams Setting
msTeams.initialize(() => {
    store.commit(typesAppbase.TEAMS, true)
    //teams 자체에서 background 컬러 변경함
    window.document.body.style.background = "white"
})

Vue.config.productionTip = false

//
// ────────────────────────────────────────────────────────────────── I ──────────
//   :::::: I N I T   V U E   R O O T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────────
//

const bootstrap = async () => {
    await initBoardAsync()
    await initAppBaseAsync()
    new Vue({
        router,
        store,
        i18n,
        render: (h) => h(App),
        beforeCreate() {
        },
    }).$mount('#app')
}
bootstrap().then();
