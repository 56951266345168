import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import * as usertypes from '@account/store/types'
import menuHelper from '../../../util/menu-helper'
import LeftMenuItem from './LeftMenuItem.vue'

@Component({
  components: { LeftMenuItem },
  computed: {
    ...mapGetters({
      userCurrent: usertypes.CURRENT,
    }),
  },
})
export default class LeftMenu extends Vue {
  userCurrent!: account.TCurrent
  menuPermission: account.TPermissionMenu[] = []
  menuItem: appbase.cache.TMenuItem | null = null
  menuItemPath: number[] = []
  userLang: string = ""

  isPermission: boolean = false;
  reqMenuId: number = (this.$route.query.MENUID ? Number(this.$route.query.MENUID) : 0)

  fnExpandMenu(item: appbase.cache.TMenuItem) {
    let iObj = document.getElementById('i_leftmenu_' + item.menuId)
    let subObj = document.getElementById('div_leftmenu_sub_' + item.menuId)

    if (iObj) {
      if (iObj.classList.contains('icon-fux-minus-square')) {
        iObj.classList.remove('icon-fux-minus-square')
        iObj.classList.add('icon-fux-add-square')
        subObj?.setAttribute('style', 'display:none')
      } else {
        iObj.classList.remove('icon-fux-add-square')
        iObj.classList.add('icon-fux-minus-square')
        subObj?.removeAttribute('style')
      }
    }
  }

  fnClickMenu(item: appbase.cache.TMenuItem) {
    if (item) {
      // 선택한 메뉴의 targetUrl 값이 없는 경우 하위 메뉴 중 첫 번째 항목의 targetUrl로 이동한다.
      if (item.targetUrl){
        menuHelper.next(item);
      }
      else if (item.targetUrl == '' && item.hasChildren && item.children.length > 0){
        if (item.children[0].targetUrl){
          menuHelper.next(item.children[0]);
        }
        else{
          this.fnExpandMenu(item);
        }
      }
    }
  }

  @Watch('$route.query.MENUID')
  fnChangeMenuId() {
    const { MENUID } = this.$route.query
    if (MENUID){
      let leftItem = menuHelper.getLeftMenuList(Number(MENUID))
      let menuPath = menuHelper.getMenuIdFullPath(Number(MENUID))
      if (leftItem){
        this.menuItem = leftItem
        if (this.menuItem && this.menuItem.hasChildren && this.menuItem.children.length > 0){
          this.menuItem.children = menuHelper.setMenuParam(this.menuItem.children, this.userCurrent.user)
        }
        this.menuItemPath = menuPath
        let menuId = (this.menuItem ? this.menuItem.menuId : 0)
        if (this.menuPermission.find(x => x.id == menuId)){
          this.isPermission = true;
        }
      }
    }
  }

  created() {
    this.menuPermission = this.userCurrent.permissions.menues;
    this.userLang = this.userCurrent.userConfig.language.toLowerCase();
    this.fnChangeMenuId();
  }
}
