import { MutationTree } from 'vuex';
import * as types from './types'
import { i18n } from '@appbase/util/i18n-setup'

export default <MutationTree<gnb.state.GnbState>>{
    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M M O N : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    /**
     * 로딩 UI를 호출합니다
     * @param action 
     * @param flag 
     */
    [types.COMMON_LOADDING](state, flag: boolean) {
        state.isLoading = flag;
    },

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: S E T T I N G S : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //
    [types.SETTINGS_MYSETTING_LOAD](state, payload: gnb.CM_MySetting){
        state.settings.mySetting = payload;
    },
    [types.APPLICATION_LIST_LOAD](state, payload: gnb.CM_ApplicationList){
        state.settings.applicationList = payload;
    },
    [types.SETTINGS_DELEGATOR_LIST_LOAD](state, payload: gnb.CM_DelegationList){
        state.settings.delegate = payload;
    },
    //
    // ────────────────────────────────────────────────────────────────────── I ──────────
    //   :::::: L A N G U A G E A N D T I M E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────────────────
    //

    [types.LANGUAGE_LOAD](state) {
        state.languageFields = {
            languageFields: [
                {
                    key: 'ko-KR',
                    name: i18n.t('W_Settings_Korean').toString(),
                    selected: true,
                },
                {
                    key: 'en-US',
                    name: i18n.t('W_Settings_English').toString(),
                    selected: false,
                },
                // {
                //     key: 'ja-JP',
                //     name: i18n.t('W_Settings_Japanese').toString(),
                //     selected: false,
                // },
                {
                    key: 'zh-CN',
                    name: i18n.t('W_Settings_Chinese').toString(),
                    selected: false,
                },
            ],
        };
    },

    [types.TIME_LOAD](state) {
        state.timeFields = {
            timeFields: [
                {
                    key: 'Dateline Standard Time',
                    name: i18n
                        .t('W_TZ_DatelineStandardTime')
                        .toString(), // (UTC-12:00) 날짜 변경선 서쪽
                    selected: false,
                },
                {
                    key: 'UTC-11',
                    name: i18n.t('W_TZ_UTC_11').toString(), // (UTC-11:00) 협정 세계시-11
                    selected: false,
                },
                {
                    key: 'Aleutian Standard Time',
                    name: i18n
                        .t('W_TZ_AleutianStandardTime')
                        .toString(), // (UTC-10:00) 알류샨 열도
                    selected: false,
                },
                {
                    key: 'Hawaiian Standard Time',
                    name: i18n
                        .t('W_TZ_HawaiianStandardTime')
                        .toString(), // (UTC-10:00) 하와이
                    selected: false,
                },
                {
                    key: 'Marquesas Standard Time',
                    name: i18n
                        .t('W_TZ_MarquesasStandardTime')
                        .toString(), // (UTC-09:30) 마키저스 제도
                    selected: false,
                },
                {
                    key: 'Alaskan Standard Time',
                    name: i18n.t('W_TZ_AlaskanStandardTime').toString(), // (UTC-09:00) 알래스카
                    selected: false,
                },
                {
                    key: 'UTC-09',
                    name: i18n.t('W_TZ_UTC_09').toString(), // (UTC-09:00) 협정 세계시-09
                    selected: false,
                },
                {
                    key: 'Pacific Standard Time (Mexico)',
                    name: i18n
                        .t('W_TZ_PacificStandardTime_Mexico_')
                        .toString(), // (UTC-08:00) 바하 캘리포니아
                    selected: false,
                },
                {
                    key: 'Pacific Standard Time',
                    name: i18n.t('W_TZ_PacificStandardTime').toString(), // (UTC-08:00) 태평양 표준시 (미국과 캐나다)
                    selected: false,
                },
                {
                    key: 'UTC-08',
                    name: i18n.t('W_TZ_UTC_08').toString(), // (UTC-08:00) 협정 세계시-08
                    selected: false,
                },
                {
                    key: 'Mountain Standard Time',
                    name: i18n
                        .t('W_TZ_MountainStandardTime')
                        .toString(), // (UTC-07:00) 산지 표준시 (미국과 캐나다)
                    selected: false,
                },
                {
                    key: 'US Mountain Standard Time',
                    name: i18n
                        .t('W_TZ_USMountainStandardTime')
                        .toString(), // (UTC-07:00) 애리조나
                    selected: false,
                },
                {
                    key: 'Mountain Standard Time (Mexico)',
                    name: i18n
                        .t('W_TZ_MountainStandardTime_Mexico_')
                        .toString(), // (UTC-07:00) 치와와, 라파스, 마사틀란
                    selected: false,
                },
                {
                    key: 'Central Standard Time (Mexico)',
                    name: i18n
                        .t('W_TZ_CentralStandardTime_Mexico_')
                        .toString(), // (UTC-06:00) 과달라하라, 멕시코시티, 몬테레이
                    selected: false,
                },
                {
                    key: 'Canada Central Standard Time',
                    name: i18n
                        .t('W_TZ_CanadaCentralStandardTime')
                        .toString(), // (UTC-06:00) 서스캐처원
                    selected: false,
                },
                {
                    key: 'Easter Island Standard Time',
                    name: i18n
                        .t('W_TZ_EasterIslandStandardTime')
                        .toString(), // (UTC-06:00) 이스터 섬
                    selected: false,
                },
                {
                    key: 'Central Standard Time',
                    name: i18n.t('W_TZ_CentralStandardTime').toString(), // (UTC-06:00) 중부 표준시 (미국과 캐나다)
                    selected: false,
                },
                {
                    key: 'Central America Standard Time',
                    name: i18n
                        .t('W_TZ_CentralAmericaStandardTime')
                        .toString(), // (UTC-06:00) 중앙 아메리카
                    selected: false,
                },
                {
                    key: 'Eastern Standard Time',
                    name: i18n.t('W_TZ_EasternStandardTime').toString(), // (UTC-05:00) 동부 표준시 (미국과 캐나다)
                    selected: false,
                },
                {
                    key: 'SA Pacific Standard Time',
                    name: i18n
                        .t('W_TZ_SAPacificStandardTime')
                        .toString(), // (UTC-05:00) 보고타, 리마, 키토, 리오 브랑코
                    selected: false,
                },
                {
                    key: 'Haiti Standard Time',
                    name: i18n.t('W_TZ_HaitiStandardTime').toString(), // (UTC-05:00) 아이티
                    selected: false,
                },
                {
                    key: 'US Eastern Standard Time',
                    name: i18n
                        .t('W_TZ_USEasternStandardTime')
                        .toString(), // (UTC-05:00) 인디애나 (동부)
                    selected: false,
                },
                {
                    key: 'Eastern Standard Time (Mexico)',
                    name: i18n
                        .t('W_TZ_EasternStandardTime_Mexico_')
                        .toString(), // (UTC-05:00) 체투말
                    selected: false,
                },
                {
                    key: 'Cuba Standard Time',
                    name: i18n.t('W_TZ_CubaStandardTime').toString(), // (UTC-05:00) 하바나
                    selected: false,
                },
                {
                    key: 'Atlantic Standard Time',
                    name: i18n
                        .t('W_TZ_AtlanticStandardTime')
                        .toString(), // (UTC-04:00) 대서양 표준시 (캐나다)
                    selected: false,
                },
                {
                    key: 'Pacific SA Standard Time',
                    name: i18n
                        .t('W_TZ_PacificSAStandardTime')
                        .toString(), // (UTC-04:00) 산티아고
                    selected: false,
                },
                {
                    key: 'Paraguay Standard Time',
                    name: i18n
                        .t('W_TZ_ParaguayStandardTime')
                        .toString(), // (UTC-04:00) 아순시온
                    selected: false,
                },
                {
                    key: 'SA Western Standard Time',
                    name: i18n
                        .t('W_TZ_SAWesternStandardTime')
                        .toString(), // (UTC-04:00) 조지타운, 라파스, 마노스, 산후안
                    selected: false,
                },
                {
                    key: 'Venezuela Standard Time',
                    name: i18n
                        .t('W_TZ_VenezuelaStandardTime')
                        .toString(), // (UTC-04:00) 카라카스
                    selected: false,
                },
                {
                    key: 'Central Brazilian Standard Time',
                    name: i18n
                        .t('W_TZ_CentralBrazilianStandardTime')
                        .toString(), // (UTC-04:00) 쿠이아바
                    selected: false,
                },
                {
                    key: 'Turks And Caicos Standard Time',
                    name: i18n
                        .t('W_TZ_TurksAndCaicosStandardTime')
                        .toString(), // (UTC-04:00) 터크스 케이커스
                    selected: false,
                },
                {
                    key: 'Newfoundland Standard Time',
                    name: i18n
                        .t('W_TZ_NewfoundlandStandardTime')
                        .toString(), // (UTC-03:30) 뉴펀들랜드
                    selected: false,
                },
                {
                    key: 'Greenland Standard Time',
                    name: i18n
                        .t('W_TZ_GreenlandStandardTime')
                        .toString(), // (UTC-03:00) 그린란드
                    selected: false,
                },
                {
                    key: 'Montevideo Standard Time',
                    name: i18n
                        .t('W_TZ_MontevideoStandardTime')
                        .toString(), // (UTC-03:00) 몬테비디오
                    selected: false,
                },
                {
                    key: 'Argentina Standard Time',
                    name: i18n
                        .t('W_TZ_ArgentinaStandardTime')
                        .toString(), // (UTC-03:00) 부에노스아이레스
                    selected: false,
                },
                {
                    key: 'E. South America Standard Time',
                    name: i18n
                        .t('W_TZ_E_SouthAmericaStandardTime')
                        .toString(), // (UTC-03:00) 브라질리아
                    selected: false,
                },
                {
                    key: 'Bahia Standard Time',
                    name: i18n.t('W_TZ_BahiaStandardTime').toString(), // (UTC-03:00) 살바도르
                    selected: false,
                },
                {
                    key: 'Saint Pierre Standard Time',
                    name: i18n
                        .t('W_TZ_SaintPierreStandardTime')
                        .toString(), // (UTC-03:00) 생피에르앤드미클롱
                    selected: false,
                },
                {
                    key: 'Tocantins Standard Time',
                    name: i18n
                        .t('W_TZ_TocantinsStandardTime')
                        .toString(), // (UTC-03:00) 아라구아이나
                    selected: false,
                },
                {
                    key: 'SA Eastern Standard Time',
                    name: i18n
                        .t('W_TZ_SAEasternStandardTime')
                        .toString(), // (UTC-03:00) 카옌, 포르탈레자
                    selected: false,
                },
                {
                    key: 'Magallanes Standard Time',
                    name: i18n
                        .t('W_TZ_MagallanesStandardTime')
                        .toString(), // (UTC-03:00) 푼타아레나스
                    selected: false,
                },
                {
                    key: 'Mid-Atlantic Standard Time',
                    name: i18n
                        .t('W_TZ_Mid_AtlanticStandardTime')
                        .toString(), // (UTC-02:00) 중부-대서양 - 이전
                    selected: false,
                },
                {
                    key: 'UTC-02',
                    name: i18n.t('W_TZ_UTC_02').toString(), // (UTC-02:00) 협정 세계시-02
                    selected: false,
                },
                {
                    key: 'Azores Standard Time',
                    name: i18n.t('W_TZ_AzoresStandardTime').toString(), // (UTC-01:00) 아조레스
                    selected: false,
                },
                {
                    key: 'Cape Verde Standard Time',
                    name: i18n
                        .t('W_TZ_CapeVerdeStandardTime')
                        .toString(), // (UTC-01:00) 카보베르데 제도
                    selected: false,
                },
                {
                    key: 'UTC',
                    name: i18n.t('W_TZ_UTC').toString(), // (UTC) 협정 세계시
                    selected: false,
                },
                {
                    key: 'GMT Standard Time',
                    name: i18n.t('W_TZ_GMTStandardTime').toString(), // (UTC+00:00) 더블린, 에든버러, 리스본, 런던
                    selected: false,
                },
                {
                    key: 'Greenwich Standard Time',
                    name: i18n
                        .t('W_TZ_GreenwichStandardTime')
                        .toString(), // (UTC+00:00) 몬로비아, 레이캬비크
                    selected: false,
                },
                {
                    key: 'Morocco Standard Time',
                    name: i18n.t('W_TZ_MoroccoStandardTime').toString(), // (UTC+00:00) 카사블랑카
                    selected: false,
                },
                {
                    key: 'Central Europe Standard Time',
                    name: i18n
                        .t('W_TZ_CentralEuropeStandardTime')
                        .toString(), // (UTC+01:00) 베오그라드,브라티슬라바,부다페스트,류블랴나,프라하
                    selected: false,
                },
                {
                    key: 'Romance Standard Time',
                    name: i18n.t('W_TZ_RomanceStandardTime').toString(), // (UTC+01:00) 브뤼셀, 코펜하겐, 마드리드, 파리
                    selected: false,
                },
                {
                    key: 'Namibia Standard Time',
                    name: i18n.t('W_TZ_NamibiaStandardTime').toString(), // (UTC+01:00) 빈트후크
                    selected: false,
                },
                {
                    key: 'Central European Standard Time',
                    name: i18n
                        .t('W_TZ_CentralEuropeanStandardTime')
                        .toString(), // (UTC+01:00) 사라예보, 스코페, 바르샤바, 자그레브
                    selected: false,
                },
                {
                    key: 'W. Central Africa Standard Time',
                    name: i18n
                        .t('W_TZ_W_CentralAfricaStandardTime')
                        .toString(), // (UTC+01:00) 서중앙 아프리카
                    selected: false,
                },
                {
                    key: 'W. Europe Standard Time',
                    name: i18n
                        .t('W_TZ_W_EuropeStandardTime')
                        .toString(), // (UTC+01:00) 암스테르담, 베를린, 베른, 로마, 스톡홀름, 빈
                    selected: false,
                },
                {
                    key: 'West Bank Standard Time',
                    name: i18n
                        .t('W_TZ_WestBankStandardTime')
                        .toString(), // (UTC+02:00) 가자, 헤브론
                    selected: false,
                },
                {
                    key: 'Syria Standard Time',
                    name: i18n.t('W_TZ_SyriaStandardTime').toString(), // (UTC+02:00) 다마스쿠스
                    selected: false,
                },
                {
                    key: 'Middle East Standard Time',
                    name: i18n
                        .t('W_TZ_MiddleEastStandardTime')
                        .toString(), // (UTC+02:00) 베이루트
                    selected: false,
                },
                {
                    key: 'GTB Standard Time',
                    name: i18n.t('W_TZ_GTBStandardTime').toString(), // (UTC+02:00) 아테네, 부카레스트
                    selected: false,
                },
                {
                    key: 'Jordan Standard Time',
                    name: i18n.t('W_TZ_JordanStandardTime').toString(), // (UTC+02:00) 암만
                    selected: false,
                },
                {
                    key: 'Israel Standard Time',
                    name: i18n.t('W_TZ_IsraelStandardTime').toString(), // (UTC+02:00) 예루살렘
                    selected: false,
                },
                {
                    key: 'Egypt Standard Time',
                    name: i18n.t('W_TZ_EgyptStandardTime').toString(), // (UTC+02:00) 카이로
                    selected: false,
                },
                {
                    key: 'Kaliningrad Standard Time',
                    name: i18n
                        .t('W_TZ_KaliningradStandardTime')
                        .toString(), // (UTC+02:00) 칼리닌그라드
                    selected: false,
                },
                {
                    key: 'E. Europe Standard Time',
                    name: i18n
                        .t('W_TZ_E_EuropeStandardTime')
                        .toString(), // (UTC+02:00) 키시네프
                    selected: false,
                },
                {
                    key: 'Libya Standard Time',
                    name: i18n.t('W_TZ_LibyaStandardTime').toString(), // (UTC+02:00) 트리폴리
                    selected: false,
                },
                {
                    key: 'South Africa Standard Time',
                    name: i18n
                        .t('W_TZ_SouthAfricaStandardTime')
                        .toString(), // (UTC+02:00) 하라레, 프리토리아
                    selected: false,
                },
                {
                    key: 'FLE Standard Time',
                    name: i18n.t('W_TZ_FLEStandardTime').toString(), // (UTC+02:00) 헬싱키, 키예프, 리가, 소피아, 탈린, 빌뉴스
                    selected: false,
                },
                {
                    key: 'E. Africa Standard Time',
                    name: i18n
                        .t('W_TZ_E_AfricaStandardTime')
                        .toString(), // (UTC+03:00) 나이로비
                    selected: false,
                },
                {
                    key: 'Russian Standard Time',
                    name: i18n.t('W_TZ_RussianStandardTime').toString(), // (UTC+03:00) 모스크바, 상트페테르부르크, 볼고그라드
                    selected: false,
                },
                {
                    key: 'Belarus Standard Time',
                    name: i18n.t('W_TZ_BelarusStandardTime').toString(), // (UTC+03:00) 민스크
                    selected: false,
                },
                {
                    key: 'Arabic Standard Time',
                    name: i18n.t('W_TZ_ArabicStandardTime').toString(), // (UTC+03:00) 바그다드
                    selected: false,
                },
                {
                    key: 'Turkey Standard Time',
                    name: i18n.t('W_TZ_TurkeyStandardTime').toString(), // (UTC+03:00) 이스탄불
                    selected: false,
                },
                {
                    key: 'Arab Standard Time',
                    name: i18n.t('W_TZ_ArabStandardTime').toString(), // (UTC+03:00) 쿠웨이트, 리야드
                    selected: false,
                },
                {
                    key: 'Iran Standard Time',
                    name: i18n.t('W_TZ_IranStandardTime').toString(), // (UTC+03:30) 테헤란
                    selected: false,
                },
                {
                    key: 'Azerbaijan Standard Time',
                    name: i18n
                        .t('W_TZ_AzerbaijanStandardTime')
                        .toString(), // (UTC+04:00) 바쿠
                    selected: false,
                },
                {
                    key: 'Saratov Standard Time',
                    name: i18n.t('W_TZ_SaratovStandardTime').toString(), // (UTC+04:00) 사라토브
                    selected: false,
                },
                {
                    key: 'Arabian Standard Time',
                    name: i18n.t('W_TZ_ArabianStandardTime').toString(), // (UTC+04:00) 아랍: 아부다비, 무스카트
                    selected: false,
                },
                {
                    key: 'Astrakhan Standard Time',
                    name: i18n
                        .t('W_TZ_AstrakhanStandardTime')
                        .toString(), // (UTC+04:00) 아스트라한, 울랴노브스크
                    selected: false,
                },
                {
                    key: 'Caucasus Standard Time',
                    name: i18n
                        .t('W_TZ_CaucasusStandardTime')
                        .toString(), // (UTC+04:00) 예레반
                    selected: false,
                },
                {
                    key: 'Russia Time Zone 3',
                    name: i18n.t('W_TZ_RussiaTimeZone3').toString(), // (UTC+04:00) 이젭스크, 사마라
                    selected: false,
                },
                {
                    key: 'Georgian Standard Time',
                    name: i18n
                        .t('W_TZ_GeorgianStandardTime')
                        .toString(), // (UTC+04:00) 트빌리시
                    selected: false,
                },
                {
                    key: 'Mauritius Standard Time',
                    name: i18n
                        .t('W_TZ_MauritiusStandardTime')
                        .toString(), // (UTC+04:00) 포트루이스
                    selected: false,
                },
                {
                    key: 'Afghanistan Standard Time',
                    name: i18n
                        .t('W_TZ_AfghanistanStandardTime')
                        .toString(), // (UTC+04:30) 카불
                    selected: false,
                },
                {
                    key: 'West Asia Standard Time',
                    name: i18n
                        .t('W_TZ_WestAsiaStandardTime')
                        .toString(), // (UTC+05:00) 아슈하바트, 타슈켄트
                    selected: false,
                },
                {
                    key: 'Ekaterinburg Standard Time',
                    name: i18n
                        .t('W_TZ_EkaterinburgStandardTime')
                        .toString(), // (UTC+05:00) 예카테린부르크
                    selected: false,
                },
                {
                    key: 'Pakistan Standard Time',
                    name: i18n
                        .t('W_TZ_PakistanStandardTime')
                        .toString(), // (UTC+05:00) 이슬라마바드, 카라치
                    selected: false,
                },
                {
                    key: 'Sri Lanka Standard Time',
                    name: i18n
                        .t('W_TZ_SriLankaStandardTime')
                        .toString(), // (UTC+05:30) 스리자야와르데네푸라
                    selected: false,
                },
                {
                    key: 'India Standard Time',
                    name: i18n.t('W_TZ_IndiaStandardTime').toString(), // (UTC+05:30) 첸나이, 콜카타, 뭄바이, 뉴델리
                    selected: false,
                },
                {
                    key: 'Nepal Standard Time',
                    name: i18n.t('W_TZ_NepalStandardTime').toString(), // (UTC+05:45) 카트만두
                    selected: false,
                },
                {
                    key: 'Bangladesh Standard Time',
                    name: i18n
                        .t('W_TZ_BangladeshStandardTime')
                        .toString(), // (UTC+06:00) 다카
                    selected: false,
                },
                {
                    key: 'Central Asia Standard Time',
                    name: i18n
                        .t('W_TZ_CentralAsiaStandardTime')
                        .toString(), // (UTC+06:00) 아스타나
                    selected: false,
                },
                {
                    key: 'Omsk Standard Time',
                    name: i18n.t('W_TZ_OmskStandardTime').toString(), // (UTC+06:00) 옴스크
                    selected: false,
                },
                {
                    key: 'Myanmar Standard Time',
                    name: i18n.t('W_TZ_MyanmarStandardTime').toString(), // (UTC+06:30) 양곤
                    selected: false,
                },
                {
                    key: 'N. Central Asia Standard Time',
                    name: i18n
                        .t('W_TZ_N_CentralAsiaStandardTime')
                        .toString(), // (UTC+07:00) 노보시비르스크
                    selected: false,
                },
                {
                    key: 'Altai Standard Time',
                    name: i18n.t('W_TZ_AltaiStandardTime').toString(), // (UTC+07:00) 바르나울, 고르노알타이스크
                    selected: false,
                },
                {
                    key: 'SE Asia Standard Time',
                    name: i18n.t('W_TZ_SEAsiaStandardTime').toString(), // (UTC+07:00) 방콕, 하노이, 자카르타
                    selected: false,
                },
                {
                    key: 'North Asia Standard Time',
                    name: i18n
                        .t('W_TZ_NorthAsiaStandardTime')
                        .toString(), // (UTC+07:00) 크라스노야르스크
                    selected: false,
                },
                {
                    key: 'Tomsk Standard Time',
                    name: i18n.t('W_TZ_TomskStandardTime').toString(), // (UTC+07:00) 톰스크
                    selected: false,
                },
                {
                    key: 'W. Mongolia Standard Time',
                    name: i18n
                        .t('W_TZ_W_MongoliaStandardTime')
                        .toString(), // (UTC+07:00) 호브드
                    selected: false,
                },
                {
                    key: 'China Standard Time',
                    name: i18n.t('W_TZ_ChinaStandardTime').toString(), // (UTC+08:00) 베이징, 충칭, 홍콩 특별 행정구, 우루무치
                    selected: false,
                },
                {
                    key: 'Ulaanbaatar Standard Time',
                    name: i18n
                        .t('W_TZ_UlaanbaatarStandardTime')
                        .toString(), // (UTC+08:00) 울란바토르
                    selected: false,
                },
                {
                    key: 'North Asia East Standard Time',
                    name: i18n
                        .t('W_TZ_NorthAsiaEastStandardTime')
                        .toString(), // (UTC+08:00) 이르쿠츠크
                    selected: false,
                },
                {
                    key: 'Singapore Standard Time',
                    name: i18n
                        .t('W_TZ_SingaporeStandardTime')
                        .toString(), // (UTC+08:00) 콸라룸푸르, 싱가포르
                    selected: false,
                },
                {
                    key: 'Taipei Standard Time',
                    name: i18n.t('W_TZ_TaipeiStandardTime').toString(), // (UTC+08:00) 타이베이
                    selected: false,
                },
                {
                    key: 'W. Australia Standard Time',
                    name: i18n
                        .t('W_TZ_W_AustraliaStandardTime')
                        .toString(), // (UTC+08:00) 퍼스
                    selected: false,
                },
                {
                    key: 'North Korea Standard Time',
                    name: i18n
                        .t('W_TZ_NorthKoreaStandardTime')
                        .toString(), // (UTC+08:30) 평양
                    selected: false,
                },
                {
                    key: 'Aus Central W. Standard Time',
                    name: i18n
                        .t('W_TZ_AusCentralW_StandardTime')
                        .toString(), // (UTC+08:45) 유클라
                    selected: false,
                },
                {
                    key: 'Korea Standard Time',
                    name: i18n.t('W_TZ_KoreaStandardTime').toString(), // (UTC+09:00) 서울
                    selected: true,
                },
                {
                    key: 'Yakutsk Standard Time',
                    name: i18n.t('W_TZ_YakutskStandardTime').toString(), // (UTC+09:00) 야쿠츠크
                    selected: false,
                },
                {
                    key: 'Tokyo Standard Time',
                    name: i18n.t('W_TZ_TokyoStandardTime').toString(), // (UTC+09:00) 오사카, 삿포로, 도쿄
                    selected: false,
                },
                {
                    key: 'Transbaikal Standard Time',
                    name: i18n
                        .t('W_TZ_TransbaikalStandardTime')
                        .toString(), // (UTC+09:00) 치타
                    selected: false,
                },
                {
                    key: 'AUS Central Standard Time',
                    name: i18n
                        .t('W_TZ_AUSCentralStandardTime')
                        .toString(), // (UTC+09:30) 다윈
                    selected: false,
                },
                {
                    key: 'Cen. Australia Standard Time',
                    name: i18n
                        .t('W_TZ_Cen_AustraliaStandardTime')
                        .toString(), // (UTC+09:30) 애들레이드
                    selected: false,
                },
                {
                    key: 'West Pacific Standard Time',
                    name: i18n
                        .t('W_TZ_WestPacificStandardTime')
                        .toString(), // (UTC+10:00) 괌, 포트모르즈비
                    selected: false,
                },
                {
                    key: 'E. Australia Standard Time',
                    name: i18n
                        .t('W_TZ_E_AustraliaStandardTime')
                        .toString(), // (UTC+10:00) 브리즈번
                    selected: false,
                },
                {
                    key: 'Vladivostok Standard Time',
                    name: i18n
                        .t('W_TZ_VladivostokStandardTime')
                        .toString(), // (UTC+10:00) 블라디보스토크
                    selected: false,
                },
                {
                    key: 'AUS Eastern Standard Time',
                    name: i18n
                        .t('W_TZ_AUSEasternStandardTime')
                        .toString(), // (UTC+10:00) 캔버라, 멜버른, 시드니
                    selected: false,
                },
                {
                    key: 'Tasmania Standard Time',
                    name: i18n
                        .t('W_TZ_TasmaniaStandardTime')
                        .toString(), // (UTC+10:00) 호바트
                    selected: false,
                },
                {
                    key: 'Lord Howe Standard Time',
                    name: i18n
                        .t('W_TZ_LordHoweStandardTime')
                        .toString(), // (UTC+10:30) 로드하우 섬
                    selected: false,
                },
                {
                    key: 'Norfolk Standard Time',
                    name: i18n.t('W_TZ_NorfolkStandardTime').toString(), // (UTC+11:00) 노퍽 섬
                    selected: false,
                },
                {
                    key: 'Magadan Standard Time',
                    name: i18n.t('W_TZ_MagadanStandardTime').toString(), // (UTC+11:00) 마가단
                    selected: false,
                },
                {
                    key: 'Bougainville Standard Time',
                    name: i18n
                        .t('W_TZ_BougainvilleStandardTime')
                        .toString(), // (UTC+11:00) 부건빌 섬
                    selected: false,
                },
                {
                    key: 'Sakhalin Standard Time',
                    name: i18n
                        .t('W_TZ_SakhalinStandardTime')
                        .toString(), // (UTC+11:00) 사할린
                    selected: false,
                },
                {
                    key: 'Central Pacific Standard Time',
                    name: i18n
                        .t('W_TZ_CentralPacificStandardTime')
                        .toString(), // (UTC+11:00) 솔로몬 제도, 뉴칼레도니아
                    selected: false,
                },
                {
                    key: 'Russia Time Zone 10',
                    name: i18n.t('W_TZ_RussiaTimeZone10').toString(), // (UTC+11:00) 초쿠르다흐
                    selected: false,
                },
                {
                    key: 'Russia Time Zone 11',
                    name: i18n.t('W_TZ_RussiaTimeZone11').toString(), // (UTC+12:00) 아나디리, 페트로파블로프스크-캄차스키
                    selected: false,
                },
                {
                    key: 'New Zealand Standard Time',
                    name: i18n
                        .t('W_TZ_NewZealandStandardTime')
                        .toString(), // (UTC+12:00) 오클랜드, 웰링턴
                    selected: false,
                },
                {
                    key: 'Fiji Standard Time',
                    name: i18n.t('W_TZ_FijiStandardTime').toString(), // (UTC+12:00) 피지
                    selected: false,
                },
                {
                    key: 'UTC+12',
                    name: i18n.t('W_TZ_UTC_12').toString(), // (UTC+12:00) 협정 세계시+12
                    selected: false,
                },
                {
                    key: 'Chatham Islands Standard Time',
                    name: i18n
                        .t('W_TZ_ChathamIslandsStandardTime')
                        .toString(), // (UTC+12:45) 채텀 섬
                    selected: false,
                },
                {
                    key: 'Tonga Standard Time',
                    name: i18n.t('W_TZ_TongaStandardTime').toString(), // (UTC+13:00) 누쿠알로파
                    selected: false,
                },
                {
                    key: 'Samoa Standard Time',
                    name: i18n.t('W_TZ_SamoaStandardTime').toString(), // (UTC+13:00) 사모아
                    selected: false,
                },
                {
                    key: 'UTC+13',
                    name: i18n.t('W_TZ_UTC_13').toString(), // (UTC+13:00) 협정 세계시+13
                    selected: false,
                },
                {
                    key: 'Line Islands Standard Time',
                    name: i18n
                        .t('W_TZ_LineIslandsStandardTime')
                        .toString(), // (UTC+14:00) 키리티마티 섬
                    selected: false,
                },
            ],
        };
    },

 
    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: U S E R C A R D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    [types.GET_CARDSTATE](state, item: gnb.UserBusinessCardState){
        state.cardState = item;
    },

    [types.GET_CARD](state, item: gnb.UserBusinessCard){
        state.userCard = item;
    },


    [types.SET_CHANGE_LANG_FLAG](state, payload:boolean){
        state.isChangedLang = payload
    },
    
};
