import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class GnbWorkFeed extends Vue {
    @Prop({ type: Array }) workFeedItems!: gnb.WorkFeedItem[];
    workfeedType: string = ''

    fnSetWorkFeedType(workfeedType: string){
        this.workfeedType = workfeedType;
    }

    getWorkFeedType(alarmType: string){
        let sysName = "";
        switch(alarmType){
            case "WF":
                sysName = this.$t('W_Approval').toString();
                break;
            case "BB":
                sysName = this.$t('게시').toString();
                break;
        }
        return sysName;
    }

    fnSetDisplayDate(createDate: Date){
        let displayDate = "";
        let dtNow = new Date();
        let dtCreate = new Date(createDate);
        if (dtCreate.toISOString().slice(0, 10) == dtNow.toISOString().slice(0, 10)){
            displayDate = [(dtCreate.getHours() > 9 ? dtCreate.getHours() : '0' + dtCreate.getHours()), 
                           (dtCreate.getMinutes() > 9 ? dtCreate.getMinutes() : '0' + dtCreate.getMinutes())].join(' : ');
        }
        else{
            displayDate = dtCreate.toISOString().slice(0, 10);
        }        

        return displayDate;
    }
}