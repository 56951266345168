import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { i18n } from '@appbase/util';
// components
import TreeList from '@gnb/components/Common/TreeList.vue';
import api from '@gnb/api/common'
import FolderList from './FolderList.vue';
@Component({
    components: { TreeList, FolderList },
})
export default class SimpleFolderList extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    selectedFolder: gnb.MailboxFolder | null = null;
    folders: gnb.FolderItem[] = [];
    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    get filteredFolders() {
        const getFolderName = (folders: gnb.FolderItem[], depth: number = 0) => {
            folders.forEach((f) => {

                if (depth == 0) {
                    (f as gnb.FolderItem).expanded = false;
                }

                f.selected = this.selectedFolder?.id === f.id;
                if (f.children != null) {
                    getFolderName(f.children as gnb.FolderItem[], ++depth);
                }
            });
        }
        getFolderName(this.folders);
        const excepts: (string | undefined)[] = ['outbox', 'syncissues', '예약메일함', '중요메일함']
        this.folders = this.folders.filter((f) => (!excepts.includes(f.wellKnownFolderName) && !excepts.includes(f.displayName)));

        this.folders.map((folder) => {
            if (folder.wellKnownFolderName){
                switch(folder.wellKnownFolderName){
                    case 'inbox':
                        folder.displayName = i18n.t('W_Mail_FolderInbox').toString()
                        break
                    case 'sentitems':
                        folder.displayName = i18n.t('W_Mail_FolderSentbox').toString()
                        break
                    case 'drafts':
                        folder.displayName = i18n.t('W_Mail_FolderDrafts').toString()
                        break
                    case 'deleteditems':
                        folder.displayName = i18n.t('W_Mail_FolderDeletedItems').toString()
                        break
                    case 'junkemail':
                        folder.displayName = i18n.t('W_Mail_Junk').toString()
                        break
                }
            }
            else{
                if (['보관', 'Archive'].indexOf(folder.displayName) >= 0){
                    folder.displayName = i18n.t('보관').toString()
                    folder.wellKnownFolderName = 'archive'
                }
            }            
        })
        
        this.folders = this.sortForFolder(this.folders);

        return this.folders    
        //return this.folders.filter((f: gnb.FolderItem) => !f.wellKnownFolderName && !f.userWellKnownName);
    }
    
    sortForFolder(folders: gnb.FolderItem[]){
        var sortPriority: { [key: string]: number } = {
            // 시스템 예약 포더
            inbox: 1, // 받은 편지함
            drafts: 2, // 임시 보관함
            sentitems: 3, // 보낸 편지함
            deleteditems: 4, // 휴지통
            archive: 5, // 보관
            junkemail: 6, // 스펨
            // 솔루션 예약 폴더
            reserveditems: 7, // 예약 편지함
            'user-unread': 8, // 읽지 않음 메일함
            userimportance: 9 // 중요메일함
        }
        const sorted = folders.sort(function (a, b) {
        // If compareFunction(a, b) is less than 0, sort a to a lower index than b.
        // See https://developer.mozilla.org/en/JavaScript/Reference/Global_Objects/Array/sort
            var important_a = sortPriority[(a.wellKnownFolderName || a.userWellKnownName)!],
                important_b = sortPriority[(b.wellKnownFolderName || b.userWellKnownName)!]
            if (important_a && !important_b) {
                return -1
            } else if (important_b && !important_a) {
                return 1
            } else if (important_a && important_b) {
                return important_a - important_b
            } else {
                return 99
            } // keep original order if neither a or b is important
        })

        return sorted
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    select(folder: gnb.MailboxFolder) {
        this.selectedFolder = folder;
        this.$emit('select', folder);
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    created(){
        api.getFolder().then((res)=>{
            this.folders = res;
        });
    }

}
