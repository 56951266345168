import { GetterTree } from 'vuex';
import * as types from './types';

export default <GetterTree<gnb.state.GnbState, RootState>>{

    [types.LANGUAGE_LOAD](state) {
        return state.languageFields;
    },

    [types.TIME_LOAD](state) {
        return state.timeFields;
    },

    [types.GET_CARDSTATE](state) {
        return state.cardState;
    },

    [types.GET_CARD](state) {
        return state.userCard;
    },

    [types.SETTINGS_MYSETTING_DATA](state){
        return state.settings.mySetting;
    },

    
    [types.APPLICATION_LIST_DATA](state){
        return state.settings.applicationList;
    },

    [types.SETTINGS_DELEGATOR_LIST_DATA](state){
        return state.settings.delegate;
    },
    
    [types.COMMON_LOADDING](state) {
        return state.isLoading;
    },
    
    [types.SET_CHANGE_LANG_FLAG](state) {
        return state.isChangedLang;
    },
};
