import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { PageLayout } from '@appbase/templates'
import { mapGetters } from 'vuex'
import * as usertypes from '@account/store/types'
import api from '@gnb/api'
import { openPopup } from '@appbase/util/popup-helper'
import menuHelper from '@appbase/util/menu-helper'
// components
import SubGlobalNavigationBar from '@gnb/components/GlobalNavigationBar/SubGlobalNavigationBar.vue'
import GnbSearch from '@gnb/components/GlobalNavigationBar/GnbSearch.vue'
import SiteMap from '@gnb/components/GlobalNavigationBar/SiteMap.vue'
import GnbBookmark from '@gnb/components/GlobalNavigationBar/GnbBookmark.vue'
import GnbQuickMenu from '@gnb/components/GlobalNavigationBar/GnbQuickMenu.vue'
import GnbWorkFeed from '@gnb/components/GlobalNavigationBar/GnbWorkFeed.vue'
import modalHelper from '@appbase/util/modal-helper'
import PreferenceModal from '@gnb/components/Preference/PreferenceModal.vue'
@Component({
  components: {
    PageLayout,
    SubGlobalNavigationBar,
    GnbSearch,
    SiteMap,
    GnbBookmark,
    GnbQuickMenu,
    GnbWorkFeed,
    PreferenceModal,
  },
  computed: {
    ...mapGetters({
      userCurrent: usertypes.CURRENT,
    }),
  },
})
export default class GlobalNavigationBar extends Vue {
  userCurrent!: account.TCurrent
  menuItems: appbase.cache.TMenuItem[] = []
  siteMapItems: appbase.cache.TMenuItem[] = []
  menuPermission: account.TPermissionMenu[] = []
  orgSearchItems: gnb.OrgPersonSearch[] = []
  workFeedItems: gnb.WorkFeedItem[] = []
  bookMarkItems: gnb.BookMarkItem[] = []
  quickMenuItems: gnb.QuickMenuItem[] = []
  iconMenuItems: gnb.QuickMenuItem[] = []
  isAdmin: boolean = false
  personCode: string = ''
  comCode: string = ''

  // 알림 관련
  UnReadWorkFeedStatus: boolean = false

  // Gnb Item Display Option
  dispGnbSearch: boolean = false
  dispGnbWorkFeed: boolean = false
  dispGnbBookmark: boolean = false
  dispGnbQuickMenu: boolean = false


  //show subnavi
  showSubnavi : boolean = false




  // 메뉴 클릭 이벤트 처리
  fnGnbClick(item: appbase.cache.TMenuItem) {
    menuHelper.next(item)
  }


  // 검색 버튼 이벤트 처리
  fnOpenGnbSearch(event: string) {
    this.dispGnbSearch = event == 'O' ? true : false
    this.dispGnbWorkFeed = false
    this.dispGnbBookmark = false
    this.dispGnbQuickMenu = false

    if (event == 'O') {
      // 팝업 종료 시 검색 데이터 초기화
      this.orgSearchItems = []
    }
  }

  // 사우검색
  async fnGnbSearch(event: KeyboardEvent) {
    if (event) event.preventDefault()
    let searchVal = (<HTMLInputElement>document.getElementById('txtGnbSearch')).value

    if (event.code == 'Enter') {
      if (searchVal.trim() == '') {
        await (window as any).alertAsync('검색어를 입력하세요.')
        ;(<HTMLInputElement>document.getElementById('txtGnbSearch')).focus()
      } else {
        // 통합검색으로 이동
      }
    } else {
      // 2자 이상인 경우 검색 시작
      if (searchVal.length >= 2) {
        let comCode = this.userCurrent.user.comCode
        api.gnb.getOrgPersonSearch(searchVal, comCode).then((res: any) => {
          if (res) {
            this.orgSearchItems = res
          } else {
            this.orgSearchItems = []
          }
        })
      } else {
        this.orgSearchItems = []
      }
    }
  }

  // WorkFeed 버튼 이벤트 처리
  fnOpenGnbWorkFeed(event: string) {
    this.dispGnbSearch = false
    this.dispGnbWorkFeed = event == 'O' ? true : false
    this.dispGnbBookmark = false
    this.dispGnbQuickMenu = false

    if (event == 'O') {
      // WorkFeed 데이터 조회
      this.fnGetWorkFeedList()
    }
  }

  // 읽지 않은 WorkFeed 건수 조회
  fnGetUnreadWorkFeedCount() {
    api.gnb.getWorkFeedUnreadCount(this.personCode, this.comCode).then((res: any) => {
      this.UnReadWorkFeedStatus = res > 0 ? true : false
    })
  }

  // WorkFeed 데이터 조회
  fnGetWorkFeedList() {
    api.gnb.getWorkFeedList(this.personCode, this.comCode).then((res: any) => {
      if (res) {
        this.workFeedItems = res
        this.UnReadWorkFeedStatus = true
      } else {
        this.workFeedItems = []
        this.UnReadWorkFeedStatus = false
      }
    })
  }

  // WorkFeed Item Click
  fnReadWorkFeed(workfeedItem: gnb.WorkFeedItem) {
    // Link가 존재하는 경우 호출
    if (workfeedItem.itemUrl != '') {
      this.fnOpenWindow(workfeedItem.itemUrl, workfeedItem.alarmText, 1024, 900)
    }

    api.gnb.setWorkFeedReadStatus(workfeedItem.alarmId, true).then((res: any) => {
      if (res > -1) {
        //this.fnGetWorkFeedList();
        let nIdx = this.workFeedItems.findIndex((x) => x.alarmId == workfeedItem.alarmId)
        if (nIdx > -1) this.workFeedItems.splice(nIdx, 1)
        if (this.workFeedItems.length == 0) this.UnReadWorkFeedStatus = false
      }
    })
  }

  // WorkFeed 전체 삭제 이벤트 처리
  async fnAllReadWorkFeed() {
    if (this.UnReadWorkFeedStatus) {
      if (await(window as any).confirmAsync('알림내역을 전체 삭제하시겠습니까?')) {
        api.gnb.setWorkFeedAllReadStatus(this.personCode, this.comCode).then(async (res: any) => {
          if (res > -1) {
            await (window as any).alertAsync('삭제 하였습니다.')
            //this.fnGetWorkFeedList();
            this.workFeedItems = []
            this.UnReadWorkFeedStatus = false
          } else {
            await (window as any).alertAsync('삭제에 실패 하였습니다.')
          }
        })
      }
    }
  }

  // 즐겨찾기 버튼 이벤트
  fnOpenGnbBook(event: string) {
    this.dispGnbSearch = false
    this.dispGnbWorkFeed = false
    this.dispGnbBookmark = event == 'O' ? true : false
    this.dispGnbQuickMenu = false

    if (event == 'O') {
      this.fnGetBookMarkList()
    }
  }

  // 즐겨찾기 목록 조회
  fnGetBookMarkList() {
    api.gnb.getBookMarkList(this.personCode, this.comCode).then((res: any) => {
      if (res) {
        this.bookMarkItems = res
      } else {
        this.bookMarkItems = []
      }
    })
  }

  // 즐겨찾기 Link 클릭
  fnBookMarkClick(bookMarkItem: gnb.BookMarkItem) {
    if (bookMarkItem.itemUrl != '') {
      switch (bookMarkItem.itemType) {
        case 'MU':
          let url =
            bookMarkItem.itemUrl +
            (bookMarkItem.itemId != 0 ? '?MENUID=' + bookMarkItem.itemId : '')
          document.location.href = url
          break
        case 'FO':
          this.fnOpenWindow(bookMarkItem.itemUrl, bookMarkItem.itemText, 1024, 900)
          break
      }
    }
  }

  // 즐겨찾기 삭제
  async fnDeleteViewBookMarkItem(bookMarkItem: gnb.BookMarkItem) {
    if (await(window as any).confirmAsync('삭제 하시겠습니까?')) {
      api.gnb
        .deleteBookMark(bookMarkItem.bookMarkId, this.personCode, this.comCode)
        .then(async (res: any) => {
          if (res > -1) {
            await (window as any).alertAsync('삭제 하였습니다.')
            let nIdx = this.bookMarkItems.findIndex((x) => x.bookMarkId == bookMarkItem.bookMarkId)
            if (nIdx > -1) {
              this.bookMarkItems.splice(nIdx, 1)
            }
          } else {
            await (window as any).alertAsync('삭제에 실패 하였습니다.')
          }
        })
    }
  }

  // Quick Menu 버튼 이벤트
  fnOpenGnbQuickMenu(event: string) {
    this.dispGnbSearch = false
    this.dispGnbWorkFeed = false
    this.dispGnbBookmark = false
    this.dispGnbQuickMenu = event == 'O' ? true : false
  }

  // 사용자 설정 Quick Menu 목록 조회
  fnGetQuickMenuList() {
    api.gnb.getUserQuickMenuList(this.personCode, this.comCode, 'U').then((res: any) => {
      if (res) {
        let resQuickMenuItems: gnb.QuickMenuItem[] = res
        this.quickMenuItems = resQuickMenuItems.filter((x) => x.linkType == 'Q')
        this.iconMenuItems = resQuickMenuItems.filter((x) => x.linkType == 'I')
      } else {
        this.quickMenuItems = []
        this.iconMenuItems = []
      }
    })
  }

  // Quick Menu Click 이벤트 처리
  fnQuickMenuClick(quickItem: gnb.QuickMenuItem) {}

  // 퀵메뉴 설정 팝업 오픈
  fnOpenQuickMenuUserSet() {
    let url = '/gnb/setquickmenu'
    this.fnOpenWindow(url, 'Quick Menu', 700, 900)
  }

  // 퀵메뉴 사용지 지정 이벤트 처리
  fnInsertQuickMenuUserSet(selQuickMenuItems: string) {
    api.gnb
      .insertQuickMenuUserSet(this.personCode, this.comCode, selQuickMenuItems)
      .then(async (res: any) => {
        if (res > -1) {
          await (window as any).alertAsync('등록 하였습니다.')
          this.fnGetQuickMenuList()
        } else {
          await (window as any).alertAsync('퀵메뉴 설정 중 오류가 발생 하였습니다.')
        }
      })
  }

  /** 조직도 창 열기 */
  fnOrgPop() {
    openPopup(
      'gnb',
      '/orgmap',
      { event: 'OrgPopup', option: { personal: false, public: false, single: true }},
      {
        width: 1024,
        height: 700,
      }
    )
  }

  fnOpenWindow(url: string, title: string, width: number, height: number) {
    if (url != '') {
      let nWidth = width ? width : 1024
      let nHeight = height ? height : 900
      let nLeft = (screen.width - nWidth) / 2
      let nTop = (screen.height - nHeight) / 2
      let property =
        'left=' +
        nLeft +
        ',top=' +
        nTop +
        ',height=' +
        nWidth +
        ',width=' +
        nHeight +
        ',toolbar=no,scrollbars=no,resizable=yes'

      window.open(url, title, property)
    }
  }

  // 메뉴 데이터 생성
  fnMakeMenuItem(
    childItems: appbase.cache.TMenuItem[],
    item: appbase.cache.TMenuItem,
    itemType: string
  ) {
    for (let i = 0; i < childItems.length; i++) {
      let isPermission =
        (itemType == 'S' ? true : childItems[i].isTopVisible) &&
        this.menuPermission.find((x) => x.id == childItems[i].menuId)
          ? true
          : false && childItems[i].callType == 'USER'

      if (isPermission && childItems[i].hasChildren) {
        this.fnMakeMenuItem(childItems[i].children, childItems[i], itemType)
      }

      if (i == 0) {
        item.children = []
        item.hasChildren = false
      }
      if (isPermission) {
        item.children.push(childItems[i])
      }
    }

    if (item.children.length > 0) {
      item.hasChildren = true
    }
  }

  openModal() {
    modalHelper.show({ component: PreferenceModal, force: true, value: { aaa: 'aaa' } })
  }

  created() {
    const { menu } = this.portalCache
    const excepts = ['HOME']
    this.menuPermission = this.userCurrent.permissions.menues

    // 관리자 여부 및 사용자 정보
    this.isAdmin =
      this.userCurrent.permissions.isSuperAdmin || this.userCurrent.permissions.isCompanyManager
        ? true
        : false
    this.personCode = this.userCurrent.user.personCode
    this.comCode = this.userCurrent.user.comCode

    for (let i = 0; i < menu.items.length; i++) {
      let mItem: appbase.cache.TMenuItem = menu.items[i]
      if (
        mItem.isTopVisible == true &&
        mItem.callType == 'USER' &&
        this.menuPermission.find((x) => x.id == mItem.menuId)
      ) {
        this.menuItems.push(mItem)
        if (mItem.hasChildren) {
          this.fnMakeMenuItem(mItem.children, mItem, 'M')
        }
      }

      if (
        !excepts.includes(mItem.callType) &&
        this.menuPermission.find((x) => x.id == mItem.menuId)
      ) {
        this.siteMapItems.push(mItem)
        if (mItem.hasChildren) {
          this.fnMakeMenuItem(mItem.children, mItem, 'S')
        }
      }
    }

    // WorkFeed 건수 조회
    this.fnGetUnreadWorkFeedCount()

    // Quick Menu 데이터 조회
    this.fnGetQuickMenuList()
  }
}
