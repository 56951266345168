import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import GridView from '@appbase/components/GridView/GridView.vue'

export interface receiver {
  userName: string,
  userCode: string,
  deptCode: string,
  deptName: string,
  userEmail: string
}

@Component({
  components: { GridView }
})
export default class MailSendField extends Vue {
  @Prop({ type: String }) displayName!: string
  @Prop({ type: String }) fieldValue!: string

  receiversModel: gridView.ModelItem<board.AlarmReceiver>[] = []
  receiversHeaders: gridView.Header[] = []

  created() {
    this.receiversHeaders = [
      { displayName: this.$t('이름').toString(), rowKey: 'userName', align: 'left', slotName: 'userName', width: '30%' },
      { displayName: this.$t('이메일').toString(), rowKey: 'userEmail', align: 'left', slotName: 'userEmail', width: '*' },
    ]
    
    var fieldParse = JSON.parse(this.fieldValue);

    if(Array.isArray(fieldParse)) {
        this.receiversModel = fieldParse;
    }
  }
}
