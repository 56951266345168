import { GetterTree } from 'vuex'
import * as types from './types'

export default <GetterTree<orgmap.state.OrgmapState, RootState>>{
  [types.ORG_ME](state) {
    return state.user
  },

  [types.ORG_SELECT_TREE_ITEM](state) {
    return state.selectTreeItem
  },


  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: C O M M O N S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //

  [types.ORG_OPTION_GET](state) {
    return state.options;
  },

  [types.ORG_MEMBER_LIST](state) {
    return state.members
  },

  //
  // ────────────────────────────────────────────────────────────────────── I ──────────
  //   :::::: W I T H   C O N T A I N E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────────────────
  //

  [types.ORG_CONTAINER_REGISTER](state) {
    return state.wish
  },

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: M O B I L E : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //

  [types.ORG_USER_SELECT](state) {
    return state.selections.users
  },
}
