import { render, staticRenderFns } from "./DeptTreeItem.vue?vue&type=template&id=0efdffa3"
import script from "./DeptTreeItem.ts?vue&type=script&lang=ts&external"
export * from "./DeptTreeItem.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports