import Vue from 'vue'
import { Component,  Watch } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown,Checkbox} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import { mapActions, mapGetters } from 'vuex';
import * as accountTypes from '@account/store/types'
import api from '@gnb/api/common'
import * as types from '@gnb/store/types';

@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        Dropdown,
        Checkbox
    },
    methods:{
        ...mapActions({
            loading: types.COMMON_LOADDING
        })
    },
    computed:{
        ...mapGetters({
            user: accountTypes.USER
        })
    }
})
export default class Wallpapers extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    allChecked: boolean = false;
    newAlarmChecked: boolean | null = null;
    scheduleAlarmChecked:boolean | null = null;
    name1: string = this.$t('바탕화면').toString();
    name2: string = this.$t('바탕화면_모두_알림').toString();
    name3: string = this.$t('새_알림_알림').toString();
    name4: string = this.$t('일정_미리_알림').toString();

    

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    loading!: (option: boolean) => void;
   
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    user!: account.User;

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Watch('newAlarmChecked')
    onCheckNewAlarm(){
        if(this.newAlarmChecked && this.scheduleAlarmChecked){
            this.allChecked = true;
        }else{
            this.allChecked = false;
        }
    }

    @Watch('scheduleAlarmChecked')
    onCheckScheduleAlarm(){
        if(this.newAlarmChecked && this.scheduleAlarmChecked){
            this.allChecked = true;
        }else{
            this.allChecked = false;
        }
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
 
    onChangeAllChecked(item:boolean){
        this.allChecked = item;
        if(this.allChecked){
            this.newAlarmChecked = true;
            this.scheduleAlarmChecked = true;
        }else if(!this.allChecked){
            this.newAlarmChecked = false;
            this.scheduleAlarmChecked = false;
        }
    }

    onChangeNewAlarm(item:boolean){
        this.newAlarmChecked = item;
    }

    onChangeScheduleAlarm(item:boolean){
        this.scheduleAlarmChecked = item;
    }

    save(){
        api.setAlarmConfig({
            comCode: this.user.comCode,
            personCode: this.user.personCode,
            newAlarm: this.newAlarmChecked,
            scheduleAlarm: this.scheduleAlarmChecked,
            noticeAlarm: null,
            boardAlarm: null,
            serveyAlarm:null,
        } as gnb.AlarmConfig).then(()=>{
            this.onChagedAlarmConfig();
        });
    }
    
    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //
    onChagedAlarmConfig(){
        api.getAlarmConfig(this.user.comCode, this.user.personCode).then((res)=>{
            if(res.length > 0){
                this.newAlarmChecked = res[0].newAlarm;
                this.scheduleAlarmChecked = res[0].scheduleAlarm;
                this.loading(false);
            }else{
                this.newAlarmChecked = false;
                this.scheduleAlarmChecked = false;
                this.loading(false);
            }
        })
    }

    created(){
        this.onChagedAlarmConfig();
    }
    
}