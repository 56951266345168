import { MutationTree } from 'vuex'
import * as types from './types'

export default <MutationTree<appbase.state.AppbaseState>>{
  [types.MODAL_CURRENT](state, payload: appbase.TModalInfo<any>) {
    state.modal = payload
  },

  [types.TEAMS](state, payload: boolean) {
    state.teams = payload
  },

  [types.PAGE_CURRENT](state, payload: appbase.TPageInfo) {
    state.page.current = payload
  },
}
