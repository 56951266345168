import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { uGetDateFormat, IEDate } from '@appbase/util';

var today = new Date();
var addMonth = new Date();
addMonth.setMonth(addMonth.getMonth() + 1);
addMonth.setDate(addMonth.getDate() - 1);

// initial state
const state: reserve.state.ReserveState = {
    isLoading: false,
    isDailyEventLoading: false,
    listViewOpen: false,
    // 20220907 기본 숨김으로 처리
    rightPaneOpen: false,
    leftPaneOpen: true,
    miniCalendarMatrix: [],
    monthMatrix: [],
    weekMatrix: [],
    dateMatrix: [],
    resourceEvents: [],    
    resourceDailyEvents: [],
    searchSet: {
        reserveContent: "",
        reserveTitle: "",
        reserverName: "",
        searchStart: uGetDateFormat('yyyy-MM-dd', IEDate(today)),
        searchEnd: uGetDateFormat('yyyy-MM-dd', IEDate(addMonth)),
    },
    searchEvents: [],
    resourceGroupsTree: [],
    resourceGroupsList: [],
    resourceBookmarkList: [],
    selectedResourceKeys: [],
    selectedResources: [],
    calendarMode: 2,
    calendarSetting: {
        seq: 1,
        personCode: '',
        firstDay: 0,
        workingWeek: '[1,2,3,4,5]',
        workingStartTime: '09:00:00',
        workingEndTime: '18:00:00',
        viewMode: 2,        
    } as reserve.CalendarSetting,
    monthDate: new Date(),    
    currentDate: {
        date: new Date(),
        isToday: false,
        isSelected: false,
        isWorkingTime: false,
        isDisplayArea: true,
        isSun: false,
        isSat: false,
        events: [],
        MoreEventsCount: 0,
        ui_days_week_time_text: null
    },
    currentCalendarDate: {
        date: new Date(),
        isToday: false,
        isSelected: false,
        isWorkingTime: false,
        isDisplayArea: true,
        isSun: false,
        isSat: false,
        events: [],
        MoreEventsCount: 0,
        ui_days_week_time_text: null
    },
    currentCalendarTime: {
        date: new Date(),
        isToday: false,
        isSelected: false,
        isWorkingTime: false,
        isDisplayArea: true,
        isSun: false,
        isSat: false,
        events: [],
        MoreEventsCount: 0,
        ui_days_week_time_text: null
    },
    backupDate: {
        date: new Date(),
        isToday: false,
        isSelected: false,
        isWorkingTime: false,
        isDisplayArea: true,
        isSun: false,
        isSat: false,
        events: [],
        MoreEventsCount: 0,
        ui_days_week_time_text: null
    }
};

export default {
    state,
    actions,
    getters,
    mutations,
};
