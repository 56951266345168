import api from '@/api'

export default {
  getCurrentUser(): Promise<account.TCurrent> {
    return api.get<account.TCurrent>({ url: '/commonaccount/auth/api/current' })
  },

  getPhotoUrl(address: string): Promise<string> {
    return api.get({
      url: `/api/account/photo/${address}`,
    })
  },

  getPhotoUrlByCodes(codes: string[]): Promise<any[]> {
      return api.post({
          url: '/api/account/photo/byCodes',
          data: codes
      });
  },

  // getUser(email: string): Promise<account.User> {
  //     return api.get({
  //         url: `/api/hr/person`,
  //         params: {
  //             email,
  //         },
  //     });
  // },

  // getUserByPersonCode(personCode: string): Promise<account.User> {
  //     return api.get({
  //         url: `/api/hr/department/person`,
  //         params: {
  //             personCode,
  //         }
  //     })
  // }
}
