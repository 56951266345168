const path = '$gnb';

//
// ────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: L A N G U A G E A N D T I M E : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────────────
//

export const LANGUAGE_TIME_CHANGE = `${path}/language/time/change`;
export const LANGUAGE_LOAD = `${path}/language/load`;
export const TIME_LOAD = `${path}/time/load`;

//
// ──────────────────────────────────────────────────────── I ──────────
//   :::::: U S E R C A R D : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────
//

export const GET_CARDSTATE = `${path}/get/cardstate`;
export const GET_CARD = `${path}/get/card`;
export const UPDATE_CARD = `${path}/update/card`;

//
// ──────────────────────────────────────────────────────── I ──────────
//   :::::: D O W N L O A D : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────
//

export const DOWNLOAD_PC = `${path}/download/pc`;

//
// ──────────────────────────────────────────────────────── I ──────────
//   :::::: S E T T I N G S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────
//
export const SETTINGS_MYSETTING_LOAD = `${path}/get/mysetting/load`;
export const SETTINGS_MYSETTING_DATA = `${path}/get/mysetting/data`;
export const SETTINGS_MYSETTING_SAVE = `${path}/set/mysetting/save`;
export const APPLICATION_LIST_DATA = `${path}/get/applicationlist/data`;
export const APPLICATION_LIST_LOAD = `${path}/get/applicationlist/load`;
export const SETTINGS_DELEGATOR_LIST_DATA = `${path}/get/delegatorlist/data`;
export const SETTINGS_DELEGATOR_LIST_LOAD = `${path}/get/delegatorlist/load`;
export const SETTINGS_DELEGATOR_ADD = `${path}/set/delegator/add`;
export const SETTINGS_DELEGATOR_REMOVE = `${path}/set/delegator/remove`;

//
// ──────────────────────────────────────────────────── I ──────────
//   :::::: C O M M O N : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────
//

export const COMMON_LOADDING = `${path}/common/loading`;



export const SET_CHANGE_LANG_FLAG = `${path}/setlangflag`;


