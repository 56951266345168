import axios from 'axios'
import api from '@/api'
import { invalidateUrl } from '@appbase/util'
var process = require('process-nextick-args')

export default {
  /**
   * 사용자 설정 조회
   */
  getUserConfig(personCode: string): Promise<gnb.UserConfig[]> {
    return api.get<gnb.UserConfig[]>({
      url: '/Common/gnb/api/userSetting',
      params: {
        personCode: personCode,
      },
    })
  },

  /**
   * 사용자 설정
   */
  setUserConfig(item: gnb.UserConfig): Promise<number> {
    return api.post<number>({
      url: '/Common/gnb/api/userSetting',
      data: item,
    })
  },

  /**
   * 알림 설정 조회
   */
  getAlarmConfig(comCode: string, personCode: string): Promise<gnb.AlarmConfig[]> {
    return api.get<gnb.AlarmConfig[]>({
      url: '/Common/gnb/api/alarmConfig',
      params: {
        comCode: comCode,
        personCode: personCode,
      },
    })
  },

  /**
   * 알림 설정
   */
  setAlarmConfig(item: gnb.AlarmConfig): Promise<number> {
    return api.post<number>({
      url: '/Common/gnb/api/alarmConfig',
      data: item,
    })
  },

  /**
   * 명함 옵션 리스트를 가져옵니다 (0: 기본명함, 1:한글명함, 2:영문명함, 3:자유명함)
   */
  getUserBusinessCardStateList(): Promise<gnb.UserBusinessCardState[]> {
    return api.get<gnb.UserBusinessCardState[]>({
      url: '/api/mail/settings/UserCard',
    })
  },

  /**
   * 명함 정보 수정
   */
  setUserBusinessCardInfo(item: gnb.UserBusinessCard): Promise<number> {
    return api.post<number>({
      url: '/api/mail/settings/UserCard/update',
      data: item,
    })
  },

  /**
   * 사용자의 명함정보를 가져옵니다
   */
  getUserBusinessCardInfo(personCode: string): Promise<gnb.UserBusinessCard> {
    return api.post<gnb.UserBusinessCard>({
      url: '/api/mail/settings/UserCard/get',
      data: {
        PersonCode: personCode,
      },
    })
  },

  /**
   *사용자의 명함옵션을 업데이트 합니다
   */
  setUserBusinessCardState(item: gnb.UserCardInfo): Promise<number> {
    return api.post<number>({
      url: '/api/mail/settings/UserCard/updatestate',
      data: {
        personCode: item.personCode,
        usageState: item.usageState,
      },
    })
  },
  /**
   * 부재중 설정 조회
   */
  getOOF: (): Promise<gnb.OOFconfig> => {
    return api.get<gnb.OOFconfig>({
      url: '/api/mail/settings/exchange/oof',
    })
  },

  /**
   * 부재중 설정
   */
  setOOF: (item: gnb.OOFconfig): Promise<number> => {
    return api.post<number>({
      url: '/api/mail/settings/exchange/oof',
      data: item,
    })
  },

  /**
   * 규칙 조회
   */
  getRules: (): Promise<gnb.rules.SaveRulesRequest> => {
    return api.get<gnb.rules.SaveRulesRequest>({
      url: '/api/ews/inboxrule',
    })
  },

  /**
   * 규칙 설정
   */
  setRules: (item: gnb.rules.Rule[]): Promise<boolean> => {
    return api.post<boolean>({
      url: '/api/ews/inboxrule',
      data: {
        rules: item,
      },
    })
  },

  /**
   * 저장소 폴더별 정보조회
   */
  getFolderInfo: (): Promise<gnb.FolderItem> => {
    return api.get<gnb.FolderItem>({
      url: '/api/mail/settings/folder',
    })
  },

  checkDownloadEml(data: gnb.downloadOption): Promise<gnb.DownloadEmlResult> {
    return api.post({
      url: '/api/ews/folder/archive/check',
      data: {
        folderId: data.folderId,
        start: data.start,
        end: data.end,
      },
    })
  },

  downloadPC(data: gnb.downloadOption): Promise<void> {
    return axios({
      method: 'post',
      url: invalidateUrl('/cm/api/ews/folder/archive'),
      responseType: 'arraybuffer',
      data: {
        folderId: data.folderId,
        start: data.start,
        end: data.end,
      },
    }).then(function (response) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/

      if (data.fileName.indexOf('.') == -1) {
        data.fileName += '.zip'
      }

      const blob = new Blob([response.data], { type: 'application/zip' })
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        ;(window.navigator as any).msSaveOrOpenBlob(blob, data.fileName)
      } else {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${data.fileName}`
        link.click()
        process.nextTick(() => link.remove())
      }
    })
  },

  delete(options: gnb.DeleteRequestData): Promise<boolean> {
    return api.delete<boolean>({
      url: '/api/ews/folder/empty',
      data: options,
    })
  },

  /**
   * 하나 이상의 폴더를 대상 폴더로 이동합니다.
   *
   * @param ids 폴더 아이디
   * @param destinationFolderId 대상 폴더 아이디
   */
  moveFolders(ids: string[], destinationFolderId: string): Promise<boolean> {
    if (!ids || ids.length === 0) {
      console.error(`ARG_NULL_ERROR: ids is NULL or empty.`)
    }
    if (!destinationFolderId) {
      console.error(`ARG_NULL_ERROR: destinationFolderId is NULL or empty.`)
    }
    return api.post<boolean>({
      url: '/api/ews/folder/move',
      data: {
        ids,
        destinationFolderId,
      },
    })
  },

  async getUserInfoByEmail(email: string) {
    return await api
      .get<gnb.UserInfo>({
        url: '/api/hr/department/info',
        params: {
          email: email,
        },
      })
      .then((res) => {
        return res
      })
  },

  /**
   * 사번으로 해당 사용자의 대결자 목록을 조회합니다.
   * @param personCode 사번
   * @returns
   */
  getDelegationList(personCode: string): Promise<gnb.CM_DelegationList> {
    return api
      .post<gnb.CM_DelegationList>({
        url: '/workflow/delegate/api/getdelegationlist',
        data: {
          PersonCode: personCode,
        },
      })
      .then((res) => res)
  },

  /**
   * 대결자를 추가합니다.
   * @param payload 대결자 정보
   * @returns
   */
  setDelegator(payload: gnb.TA_DELEGATION): Promise<boolean> {
    return api
      .post<boolean>({
        url: '/workflow/delegate/api/set',
        data: payload,
      })
      .then((res) => res)
  },

  /**
   * 대결자를 삭제합니다.
   * @param payload { Seq: 삭제할 대결자 항목의 번호, DeleteFlag: 삭제여부 }
   * @returns
   */
  removeDelegator(payload: { Seq: number; DeleteFlag: boolean }): Promise<boolean> {
    return api
      .post<boolean>({
        url: '/workflow/delegate/api/set',
        data: payload,
      })
      .then((res) => res)
  },

  /**
     *  양식 트리구조를 가져옵니다
	    사번이 없는 경우 모두 조회
	    사번이 있는 경우 권한있는 사용자만 조회
     * @param comCode 회사코드
     * @param personCode 사번
     */
  getApplicationList(comCode: string, personCode?: string): Promise<gnb.CM_ApplicationList> {
    return api
      .post<gnb.CM_ApplicationList>({
        url: '/workflow/home/api/getapplicationlist',
        data: {
          ComCode: comCode,
          PersonCode: personCode,
        },
      })
      .then((res) => {
        //debugger;
        return res
      })
  },

  /**
   * 폴더별 정보 조회
   */
  getFolder(): Promise<gnb.FolderItem[]> {
    return api
      .get<gnb.FolderItem[]>({
        url: '/api/ews/folder',
      })
      .then((res): gnb.FolderItem[] => {
        return res
      })
  },

  /**
   * 일정 정보 조회
   */
  getCalendarInfo(personCode: string): Promise<gnb.CalendarInfo> {
    return api.post<gnb.CalendarInfo>({
      url: '/api/calendar/UserConfig/get',
      data: {
        PERSON_CODE: personCode,
      },
    })
  },

  /**
   * 일정 정보 변경
   */
  setCalendarInfo(data: gnb.CalendarInfo): Promise<boolean> {
    return api.post<boolean>({
      url: '/api/calendar/UserConfig/update',
      data: data,
    })
  },

  /**
   * 범주 목록 조회
   */
  getCategoryList(): Promise<gnb.CategoryItem[]> {
    return api.get<gnb.CategoryItem[]>({
      url: '/api/ews/category',
    })
  },

  /**
   * 새범주 생성
   */
  createCategory(category: gnb.CategoryItem) {
    return api.put({
      url: '/api/ews/category',
      data: {
        name: category.name,
        color: category.color,
        isFavorite: category.isFavorite,
      },
    })
  },

  /**
   * 범주 수장
   */
  updateCategory(category: gnb.CategoryItem) {
    return api.post({
      url: '/api/ews/category',
      data: {
        id: category.id,
        name: category.name,
        color: category.color,
        isFavorite: category.isFavorite,
      },
    })
  },

  /**
   *  범주 즐겨찾기 설정
   */
  //  setFavoriteStatus(item:any) {
  //     return api.post({
  //         url: '/api/ews/category',
  //         data: item
  //     });
  // },

  /**
   *  범주 삭제
   */
  deleteCategory(id: string) {
    console.log('아이디', id)
    return api.post({
      url: `/api/ews/category/delete?id=${id}`,
    })
  },

  /**
   * 사용자의 환경설정 정보를 조회합니다.
   */
  getMySetting(): Promise<gnb.CM_MySetting> {
    return api
      .get<gnb.CM_MySetting>({
        url: '/workflow/mysetting',
      })
      .then((res) => res)
  },

  /**
   * 사용자의 환경설정을 추가합니다.
   * @param data
   */
  createMySetting(data: gnb.CM_MySetting): Promise<boolean> {
    return api.post<boolean>({
      url: '/workflow/mysetting/api/create',
      data: data,
    })
  },

  /**
   * 사용자의 환경설정을 수정합니다.
   * @param data
   */
  setMysetting(data: gnb.CM_MySetting): Promise<boolean> {
    return api.post<boolean>({
      url: '/workflow/mysetting/api/set',
      data: data,
    })
  },
}
