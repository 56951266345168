const path='$board';

export const BOARD_LOADDING = `${path}/common/boardLoading`;
export const ARTICLE_LOADDING = `${path}/common/articleLoading`;
export const POPUP_LOADING = `${path}/common/popupLoading`;

export const BOARD_CONFIG = `${path}/board/boardConifg`
//
// ────────────────────────────────────────────────── I ──────────
//   :::::: B O A R D : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────
//

export const BOARD_BASEURL = `${path}/board/baseUrl`
export const POPUP_BASEURL = `${path}/board/popupBaseUrl`
export const POPUP_BASENAME = `${path}/board/popupBaseName`
export const FETCH_BOARD = `${path}/board/fetchBoard`
export const FETCH_LISTFIELD = `${path}/board/fetchListField`
export const FETCH_CONTENTFIELD = `${path}/board/fetchContentField`
export const FETCH_TOTALARTICLES = `${path}/article/fetchTotalArticles`
export const FETCH_ARTICLES =`${path}/article/fetchArticles`;
export const FETCH_ADMINARTICLES =`${path}/article/fetchAdminArticles`;
export const FETCH_BLOGARTICLES =`${path}/article/fetchBlogArticles`;
export const FETCH_WRITECONFIG =`${path}/article/fetchWriteConfig`;
export const FETCH_READCONFIG =`${path}/article/fetchReadConfig`;
export const FETCH_PAGESET = `${path}/article/fetchPageset`;
export const FETCH_ARTICLESEMPTY =`${path}/article/fetchArticlesEmpty`;

export const COPERATION_BOARD_INFO = `${path}/coperationBoard/boardinfo`;
//
// ────────────────────────────────────────────────────── I ──────────
//   :::::: A R T I C L E : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────
//
export const SEND_MAIL = `${path}/article/sendEmail`
export const CREATE_PERMISSION = `${path}/article/permission`;

export const CREATE_ARTICLE = `${path}/article/createArticle`;
export const READ_ARTICLE = `${path}/article/readArticle`;
export const UPDATE_ARTICLE = `${path}/article/updateArticle`;
export const DELETE_ARTICLE = `${path}/article/deleteArticle`;

export const FETCH_FILE = `${path}/article/fetchFile`;
export const CREATE_FILE = `${path}/article/createArticleFile`;
export const UPDATE_FILE = `${path}/article/updateArticleFile`;
export const FETCH_COMMENT = `${path}/article/fetchComment`;
export const FETCH_BLOGCOMMENT = `${path}/article/fetchBlogComment`;
export const CREATE_VIEWLOG = `${path}/article/createViewLog`;
export const FETCH_VIEWLOGS = `${path}/article/fetchViewLogs`;
export const CREATE_COMMENT = `${path}/article/createComment`;
export const DELTE_COMMENT = `${path}/article/deleteComment`;
export const CREATE_RECOMMEND = `${path}/article/createRecommend`;
export const DELETE_RECOMMEND = `${path}/article/deleteRecommend`;
export const GET_RECOMMENDS = `${path}/article/getRecommend`;

export const TOGGLE_COMMENT =  `${path}/article/toggleComment`;
export const TOGGLE_RECOMMEND = `${path}/article/toggleRecommend`;
export const TOGGLE_RECCOMENDICON = `${path}/article/toggleRecommendIcon`;
export const TOGGLE_FILE = `${path}/article/toggleFile`;

export const Z_HELPDESK_CATEGORIES = `${path}/article/helpdeskcategory`;