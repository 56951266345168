<template>
  <div id="wrapper">
      <UserInfoWebpart />
  </div>
</template>

<script>

import UserInfoWebpart from '../UserInfoWebpart/UserInfoWebpart.vue'
export default {
  components: { UserInfoWebpart },
  
}

</script>

<style></style>
