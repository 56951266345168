export const loadSript = function(url: string, scriptId: string, callback?:() => void) {
    var loadTime = new Date();
    var doc = window.document;
    var scriptTag = doc.createElement('script');
    scriptTag.referrerPolicy = 'origin';
    scriptTag.type = 'application/javascript';
    scriptTag.id = scriptId;
    scriptTag.src = `${url}?timestamp=${loadTime.getTime()}`;

    var handler = function() {
        scriptTag.removeEventListener('load', handler);
        if(callback) {
            callback();
        }
    }

    scriptTag.addEventListener('load', handler);

    if(!doc.getElementById(scriptId)) {
        doc.head.appendChild(scriptTag);
    } else {
        if(callback) {
            callback();
        }
    }    
}