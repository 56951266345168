import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// initial state
const state: gnb.state.GnbState = {
    languageFields: undefined,
    timeFields: undefined,
    cardState: undefined,
    userCard: undefined,
    settings: {
        mySetting: {
            mySetting: {
                personCode: '',
                tabOrder: '0,1,2',
                language: 'ko',
                isReceiveApproveMail: false,
                isReceiveNextMail: false,
                isReceiveBeforeMail: false,
                isReceiveAfterMail: false,
                isReceiveRefMail: false,
                isReceiveCompleteMail: false,
                isReceiveCompleteApproveMail: false,
                isTUReceiveApproveMail: false,
                isTUReceiveNextMail: false,
                isTUReceiveBeforeMail: false,
                isTUReceiveAfterMail: false,
                isTUReceiveRefMail: false,
                isTUReceiveCompleteMail: false,
                isTUReceiveCompleteApproveMail: false,
                fiDocumentCount: 10,
                createdId: '',
                createdDate: new Date(),
                isRead: true,
                isCreated: false,
                isUpdate: false,
                isDelete: false,
                actionType: 'Read',
            }
        },
        delegate: {
            delegationList: []
        },
        applicationList: {
            applicationGroupList: []
        },
    },
    isLoading: true,
    isChangedLang : false
    
};

export default {
    state,
    actions,
    getters,
    mutations,
};
