import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import IframeModal from '../../containers/ModalContainer/IframeModal.vue'
@Component({
    components: { IframeModal }
})
export default class PageLayout extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({ type: Boolean, default: true }) modalFlag!: boolean;
}
