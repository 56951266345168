import Vue from 'vue';

/**
 * 스크롤이 있는 부모 Element를 반환합니다
 * @param element 
 * @param includeHidden 
 */
export const getScrollParent = function(element: HTMLElement, includeHidden: boolean) {
    var style = getComputedStyle(element);
    var excludeStaticParent = style.position === "absolute";
    var overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

    if (style.position === "fixed") return document.body;
    for (let parent:HTMLElement | null = element; (parent = parent.parentElement);) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === "static") {
            continue;
        }
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent;
    }

    return null;
}

/**
 * 부모
 * @param element 
 * @param selector 
 */
export const findParent = function(element: HTMLElement, selector: string) {
    let isID = selector && selector.length && selector[0] == '#';
    let isClass = selector && selector.length && selector[0] == '.';
    if (isID || isClass) {
        selector = selector.substr(1);
    }
    do {
        if (element) {
            if ((isClass && element.classList && element.classList.contains(selector)) ||
                (isID && element.id && element.id == selector) ||
                ((!isClass && !isID) && (element.tagName + '').toLowerCase() == (selector + '').toLowerCase())) 
            {
                break;
            } 
            else if ((element.tagName + '').toLowerCase() == 'body') 
            {
                return null;
            }
            element = element.parentNode as HTMLElement;
        } else {
            break;
        }
    } while (true);
    return element;
}