import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem} from '@gnb/components/Common'
import DatePicker  from '@appbase/components/DatePicker/DatePicker.vue'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import { SettingMissedTime, MissedAutoReply } from '@gnb/components/Preference/Mail/Missed'
import * as dateutil from '@appbase/util/date-helper'
import api from '@gnb/api/common'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@gnb/store/types'


@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        DatePicker,
        PreferenceContentTemplate,
        SettingMissedTime,
        MissedAutoReply
    },
    methods:{
        ...mapActions({
            loading: types.COMMON_LOADDING
        })
    },
    computed:{
        ...mapGetters({
            isLoading: types.COMMON_LOADDING
        })
    }
})
export default class Missed extends Vue {
    
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    loading!: (option: boolean) => void;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    isLoading !: boolean;
     
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    header:string = this.$t('부재중_설정').toString();
    get contentComponents() {
        return [SettingMissedTime]
    }


    item: gnb.MissedAutoReplyItem = {
        start: dateutil.uGetDateFormat('yyyy-MM-DDTH:i:00', new Date()),
        end: dateutil.uGetDateFormat('yyyy-MM-DDTH:i:00', new Date()),
        starttime: '00:00:00',
        endtime: '00:00:00',
        replyContent: '',
        checkUseAutoReply: true,
        checkReplyDate: true
    };


    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    handleSave() {
        const contens = this.$refs.contents as any[]
        contens.forEach((x) => {
        x.save()
        })
        
    }

   
    changeDate(dateTime: string, type: string = '') {
        const offset = new Date().getTimezoneOffset();
        let date = dateutil.IEDate(dateTime);
        if (type === 'UTC') {
            date.setMinutes(date.getMinutes() + offset);
        } else {
            date.setMinutes(date.getMinutes() - offset);
        }
        return dateutil.uGetDateFormat(
            'yyyy-MM-DD H:i:s',
            dateutil.IEDate(date)
        );
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    created(){
        this.loading(true);
        api.getOOF().then((res)=>{
            const autoReply = res;
            let startDateTime = '';
            let endDateTime = '';
    
            if (autoReply.startUtc && autoReply.endUtc) {
                startDateTime = this.changeDate(autoReply.startUtc);
                endDateTime = this.changeDate(autoReply.endUtc);
            } else {
                let sdate = new Date();
                let edate = new Date();
                edate.setDate(edate.getDate() + 1);
    
                startDateTime = this.changeDate(dateutil.uGetDateFormat('yyyy-MM-DD H:i:00', sdate));
                endDateTime = this.changeDate(dateutil.uGetDateFormat('yyyy-MM-DD H:i:00', edate));
            }
    
            this.item = {
                start: autoReply.enabled
                    ? startDateTime.split(' ')[0]
                    : dateutil.uGetDateFormat('yyyy-MM-DD', new Date()),
    
                starttime: autoReply.enabled
                    ? startDateTime.split(' ')[1] : '00:00:00',
    
                end: autoReply.enabled
                    ? endDateTime.split(' ')[0]
                    : dateutil.uGetDateFormat('yyyy-MM-DD', new Date()),
    
                endtime: autoReply.enabled ? endDateTime.split(' ')[1] : '00:00:00',
                checkUseAutoReply: autoReply.enabled,
                checkReplyDate: autoReply.state === 'Scheduled',
                replyContent: autoReply.internalMessage,
            };
        }).then(()=>{
            this.loading(false);
        })
    }

}