export enum FilterConditions {
    /**
     * 포함하는
     */
    ContainsSubstring,
    ExcludesBitmask,
    /**
     * 속성 존재하는
     */
    Exists,
    /**
     * 일치하는
     */
    IsEqualTo,
    /**
     * 보다 큰
     */
    IsGreaterThan,
    /**
     * 보다 크거나 같은
     */
    IsGreaterThanOrEqualTo,
    /**
     * 보다 작은
     */
    IsLessThan,
    /**
     * 보다 작거나 같은
     */
    IsLessThanOrEqualTo,
    /**
     * 같지 않은
     */
    IsNotEqualTo,
    /**
     * 부정
     */
    Not,
}
export enum FilterContainmentModes {
    /**
     * 전체 문자열과 상수와 정확히 일치
     */
    FullString,
    /**
     * 상수의 시작이 문자열로 시작
     */
    Prefixed,
    /**
     * 지정된 문자열 포함, 상수의 개별 단어 중간에서 시작 가능
     */
    Substring,
    /**
     * 문자열에서 모든 개별 단어를 접두사로 하는 단어 포함 (개별 단어는 AND 조건)
     */
    PrefixOnWords,
    /**
     * 문자열의 단어가 상수 안에서 정확히 일치(중간 단어 시작 불가)
     */
    ExactPhrase,
}
export enum FilterComparisonModes {
    /**
     * 정확히 일치
     */
    Exact,
    /**
     * 대/소문자 무시
     */
    IgnoreCase,
    /**
     * 공백 문자 무시
     */
    IgnoreNonSpacingCharacters,
    /**
     * 대/소문자, 공백 문자
     */
    IgnoreCaseAndNonSpacingCharacters,
}
export enum WellKnownFolderName {
        /// <summary>
        /// The Calendar folder.
        /// </summary>
        Calendar,

        /// <summary>
        /// The Contacts folder.
        /// </summary>
        Contacts,

        /// <summary>
        /// The Deleted Items folder
        /// </summary>
        DeletedItems,

        /// <summary>
        /// The Drafts folder.
        /// </summary>
        Drafts,

        /// <summary>
        /// The Inbox folder.
        /// </summary>
        Inbox,

        /// <summary>
        /// The Journal folder.
        /// </summary>
        Journal,

        /// <summary>
        /// The Notes folder.
        /// </summary>
        Notes,

        /// <summary>
        /// The Outbox folder.
        /// </summary>
        Outbox,

        /// <summary>
        /// The Sent Items folder.
        /// </summary>
        SentItems,

        /// <summary>
        /// The Tasks folder.
        /// </summary>
        Tasks,

        /// <summary>
        /// The message folder root.
        /// </summary>
        MsgFolderRoot,

        /// <summary>
        /// The root of the Public Folders hierarchy.
        /// </summary>
        PublicFoldersRoot,

        /// <summary>
        /// The root of the mailbox.
        /// </summary>
        Root,

        /// <summary>
        /// The Junk E-mail folder.
        /// </summary>
        JunkEmail,

        /// <summary>
        /// The Search Folders folder, also known as the Finder folder.
        /// </summary>
        SearchFolders,

        /// <summary>
        /// The Voicemail folder.
        /// </summary>
        VoiceMail,

        /// <summary>
        /// The Dumpster 2.0 root folder.
        /// </summary>
        RecoverableItemsRoot,

        /// <summary>
        /// The Dumpster 2.0 soft deletions folder.
        /// </summary>
        RecoverableItemsDeletions,

        /// <summary>
        /// The Dumpster 2.0 versions folder.
        /// </summary>
        RecoverableItemsVersions,

        /// <summary>
        /// The Dumpster 2.0 hard deletions folder.
        /// </summary>
        RecoverableItemsPurges,

        /// <summary>
        /// The Dumpster 2.0 discovery hold folder
        /// </summary>
        RecoverableItemsDiscoveryHolds,

        /// <summary>
        /// The root of the archive mailbox.
        /// </summary>
        ArchiveRoot,

        /// <summary>
        /// The root of the archive mailbox.
        /// </summary>
        ArchiveInbox,

        /// <summary>
        /// The message folder root in the archive mailbox.
        /// </summary>
        ArchiveMsgFolderRoot,

        /// <summary>
        /// The Deleted Items folder in the archive mailbox
        /// </summary>
        ArchiveDeletedItems,

        /// <summary>
        /// The Dumpster 2.0 root folder in the archive mailbox.
        /// </summary>
        ArchiveRecoverableItemsRoot,

        /// <summary>
        /// The Dumpster 2.0 soft deletions folder in the archive mailbox.
        /// </summary>
        ArchiveRecoverableItemsDeletions,

        /// <summary>
        /// The Dumpster 2.0 versions folder in the archive mailbox.
        /// </summary>
        ArchiveRecoverableItemsVersions,

        /// <summary>
        /// The Dumpster 2.0 hard deletions folder in the archive mailbox.
        /// </summary>
        ArchiveRecoverableItemsPurges,

        /// <summary>
        /// The Dumpster 2.0 discovery hold folder in the archive mailbox.
        /// </summary>
        ArchiveRecoverableItemsDiscoveryHolds,

        /// <summary>
        /// The Sync Issues folder.
        /// </summary>
        SyncIssues,

        /// <summary>
        /// The Conflicts folder
        /// </summary>
        Conflicts,

        /// <summary>
        /// The Local failures folder
        /// </summary>
        LocalFailures,

        /// <summary>
        /// The Server failures folder
        /// </summary>
        ServerFailures,

        /// <summary>
        /// The Recipient Cache folder
        /// </summary>
        RecipientCache,

        /// <summary>
        /// The Quick Contacts folder
        /// </summary>
        QuickContacts,

        /// <summary>
        /// Conversation history folder
        /// </summary>
        ConversationHistory,

        /// <summary>
        /// AdminAuditLogs folder
        /// </summary>
        AdminAuditLogs,

        /// <summary>
        /// search folder
        /// </summary>
        ToDoSearch,

        /// <summary>
        /// MyContacts folder
        /// </summary>
        MyContacts,

        /// <summary>
        /// Directory (GAL)
        /// It is not a mailbox folder. It only indicates any GAL operation.
        /// </summary>
        Directory,

        /// <summary>
        /// IMContactList folder
        /// </summary>
        IMContactList,

        /// <summary>
        /// PeopleConnect folder
        /// </summary>
        PeopleConnect,

        /// <summary>
        /// Favorites folder
        /// </summary>
        Favorites,
}
export enum ContactsStoredType{
    /**
     * database 버전
     */
    DB,
    /**
     * exchage 버전
     */
    EWS
}
export enum PublicSearchConditions{
    UserName,
    UserId
}


    
export enum TWishContainerValueType{
    personCode = 1,
    email = 0,
    deptCode = 2
  }
