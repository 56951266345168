import client from '@/api'

export default {
  /**
   * 대용량 첨부 템플릿을 가져옵니다.
   * @param {string} code 대용량 첨부 그룹 코드
   * @returns template html string
   */
  deleteFiles: function(code: string, codeValues: string[], fileNames: string[], fileIdentifiers?:string[]): Promise<string> {
    return client.post({
      url: '/file/store/delete',
      data: { code, codeValues, fileNames, fileIdentifiers },
    })
  },

  getFiles(code?: string): Promise<appbase.FileInfo[]> {
    if (code) {
      return client.get({
        url: `/file/store/${code}`,
      })
    } else {
      return Promise.resolve([])
    }
  },

  download(code: string, filename: string): void {
    const url = `file/download?code=${code}&filename=${filename}`
    window.open(url)
  },

  downloadCheck(code: string, filename: string): Promise<appbase.DownloadCheckResult> {
    return client.get({
      // url: `/file/download/check?code=${code}&filename=${filename}`,
      url: '/file/download/check',
      params: {
        code: code,
        filename: filename,
      },
    })
  },

  deleteByCode(code: string): Promise<any[]> {
    return client.delete({
      url: `/file/store/${code}`,
    })
  },

  docViewerKeyRequest(payload: any){
    return client.post({
        url: '/workflow/outinterface/api/commonrequest',
        data: payload
    })
  }
}
