import * as types from './types';
import { GetterTree } from 'vuex';

export default <GetterTree<boardModule.state.BoardState, RootState>>{

    [types.COPERATION_BOARD_INFO](state: boardModule.state.BoardState) {
        return state.coperationBoardInfo
    },

    [types.BOARD_LOADDING](state: boardModule.state.BoardState) {
        return state.boardLoading;
    },
    [types.ARTICLE_LOADDING](state: boardModule.state.BoardState) {
        return state.articleLoading;
    },
    [types.BOARD_BASEURL](state: boardModule.state.BoardState) {
        return state.baseUrl;
    },
    [types.POPUP_BASEURL](state: boardModule.state.BoardState) {
        return `${state.baseUrl}${state.popupBaseName}/`;
    },
    [types.POPUP_BASENAME](state: boardModule.state.BoardState) {
        return state.popupBaseName;
    },
    [types.BOARD_CONFIG](state: boardModule.state.BoardState) {
        return state.boardConfig
    },
    [types.FETCH_TOTALARTICLES](state: boardModule.state.BoardState) {
        return state.articleList
    },
    [types.FETCH_ARTICLES](state: boardModule.state.BoardState) {
        return state.articleList
    },
    [types.FETCH_BLOGARTICLES](state: boardModule.state.BoardState) {
        return state.blogArticleList
    },
    [types.FETCH_PAGESET](state: boardModule.state.BoardState) {
        return state.pageSet
    },
    [types.FETCH_CONTENTFIELD](state: boardModule.state.BoardState) {
        return state.contentField
    },
    [types.FETCH_LISTFIELD](state: boardModule.state.BoardState) {
        return state.listField
    },

    [types.Z_HELPDESK_CATEGORIES](state: boardModule.state.BoardState) {
        return state.helpdeskCategories
    },

   
};
