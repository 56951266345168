import api from '@/api'

export default {
    getCheckBookmark(personCode: string, comCode: string, urlPath: string, urlSearch: string, itemId: number){
        return api.get({
            url: '/Common/Gnb/api/bookmark/checkbookmark',
            params:{
                PersonCode: personCode,
                ComCode: comCode,
                UrlPath: urlPath,
                UrlSearch: urlSearch,
                ItemId: itemId
            }
        });
    },
    deleteBookmark(personCode: string, comCode: string, bookMarkId: number){
        return api.get({
            url: '/Common/Gnb/api/bookmark/delete',
            params:{
                PersonCode: personCode,
                ComCode: comCode,
                BookMarkId: bookMarkId
            }
        });
    },
    insertBookmark(personCode: string, comCode: string, itemId: number, menuText: string, menuTextEn: string, itemType: string, itemUrl: string){
        return api.get({
            url: '/Common/Gnb/api/bookmark/simpleinsert',
            params:{
                PersonCode: personCode,
                ComCode: comCode,
                ItemId: itemId,
                MenuText: menuText,
                MenuTextEn: menuTextEn,
                ItemType: itemType,
                ItemUrl: itemUrl
            }
        });
    }
}