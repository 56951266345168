
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import api from '@gnb/api/common'
import CheckSlider from '@gnb/components/Common/CheckSlider.vue'
import { mapActions } from 'vuex'
import * as types from '@gnb/store/types'
import { i18n } from '@appbase/util/i18n-setup'

@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        Dropdown,
        CheckSlider
    },
    methods:{
        ...mapActions({
            loading: types.COMMON_LOADDING
        })
    }

})
export default class RuleView extends Vue {
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    loading!: (option: boolean) => void;
    
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    rules: gnb.rules.Rule[] = [];
    lastIndex: number = 0;
    folders: gnb.FolderItem[] = [];
    tempText: string = '';

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

  
    

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Watch('rules')
    onChangeRuleList() {
        const rules = this.rules;

        let idx = rules.findIndex((r: gnb.rules.Rule) => {
            return r.name === '[EUM] 자동전달';
        });

        // 삭제
        if (rules[idx]) {
            // 추가한 데이터를 삭제할 경우
            this.rules.splice(idx, 1);
        }

        //this.tempRuleList = this.rules;

        this.lastIndex =
            this.rules.filter((rule: gnb.rules.Rule) => {
                return rule.operation !== 'delete';
            })?.length - 1;

        this.$emit('changeRules', rules);
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    changeIsEnable(rule:any){
        this.$emit('changeIsEnable', rule)
    }

    async getName(item:any){
        if(typeof item === 'object' ){
           return await api.getUserInfoByEmail(item[0]).then((res)=>{
                return res.items[0]?.displayName + '(' + item[0] + ')';
            })
        }else{
           return await api.getUserInfoByEmail(item).then((res)=>{
                return res.items[0]?.displayName + '(' + item + ')';
            })
        }
    }

    convertConditions(conditions: gnb.rules.RuleCondition[]) :Promise<string>{
        const _this = this;
        let text: string = '';
        var cnt: number = 0;
        return new Promise(function(resolve,reject){
            conditions.forEach(async (c:gnb.rules.RuleCondition, idx: number, array: Array<any>)=>{
                if(c.key === "ContainsSenderStrings"){
                    var res = await _this.getName(c.value);
                    text += i18n.t('보낸사람이').toString() + res + i18n.t('_인_경우').toString();
                    cnt++;
                }else if(c.key === "SentToAddresses"){
                    let receiverList = await Promise.all(c.value.map((a:any) => {
                        var res = _this.getName(a.address);
                        return res;
                    }));
                    text += i18n.t('받는사람에')+ receiverList.join(', ') + i18n.t('_가_포함된_경우').toString();
                    cnt++;
                }else if(c.key === "ContainsHeaderStrings"){
                    text += i18n.t('헤더에') + c.value + i18n.t('_가_포함된_경우').toString();
                    cnt++;
                }else if(c.key === "ContainsSubjectStrings"){
                    cnt++;
                }else if(c.key === "ContainsBodyStrings"){
                    text += i18n.t('내용에').toString() + c.value + i18n.t('_가_포함된_경우').toString();
                    cnt++;
                }
    
                if(cnt == array.length){
                    resolve(text);
                }
            });
        })
      
    }

 
    convert(rule:gnb.rules.Rule){
        const _this = this;
        let text: string = '';
         this.convertConditions(rule.conditions).then(async (res: string) => {
            text += res;
            if(res){
            let folderName: string = '';
            rule.actions.forEach((a:gnb.rules.RuleAction)=>{
                const getFolderName = (folders: gnb.FolderItem[]) => {
                    folders.forEach((f) => {
                        if (f.id === a.value) {
                            folderName = f.displayName;
                        }
        
                        if (f.children != null) {
                            getFolderName(f.children as gnb.FolderItem[]);
                        }
                    });
                }
        
                getFolderName(_this.folders);
    
                if(a.key === "MoveToFolder"){
                    text += '"' + folderName +'"로 이동';
                }
            })
            }
            this.tempText = text;
            rule.text = this.tempText;
            if(rule.text){
            }
        });
    }
   
        
  

    setRule(item: gnb.rules.Rule) {
        const rules = this.rules;

        let idx = rules.findIndex((r: any) => {
            return r.id === item.id;
        });

        if (item.operation === 'create') {
            // 추가
            if (idx >= 0) {
                // 추가한 데이터를 수정할 경우
                this.rules.splice(idx, 1, item);
            } else {
                this.rules.push(item);
            }
        } else if (item.operation === 'set') {
            // 수정
            this.rules.splice(idx, 1, item);
        } else if (item.operation === 'delete') {
            // 삭제
            if (rules[idx].id.indexOf('temp_') > -1) {
                // 추가한 데이터를 삭제할 경우
                this.rules.splice(idx, 1);
            } else {
                // 기존 데이터를 삭제할 경우
                this.rules.splice(idx, 1, item);
            }
        }

        //this.$emit('setRule', this.rules);
        
    }

    move(from: number, to: number) {
        this.$emit('move', from, to);
        this.rules.splice(to, 0, this.rules.splice(from, 1)[0]);
        return this.rules;
    }

    edit(rule: gnb.rules.Rule) {
       this.$emit('select',rule)
    }

    async deleteRule(item: gnb.rules.Rule) {
        const message = this.$t('M_Settings_Delete_Rule').toString();
        if (await(window as any).confirmAsync(message) == false) {
            return false;
        } else {
            item.operation = 'delete';
            this.setRule(item);
        }
    }
   
    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //
    created(){
       this.loading(true);
        api.getFolder().then((res)=>{
            this.folders = res;
            api.getRules().then((res:gnb.rules.SaveRulesRequest)=>{
                this.loading(false);
                this.rules = res.rules.map((rule:gnb.rules.Rule)=>{
                    rule.text = '';
                    this.convert(rule);
                    return rule;
                });
            });
        });
    }

}
