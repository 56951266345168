import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import { mapActions,mapGetters, mapMutations } from 'vuex';
import * as types from '@gnb/store/types';
import * as accountTypes from '@account/store/types'
import api from '@gnb/api/common'

@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        Dropdown
    },
    computed:{
        ...mapGetters({
            user: accountTypes.USER,
            cardState: types.GET_CARDSTATE,
            userCard: types.GET_CARD
        })
    },
    methods:{
        ...mapActions({
            getCardState: types.GET_CARDSTATE,
            getCardInfo: types.GET_CARD,
            setCardInfo: types.UPDATE_CARD
        }),
        ...mapMutations({
            setUserCard: types.GET_CARD
        })
    }
})
export default class SignatureOptionView extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    tempCardState: gnb.UserBusinessCardState[] = [
        {
            description: this.$t('W_Mail_UserBusinessCardState_0').toString(),
            displayOrder: 0,
            enumString: "Default",
            resourceId: this.$t('W_Mail_Non').toString(),
            usageState: 'Default',
        },
        {
            description: this.$t('W_Mail_UserBusinessCardState_1').toString(),
            displayOrder: 1,
            enumString: "Korean",
            resourceId: this.$t('한글').toString(),
            usageState: "Korean",
        },
        {
            description: this.$t('W_Mail_UserBusinessCardState_2').toString(),
            displayOrder: 2,
            enumString: "English",
            resourceId: this.$t('영문').toString(),
            usageState: "English",
        },
        {
            description: this.$t('W_Mail_UserBusinessCardState_3').toString(),
            displayOrder: 3,
            enumString: "Free",
            resourceId: this.$t('자유').toString(),
            usageState: "Free"
        }

    ];
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    getCardState!: () => Promise<gnb.UserBusinessCardState>;
    getCardInfo!: (personCode: string) => Promise<gnb.UserBusinessCard>;
    setCardInfo!: (item:gnb.UserBusinessCard) => Promise<number>;
    setUserCard!: (item:gnb.UserBusinessCard) => Promise<gnb.UserBusinessCardState>;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    cardState!: gnb.UserBusinessCardState[];
    user!: account.User;
    userCard!: gnb.UserBusinessCard;
    name: string = this.$t('W_Settings_BusinessCardOption').toString();
    
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    item: any = [];
    usageState:string = '';

    userBusinessCardStateList: gnb.UserBusinessCardState[] = [];
    signInfo: gnb.UserBusinessCard = {
        personCode: '',
        companyName: '',
        companyNameEng: '',
        deptName: '',
        deptNameEng: '',
        titleName: '',
        titleNameEng: '',
        roleName: '',
        roleNameEng: '',
        jobGroupName: '',
        jobGroupNameEng: '',
        displayName: '',
        displayNameEng: '',
        homeAddress: '',
        homeAddressEng: '',
        officeTel: '',
        officeFax: '',
        mobileTel: '',
        email: '',
        homePage: '',
        template: '',
        freeContent: '',
        usageState: 'Default',
        autoSign: false,
        replyAutoSign: false,
        officeAddress: '', //2022.08.08
        officeAddressEng: '' //2022.08.08
    };

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Watch('userCard')
    onChangeUserCard(){
        if(this.userCard){
            switch (this.userCard.usageState){
                case '0':
                    this.userCard.usageState = 'Default';
                    this.usageState = 'Default';
                    break;
                case '1':
                    this.userCard.usageState = 'Korean';
                    this.usageState = 'Korean';
                    break;
                case '2':
                    this.userCard.usageState = 'English';
                    this.usageState = 'English';
                    break;
                case '3':
                    this.userCard.usageState = 'Free';
                    this.usageState = 'Free';
                    break;
                case 'Default':
                    this.userCard.usageState = 'Default';
                    this.usageState = 'Default';
                    break;
                case 'Korean':
                    this.userCard.usageState = 'Korean';
                    this.usageState = 'Korean';
                    break;
                case 'English':
                    this.userCard.usageState = 'English';
                    this.usageState = 'English';
                    break;
                case 'Free':
                    this.userCard.usageState = 'Free';
                    this.usageState = 'Free';
                    break;
            }
        }
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    

    select(item:string){
        this.signInfo.usageState = item;
    }

    preview(){

    }

    
    getUserInfo(){
        this.getCardInfo(this.user.personCode).then((res:any)=>{
            //this.signInfo = res;
            if (res) this.signInfo = res
            
            if(this.tempCardState){
                this.item = this.tempCardState.map((c)=>{
                    return {
                        name: c.description,
                        key: c.usageState,
                    }
                })
            }
        });
    }
    
    save(){
        this.tempCardState.map((c:gnb.UserBusinessCardState)=>{
            if(c.key === this.signInfo.usageState){
                this.signInfo.usageState = c.enumString;
            }
        })
        api.setUserBusinessCardState({personCode: this.user.personCode, usageState: this.signInfo.usageState})
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    created() {
        this.getCardState();
        this.getUserInfo();
        this.onChangeUserCard();
    }
}