import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import AttendanceGrid from '@attendance/components/AttendanceList/AttendanceGrid.vue'
import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import AttendanceDetailModalView from '@attendance/views/Modal/AttendanceDetailModalView.vue'
import * as dateutil from '@appbase/util/date-helper'
import api from '@attendance/api/index'

@Component({
  components: { AttendanceGrid, DatePicker, AttendanceDetailModalView }
})
export default class AttendanceListView extends Vue {
  startDate: string = ''
  endDate: string = ''

  list: attendance.AttendanceItem[] = []
  totalCount: number = 0

  pageNum: number = 1
  pageSize: number = 20

  // 판정
  attendanceFilter: number = 0

  // 근무형태
  workingTypeList:stagger.StaggerAttendanceType[] =[]
  attendanceTypeFilter:number = 0

  // 년도 검색
  yearList:number[] = []
  yearFilter:number = 0

  onSearch() {
    this.getList(1)
  }

  getList(pageNum: number) {
    this.pageNum = pageNum
    api.attendance.getAttendaceUserList(this.pageNum, this.pageSize, this.startDate, this.endDate, this.attendanceTypeFilter, this.attendanceFilter).then(res => {
      this.list = res.attendanceList
      this.totalCount = res.totalCount
    })
  }

      
  @Watch('yearFilter')
  changeFilter(val:number){
      if(val>0){
      this.startDate = dateutil.uGetDateFormat('yyyy-MM-dd',new Date(val,0,1));
      this.endDate = dateutil.uGetDateFormat('yyyy-MM-dd',new Date(val,11,31));
      }
      else
      this.dateReset()
      this.getList(1)
  }
  
  dateReset(){
    let date = new Date();
    date.setDate(date.getDate() - 30);
    this.startDate = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(date))
    this.endDate = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(new Date()))
  }

  mounted(){
    this.dateReset()
    this.getList(this.pageNum)
  }

  created() {
    api.stagger.getStaggerAttendanceTypeList().then(res=>{
      this.workingTypeList = res
    })
    let _year = new Date().getFullYear()
    for(var i =2022;i<=_year+1;i++ ){
        this.yearList.push(i)
    }
    this.setPage({title:this.$t('근무현황조회')});
  }
}