import { FlowFile } from '@appbase/libs/flowjs'

export type ConvertToFlowFileInfo = {
  /** 파일 이름 */
  name: string
  /** 파일 크기 (byte) */
  size: number
  uniqueIdentifier: string
  type: string
}

/**
 * Flow에서 동작하기 위한 최소한의 구성
 */
const initialFlowFileInfo = {
  abort: () => {},
  percent: 100,
  status: 'succeed',
  selected: false
}

const flowHelper = {
  readablizeBytes(bytes: number, digits: number = 2) {
    var s = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
    var e = Math.floor(Math.log(bytes) / Math.log(1024))
    return (bytes / Math.pow(1024, e)).toFixed(digits) + ' ' + s[e]
  },
  secondsToStr(time: number) {
    function numberEnding(value: number) {
      return value > 1 ? 's' : ''
    }
    var years = Math.floor(time / 31536000)
    if (years) return years + ' year' + numberEnding(years)

    var days = Math.floor((time %= 31536000) / 86400)
    if (days) return days + ' day' + numberEnding(days)

    var hours = Math.floor((time %= 86400) / 3600)
    if (hours) return hours + ' hour' + numberEnding(hours)

    var minutes = Math.floor((time %= 3600) / 60)
    if (minutes) return minutes + ' minute' + numberEnding(minutes)

    var seconds = time % 60
    return seconds + ' second' + numberEnding(seconds)
  },

  /**
   * 직접 첨부한 파일이 아닌 서버에서 파일목록을 가져올 때 기본적으로
   * 가져야하는 속성을 포함하여 FlowFile를 만들어냅니다.
   * @param data
   * @returns instance of FlowFile
   */
  convertToFlowFile(data: ConvertToFlowFileInfo): FlowFile {
    //name, size
    // abort() 목록에서 삭제 시 호출되는 함수
    return {
      ...Object.create(null),
      ...initialFlowFileInfo,
      ...data,
    }
  },
}

export default flowHelper
