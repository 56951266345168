import hr from './hr'
import personal from './personal'
import local from './local'
import publics from './public'
import group from './group'
import favorite from './favorite'
import account from './account'
import auth from './auth'

export default {
  auth,
  account,
  hr,
  personal,
  local,
  publics,
  group,
  favorite,
}
