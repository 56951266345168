
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'
import * as types from '../../store/types'

@Component({
    computed: {
        ...mapGetters({
            modal: types.MODAL_CURRENT,
        }),
    },
})
export default class BaseLayout extends Vue {
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    modal!: appbase.TModalInfo<any> | null
}
