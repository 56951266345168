import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ModalLayout } from '@appbase/templates'
import * as attHelper from '@/util/attendance-helper'

@Component({
    components: {
        ModalLayout,
    },
})
export default class AttendanceWeekListModalView extends Vue {
    @Prop({ type: Array }) list!: attendance.AttendanceItem[]

    modalStyle:string = 'width:1000px;height:300px'
    modalTitle:string = ''

    close(){
        this.$emit('close');
    }

    setDisplayDate(date: Date){
        return attHelper.uSetDisplayDate(date);
    }

    setDisplayTime(date: Date | null){
        if (date) {
            return attHelper.uSetDisplayTime(date);
        } else {
            return null;
        }
    }

    // Working Time 설정
    setDisplayWorkingTime(workingTime: any | null, hrTime: any | null){
        return attHelper.uSetDisplayWorkingTime(workingTime, hrTime);
    }

    setDisplayAttendanceResult(result: number | null){
        return attHelper.uDisplayAttendanceResult(result);
    }

    @Watch("list")
    setModalTitle(){
        if (this.list.length > 0){
            this.modalTitle = this.$t('주간근무현황').toString() + ' (' + this.list[0].displayName + ')'; 
        }
        else{
            this.modalTitle = "";
        }
    }
}