
import Vue from 'vue'
import Component from 'vue-class-component'

type ComponentOptions = {
    component: any
    vmodel: any
    vbind: { [key: string]: any }
    vevent: { [key: string]: Function }
    /**
     * @deprecated
     */
    value: any
    canbind: boolean
}

@Component({
    name: 'eum-modal',
})
export default class EumModal extends Vue implements appbase.IModalOpenable {
    show: boolean = false
    options: ComponentOptions = Object.create(null)

    openModal(options: appbase.TModalInfo<any>) {
        const { 'v-model': vmodel, ...props } = options.props || {}
        const events = options?.events || {}
        // value는 deprecated 되었기 때문에, value에 값이 할당된 경우
        // 예전 버전에서 개발된 모달로 보고 props와 event를 바인딩하지 않도록 함.
        const canBind = options?.value ? false : true
        this.options = {
            component: options?.component,
            vmodel: vmodel,
            vbind: props,
            vevent: events,
            value: options?.value,
            canbind: canBind,
        }
        this.show = true
        console.log('show', 'eum-modal-container', this.options)
    }

    closeModal(key?: string) {
        this.show = false
        console.log('close', 'eum-modal-container', this.options)
    }
}
