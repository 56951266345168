import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import api from '@gnb/api/common'
import CheckSlider from '@gnb/components/Common/CheckSlider.vue'
import { mapActions } from 'vuex'
import * as types from '@gnb/store/types'
import RecipientField from '@gnb/components/Common/RecipientField.vue';

interface MemberList {
    personCode: String;
    comCode: String;
    displayName: String;
    deptName: String;
    titleName: String;
    email: String;
}
@Component({
    components:{
        CheckSlider,
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        Dropdown,
        RecipientField
    },
    methods:{
        ...mapActions({
            loading: types.COMMON_LOADDING
        })
    },
})
export default class AutoDeliveryView extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    ruleList: gnb.rules.Rule[] = [];
    member: String = '';
    tempList: MemberList[] = [];
    autoDelivery: gnb.rules.Rule = {
        id: '',
        name: '[EUM] 자동전달',
        priority: 1,
        isEnabled: false,
        operation: 'create',
        conditions: [],
        actions: [],
    };
    TempName: orgmap.UserItem[] = [];
    TempEmail: orgmap.UserItem[] = [];
    requiredRecipients: orgmap.UserItem[] = [];
    label:string = this.$t('자동_전달_사용').toString();
    placeholder:string = this.$t('자동전달_대상을_입력하세요').toString();

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    loading!: (option: boolean) => void;
    
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Watch('requiredRecipients')
    onChangedRequiredRecipients(){
        if(this.requiredRecipients){
            this.TempEmail =    this.requiredRecipients.map((r:any)=>{
                return r.email;
             });
            this.member = this.TempEmail.toString();
            if(this.member){
                this.autoDelivery.actions = [
                    {
                        key: 'RedirectToRecipients',
                        value: this.TempEmail
                    },
                ];
            }
        }else{
            this.member = '';
        }
    }

    @Watch('autoDelivery.isEnabled')
    onChangedCheckedUseRedirect() {
        if (this.autoDelivery) {
            if (this.autoDelivery.isEnabled) {
                api.getRules().then(()=>{
                });  
            } else {
                this.tempList = [];
                this.autoDelivery.actions = [];
            }
        }
    }

    @Watch('tempList')
    onChangedTempList() {
        this.autoDelivery.actions = [
            {
                key: 'RedirectToRecipients',
                value: this.tempList.map((i) => {
                    return i.email;
                }),
            },
        ];
    }

    @Watch('ruleList')
    onChanged() {
        let autoDelivery = this.ruleList.find((r) => {
            return r.name === '[EUM] 자동전달';
        });

        if (autoDelivery) {
            this.autoDelivery = autoDelivery;
        }

        if (this.autoDelivery) {
            if (this.autoDelivery.isEnabled) {
              
                let emailList = this.autoDelivery.actions.find((action: gnb.rules.RuleAction) => {
                    return action.key === 'RedirectToRecipients';
                });

                if (emailList) {
                    emailList.value.forEach((v:any) => {
                        if(v){
                            let member: any = [];
                            api.getUserInfoByEmail(v).then((res:any)=>{
                                if(res){
                                    member.push(res.items);
                                }
                            })
                        }
                    });
                }
            } 
        }
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    
    onChange(){
           this.autoDelivery.isEnabled = !this.autoDelivery.isEnabled;
    }

    async save() {
        if(this.autoDelivery.isEnabled && this.requiredRecipients.length == 0){
            await (window as any).alertAsync('자동전달할 사용자를 선택해주세요.');
            return false;
        }else{
            // 받는 사람 규칙이 있는 경우 value를 string 배열로 변경 필요
            this.ruleList.map(r => {
                if(r.actions.findIndex((c:gnb.rules.RuleAction)=> c.key === 'RedirectToRecipients')<0) {return r;}
                else {
                    var rule = r;
                    rule.actions = r.actions.map((action:gnb.rules.RuleAction) => {
                        if (action.key === 'RedirectToRecipients') {
                            return {
                                ...action,
                                value: action.value.map((v:any)=>{
                                    return v
                                }),
                            }
                        }
                        else {
                            return action;
                        }
                    })
                    return rule;
                }
            })

            this.ruleList.map(r => {
                if(r.conditions.findIndex((c:gnb.rules.RuleCondition)=> c.key === 'SentToAddresses')<0) {return r;}
                else {
                    var rule = r;
                    rule.conditions = r.conditions.map((condition:gnb.rules.RuleCondition) => {
                        if (condition.key === 'SentToAddresses') {
                            return {
                                ...condition,
                                value: condition.value.map((v:any)=>{
                                    return v.address
                                }),
                            }
                        }
                        else {
                            return condition;
                        }
                    })
                }return rule;
            });

            if (this.autoDelivery.isEnabled) {
                if (
                    this.autoDelivery.actions.length === 0 ||
                    this.autoDelivery.actions[0].value.length === 0
                ) {
                    await (window as any).alertAsync(this.$t('M_Settings_Select_User'));
                    return false;
                }
            }

            if (this.autoDelivery.id) {
                if (this.autoDelivery.isEnabled) {
                    this.autoDelivery.operation = 'set';

                    let idx = this.ruleList.findIndex((r:gnb.rules.Rule) => {
                        return r.id === this.autoDelivery.id;
                    });
                    
                

                    if (idx == -1) {
                        this.autoDelivery.id = '';
                        this.autoDelivery.operation = 'create';
                        this.ruleList.push(this.autoDelivery);
                    } else {
                        this.ruleList.splice(idx, 1, this.autoDelivery);
                        this.ruleList.forEach((r:gnb.rules.Rule, index:number) => {
                            if (r.name == '[EUM] 자동전달' && index != idx) {
                                r.actions = [];
                                r.operation = 'delete';
                            }
                        });
                    }
                    api.setRules(this.ruleList).then(async (succeed:boolean) => {
                        if (succeed) {

                            await (window as any).alertAsync(this.$t('M_Settings_Msg39'));
                            api.getRules().then((res:gnb.rules.SaveRulesRequest)=>{
                                this.ruleList = res.rules;
                                this.$emit('cancel');
                            });
                        }else{
                            await (window as any).alertAsync('저장되지않았습니다. 규칙설정을 확인해주세요.')
                        }
                    });
                } else {
                    //삭제
                    const message = this.$t(
                        'M_Settings_OffFoward'
                    ).toString();
                    if (await(window as any).confirmAsync(message) == false) {
                        return false;
                    } else {
                        if (this.autoDelivery.id) {
                            this.autoDelivery.operation = 'delete';

                            let idx = this.ruleList.findIndex((r:gnb.rules.Rule) => {
                                return r.id === this.autoDelivery.id;
                            });

                            if (idx == -1) {
                                
                            } else {
                                this.ruleList.splice(idx, 1, this.autoDelivery);
                                this.ruleList.forEach((r:gnb.rules.Rule, index:number) => {
                                    if (r.name == '[EUM] 자동전달' && index != idx) {
                                        r.actions = [];
                                        r.operation = 'delete';
                                    }
                                });
                            }

                            api.setRules(this.ruleList).then(async (succeed:boolean) => {
                                if (succeed) {
                                    this.member = '';
                                    this.requiredRecipients = [];
                                    await (window as any).alertAsync(this.$t('M_Settings_Released'));
                                    api.getRules().then((res:gnb.rules.SaveRulesRequest)=>{
                                        this.ruleList = res.rules;
                                        this.$emit('cancel');
                                    });
                                }else{
                                    await (window as any).alertAsync('저장되지않았습니다. 규칙설정을 확인해주세요.');
                                }
                            }).catch(async (err)=> {await (window as any).alertAsync('문제가 발생했습니다.')});
                        } else {
                            await (window as any).alertAsync(this.$t('M_Settings_NoFowrading'));
                        }
                    }
                }
            } else {
                if (this.autoDelivery.isEnabled) {
                    this.ruleList.push(this.autoDelivery);
                    api.setRules(this.ruleList).then(async (succeed:boolean) => {
                        if (succeed) {
                            await (window as any).alertAsync(this.$t('M_Settings_Msg39'));
                            api.getRules().then((res:gnb.rules.SaveRulesRequest)=>{
                                this.ruleList = res.rules;
                            });
                        }
                    });
                } else {
                    await (window as any).alertAsync(this.$t('M_Settings_NoFowrading'));
                }
            }
        }
       
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //


    created(){
       // createEventBus(window, ORGLIST_ORG_POPUP, this.handlePopupResult);

        api.getRules().then(async (res:gnb.rules.SaveRulesRequest)=>{
            this.ruleList = res.rules;
            if(this.ruleList){
                let autoDelivery = this.ruleList.find((r) => {
                    return r.name === '[EUM] 자동전달';
                });
                if(autoDelivery){
                    let actions = autoDelivery.actions.find((r:any) => { 
                        return r.key === 'RedirectToRecipients'
                    });
                    this.requiredRecipients = await Promise.all(
                        actions.value.map((a:any) => { 
                            return this.getUserItem(a.address);    
                        })
                    );
                }

                this.loading(false);
            }
        });
    }

    async getUserItem(email:string){
        return await api.getUserInfoByEmail(email).then((res)=>{
            return <orgmap.UserItem>{
                displayName: res.items[0]?.displayName,
                titleName: res.items[0]?.titleName,
                deptName: res.items[0]?.deptName,
                email: res.items[0]?.email  
            }   
        });
    }
}