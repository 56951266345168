import { FlowChunk, FlowFile } from '.'
import { IFlow } from './interface'

export const FlowEventNames: string[] = [
  'fileSuccess',
  'fileProgress',
  'fileAdded',
  'filesAdded',
  'filesAdding',
  'filesSubmitted',
  'fileRemoved',
  'fileRetry',
  'fileError',
  'uploadStart',
  'complete',
  'progress',
  'error',
  'catchAll',
]

export interface IFlowFileHandler {
  /**
   * A specific file was completed. First argument file is instance of FlowFile,
   * second argument message contains server response. Response is always a string.
   * Third argument chunk is instance of FlowChunk.
   * You can get response status by accessing xhr object chunk.xhr.status.
   */
  fileSuccess?: (file: FlowFile, message: string, chunk: FlowChunk) => void

  /**
   * Uploading progressed for a specific file.
   */
  fileProgress?: (file: FlowFile, chunk: FlowChunk) => void

  /**
   * This event is used for file validation. To reject this file return false.
   * This event is also called before file is added to upload queue,
   * this means that calling flow.upload() function will not start current file upload.
   * Optionally, you can use the browser event object from when the file was added.
   */
  fileAdded?: (file: FlowFile, event: Event) => boolean

  /**
   * @returns false: cancel adding
   */
  filesAdding?: (fileList: FlowFile[], event: Event) => boolean

  /**
   * Same as fileAdded, but used for multiple file validation.
   */
  filesAdded?: (array: FlowFile[], event: Event) => boolean

  /**
   * Same as filesAdded, but happens after the file is added to upload queue. Can be used to start upload of currently added files.
   */
  filesSubmitted?: (array: FlowFile[], event: Event) => void

  /**
   * The specific file was removed from the upload queue. Combined with filesSubmitted, can be used to notify UI to update its state to match the upload queue.
   */
  fileRemoved?: (file: FlowFile) => void

  /**
   * Something went wrong during upload of a specific file, uploading is being retried.
   */
  fileRetry?: (file: FlowFile, chunk: FlowChunk) => void

  /**
   * An error occurred during upload of a specific file.
   */
  fileError?: (file: FlowFile, message: string, chunk: FlowChunk) => void

  /**
   * Upload has been started on the Flow object.
   */
  uploadStart?: () => void

  /**
   * Uploading completed.
   */
  complete?: () => void

  /**
   * Uploading progress.
   */
  progress?: () => void

  /**
   * An error, including fileError, occurred.
   */
  error?: (message: string, file: FlowFile, chunk: FlowChunk) => void

  /**
   * Listen to all the events listed above with the same callback function.
   */
  catchAll?: (event: Event, ...args: any[]) => void
}

export interface IFlowContextFileHandler<TContext> extends IFlowFileHandler {
  context: TContext
}
