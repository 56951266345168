import { CalendarEventDetail } from './type'

export const skeletonEvents: Array<CalendarEventDetail> = [
  {
    title: 'ㅁㅁㅁㅁㅁㅁ',
    color: { hasColor: false, code: '0', category: '' },
    isAllDay: false,
    scheduleId: '',
    place: 'ㅁㅁㅁㅁㅁㅁㅁㅁ',
  },
  {
    title: 'ㅁㅁㅁㅁㅁㅁ',
    color: { hasColor: false, code: '0', category: '' },
    isAllDay: false,
    scheduleId: '',
    place: 'ㅁㅁㅁㅁㅁㅁㅁㅁ',
  },
  {
    title: 'ㅁㅁㅁㅁㅁㅁ',
    color: { hasColor: false, code: '0', category: '' },
    isAllDay: false,
    scheduleId: '',
    place: 'ㅁㅁㅁㅁㅁㅁㅁㅁ',
  },

]
