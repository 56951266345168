<template>
  <!-- <div id="wrapper"> -->

  <!-- <div style="width: 300px; height: 200px;"> -->
    <!-- <UserInfoWebpart /> -->
    <CalendarWebpart :option="webPartItem"/>
  <!-- </div> -->
</template>

<script>

import CalendarWebpart from '../CalendarWebpart/CalendarWebpart.vue'
export default {
  components: { CalendarWebpart },
  data() {
    return {
      webPartItem: {
      displayCount: 5,
      tabs:[
        {
          boardId:22,
          tabTitle: "1번게시판"
        },
        {
          boardId:23,
          tabTitle: "2번게시판"
        },
      ]
      }
    }
  }
}

</script>

<style></style>
