
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// initial state
const state: survey.state.SurveyState = {
    permissions : Object.create(null),
    listGrid : Object.create(null),
    createdSurveyId : Object.create(null),
    createdQuestions : [],
    answers : [],
    statisticsDetail : Object.create(null),
    listTotalCount : 0
};

export default {
    state,
    actions,
    getters,
    mutations,
};
