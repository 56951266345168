import Vue from 'vue';
import { Component } from 'vue-property-decorator';
// components
import MobileContainer from '../containers/MobileContainer.vue';

@Component({
    name: 'MobileView',
    components: { MobileContainer },
})
export default class MobileView extends Vue {
    selectItem(item: any) {
        this.$emit('selectItem', item);
    }
}
