
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { LoadingBar } from '../../components/loading'
import modalHelper from '../../util/modal-helper'

type ComponentOptions = {
    component: any
    vmodel: any
    vbind: { [key: string]: any }
    vevent: { [key: string]: Function }
    /**
     * @deprecated
     */
    value: any
    canbind: boolean
}

const DEFAULT_STYLE = 'width: 800px; height: 400px;'

@Component({
    components: { LoadingBar },
})
export default class ModalLayout extends Vue{
    @Prop({ type: String, default: '' }) title!: string
    @Prop({ type: Boolean, default: false }) loading!: boolean
    @Prop({ type: Boolean, default: false }) full!: boolean
    @Prop({ type: String }) loadingMessage!: string
    @Prop({ type: String, default: DEFAULT_STYLE }) mStyle!: string
    @Prop({ type: Boolean, default: false }) isEmit!: boolean
    @Prop({ type: Boolean, default: false }) noFooter!: boolean

    handleClose() {
        this.$emit('close')
        modalHelper.close()
    }
}
