import api from '@/api'
import axios from 'axios'
 
export default {
  // 근무시간 설정 사용자 목록 조회
  getStaggerUserList(pageNum: number, pageSize: number, startDate: string, endDate: string, attendanceTypeFilter:number) : Promise<stagger.ResponseStaggerList>{
    return api.get({
        url: `/api/z/stagger/userlist?pageNum=${pageNum}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&attendanceTypeFilter=${attendanceTypeFilter}`
      });
  },

  // 근무시간 설정 상세 조회
  getStaggerDetail(StaggerId: number): Promise<stagger.StaggerItem>{
    return api.get({
      url: `/api/z/stagger/detail?StaggerId=${StaggerId}`
    });
  },

  // 근무시간 설정 중복 체크
  getStaggerCheckDate(personCode: string, staggeredId: number, attendanceStartDate: string, attendanceEndDate: string): Promise<number>{
    return api.get({
      url: `/api/z/stagger/checkdate?personCode=${personCode}&staggeredId=${staggeredId}&attendanceStartDate=${attendanceStartDate}&attendanceEndDate=${attendanceEndDate}`
    })
  },

  // 근무시간 설정 여부 확인
  getStaggerRegistCheck(personCode: string): Promise<number>{
    return api.get({
      url: `/api/z/stagger/StaggerRegistCheck?personCode=${personCode}`
    })
  },
  
  createUpdateStagger(data: stagger.StaggerItemRequest): Promise<number>{
    return api.post({
      url: `/api/z/stagger/create`,
      data: data
    })
  },
  
  // 관리자 목록 조회
  getStaggerList(pageNum: number, pageSize: number, startDate: string, endDate: string, searchFilter: number, searchValue: string | null, attendanceTypeFilter:number) : Promise<stagger.ResponseStaggerList>{
    return api.get({
        url: `/api/z/stagger/list?pageNum=${pageNum}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&searchFilter=${searchFilter}&searchValue=${searchValue}&attendanceTypeFilter=${attendanceTypeFilter}`
      });
  },

  // 근무시간 삭제
  deleteStagger(personCode: string, staggeredId: number): Promise<number>{
    return api.get({
      url: `/api/z/stagger/DeleteStagger?personCode=${personCode}&staggeredId=${staggeredId}`
    })
  },

  downloadListExcel(startDate: string, endDate: string, searchFilter: number, searchValue: string | null, attendanceTypeFilter:number) {
    const url = `/api/z/stagger/download?startDate=${startDate}&endDate=${endDate}&searchFilter=${searchFilter}&searchValue=${searchValue}&attendanceTypeFilter=${attendanceTypeFilter}`
    return axios({
      method: "get",
      url: api.invalidateUrl(url),
      responseType: "arraybuffer",
    }).then(function (response) {
      console.log("response", response);
      const disposition = response.headers["content-disposition"];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      var fileName = "";
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
        if (fileName) {
          fileName = decodeURIComponent(fileName);
        }
      }
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      //IE 경우
      const navigator = window.navigator as any;
      if (window.navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}`;
        link.click();
        setTimeout(() => link.remove(), 100);
      }
    });
  },

  uploadExcelTemplate(fileform:FormData, successfn:Function, errorfn:Function, params?:any){
    const url = `/api/z/stagger/uploadtemplate`;
    return axios({
      method: "post",
      url: api.invalidateUrl(url),
      data: fileform,
    });
  },

  //근무종류 목록 조회
  getStaggerAttendanceTypeList():Promise<stagger.StaggerAttendanceType[]>{
    return api.get({
      url: `/api/z/stagger/typeList`
    })
  }
}