import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import { mapActions, mapGetters} from 'vuex'
import {MailApproval, MessageApproval} from '@gnb/components/Preference/Approval/Reception'
import * as types from '@gnb/store/types'
@Component({
    components:{
      ModalHeader,
      ModalContent,
      ModalFooter,
      ContentItem,
      PreferenceContentTemplate,
      MailApproval,
      MessageApproval
    },
    methods: {
      ...mapActions({
        load: types.SETTINGS_MYSETTING_LOAD,
        loading: types.COMMON_LOADDING
      }),
    },
    computed: {
      ...mapGetters({
        isLoading: types.COMMON_LOADDING
      })
    }
})
export default class Reception extends Vue {

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  settingInfo: gnb.TA_MYSETTING = {} as gnb.TA_MYSETTING;
  header: string = this.$t('수신_관리').toString();

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  get contentComponents() {
    return [MailApproval, MessageApproval]
  }
  isLoading !: boolean;

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: A C T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  loading!: (option: boolean) => void;
  load!: () => Promise<gnb.CM_MySetting>;
  
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  handleSave() {
    const contens = this.$refs.contents as any[];
    contens.forEach((x) => {
      x.save();
    })
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //
    created(){
      this.loading(true);
      this.load().then((res)=>{
          this.settingInfo = res.mySetting;
          if(this.settingInfo){
            this.loading(false);
          }
      });
      
    }
}