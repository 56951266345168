import Vue from 'vue'
import { Component, Mixins } from 'vue-property-decorator'
import ZinusBoardBase, { TabData } from '../Base/ZinusBoardBase'
import api from '@webparts/api'
import { mapGetters } from 'vuex';
import * as usertypes from '@account/store/types';

@Component({
    computed: {
        ...mapGetters({
            user: usertypes.USER
        })
    },
})
export default class ZinusCompanyNews extends Mixins(ZinusBoardBase) {

    user!: account.TUser
    //myCoperations : Array<webparts.CoperationBoard> = []

    created() {
        this.initSkeleton(true)
        this.getComNotiWebpartData();
        
        // api.webPart.findCoperationBoardInfo().then(result => {
              
        //     //      this.myCoperations = result
        //     //탭정보 초기화 해야 됨
        //     //2022-07-04 
        //     //CEO 부서인 두사용자에 대해서 예외처리 함
        //     let personCode = this.user.personCode

        //     if(personCode.toLowerCase() == "c02_alan.shim"){
        //         personCode = "C021_alan.shim"
        //     }else if (personCode.toLowerCase() == "c02_d.jensen"){
        //         personCode = "C031_d.jensen";
        //     }

        //     let myCoperation = result.find(co => {
        //         return co.coperationCode == personCode.split("_")[0].replace('C', 'D');
        //     })
            
        //     if (myCoperation) {
        //         //기존 데이터 초기화 
        //         this.values = []
        //         this.values.push({ isShow: true, tabNo: 0, boardId: myCoperation?.boardId, tabName: this.$t("COMPANY_NOTICE").toString(), articles: [] })
        //     }

        //     //여기서 표시 대상이 아니면 제외 함
        //     this.values = this.values.filter((item: TabData) => {
        //         return item.isShow == true
        //     })

        //     this.initArticleData()
        // }) 
    }
}
