import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import PageNavigationBar from '@appbase/components/Paginate/PageNavigationBar.vue'
import api from '@attendance/api'
import * as dateutil from '@appbase/util/date-helper'

@Component({
    components: {
        PageNavigationBar
    },
})

export default class AttendanceStaggerGrid extends Vue {
    @Prop({ type: Array }) list!: stagger.StaggerItem[]
    @Prop({ type: Number }) totalCount!: number
    @Prop({ type: Number }) pageNum!: number
    @Prop({ type: Number }) pageSize!: number

    pageRange: number = 5

    setDisplayDate(date: Date, dateFormat: string){
        if (date === null) return "";

        var displayDate = new Date(date.toString());
        return dateutil.uGetDateFormat(dateFormat, displayDate);
    }

    setDisplayTime(date: Date | null){
        if (date) {
            var displayDate = new Date(date.toString());
            return dateutil.uGetDateFormat('H:i:s', displayDate);
        } else {
            return null;
        }
    }

    setDisplayHours(hours: number | null){
        if (hours) {
            return (hours.toString().length == 1 ? '0' : '') + hours + ':00';
        } else {
            return null;
        }
    }

    pageMove(pageNum : number){
        this.$emit('pageMove', pageNum)
    }
}
