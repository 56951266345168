import { ActionTree } from 'vuex'
import api from '../api'
import { ContactsStoredType } from '@orgmap/types/enums'
import { OrgMemberListMutationPayload } from './mutations'
import * as types from './types'
import Vue from 'vue'

export interface OrgMemberListActionPayload {
  comCode: string
  deptCode: string
}

export default <ActionTree<orgmap.state.OrgmapState, RootState>>{
  //
  // ──────────────────────────────────────────── I ──────────
  //   :::::: H R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────
  //

  async [types.ORG_MEMBER_LIST](action, payload: OrgMemberListActionPayload) {
    const { comCode, deptCode } = payload
    action.commit(types.ORG_MEMBER_SELECT_ALL, false)
    const { items, totalCount } = await api.hr.getEmployeeList({ deptCode, comCode })
    const members = items.map<orgmap.TMemberItem>((x) => {
      return {
        id: x.personCode,
        name: x.displayName,
        nameEn: x.displayNameEng || '',
        deptCode: x.deptCode,
        deptName: x.deptName,
        deptNameEn: x.deptNameEn || '',
        photo: x.photoPath,
        titleCode: x.gradeCode,
        title: x.titleName || '',
        titleEn: x.titleNameEn || '',
        positionCode: x.positionCode,
        positionName: x.positionName,
        positionNameEn: x.positionNameEn,
        roleCode: x.roleCode,
        roleName: x.roleName,
        roleNameEn: x.roleNameEn,
        gradeCode: x.gradeCode,
        gradeName: x.gradeName,
        gradeNameEn: x.gradeNameEn,
        email: x.email,
        mobile: x.mobileTel || '',
        tel: x.officeTel || '',
        officeFax: x.officeFax || '',
        company: x.companyName || '',
        companyEn: x.companyNameEng || '',
        personCode: x.personCode,
        selected: false,
        employeeNumber: x.employeeNumber || ''
      }
    })
    action.commit(types.ORG_MEMBER_LIST, {
      items: members,
      totalCount,
      selectedAll: false,
    } as OrgMemberListMutationPayload)
  },

  async [types.ORG_MEMBER_SEARCH](
    action,
    payload: { option: orgmap.SearchOptions; comCode: string }
  ) {
    const { items, totalCount } = await api.hr.searchOrg(payload.option, payload.comCode)
    const members = items.map<orgmap.TMemberItem>((x) => {
      return {
        id: x.id,
        name: x.displayName,
        nameEn: x.displayNameEng,
        deptCode: x.deptCode,
        deptName: x.deptName,
        deptNameEn: x.deptNameEn || '',
        photo: x.photoPath,
        titleCode: x.titleCode,
        title: x.titleName || '',
        titleEn: x.titleNameEn || '',
        positionCode: x.positionCode,
        positionName: x.positionName,
        positionNameEn: x.positionNameEn,
        roleCode: x.roleCode,
        roleName: x.roleName,
        roleNameEn: x.roleNameEn,
        email: x.email,
        mobile: x.mobileTel || '',
        tel: x.officeTel || '',
        officeFax: x.officeFax || '',
        company: x.companyName || '',
        companyEn: x.companyNameEng || '',
        personCode: x.id,
        selected: false,
      }
    })
    action.commit(types.ORG_MEMBER_LIST, {
      items: members,
      totalCount,
      selectedAll: false,
    } as OrgMemberListMutationPayload)
  },

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: P U B L I C : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //

  async [types.ORG_PUBLIC_FOLDER_LIST](action, payload: orgmap.DLGroupApiOption) {
    return await api.publics.getPublicContactsList(payload).then((res) => res)
  },

  async [types.ORG_PUBLIC_MEMBER_LIST](action, payload: orgmap.DLGroupApiOption) {
    action.commit(types.ORG_MEMBER_SELECT_ALL, false)
    const { items, totalCount } = await api.publics.getPublicContactsMembersList(payload)
    const members = items.map<orgmap.TMemberItem>((x: any) => {
      return {
        id: x.id,
        name: x.displayName,
        nameEn: x.displayNameEng,
        deptCode: x.deptCode,
        deptName: x.deptName,
        deptNameEn: x.deptNameEn || '',
        photo: x.photoPath,
        title: x.titleName,
        titleEn: x.titleNameEn || '',
        email: x.email,
        mobile: x.mobileTel || '',
        tel: x.officeTel || '',
        company: x.companyName || '',
        personCode: x.identity,
        selected: false,
      }
    })

    action.commit(types.ORG_MEMBER_LIST, {
      items: members,
      totalCount,
      selectedAll: false,
    } as OrgMemberListMutationPayload)
  },

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: P E R S O N A L : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //

  async [types.ORG_PERSONAL_FOLDER_LIST](action) {
    var funcArr = [
      api.personal.getBasicContactFolder(),
      api.personal.getContactTree(),
      api.local.getContactTree(),
    ]
    return await Promise.all(funcArr).then((res: Array<orgmap.PersonalFolder[]>) => {
      return res.flat()
    })
  },

  async [types.ORG_PERSONAL_MEMBER_LIST](
    action,
    payload: { data: orgmap.api.DynamicApiOptions; type: ContactsStoredType }
  ) {
    const { data, type } = payload
    action.commit(types.ORG_MEMBER_SELECT_ALL, false)
    const { items, totalCount } = await api.personal.getContactsList(data, type)
    const members = items.map<orgmap.TMemberItem>((x: any) => {
      return {
        id: x.id,
        name: x.displayName,
        nameEn: x.displayNameEng,
        deptCode: x.department,
        deptName: x.department,
        deptNameEn: x.department,
        photo: x.photoPath,
        title: x.jobTitle,
        titleEn: x.jobTitle,
        email: x.email,
        mobile: x.mobileTel || '',
        tel: x.officeTel || '',
        company: x.companyName || '',
        personCode: '',
        selected: false,
        type: x.itemClass || x.type,
      }
    })

    action.commit(types.ORG_MEMBER_LIST, {
      items: members,
      totalCount,
      selectedAll: false,
    } as OrgMemberListMutationPayload)

    members.forEach((x) => {
      if (['IPM.DistList', 'G'].includes(x.type || '')) {
        action.dispatch(types.ORG_PERSONAL_GROUPMEMBER_LIST, { item: x, type: type })
      }
    })
  },

  async [types.ORG_PERSONAL_GROUPMEMBER_LIST](
    action,
    payload: { item: orgmap.TMemberItem; type: ContactsStoredType }
  ) {
    const { item, type } = payload
    const { items, totalCount } = await api.group.getGroupMembers(item.id, type)
    const children = items.map<orgmap.TMemberItem>((x: any) => {
      return {
        id: x.id,
        name: x.displayName,
        nameEn: x.displayNameEng,
        deptCode: x.department,
        deptName: x.department,
        deptNameEn: x.department,
        photo: x.photoPath,
        title: x.jobTitle,
        titleEn: x.jobTitle,
        email: x.email,
        mobile: x.mobileTel || '',
        tel: x.officeTel || '',
        company: x.companyName || '',
        personCode: x.targetPersonCode || '',
        selected: false,
        type: x.type,
      }
    })

    const found = action.state.members.items.find((x) => x.id === item.id)
    if (found) {
      found.children = children
    }
  },
}
