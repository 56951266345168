import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as types from '@board/store/types'
import * as accountTypes from '@account/store/types'
import { mapGetters, mapActions } from 'vuex';
import { cache, getLangProperty } from '@appbase/util';
import * as dateutil from '@appbase/util/date-helper';
import CheckBox from '@board/components/common/CheckBox.vue'
import api from '@board/api'
import PasswordCheckComment from '@board/components/common/PasswordCheckComment.vue'

@Component({
    components: {
        CheckBox,
        PasswordCheckComment
    },

    computed: {
        ...mapGetters({
            user: accountTypes.USER
        })
    },

    methods: {
        ...mapActions({
            fetchArticleComment: types.FETCH_COMMENT,
            createComment: types.CREATE_COMMENT,
            deleteComment: types.DELTE_COMMENT
        })
    }
})
export default class CommentBox extends Vue {
    @Prop({ type: Number }) articleId!: number;
    @Prop({ type: Boolean }) showComment!: boolean;
    @Prop({ type: Boolean }) isAnonCommentBoard!: boolean;

    fetchArticleComment!: (article: article.CommentFetchSet) => Promise<article.PagedData<article.ArticleComment>>;
    createComment!: (set: article.CreateComment) => Promise<article.ArticleComment>;
    deleteComment!: (commentId: number) => Promise<article.ArticleComment>;

    user!: account.User;

    mAnonPhoto: string = "/cm/gres/faux/images/ghost.png";
    mUserPhoto: string = "/cm/gres/faux/images/ghost.png";
    commentsModel: any[] = [];
    commnetPhotos: article.CommentPhoto[] = [];
    commentPhotoMounted: boolean = false;
    mComment: string = "";

    useAnonComment: boolean = false;
    anonCommentLabel: string = this.$t('익명댓글').toString();
    anonName: string = '';

    anonInfo!: any
    defaultUserInfo!: any

    mShowPasswordCheck: boolean = false;
    selCommentObj: any;
    commentEventType: string = '';      // C : 등록, U : 수정 모드, U : 수정 처리, D : 삭제

    toggleAnonComment() {
        this.useAnonComment = !this.useAnonComment
    }

    @Watch('useAnonComment')
    onAnonCommentChanged(newVal: boolean) {
        if (newVal) {
            this.commnetPhotos.filter(c => c.isReplyModel == true).forEach(e => {
                e.photoUrl = this.mAnonPhoto
            })

        } else {
            this.commnetPhotos.filter(c => c.isReplyModel == true).forEach(e => {
                e.photoUrl = this.mUserPhoto
            })
        }
    }

    @Watch('anonName')
    onAnonNameChanged() {
        this.anonInfo.userName = this.anonName
    }

    @Watch('showComment')
    onShowChanged(newVal: boolean) {
        if (newVal) {
            this.mountComments();
        }
    }

    created() {
        this.onShowChanged(this.showComment);
        cache.avatar.get(this.user.personCode)
            .then(res => {
                this.mUserPhoto = res;
            });
    }

    langProperty(obj: Object, propKey: string) {
        return getLangProperty(obj, propKey);
    }

    getCreatedDate(createdDate: Date) {
        if(typeof(createdDate) === 'string') 
            createdDate = dateutil.IEDate(createdDate)
        // let baseDatetime = new Date(createdDate.toString())
        // baseDatetime.setMinutes(baseDatetime.getMinutes() + (new Date().getTimezoneOffset() * -1))
        return dateutil.uGetDateFormat('yyyy-MM-dd', createdDate);
    }

    getCreateTime(createdDate: Date) {
        if(typeof(createdDate) === 'string') 
            createdDate = dateutil.IEDate(createdDate)
        // let baseDatetime = new Date(createdDate.toString())
        // baseDatetime.setMinutes(baseDatetime.getMinutes() + (new Date().getTimezoneOffset() * -1))
        return dateutil.uGetDateFormat('H:i', createdDate);
    }

    getCommentEditable(comment: article.ArticleComment) {
        if (comment.userCode === this.user.personCode || comment.isAnonymous)
            return true;
        else
            return false;
    }

    sortComments(comments: article.ArticleComment[]) {
        var returnModel: any[] = comments.filter(c => c.replyTo == null);

        var appendReplies = function (remains: article.ArticleComment[]) {
            if (remains.length == 0)
                return;

            var nextRemains: article.ArticleComment[] = [];
            remains.forEach(r => {
                var idx = returnModel.findIndex(m => m.commentId == r.replyTo);

                if (idx >= 0) {
                    var parentComment = returnModel[idx];
                    var model = {
                        ...r,
                        replyToName: parentComment.userName
                    }

                    returnModel.splice(idx + 1, 0, model);
                } else {
                    nextRemains.push(r);
                }
            });

            if (comments.length == nextRemains.length)
                return;

            appendReplies(nextRemains);
        }

        appendReplies(comments.filter(c => c.replyTo != null));
        return returnModel.map(m => <any>{
            ...m,
            contentAfter: m.content,
            onEdit: false,
            isReplyModel: false
        })
    }

    mountComments() {
        return this.fetchArticleComment(<article.CommentFetchSet>{
            articleId: this.articleId,
            // itemsPerPage = 0 : 모든 댓글 FETCH
            itemsPerPage: 0,
            page: 1
        }).then(res => {
            this.commentsModel = this.sortComments(res.data);
            this.mountCommentPhotos(this.commentsModel, res.data.length);
            this.$emit('commentMounted', res.data, res.data.length);
        })
    }

    mountCommentPhotos(comments: article.ArticleComment[], length: number) {
        this.commentPhotoMounted = false;
        var photoPromise: Promise<string>[] = [];

        comments.forEach(c => photoPromise.push(cache.avatar.get(c.userCode)));
        Promise.all(photoPromise)
            .then(res => {
                this.commnetPhotos = res.map(r => <article.CommentPhoto>{ photoUrl: r, isReplyModel: false });
                this.commentPhotoMounted = true;
            });
    }

    onClickUpdateComment(comment: any, idx: number) {
        if (comment.isAnonymous) {
            this.selCommentObj = comment;
            this.commentEventType = 'U';
            this.mShowPasswordCheck = true;
        }
        else {
            comment.onEdit = true;
        }
    }

    async createArticleComment() {
        if (this.mComment.length == 0) {
            await (window as any).alertAsync(this.$t('M_BB_CommentIsNull').toString());
            return;
        }

        if (this.useAnonComment) {
            this.commentEventType = 'C';
            this.mShowPasswordCheck = true;         
        }
        else {
            this.createCommentProcess(null);            
        }        
    }

    createCommentProcess(password: string | null){
        let tempComment = this.mComment
        var setComment = this.useAnonComment ? this.base64Encode(this.mComment) : this.mComment;
        var setPassword = this.useAnonComment ? (password ? this.base64Encode(password) : '') : password ?? '';

        this.createComment(<article.CreateComment>{
            //createdUser: this.useAnonComment ? this.anonInfo : this.defaultUserInfo,
            createdUser: this.defaultUserInfo,
            replyTo: null,
            articleId: this.articleId,
            content: setComment,
            isAnonymous: this.useAnonComment,
            password: setPassword
        }).then(res => {
            this.mComment = "";
            this.onPasswordCheckCancel();
            this.mountComments();
            //2022-07-14 댓글 등록 후 팀즈 알림 발송 
            //대상 작성자, 등록된 카테고리 담당자
            //위에 초기화 과정이 있어서 temp 에 저장된 comment parameter 로 전달
            if(typeof res=="number" && res==0) this.callbackHanddler(tempComment)
        })
    }

    callbackHanddler(comment : string){
        //성공 케이스 숫자고 res 가 0이면 성공으로 간주
            // response 타입 지정이 잘 못 된 것 같음 하 
        this.$emit("created", comment)
    }

    //'댓글 수정' 버튼 클릭 시
    updateArticleComment(comment: any) {
        if (this.useAnonComment && !comment.password) {
            this.selCommentObj = comment;
            this.commentEventType = 'UP';
            this.mShowPasswordCheck = true;
        }
        else {
            this.updateArticleCommentProcess(comment, null);
        }
    }

    updateArticleCommentProcess(comment: any, password: string | null){
        let tempComment = comment.contentAfter
        var setComment = this.useAnonComment ? this.base64Encode(comment.contentAfter) : comment.contentAfter;
        var setPassword = this.useAnonComment ? (password ? this.base64Encode(password) : '') : password ?? '';

        this.createComment(<article.CreateComment>{
            //createdUser: this.useAnonComment ? this.anonInfo : this.defaultUserInfo,
            createdUser: this.defaultUserInfo,
            replyTo: comment.isReplyModel ? comment.replyTo : null,
            articleId: this.articleId,
            //content: comment.contentAfter,
            content: setComment,
            commentId: comment.commentId,
            isAnonymous: this.useAnonComment,
            //password: password ?? ''
            password: setPassword
        }).then(res => {
            this.onPasswordCheckCancel();
            this.mountComments();
             //2022-07-14 댓글 등록 후 팀즈 알림 발송 
            //대상 작성자, 등록된 카테고리 담당자
            //성공 케이스 숫자고 res 가 0이면 성공으로 간주
            // response 타입 지정이 잘 못 된 것 같음 하 
            if(typeof res=="number" && res==0) this.callbackHanddler(tempComment)
        })
    }

    deleteArticleComment(commentId: number) {
        let comment = this.commentsModel.find(e => e.commentId === commentId)

        if (comment.isAnonymous) {
            this.selCommentObj = comment;
            this.commentEventType = 'D';
            this.mShowPasswordCheck = true;
        }
        else {
            this.deleteCommentProcess(comment.commentId);
        }
    }

    deleteCommentProcess(commentId: number){
        this.deleteComment(commentId).then(res => {
            this.onPasswordCheckCancel();
            this.mountComments();
        })
    }

    replyArticleComment(commentId: number, idx: number) {
        this.commnetPhotos.splice(idx + 1, 0, { photoUrl: this.useAnonComment ? this.mAnonPhoto : this.mUserPhoto, isReplyModel: true });

        if (this.useAnonComment) {
            this.commentsModel.splice(idx + 1, 0, {
                commentId: 0,
                articleId: this.articleId,
                replyTo: commentId,
                content: "",
                createdDate: new Date(),
                // userCode: this.user.id,
                // userName: this.user.displayName,
                // userName_En: this.user.displayNameEng,
                // userDeptName: this.user.deptName,
                // userDeptName_En: this.user.deptNameEn,
                // userJobTitle: this.user.titleName,
                // userJobTitle_En: this.user.titleNameEn,
                contentAfter: "",
                onEdit: true,
                isReplyModel: true,
                ...this.anonInfo
            })
        } else {
            this.commentsModel.splice(idx + 1, 0, {
                commentId: 0,
                articleId: this.articleId,
                replyTo: commentId,
                content: "",
                createdDate: new Date(),
                // userCode: this.user.id,
                // userName: this.user.displayName,
                // userName_En: this.user.displayNameEng,
                // userDeptName: this.user.deptName,
                // userDeptName_En: this.user.deptNameEn,
                // userJobTitle: this.user.titleName,
                // userJobTitle_En: this.user.titleNameEn,
                contentAfter: "",
                onEdit: true,
                isReplyModel: true,
                ...this.defaultUserInfo
            })
        }
    }

    onCommentEditCancel(comment: any) {
        comment.onEdit = false;
        this.commentsModel = this.commentsModel.filter(c => !c.isReplyModel);
        this.commnetPhotos = this.commnetPhotos.filter(p => !p.isReplyModel);
    }

    async onPasswordCheckConfirm(password: string){
        this.mShowPasswordCheck = false;

        if (password && password == ''){
            await (window as any).alertAsync(this.$t('비밀번호를_입력해_주세요').toString());
            return;
        }
        if (password && password.length < 4){
            await (window as any).alertAsync(this.$t('비밀번호4자이상입력').toString());
            return;
        }

        if (this.commentEventType == 'C'){
            // 신규 작성인 경우
            this.createCommentProcess(password);
        }
        else if (this.commentEventType == 'UP' && this.selCommentObj && this.selCommentObj.isReplyModel){
            // 답글인 경우
            this.updateArticleCommentProcess(this.selCommentObj, password);   
        }
        else {
            api.article.checkArticleCommentPassword({
                articleId: this.articleId,
                commentId: this.selCommentObj.commentId,
                password: this.base64Encode(password)  
            }).then(async res => {
                if (res){
                    if (this.commentEventType == 'U'){
                        // 최초 수정 버튼 클릭 시
                        if (this.selCommentObj){
                            this.selCommentObj.onEdit = true;
                        }
                    }
                    else if (this.commentEventType == 'UP'){
                        if (this.selCommentObj){
                            // 수정 완료 후 작성 버튼 클릭 시
                            this.updateArticleCommentProcess(this.selCommentObj, password);
                        }
                    }
                    else if (this.commentEventType == 'D'){
                        // 삭제 클릭 시
                        this.deleteCommentProcess(this.selCommentObj.commentId);
                    }
                }
                else {
                    this.onPasswordCheckCancel();
                    await (window as any).alertAsync(this.$t('M_DifferentPassword').toString());
                    return;
                }
            });
        }
    }

    onPasswordCheckCancel() {
        this.selCommentObj = null;
        this.commentEventType = '';
        this.mShowPasswordCheck = false;
    }

    base64Encode(text: string){
        if (text){
            return window.btoa(unescape(encodeURIComponent(text)));
        }
        else {
            return '';
        }
    }

    mounted() {
        //사용자가 익명댓글 여부를 결정할 수 없을 경우 게시판의 사용여부를 상속
        this.useAnonComment = this.isAnonCommentBoard

        this.anonInfo = {
            deptName: '',
            deptName_En: '',
            userCode: '$Anonymous',
            userMail: '',
            userDept: '',
            userPhone: '',
            userJobTitle: '',
            userJobTitle_En: '',
            userName: this.anonName ?? this.$t('익명').toString(),
            userName_En: '',
            userDeptName: '',
            userDeptName_En: '',
        }

        this.defaultUserInfo = {
            deptName: this.useAnonComment ? this.base64Encode(this.user.deptName) : this.user.deptName,
            deptName_En: this.useAnonComment ? this.base64Encode(this.user.deptNameEn) : this.user.deptNameEn,
            userCode: this.useAnonComment ? this.base64Encode(this.user.id) : this.user.id,
            userMail: this.useAnonComment ? this.base64Encode(this.user.email) : this.user.email,
            userDept: this.useAnonComment ? this.base64Encode(this.user.deptCode) : this.user.deptCode,
            userPhone: this.useAnonComment ? this.base64Encode(this.user.officeTel) : this.user.officeTel,
            userJobTitle: this.useAnonComment ? this.base64Encode(this.user.titleName) : this.user.titleName,
            userJobTitle_En: this.useAnonComment ? this.base64Encode(this.user.titleNameEn) : this.user.titleNameEn,
            userName: this.useAnonComment ? this.base64Encode(this.user.displayName) : this.user.displayName,
            userName_En: this.useAnonComment ? this.base64Encode(this.user.displayNameEng) : this.user.displayNameEng,
            userDeptName: this.useAnonComment ? this.base64Encode(this.user.deptName) : this.user.deptName,
            userDeptName_En: this.useAnonComment ? this.base64Encode(this.user.deptNameEn) : this.user.deptNameEn,
        }
    }
}