import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import DatePicker  from '@appbase/components/DatePicker/DatePicker.vue'
import * as dateutil from '@appbase/util/date-helper'
import api from '@gnb/api/common'
import CheckSlider from '@gnb/components/Common/CheckSlider.vue'
import { mapActions } from 'vuex'
import * as types from '@gnb/store/types'
import { EumEditor } from '@appbase/components/editor'

@Component({
    components:{
        CheckSlider,
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        Dropdown,
        DatePicker,
        EumEditor
    },
    methods:{
        ...mapActions({
            loading: types.COMMON_LOADDING
        })
    },
})
export default class SettingMissedTime extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({ type: Object }) value!: gnb.MissedAutoReplyItem;
    
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    loading!: (option: boolean) => void;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    isEnabled: boolean = false;
    startTimeArray: Array<any> = [];
    endTimeArray: Array<any> = [];
    label: string = this.$t('부재중_설정_사용').toString();
    name:string = this.$t('부재중_자동_회신_보내기').toString();

    tinyopts = {
        menubar: false,
        inline: false,
        height: 500,
        relative_urls: false,
        remove_script_host: true,
        document_base_url: location.origin,
        language: 'ko_KR',
        content_style: `
        body {font-family: arial; font-size: 11pt; line-height: 1.2;}
        body#tinymce p {
            margin-top: 0.3em;
            margin-bottom: 0.3em;
        }`,
        setup: (e: any) => {
          e.on('init', (ed: any) => {
            ed.target.editorCommands.execCommand('fontSize', false, '10pt')
          })
        },
        auto_focus: '',
      }

      onValueChanged(value: string) {
         this.value.replyContent = value;
    }

    dext5opts: editor.dext5.DEXT5Config = {
      EditorBorder: '0',
    } 

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    /**
     * 시작일자 배열을 가져옵니다
     */
    get StartTimeArray() {
    this.startTimeArray.sort((a, b) => a.num - b.num);
    return this.startTimeArray;
    }
    /**
     * 시작일자를 직접 입력했을 경우에 동작합니다
     */
    set StartTimeArray(value) {
        this.startTimeArray = value;
    }
    /**
     * 완료일자 배열을 표시합니다
     */
    get EndTimeArray() {
        this.endTimeArray.sort((a, b) => a.num - b.num);
        return this.endTimeArray;
    }
    /**
     * 완료일자를 직접 입력했을 경우에 동작합니다
     */
    set EndTimeArray(value) {
        this.endTimeArray = value;
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    async checkDate(startDate: string, endDate: string) {
        const now = new Date();
    
        if (startDate >= endDate) {
            await (window as any).alertAsync(this.$t('M_Settings_Msg37'));
            return false;
        }
        if (new Date(endDate) <= now) {
            await (window as any).alertAsync(this.$t('M_Settings_Msg38'));
            return false;
        }
    
        return true;
    }

    changeDate(dateTime: string, type: string = '') {
        const offset = new Date().getTimezoneOffset();
        let date = dateutil.IEDate(dateTime);
        if (type === 'UTC') {
            date.setMinutes(date.getMinutes() + offset);
        } else {
            date.setMinutes(date.getMinutes() - offset);
        }
        return dateutil.uGetDateFormat(
            'yyyy-MM-DD H:i:s',
            dateutil.IEDate(date)
        );
    }

    save(){
        let startDateTime = this.value.start + ' ' + this.value.starttime;
        let endDateTime = this.value.end + ' ' + this.value.endtime;
        const editor: editor.IEditorProxy = this.$refs.editor as any
        // 에디터에서 본문 컨텐츠 가져오기
        this.value.replyContent = editor.getEditor().getBodyValue();

        this.$nextTick(async () => {
            if(this.value.checkUseAutoReply){
                if(await this.checkDate(startDateTime, endDateTime)){
                    api.setOOF({
                        startUtc: this.changeDate(startDateTime, 'UTC'),
                        endUtc: this.changeDate(endDateTime, 'UTC'),
                        enabled: this.value.checkUseAutoReply,
                        internalMessage: this.value.replyContent,
                        externalMessage: ''
                    }).then(async ()=>{
                        await (window as any).alertAsync(this.$t('M_Expense_SavedAlert').toString());
                        this.$emit('cancel');
                    })
                }
            }else{
                api.setOOF({
                    startUtc: dateutil.uGetDateFormat('yyyy-MM-DDTH:i:00', new Date()),
                    endUtc: dateutil.uGetDateFormat('yyyy-MM-DDTH:i:00', new Date()),
                    enabled: this.value.checkUseAutoReply,
                    internalMessage: this.value.replyContent,
                    externalMessage: '',
                    
                }).then(async ()=>{
                    await (window as any).alertAsync(this.$t('해제되었습니다').toString());
                    this.$emit('cancel');
                })
            }
        })
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    mounted(){
        this.startTimeArray = dateutil.uGetTimeArray([
            this.$t('W_AM').toString(),
            this.$t('W_PM').toString(),
        ]);
        this.endTimeArray = dateutil.uGetTimeArray([
            this.$t('W_AM').toString(),
            this.$t('W_PM').toString(),
        ]);
    }

 

}