import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { WebpartTemplate } from '../../templates'
import { mapGetters } from 'vuex'
import * as typesAccount from '@account/store/types'
import WebpartBase from '@webparts/views/WebpartBase'
import * as dateutil from '@appbase/util/date-helper'
import api from '../../api/index'
import { openPopup } from '@/modules/@appbase/util'

const unreadMailRequest = {
  fields: [{ key: 'Subject' }, { key: 'DateTimeCreated', format: 'yyyy-MM-dd' }],
  filters: [
    {
      key: 'IsRead',
      value: 'false',
      condition: 'isEqualTo',
    },
  ],
  pageSize: 0,
  offset: 0,
  type: 'EmailMessage',
}

var listRequest: awsRequest.request = {
  fields: [{ key: 'Id' }],
  filters: [
    {
      operator: 'AND',
      filters: [
        {
          key: 'Start',
          value: dateutil.uGetDateFormat('yyyy-MM-DD', new Date()),
          valueType: 'datetime',
          valueFormat: 'yyyy-MM-dd',
          condition: 'IsGreaterThanOrEqualTo',
        },
        {
          key: 'End',
          value: dateutil.uGetDateFormat('yyyy-MM-DD', new Date()),
          valueType: 'datetime',
          valueFormat: 'yyyy-MM-dd',
          condition: 'IsLessThanOrEqualTo',
        },
      ],
    },
  ],
  wellKnownParentName: 'Calendar',
  type: 'Calendar',
}

@Component({
  components: { WebpartTemplate },
  computed: {
    ...mapGetters({
      current: typesAccount.CURRENT,
    }),
  },
})
export default class UserProfileWebpart extends WebpartBase {
  constructor() {
    super()
    this.firstRender = false
  }

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  unreadMailCNT: number = 0
  myAPMCNT: number = 0
  mySchedule: number = 0

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  current!: account.TCurrent

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //

  get user() {
    return this.current.user
  }

  get company() {
    return this.current.company
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E S T Y L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  async load() {
    const [res1, res2, res3] = await Promise.all([
      api.webPart.getEWSData<any>(unreadMailRequest),
      api.webPart.getMyAPM(this.user.personCode, this.user.deptCode),
      api.webPart.getEWSData<any>(listRequest),
    ])
    /**
     * 읽지 않은 메일
     */
    if (res1 && res1.items) {
      this.unreadMailCNT = res1.items.length
    }

    /**
     * 결재
     */
    this.myAPMCNT = res2.reduce((prev: any, next: any) => {
      return prev + next.count
    }, 0)

    /**
     * 일정
     */
    this.mySchedule = res3.items.length
  }

  handleProfileSetting() {
    openPopup('user-profile-setting', '/webparts/profile-setting', {}, { width: 1024, height: 340 })
  }
}
