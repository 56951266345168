import { Flow, FlowChunk, FlowFile } from '@appbase/libs/flowjs'
import FlowFileUploader from './FlowFileUploader'
import flowHelper from './flow-helper'
import officeHelper from '../../../util/office-helper'
import { IFlowContextFileHandler } from '@appbase/libs/flowjs/FlowFileHandler'
import api from '../../../api'

export class FlowFileHandler implements IFlowContextFileHandler<FlowFileUploader> {
  //
  // ──────────────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P E R T I E S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────
  //

  context!: FlowFileUploader

  //
  // ────────────────────────────────────────────────────────────────────── I ──────────
  //   :::::: I M P L E M E N T A T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────────────────
  //

  /**
   * A specific file was completed. First argument file is instance of FlowFile,
   * second argument message contains server response. Response is always a string.
   * Third argument chunk is instance of FlowChunk.
   * You can get response status by accessing xhr object chunk.xhr.status.
   */
  fileSuccess(file: FlowFile, message: string, chunk: FlowChunk) {
    console.log('FlowFileHandler', 'fileSuccess', arguments)
  }

  /**
   * Uploading progressed for a specific file.
   */
  fileProgress(file: FlowFile, chunk: FlowChunk): void {
    // console.log('FlowFileHandler', 'fileProgress', arguments)

    const percent = file.error ? 0 : Math.floor(file.progress() * 100)
    file.percent = percent
  }

  /**
   * This event is used for file validation. To reject this file return false.
   * This event is also called before file is added to upload queue,
   * this means that calling flow.upload() function will not start current file upload.
   * Optionally, you can use the browser event object from when the file was added.
   */
  fileAdded(file: FlowFile, event: Event): boolean {
    console.log('FlowFileHandler', 'fileAdded', arguments)
    return true
  }

  /**
   *
   * @param array 추가하려는 파일 목록
   */
  filesAdding(files: FlowFile[], event: Event): boolean {
    console.log('FlowFileHandler', 'filesAdding', files)
    const {
      totalSizeLimit,
      totalSizeLimitExcessMessage,
      bannedExtentions,
      bannedExtentionMessage,
      emptyFileDenialMessage,
    } = this.context
    const flow = this.context.flow

    let filtered = [...files].filter((f) => f.size === 0)
    if (filtered.length > 0) {
      if (typeof emptyFileDenialMessage === 'string') {
        alert(emptyFileDenialMessage)
        officeHelper.notify(filtered[0].name, emptyFileDenialMessage, 'error')
      }
      return false
    }
    if (typeof totalSizeLimit === 'number' && totalSizeLimit > 0) {
      if (
        [...flow.files, ...files].map((f) => f.size).reduce((a, b) => a + b, 0) > totalSizeLimit
      ) {
        if (typeof totalSizeLimitExcessMessage === 'string') {
          officeHelper.notify(
            `전체 파일 크기 제한: ${flowHelper.readablizeBytes(totalSizeLimit, 0)}`,
            totalSizeLimitExcessMessage,
            'error'
          )
        }
        return false
      }
    }

    if (bannedExtentions != undefined && bannedExtentions != null) {
      let filtered = [...files].filter((f) => {
        var match = f.name.match(/[^.]+$/)
        var extension = match == null ? '' : match[0]

        if (bannedExtentions.indexOf(extension) >= 0) return true
        else return false
      })

      if (filtered.length > 0) {
        officeHelper.notify(filtered[0].name, bannedExtentionMessage, 'error')
        return false
      }
    }
    return true
  }

  /**
   * Same as fileAdded, but used for multiple file validation.
   */
  filesAdded(array: FlowFile[], event: Event): boolean {
    console.log('FlowFileHandler', 'filesAdded', arguments)
    return true
  }

  /**
   * Same as filesAdded, but happens after the file is added to upload queue. Can be used to start upload of currently added files.
   */
  filesSubmitted(array: FlowFile[], event: Event): void {
    console.log('FlowFileHandler', 'filesSubmitted', arguments)
    // 파일 추가 즉시 업로드 시작
    this.context.flow.upload()
  }

  /**
   * The specific file was removed from the upload queue. Combined with filesSubmitted, can be used to notify UI to update its state to match the upload queue.
   */
  fileRemoved(file: FlowFile): void {
    console.log('FlowFileHandler', 'fileRemoved', arguments)
    const {
      meta: { code, codeValues },
    } = this.context
    const splitFileName = file.name.split('.')
    const fileExtention = splitFileName[splitFileName.length - 1]
    let identifier = (file as any).serverPath
      ? (file as any).serverPath
      : file.uniqueIdentifier + `.${fileExtention}`
    api.file.deleteFiles(code, codeValues, [file.name], [identifier])
  }

  /**
   * Something went wrong during upload of a specific file, uploading is being retried.
   */
  fileRetry(file: FlowFile, chunk: FlowChunk): void {
    console.log('FlowFileHandler', 'fileRetry', arguments)
  }

  /**
   * An error occurred during upload of a specific file.
   */
  fileError(file: FlowFile, message: string, chunk: FlowChunk) {
    console.log('FlowFileHandler', 'fileError', arguments)
  }

  /**
   * Upload has been started on the Flow object.
   */
  uploadStart(): void {
    console.log('FlowFileHandler', 'uploadStart', arguments)
  }

  /**
   * Uploading completed.
   */
  complete(): void {
    console.log('FlowFileHandler', 'complete', arguments)
  }

  /**
   * Uploading progress.
   */
  progress(): void {
    // console.log('FlowFileHandler', 'progress', arguments)
  }

  /**
   * An error, including fileError, occurred.
   */
  error(message: string, file: FlowFile, chunk: FlowChunk): void {
    console.log('FlowFileHandler', 'error', arguments)
  }

  /**
   * Listen to all the events listed above with the same callback function.
   */
  catchAll(event: Event, ...args: any[]): void {
    //console.log('FlowFileHandler', 'catchAll', arguments)
    // console.log('files', this.context.value)
  }

  //
  // ────────────────────────────────────────────────────────────── I ──────────
  //   :::::: C O N S T R U C T O R : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────────
  //

  constructor(uploader: FlowFileUploader) {
    if (!uploader) throw new Error('ARGS_NULL_ERROR : uploader')
    this.context = uploader
  }
}
