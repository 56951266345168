import { Flow, FlowFile, FlowOptions } from '../../../libs/flowjs'
import Vue, { DirectiveOptions } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import FileList from './FileList/FileList.vue'
import { FlowFileHandler } from './flow-file-handler'
import { FlowFileMeta } from './flow-file-meta'
import { i18n } from '@appbase/util'

const FLOW_DROP_SELECTOR = '#flowDrop'
const flowDirective: DirectiveOptions = {
  inserted: (el, binding, vnode) => {
    const {
      flow,
      browseButton,
      browseFolderButton,
      deleteSelectedFilesButton,
      deleteSelectedFilesMessage,
      uploderSelector
    } = vnode.context as FlowFileUploader
    console.log('v-flow', 'inserted', [flow, browseButton, browseFolderButton, uploderSelector])

    const query = (query: string) => window.document.body.querySelector(`${query}`)

    if (flow) {
      let selector =  uploderSelector ? uploderSelector : FLOW_DROP_SELECTOR 
      let found = query(selector as string)
      if (found) {
        flow.assignDrop(found)
      }

      //2022-06-10 한개의 컴포넌트에서 1개 이상 파일업로더가 있는 경우 drop 다운 문제가 있음
      //입력받은 uploder querselector 를 이용함
      // 파일 찾아보기 버튼
      if (browseButton) {
        let found = query(browseButton)
        if (found) {
          flow.assignBrowse(found)
        }
      }

      // 폴더 찾아보기 버튼
      if (browseFolderButton) {
        let found = query(browseFolderButton)
        if (found) {
          flow.assignBrowse(found, true)
        }
      }

      // 선택된 파일 삭제 버튼
      // vnode.context?.$watch
      if (deleteSelectedFilesButton) {
        let found = query(deleteSelectedFilesButton)
        if (found) {
          flow.assignDeleteSelectedFiles(found, deleteSelectedFilesMessage || i18n.t('삭제하려는_파일을_선택하세요').toString())
        }
      }

      // 이미지 찾아보기 버튼
      // if (flowBrowseImage) {
      //     var found = query(flowBrowseImage);
      //     flow.assignBrowse(found, false, false, {
      //         accept: 'image/*',
      //     });
      // }
    } else {
      throw new Error('The flow object has not been initialized.')
    }
  },
}

@Component({
  components: { FileList },
  directives: { flow: flowDirective },
})
export default class FlowFileUploader extends Vue {
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //

  /**
   * 파일 업로드 API URL
   * @requires
   */
  @Prop({ type: String, required: true }) uploadUrl!: string

  /**
   * 파일 삭제 API URL
   * @requires
   */
  @Prop({ type: String, required: true }) deleteUrl!: string

  /**
   * 파일 삭제 API URL 호출 시 사용할 HTTP Method (DELETE, POST)
   */
  @Prop({ type: String, default: 'DELETE' }) deleteMethod!: string

  /**
   * 파일 업로드 시 함께 첨부할 파일 메타 정보
   */
  @Prop({ type: Object, required: true }) meta!: FlowFileMeta

  /**
   * 파일 업로드 시 전송할 바이트 크기
   * @default 10MB
   */
  @Prop({ type: Number, default: 10000000 }) chunkSize!: number

  /**
   * TODO: 용도 확인하여 제거 가능한 것인지 기술 검토 필요
   */
  @Prop({ type: Number, default: 4 }) simultaneousUploads!: number

  /**
   * 파일 목록
   */
  @Prop({ type: Array, required: true }) value!: FlowFile[]

  /**
   * 파일 찾아보기 버튼을 검색할 셀렉터
   * 지정된 셀렉터에 의해 조회된 엘리먼트에 파일 찾아보기 액션이 바인딩 됩니다.
   */
  @Prop({ type: String }) browseButton?: string

  @Prop({ type: String, default:"" }) uploderSelector?: string
  /**
   * 폴더 찾아보기 버튼을 검색할 셀렉터
   * 지정된 셀렉터에 의해 조회된 엘리먼트에 폴더 찾아보기 액션이 바인딩 됩니다.
   */
  @Prop({ type: String }) browseFolderButton?: string

  /**
   * 선택된 파일 삭제 버튼을 검색할 셀렉터
   */
  @Prop({ type: String }) deleteSelectedFilesButton?: string

  /**
   * TODO: 용도 확인하여 제거 가능한 것인지 기술 검토 필요
   */
  @Prop({ type: Function }) customDelete?: (files: any[]) => Promise<boolean>

  /**
   * 전체 파일 크기 제한
   * @default 1GB
   */
  @Prop({ type: Number, default: 1073741824 }) totalSizeLimit!: Number

  /**
   * 전체 파일 크기 제한 초과시 표시할 메시지 내용
   */
  @Prop({ type: String, default: '전체 파일 크기 제한 초과' })
  totalSizeLimitExcessMessage!: string

  /**
   * 첨부파려는 파일 크기가 0바이트일 경우 표시할 메시지 내용
   */
  @Prop({ type: String, default: '비어있는 파일은 첨부할 수 없습니다.' })
  emptyFileDenialMessage!: string

  @Prop({ type: String })
  deleteSelectedFilesMessage!: string

  /**
   * 업로드 파일 확장자 제한
   * @example [".",]
   */
  @Prop({ type: Array, default: () => [] }) bannedExtentions!: string[]

  /**
   * 업로드 파일 확장자 제한에 포함된 파일을 업로드하려는 경우 표시할 메시지 내용
   */
  @Prop({ type: String, default: '해당 파일 형식은 업로드할 수 없습니다.' })
  bannedExtentionMessage!: string

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  /**
   * instance of Flowjs
   */
  flow: Flow = Object.create(null)

  /**
   * Flow 지원 여부
   */
  isNotSupported: boolean = false

  /**
   * 파일을 마우스로 끌어서 뷰 위에 올려두고 있는지 여부
   */
  dragin: boolean = false

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  created() {
    const options: FlowOptions = {
      ...Object.create(null),
      ...{
        target: this.uploadUrl,
        singleFile: false,
        chunkSize: this.chunkSize,
        testChunks: false,
        query: {
          code: this.meta.code,
          codeValues: this.meta.codeValues,
          type: this.meta.type,
        },
        on: new FlowFileHandler(this),
      },
    }
    this.flow = new Flow(options, this.value)

    if (!this.flow.support) {
      this.isNotSupported = true
    }
  }

  @Watch('value')
  onChangeValue() {
    this.flow.files = this.value
  }

  deleteFile(file: FlowFile) {
    this.flow.removeFile(file)
  }
}
