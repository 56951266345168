import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import WebpartBase from '@webparts/views/WebpartBase'
import * as dateutil from '@appbase/util/date-helper'
import { mapGetters } from 'vuex'
import * as typesAccount from '@account/store/types'
import { Calendar, EventList } from '../../components/Calendar'
import {
  CalendarEventColor,
  CalendarFolderInfo,
} from '../../components/Calendar/type'
import api from '../../api'

export const COMMON_AUTO_COLOR_ARRAY = [
  '#ff4b09',
  '#ffc609',
  '#e0ff03',
  '#bdff09',
  '#29e1bf',
  '#0942ff',
  '#241eb4',
  '#c609ff',
  '#ff119a',
  '#c9dbdb',
]

const folderRequest: awsRequest.request = {
  fields: [
    {
      key: 'Id',
    },
    {
      key: 'USER_COLOR',
    },
  ],
  filters: [
    {
      operator: 'or',
      filters: [
        {
          key: 'FolderClass',
          value: 'IPF.Appointment',
          condition: 'isEqualTo',
        },
        {
          key: 'FolderClass',
          value: 'IPF.Appointment.Birthday',
          condition: 'isEqualTo',
        },
      ],
    },
    {
      key: 'PR_ATTR_HIDDEN',
      value: 'false',
      condition: 'isEqualTo',
    },
  ],
  wellKnownParentName: 'MsgFolderRoot',
  type: 'Folder',
  Traversal: 'Deep',
}

@Component({
  components: { Calendar, EventList },
  computed: {
    ...mapGetters({
      current: typesAccount.CURRENT,
    }),
  },
})
export default class CalendarWebpart extends WebpartBase {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //
  @Prop({
    type: String,
    default: dateutil.uGetDateFormat('yyyy-MM-DD', new Date()),
  })
  value!: string

  baseValue: string | null = dateutil.uGetDateFormat(
    'yyyy-MM-DD',
    dateutil.IEDate(this.value)
  )
  weeks: string[] = [
    this.$t('W_Schedule_Sun').toString(),
    this.$t('W_Schedule_Mon').toString(),
    this.$t('W_Schedule_Tue').toString(),
    this.$t('W_Schedule_Wed').toString(),
    this.$t('W_Schedule_Thu').toString(),
    this.$t('W_Schedule_Fri').toString(),
    this.$t('W_Schedule_Sat').toString(),
  ]
  folders: CalendarFolderInfo[] = []

  //
  // ──────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────
  //

  async created() {
    this.folders = await api.webPart
      .getEWSData<{ items: EwsCalendarFolderColorInfo[] }>(folderRequest)
      .then((x) => x.items)
      .then((items) => {
        return items.map((item, index) => {
          const defaultColorCode = Math.floor(
            Math.random() * (10 - 1) + 1
          ).toString()
          let color: CalendarEventColor = {
            hasColor: true,
            code: item.colorCode || COMMON_AUTO_COLOR_ARRAY[index],
            category: `bg-category-${index}`,
          }
          // color.hasColor =
          //   item.colorCode !== undefined && item.colorCode !== 'none'
          return { id: item.id, color } as CalendarFolderInfo
        })
      })
  }
}

export interface EwsCalendarFolderColorInfo {
  id: string
  colorCode: string | 'none'
}
