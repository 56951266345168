import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'folder-item',
})

export default class FolderItem extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: Object }) item!: orgmap.TreeListItem;
  @Prop({ type: String }) id!: string;
  @Prop({ type: Array }) items!: orgmap.TreeListItem[];

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  itemclick(folder: orgmap.TreeListItem){
    this.$emit('itemclick', folder);
  }
}
