import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { TTabNames } from '../views/OrgmapMainBase'

@Component
export default class OrgTab extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: Function }) onSelect!: (name: TTabNames) => void
  @Prop({ type: Function }) handleActive!: (name: TTabNames) => boolean
  @Prop({ type: Boolean }) usePersonal!: boolean
  @Prop({ type: Boolean }) usePublic!: boolean
}
