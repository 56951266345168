import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import MemberListItem from './MemberListItem.vue'
import { mapGetters, mapMutations } from 'vuex'
import * as types from '@orgmap/store/types'

@Component({
  name: 'MemberList',
  components: { MemberListItem },
  computed: {
    ...mapGetters({
      options: types.ORG_OPTION_GET
    }),
  }
})
export default class MemberList extends Vue {
  @Prop({ type: Object }) value!: orgmap.TMemberList
  @Prop({ type: Boolean }) loading!: boolean
  @Prop({ type: String }) userlang!: string
  @Prop({ type: Function }) onSelect!: (item: orgmap.TMemberItem) => void
  @Prop({ type: Function }) onSelectOne!: (item: orgmap.TMemberItem, isSingle: boolean) => void
  @Prop({ type: Function }) onSelectAll!: () => void

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //

  get items() {
    return this.value.items
  }

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  selectedAll: boolean = false

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  options!: orgmap.TWishContainerOptions; 


  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: W A T C H E S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  @Watch('value.selectedAll')
  onAllSelectChanged(newVal: boolean) {
    this.selectedAll = newVal
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  mounted() {
    this.selectedAll = this.value.selectedAll
  }
}
