import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import CategoryView from '@gnb/components/Preference/Mail/Category/CategoryView.vue'
import api from '@gnb/api/common'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@gnb/store/types'
import CategoryModal from './Modals/CategoryModal.vue'
@Component({
    components:{
      ModalHeader,
      ModalContent,
      ModalFooter,
      ContentItem,
      PreferenceContentTemplate,
      CategoryView,
      CategoryModal
    },
    methods:{
      ...mapActions({
          loading: types.COMMON_LOADDING
      })
    },
    computed:{
        ...mapGetters({
            isLoading: types.COMMON_LOADDING
        })
    }
})
export default class Category extends Vue {
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: A C T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  loading!: (option: boolean) => void;
  
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  isLoading !: boolean;
  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //
  isShow: boolean = false;
  show: boolean = false;
  selectedCategory: gnb.CategoryItem = {} as gnb.CategoryItem;
  categoryItem: gnb.CM_MySetting = {} as gnb.CM_MySetting
  categories: gnb.CategoryItem[] = [];
  header: string = this.$t('W_Mail_Category').toString();
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  change(value:gnb.CM_MySetting){
    this.categoryItem = value;
  }

  addNewCategory(){
    // this.isShow = true;
    this.show = true;
  }

  closeModal(){
    this.selectedCategory =  {} as gnb.CategoryItem;
    this.show = false;
    api.getCategoryList().then((res:gnb.CategoryItem[])=>{
      this.categories = res;
    });
  }

  select(cat:gnb.CategoryItem){
    this.show = true;
    this.selectedCategory = cat;
  }

  cancel(){
    if(this.isShow){
      this.isShow = false;

    }else{
      this.$emit('cancel')
    }
  }

  save(){
    if(this.isShow){
      api.setMysetting(this.categoryItem).then(async ()=>{
        await (window as any).alertAsync(this.$t('M_Expense_SavedAlert'.toString()));
      })

    }
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  created(){
    this.loading(true);
    api.getCategoryList().then((res:gnb.CategoryItem[])=>{
          this.categories = res;
          if(this.categories){
              this.loading(false);
          }
      });
  }
}