import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import WebpartBase from '@webparts/views/WebpartBase'
import { ListHeader, ListImageSwipeArticle } from '../../components/index'
import api from '../../api/index'
import { openPopup } from '@appbase/util/popup-helper'
import { mapGetters } from 'vuex'
import * as typesAccount from '@account/store/types'
const carousel = require('vue-owl-carousel')
import { NoData } from '../../components/common'

@Component({
  components: {
    ListHeader,
    ListImageSwipeArticle,
    carousel,
    NoData,
  },
  computed: {
    ...mapGetters({
      current: typesAccount.CURRENT,
    }),
  },
})
export default class ListImageSwipeWebpart extends WebpartBase {
  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //
  activeBoardId!: number
  nowPageNumber: number = 1
  totalCount!: number
  listArticles?: any = [
    // { title: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁ' }, { title: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁ' },
  ]
  boardDefaultImage: string = ''
  current!: account.TCurrent
  text:string = this.$t('등록된_데이터가_없습니다').toString();

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  Refresh() {
    this.nowPageNumber = 1
    this.getArticles()
  }

  Prev() {
    if (this.nowPageNumber > 1) {
      this.nowPageNumber--
      this.getArticles()
    }
  }

  Next() {
    if (Math.ceil(this.totalCount / this.option.displayCount!) > this.nowPageNumber) {
      this.nowPageNumber++
      this.getArticles()
    }
  }

  async load() {
    if (this.option.tabs != null && this.option.tabs.length > 0)
      this.activeBoardId = this.option.tabs[0].boardId

    this.boardDefaultImage = await api.webPart
      .getBoardDefaultImage(this.activeBoardId)
      .then((m: any) => m)
    this.getArticles()
    setTimeout(() => (this.firstRender = this.loading = false), 200)
  }

  async getArticles() {
    try {
      var res = await api.webPart
        .getArticles(
          this.activeBoardId,
          this.option.tabs![0]!.displayCount * 5,
          this.nowPageNumber,
          false,
          false
        )
        .then((m: any) => m)

      this.listArticles = res.data || []
      this.totalCount = res.totalCount
    } catch {
      this.listArticles = []
    }
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E S T Y L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //
  mounted() {
    this.load()
    // var owl:any = $('.owl-carousel.list-thumbnail');
    // owl.owlCarousel({
    //     items:this.option.displayCount!,
    //     nav:true,
    //     dots:false,
    // });
  }
}
