import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { WishListContainer } from '../containers'
import * as types from '../store/types'
import { mapGetters, mapMutations } from 'vuex'
import modalHelper from '@appbase/util/modal-helper'
// components
import OrgTab from '../components/OrgTab.vue'
import { ModalLayout } from '@appbase/templates'
import { OrgmapMainBase, TTabNames } from './OrgmapMainBase'

export interface TOrgmapModalPayload {
  options: orgmap.TWishContainerOptions
  event: string
  props: orgmap.TWishItemPropsOptions
}

@Component({
  components: { WishListContainer, ModalLayout, OrgTab },
  computed: {
    ...mapGetters({
      containers: types.ORG_CONTAINER_REGISTER,
    }),    
  },
  methods: {
    ...mapMutations({
      setOptions: types.ORG_OPTION_SET,
      clearContainer : types.ORG_CONTAINER_CLEAR
    }),
  },
})
export default class OrgmapModalView extends OrgmapMainBase {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: Object }) value!: TOrgmapModalPayload 

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: M U T A T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  setOptions!: (payload: orgmap.TWishContainerOptions) => void
  clearContainer! : () => void
  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  created() {
    this.options = this.value.options
    this.setOptions(this.options)
    this.event = this.value.event
    this.propsOption = this.value.props
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  handleTabSelect(name: TTabNames) {
    this.processTabSelect.bind(this, name)()
  }

  handleTabActive(name: TTabNames){
    return this.processTabActive.bind(this, name)()
  }

  handleOk() {
    this.processOk.bind(this, window)()
  }

  handleClose() {
    modalHelper.close()

    //컨테이너 store 비워주기 
    this.clearContainer()
  }
}
