import api from '@/api';
export default {
    sendMail(mail: boardmail.EmailSendRequest) {
        if(!mail) {
            throw new Error("ARGUMENT_NULL_EXCEPTION: {mail}");
        }
        return api.post<any>({
            url: `/api/ews/EmailMessage`,
            data: mail
        })
    }
}
