import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import WebpartBase from '@webparts/views/WebpartBase'
import { ShortcutsWebpart } from '../..'
import * as usertypes from '@account/store/types';
import { mapGetters } from 'vuex';
// import api from '@/api';
import api from '@webparts/api'
import * as accountTypes from '@account/store/types'

export interface shortCutItem {
    shortCode: string
    name: string
    link: string
    isFixed: boolean
    comCode?: string
}

@Component({
    computed: {
        ...mapGetters({
            user: usertypes.USER,
            current: accountTypes.CURRENT
        })
    },
})
export default class ZinusWorldTimeWebpart extends WebpartBase {
    fixedItems: Array<shortCutItem> = []
    items: Array<shortCutItem> = []
    user!: account.TUser
    current!: account.TCurrent;
    webpartItems: Array<webparts.WebpartItem> = []

    created() {
        //고정메뉴 추가
        this.fixedItems.push({ shortCode: "ESG", name: "ESG", link: "https://esg.zinus.co.kr", isFixed: true })
        this.fixedItems.push({ shortCode: "WP", name: this.$t("내부신고제도").toString(), link: "https://esg.zinus.co.kr/ESG/html/ethical/report.html", isFixed: true })
        this.fixedItems.push({ shortCode: "GHRIS", name: "GHRIS", link: "https://performancemanager4.successfactors.com/login?company=zinusP", isFixed: true })
        //this.fixedItems.push({ shortCode: "OFFICE", name: "Office 365", link: "https://www.office.com", isFixed: true })
        //this.initItems() //comCode ? 아니면 최상단 부서코드?

        //alert(this.user.comCode);

        let comCode = this.user.personCode.split("_")[0];

        if(this.user.comCode =="MELLOW")
            {
                comCode = "C033";
            }

        // 데이터 조회
        api.webPart.getWebpartItems(comCode, 'SHORTCUT').then(res => {
            if (res){
                this.webpartItems = res;
            }
        });
    }

    initItems() {
        //고정 메뉴는 
        //팝업?처리? window.open?
        //라우팅?
        //api 콜해서 처리

        var timezoneConverterUrl = 'https://www.timeanddate.com/worldclock/converter.html?iso=20211123T003000&p1=235&p2=2004&p3=4780&p4=108&p5=47&p6=136&p7=218&p8=232&p9=25';

        //KOREA C021
        this.items.push({ comCode: "C021", shortCode: "IAC", name: "IAC", link: "https://iac.zinus.com", isFixed: false })
        // this.items.push({ comCode: "C021", shortCode: "GW", name: "기존 그룹웨어", link: "http://zone1.zinus.com", isFixed: false })
        this.items.push({ comCode: "C021", shortCode: "EIS", name: "EIS", link: "https://zinus.authentication.ap12.hana.ondemand.com/login", isFixed: false })
        this.items.push({ comCode: "C021", shortCode: "CUR", name: "환율사이트", link: "https://www.bloomberg.com/markets/currencies", isFixed: false })
        this.items.push({ comCode: "C021", shortCode: "TC", name: "Timezone Converter", link: timezoneConverterUrl, isFixed: false })

        //CHINA
        this.items.push({ comCode: "C041;C042;C043;C044", shortCode: "cgw", name: "G/W( OfficeOn )", link: "http://zone1.zinus.com", isFixed: false })
        this.items.push({ comCode: "C041;C042;C043;C044", shortCode: "xierp", name: "ERP - Xiamen", link: "https://xmerp.zinus.com/jde", isFixed: false })
        this.items.push({ comCode: "C041;C042;C043;C044", shortCode: "fuerp", name: "ERP - Fujian", link: "https://fjerp.zinus.com/jde", isFixed: false })
        this.items.push({ comCode: "C041;C042;C043;C044", shortCode: "zherp", name: "ERP - Zhangpu", link: "https://zperp.zinus.com/jde", isFixed: false })        

        //Indonesia 
        this.items.push({ comCode: "C051;C052", shortCode: "igw", name: "G/W (OfficeOn)", link: "http://zone1.zinus.com", isFixed: false })
        this.items.push({ comCode: "C051;C052", shortCode: "zgierp", name: "ERP - ZGI", link: "http://zgierp.zinus.com/jde/E1Menu.maf", isFixed: false })
        this.items.push({ comCode: "C051;C052", shortCode: "zdierp", name: "ERP - ZDI", link: "http://zdierp.zinus.com/jde/E1Menu.maf", isFixed: false })

        //USA
        this.items.push({ comCode: "C031;C032", shortCode: "cgw", name: "G/W( OfficeOn )", link: "http://zone1.zinus.com", isFixed: false })
        this.items.push({ comCode: "C031;C032", shortCode: "ult", name: "UltiPro", link: "https://nw11.ultipro.com/Login.aspx?ReturnUrl=%2f", isFixed: false })
        this.items.push({ comCode: "C031;C032", shortCode: "iacc", name: "iAccounting", link: "https://iac.zinus.com:44300/sap/bc/ui5_ui5/sap/ziapps_uwl/?/index.html?sap-client=100", isFixed: false })
        this.items.push({ comCode: "C031;C032", shortCode: "adp", name: "ADP", link: "https://online.adp.com/signin/v1/?APPID=WFNPortal&productId=80e309c3-7085-bae1-e053-3505430b5495&returnURL=https://workforcenow.adp.com/&callingAppId=WFN", isFixed: false })
        
        // Zinus KR	IAC	https://…..
        // Zinus KR	기존 그룹웨어	http://zone.zinus.com (이름 수정 예정)
        // Zinus KR	환율사이트	재무팀에서 사용하는 사이트 확인 필요
        // Zinus CN	G/W( OfficeOn )	
        // Zinus CN	ERP - Xiamen	https://xmerp.zinus.com:8081/jde/E1Menu.maf
        // Zinus CN	ERP - Fujian	https://fjerp.zinus.com/jde/E1Menu.maf
        // Zinus CN	ERP - Zhangpu	https://zperp.zinus.com/jde/E1Menu.maf
        // Zinus ID	ERP - ZGI	http://zgierp.zinus.com:8081/jde/E1Menu.maf
        // Zinus ID	ERP - ZDI	http://zdierp.zinus.com:8081/jde/E1Menu.maf
        // Zinus ID	G/W (OfficeOn)	http://zone.zinus.com
        // 미국 판매	UltiPro	
        // 미국 판매	iAccounting	
        // 미국 생산	ADP	
    }

    onClickLink(shortCode: string, isFixed: boolean = false) {
        // 2023-02-16 내부신고제 Link 변경으로 인한 분기 처리(언어별 다른 URL을 설정해주어야 함.)
        if (shortCode == 'WP'){
            var linkUrl = ''
            var userLang = this.current.userConfig.language;
            switch(userLang){
                case "ko-KR":   // 국문
                    linkUrl = 'https://esg.zinus.co.kr/kor/html/ethical/report.html';
                    break;
                case "en-US":   // 영문
                    linkUrl = 'https://esg.zinus.co.kr/eng/html/ethical/report.html';
                    break;
                default:        // 중문
                    linkUrl = 'https://esg.zinus.co.kr/kor/html/ethical/report_cn.html';
                    break;
            }

            if (linkUrl != ''){
                window.open(linkUrl);
            }
        }
        else {
            const findItem = (isFixed ? this.fixedItems : this.items).find(item => {
                return item.shortCode == shortCode
            })
            if (findItem) {
                window.open(findItem.link)
            }
        }
    }

    clickWebpartItem(linkUrl: string){
        if (linkUrl){
            window.open(linkUrl)
        }
    }

    filterByComCode() {
        let comCode = this.user.personCode.split("_")[0]

        

        return this.items.filter(item => {
            if (item.comCode) return item.comCode?.indexOf(comCode) > -1
        })
    }


}
