import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import {Wallpapers,Board} from '@gnb/components/Preference/General/Notice'
import { mapActions,mapGetters } from 'vuex'
import * as types from '@gnb/store/types';
import * as accountTypes from '@account/store/types'


@Component({
    components:{ModalHeader,ModalContent,ModalFooter,ContentItem,PreferenceContentTemplate,Wallpapers,Board},
    methods:{
      ...mapActions({
        loading: types.COMMON_LOADDING
      })
    },
    computed:{
      ...mapGetters({
        user: accountTypes.USER,
        isLoading: types.COMMON_LOADDING
      })
    }
})
export default class Notice extends Vue {
  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  get contentComponents() {
    return [Wallpapers, Board]
  }

  header:string = this.$t('알림').toString();
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: A C T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  loading!: (option: boolean) => void;

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  isLoading !: boolean;
  user!: account.User;

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  handleSave() {
    const contens = this.$refs.contents as any[]
    contens.forEach((x) => {
      x.save()
    })
  }
  
  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  created(){
    this.loading(true);
  }

}