<template>
  <div id="wrapper">
    <!-- <UserInfoWebpart /> -->
    <ListImageWebpart :option="webPartItem" />
  </div>
</template>

<script>
import ListImageWebpart from "../ListImageWebpart/ListImageWebpart.vue";
export default {
  components: { ListImageWebpart },
  data() {
    return {
      webPartItem: {
        tabs: [
          {
            boardId:29,
            displayCount:3,
            isContentShow:false,
            isImage:false,
            isNoticeShow:false,
            isPaging:false,
            tabTitle:this.$t('단순사진').toString()
          },
        ],
      },
    };
  },
};
</script>

<style></style>
