import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
 
@Component({
  
})

export default class TitleField extends Vue {
    @Prop ({ type: String }) displayName!: string;
    @Prop({ type: String }) fieldValue!: string; 

}