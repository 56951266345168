declare global {
  namespace datehelper {
    export interface Duration {
      Seconds: number
      Minutes: number
      Hours: number
      Days: number
      totalSeconds: number
      totalMinutes: number
      totalHours: number
      totalDays: number
      totalWeeks: number
      durationText: string // 1 일, 1.5 시간
      durationValue: string // 1.12:30:00
      error: string
    }
  }
}
import { i18n } from './i18n-setup'
export const uGetZero = (value: number | string) =>
  value.toString().length === 1 ? `0${value}` : value
/**
 * IE에서 문자열로 날짜 생성 시 ISOString 포멧으로 강제변환 함수
 * @param value 문자열 yyyy-MM-dd HH:mm:ss 형식의 경우 변환
 */
export const IEDate = function(value: any): Date {
  if (typeof value == typeof '') {
    if (
      value.indexOf('-') != -1 &&
      value.indexOf(':') != -1 &&
      value.indexOf(' ') != -1 &&
      value.indexOf('T') == -1 &&
      value.indexOf('Z') == -1
    ) {
      return new Date(value.replace(' ', 'T'))
    }
  }
  return new Date(value)
}

export const getDaysArray = (start: Date, end: Date) => {
  for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt))
  }
  return arr
}

/**
 * 시작과 끝의 일수를 계산하여 날자 목록을 가져옵니다.
 * @param start start date
 * @param end stop date
 * @returns ex) 2021-10-28
 */
export const getDates = (start: Date, end: Date): string[] => {
  var daylist = getDaysArray(start, end)
  if (daylist.length > 0) {
    return daylist.map((v) => v.toISOString().slice(0, 10))
  } else {
    return [new Date(start).toISOString().slice(0, 10)]
  }
}

/**
 * 날짜를 형식 문자열로 변환합니다
 * @param format
 * @param date
 */
export const uGetDateFormat = function(format: string, date: Date = new Date()): string {
  const _date = IEDate(date.getTime())

  return format.replace(/(yyyy|mm|dd|MM|DD|H|i|s)/g, (t: string): any => {
    switch (t) {
      case 'yyyy':
        return _date.getFullYear()
      case 'mm':
        return _date.getMonth() + 1
      case 'dd':
        return uGetZero(_date.getDate())
      case 'MM':
        return uGetZero(_date.getMonth() + 1)
      case 'DD':
        return uGetZero(_date.getDate())
      case 'H':
        return uGetZero(_date.getHours())
      case 'i':
        return uGetZero(_date.getMinutes())
      case 's':
        return uGetZero(_date.getSeconds())
      default:
        return ''
    }
  })
}
/**
 * 요일 배열을 반환합니다 text / value
 */
export const uGetWeekArray = function(
  WeekArray: string[] = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']
) {
  let list = []

  for (let i = 0; i < WeekArray.length; i++) {
    const week = WeekArray[i]
    list.push({ text: week, value: i })
  }

  return list
}

/**
 * 날짜의 시,분,초,밀리초를 0으로 초기화하여 날짜비교만 가능하도록 변경합니다
 * @param date
 */
export const uGetDateOnly = function(date: Date = new Date()): Date {
  let rDate = IEDate(date)
  return IEDate(
    '' +
      rDate.getFullYear() +
      '-' +
      uGetZero(rDate.getMonth() + 1) +
      '-' +
      uGetZero(rDate.getDate()) +
      'T00:00:00'
  )
}

/**
 * 날짜의 해당주 첫일로 세팅하여 반환합니다
 * @param date
 */
export const uGetWeekStartDate = function(date: Date = new Date(), firstDay: number = 0): Date {
  let mDate = IEDate(date)
  while (mDate.getDay() != firstDay) {
    mDate.setDate(mDate.getDate() - 1)
  }
  return mDate
}

/**
 * 날짜의 해당주 마지막일로 세팅하여 반환합니다
 * @param date
 */
export const uGetWeekEndDate = function(date: Date = new Date(), firstDay: number = 0): Date {
  let mDate = IEDate(date)
  while (mDate.getDay() != firstDay) {
    mDate.setDate(mDate.getDate() - 1)
  }
  mDate.setDate(mDate.getDate() + 6)
  return mDate
}

/**
 * 날짜의 해당월 첫일로 세팅하여 반환합니다
 * @param date
 */
export const uGetMonthStartDate = function(
  date: Date = new Date(),
  isForDisplay: boolean = true,
  firstDay: number = 0
): Date {
  let mDate = IEDate(date)
  mDate.setDate(1)
  return mDate
}

/**
 * 날짜의 해당월 마지막일로 세팅하여 반환합니다
 * @param date
 */
export const uGetMonthEndDate = function(
  date: Date = new Date(),
  isForDisplay: boolean = true,
  firstDay: number = 0
): Date {
  let mDate = IEDate(date)
  mDate.setDate(1)
  mDate.setMonth(mDate.getMonth() + 1)
  mDate.setDate(0)
  return mDate
}

/**
 *
 * @param date 선택날짜
 * @param bdate 현재날짜
 */
export const uGetDateForDisplayMonth = function(
  date: Date,
  mdate: Date,
  firstDay: number = 0
): Date {
  let monthDates = uGetDaysInMonth(mdate)
  let strDate = uGetDateFormat('yyyy-MM-DD', date)
  let strMonthDates = monthDates.map((x) => uGetDateFormat('yyyy-MM-DD', x))

  if (strMonthDates.indexOf(strDate) != -1) {
    return uGetMonthStartDate(mdate, true, firstDay)
  } else {
    return date
  }
}

/**
 * 현재월의 날짜 집합을 리턴합니다
 * @param date
 * @param isForDisplay 표시용도의 경우 전월 날짜와 다음월 날짜로 남은 공간을 채워줍니다 (기본 true)
 */
export const uGetDaysInMonth = function(
  date: Date = new Date(),
  isForDisplay: boolean = true,
  firstDay: number = 0,
  isSixWeek: boolean = false
): Date[] {
  //debugger;
  let DateArray: Date[] = []
  let behindArray: Date[] = []

  let StartDate = uGetMonthStartDate(date, isForDisplay, firstDay)
  let PrevShiftDays = StartDate.getDay()

  let EndDate = uGetMonthEndDate(date, isForDisplay, firstDay)
  let EndDateNum = EndDate.getDate()
  let NextShiftDays = 7 - EndDate.getDay() - 1

  // UI 그리는 용도의 경우 전월 날짜를 채워줌
  if (isForDisplay) {
    for (let i = 0; i < PrevShiftDays; i++) {
      let prvDate = IEDate(StartDate)
      prvDate.setDate(prvDate.getDate() - PrevShiftDays + i)
      DateArray.push(prvDate)
    }
  }

  for (let i = 0; i < EndDateNum; i++) {
    let curDate = IEDate(StartDate)
    curDate.setDate(curDate.getDate() + i)
    DateArray.push(curDate)
  }

  // UI 그리는 용도의 경우 다음월 날짜를 채워줌

  if (isForDisplay) {
    for (let i = 0; i < NextShiftDays; i++) {
      let nxtDate = IEDate(EndDate)
      nxtDate.setDate(nxtDate.getDate() + i + 1)
      DateArray.push(nxtDate)
    }

    if (isSixWeek && DateArray.length / 7 == 5) {
      for (let i = 1; i <= 7; i++) {
        let nxtDate = IEDate(DateArray[DateArray.length - 1])
        nxtDate.setDate(nxtDate.getDate() + 1)
        DateArray.push(nxtDate)
      }
    }
  }

  if (firstDay > 0) {
    let newNextShiftDays = 7 - ((EndDate.getDay() + firstDay) % 7) - 1
    let isAddedFront = false
    let isAddedNext = false

    let backDate = IEDate(DateArray[0])
    backDate.setDate(backDate.getDate() - 1)
    let backMonthArray = uGetDaysInMonth(backDate, true, 0)
    let newArray = backMonthArray.slice(backMonthArray.length - 7)

    if (
      newArray.filter((x) => x.getDate() == (DateArray[DateArray.length - 1] as Date).getDate())
    ) {
      if (StartDate.toString() == DateArray[0].toString()) {
        newArray = backMonthArray.slice(backMonthArray.length - 7)
      } else {
        newArray = backMonthArray.slice(backMonthArray.length - 14, backMonthArray.length - 7)
      }
    }

    DateArray = [...newArray, ...DateArray]
    isAddedFront = true

    DateArray.splice(0, firstDay)
    let isRemoveFirstLine = !DateArray.filter((x, index) => {
      return index < 7 && x.getDate() == 1
    }).length

    if (isRemoveFirstLine) {
      DateArray = DateArray.slice(7)
    }

    //꼬리
    while (Number(DateArray[DateArray.length - 1]) < Number(EndDate) || DateArray.length % 7 != 0) {
      let newDate = IEDate(DateArray[DateArray.length - 1])
      newDate.setDate(newDate.getDate() + 1)
      DateArray.push(newDate)
    }

    if (Number(DateArray[DateArray.length - 7]) > Number(EndDate)) {
      DateArray.splice(DateArray.length - 7)
    }
  }
  return DateArray
}

/**
 * 현재주의 날짜 집합을 리턴합니다
 * @param date
 * @param isForDisplay 표시용도의 경우 전월 날짜와 다음월 날짜로 남은 공간을 채워줍니다 (기본 true)
 */
export const uGetDaysInWeek = function(date: Date = new Date(), firstDay: number = 0): Date[] {
  let DateArray = []

  let StartDate = uGetWeekStartDate(date, firstDay)
  let EndDate = uGetWeekEndDate(date, firstDay)

  for (let i = 0; uGetDateOnly(StartDate) <= uGetDateOnly(EndDate); i++) {
    DateArray.push(uGetDateOnly(StartDate))
    StartDate.setDate(StartDate.getDate() + 1)
  }

  return DateArray
}

/**
 * 날짜가 시작일 종료일 범위에 포함하는지 여부를 리턴
 * @param date 날짜
 * @param start 시작일
 * @param end 종료일
 */
export const uGetIsInDate = function(date: Date, start: Date, end: Date): boolean {
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)

  let sDate = IEDate(start)
  let eDate = IEDate(end)

  sDate.setHours(0)
  sDate.setMinutes(0)
  sDate.setSeconds(0)

  eDate.setHours(23)
  eDate.setMinutes(59)
  eDate.setSeconds(59)

  return Number(date) >= Number(sDate) && Number(date) <= Number(eDate)
}

/**
 * 날짜가 시작일 종료일 범위에 포함하는지 여부를 리턴 (시간, 분, 초 포함)
 * @param date 날짜
 * @param start 시작일
 * @param end 종료일
 * @param isInitDateTime 기준날짜를 00:00:00 으로 초기화 여부 (기본 false)
 */
export const uGetIsInDateTime = function(
  date: Date,
  start: Date,
  end: Date,
  isInitDateTime = false
): boolean {
  if (isInitDateTime) {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
  }

  return Number(date) >= Number(start) && Number(date) <= Number(end)
}

/**
 * 날짜가 시작일 종료일 범위에 포함하는지 여부를 리턴 (시간, 분, 초 포함), 초과 미만
 * @param date 날짜
 * @param start 시작일
 * @param end 종료일
 * @param isInitDateTime 기준날짜를 00:00:00 으로 초기화 여부 (기본 false)
 */
export const uGetIsInDateTime2 = function(
  date: Date,
  start: Date,
  end: Date,
  isInitDateTime = false
): boolean {
  let oDate = new Date(date)
  if (isInitDateTime) {
    oDate.setHours(0)
    oDate.setMinutes(0)
    oDate.setSeconds(0)
  }

  return Number(oDate) > Number(start) && Number(oDate) < Number(end)
}

/**
 * 시간 배열을 가져옵니다 ({text, value} 배열)
 * @param resFunc $t 다국어 함수
 */
export const uGetTimeArray = function(displayArr: string[] = ['오전', '오후']): Array<any> {
  let list: Array<any> = []
  for (let i = 0; i < 24; i += 0.5) {
    const left: string = uGetZero(Math.floor(i)).toString()
    const right: string = i - Math.floor(i) == 0.5 ? '30' : '00'
    var ampm = ''
    if (i < 12) {
      ampm = displayArr[0]
    } else {
      ampm = displayArr[1]
    }
    const result = ampm + ' ' + left + ':' + right
    list.push({ text: result, value: left + ':' + right + ':00', num: i })
  }

  return list
}
/**
 * 월 배열을 가져옵니다 ({text, value} 배열)
 */
export const uGetMonthArray = function(displayArr: string[] = []): Array<any> {
  let list: Array<any> = []

  for (let i = 1; i <= 12; i++) {
    let text = i + '월'
    if (displayArr.length > i) {
      text = displayArr[i - 1]
    }
    list.push({ text: text, value: i })
  }
  return list
}

/**
 * 두 날짜차이의 총 시간을 분으로 반환합니다
 * @param start 시작날짜
 * @param end 종료날짜
 */
export const uGetTotalDiffMinutes = function(start: Date, end: Date): number {
  let diffMs = Number(end) - Number(start) // milliseconds between now & Christmas
  // let diffDays = Math.floor(diffMs / 86400000); // days
  // let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  // let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  let totalMins = Math.floor(diffMs / 1000 / 60)
  return totalMins
}

/**
 * 소스 날짜의 시작/종료 시간이 타겟 날짜의 시작/종료시과 서로 포함된 관계인지 여부를 리턴
 * @param sourceStart 소스날짜 시작시간
 * @param sourceEnd 소스날짜 종료시간
 * @param targetStart 타겟날짜 시작시간
 * @param targetEnd 타겟날짜 종료시간
 */
export const uGetInDateBoundary = function(
  sourceStart: Date,
  sourceEnd: Date,
  targetStart: Date,
  targetEnd: Date
): boolean {
  let ss = Number(sourceStart)
  let se = Number(sourceEnd)
  let ts = Number(targetStart)
  let te = Number(targetEnd)

  let isTargetContain = (ss < ts && se > ts) || (ss < te && se > te)
  let isSourceContain = (ts < ss && te > ss) || (ts < se && te > se)
  let isSame = ss == ts && se == te

  return isTargetContain || isSourceContain || isSame
}

/**
 * 소스 날짜의 시작/종료 시간이 타겟 날짜의 시작/종료시과 서로 포함된 관계인지 여부를 리턴
 * @param sourceStart 소스날짜 시작시간
 * @param sourceEnd 소스날짜 종료시간
 * @param targetStart 타겟날짜 시작시간
 * @param targetEnd 타겟날짜 종료시간
 */
 export const uGetInDateBoundaryEqual = function (sourceStart: Date, sourceEnd: Date, targetStart: Date, targetEnd: Date): boolean {
    let ss = Number(sourceStart);
    let se = Number(sourceEnd);
    let ts = Number(targetStart);
    let te = Number(targetEnd);

    let isTargetContain = (ss <= ts && se >= ts) || (ss <= te && se >= te);
    let isSourceContain = (ts <= ss && te >= ss) || (ts <= se && te >= se);
    let isSame = ss == ts && se == te;


    return isTargetContain || isSourceContain || isSame ;
}


/**
 * 특정 날짜를 특정 수치만큼 변경하여 반환합니다
 * @param difValue 날짜 변경 수치
 * @param difDate 날짜 지정 (기본 new Date())
 * @param difType 단위 타입 time / sec / min / hour / date / month / year (기본 date)
 */
export const uGetDate = function(
  difValue: number,
  difDate: Date = new Date(),
  difType: 'time' | 'sec' | 'min' | 'hour' | 'date' | 'month' | 'year' = 'date'
): Date {
  switch (difType) {
    case 'time':
      difDate.setTime(difDate.getTime() + difValue)
      break
    case 'sec':
      difDate.setSeconds(difDate.getSeconds() + difValue)
      break
    case 'min':
      difDate.setMinutes(difDate.getMinutes() + difValue)
      break
    case 'hour':
      difDate.setHours(difDate.getHours() + difValue)
      break
    case 'date':
      difDate.setDate(difDate.getDate() + difValue)
      break
    case 'month':
      difDate.setMonth(difDate.getMonth() + difValue)
      break
    case 'year':
      difDate.setFullYear(difDate.getFullYear() + difValue)
      break
    default:
      break
  }
  return difDate
}

/**
 * 문자열을 Duration 객체로 반환합니다
 * @param str ex) 1.5 시간
 */
export const getDurationFromText = function(str: string) {
  let text = (getStringWithoutNumber(str) + '').toLowerCase().trim()
  let numstr = str.replace(text, '')
  let num = Number(getOnlyNumber(str))
  let error = ''
  let sumSec = 0

  if (!isNaN(num)) {
    switch (text) {
      case 's':
      case 'sec':
      case 'secs':
      case 'second':
      case 'seconds':
      case '초':
        sumSec = num
        break
      case 'm':
      case 'min':
      case 'mins':
      case 'minute':
      case 'minutes':
      case '분':
        sumSec = num * 60
        break
      case 'h':
      case 'hour':
      case 'hours':
      case '시':
      case '시간':
        sumSec = num * 60 * 60
        break
      case 'd':
      case 'day':
      case 'days':
      case 'date':
      case '일':
        sumSec = num * 60 * 60 * 24
        break
      case 'w':
      case 'week':
      case 'weeks':
      case '주':
        sumSec = num * 60 * 60 * 24 * 7
        break
      default:
        error = `${i18n.t('message.M_Common_DateMsg2', { text: text })} `
        break
    }
  } else {
    error = `${i18n.t('message.M_Common_DateMsg1')}`
  }

  let Seconds = 0
  let Minutes = 0
  let Hours = 0
  let Days = 0
  let Weeks = 0

  let fixedDigit = 10
  let totalSeconds = sumSec + 0
  let totalWeeks = Number((sumSec / (86400 * 7)).toFixed(fixedDigit))
  let totalDays = Number((sumSec / 86400).toFixed(fixedDigit))
  let totalHours = Number((sumSec / 3600).toFixed(fixedDigit))
  let totalMins = Number((sumSec / 60).toFixed(fixedDigit))

  Weeks = Math.floor(sumSec / (86400 * 7))
  sumSec -= Weeks * (86400 * 7)

  Days = Math.floor(sumSec / 86400) % 7
  sumSec -= Days * 86400

  Hours = Math.floor(sumSec / 3600) % 24
  sumSec -= Hours * 3600

  Minutes = Math.floor(sumSec / 60) % 60
  sumSec -= Minutes * 60

  Seconds = sumSec % 60

  let time = uGetZero(Hours) + ':' + uGetZero(Minutes) + ':' + uGetZero(Seconds)

  let result = {
    totalSeconds: totalSeconds,
    totalMinutes: totalMins,
    totalHours: totalHours,
    totalDays: totalDays,
    totalWeeks: totalWeeks,
    Seconds: Seconds,
    Minutes: Minutes,
    Hours: Hours,
    Days: Days,
    durationText: str,
    durationValue: '',
    error: error,
  } as datehelper.Duration

  if (Days + Weeks * 7 > 0) {
    result.durationValue = Days + Weeks * 7 + '.' + time
  } else {
    result.durationValue = time
  }

  return result
}

/**
 * 종료일과 시작일의 차를 Duration 객체로 반환합니다
 * @param end
 * @param start
 */
export const getDurationFromDate = function(
  end: string | Date,
  start: string | Date,
  isAbs: boolean = true,
  displayArr: string[] = []
) {
  if (typeof end === typeof '') {
    end = (end as string).split('+')[0]
    end = IEDate(end as string)
  }

  if (typeof start === typeof '') {
    start = (start as string).split('+')[0]
    start = IEDate(start as string)
  }

  let diff = isAbs ? Math.abs(Number(end) - Number(start)) : Number(end) - Number(start)
  let sumSec = diff / 1000
  let input = getTextFromDuration(sumSec, displayArr)
  let Seconds = 0
  let Minutes = 0
  let Hours = 0
  let Days = 0
  let Weeks = 0

  let fixedDigit = 10
  let totalSeconds = sumSec + 0
  let totalWeeks = Number((sumSec / (86400 * 7)).toFixed(fixedDigit))
  let totalDays = Number((sumSec / 86400).toFixed(fixedDigit))
  let totalHours = Number((sumSec / 3600).toFixed(fixedDigit))
  let totalMins = Number((sumSec / 60).toFixed(fixedDigit))

  Weeks = Math.floor(sumSec / (86400 * 7))
  sumSec -= Weeks * (86400 * 7)

  Days = Math.floor(sumSec / 86400)
  sumSec -= Days * 86400

  Hours = Math.floor(sumSec / 3600) % 24
  sumSec -= Hours * 3600

  Minutes = Math.floor(sumSec / 60) % 60
  sumSec -= Minutes * 60

  Seconds = sumSec % 60

  let time = uGetZero(Hours) + ':' + uGetZero(Minutes) + ':' + uGetZero(Seconds)

  let result = {
    totalSeconds: totalSeconds,
    totalMinutes: totalMins,
    totalHours: totalHours,
    totalDays: totalDays,
    totalWeeks: totalWeeks,
    Seconds: Seconds,
    Minutes: Minutes,
    Hours: Hours,
    Days: Days,
    durationText: input,
    durationValue: '',
    error: '',
  } as datehelper.Duration

  if (Days > 0) {
    result.durationValue = Days + '.' + time
  } else {
    result.durationValue = time
  }

  return result
}

/**
 * Duration 문자열을 일반 시간 문자열로 반환합니다
 * @param duration 1.00:30:00 형식 문자열
 * @param displayArr 반환 시간 단위 문자배열 (기본값 : ['초','분','시간','일','주'])
 */
export const getTextFromDate = function(duration: string, displayArr: string[] = []) {
  let days = 0

  let daysStr = duration.split('.')[0]
  let timeStr = duration + ''
  if (daysStr != duration) {
    days = Number(daysStr)
    timeStr = duration.split('.')[1]
  }

  let tarr = timeStr.split(':')

  let hours = Number(tarr[0])
  let mins = Number(tarr[1])
  let secs = Number(tarr[2])

  let sumSec = days * 86400 + hours * 3600 + mins * 60 + secs
  return getTextFromDuration(sumSec, displayArr)
}

/**
 * 초(Second)의 합으로 일반 시간 문자열로 반환합니다
 * @param sumSec 초(Seconds)
 * @param displayArr 반환 시간 단위 문자배열 (기본값 : ['초','분','시간','일','주'])
 */
export const getTextFromDuration = function(sumSec: number, displayArr: string[] = []) {
  let _displayArr = ['초', '분', '시간', '일', '주']

  if (displayArr.length) {
    _displayArr = displayArr
  }

  let fixedDigit = 10

  let totalWeeks = Number((sumSec / (86400 * 7)).toFixed(fixedDigit))
  let totalDays = Number((sumSec / 86400).toFixed(fixedDigit))
  let totalHours = Number((sumSec / 3600).toFixed(fixedDigit))
  let totalMins = Number((sumSec / 60).toFixed(fixedDigit))

  let array = [sumSec, totalMins, totalHours, totalDays, totalWeeks].map((x, idx) => {
    return { value: x + '', text: _displayArr[idx], idx: idx }
  })

  array.sort(
    (a, b) =>
      a.value.replace('.', '').length - b.value.replace('.', '').length ||
      Number(a.value) - Number(b.value) ||
      a.idx - b.idx
  )

  let result = array[0]

  return result.value + ' ' + result.text
}

/**
 * 문자열만 반환합니다
 * @param str
 */
export const getStringWithoutNumber = function(str: string) {
  return str.replace(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g, '')
}

/**
 * 숫자만 반환합니다
 * @param str
 */
export const getOnlyNumber = function(str: string) {
  let array: RegExpMatchArray | null = str.match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g)
  return array?.join('')
}

/**
 * 날짜 기간선택 Duration 배열
 */
export const getDateTimeRangeTextArray = function() {
  let array = [
    '0 분',
    '5 분',
    '10 분',
    '15 분',
    '30 분',
    '1 시간',
    '2 시간',
    '3 시간',
    '4 시간',
    '5 시간',
    '6 시간',
    '7 시간',
    '8 시간',
    '9 시간',
    '10 시간',
    '11 시간',
    '0.5 일',
    '18 시간',
    '1 일',
    '2 일',
    '3 일',
    '4 일',
    '1 주',
    '2 주',
  ]
  let result = array.map((x) => getDurationFromText(x))
  return result
}

export const uGetTimeTextFromDurationValue = function(
  dvalue: string,
  displayArr: string[] = ['오전', '오후']
) {
  let tarr = dvalue.indexOf('.') == -1 ? dvalue.split(':') : dvalue.split('.')[1].split(':')

  let hours = Number(tarr[0])
  let mins = Number(tarr[1])
  let secs = Number(tarr[2])

  let ampmStr = ''

  if (hours < 12) {
    ampmStr = displayArr[0]
  } else {
    ampmStr = displayArr[1]
  }

  let text = `${ampmStr} ${uGetZero(hours)}:${uGetZero(mins)}`

  return { text: text, value: dvalue, num: hours + Number((mins / 60).toFixed(2)) }
}

export const DateAndTimeSplitor = function(dateString: string) {
  let arr: string[] = []
  if (dateString && typeof dateString == typeof '') {
    if (dateString.indexOf(' ') != -1) {
      arr = dateString.split(' ')
    } else if (dateString.indexOf('T') != -1) {
      arr = dateString.split('T')
    }

    if (arr.length > 1) {
      if (arr[1].indexOf('+') != -1) {
        arr = [arr[0], arr[1].split('+')[0]]
      }
    }
  }
  if (arr.length) {
    return arr
  } else {
    return [dateString]
  }
}

/**
 * 날짜를 형식 문자열로 변환합니다. (Board 에서 사용)
 * @param format
 * @param date
 */
export const uGetDateFormat_UTC = function(format: string, date: Date = new Date()): string {
  const _date = IEDate(date.getTime())

  return format.replace(/(yyyy|mm|dd|MM|DD|H|i|s)/g, (t: string): any => {
    switch (t) {
      case 'yyyy':
        return _date.getFullYear()
      case 'mm':
        return _date.getMonth() + 1
      case 'dd':
        return _date.getDate()
      case 'MM':
        return uGetZero(_date.getMonth() + 1)
      case 'DD':
        return uGetZero(_date.getDate())
      case 'H':
        return uGetZero(_date.getHours() + 9)
      case 'i':
        return uGetZero(_date.getMinutes())
      case 's':
        return uGetZero(_date.getSeconds())
      default:
        return ''
    }
  })
}

/**
 * 일주일 전을 계산합니다. (Board 에서 사용)
 * @param format
 * @param date
 */
export const getbeforeWeek = function(format: string, date: Date = new Date()): string {
  const _date = new Date(date.getTime())

  return format.replace(/(yyyy|mm|dd|MM|DD|H|i|s)/g, (t: string): any => {
    switch (t) {
      case 'yyyy':
        return _date.getFullYear()
      case 'mm':
        return _date.getMonth() + 1
      case 'dd':
        return _date.getDate()
      case 'MM':
        return uGetZero(_date.getMonth() + 1)
      case 'DD':
        return uGetZero(_date.getDate() - 7)
      case 'H':
        return uGetZero(_date.getHours() + 9)
      case 'i':
        return uGetZero(_date.getMinutes())
      case 's':
        return uGetZero(_date.getSeconds())
      default:
        return ''
    }
  })
}
