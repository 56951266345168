<template>
    <div class="no-data">
        <!-- <img src="/cm/gres/faux/images/no_data.png" class="m-b-l" /> -->
        <span class="ft-m ft-gray">{{ text }}</span>
    </div>
</template>

<script>
export default {
    props: {
        text: String,
    },
};
</script>

<style scoped>
.no-data {
    display: flex;
    justify-content: center;
    padding: 38px;
}
</style>