import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import {
    ModalHeader,
    ModalContent,
    ModalFooter,
    ContentItem,
} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import RuleModal from './RuleModal.vue'
import api from '@gnb/api/common'
import { mapActions } from 'vuex'
import * as types from '@gnb/store/types'
import * as orgapi from '@orgmap/api'
import RecipientField from '@gnb/components/Common/RecipientField.vue'

@Component({
    components: {
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        RuleModal,
        RecipientField,
    },
    methods: {
        ...mapActions({
            loading: types.COMMON_LOADDING,
        }),
    },
})
export default class AddRule extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({ type: Object }) value!: gnb.rules.Rule

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    item: gnb.rules.Rule = {
        id: '',
        name: '',
        priority: 1,
        isEnabled: true,
        operation: 'create',
        conditions: [],
        actions: [],
    }

    senderList: Array<gnb.memberInfo> = []
    receiverList: Array<gnb.memberInfo> = []
    title: string = ''
    content: string = ''
    show: boolean = false
    folder: gnb.FolderItem = {} as gnb.FolderItem
    folders: gnb.FolderItem[] = []
    CONST_HEADER: string = 'x-eum-deptcode: '
    header: string = ''
    isReadOnlySender: boolean = false
    receiverPlaceholder: string =
        this.$t('받는사람의_이름을_입력하세요').toString()
    senderPlaceholder: string =
        this.$t('보낸사람의_이름을_입력하세요').toString()
    loading!: (option: boolean) => void

    @Watch('title')
    onChangedTitle() {
        const conditions = this.item.conditions.find(
            (con: gnb.rules.RuleCondition) =>
                con.key == 'ContainsSubjectStrings'
        )
        if (conditions) {
            conditions.value = [this.title]
        } else {
            const newCondition = {
                key: 'ContainsSubjectStrings',
                value: [this.title],
            }
            this.item.conditions.push(newCondition)
        }
    }

    @Watch('content')
    onChangedContent() {
        const conditions = this.item.conditions.find(
            (con) => con.key == 'ContainsBodyStrings'
        )
        if (conditions) {
            conditions.value = [this.title]
        } else {
            const newCondition = {
                key: 'ContainsBodyStrings',
                value: [this.title],
            }
            this.item.conditions.push(newCondition)
        }
    }

    @Watch('header')
    onChangedHeader() {
        const conditions = this.item.conditions.find(
            (con) => con.key == 'ContainsHeaderStrings'
        )
        if (conditions) {
            conditions.value = [this.header]
        } else {
            const newCondition = {
                key: 'ContainsHeaderStrings',
                value: [this.header],
            }
            this.item.conditions.push(newCondition)
        }
    }

    @Watch('senderList')
    onChangedSender() {
        if (this.senderList) {
            let arrSender = new Array();
            this.senderList.forEach(sender => {
                arrSender.push(sender.email);
            });            

            //const firstEmail = this.senderList[0]?.email
            const condition = this.item.conditions.find(
                (con) => con.key == 'ContainsSenderStrings'
            )
            if (condition) {
                //condition.value = firstEmail ? [firstEmail] : []
                condition.value = (arrSender && arrSender.length > 0 ? arrSender : [])
            } else {
                const newCondition = {
                    key: 'ContainsSenderStrings',
                    //value: firstEmail ? [firstEmail] : [],
                    value: (arrSender && arrSender.length > 0 ? arrSender : [])
                }
                this.item.conditions.push(newCondition)
            }
        }
    }

    @Watch('receiverList')
    onChangedReceiver() {
        if (this.receiverList) {
            let emails = this.receiverList.map((m) => {
                return m.email
            })
            let conditions = this.item.conditions.find(
                (con) => con.key == 'SentToAddresses'
            )
            if (conditions) {
                conditions.value = emails
            } else {
                let newCondition = {
                    key: 'SentToAddresses',
                    value: emails,
                }
                this.item.conditions.push(newCondition)
            }
        }
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    openModal() {
        this.show = true
    }

    cancel() {
        this.show = false
    }

    selectedFolder(item: gnb.FolderItem) {
        this.folder = item
        this.item.actions = [{ key: 'MoveToFolder', value: item.id }]
        this.show = false
    }

    async convertToConditionString(value: any, key: string) {
        if (!value) return false
        const _this = this
        switch (key) {
            case 'ContainsSenderStrings':
                this.senderList = await Promise.all(
                    value.map((email: any) => {
                        return _this.getUserItem(email)
                    })
                )
                break
            case 'SentToAddresses':
                this.receiverList = await Promise.all(
                    value.map((a: any) => {
                        return this.getUserItem(a.address)
                    })
                )
                break
            case 'ContainsSubjectStrings':
                if (typeof value === 'object') {
                    this.title = value[0]
                } else {
                    this.title = value
                }
                break
            case 'ContainsBodyStrings':
                if (typeof value === 'object') {
                    this.content = value[0]
                } else {
                    this.content = value
                }
                break
            case 'ContainsHeaderStrings':
                if (typeof value === 'object') {
                    this.header = value[0]
                } else {
                    this.header = value
                }
                break
        }
    }

    getCondition(conditions: gnb.rules.RuleCondition[], key: string) {
        return conditions.find((c: gnb.rules.RuleCondition) => {
            return c.key === key
        })
    }

    convertToActionsString(value: any) {
        if (!value.actions) return ''

        const actions = value.actions
        return actions
            .map((c: any) => {
                switch (c.key) {
                    case 'MoveToFolder':
                        let folderName: string = ''

                        const getFolderName = (folders: gnb.FolderItem[]) => {
                            folders.forEach((f) => {
                                if (f.id === c.value) {
                                    folderName = f.displayName
                                }

                                if (f.children != null) {
                                    getFolderName(
                                        f.children as gnb.FolderItem[]
                                    )
                                }
                            })
                        }

                        getFolderName(this.folders)

                        return `${this.$t('M_Mail_MoveFormat')
                            .toString()
                            .replace('{0}', folderName)}`
                }
            })
            .join('; ')
    }

    @Watch('value')
    onitemChanged(newVal?: gnb.rules.Rule, oldVal?: gnb.rules.Rule) {
        if (newVal) {
            const getCondition = (key: string) =>
                newVal.conditions.find((c) => c.key === key)?.value
            let sender = getCondition('ContainsSenderStrings')
            let receiver = getCondition('SentToAddresses')
            let header = getCondition('ContainsHeaderStrings')
            let subject = getCondition('ContainsSubjectStrings')
            let content = getCondition('ContainsBodyStrings')

            if (sender) {
                this.convertToConditionString(sender, 'ContainsSenderStrings')
            }
            if (receiver) {
                this.convertToConditionString(receiver, 'SentToAddresses')
            }
            if (header) {
                this.convertToConditionString(header, 'ContainsHeaderStrings')
            }
            if (subject) {
                this.convertToConditionString(subject, 'ContainsSubjectStrings')
            }
            if (content) {
                this.convertToConditionString(content, 'ContainsBodyStrings')
            }
        } else {
            throw new Error('invalid operation errror')
        }
    }

    async getUserItem(email: string) {
        return await api.getUserInfoByEmail(email).then((res) => {
            return <orgmap.UserItem>{
                displayName: res.items[0]?.displayName,
                titleName: res.items[0]?.titleName,
                deptName: res.items[0]?.deptName,
                email: res.items[0]?.email,
            }
        })
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    created() {
        api.getFolder().then((res) => {
            this.folders = res
        })

        this.item = this.value
        if (this.item) {
            this.onitemChanged(this.item)
        }
    }
}
