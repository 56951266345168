import api from '@/api'

export default {

    isApprover: (): Promise<boolean> => {
        return api.get({
            url: `/CommonReserveDb/approval/api/isapprover`,
        })
    },

    getApprovals: (param:reservedb.RequestApprovalPageSet): Promise<reservedb.ResponseApprovalPageSet> => {
        console.log(param)
        return api.post({
            url: `/CommonReserveDb/approval/api/list`,
            data: param
            
        })
    },

    updateApprovals: (approvals:Array<reservedb.ApprovalItem>): Promise<boolean> =>{
        return api.post({
            url: `/CommonReserveDb/approval/api`,
            data : approvals
        })
    },

    deleteApprovals: (targets:Array<number>): Promise<boolean> =>{
        return api.delete({
            url: `/CommonReserveDb/approval/api`,
            data : targets
        })
    }

}