import accountApi from "@account/api";
import { debounce } from "./debounce";
import { invalidateUrl } from "./invalidate-url";
interface CacheData<TValue> {
  [key: string]: TValue;
}

interface ICacheManager<TValue> {
  data: CacheData<TValue>;
  get: (key: string) => Promise<TValue>;
  set: (key: string, value: TValue) => void;
}

abstract class CacheManagerBase<TValue> implements ICacheManager<TValue> {
  constructor() {
    this.debounceFunction = debounce(this.set);
  }

  data: CacheData<TValue> = Object.create(null);

  abstract get(key: string): Promise<TValue>;

  set(key: string, value: TValue) {
    this.data[key] = value;
  }

  debounceFunction!: (key: string, value: TValue) => void;
}

class AvatarCacheManager extends CacheManagerBase<string> {
  constructor() {
    super();
  }

  get(email: string) {
    var avatar = this.data[email];
    if (avatar) {
      return Promise.resolve(avatar);
    } else {
      return accountApi.account.getPhotoUrl(email).then((url) => {
        if (!url) {
          url = "/cm/gres/img/ghost.png";
        } else {
          url = invalidateUrl(url);
        }
        console.log("image", url);
        this.set(email, url);
        return url;
      });
    }
  }

  getByCodes(codes: string[]): Promise<any[]> {
    return accountApi.account.getPhotoUrlByCodes(codes).then((res) => {
      res.forEach((p) => this.set(p.personCode, p.photoPath))
      return res
    })
  }
}

export type Cache = {
  avatar: AvatarCacheManager;
};

export const cache: Cache = {
  avatar: new AvatarCacheManager(),
};
