
import Vue from 'vue'
import $ from 'jquery'
import { Component, Prop } from 'vue-property-decorator'
import { mapGetters, mapActions, mapMutations } from 'vuex'

@Component({
    name: 'iframe-modal',
})
export default class IframeModal extends Vue {
    created() {
        //@ts-ignore
        window.isTeams = this.isTeams
        //@ts-ignore
        window['showiFrameWindow'] = (
            newUrl: string,
            title?: string,
            style?: any
        ) => {
            if (style.width) {
                $('#dvlayerPopup .modal-size').css('width', style.width)
                $('#dvlayerPopup .optional-modal-w-full').removeClass(
                    'optional-modal-w-full'
                )
            }

            var $layerInner = $('#dvlayerPopup .modal-flex-inner')
            if ($layerInner) {
                if (style.top) {
                    $layerInner.css('margin-top', style.top)
                }

                if (style.left) {
                    $layerInner.css('margin-left', style.left)
                }
            }

            //@ts-ignore
            window.iframeClosed = false
            $('#dvlayerPopup iframe').attr('src', newUrl)
            if (title !== undefined && title !== '') {
                //$('#dvlayerTitle').html(title);
            }

            var $layer = $('#dvlayerPopup')
            if ($layer.length) {
                var $ifrm = $layer.find('iframe')
                if ($ifrm.length) {
                    // $ifrm.get(0)?.style.setProperty('width', ((style?.width || 600) + 200) + 'px', 'important');
                    // $ifrm.get(0)?.style.setProperty('height', ((style?.height || 400) + 200) + 'px', 'important');
                    $ifrm.on('load', (e) => {
                        var iframe = e.target
                        var $html = $((iframe as any).contentDocument).find(
                            'html'
                        )
                        if ($html.length) {
                            var intervalCount = 10000
                            var flag = false
                            var trigger = setInterval(() => {
                                var $common = $(
                                    (iframe as any).contentDocument
                                ).find('#common-wrapper')

                                if ($common.length && !flag) {
                                    flag = true
                                    $common.addClass('inner-frame')

                                    var $headerTitle = $html.find(
                                        '.pop-header > .dp-flex .title'
                                    )

                                    var $footer = $html.find(
                                        '.pop-footer > .dp-flex'
                                    )

                                    var $mheader = $layer.find('.modal-header')
                                    var $mfooter = $layer.find('.modal-footer')

                                    //$mfooter.empty();

                                    if ($headerTitle.length) {
                                        var text = $headerTitle.text()
                                        //$header.appendTo($mheader);
                                        $mheader.find('.title').text(text)
                                        //$html.find('.pop-header').hide();
                                    }

                                    if ($footer.length) {
                                        $footer.appendTo($mfooter)
                                        //$html.find('.pop-footer').hide();
                                    }

                                    clearInterval(trigger)
                                }
                                intervalCount--

                                if (intervalCount < 0 && !flag) {
                                    clearInterval(trigger)
                                }
                            }, 11)

                            //$html.get(0)?.style.setProperty('overflow', 'hidden', 'important');
                        }
                    })
                }

                $layer.css({
                    opacity: 0,
                    filter: 'Alpha(Opacity=0)',
                })

                $layer.show()
                $layer.animate(
                    {
                        opacity: 1,
                        filter: 'Alpha(Opacity=100)',
                    },
                    {
                        duration: 500,
                        complete: () => {
                            $layer.removeAttr('style')
                            $layer.show()
                        },
                    }
                )
            }
        }
    }
}
