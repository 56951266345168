import api from '@gnb/api/common'
import { ActionTree } from 'vuex';
import * as types from './types';
const DEFAULT_LANGUAGE = 'ko-KR';
import { i18n } from '@appbase/util';
export default <ActionTree<gnb.state.GnbState, RootState>>{
    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M M O N : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    /**
     * 로딩 UI를 호출합니다
     * @param action 
     * @param flag 
     */
     [types.COMMON_LOADDING](action, flag: boolean) {
        action.commit(types.COMMON_LOADDING, flag);        
    },

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: S E T T I N G S : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    [types.SETTINGS_MYSETTING_LOAD](action){
        return api.getMySetting()
            .then((res: gnb.CM_MySetting) => {
                action.commit(types.SETTINGS_MYSETTING_LOAD, res);
                return res;
            })
    },

    [types.SETTINGS_MYSETTING_SAVE](action, payload: { data: gnb.CM_MySetting, isFirst: boolean }){        
        const { data, isFirst } = payload;
        // 처음 환경설정을 하는 경우
        if(isFirst){
            return api.createMySetting(data)
                .then((res) => {
                    action.dispatch(types.SETTINGS_MYSETTING_LOAD);
                    return res;
                });
        }
        // 환경설정을 수정하는 경우
        else{
            return api.setMysetting(data)
                .then((res) => {
                    action.dispatch(types.SETTINGS_MYSETTING_LOAD);
                    return res;
                });
        }
    },

    [types.APPLICATION_LIST_LOAD](action, payload: { comCode: string, personCode?: string }){
        const { comCode, personCode } = payload;
        api.getApplicationList(comCode, personCode)
            .then((res: gnb.CM_ApplicationList) => {
                console.log('APPLICATION_LIST_LOAD', res);
                action.commit(types.APPLICATION_LIST_LOAD, res);
            });
    },

    [types.SETTINGS_DELEGATOR_LIST_LOAD](action, personCode: string) {
        return api.getDelegationList(personCode)
            .then((res: gnb.CM_DelegationList) => {
                action.commit(types.SETTINGS_DELEGATOR_LIST_LOAD, res);
                return res;
            })
    },

    
    [types.SETTINGS_DELEGATOR_ADD](action, payload: gnb.CM_DelegationItem) {
        const { delegation } = payload;
        return api.setDelegator(delegation)
            .then((res: boolean) => {
                if(res){
                    action.dispatch(types.SETTINGS_DELEGATOR_LIST_LOAD, delegation.personCode);
                }
                return res;
            });
    },

    [types.SETTINGS_DELEGATOR_REMOVE](action, payload: gnb.CM_DelegationItem) {
        const { delegation } = payload;
        const { seq, personCode } = delegation;
        
        return api.removeDelegator({ Seq: seq, DeleteFlag: true })
            .then((res: boolean) => {
                if(res){
                    action.dispatch(types.SETTINGS_DELEGATOR_LIST_LOAD, personCode);
                }
                return res;
            })
    },
    //
    // ────────────────────────────────────────────────────────────────────── I ──────────
    //   :::::: L A N G U A G E A N D T I M E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────────────────
    //
    
    [types.LANGUAGE_LOAD](action) {
        action.commit(types.LANGUAGE_LOAD);
    },

    [types.TIME_LOAD](action) {
        action.commit(types.TIME_LOAD);
    },

    [types.LANGUAGE_TIME_CHANGE](action, item: gnb.UserConfig) {
        console.log('22222222222222222', item)
       return api.setUserConfig(item);
    },

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: U S E R C A R D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    [types.GET_CARDSTATE](action){
        return api.getUserBusinessCardStateList().then((res)=>{
            if(res){
                action.commit(types.GET_CARDSTATE, res);
                return res;
            }
        });
    },  

    [types.GET_CARD](action, personCode:string){
        return api.getUserBusinessCardInfo(personCode).then((res)=>{
            if(res){
                action.commit(types.GET_CARD, res);
                return res;
            }
        });
    },

    [types.UPDATE_CARD](action, item:gnb.UserBusinessCard){
        return api.setUserBusinessCardInfo(item).then((res)=>{
            if(res){
                action.dispatch(types.GET_CARD,item.personCode)
                return res;
            }
        })
    },

    [types.DOWNLOAD_PC](action, item: gnb.downloadOption): Promise<any> {
        return api.checkDownloadEml(item).then(async checkResult => {
            if (checkResult.succeed) {
                return api
                    .downloadPC(item)
                    .then(() => {
                        return checkResult;
                    })
                    .catch(async (err) => {
                        await (window as any).alertAsync(i18n.t('M_Settings_Msg43'));
                        return checkResult;
                    });
            }
            else {
                const { succeed, resultCode, exception, maxSize, transitionSize } = checkResult;
                const readablizeBytes = (bytes: number) => {
                    var s = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
                    var e = Math.floor(Math.log(bytes) / Math.log(1024));
                    return (bytes / Math.pow(1024, e)) + ' ' + s[e];
                };
                const nMaxSize = readablizeBytes(maxSize);
                const nTransitionSize = readablizeBytes(transitionSize);
                switch (resultCode) {
                    case 'MaximumSizeExceeded':
                        await (window as any).alertAsync(i18n.t('M_Mail_ArchiveErrorMaximumSizeExceeded', { size: nMaxSize }).toString());
                        break;

                    case 'SwitchLargeCapacity':
                        await (window as any).alertAsync(i18n.t('M_Mail_ArchiveErrorSwitchLargeCapacity', { size: nTransitionSize }).toString());
                        break;
                }
                return checkResult;
            }
        });
    },
    
};
