import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// initial state
const state: account.state.AccountState = {
  current: null,
}

export default {
  state,
  actions,
  getters,
  mutations,
}
