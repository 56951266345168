import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
  ContentItem,
  Dropdown,
} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import { Language, Time } from '@gnb/components/Preference/General/LanguageTime'
import { mapGetters,mapActions } from 'vuex'
import * as accountTypes from '@account/store/types'
import * as types from '@gnb/store/types';

@Component({
  components: {
    ModalHeader,
    ModalContent,
    ModalFooter,
    ContentItem,
    PreferenceContentTemplate,
    Dropdown,
    Language,
    Time,
  },
  methods: {
    ...mapActions({
     loading: types.COMMON_LOADDING
    }),
  },
  computed: {
    ...mapGetters({
      user: accountTypes.USER,
      isLoading: types.COMMON_LOADDING
    })
  }
})
export default class LanguageTime extends Vue {
  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  get contentComponents() {
    return [Language, Time]
  }

  header:string = this.$t('M_Settings_Msg36').toString();

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: A C T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  
  loading!: (option: boolean) => void;

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  isLoading !: boolean;
  user!: account.User;
 
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  handleSave() {
    const contens = this.$refs.contents as any[]
    contens.forEach((x) => {
      x.save()
    })
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  created(){
    this.loading(true);
  }
 
}
