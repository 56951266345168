import board from './board';
import article from './article';
import boardmail from './boardmail';
import helpdesk from "./helpdesk"

export default{
    board,
    article,
    boardmail,
    helpdesk
}