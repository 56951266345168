export default (function() {
  return {
    set(key: string, value: any) {
      if (!this.isSupported()) {
        return
      }

      localStorage.setItem(key, JSON.stringify(value))
    },

    get(key: string) {
      if (!this.isSupported()) {
        return
      }

      const value = localStorage.getItem(key)
      //console.log(key, value)
      return value ? JSON.parse(value) : null
    },

    remove(key: string) {
      if (!this.isSupported()) {
        return
      }

      localStorage.removeItem(key)
    },

    isSupported() {
      return typeof Storage !== 'undefined'
    },
  }
})()
