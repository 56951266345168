import axios from 'axios'

export default {
  loadMenuCache(comCode: string, version: number): Promise<appbase.cache.TMenuSource> {
    return axios({
      method: 'GET',
      url: `/cm/gres/libs/portal-cache/menu-${comCode.toLowerCase()}.json?ver=${version}`,
    }).then((res) => res.data)
  },
  loadQuickMenuCache(comCode: string, version: number): Promise<appbase.cache.TMenuSource> {
    return axios({
      method: 'GET',
      url: `/cm/gres/libs/portal-cache/quick-menu-${comCode.toLowerCase()}.json?ver=${version}`,
    }).then((res) => res.data)
  },
  loadWebpartCache(comCode: string, version: number): Promise<appbase.cache.TWebpartSource[]> {
    return axios({
      method: 'GET',
      url: `/cm/gres/libs/portal-cache/webpart-${comCode.toLowerCase()}.json?ver=${version}`,
    }).then((res) => res.data)
  },
  loadDictionary(lang: string, version: number): Promise<any> {
    return axios({
      method: 'GET',
      url: `/cm/gres/libs/portal-cache/${lang}.json?ver=${version}`,
    }).then((res) => res.data)
  },
}
