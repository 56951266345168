//import modalHelper from '@appbase/util/modal-helper'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { LoadingBar } from '@appbase/components/loading'

@Component({
  components: { LoadingBar },
})
export default class ModalLayout extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: String, default: '' }) title!: string
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ type: Boolean, default: false }) full!: boolean
  @Prop({ type: String }) loadingMessage!: string
  @Prop({ type: String, default: `width:400px` }) mStyle?: string
  @Prop({ type: Boolean, default: false }) isEmit!: boolean
  @Prop({ type: Boolean, default: false }) noFooter!: boolean
  @Prop({ type: Boolean, default: false }) show!: boolean


}
