import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// initial state
const state: boardModule.state.BoardState = {
  boardLoading: false,
  articleLoading: false,  
  baseUrl: '/',
  popupBaseName: 'BoardPopup',
  boardConfig: null,
  articleList: [],
  blogArticleList: [],
  contentField: [],
  listField: [],
  writeConfig: null,
  pageSet: {
    boardId: 0,
    currentPage: 1,
    searchField: 0,
    searchText: null,
    useNotice: false,
    preface1: null,
    preface2: null,
  },
//법인게시판정보
  coperationBoardInfo : [],
  helpdeskCategories : []
}

const storeBoard = (options: any = {}) => {
  const { baseUrl, popupBaseName } = options
  if (baseUrl) state.baseUrl = baseUrl
  if (popupBaseName) state.popupBaseName = popupBaseName

  return {
    state,
    actions,
    getters,
    mutations,
  }
}

export default storeBoard
