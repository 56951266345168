export enum EnumCalendarViewType {
    /**
     * 일별 보기
     */
    Daily = 0,

    /**
     * 주별 보기
     */
    Week = 1,

    /**
     * 월별 보기
     */
    Month = 2,
}

export enum ReserveResourceMode {
    Write = 0,
    Edit = 1,
    Read = 2
}