import { FlowFile } from '@appbase/libs/flowjs'
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import flowHelper from '../flow-helper'

@Component
export default class FileListItem extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: Object, required: true }) file!: FlowFile

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //

  get size(): string {
    return flowHelper.readablizeBytes(this.file.size, 0)
  }

  download(file: any) {
    if (file.blob || file.file) {
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(file.blob || file.file, file.name);
      } else {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(file.blob || file.file);
        link.download = `${file.name}`;
        link.click();
      }
    } else {
      let link = document.createElement('a');
      link.href = file.downloadUrl;
      link.target = "_blank";
      link.download = `${file.name}`;
      link.click();
    }
  }
}
