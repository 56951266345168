import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({

})
export default class CheckSlider extends Vue {
    @Prop({ type: Boolean }) value!: Boolean;      
    @Prop({ type: String }) label!: string;      
        
    checkBoxModel: Boolean = false;

    @Watch('value')
    onValueChange(newVal: Boolean, oldVal: Boolean) {
        this.checkBoxModel = this.value;
    }

    created() {       
        this.checkBoxModel = this.value;
    }

    onChange() {
        this.$emit('input', this.checkBoxModel);
        this.$emit('change', this.checkBoxModel);
    }
}
