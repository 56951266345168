import { Dictionary } from 'vue-router/types/router'

export function combindWithQuery(url: string, query: { [key: string]: any }): string {
  console.log('combindWithQuery', url, query)
  let keys: string[]
  if (query && (keys = Object.keys(query)).length > 0) {
    url += '?'
    url += keys
      .map((key) => {
        var value = query[key]
        if (typeof value === 'object') {
          value = JSON.stringify(value)
        }
        return `${key}=${encodeURIComponent(value)}`
      })
      .join('&')
    return url
  } else {
    return url
  }
}

export function getUrlParamToDictionary(url: string): Dictionary<string>{
  var dicObject = {...Object.create(null)};
  var urlSplit = url.split('?');
  if(urlSplit.length == 2){
      let vars = urlSplit[1].split('&');
      let tmp = [];
      for(var i = 0 ; i < vars.length; i++){
          tmp = vars[i].split('=');
          if(tmp.length == 2)
          {
              dicObject[tmp[0].toString()] = tmp[1];
          }
      }
  }
  return dicObject;
}
