import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'

const evaluateGuards = async (
  guards: NavigationGuard[],
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) => {
  const guardsLeft = guards.slice(0) // clone the array so we do not accidentally modify it
  const nextGuard = guardsLeft.shift()

  if (nextGuard) {
    await nextGuard(to, from, async (nextArg) => {
      if (nextArg) {
        next(nextArg)
      } else {
        await evaluateGuards(guardsLeft, to, from, next)
      }
    })
  } else next()
}

const multiguard = function(guards: NavigationGuard[], bypass: string[]): NavigationGuard {
  if (!Array.isArray(guards)) throw new Error('You must specify an array of guards')

  return (to, from, next) => {
    if (bypass.includes(to.path)) {
      next()
    } else {
      evaluateGuards(guards, to, from, next)
    }
  }
}

export default multiguard
