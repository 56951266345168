import Vue from 'vue'
import { Component, Prop} from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import api from '@gnb/api/common'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@gnb/store/types'

@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        Dropdown
    },
    methods:{
        ...mapActions({
            loading: types.COMMON_LOADDING
        })
    },
})
export default class CategoryView extends Vue {
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    loading!: (option: boolean) => void;
    
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    @Prop({type: Array}) categories!: gnb.CategoryItem[];
    categoryEdit: string = this.$t('범주명_수정').toString();
    categoryDelete: string = this.$t('범주명_삭제').toString();

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    setCategoryView(item: gnb.CategoryItem) {
        const categories = this.categories;

        let idx = categories.findIndex((r: any) => {
            return r.id === item.id;
        });

        if (item.operation === 'create') {
            // 추가
            if (idx >= 0) {
                // 추가한 데이터를 수정할 경우
                this.categories.splice(idx, 1, item);
            } else {
                this.categories.push(item);
            }
        } else if (item.operation === 'set') {
            // 수정
            this.categories.splice(idx, 1, item);
        } else if (item.operation === 'delete') {
            // 삭제
            if (categories[idx].id.indexOf('temp_') > -1) {
                // 추가한 데이터를 삭제할 경우
                this.categories.splice(idx, 1);
            } else {
                // 기존 데이터를 삭제할 경우
                this.categories.splice(idx, 1, item);
            }
        }
    }


    edit(item:gnb.CategoryItem){
        this.$emit('select', item)
    }

    async deleteItem(item:gnb.CategoryItem){
        const message = this.$t('M_Settings_Delete_Rule').toString();
        if (await(window as any).confirmAsync(message) == false) {
            return false;
        } else {
            api.deleteCategory(item.id).then(async (res)=>{
                await (window as any).alertAsync(this.$t('삭제되었습니다').toString());
            })
            item.operation = 'delete';
            this.setCategoryView(item);
        }
    }

    setFavorite(item:gnb.CategoryItem){
        
    }

    convertToClass(color: string){
        let index;

        switch(color){
            case 'Blue':
                index = 1;
                break; 
            case 'Green':
                index = 2;
                break;
            case 'Red':
                index = 3;
                break;
            case 'Orange':
                index = 4;
                break;
            case 'Yellow':
                index = 5;
                break;
            case 'Purple':
                index = 9;
                break; 
        }
        return index;
    }
}