import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import StorageView from '@gnb/components/Preference/Mail/Storage/StorageView.vue'
import { ModalLayout } from '@appbase/templates'
import PopupModal from '@gnb/components/Common/PopupModal.vue'
import SimpleFolderList from '@gnb/components/Common/SimpleFolderList.vue'
@Component({
    components:{PopupModal,ModalLayout,ModalHeader,ModalContent,ModalFooter,ContentItem,PreferenceContentTemplate,StorageView,SimpleFolderList}
})
export default class RuleModal extends Vue {
    @Prop({type: Boolean}) show!: false;
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    selectedFolder: gnb.MailboxFolder | null = null;
    title: string = this.$t('M_Mail_SelectedFolder').toString();
    
 
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    select(folder: gnb.MailboxFolder) {
        this.selectedFolder = folder;
    }

    save(){
        this.$emit('save', this.selectedFolder)
    }

    cancel(){
        this.$emit('cancel');
    }
}