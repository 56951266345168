import api from '@/api';
export default {
    /**
     * 현재 사용자 정보를 가져옵니다.
     */
    getCurrentUser(): Promise<account.User> {
        return api.get<account.User>({ url: '/api/account/user/current' });
    },

    getBoard(boardId: number): Promise<board.BoardItem>{
        return api.get<board.BoardItem>({
            url:`/api/BoardApi/Board?boardId=${boardId}`
        })
    },
    getWriteConfig(boardId: number): Promise<board.WriteConfig>{
        return api.get<board.WriteConfig>({
            url: `/api/boardapi/Board/writeConfig?boardId=${boardId}`
        })
    },
    getReadConfig(boardId: number): Promise<board.ReadConfig>{
        return api.get<board.ReadConfig>({
            url: `/api/boardapi/Board/writeConfig?boardId=${boardId}`
        })
    },
    FindCoperationBoard(): Promise<Array<board.CoperationBoard>>{
        return api.get<Array<board.CoperationBoard>>({
            url: `/api/z/boardproxy`
        })
    },
    getCustomBoardAuthCheck(menuId: number, personCode: string): Promise<boolean> {
        return api.get<boolean>({
            url: `/api/z/boardproxy/boardMenuAuthCheck?MenuId=${menuId}&PersonCode=${personCode}`
        });
    },
    getCustomBoardManagerCheck(boardId: number, personCode: string): Promise<boolean> {
        return api.get<boolean>({
            url: `/api/z/boardproxy/boardMenuManagerCheck?BoardId=${boardId}&PersonCode=${personCode}`
        });
    },
    getCustomBoardAuthCheckToBoardId(boardId: number, personCode: string): Promise<boolean> {
        return api.get<boolean>({
            url: `/api/z/boardproxy/boardMenuAuthCheckToBoardId?BoardId=${boardId}&PersonCode=${personCode}`
        });
    }
}