import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
})
export default class ContentItem extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({ type:String }) name!: '';
    @Prop({ type:String }) number!: '';

}