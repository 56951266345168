import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import * as dateutil from '@appbase/util/date-helper'
import { openPopup } from '@appbase/util/popup-helper'

@Component({
    components: {

    }
})
export default class TotalBoardImageArticle extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    @Prop({type: Object}) board!: any;
    @Prop({type: Object}) article!: any;
    @Prop({type: Boolean, default: false}) isPreview!: boolean;
    @Prop({type: String, default: ''}) boardDefaultImage?: string;

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //
    get createdDate(){
        return dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(new Date(this.article.createdDate)));
    }

    get isNew(){
        if(this.createdDate == dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(new Date()))){
            return true;
        }
        else{
            return false;
        }
    }

    get InnerText(){
        var divElem = document.createElement('div');
        divElem.innerHTML = this.article.content;
        return divElem.textContent || divElem.innerText || "";
    }

    get getWidth(){
        return `col-${Math.floor(12/this.board.displayCount)}`
    }

    get image(){
        return this.article.imageFilePath ? this.article.imageFilePath : this.boardDefaultImage;
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    articleClick(){
        if (!this.isPreview) {
            openPopup('TotalBoardImageArticle', `/BoardPopup/readPopup?SYSID=${this.article.boardId}&ARTICLEID=${this.article.articleId}`, {}, {
                width: 1024,
                height: 700,
            });
        }
    }
}
