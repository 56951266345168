import Vue from 'vue'
import api from '../../api'
import store from '@/store'
import { NavigationGuard } from 'vue-router'
import { setI18nLanguage } from '../../util/i18n-setup'

const I18nGuard: NavigationGuard = async (to, from, next) => {
  console.log(`Evaluating ${I18nGuard.name}...`)
  const current = (store.state as any).$account?.current as account.TCurrent | null | undefined
  if (current) {
    var lang = current.userConfig.language.substring(0, 2)
    if(lang == 'zh'){
      lang = 'cn';
    }
    const dic = await api.cache.loadDictionary(lang, current.cacheVersion.lang || 0)
    Vue.prototype.portalCache = {
      ...Vue.prototype.portalCache,
      dic,
      lang,
    }
    setI18nLanguage(lang, dic)
    next()
  } else {
    throw new Error(
      `[INVALID_OPERATION_ERROR] ${I18nGuard.name} : store.state.$account is not initialized.`
    )
  }
}

export default I18nGuard
