import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import api from '@board/api'

import ModalLayout from '@appbase/templates/ModalLayout/ModalLayout.vue'

@Component({
    components: {
        ModalLayout
    }
})
export default class PasswordCheck  extends Vue {
    @Prop({ type: Number }) articleId!: number;

    password: string = "";

    onConfirm() {
        api.article.checkArticlePassword({
            articleId: this.articleId,
            password: this.password
        }).then(async res => {
            if(res) {
                this.$emit('confirm', this.password);
            } else {
                await (window as any).alertAsync('패스워드가 일치하지 않습니다.');
            }
        });
    }

    onCancel() {
        this.password = "";
        this.$emit('cancel');
    }
}