
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import api from '@gnb/api/common'
import * as accountTypes from '@account/store/types'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@gnb/store/types'

@Component({
    components:{ModalHeader,ModalContent,ModalFooter,ContentItem,PreferenceContentTemplate,Dropdown},
    computed: {
        ...mapGetters({
          user: accountTypes.USER,
        })
    },
    methods: {
        ...mapActions({
            loading: types.COMMON_LOADDING
        })
    }
})
export default class DeleteView extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    isPermanentDelete: boolean = false;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    loading!: (option: boolean) => void;
        
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    user!: account.User;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    save(){
        api.setUserConfig({personCode: this.user.personCode, isPermanentDelete: this.isPermanentDelete}as gnb.UserConfig).then(async ()=>{
            await (window as any).alertAsync(this.$t('M_Expense_SavedAlert').toString());
        }).then(()=>{
            this.$emit('cancel');
        })
    }

    getDeleteInfo(){
        api.getUserConfig(this.user.personCode).then((res)=>{
            this.isPermanentDelete = res[0].isPermanentDelete;
            this.loading(false);
        });
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    created(){
       this.getDeleteInfo();
    }
  
}
