<template>
  <div id="wrapper">
    <!-- <UserInfoWebpart /> -->
    <TotalBoardWebpart :option="webPartItem" />
  </div>
</template>

<script>
import TotalBoardWebpart from "../TotalBoardWebpart/TotalBoardWebpart.vue";
export default {
  components: { TotalBoardWebpart },
  data() {
    return {
      webPartItem: {
        tabs: [
          {
            tabTitle: this.$t('목록_5_O_O').toString(),
            boardId: 17,
            displayCount: 5,
            isImage: false,
            isContentShow: false,
            isNoticeShow: true,
            isPaging: true,
          },
          {
            tabTitle: this.$t('목록_4_O_X').toString(),
            boardId: 17,
            displayCount: 4,
            isImage: false,
            isContentShow: false,
            isNoticeShow: true,
            isPaging: false,
          },
          {
            tabTitle: this.$t('목록_3_X_O').toString(),
            boardId: 18,
            displayCount: 3,
            isImage: false,
            isContentShow: false,
            isNoticeShow: false,
            isPaging: true,
          },
          {
            tabTitle: this.$t('목록_2_X_X').toString(),
            boardId: 18,
            displayCount: 2,
            isImage: false,
            isContentShow: false,
            isNoticeShow: false,
            isPaging: false,
          },
          {
            boardId: 23,
            tabTitle: this.$t('이미지_8_O_O_O').toString(),
            isImage: true,
            isContentShow: true,
            isNoticeShow: true,
            isPaging: true,
            displayCount: 8
        },
        {
            boardId: 23,
            tabTitle: this.$t('이미지_7_X_O_O').toString(),
            isImage: true,
            isNoticeShow: true,
            isContentShow: false,
            isPaging: true,
            displayCount: 7
        },
        {
            boardId: 1039,
            tabTitle: this.$t('이미지_6_O_X_O').toString(),
            isImage: true,
            isContentShow: true,
            isPaging: true,
            displayCount: 6
        },
        {
            boardId: 29,
            tabTitle: this.$t('이미지_5_X_X_O').toString(),
            isImage: true,
            isPaging: true,
            displayCount: 5
        },
        // {
        //     tabTitle: "이미지/4/O/O/X",
        //     boardId: 23,
        //     isImage: true,
        //     isContentShow: true,
        //     isNoticeShow: true,
        //     displayCount: 4
        // },
        // {
        //     tabTitle: "이미지/3/X/O/X",
        //     boardId: 23,
        //     isImage: true,
        //     isNoticeShow: true,
        //     displayCount: 3
        // },
        // {
        //     tabTitle: "이미지/2/O/X/X",
        //     boardId: 23,
        //     isImage: true,
        //     isContentShow: true,
        //     displayCount: 2
        // },
        // {
        //     tabTitle: "이미지/1/X/X/X",
        //     boardId: 23,
        //     isImage: true,
        //     displayCount: 1
        // }
        ],
      },
    };
  },
};
</script>

<style></style>
