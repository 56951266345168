import { RouteConfig } from 'vue-router'
const Attendance = () => import(/* webpackChunkName: "example" */ '@attendance/Index.vue')
const AttendanceMaster = () => import(/* webpackChunkName: "example" */ '@attendance/views/Master.vue')
import AttendanceListView from '../views/AttendanceListView.vue'
import AttendanceAdminView from '../views/AttendanceAdminView.vue'
import AttendanceStaggerListView from '../views/AttendanceStaggerListView.vue'
import AttendanceManageView from '../views/AttendanceManageView.vue'
import AttendanceStaggerManageView from '../views/AttendanceStaggerManageView.vue'
import AttendanceManageWeekView from '../views/AttendanceManageWeekView.vue'

const routes: RouteConfig[] = [
  {
    path: '/attendance',
    component: Attendance,
    children: [
      {
        path: '',
        component: AttendanceMaster,
        children: [
          {
            path: 'my',
            name: 'attendance-my',
            component: AttendanceListView,
          },
          {
            path: 'staggerList',
            name: 'attendance-staggerList',
            component: AttendanceStaggerListView,
          },
          {
            path: 'manage',
            name: 'attendance-manage',
            component: AttendanceManageView,
          },
          {
            path: 'staggermanage',
            name: 'stagger-manage',
            component: AttendanceStaggerManageView,
          },
          {
            path: 'manageweek',
            name: 'attendance-manageweek',
            component: AttendanceManageWeekView
          },
          {
            path: '',
            redirect: 'list',
          },
        ],
      },
    ],
  },
]
export default routes
 