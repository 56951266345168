import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import AttendanceStaggerManageGrid from '@attendance/components/ManageList/AttendanceStaggerManage.vue'
import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import AttendanceStaggerDetailModalView from '@attendance/views/Modal/AttendanceStaggerDetailModalView.vue'
import * as dateutil from '@appbase/util/date-helper'
import api from '@attendance/api/index'
import stagger from '../api/stagger'

@Component({
    components: { AttendanceStaggerManageGrid, DatePicker, AttendanceStaggerDetailModalView }
  })
export default class AttendanceStaggerManageView extends Vue {
    startDate: string = ''
    endDate: string = ''
  
    searchFilter: number = 0
    searchValue: string = ''
  
    isShowModal: boolean = false
    staggerItem: stagger.StaggerItem | null = null
  
    list: stagger.StaggerItem[] = []
    totalCount: number = 0
    pageNum: number = 1
    pageSize: number = 20
  
    selStaggeredId: number = 0

    // 근무형태
    workingTypeList:stagger.StaggerAttendanceType[] =[]
    attendanceTypeFilter:number = 0

    // 년도 검색
    yearList:number[] = []
    yearFilter:number = 0    
    
    // 팝업 오픈
    onOpenStaggerPop(staggeredId: number){
        this.selStaggeredId = staggeredId;
        
        if (staggeredId == 0){
            this.isShowModal = true;
        }
        else{
            this.getStaggerDetail();
        }
    }

    // 팝업 Close
    onCloseStaggerPop(reload: boolean){
        this.isShowModal = false;
        if (reload){
        this.getList(1);
        }
    }

    // 근무시간설정 상세조회
    getStaggerDetail(){
        if(this.selStaggeredId == 0) return;
        else{
        api.stagger.getStaggerDetail(this.selStaggeredId).then(res => {
            this.staggerItem = res;
            if (res){
            this.isShowModal = true;
            }
        });
        }
    }

    // 검색 버튼 이벤트
    onSearch(){
        this.getList(1);
    }

    // 근무시간 목록 조회
    getList(pageNum: number){
        this.pageNum = pageNum;
        api.stagger.getStaggerList(this.pageNum, this.pageSize, this.startDate, this.endDate, this.searchFilter, this.searchValue, this.attendanceTypeFilter).then(res => {
            if (res){
                this.list = res.staggeredList;
                this.totalCount = res.totalCount;
            }
            else{ 
                this.list = [];
                this.totalCount = 0;
            }
        });
    }

    // Excel 다운로드
    excelDownload(){
        api.stagger.downloadListExcel(this.startDate, this.endDate, this.searchFilter, this.searchValue, this.attendanceTypeFilter);
    }

    // Excel 템플릿 다운로드
    templateDownload(){
        window.open('/cm/gres/templates/AttendanceStaggeredTemplate_V1.xlsx');
    }

    // Excel 업로드
    tgrExlUpload(){
        (this.$refs.exluploader as HTMLElement).click();
    }

    uploadExcel(e:any){
        let file = e.target.files;
        let formData = new FormData();

        if (file[0]) {
            formData.append("file", file[0]);

            if (formData) {
                api.stagger.uploadExcelTemplate(formData, ()=>{return 0}, (err:string)=>{return -1}).then(async res => {
                    let msg = this.$t('총_COUNT_처리완료').toString()
                    if (res.data > 0){
                        await (window as any).alertAsync(msg.replace('{{COUNT}}', res.data));
                    }
                    else{
                        await (window as any).alertAsync(this.$t('M_ErrorMsg_TaskProcess_05').toString());
                    }
                    
                    // 다시 업로드를 위해 Value 값을 초기화한다.
                    (this.$refs.exluploader as HTMLInputElement).value = "";
                });
            }
        }
    }

    @Watch('yearFilter')
    changeFilter(val:number){
      if(val>0){
        this.startDate = dateutil.uGetDateFormat('yyyy-MM-dd',new Date(val,0,1));
        this.endDate = dateutil.uGetDateFormat('yyyy-MM-dd',new Date(val,11,31));
      }
      else
        this.dateReset()
      this.getList(1)
    }

    dateReset(){
        let stDate = new Date();
        let edDate = new Date();
        stDate.setMonth(stDate.getMonth() - 3);
        edDate.setMonth(edDate.getMonth() + 10);
        this.startDate = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(stDate));
        this.endDate = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(edDate));
      }

    mounted(){
        this.dateReset()
        this.getList(this.pageNum)
    }

    created(){
        api.stagger.getStaggerAttendanceTypeList().then(res=>{
            this.workingTypeList = res
          })
        let _year = new Date().getFullYear()
        for(var i =2022;i<=_year+1;i++ ){
        this.yearList.push(i)
        }
        this.setPage({title:this.$t('근무시간관리')});
    }
}