import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import * as dateutil from '@appbase/util/date-helper';

@Component
export default class ArticleHistoryList extends Vue {
    @Prop({ type:Array }) articleHistoryList!: article.ArticleHistoryItem[]

    fnSetDisplayDate(date: Date){
        var displayDate = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(date)) + ' ' + dateutil.uGetDateFormat('H:i', dateutil.IEDate(date));
        return displayDate;
    }
}