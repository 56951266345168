import api from '@/api'

export default {

    getReserveRequest : (bookingId: number, isMaster : boolean, isPreview :boolean): Promise<reservedb.ResponseRequestItem> => {

        let urlWithQueryStr = `/CommonReserveDb/requestdb/api/${bookingId}?isMaster=${isMaster}`
        if(isPreview == true)
            urlWithQueryStr += `&isPreview=${isPreview}`

        return api.get({
            url: urlWithQueryStr,
            
        })

    },

    
    cancelReserveRequest: (data: reservedb.RequestCancelParam): Promise<boolean> => {

        return api.post({
            url: `/CommonReserveDb/requestdb/api/cancel`,
            data: data
        })

    },

 

    createUpdateReserveRequest: (data: reservedb.RequestRequestItem): Promise<boolean> => {

        return api.post({
            url: `/CommonReserveDb/requestdb/api`,
            data: data
        })

    },

     validationReserveRequest: (data: reservedb.RequestValdationRequest): Promise<boolean> => {

        return api.post({
            url: `/CommonReserveDb/requestdb/validation/api`,
            data: data
        })
    }
    
}