<template>
  <PageLayout>
    <!-- <Header /> -->
    <!-- <GlobalNavigationBar /> -->
    <GnbLeftView />
    <!---------- 시작 : OuterWrap ---------->
    <div id="outer-wrap">
      <slot></slot>
    </div>
    <!---------- 끝 : wrapper ---------->
  </PageLayout>
</template>

<script>
import { PageLayout } from '@appbase/templates'
import GnbLeftView from '@gnb/views/GnbLeft/GnbLeftView.vue'
export default {
  components: { PageLayout , GnbLeftView },
  props: { useLeft: { type: Boolean, default: true } },
}
</script>

<style></style>
