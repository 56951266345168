<template>
  <div id="wrapper">
    <ShortcutsWebpart />
  </div>
</template>

<script>

import ShortcutsWebpart from '../ShortcutsWebpart/ShortcutsWebpart.vue'
export default {
  components: { ShortcutsWebpart },
}

</script>

<style></style>
