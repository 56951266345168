import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters, mapActions, mapMutations } from 'vuex';


declare type tab = { idx: string; name: string; };
import * as types from '@gnb/store/types'

@Component({
   name: 'TabOrder',
   components: { },
   computed: {
        ...mapGetters({
      }),
    },
   methods: {
        ...mapActions({
          load: types.SETTINGS_MYSETTING_LOAD,
        }),
        ...mapMutations({
      

        }),
    },
})
export default class TabOrder extends Vue {

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //  

    @Prop({ type: String }) value!: string;
    
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //   

    tabs: Array<tab> = [ { idx: '0', name: this.$t('W_Pending_box').toString() }, { idx: '1', name: this.$t('내가_기안한_문서함').toString() }, { idx: '2', name: this.$t('W_DocumentList_6').toString() } ];
    selectedTab = '';

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    load!: () => Promise<gnb.CM_MySetting>;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M U T A T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    get tabList(){
      return this.tabs;
    }

    set tabList(value: Array<tab>){
      this.tabs = value;
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    /**
     * 현재 선택한 결재함을 기준으로 목록의 순서를 변경합니다.
     * @param list 결재함 목록
     */
    modifyList(list: Array<tab>){
      const selected = this.selectedTab;
      let newList : Array<tab> = [];
      let last = null;
      for(var x of list){
        if(selected.includes(x.idx)){
          last = newList?.length > 0 ? newList.pop() : null;
          newList.push(x);
          if(last){
            newList.push(last);
            last = null;
          }
        }
        else{
          newList.push(x);
        }
      }
      return newList;
    }

    /**
     * 선택한 결재함의 순서를 위로 올립니다.
     */
    up(){
      this.tabList = this.modifyList(this.tabList);
    }

    /**
     * 선택한 결재함의 순서를 아래로 내립니다.
     */
    down(){
      this.tabList = this.modifyList(this.tabList.reverse()).reverse();
    }

    setTabSort(newTabOrder: string, oldTabOrder: string) { 
        const _this = this;
        const list : Array<tab> = [];
        if(oldTabOrder?.length === 0){
          newTabOrder.split(',').forEach(order => {
            list.push(_this.tabs.find((x: tab) => x.idx === order) as tab);
          })
          this.tabList = list;
        }
      
    }

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    /**
     * 결재함 순서 값을 처음 전달 받을 때에만 목록을 정렬합니다.
     * @param newTabOrder 
     * @param oldTabOrder 
     */
    @Watch('value')
    onTabOrderChanged(newTabOrder: string, oldTabOrder: string){     
      this.setTabSort(newTabOrder, oldTabOrder);
    }

    /**
     * 결재함 목록의 순서가 변경될 때 마다 순서를 상위 컴포넌트로 전달합니다.
     * @param newList 결재함 목록
     */
    @Watch('tabList')
    onTabListChanged(newList: Array<tab>){
      const order = newList.map(tab => tab.idx);
      var orderStr = order.toString();
      this.$emit('order', orderStr);
    }


    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //
    mounted() {
      
    }

    created() {
      this.load().then((res)=>{
      if(res){
        this.setTabSort(res.mySetting.tabOrder as string, '');
        }
      })
     
    }
}