import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { EumEditor } from '@appbase/components/editor'

@Component({
    components: {
        EumEditor
    }
})

export default class TitleField extends Vue {
    @Prop ({ type: String }) displayName!: string;
    @Prop({ type: String }) fieldValue!: string;
    @Prop({ type: Boolean }) printMode!: boolean;
    @Prop({ type: Object }) article!: article.ArticleDetail;

    isBatchContent: boolean = false;
    mContent: string = "";

    tinyopts: any = {
        content_style: `
                    p { font-family: dotum; }
                    body {font-family: dotum; font-size: 10pt;}
                    body#tinymce p {
                        font-family: dotum; 
                        margin-top: 0.3em;
                        margin-bottom: 0.3em;
                    }`,     
        language: "ko_KR"
    };
    dext5opts: editor.dext5.DEXT5Config = {
        EditorBorder: '0',
    } 

    @Watch('fieldValue')
    onValueChanged(newVal: string) {
        this.mContent = newVal;
    }

    get showVHTML() {
        if (this.isBatchContent || this.printMode) {
            return true;
        } else {
            return false;
        }
    }

    created() {
        if (this.article != null) {
            this.isBatchContent = this.article.prefaceId?.trim() === 'B10';
        } else {
            this.isBatchContent = false;
        }

        this.mContent = this.fieldValue;      

        if (this.printMode) {            
            this.$nextTick(() => {
                var queryTime = new Date();
                var elemet = document.createElement("link");
                elemet.setAttribute("rel", "stylesheet");
                elemet.setAttribute("type", "text/css");
                elemet.setAttribute("href", `/cm/gres/css/editor_content.css?v=${queryTime.getTime()}`);
                document.getElementsByTagName("head")[0].appendChild(elemet);                                

                elemet.addEventListener('load', function () {
                    window.print();
                    setTimeout(function () { window?.close(); }, 1);     
                })
            });
        }        
    }
}