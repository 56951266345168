import { BaseLayout } from '@appbase/templates'
import Vue from 'vue'
import VueRouter, { NavigationGuard, RouteConfig } from 'vue-router'
// modules
import { routes as moduleBoard } from '@board/module'
import moduleAccount from '../modules/@account/router'
import authGuard from '@account/router/auth-guard'
import moduleAppbase from '@appbase/router'
import { PortalCacheGuard, SkinGuard, multiguard, I18nGuard, CssSwitchingGuard } from '../modules/@appbase/router'
import moduleOrgmap from '../modules/@orgmap/router'
import moduleExample from '../modules/example/router'
import moduleHome from '../modules/home/router'
import moduleReserve from '../modules/reserve/router'
import moduleReserveDb from '../modules/reservedb/router'
import moduleSurvey from '../modules/survey/router'
import moduleGnb from '../modules/@gnb/router'
import moduleWebparts from '../modules/@webparts/router'
import moduleAttendance from '../modules/attendance/router'
import moduleHr from '../modules/hr/router'
import menuHelper from '@appbase/util/menu-helper'
 


const RedirectView = () => import('@home/views/RedirectView.vue')

Vue.use(VueRouter)

// 브라우저 감지
// 현재 MS Teams 지원 웹/모바일 클라이언트
// https://docs.microsoft.com/en-us/microsoftteams/get-clients
const BrowserGuard: NavigationGuard = (to, from, next) => {
    var ua = window.navigator.userAgent
    var iOS = ua.indexOf('iPhone')
    var Android = ua.indexOf('Android')

    //mobile
    if (iOS > 0 || Android > 0) {
        window.location.href = '/mobile'
    }
    next()
}

const HomePageGuard: NavigationGuard = (to, from, next) => {
    if (to.path === '/') {
        return menuHelper.goHome()
    }
    next()
}




const routes: RouteConfig[] = [
    {
        path: '/',
        component: BaseLayout,
        beforeEnter: multiguard(
            [BrowserGuard, SkinGuard, PortalCacheGuard, I18nGuard, HomePageGuard, CssSwitchingGuard],
            // [BrowserGuard, SkinGuard, PortalCacheGuard, I18nGuard, HomePageGuard,CssSwitchingGuard],
            ['/account/login']
        ),
        children: [
            ...moduleAppbase,
            ...moduleOrgmap,
            ...moduleBoard,
            ...moduleExample,
            ...moduleHome,
            ...moduleReserve,
            ...moduleReserveDb,
            ...moduleSurvey,
            ...moduleGnb,
            ...moduleWebparts,
            ...moduleAttendance,
            ...moduleHr,
        ],
    },
    {
        path: '/',
        component: BaseLayout,
        beforeEnter: CssSwitchingGuard,
        children: [
            ...moduleAccount,
            {
                path: 'redirect',
                name: 'redirect-page',
                component: RedirectView,
            }
        ],
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

// router.beforeEach(async (to, from, next) => {
//   const result = await authGuard(to, from, next)
//   console.log('result', result)
// })



router.beforeEach(authGuard)
// 전후 나눠서 추가해야 될듯?

// router.beforeEach(CssSwitchingGuard)


export default router
