import api from '@/api'

export interface RoleGroup {
    roleId: number
    comCode: string
    subSystemCode: string
    roleName: string
    roleNameEn: string
    roleType: string
    roleDescription: string
    permissionValue: number
    isSpecialRole: boolean
    useYN: boolean
    createdId: string
    createdDate: Date
    updatedId: string
    updatedDate: Date
    actionType: string
}

export default {
    getRoleGroups(): Promise<RoleGroup[]> {
        return api.get({
            url: '/Common/Role/api'
        })
    }
}
