<template>
  <div id="wrapper">
    <!-- <UserInfoWebpart /> -->
    <ApproveWebpart :option="webPartItem"/>
  </div>
</template>

<script>

import ApproveWebpart from '../ApproveWebpart/ApproveWebpart.vue'
export default {
  components: { ApproveWebpart },
  data() {
    return {
      webPartItem: {
      
      }
    }
  }
}

</script>

<style></style>
