import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// initial state
const state: attendance.state.AttendanceState = {};

export default {
    state,
    actions,
    getters,
    mutations,
};
