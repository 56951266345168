<template>
  <div id="wrapper">
    <!-- <UserInfoWebpart /> -->
    <ListTabWebpart :option="webPartItem"/>
  </div>
</template>

<script>

import ListTabWebpart from '../ListTabWebpart/ListTabWebpart.vue'
export default {
  components: { ListTabWebpart },
  data() {
    return {
      webPartItem: {
      displayCount: 5,
      tabs:[
        {
          boardId:17,
          tabTitle: "1번게시판"
        },
        {
          boardId:1062,
          tabTitle: "2번게시판"
        },
      ]
      }
    }
  }
}

</script>

<style></style>
