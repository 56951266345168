<template>
  <div class="align-center loading-bar-wrapper">
    <div class="lds-bar">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <span class="dp-block b">{{ displayMsg }}</span>
  </div>
</template>

<script>
 
export default {
  data()  {
    return{
      displayMsg : ""
    }
    
  },
  props: {
    message: {
      type: String,
      default: "",
    },
  },
  created(){
    if(!this.message  || this.message==""){
      this.displayMsg = this.$t('로딩중입니다__잠시만_기다려주세요')
    }
    
  }
}
</script>

<style lang="scss">
.loading-bar-wrapper {
  flex: 1;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>
