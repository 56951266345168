import Vue from 'vue'
import { Component } from 'vue-property-decorator'
@Component
export default class I18nExampleView extends Vue {
  test: string = ''

  created() {
    this.test = this.$t('자동_아이디_로직_점검').toString()
  }
}
