import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import DatePicker  from '@appbase/components/DatePicker/DatePicker.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import * as types from '@gnb/store/types'
import * as dateutil from '@appbase/util/date-helper'
import * as accountTypes from '@account/store/types';
import { openPopup } from '@appbase/util/popup-helper';
import { createEventBus, getDispatchData, removeEventBus } from '@appbase/util/eventBus';
import api from '@gnb/api/common'
const ORG_LIST = '$org/list';

@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        Dropdown,
        DatePicker
    },
    computed: {
        ...mapGetters({
            user: accountTypes.USER,
            applicationList: types.APPLICATION_LIST_DATA,
            list: types.SETTINGS_DELEGATOR_LIST_DATA,
            userCurrent: accountTypes.CURRENT
      }),
    },
    methods: {
        ...mapActions({
            loadApplicationList: types.APPLICATION_LIST_LOAD,
            loadDelegationList: types.SETTINGS_DELEGATOR_LIST_LOAD,
            add: types.SETTINGS_DELEGATOR_ADD,
            remove: types.SETTINGS_DELEGATOR_REMOVE,
            loading: types.COMMON_LOADDING
        }),
        ...mapMutations({
        }),
    },
})
export default class ConfrontationView extends Vue {

    
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    // 
    
    selApplication: number = -1; //선택한 양식
    selReason: number = 0; //선택한 사유
    selDelegator: gnb.DelegateItem = {
        displayName: '',
        email: '',
    }; // 선택한 대결자
    start: string = dateutil.uGetDateFormat('yyyy-MM-DD'); //선택한 시작일
    end: string = dateutil.uGetDateFormat('yyyy-MM-DD'); // 선택한 종료일
    reasons: Array<string> = [ this.$t('W_ETC').toString(), this.$t('W_Vacation').toString(), this.$t('W_Business_trip').toString(), this.$t('W_Training2').toString() ]; // 대결 사유
    dateFormat: string = 'yyyy-MM-dd'; // 달력 일자 형식
    mstart: string = dateutil.uGetDateFormat('yyyy-MM-DD', new Date()); // 달력 시작일자
    mend: string = dateutil.uGetDateFormat('yyyy-MM-DD', new Date());  // 달력 종료일자
    placeholder: string = this.$t('placeholder').toString();
    checkedAll: boolean = false; // 전체선택 여부
    mList: Array<gnb.TA_DELEGATION> = [];

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    user!: account.User;
    userCurrent!: account.TCurrent;
    applicationList!: gnb.CM_ApplicationList;
    list!: gnb.CM_DelegationList;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    loadApplicationList!: (payload: { comCode: string, personCode?: string }) => void;
    loadDelegationList!: (personCode: string) => Promise<gnb.CM_DelegationList>;
    add!: (payload: gnb.CM_DelegationItem) => Promise<boolean>;
    remove!: (payload: gnb.CM_DelegationItem) => Promise<boolean>;
    loading!: (option: boolean) => void;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    get appList(){
        return this.applicationList.applicationGroupList
        .flatMap(g => g.applicationItemList.map(x => x.applicationItem))
        .concat(this.applicationList.applicationGroupList
                    .flatMap(g => g.applicationSubGroupList)
                    .flatMap(sg => sg.applicationSubItemList.map((i:gnb.CM_ApplicationSubItem) => i.applicationSubItem))
        );
    }

    get delegatorName(){
        return this.selDelegator ? this.selDelegator.displayName : '';
    }

    get delegatorList(){
        return this.mList;
    }

    set delegatorList(value: Array<gnb.TA_DELEGATION>){
        this.mList = value;
    }

    get allChecked(){
        return this.delegatorList.length === 0 ? false : this.delegatorList.length === this.delegatorList.filter((x:gnb.TA_DELEGATION) => x.checked === true).length;
    }

    set allChecked(value: boolean){
        this.delegatorList = this.delegatorList.map(x => {
            return {
                ...x,
                checked: value
            }
        })
    }

    get userLang(){
        let lang = 'ko-kr'
        if (this.userCurrent && this.userCurrent.userConfig){
            lang = this.userCurrent.userConfig.language.toLowerCase()
        }
        return lang
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

     /**
     * 팝업 창으로 조직도를 엽니다.
     */
      openOrgmap(){
        let eventName;
        let items;

        const options = {
            addableDept: false,
            single: true,
            containers: [
                {
                  id: 0,
                  name: this.$t('선택된_사람').toString(),
                  height: '1',
                  items: items
                },
            ]
        };
        openPopup(
            'addConfrontation',
            '/orgmap',
            { event: ORG_LIST, option: options },
            {
                width: 1024,
                height: 700,
            }
        )
    }

    handlePopupResult(){
        let orgData = getDispatchData(ORG_LIST) || {};
        let memberList: gnb.memberInfo[] = [];

        if (orgData.length > 0 && orgData[0].items.length > 0) {
            for (let i = 0; i < orgData[0].items.length; i++){
                let item = orgData[0].items[i];
                let member: gnb.memberInfo = {
                    text: item.text,
                    value: item.value,
                    email: item.value,
                    selected: false
                }
                
                memberList.push(member);
            }
        }

        this.selDelegator = {
            displayName: memberList[0].text,
            email: memberList[0].value
        }
    }

    /**
     * 선택한 사용자를 대결자로 지정합니다.
     * @param value [{ personCode: string, displayName: string, ... }]
     */
    setDelegator(value: Array<gnb.DelegateItem>){
        this.selDelegator = value[0];
    }

    /**
     * 대결자를 등록하기 전에 유효성을 검사합니다.
     * @returns 
     */
    async validateDelegator(): Promise<boolean>{
        const sDate = dateutil.uGetDateOnly(dateutil.IEDate(this.start));
        const eDate = dateutil.uGetDateOnly(dateutil.IEDate(this.end));
        if(!this.selDelegator){
            await (window as any).alertAsync(this.$t('대결자를_선택하시기_바랍니다').toString());
            return false;
        }
        else if(this.selDelegator.personCode === this.user.personCode){
            await (window as any).alertAsync(this.$t('자신을_대결자로_할_수_없습니다').toString());
            return false;
        }
        else if(this.start === ''){
            await (window as any).alertAsync(this.$t('시작일자를_선택하시기_바랍니다').toString());
            return false;
        }
        else if(this.end === ''){
            await (window as any).alertAsync(this.$t('종료일자를_선택하시기_바랍니다').toString());
            return false;
        }
        else if( sDate > eDate){
            await (window as any).alertAsync(this.$t('시작일자가_종료일자보다_클_수_없습니다').toString());
            return false;
        }
        // else if(!this.validateList(sDate, eDate)){
        //     alert('등록할 수 없습니다');
        //     return false;
        // }
        else{
            return true;
        }
    }

    /**
     * 기존 대결자 목록과 유효성 검사
     * @param sDate 시작일자
     * @param eDate 종료일자
     * @returns 
     */
    validateList(sDate: Date, eDate: Date){
        // const list = this.delegatorList;
        // const applicationId = this.selApplication;
        // // 모든양식의 경우
        // if(applicationId === -1){
        //     return list.findIndex(x => x.applicationId === null 
        //         && dateutil.uGetInDateBoundaryEqual(dateutil.IEDate(x.startDate), dateutil.IEDate(x.endDate), sDate, eDate)) < 0
        // }
        // else{
        //     return list.findIndex(x => x.applicationId === applicationId 
        //         && dateutil.uGetInDateBoundaryEqual(dateutil.IEDate(x.startDate), dateutil.IEDate(x.endDate), sDate, eDate)) < 0
        // }
    }

    /**
     * 대결자를 등록합니다.
     */
    async submit(){
         if(await this.validateDelegator() && this.selDelegator.email !==''){
            if(!this.selDelegator.personCode){
                api.getUserInfoByEmail(this.selDelegator.email).then((res)=>{
                    this.selDelegator.personCode = res.items[0].personCode;

                    let data = {
                        personCode: this.user.personCode,
                        delegatorCode: this.selDelegator.personCode,
                        applicationId: this.selApplication === -1 ? null : this.selApplication,
                        reason: this.selReason,
                        startDate: this.mstart,
                        endDate: this.mend,
                        createdId: this.user.personCode,
                    } as gnb.TA_DELEGATION;
                    this.add({ delegation: data }).then(async (res) =>{
                        if(res){
                            await (window as any).alertAsync(this.$t('등록되었습니다').toString());
                            this.loadDelegationList(this.user.personCode)
                            .then((res: gnb.CM_DelegationList) => {
                                this.mList = res.delegationList.map(x => { 
                                    return {
                                        ...x.delegation,
                                        checked: false,
                                    }
                                });
                            });
                        }
                        else{
                            await (window as any).alertAsync(this.$t('등록이_되지_않았습니다').toString());
                        }
                    })
                    .catch(async (err: Error) => {
                        await (window as any).alertAsync(this.$t('다시_시도해주시기_바랍니다').toString() + '<br/>' + err.message);
                    })
                })
            }else{
                let data = {
                    personCode: this.user.personCode,
                    delegatorCode: this.selDelegator.personCode,
                    applicationId: this.selApplication === -1 ? null : this.selApplication,
                    reason: this.selReason,
                    startDate: this.mstart,
                    endDate: this.mend,
                    createdId: this.user.personCode,
                } as gnb.TA_DELEGATION;
                this.add({ delegation: data }).then(async (res) =>{
                    if(res){
                        await (window as any).alertAsync(this.$t('등록되었습니다').toString());
                        this.loadDelegationList(this.user.personCode)
                        .then((res: gnb.CM_DelegationList) => {
                            this.mList = res.delegationList.map(x => { 
                                return {
                                    ...x.delegation,
                                    checked: false,
                                }
                            });
                        });
                    }
                    else{
                        await (window as any).alertAsync(this.$t('등록이_되지_않았습니다').toString());
                    }
                })
                .catch(async (err: Error) => {
                    await (window as any).alertAsync(this.$t('다시_시도해주시기_바랍니다').toString() + '<br/>' + err.message);
                })
            }
           
        }else{
            await (window as any).alertAsync(this.$t('대결자를_설정_후_등록_버튼을_클릭해주시기_바랍니다').toString());
        }
    }

    /**
     * 대결자를 삭제합니다.
     * @param value seq 값
     */
    async deleteDelegator(isChecked: boolean = false, value?: number){
        if(await(window as any).confirmAsync(this.$t('선택한_대결자를_삭제하시겠습니까').toString())){
            if(!isChecked){
                const data = {
                    seq: value,
                    personCode: this.user.personCode,
                } as gnb.TA_DELEGATION;
                this.remove({ delegation: data }).then(async (res) => {
                    if(res){
                        await (window as any).alertAsync(this.$t('삭제되었습니다').toString());
                        this.loadDelegationList(this.user.personCode)
                        .then((res: gnb.CM_DelegationList) => {
                            this.mList = res.delegationList.map(x => { 
                                return {
                                    ...x.delegation,
                                    checked: false,
                                }
                            });
                        });
                    }
                    else{
                        await (window as any).alertAsync(this.$t('삭제가_되지_않았습니다').toString());
                    }
                })
                .catch(async (err: Error) => {
                    await (window as any).alertAsync(this.$t('다시_시도해주시기_바랍니다').toString() + '<br/>' + err.message);
                })
            }
            else{
                this.delegatorList.filter((x:gnb.TA_DELEGATION) => x.checked === true).forEach((delegator, idx, array) =>{
                    const data = {
                        seq: delegator.seq,
                        personCode: this.user.personCode,
                    } as gnb.TA_DELEGATION;
                    this.remove({ delegation: data }).then(async (res) => {
                        if(idx === array.length -1){
                            if(res){
                                await (window as any).alertAsync(this.$t('삭제되었습니다').toString());
                                this.loadDelegationList(this.user.personCode)
                                .then((res: gnb.CM_DelegationList) => {
                                    this.mList = res.delegationList.map(x => { 
                                        return {
                                            ...x.delegation,
                                            checked: false,
                                        }
                                    });
                                });
                            }
                            else{
                                await (window as any).alertAsync(this.$t('삭제가_되지_않았습니다').toString());
                            }
                        }
                    });
                });
            }
        }
    }

    /**
     * 날짜 값을 문자열로 변환합니다.
     * @param value 일자
     * @returns 
     */
    getDate(value: string){
        return dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(value));
    }

    /**
     * 대결 사유를 문자열로 변환합니다.
     * @param value 대결 사유
     */
    getReason(value: number){
        return this.reasons.find((x, idx) => idx === value);
    }

    /**
     * 양식ID로 양식명을 조회합니다.
     * @param value 양식 ID
     */
    getApplicationName(value: number){
        return this.appList.find(x => x.applicationId === value)?.applicationName || this.$t('모든양식').toString();
    }

    /**
     * 시작일과 종료일로 진행여부를 확인합니다.
     * @param start 시작일
     * @param end 종료일
     */
    getStatus(start: string, end: string){
        const today = new Date();
        const sDate = dateutil.IEDate(start);
        const eDate = dateutil.IEDate(end);
        if(today < sDate){
            return this.$t('W_Code_ApprovalLineStatus_A3').toString();
        }
        else if(dateutil.uGetIsInDate(today, sDate, eDate)){
            return this.$t('진행').toString();
        }
        else if(today > eDate){
            return this.$t('종료').toString();
        }
    }

    /**
     * 진행여부에 따른 css 클래스명을 지정합니다.
     * @param start 
     * @param end 
     * @returns 
     */
    getStatusStyle(start: string, end: string){
        const today = new Date();
        const sDate = dateutil.IEDate(start);
        const eDate = dateutil.IEDate(end);
        if(today < sDate){
            return 'fx-sta-lightgray';
        }
        else if(dateutil.uGetIsInDate(today, sDate, eDate)){
            return 'fx-sta-yellow'
        }
        else if(today > eDate){
            return 'fx-sta-darkgray';
        }
    }

    /**
     * 선택한 날짜를 문자열로 변환하여 변수에 할당합니다.
     * @param date 선택한 날짜
     * @param type 시작일자/종료일자 구분값
     */
    selectDate(date: Date, type: string){
        const dateStr = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.uGetDateOnly(date));
        switch(type){
            case 'start':
                this.start = dateStr;
                break;
            case 'end':
                this.end = dateStr;
                break;
        }
    }



    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    created(){
        createEventBus(window, ORG_LIST,  this.handlePopupResult);
        const _this = this;
        this.loadApplicationList({ comCode: this.user.comCode, personCode: this.user.personCode });
        this.loadDelegationList(this.user.personCode)
            .then((res: gnb.CM_DelegationList) => {
                _this.mList = res.delegationList.map(x => { 
                    return {
                        ...x.delegation,
                        checked: false,
                    }
                });
            }).then(()=>{
                this.loading(false);
            });
    }

    beforeDestroy(){
        removeEventBus(window, ORG_LIST,  this.handlePopupResult);
    }
  
}