import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { createEventBus, removeEventBus, getDispatchData } from '@appbase/util/eventBus';
import { api } from '@/api';

const GNB_QUICKMENU_USERSET = '$gnb/pop/setquickmenu';

@Component
export default class GnbQuickMenu extends Vue {
    @Prop({ type:Array }) quickMenuItems!: gnb.QuickMenuItem[];
    @Prop({ type:Array }) iconMenuItems!: gnb.QuickMenuItem[];

    fnOnChangeQuickMenuUserSet(){
        let quickMenuItems: number[] = getDispatchData(GNB_QUICKMENU_USERSET);
        let quickMenuIds = ((quickMenuItems !== undefined && quickMenuItems !== null && quickMenuItems.length > 0) ? quickMenuItems.join(';') : '');
        this.$emit("fnInsertQuickMenuUserSet", quickMenuIds);
    }

    created(){
        createEventBus(window, GNB_QUICKMENU_USERSET, this.fnOnChangeQuickMenuUserSet);
    }

    beforeDestroy(){
        removeEventBus(window, GNB_QUICKMENU_USERSET, this.fnOnChangeQuickMenuUserSet);
    }
}