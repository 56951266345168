import { MutationTree } from 'vuex';
import * as types from './types'
import * as dateutil from '@appbase/util/date-helper';
import { EnumCalendarViewType }  from '../types';

const buildResourceTree = function(root: ewsResource.EwsResourceItem[], items: ewsResource.EwsResourceItem[], depth: number) {    
    root.forEach(r => { r.childItems = [], r.selected = false; });
    var depthItems = items.filter(i => i.depth == depth);
    
    if(depthItems.length == 0)
        return;

    root.forEach(r => {        
        depthItems.forEach(i => {
            if(i.parentId === r.uniqueId) {  
                i.childItems = [];
                i.selected = false;             
                r.childItems.push(i);                              
            }
        });
        
        if(r.childItems && r.childItems.length > 0) {
            buildResourceTree(r.childItems, items, r.depth + 1);
        }
    })
}

//월 매트릭에서 일별 Event 계산
//jumdate는 날짜가 계속이어지는 일정일경우
const getContainsMonthEvents = function (idx: number, date: Date, events: reserve.ItemList<reserve.CalendarItem>): reserve.ItemList<reserve.CalendarItem> {
    let resultEvents = [];

    for (let i = 0; i < events.length; i++) {
        const event = events[i];

        var sDate = dateutil.IEDate(event.ui_month_start || event.start);
        var eDate = dateutil.IEDate(event.ui_month_end || event.end);

        if (dateutil.uGetIsInDate(date, sDate, eDate)) {
            if (!event.ui_month_jump_date || dateutil.uGetDateOnly(date) >= event.ui_month_jump_date) {
                if(event.isAllDayEvent) {
                    if(dateutil.uGetDateFormat("yyyy-MM-dd H:i:s", date) === event.end)
                        continue;                   
                }

                let newEvent: reserve.CalendarItem = Object.create(null);
                Object.assign(newEvent, event);
                newEvent.index = i;
                resultEvents.push(newEvent as reserve.CalendarItem);
            }
        }
    }
    return resultEvents;
}

const getContainsWeekEvents = function (idx: number, date: Date, events: reserve.ItemList<reserve.CalendarItem>): reserve.ItemList<reserve.CalendarItem> {
    let resultEvents = [];

    for (let i = 0; i < events.length; i++) {
        const event = events[i];

        var strDate = (event.ui_week_start || event.start) + '';

        var time = dateutil.DateAndTimeSplitor(strDate)[1];
        var tarr = time.split(':');

        var last = tarr[tarr.length - 2];

        if (!(last == '00' || last == '30')) {
            let min = Number(last);
            if (min < 30) {
                strDate = strDate.replace(time, `${tarr[0]}:00:00Z`);
            } else {
                strDate = strDate.replace(time, `${tarr[0]}:30:00Z`);
            } 
        }

        var sDate = dateutil.IEDate(strDate.split('+')[0]);

        if (sDate.getFullYear() == date.getFullYear() &&
            sDate.getMonth() == date.getMonth() &&
            sDate.getDate() == date.getDate() &&
            sDate.getHours() == date.getHours() &&
            sDate.getMinutes() == date.getMinutes()) {
            let newEvent: reserve.CalendarItem = Object.create(null);
            Object.assign(newEvent, event);
            newEvent.index = i;
            resultEvents.push(newEvent as reserve.CalendarItem);
        }
    }
    return resultEvents;
}

const getContainsWeekCalcEvents = function (date: Date, events: reserve.ItemList<reserve.CalendarItem>): reserve.ItemList<reserve.CalendarItem> {
    let resultEvents = [];

    for (let i = 0; i < events.length; i++) {
        const event = events[i];

        var sDate = dateutil.IEDate(event.ui_week_start || event.start);

        if (sDate.getFullYear() == date.getFullYear() &&
            sDate.getMonth() == date.getMonth() &&
            sDate.getDate() == date.getDate()) {
            // let newEvent: calendar.CalendarItem = Object.create(null);
            // Object.assign(newEvent, event);
            resultEvents.push(event);
        }
    }
    return resultEvents;
}

const addPrintEvent = function(state: reserve.state.ReserveState, item: reserve.CalendarItem) {
    // if (item && item.id) {
    //     if (!state.calendarPrintEvents) {
    //         state.calendarPrintEvents = [];
    //     }

    //     if (!state.calendarPrintEvents.filter((x) => x.id == item.id).length) {
    //         let newObj:calendar.CalendarItem = Object.create(null);
    //         Object.assign(newObj, item);
    //         state.calendarPrintEvents.push(newObj);
    //     }
    // }
}

export default <MutationTree<reserve.state.ReserveState>>{
    [types.CALENDAR_VIEWMODE](state, payload: EnumCalendarViewType) {
        state.calendarMode = payload;
    },

    [types.EWS_RESOURCEGROUPS_LOAD](state, payload: ewsResource.EwsResourceItem[]) {        
        state.resourceGroupsList = payload;
        var groups = payload.filter(p => p.depth == 0);

        buildResourceTree(groups, payload, 1);   
        
        state.resourceGroupsTree = groups;
    },

    [types.EWS_RESOURCEBOOKMARK_LOAD](state, payload: reserve.ResourceBookmark[]) {
        state.resourceBookmarkList = payload;
    },
    
    [types.CALENDAR_TOGGLE_RIGHTPANE](state, payload) {
        state.rightPaneOpen = payload;
    },

    [types.CALENDAR_TOGGLE_LEFTPANE](state, payload) {
        state.leftPaneOpen = payload;
    },

    [types.TOGGLE_RESOURCE_SELECT](state, payload) {
        var { id, select} = payload;
        var selectedAfter: string[] = state.selectedResourceKeys.slice();

        if(select) {
            if(!(selectedAfter.indexOf(id) >= 0)) {
                selectedAfter.push(id);
            }
        } else {
            var idx = selectedAfter.indexOf(id);

            if(idx >= 0) {
                selectedAfter.splice(idx, 1);
            }
        }

        state.selectedResourceKeys = selectedAfter;  
        state.selectedResources = state.resourceGroupsList.filter(r => selectedAfter.indexOf(r.uniqueId) >= 0);    
        console.log('selectedChanged', state.selectedResources);    
    },

    [types.TOGGLE_RESOURCEGROUP_SELECT](state, payload) {
        var { id, select} = payload;
        var selectedAfter: string[] = state.selectedResourceKeys.slice();
        var childs = state.resourceGroupsList.filter(r => r.parentId === id).map(r => r.uniqueId);
        childs.push(id);      

        childs.forEach(c => {
            if(select) {
                if(!(selectedAfter.indexOf(c) >= 0)) {
                    selectedAfter.push(c);
                }
            } else {
                var idx = selectedAfter.indexOf(c);
    
                if(idx >= 0) {
                    selectedAfter.splice(idx, 1);
                }
            }
        });

        state.selectedResourceKeys = selectedAfter;   
        state.selectedResources = state.resourceGroupsList.filter(r => selectedAfter.indexOf(r.uniqueId) >= 0);   
        console.log('selectedChange', state.selectedResources);    
    },

    [types.MINI_CALENDAR_MONTH_MATRIX_LOAD](state) {
        state.miniCalendarMatrix = [];

        let Rows = [];
        let today = new Date();
        let daysList = dateutil.uGetDaysInMonth(state.monthDate, true, state.calendarSetting.firstDay);

        for (let i = 0; i < daysList.length; i++) {
            let date = daysList[i];

            let isToday =
                date.getFullYear() == today.getFullYear() &&
                date.getMonth() == today.getMonth() &&
                date.getDate() == today.getDate();

            let isSelected =
                date.getFullYear() == state.currentDate.date.getFullYear() &&
                date.getMonth() == state.currentDate.date.getMonth() &&
                date.getDate() == state.currentDate.date.getDate();

            let isDisplayArea = false;
            if (state.calendarMode == EnumCalendarViewType.Month) {
                if (state.currentDate.date.getMonth() == date.getMonth()) {
                    isDisplayArea = true;
                }
            }

            if (state.calendarMode == EnumCalendarViewType.Week) {
                let weekStartDate = dateutil.uGetWeekStartDate(state.currentDate.date, state.calendarSetting.firstDay);
                let weekEndDate = dateutil.uGetWeekEndDate(state.currentDate.date, state.calendarSetting.firstDay);

                if (Number(weekStartDate) <= Number(date) && Number(weekEndDate) >= Number(date)) {
                    isDisplayArea = true;
                }
            }

            if (state.calendarMode == EnumCalendarViewType.Daily) {
                if (isSelected) {
                    isDisplayArea = true;
                }
            }

            let dateItem = {
                date: date,
                isToday: isToday,
                isSelected: isSelected,
                isSun: date.getDay() == 0,
                isSat: date.getDay() == 6,
                isDisplayArea: isDisplayArea,
                item: null,
            }

            Rows.push(dateItem);

            if ((i + 1) % 7 == 0) {
                let NewRows = new Array;
                NewRows = Object.assign(NewRows, Rows);
                state.miniCalendarMatrix.push(NewRows);
                Rows = [];
            }
        }
    },

    [types.CALENDAR_MONTH_MATRIX_LOAD](state) {
        state.monthMatrix = [];

        let copyEvents = [] as reserve.ItemList<reserve.CalendarItem>;
        let allEvents = [] as reserve.ItemList<reserve.CalendarItem>;
        const limitCount = 2;

        let current_h_idx_flags = [] as Array<Date>;
        Object.assign(copyEvents, state.resourceEvents);   

        const sortFn = function (a: any, b: any): number {
            return a == b ? 0 : a < b ? -1 : 1;
        }

        copyEvents.sort((a, b) =>
            sortFn(
                Number(dateutil.IEDate(b.ui_month_end || b.end)) - Number(dateutil.IEDate(b.ui_month_start || b.start)),
                Number(dateutil.IEDate(a.ui_month_end || a.end)) - Number(dateutil.IEDate(a.ui_month_start || a.start)))
            || sortFn(Number(dateutil.IEDate(a.ui_month_start || a.start)), Number(dateutil.IEDate(b.ui_month_start || b.start)))
            || sortFn(Number(a.dateTimeCreated), Number(b.dateTimeCreated))
        );


        Object.assign(allEvents, state.resourceEvents);
        if (state.calendarMode != EnumCalendarViewType.Month) {
            for (let i = 0; i < copyEvents.length; i++) {
                const event = copyEvents[i];
                let removeFlag = false;
                let IsAtLeastOneDate = false;
                if (event.scheduleDuration) {
                    let dayCount = Number(event.scheduleDuration.split('.')[0]);
                    if (dayCount == 0 || isNaN(dayCount)) {
                        removeFlag = true;
                    } else {
                        IsAtLeastOneDate = true;
                    }
                }
                if (!IsAtLeastOneDate) {
                    if ((event.isAllDayEvent as any) == 'False' || event.isAllDayEvent == false) {
                        removeFlag = true;
                    }
                }

                // let weekStart = dateutil.uGetDateOnly(dateutil.uGetWeekStartDate(state.currentDate.date));
                // let weekEnd = dateutil.uGetDateOnly(dateutil.uGetWeekEndDate(state.currentDate.date));

                // let eventStart = dateutil.uGetDateOnly(dateutil.IEDate(event.start));
                // let eventEnd = dateutil.uGetDateOnly(dateutil.IEDate(event.end));
                // let inWeeks = eventStart >= weekStart && eventStart <= weekEnd &&
                //     eventEnd >= weekStart && eventEnd <= weekEnd;
                if (removeFlag) {
                    copyEvents.splice(i, 1);
                    i--;
                }
            }
        }

        let matrix = [] as Array<Array<reserve.DateItem>>;
        let Rows = [];
        let today = new Date();
        let daysList = [] as Date[];

        if (state.calendarMode == EnumCalendarViewType.Month) {
            daysList = dateutil.uGetDaysInMonth(state.monthDate, true, state.calendarSetting.firstDay);
        }
        else if (state.calendarMode == EnumCalendarViewType.Week) {
            daysList = dateutil.uGetDaysInWeek(state.currentDate.date, state.calendarSetting.firstDay);
        } else if (state.calendarMode == EnumCalendarViewType.Daily) {
            for (let di = 0; di < 7; di++) {
                daysList.push(dateutil.uGetDateOnly(state.currentDate.date));                
            }
        }

        for (let i = 0; i < copyEvents.length; i++) {
            const event = copyEvents[i];
            if (dateutil.IEDate(event.start) < daysList[0]) {
                event.ui_month_start = dateutil.uGetDateFormat('yyyy-MM-DD', daysList[0]) + ' ' + dateutil.uGetDateFormat('H:i:s', dateutil.IEDate(event.start));
            }

            if (dateutil.IEDate(event.end) > daysList[daysList.length - 1]) {
                event.ui_month_end = dateutil.uGetDateFormat('yyyy-MM-DD', daysList[daysList.length - 1]) + ' ' + dateutil.uGetDateFormat('H:i:s', dateutil.IEDate(event.end));
            }
        }


        for (let i = 0; i < daysList.length; i++) {
            let date = daysList[i];
            let isWeekStart = dateutil.uGetWeekStartDate(date, state.calendarSetting.firstDay || 0).getDate() == date.getDate();

            if (isWeekStart) {
                for (let f = 0; f < limitCount; f++) {
                    current_h_idx_flags[f] = dateutil.IEDate(0);
                }
            }            

            let isToday =
                date.getFullYear() == today.getFullYear() &&
                date.getMonth() == today.getMonth() &&
                date.getDate() == today.getDate();

            let isSelected =
                date.getFullYear() == state.currentCalendarDate.date.getFullYear() &&
                date.getMonth() == state.currentCalendarDate.date.getMonth() &&
                date.getDate() == state.currentCalendarDate.date.getDate();

            let isDisplayArea = false;
            if (state.calendarMode == EnumCalendarViewType.Month) {
                if (state.currentCalendarDate.date.getMonth() == date.getMonth()) {
                    isDisplayArea = true;
                }
            }

            if (state.calendarMode == EnumCalendarViewType.Week) {
                let weekStartDate = dateutil.uGetWeekStartDate(state.currentCalendarDate.date, state.calendarSetting.firstDay);
                let weekEndDate = dateutil.uGetWeekEndDate(state.currentCalendarDate.date, state.calendarSetting.firstDay);

                if (Number(weekStartDate) <= Number(date) && Number(weekEndDate) >= Number(date)) {
                    isDisplayArea = true;
                }
            }

            if (state.calendarMode == EnumCalendarViewType.Daily) {
                if (isSelected) {
                    isDisplayArea = true;
                }
            }           

            let curEvents = getContainsMonthEvents(i, date, copyEvents);            
            let curAllEvents = state.calendarMode == EnumCalendarViewType.Month ? getContainsMonthEvents(i, date, allEvents) : [];            

            let limitedEvents = curEvents.map((item, index) => {
                if (index < limitCount) {
                    return item;
                }
            }) as reserve.ItemList<reserve.CalendarItem>;

            for (let l = 0; l < limitedEvents.length; l++) {
                const litem = limitedEvents[l];

                if (litem == undefined) {
                    limitedEvents.splice(l, 1);
                    l--;
                    continue;
                }

                litem.ui_month_hindex = l;

                //반복일정하나를 보여주기위해 여러개 복사
                if (!litem.ui_copyed) {

                    let sdate = dateutil.uGetDateOnly(dateutil.IEDate(litem.ui_month_start || litem.start));
                    let realEndDateTime = dateutil.IEDate(litem.end);
                    let realAndDateOnly = dateutil.uGetDateOnly(realEndDateTime);
                    let edate = dateutil.uGetDateOnly(dateutil.IEDate(litem.ui_month_end || litem.end));

                    let isEndZero = realEndDateTime.getHours() == 0 && realEndDateTime.getMinutes() == 0;

                    if (isEndZero &&
                        dateutil.uGetDateOnly(daysList[0]) <= realAndDateOnly &&
                        dateutil.uGetDateOnly(daysList[daysList.length - 1]) >= realAndDateOnly) {
                        edate.setDate(edate.getDate() - 1);
                    }

                    let expTimes = (Number(edate) - Number(sdate));
                    let totalDays = Math.round(expTimes / (1000 * 60 * 60 * 24)) + 1;
                    litem.ui_month_width_count = totalDays;
                    let dayGap = 7 - (state.calendarSetting.firstDay || 0);
                    
                    let calcDay = (date.getDay() + dayGap) % 7;
                    let copyCount = Math.floor((calcDay + totalDays - 1) / 7);

                    if (copyCount > 0) {
                        litem.ui_month_width_count = 7 - calcDay;
                    }
                    let savWCount = litem.ui_month_width_count;

                    for (let c = 0; c < copyCount; c++) {
                        let copyItem: reserve.CalendarItem = Object.create(null);
                        Object.assign(copyItem, litem);
                        let cDate = dateutil.IEDate(date);
                        cDate.setDate(cDate.getDate() + 7 * (c + 1));
                        copyItem.ui_month_jump_date = dateutil.uGetDateOnly(dateutil.uGetWeekStartDate(cDate, state.calendarSetting.firstDay || 0));
                        if (c == 0) {
                            copyItem.ui_month_width_count = copyCount == 1 ? (totalDays - litem.ui_month_width_count) : 7;
                            savWCount += copyItem.ui_month_width_count;
                        }
                        else if (c < copyCount - 1) {
                            copyItem.ui_month_width_count = 7;
                            savWCount += copyItem.ui_month_width_count;
                        }
                        else {
                            copyItem.ui_month_width_count = (totalDays - savWCount) > 7 ? 7 : (totalDays - savWCount);
                        }
                        copyItem.ui_copyed = true;
                        if (state.calendarMode != EnumCalendarViewType.Month) {
                            copyItem.ui_month_in_week = true;
                        }
                        copyEvents.push(copyItem);
                    }
                }
            }            

            limitedEvents.sort((a, b) => 
                sortFn(
                    Number(dateutil.IEDate(b.ui_month_end || b.end)) - Number(dateutil.IEDate(b.ui_month_start || b.start)), 
                    Number(dateutil.IEDate(a.ui_month_end || a.end)) - Number(dateutil.IEDate(a.ui_month_start || a.start)))
                || sortFn(Number(dateutil.IEDate(a.ui_month_start || a.start)), Number(dateutil.IEDate(b.ui_month_start || b.start)))
                || sortFn(Number(a.dateTimeCreated), Number(b.dateTimeCreated)) 
            );

            let resultArray = [] as reserve.ItemList<reserve.CalendarItem>;
            let flagIdx = 0;
            let addedCount = 0;

            for (let l = 0; flagIdx < limitCount; l++) {
                const litem = limitedEvents[l];

                if (current_h_idx_flags[flagIdx] >= dateutil.uGetDateOnly(date) || !litem) {
                    resultArray.push({
                        id: 'null',
                        color: 'null',
                        start: '',
                        ui_month_hindex: flagIdx,
                        ui_month_width_count: 1,
                    } as reserve.CalendarItem);
                    l--;
                    addedCount++;
                } else {
                    if (litem) {
                        litem.ui_month_hindex = flagIdx;
                        addPrintEvent(state, litem);
                        resultArray.push(litem);

                        if (litem.ui_month_width_count > 1) {
                            let idxDate = dateutil.uGetDateOnly(dateutil.IEDate(litem.ui_month_end || litem.end));
                            if (current_h_idx_flags[flagIdx] < idxDate) {     
                                let ridxDate = dateutil.IEDate(litem.ui_month_end || litem.end); 
                                if (ridxDate.getHours() == 0 && ridxDate.getMinutes() == 0 && ridxDate.getSeconds() == 0) {
                                    idxDate.setSeconds(idxDate.getSeconds() - 1);
                                }
                                current_h_idx_flags[flagIdx] = idxDate;
                            }
                        }
                    }

                    if (flagIdx == 2 && dateutil.uGetDateOnly(date) > current_h_idx_flags[flagIdx]) {
                        current_h_idx_flags[flagIdx] = dateutil.IEDate(0);
                    }
                }

                flagIdx++;
            }

            let moreCount = curEvents.length + addedCount;

            if (state.calendarMode == EnumCalendarViewType.Month) {                
                moreCount = curAllEvents.length;
            } 

            for (let e = 0; e < curEvents.length; e++) {
                const event = curEvents[e];
                copyEvents.splice(event.index - e, 1);
            }

            let dateItem = {
                date: date,
                isToday: isToday,
                isSelected: isSelected,
                isSun: date.getDay() == 0,
                isSat: date.getDay() == 6,
                isDisplayArea: isDisplayArea,
                MoreEventsCount: moreCount > limitCount ? moreCount - limitCount : 0,
                events: resultArray,
            } as reserve.DateItem;

            Rows.push(dateItem);

            if ((i + 1) % 7 == 0) {
                let NewRows = new Array;
                NewRows = Object.assign(NewRows, Rows);
                matrix.push(NewRows);
                Rows = [];
            }
        }

        state.monthMatrix = matrix;
    },

    [types.CALENDAR_WEEK_MATRIX_LOAD](state) {
        state.weekMatrix = [];
        let workingArr:number[] = JSON.parse(state.calendarSetting.workingWeek);
        
        let copyEvents = [] as reserve.ItemList<reserve.CalendarItem>;

        Object.assign(copyEvents, state.resourceEvents);

        if (state.calendarMode == EnumCalendarViewType.Week) {
            for (let i = 0; i < copyEvents.length; i++) {
                const event = copyEvents[i];
                let removeFlag = false;
                if (event.scheduleDuration) {
                    let dayCount = Number(event.scheduleDuration.split('.')[0]);
                    if (dayCount > 0 && !isNaN(dayCount)) {
                        removeFlag = true;
                    }
                }

                if ((event.isAllDayEvent as any) == 'True' || event.isAllDayEvent == true) {
                    removeFlag = true;
                }

                if (removeFlag) {
                    copyEvents.splice(i, 1);
                    i--;
                }
            }
        }

        for (let c = 0; c < copyEvents.length; c++) {
            const event = copyEvents[c];
            if (!event.ui_copyed) {
                let cStart = dateutil.uGetDateOnly(dateutil.IEDate(event.start));
                let cEnd = dateutil.uGetDateOnly(dateutil.IEDate(event.end));

                if (cStart.toString() != cEnd.toString()) {
                    let copyItem: reserve.CalendarItem = Object.create(null);
                    event.ui_week_start = dateutil.uGetDateFormat('yyyy-MM-DD H:i:00', dateutil.IEDate(event.start));
                    event.ui_week_end = dateutil.uGetDateFormat('yyyy-MM-DD 00:00:00', cEnd);
                    Object.assign(copyItem, event);

                    copyItem.ui_week_start = dateutil.uGetDateFormat('yyyy-MM-DD 00:00:00', cEnd);
                    copyItem.ui_week_end = dateutil.uGetDateFormat('yyyy-MM-DD H:i:00', dateutil.IEDate(event.end));
                    copyItem.ui_copyed = true;

                    copyEvents.push(copyItem);
                }
            }
        }

        const sortFn = function (a: any, b: any): number {
            return a == b ? 0 : a < b ? -1 : 1;
        }
        
        let matrix = [] as Array<Array<reserve.DateItem>>;
        let today = new Date();
        let daysList = dateutil.uGetDaysInWeek(state.currentDate.date, state.calendarSetting.firstDay);
        let daysEventList = [];        
        let timeList = dateutil.uGetTimeArray([
            '오전', 
            '오후', 
        ]);

        let startTidx = (timeList.filter((x) => x.value == state.calendarSetting.workingStartTime)[0].num || 0) * 2;
        let endTidx = (timeList.filter((x) => x.value == state.calendarSetting.workingEndTime)[0].num || 24) * 2;
        let valueList = timeList.map((x) => x.value);
        valueList.push('24:00:00');
        for (let i = 0; i < daysList.length; i++) {
            const oDate = daysList[i];
            let date = new Date(oDate);
            let calcEvents = getContainsWeekCalcEvents(date, copyEvents);
            calcEvents.sort((a, b) =>
                sortFn(Number(dateutil.IEDate(a.ui_week_start || a.start)), Number(dateutil.IEDate(b.ui_week_start || b.start))) ||
                sortFn(Number(a.dateTimeCreated), Number(b.dateTimeCreated))
            );
            daysEventList[i] = calcEvents;
        }
        let timevalue = 0;
        let tidx = 0;
        let duplicateCheckArray: Array<Array<number>> = [];
        for (const timeItem of timeList) {
            for (let i = 0; i < daysList.length; i++) {
                const oDate = daysList[i];
                let eventArr = daysEventList[i];
                let date = new Date(oDate);
                date = new Date(dateutil.uGetDateFormat('yyyy-MM-DD', date) + 'T' + timeItem.value); // Set Time
                if (!duplicateCheckArray[i]) {
                    duplicateCheckArray[i] = [];
                }

                if (!duplicateCheckArray[i][tidx]) {
                    duplicateCheckArray[i][tidx] = 0;
                }

                for (let d = 0; d < eventArr.length; d++) {
                    const event = eventArr[d];

                    let date30m = dateutil.IEDate(date);
                    date30m.setMinutes(date30m.getMinutes() + 30);
                    if (dateutil.uGetInDateBoundary(date, date30m,
                        dateutil.IEDate(event.ui_week_start || event.start),
                        dateutil.IEDate(event.ui_week_end || event.end))) {
                        duplicateCheckArray[i][tidx]++;
                    }
                }

            }
            tidx++;
        }

        for (let i = 0; i < daysList.length; i++) {
            const oDate = daysList[i];
            for (let t = 0; t < daysEventList[i].length; t++) {
                const event = daysEventList[i][t];
                let sDate = dateutil.IEDate(event.ui_week_start || event.start);
                let eDate = dateutil.IEDate(event.ui_week_end || event.end);

                var arrMin = [0, 30]
                if (!arrMin.includes(sDate.getMinutes()))
                    sDate.setMinutes(sDate.getMinutes() > 30 ? 30 : 0);
                if (!arrMin.includes(eDate.getMinutes()))
                    eDate.setMinutes(eDate.getMinutes() > 30 ? 30 : 0);

                let TotalMins = dateutil.uGetTotalDiffMinutes(sDate, eDate);
                event.ui_week_height_count = Math.round(TotalMins / 30);

                if (event.ui_week_height_count <= 0) {
                    event.ui_week_height_count = 1;
                }

                let svalue = dateutil.DateAndTimeSplitor((event.ui_week_start || event.start))[1];
                let evalue = dateutil.DateAndTimeSplitor((event.ui_week_end || event.end))[1];

                const virtualTime = (time: string) => {
                    var tarr = time.split(':');
                    var last = tarr[tarr.length - 2];
                    if (!(last == '00' || last == '30')) {
                        let min = Number(last);
                        if (min < 30) {
                            return `${tarr[0]}:00:00`;
                        } else {
                            return `${tarr[0]}:30:00`;
                        } 
                    }
                    return time;
                }

                let Vsvalue = virtualTime(svalue);
                let Vevalue = virtualTime(evalue);                

                let sidx = valueList.indexOf(Vsvalue);
                let eidx = valueList.indexOf(Vevalue);

                if (sDate.getDate() != eDate.getDate() && evalue == '00:00:00') {
                    eidx = valueList.indexOf('24:00:00');
                }

                let max = 0;
                for (let dp = sidx; dp <= eidx; dp++) {
                    let num = duplicateCheckArray[i][dp];
                    if (max < num) {
                        max = num;
                    }
                }
                event.ui_week_maxcount = max;
            }            

            for (let x = 0; x < daysEventList[i].length; x++) {
                const source = daysEventList[i][x];
                let smax = source.ui_week_maxcount;
                for (let y = 0; y < daysEventList[i].length; y++) {
                    const target = daysEventList[i][y];
                    if (x != y) {
                        if (dateutil.uGetInDateBoundary(
                            dateutil.IEDate(source.ui_week_start || source.start),
                            dateutil.IEDate(source.ui_week_end || source.end),
                            dateutil.IEDate(target.ui_week_start || target.start),
                            dateutil.IEDate(target.ui_week_end || target.end))) {
                            if (smax < target.ui_week_maxcount) {
                                source.ui_week_maxcount = target.ui_week_maxcount;
                            }
                        }
                    }
                }
            }
            let saveIndexArray:Array<Array<reserve.CalendarItem>> = [];
            
            for (let x = 0; x < daysEventList[i].length; x++) {
                const source = daysEventList[i][x];
                let calcIdx = 0;
                for (let y = 0; y < x; y++) {
                    let sStartNum = Number(dateutil.IEDate(source.ui_week_start || source.start));
                    if (saveIndexArray[y]) {
                        for (let s = 0; s < saveIndexArray[y].length; s++) {
                            const sevent = saveIndexArray[y][s];
                            let bStartNum = Number(dateutil.IEDate(sevent.ui_week_start || sevent.start));
                            let bEndNum = Number(dateutil.IEDate(sevent.ui_week_end || sevent.end));
                            if (sStartNum >= bStartNum && sStartNum < bEndNum) {
                                calcIdx++;
                            } 
                        }

                        if (calcIdx == y) {
                            break;
                        }
                    }                    
                }
                if (!saveIndexArray[calcIdx]) 
                {
                    saveIndexArray[calcIdx] = [];
                }                
                
                source.ui_week_ui_index = calcIdx;

                saveIndexArray[calcIdx].push(source);
            }            
        }

        let limitCount = 8;
        for (let x = 0; x < copyEvents.length; x++) {
            const event = copyEvents[x];
            if (event.ui_week_maxcount > limitCount) {
                event.ui_week_maxcount = limitCount;
            }

            if (event.ui_week_ui_index >= limitCount) {
                copyEvents.splice(x, 1);
                x--;
            }
        }

        tidx = 0;

        for (const timeItem of timeList) {
            if (tidx > 48) break;
            let Rows = [];
            let titleDateItem: reserve.DateItem = Object.create(null);
            titleDateItem.ui_days_week_time_text = dateutil.uGetZero(timevalue) as string;
            Rows.push(titleDateItem);
            for (let i = 0; i < daysList.length; i++) {
                let oDate = daysList[i];
                let date = new Date(oDate);
                date = new Date(dateutil.uGetDateFormat('yyyy-MM-DD', date) + 'T' + timeItem.value); // Set Time

                let isToday =
                    date.getFullYear() == today.getFullYear() &&
                    date.getMonth() == today.getMonth() &&
                    date.getDate() == today.getDate();

                let isSelected =
                    date.getFullYear() == state.currentCalendarTime.date.getFullYear() &&
                    date.getMonth() == state.currentCalendarTime.date.getMonth() &&
                    date.getDate() == state.currentCalendarTime.date.getDate() &&
                    date.getHours() == state.currentCalendarTime.date.getHours() &&
                    date.getMinutes() == state.currentCalendarTime.date.getMinutes();

                let isDisplayArea = false;
                if (state.calendarMode == EnumCalendarViewType.Month) {
                    if (state.currentCalendarDate.date.getMonth() == date.getMonth()) {
                        isDisplayArea = true;
                    }
                }

                if (state.calendarMode == EnumCalendarViewType.Week) {
                    let weekStartDate = dateutil.uGetWeekStartDate(state.currentCalendarDate.date, state.calendarSetting.firstDay);
                    let weekEndDate = dateutil.uGetWeekEndDate(state.currentCalendarDate.date, state.calendarSetting.firstDay);

                    if (Number(weekStartDate) <= Number(date) && Number(weekEndDate) >= Number(date)) {
                        isDisplayArea = true;
                    }
                }

                if (state.calendarMode == EnumCalendarViewType.Daily) {
                    if (isSelected) {
                        isDisplayArea = true;
                    }
                }

                let curEvents = getContainsWeekEvents(i, date, copyEvents);
                
                for (let e = 0; e < curEvents.length; e++) {
                    const event = curEvents[e];
                    copyEvents.splice(event.index - e, 1);
                }

                let max = 0;
                for (let t = 0; t < curEvents.length; t++) {
                    const event = curEvents[t];
                    max = event.ui_week_maxcount;
                }

                let resultEvents = [];
                for (let c = 0; c <= max; c++) {
                    let isAddedFlag = false;
                    for (let f = 0; f < curEvents.length; f++) {
                        const fevent = curEvents[f];
                        
                        //if (fevent.ui_week_ui_index == c) {
                            // 동일 Item이 중복으로 조회 되는 현상이 있어 아래와 같이 처리함
                            var isInclude = resultEvents.find(x => x.id == fevent.id)
                            if (!isInclude){
                                let copyEvent: reserve.CalendarItem = Object.create(null);
                                Object.assign(copyEvent, fevent);
                                addPrintEvent(state, copyEvent);
                                resultEvents.push(copyEvent);
                                isAddedFlag = true;
                            }
                            //break;
                        //}
                    }

                    if (!isAddedFlag) {
                        resultEvents.push({
                            id: 'null',
                            color: 'null',
                            organizer: {
                                name: null,
                            },
                        });
                    }
                }

                let isWorkingTime = workingArr.indexOf(date.getDay()) != -1 && tidx >= startTidx && tidx < endTidx;

                let dateItem = {
                    date: date,
                    isToday: isToday,
                    isSelected: isSelected,
                    isWorkingTime: isWorkingTime,
                    isSun: date.getDay() == 0,
                    isSat: date.getDay() == 6,
                    isDisplayArea: isDisplayArea,
                    events: resultEvents,
                } as reserve.DateItem;

                Rows.push(dateItem);
            }
            let NewRows = new Array;
            NewRows = Object.assign(NewRows, Rows);
            matrix.push(NewRows);
            timevalue += 0.5;
            tidx++;
        }
        state.weekMatrix = matrix;
    },

    [types.CALENDAR_DATE_MATRIX_LOAD](state) {
        state.dateMatrix = [];
        let workingArr:number[] = JSON.parse(state.calendarSetting.workingWeek);
        let copyEvents = [] as reserve.ItemList<reserve.CalendarItem>;

        Object.assign(copyEvents, state.resourceEvents);

        if (state.calendarMode == EnumCalendarViewType.Daily) {            
            for (let i = 0; i < copyEvents.length; i++) {
                const event = copyEvents[i];
                let removeFlag = false;
                if (event.scheduleDuration) {
                    let dayCount = Number(event.scheduleDuration.split('.')[0]);                    
                    if (dayCount > 0 && !isNaN(dayCount)) {
                        removeFlag = true;
                    }
                }

                if ((event.isAllDayEvent as any) == 'True' || event.isAllDayEvent == true) {
                    removeFlag = true;
                }

                if (removeFlag) {
                    copyEvents.splice(i, 1);
                    i--;
                }
            }
        }

        for (let c = 0; c < copyEvents.length; c++) {
            const event = copyEvents[c];           
            if (!event.ui_copyed) {
                let cStart = dateutil.uGetDateOnly(dateutil.IEDate(event.start));
                let cEnd = dateutil.uGetDateOnly(dateutil.IEDate(event.end));

                if (cStart.toString() != cEnd.toString()) {
                    let copyItem: reserve.CalendarItem = Object.create(null);
                    event.ui_week_start = dateutil.uGetDateFormat('yyyy-MM-DD H:i:00', dateutil.IEDate(event.start));
                    event.ui_week_end = dateutil.uGetDateFormat('yyyy-MM-DD 00:00:00', cEnd);
                    Object.assign(copyItem, event);

                    copyItem.ui_week_start = dateutil.uGetDateFormat('yyyy-MM-DD 00:00:00', cEnd);
                    copyItem.ui_week_end = dateutil.uGetDateFormat('yyyy-MM-DD H:i:00', dateutil.IEDate(event.end));
                    copyItem.ui_copyed = true;

                    copyEvents.push(copyItem);
                }
            }
        }

        const sortFn = function (a: any, b: any): number {
            return a == b ? 0 : a < b ? -1 : 1;
        }

        let matrix = [] as Array<Array<reserve.DateItem>>;
        let today = new Date();
        let daysList = [dateutil.uGetDateOnly(state.currentDate.date)]; //dateutil.uGetDaysInWeek(state.currentDate.date);
        let daysEventList = [];     
        let timeList = dateutil.uGetTimeArray([
            "오전", 
            "오후", 
        ]);

        let startTidx = (timeList.filter((x) => x.value == state.calendarSetting.workingStartTime)[0].num || 0) * 2;
        let endTidx = (timeList.filter((x) => x.value == state.calendarSetting.workingEndTime)[0].num || 24) * 2;

        let valueList = timeList.map((x) => x.value);
        valueList.push('24:00:00');
        for (let i = 0; i < daysList.length; i++) {
            const oDate = daysList[i];
            let date = new Date(oDate);
            let calcEvents = getContainsWeekCalcEvents(date, copyEvents);

            calcEvents.sort((a, b) =>
                sortFn(Number(dateutil.IEDate(a.ui_week_start || a.start)), Number(dateutil.IEDate(b.ui_week_start || b.start))) ||
                sortFn(Number(a.dateTimeCreated), Number(b.dateTimeCreated))
            );
            daysEventList[i] = calcEvents;
        }

        let timevalue = 0;
        let tidx = 0;
        let duplicateCheckArray: Array<Array<number>> = [];
        for (const timeItem of timeList) {
            for (let i = 0; i < daysList.length; i++) {
                const oDate = daysList[i];
                let eventArr = daysEventList[i];
                let date = new Date(oDate);
                date = new Date(dateutil.uGetDateFormat('yyyy-MM-DD', date) + 'T' + timeItem.value); // Set Time
                if (!duplicateCheckArray[i]) {
                    duplicateCheckArray[i] = [];
                }

                if (!duplicateCheckArray[i][tidx]) {
                    duplicateCheckArray[i][tidx] = 0;
                }

                for (let d = 0; d < eventArr.length; d++) {
                    const event = eventArr[d];

                    let date30m = dateutil.IEDate(date);
                    date30m.setMinutes(date30m.getMinutes() + 30);
                    if (dateutil.uGetInDateBoundary(date, date30m,
                        dateutil.IEDate(event.ui_week_start || event.start),
                        dateutil.IEDate(event.ui_week_end || event.end))) {
                        duplicateCheckArray[i][tidx]++;
                    }
                }

            }
            tidx++;
        }

        for (let i = 0; i < daysList.length; i++) {
            const oDate = daysList[i];
            for (let t = 0; t < daysEventList[i].length; t++) {
                const event = daysEventList[i][t];
                let sDate = dateutil.IEDate(event.ui_week_start || event.start);
                let eDate = dateutil.IEDate(event.ui_week_end || event.end);

                var arrMin = [0, 30]
                if (!arrMin.includes(sDate.getMinutes()))
                    sDate.setMinutes(sDate.getMinutes() > 30 ? 30 : 0);
                if (!arrMin.includes(eDate.getMinutes()))
                    eDate.setMinutes(eDate.getMinutes() > 30 ? 30 : 0);

                let TotalMins = dateutil.uGetTotalDiffMinutes(sDate, eDate);
                event.ui_week_height_count = Math.round(TotalMins / 30);

                if (event.ui_week_height_count <= 0) {
                    event.ui_week_height_count = 1;
                }

                //let sv = (event.ui_week_start || event.start).replace('T', ' ').replace('Z', ' ');
                //let ev = (event.ui_week_end || event.end).replace('T', ' ').replace('Z', ' ');
                // sv = dateutil.uGetDateFormat_UTC("yyyy-MM-DD H:i:s", new Date(sv));
                // ev = dateutil.uGetDateFormat_UTC("yyyy-MM-DD H:i:s", new Date(ev));
                let sv = (event.ui_week_start || event.start);
                let ev = (event.ui_week_end || event.end);
                sv = dateutil.uGetDateFormat("yyyy-MM-DD H:i:s", dateutil.IEDate(sv));  
                ev = dateutil.uGetDateFormat("yyyy-MM-DD H:i:s", dateutil.IEDate(ev));  

                let svalue = dateutil.DateAndTimeSplitor(sv)[1];
                let evalue = dateutil.DateAndTimeSplitor(ev)[1];

                const virtualTime = (time: string) => {
                    var tarr = time.split(':');
                    var last = tarr[tarr.length - 2];
                    if (!(last == '00' || last == '30')) {
                        let min = Number(last);
                        if (min < 30) {
                            return `${tarr[0]}:00:00`;
                        } else {
                            return `${tarr[0]}:30:00`;
                        } 
                    }
                    return time;
                }

                let Vsvalue = virtualTime(svalue);
                let Vevalue = virtualTime(evalue);                

                let sidx = valueList.indexOf(Vsvalue);
                let eidx = valueList.indexOf(Vevalue);

                if (sDate.getDate() != eDate.getDate() && evalue == '00:00:00') {
                    eidx = valueList.indexOf('24:00:00');
                }

                let max = 0;
                for (let dp = sidx; dp < eidx; dp++) {
                    let num = duplicateCheckArray[i][dp];                    
                    if (max < num) {
                        max = num;
                    }
                }
                event.ui_week_maxcount = max;
            }

            for (let x = 0; x < daysEventList[i].length; x++) {
                const source = daysEventList[i][x];
                let smax = source.ui_week_maxcount;
                for (let y = 0; y < daysEventList[i].length; y++) {
                    const target = daysEventList[i][y];
                    if (x != y) {
                        if (dateutil.uGetInDateBoundary(
                            dateutil.IEDate(source.ui_week_start || source.start),
                            dateutil.IEDate(source.ui_week_end || source.end),
                            dateutil.IEDate(target.ui_week_start || target.start),
                            dateutil.IEDate(target.ui_week_end || target.end))) {
                            if (smax < target.ui_week_maxcount) {
                                source.ui_week_maxcount = target.ui_week_maxcount;
                            }
                        }
                    }
                }
            }
            let saveIndexArray:Array<Array<reserve.CalendarItem>> = [];
            for (let x = 0; x < daysEventList[i].length; x++) {
                const source = daysEventList[i][x];
                let calcIdx = 0;

                for (let y = 0; y < x; y++) {
                    let sStartNum = Number(dateutil.IEDate(source.ui_week_start || source.start));
                    if (saveIndexArray[y]) {
                        for (let s = 0; s < saveIndexArray[y].length; s++) {
                            const sevent = saveIndexArray[y][s];
                            let bStartNum = Number(dateutil.IEDate(sevent.ui_week_start || sevent.start));
                            let bEndNum = Number(dateutil.IEDate(sevent.ui_week_end || sevent.end));
                            if (sStartNum >= bStartNum && sStartNum < bEndNum) {
                                calcIdx++;
                            } 
                        }

                        if (calcIdx == y) {
                            break;
                        }
                    }                    
                }
                if (!saveIndexArray[calcIdx]) 
                {
                    saveIndexArray[calcIdx] = [];
                }                
                
                source.ui_week_ui_index = calcIdx;

                saveIndexArray[calcIdx].push(source);
            }
        }


        tidx = 0;

        for (const timeItem of timeList) {
            if (tidx > 48) break;
            let Rows = [];
            let titleDateItem: reserve.DateItem = Object.create(null);
            titleDateItem.ui_days_week_time_text = dateutil.uGetZero(timevalue) as string;
            Rows.push(titleDateItem);
            for (let i = 0; i < daysList.length; i++) {
                let oDate = daysList[i];
                let date = new Date(oDate);
                date = new Date(dateutil.uGetDateFormat('yyyy-MM-DD', date) + 'T' + timeItem.value); // Set Time

                let isToday =
                    date.getFullYear() == today.getFullYear() &&
                    date.getMonth() == today.getMonth() &&
                    date.getDate() == today.getDate();

                let isSelected =
                    date.getFullYear() == state.currentCalendarTime.date.getFullYear() &&
                    date.getMonth() == state.currentCalendarTime.date.getMonth() &&
                    date.getDate() == state.currentCalendarTime.date.getDate() &&
                    date.getHours() == state.currentCalendarTime.date.getHours() &&
                    date.getMinutes() == state.currentCalendarTime.date.getMinutes();

                let isDisplayArea = false;
                if (state.calendarMode == EnumCalendarViewType.Month) {
                    if (state.currentCalendarDate.date.getMonth() == date.getMonth()) {
                        isDisplayArea = true;
                    }
                }

                if (state.calendarMode == EnumCalendarViewType.Week) {
                    let weekStartDate = dateutil.uGetWeekStartDate(state.currentCalendarDate.date, state.calendarSetting.firstDay);
                    let weekEndDate = dateutil.uGetWeekEndDate(state.currentCalendarDate.date, state.calendarSetting.firstDay);

                    if (Number(weekStartDate) <= Number(date) && Number(weekEndDate) >= Number(date)) {
                        isDisplayArea = true;
                    }
                }

                if (state.calendarMode == EnumCalendarViewType.Daily) {
                    if (isSelected) {
                        isDisplayArea = true;
                    }
                }

                let curEvents = getContainsWeekEvents(i, date, copyEvents);

                for (let e = 0; e < curEvents.length; e++) {
                    const event = curEvents[e];
                    copyEvents.splice(event.index - e, 1);
                }

                let max = 0;
                for (let t = 0; t < curEvents.length; t++) {
                    const event = curEvents[t];
                    max = event.ui_week_maxcount;
                }               
                
                let resultEvents = [];

                for (let c = 0; c < max; c++) {
                    let isAddedFlag = false;
                    for (let f = 0; f < curEvents.length; f++) {
                        const fevent = curEvents[f];
                        if (fevent.ui_week_ui_index == c) {
                            let copyEvent: reserve.CalendarItem = Object.create(null);
                            Object.assign(copyEvent, fevent);
                            addPrintEvent(state, copyEvent);
                            resultEvents.push(copyEvent);
                            isAddedFlag = true;
                            break;
                        }
                    }

                    if (!isAddedFlag) {
                        resultEvents.push({
                            id: 'null',
                            color: 'null',
                            organizer: {
                                name: null,
                            },
                        });
                    }
                }

                let isWorkingTime = workingArr.indexOf(date.getDay()) != -1 && tidx >= startTidx && tidx < endTidx;

                let dateItem = {
                    date: date,
                    isToday: isToday,
                    isSelected: isSelected,
                    isWorkingTime: isWorkingTime,
                    isSun: date.getDay() == 0,
                    isSat: date.getDay() == 6,
                    isDisplayArea: isDisplayArea,
                    events: resultEvents,
                } as reserve.DateItem;

                Rows.push(dateItem);
            }
            let NewRows = new Array;
            NewRows = Object.assign(NewRows, Rows);
            matrix.push(NewRows);
            timevalue += 0.5;
            tidx++;
        }
        state.dateMatrix = matrix;
    },

    [types.CALENDAR_EVENTLIST_LOAD](state, events: reserve.ItemList<reserve.CalendarItem>) {
        state.resourceEvents = events;        
    },

    [types.CALENDAR_DATE_SELECT](state, currentDate: reserve.DateItem) {             
        var newDate = dateutil.IEDate(currentDate.date);
        var newDateItem: reserve.DateItem = {
            ...currentDate,
            date: newDate
        }

        state.backupDate = newDateItem;
        state.currentDate = newDateItem;
        state.currentCalendarDate = newDateItem;
        state.monthDate = newDate;
    },

    [types.CALENDAR_DAILY_EVENT_LOAD](state, events: reserve.ItemList<reserve.CalendarItem>) {
        state.resourceDailyEvents = events;
    },

    [types.CALENDAR_SEARCH_EVENTS_LOAD](state, events: reserve.ItemList<reserve.CalendarItem>) {
        state.searchEvents = events;
    },

    [types.CALENDAR_SEARCHSET_UPDATE](state, payload: reserve.ReserveSearchSet) {
        state.searchSet = payload;
    },

    [types.TOGGLE_MAIN_LISTVIEW](state, payload: boolean) {
        state.listViewOpen = payload;
    }
};
