import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as dateutil from '@appbase/util/date-helper'
import api from '../../api'
import { debounce } from '@appbase/util/debounce'
import EventListItem from './EventListItem.vue'
import { CalendarEventDetail, CalendarFolderInfo } from './type'
import { LoadingBar } from '@appbase/components/loading'
import { skeletonEvents } from './skeleton'
import { NoData } from '../common'
import { mapGetters } from 'vuex'
import { CURRENT } from '@account/store/types'

const getRequestData = (
  folders: CalendarFolderInfo[],
  szDate: string
): webparts.calendarRequlest => {
  const dateEnd = dateutil.IEDate(szDate)
  dateEnd.setDate(dateEnd.getDate() + 1)

  return {
    EwsFolderIDArray: [...folders.filter(x => x.folderType == 'EWS').map((f) => f.id)],
    StartDate: szDate,
    EndDate: dateutil.uGetDateFormat('yyyy-MM-dd', dateEnd),
    Status: null,
    Subject: null,
    Location: null,
    OrganizerName: null,
    OrganizerAddress: null,
    PersonCode: '',
    RequiredAttendees: null,
    OptionalAttendees: null,
    EumNoCondition: false,
    EumPageNumber: 1,
    EumRowsPerPage: 9999,
  }
}

@Component({
  components: { EventListItem, LoadingBar, NoData },
  computed: {
    ...mapGetters({
      current: CURRENT,
    }),
  },
})
export default class EventList extends Vue {
  constructor() {
    super()
    this.loadEvents = debounce(this.loadEvents, 200) as any
  }

  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: String }) value!: string
  @Prop({ type: Array }) weeks!: string[]
  @Prop({ type: Array }) folders!: CalendarFolderInfo[]

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  current!: account.TCurrent
  events: Array<CalendarEventDetail> = skeletonEvents
  loading: boolean = true
  firstRender: boolean = true
  text: string = this.$t('등록된_일정이_없습니다').toString()

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //

  get YearMonthDayDateFormat() {
    const szDate = dateutil.uGetDateFormat('yyyy.MM.DD', this.date)
    const szWeek = this.weeks[this.date.getDay()]
    return `${szDate} (${szWeek})`
  }

  get YearMonthDateFormat() {
    return dateutil.uGetDateFormat('yyyy.MM', this.date)
  }

  get date(): Date {
    return dateutil.IEDate(this.value)
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: W A T C H E S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  @Watch('value')
  onValueChanged(newVal: string) {
    // 기준 날짜가 변경되면 해당 일정을 가져옵니다.
    this.loadEventsWrapper(this.folders, newVal)
  }

  @Watch('folders')
  onFoldersChanged(folders: Array<CalendarFolderInfo>) {
    this.loadEventsWrapper(folders, this.value)
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  mounted() {
    // // 기준 날짜 일정 조회
    // this.loadEventsWrapper(this.folders, this.value)
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  loadEventsWrapper(
    folders: Array<CalendarFolderInfo>,
    szDate: string,
    abort: boolean = false
  ) {
    this.loading = true
    this.loadEvents(folders, szDate, abort)
  }

  /**
   * EWS를 통해 기준일의 일정 아이템 목록을 조회합니다.
   */
  async loadEvents(
    folders: Array<CalendarFolderInfo>,
    szDate: string,
    abort: boolean = false
  ) {
    if (abort) {
      return
    }

    console.log('szDate', szDate)

    let events: Array<CalendarEventDetail> = []

    // EWS 데이터 조회
    const request = getRequestData(folders, szDate)
    var results = await api.webPart.getCalendarEvents(request)
    
    // DB 데이터 조회
    var compCalendarId = folders.find(x => x.folderType == 'DB') ? Number(folders.find(x => x.folderType == 'DB')?.id) : 0;
    var stDate = new Date(szDate);
    var edDate = new Date(szDate);
    
    if (compCalendarId > 0){
      var item = {
        FolderID: compCalendarId,
        StartDate: stDate,
        EndDate: edDate
      }

      const dbResults = await api.webPart.getCalendarEventList(item)
      results = [...results, ...dbResults]
    }
    
    results = (results as any[]).filter((e) => {
      var startTIme = dateutil.IEDate(e.start)
      var endTime = dateutil.IEDate(e.end)
      var startString = dateutil.uGetDateFormat('yyyy-MM-dd', startTIme)
      var endTimeString = dateutil.uGetDateFormat('yyyy-MM-dd H:i:s', endTime)

      return !(
        startString !== szDate &&
        endTimeString === `${szDate} 00:00:00` &&
        e.isAllDayEvent
      )
    })

    // 회사일정 우선 표시를 위한 정렬
    results.sort((a, b) => {
      if (a.parentFolderId.toString() < b.parentFolderId.toString()) return -1;
      if (a.parentFolderId.toString() > b.parentFolderId.toString()) return 1;
      return 0;
    });

    console.log('results', results)

    this.events = results.map((e) => {
      return <CalendarEventDetail>{
        scheduleId: e.id,
        isAllDay: e.isAllDayEvent,
        title: e.subject,
        place: e.location!,
        startDate: dateutil.IEDate(e.start),
        endDate: dateutil.IEDate(e.end),
        color: this.findColor(e.parentFolderId),
      }
    })

    this.$nextTick(() => {
      this.firstRender = this.loading = false
    })
  }

  handleSetToday = () =>
    this.$emit('input', dateutil.uGetDateFormat('yyyy-MM-DD', new Date()))

  findColorCode(folderId: string) {
    return this.folders.find((item) => {
      return item.id
    })?.color.code
  }

  findColor(folderId: string) {
    return this.folders.find((item) => {
      return item.id == folderId
    })?.color
  }

  goToCalendar() {
    let origin = window.location.origin

    let menudId = '1105'
    if (this.current.user.comCode == 'MELLOW') {
      // if(origin.indexOf("dev") > -1 ){ //개발 운영 메뉴아이디가 달라서 구분처리 함
      menudId = '1133'
    }

    window.location.href = `/po/home/proxy?MENUID=${menudId}`
  }
}
