import * as types from './types';
import { ActionTree } from 'vuex';
import api from '@board/api';
 

export default <ActionTree<boardModule.state.BoardState, RootState>>{
    /**
    * 로딩 UI를 호출합니다
    * @param action 
    * @param flag 
    */
    [types.ARTICLE_LOADDING](action, flag: boolean) {
        action.commit(types.ARTICLE_LOADDING, flag);
    },

    [types.POPUP_LOADING](action, flag: boolean) {
        action.commit(types.POPUP_LOADING, flag);
    },

    [types.FETCH_BOARD](action, boardId: number) {
        action.commit(types.BOARD_LOADDING, true);
        return api.board.getBoard(boardId)
            .then(res => {
                 
                
                action.commit(types.FETCH_BOARD, res);
                action.commit(types.FETCH_LISTFIELD, res.listFields);
                action.commit(types.FETCH_CONTENTFIELD, res.contentFields);               
                return res;
            })
    },
    [types.FETCH_WRITECONFIG](action, boardId: number) {
        action.commit(types.BOARD_LOADDING, true);
        return api.board.getWriteConfig(boardId)
            .then(res => {
                action.commit(types.ARTICLE_LOADDING, false);
                return res;
            })
    },
    [types.FETCH_READCONFIG](action, boardId: number) {
        action.commit(types.ARTICLE_LOADDING, true);
        return api.board.getReadConfig(boardId)
            .then(res => {
                action.commit(types.ARTICLE_LOADDING, false);
                return res;
            })
    },
    [types.FETCH_TOTALARTICLES](action, searchset: article.TotalArticleSearchSet) {
        action.commit(types.BOARD_LOADDING, true);
        return api.article.getTotalArticles(searchset)
            .then(res => {
                action.commit(types.FETCH_TOTALARTICLES, res.data);
                action.commit(types.BOARD_LOADDING, false);
                return res;
            })
    },
    [types.FETCH_ARTICLES](action, searchset: article.SearchSet) {
       
        action.commit(types.BOARD_LOADDING, true);     
        return api.article.getArticles(searchset)
            .then(res => {
                action.commit(types.FETCH_ARTICLES, res.data);
                action.commit(types.BOARD_LOADDING, false);
                return res;
            })
    },
    [types.FETCH_ADMINARTICLES](action, searchset: article.SearchSet) {
        action.commit(types.BOARD_LOADDING, true);
        return api.article.getAdminArticles(searchset)
            .then(res => {
                action.commit(types.FETCH_ARTICLES, res.data);
                action.commit(types.BOARD_LOADDING, false);
                return res;
            })
    },
    [types.FETCH_ARTICLESEMPTY](action){
        action.commit(types.FETCH_ARTICLES, null);
        action.commit(types.BOARD_LOADDING, false);
    },
    [types.FETCH_BLOGARTICLES](action, searchset: article.SearchSet) {
        action.commit(types.BOARD_LOADDING, true);
        return api.article.getBlogArticles(searchset)
            .then(res => {
                action.commit(types.FETCH_BLOGARTICLES, res.data);
                action.commit(types.BOARD_LOADDING, false);
                return res;
            })
    },
    [types.SEND_MAIL](action, mail: boardmail.EmailSendRequest) {
        return api.boardmail.sendMail(mail)
            .then(res => {
                return res;
            })
    },
    [types.CREATE_PERMISSION](action, set: article.PermissionSet) {
        return api.article.createPermission(set)
            .then(res => {
                return res;
            })
    },
    [types.FETCH_VIEWLOGS](action, articleId: number) {
        return api.article.getViewLogList(articleId)
            .then(res => {
                return res;
            })
    },
    [types.FETCH_FILE](action, fileCode: string) {
        return api.article.getArticleFile(fileCode)
            .then(res => {
                return res;
            })
    },
    [types.CREATE_FILE](action, set: article.FileCreateSet) {
        action.commit(types.ARTICLE_LOADDING, true);
        return api.article.createArticleFile(set)
            .then(res => {
                action.commit(types.ARTICLE_LOADDING, false);
                return res;
            })
    },
    [types.FETCH_COMMENT](action, set: article.CommentFetchSet) {
        return api.article.getArticleComments(set)
            .then(res => {
                return res;
            })
    },
    [types.FETCH_BLOGCOMMENT](action, set: article.CommentFetchSet) {
        return api.article.getArticleComments(set)
            .then(res => {
                action.commit(types.FETCH_BLOGCOMMENT, <article.CommentFetchResult>{
                    articleId: set.articleId,
                    comments: res.data
                });
                return res;
            })
    },
    [types.FETCH_PAGESET](action, pageSet: boardModule.state.PageSet) {
        action.commit(types.FETCH_PAGESET, pageSet);
    },
    [types.CREATE_ARTICLE](action, set: article.ArticleQuerySet) {
        action.commit(types.ARTICLE_LOADDING, true);
        return api.article.createArticle(set)
            .then(res => {
                action.commit(types.ARTICLE_LOADDING, false);
                return res;
            })
    },
    [types.READ_ARTICLE](action, set: article.ArticleFetchSet) {
        action.commit(types.ARTICLE_LOADDING, true);
        return api.article.getArticleDetail(set.boardId, set.articleId)
            .then(res => {
                action.commit(types.ARTICLE_LOADDING, false);
                return res;
            });
    },
    [types.UPDATE_ARTICLE](action, set: article.ArticleQuerySet) {
        action.commit(types.ARTICLE_LOADDING, true);
        return api.article.updateArticle(set)
            .then(res => {
                action.commit(types.ARTICLE_LOADDING, false);
                return res;
            })
    },
    [types.DELETE_ARTICLE](action, set: article.DeleteArticle) {
        action.commit(types.ARTICLE_LOADDING, true);
        return api.article.deleteArticle(set)
            .then(res => {
                action.commit(types.ARTICLE_LOADDING, false);
                return res;
            })
    },
    [types.CREATE_VIEWLOG](action, set: article.CreateOptionSet) {
        return api.article.createViewLog(set)
            .then(res => {
                return res;
            })
    },
    [types.CREATE_COMMENT](action, set: article.CreateComment) {
        return api.article.createComment(set)
            .then(res => {
                return res;
            })
    },
    [types.DELTE_COMMENT](action, commentId: number) {
        return api.article.deleteComment(commentId)
            .then(res => {
                return res;
            })
    },
    [types.CREATE_RECOMMEND](action, set: article.CreateOptionSet) {
        return api.article.createRecommend(set)
            .then(res => {
                return res;
            })
    },
    [types.DELETE_RECOMMEND](action: any, set: article.DeleteRecommendSet) {
        return api.article.deleteRecommend(set)
            .then(res => {
                return res;
            })
    },
    [types.GET_RECOMMENDS](action, articleId: number) {
        return api.article.getRecommendList(articleId)
            .then(res => {
                return res;
            })
    },
    [types.TOGGLE_COMMENT](action, articleId: number) {
        action.commit(types.TOGGLE_COMMENT, articleId);
    },
    [types.TOGGLE_RECOMMEND](action, articleId: number) {
        action.commit(types.TOGGLE_RECOMMEND, articleId);
    },
    [types.TOGGLE_RECCOMENDICON](action, articleId: number) {
        action.commit(types.TOGGLE_RECCOMENDICON, articleId);
    },
    [types.TOGGLE_FILE](action, articleId: number) {
        action.commit(types.TOGGLE_FILE, articleId);
    }
};
