<template>
  <div class="outer-wrap">
    <div id="inner-wrap" class="main-content">
      <div class="main-portal-wrap">
        <div class="main-portal-content">
          <div class="row">
            <div class="col-8">
              <div class="wpart part-user-profile">
                <slot />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
