import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import api from '@gnb/api/common'
@Component({
    components:{ModalHeader,ModalContent,ModalFooter,ContentItem,PreferenceContentTemplate,Dropdown}
})
export default class LayoutView extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    @Prop({ type: Object }) user!: account.User;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    radioValues: string = '';
    name: string = this.$t('읽기_창_표시_위치').toString();
  
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    onChangeView(type:string){
        if(type=='vertical'){
            this.radioValues = 'vertical'
        }else{
            this.radioValues = 'horizontal'
        }
    }                                                 

    getLayoutView(){
        api.getUserConfig(this.user.personCode).then((res)=>{
            if(res[0].mailLayout == 'vertical'){
                 this.radioValues = 'vertical'
            }else{
                 this.radioValues = 'horizontal'
            }
         })
    }

    save(){
        api.setUserConfig({personCode: this.user.personCode, mailLayout: this.radioValues} as gnb.UserConfig);
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    created(){
        this.getLayoutView();
     }
}