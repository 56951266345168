import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Paginate from './Paginate.vue'

@Component({
  components: { Paginate },
})
export default class PageNavigationBar extends Vue {
  //
  // props
  //

  // page number of page
  @Prop({ type: Number, default: 0 }) value!: number
  // total count
  @Prop({ type: Number, default: 0 }) total!: number
  // page size
  @Prop({ type: Number, default: 20 }) size!: number
  // range
  @Prop({ type: Number, default: 5 }) range!: number

  //
  // computed
  //

  get currentPage() {
    return this.value
  }

  get lastPage() {
    return this.totalPageCount
  }

  get totalPageCount() {
    return Math.ceil(this.total / this.size)
  }

  get isFirstPage() {
    return this.currentPage == 1
  }

  get isLastPage() {
    return this.currentPage == this.totalPageCount
  }

  //
  // methods
  //

  moveByNumber(idxTo: number) {
    this.$emit('pageMove', idxTo)
    this.$emit('input', idxTo)
  }

  moveToFirst() {
    this.moveByNumber(1)
  }

  moveToLast() {
    this.moveByNumber(this.lastPage)
  }

  moveToNext() {
    this.moveByNumber(this.calcNextPageNumber(1))
  }

  moveToPrev() {
    this.moveByNumber(this.calcPrevPageNumber(1))
  }

  moveToNextRange() {
    this.moveByNumber(this.calcNextPageNumber(this.range))
  }

  moveToPrevRange() {
    this.moveByNumber(this.calcPrevPageNumber(this.range))
  }

  calcNextPageNumber(range: number) {
    let idxTo = this.value + range > this.lastPage ? this.value : this.value + range
    return idxTo
  }

  calcPrevPageNumber(range: number) {
    let idxTo = this.value - range < 1 ? this.value : this.value - range
    return idxTo
  }
}
