import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import editorDirective, { loaded, unload } from './dext5-directive'
const { v4: uuidv4 } = require('uuid')

declare var DEXT5: editor.dext5.DEXT5

@Component({
  name: 'dext5-editor',
  directives: {
    dext5: editorDirective,
  },
})
export default class Dext5Editor extends Vue implements editor.IEumEditor {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  /** 본문 문자열 */
  @Prop({ type: String, default: '' }) private value!: string
  @Prop({ type: String, default: 'editor' }) mode!: editor.EditorMode
  /** 에디터 초기화 기 기본 몬문 문자열 */
  @Prop({ type: String, default: '' }) initialValue!: string
  /** 에디터 비활성화 여부 */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** 에디터 초기화 옵션 */
  @Prop({ type: Object }) dext5opts!: editor.dext5.DEXT5Config

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  /** 고유 키 생성 : 화면에 여러개의 에디터가 존재할 경우 ID 값이 고유해야함 */
  editorId!: string
  /** 본문 문자열 */
  body: string = '<p>&nbsp;</p>'
  /** 문문 문자열을 주기적으로 가져오기 위한 타이머 */
  timer: any = null
  /** 컴포넌트 폐기 이후 본문을 가져오는 타이머가 동작하지 않도록하기 위함 */
  disposed: boolean = false

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: W A T C H E S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  @Watch('value')
  onValueChanged(val: string) {
    console.log('onValueChanged', val)
    if (val === this.body) return // 최적화
    if (!this.loaded()) return // 에디터 초기화 전에는 initialValue로만 본문 설정 가능
    this.setBodyValue(val) // 에디터와 로컬 body 상태 설정
  }

  //
  // ──────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────
  //

  created() {
    // editor unique id
    const uuid = uuidv4() as any
    this.editorId = 'editor' + uuid.replaceAll('-', '')
    // 본문 변경사항을 감시하는 타이머 시작
    // Timer가 동작함에 따라 에디터가 깜빡이는 증상이 었어서 주석 처리함.
    // this.startTimer()
  }

  beforeDestroy() {
    this.disposed = true
    clearTimeout(this.timer)
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  /**
   * 에디터가 초기화되기 전에 value 값이 업데이트되는 경우를 위해
   * 에디터가 로드된 다음 다시 시도
   */
  handleLoaded() {
    console.log(this.handleLoaded.name)
    const { initialValue, value } = this
    if (!initialValue && value) {
      this.setBodyValue(this.value)
    }
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Private Methods

  /** DEXT5 Editor 초기화된 상태 */
  loaded(): boolean {
    return loaded(this.editorId)
  }

  // private startTimer(): void {
  //   const _this = this
  //   this.timer = setTimeout(() => {
  //     const { loaded, body, getBodyValue, notifyChangeBody, startTimer, disposed, timer } = _this
  //     // 컴포넌트가 폐기된 경우 타이머 리소스 해제
  //     clearTimeout(timer)
  //     if (disposed) {
  //       return
  //     }
  //     if (loaded()) {
  //       // 본문의 변경사항이 있을 경우, 변경사항을 알림
  //       const curBody = getBodyValue()
  //       if (body.length !== curBody.length) {
  //         notifyChangeBody(curBody)
  //       }
  //     }
  //     // 타이머 다시 시작
  //     if (!disposed) {
  //       startTimer()
  //     }
  //   }, 1000)
  // }

  // private notifyChangeBody(body: string) {
  //   if (this.loaded()) {
  //     console.log('notifyChangeBody', this.loaded(), ',', this.body, ',', body)
  //     this.body = body
  //     this.$emit('input', body)
  //   }
  // }

  // ────────────────────────────────────────────────────────────────────────────────
  // Public Methods (To access from other components)

  /** 에디터 본문 컨텐츠를 가져옵니다. */
  public getBodyValue(): string {
    return DEXT5.getBodyValue(this.editorId)
  }

  /** 에디터의 고유 아이디를 가져옵니다. */
  public getEditorId(): string {
    return this.editorId
  }

  /** 컨텐츠를 에디터 본문으로 설정합니다. */
  public setBodyValue(value: string): void {
    console.log('setBodyValue', this.loaded(), value)
    this.body = value
    this.$nextTick(() => DEXT5.setBodyValue(value, this.editorId))
  }

  /** 본문이 비어있는 상태인지 검사합니다. */
  public isEmpty(): boolean {
    return DEXT5.isEmpty(this.editorId)
  }

  /** 에디터 본문을 빈 값으로 초기화합니다. */
  public clear(): void {
    DEXT5.setBodyValue('', this.editorId)
  }
}
