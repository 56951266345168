import { cache } from '@appbase/util'
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({
    name: 'DetailCard',
})
export default class DetailCard extends Vue {
    @Prop({ type: Object }) item!: orgmap.TMemberItem | null
    @Prop({ type: String }) companyName!: string
    @Prop({ type: String }) userlang!: string

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    visible: boolean = false
    avatar = {
        url: '',
        loading: false,
    }

    companyNameDisplay: string = ''

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Watch('item')
    onItemChanged(newVal: orgmap.TMemberItem | null) {
        this.companyNameDisplay = this.companyName
        this.updateVisible(newVal)
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    mounted() {
        this.updateVisible(this.item)
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    getFax(item: orgmap.TMemberItem) {
        if (item.officeFax) {
            return `(FAX : ${item.officeFax})`;
        }
        return '';
    }

    handleClose() {
        this.visible = false
    }

    updateVisible(item: orgmap.TMemberItem | null) {
        this.visible = item ? true : false
        cache.avatar.get(item?.email || '').then((url) => {
            this.avatar.url = url
            this.avatar.loading = false
        })
    }

    catchImgError() {
        if (
            (this.$refs.photo as HTMLImageElement) != undefined &&
            (this.$refs.photo as HTMLImageElement).src !== window.location.href
        ) {
            ; (this.$refs.photo as HTMLImageElement).src = '/cm/gres/faux/images/ghost.png'
        }
    }

    openTeamsChat() {
        if(this.item) {
            var segment = this.item.id.indexOf('_');
            var account = this.item.id.substring(segment + 1);

            var chatUrl = `MSTeams:/l/chat/0/0?users=${account}@kyoboaxa.com`

            if (this.isTeams) {
                window.location.href = chatUrl;
            } else {
                var childWindow = window.open(chatUrl);
                
                // if(childWindow) {                   
                //     setTimeout(function () { childWindow?.close(); }, 3);                                    
                // }
            }
        }        
    }

    async clipboardCopy(copyText: string){
        var txtObj = document.createElement('textarea');
        txtObj.value = copyText;
        document.body.appendChild(txtObj);
    
        txtObj.select();
        document.execCommand('copy');
        document.body.removeChild(txtObj);
        await (window as any).alertAsync(this.$t('복사하였습니다').toString());
    }
}
