import * as dateutil from '@appbase/util/date-helper'
import {i18n as lang} from '@appbase/util'

export const uSetDisplayDate = function(date: Date): string {
    var displayDate = new Date(date.toString());
    return dateutil.uGetDateFormat('yyyy-MM-DD', displayDate);
}

export const uSetDisplayTime = function(date: Date): string {
    var displayDate = new Date(date.toString());
    return dateutil.uGetDateFormat('H:i:s', displayDate);
}

export const uSetDisplayWorkingTime = function(workingTime: any | null, hrTime: any | null): string{
    let hour = 0
    let minute = 0

    if (workingTime){
        hour += workingTime.value.hours;
        minute += workingTime.value.minutes;            
    }

    if (hrTime){
        hour += hrTime.value.hours;
        minute += hrTime.value.minutes;
    }

    if (hour > 0 || minute > 0){
        return ((hour > 9 ? hour : `0${hour}`) + ":" + (minute > 9 ? minute : `0${minute}`))
    }
    else {
        return ''
    }
}

export const uDisplayAttendanceResult = function(result: number | null) : string {
    let attendanceResult : string = ''

    if (result != null) {
        switch(result){
            // case 0:
            //     attendanceResult = lang.t('정상').toString();
            //     break;
            case 1:
                attendanceResult = lang.t('지각').toString();
                break;
            // case 2:
            //     attendanceResult = lang.t('출근이상').toString();
            //     break;
            // case 3:
            //     attendanceResult = lang.t('퇴근이상').toString();
            //     break;
            // case 4: 
            //     attendanceResult = lang.t('근무시간_미달').toString();
            //     break;    
            // case 5: 
            //     attendanceResult = lang.t('근무시간_초과').toString();   
            //     break;
        }
    }

    return attendanceResult;
}