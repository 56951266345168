import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {ListHeaderRight} from '../index';

@Component({
    components: {
        ListHeaderRight
    }
})
export default class TotalBoardHeader extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    @Prop({type: Array}) headers!: any;
    @Prop({type: Boolean, default: false}) isPreview!: boolean;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    activeIndex: number = 0;

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //
    get pagingShow(){
        return this.headers[this.activeIndex].isPaging;
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    TabClick(index: number, board: any){
        if(this.activeIndex != index){
            this.activeIndex = index;
            this.$emit('TabClick', board);
        }
    }
    TabMore(boardId: number){
        if(!this.isPreview){
            this.$router.push(`/Board?SYSID=${boardId}`);
        }
    }
    Prev(){
        this.$emit('Prev');
    }
    Next(){
        this.$emit('Next');
    }
    Refresh(){
        this.$emit('Refresh');
    }
}

