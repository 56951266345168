import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { CalendarEventDetail } from './type'
import { openPopup } from '@appbase/util'

@Component
export default class EventListItem extends Vue {
  @Prop({ type: Object }) item!: CalendarEventDetail
  @Prop({ type: Boolean }) isLoading!: boolean
  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //

  // get style() {
  //   const { hasColor, code } = this.item.color
  //   return hasColor ? `background-color: ${code};` : ''
  // }
  // get className() {
  //   const { hasColor, code } = this.item.color
  //   return hasColor ? '' : `bg-category-${code}`
  // }
  get style() {
    const { hasColor, code } = this.item.color
    return hasColor ? `border-color: ${code};` : ''
  }
  get className() {
    const { hasColor, code, category } = this.item.color
    return ''//category
  }

  get timeRange() {
    const { isAllDay, startDate, endDate } = this.item
    if (isAllDay) {
      return ''
    } else {
      const slice = (val: any = 0) => ('0' + val).slice(-2)
      const sh = slice(startDate?.getHours())
      const sm = slice(startDate?.getMinutes())
      const eh = slice(endDate?.getHours())
      const em = slice(endDate?.getMinutes())
      return `${sh}:${sm} ~ ${eh}:${em}`
    }
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  handleSelected() {
    const { scheduleId } = this.item
    const encodedId = encodeURIComponent(scheduleId)



    openPopup(
      'CalendarWebpart',
      `${window.location.origin}/mail/calendarpopup/edit?itemid=${encodedId}&target=MY&bname=main-calendar`,
      {},
      {
        width: 1024,
        height: 700,
      }
    )
  }
}
