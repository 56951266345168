<template>
  <div class="plan-circle" :style="style" :class="className"></div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  computed: {
    style() {
      const { hasColor, code } = this.item
      return hasColor ? `background-color: ${code};` : ''
    },
    className() {
      const { hasColor, code } = this.item
      return hasColor ? '' : `bg-category-${code}`
    },
  },
}
</script>

<style></style>
