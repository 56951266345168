import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
// modules
import moduleAccount from '../modules/@account/store'
import moduleAppbase  from '@appbase/store'
import { store as moduleBoard } from '@board/module'
import moduleOrgmap from '../modules/@orgmap/store'
import moduleExample from '../modules/example/store'
import moduleHome from '../modules/home/store'
import moduleReserve from '../modules/reserve/store'
import moduleSurvey from '../modules/survey/store'
import moduleGnb from '../modules/@gnb/store'
import moduleWebparts from '../modules/@webparts/store'
import moduleReserveDb from '../modules/reservedb/store'
import moduleAttendance from '../modules/attendance/store'

Vue.use(Vuex)

// initial state
const state: RootState = {}

export default new Vuex.Store<RootState>({
  state,
  actions,
  getters,
  mutations,
  modules: {
    $account: moduleAccount,
    $appbase: moduleAppbase,
    $orgmap: moduleOrgmap,
    $board: moduleBoard({
      baseUrl: process.env.NODE_ENV === 'production' ? '/po/' : '/',
      popupBaseName: 'BoardPopup',
    }),
    $example: moduleExample,
    $home: moduleHome,
    $reserve: moduleReserve,
    $reservedb:moduleReserveDb,
    $survey: moduleSurvey,
    $gnb: moduleGnb,
    $webparts: moduleWebparts,
    $attendance: moduleAttendance,
  },
})
