import { DirectiveOptions } from 'vue'
import DextEditor from './Dext5Editor'

declare var DEXT5: editor.dext5.DEXT5
declare var Dext5editor: any

var callbacks: { [key: string]: Function } = {}
var loadedEditorIds: string[] = []

/** DEXT5 Editor 초기화된 상태 */
export function loaded(id: string) {
  return loadedEditorIds.includes(id)
}

export function unload(id: string) {
  const idx = loadedEditorIds.indexOf(id)
  if (idx >= 0) {
    loadedEditorIds = [...loadedEditorIds.slice(0, idx), ...loadedEditorIds.slice(idx + 1)]
  }
  DEXT5.destroy(id)
}

;(window as any).dext_editor_loaded_event = function (editor: any) {
  loadedEditorIds.push(editor.ID as string)
  if (callbacks[editor.ID]) {
    callbacks[editor.ID]()
  }
}

const defaultOptions: editor.dext5.DEXT5Config = {
  ...Object.create({}),
  ...{
    Mode: 'editor',
    Width: '100%',
  },
}

const editorDirective: DirectiveOptions = {
  inserted: (el, binding, vnode) => {
    const ctx = vnode.context as DextEditor
    const { editorId, initialValue, dext5opts: options, mode } = ctx
    const { rawName, value } = binding as any
    console.log('inserted', editorId, rawName, value)
    if (rawName === 'v-dext5:loaded') {
      callbacks[editorId] = value
    }

    DEXT5.config = {
      ...DEXT5.config,
      ...defaultOptions,
      ...options,
      EditorHolder: editorId,
    }
    DEXT5.config.Mode = mode === 'editor' ? 'editor' : 'view'
    console.log('DEXT5.config:', DEXT5.config)
    // // ────────────────────────────────────────────────────────────────────────────────
    // // DEXT5 Editor Config
    // // DEXT5.config.PasteImageBase64 = '1'
    // // DEXT5.config.UploadMethod = 'base64'
    // // DEXT5.config.UploadUrl = '/assets/libs/dext5editor/handler/upload_handler.ashx'
    // DEXT5.config.Width = '100%' //넓이
    // DEXT5.config.Height = '100%' //높이
    // // DEXT5.config.SkinName = 'silver'
    // DEXT5.config.EditorHolder = editorId
    // // DEXT5.config.AutoStartUp = '1' // 컨트롤에서 Holder가 준비 완료될때까지 대기한 다음 초기화 진행

    // ────────────────────────────────────────────────────────────────────────────────
    // Initialize Editor
    new Dext5editor(editorId)
    DEXT5.setBodyValue(initialValue, editorId)
  },
}
export default editorDirective
