import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex';
import * as types from '@board/store/types'
import * as accountTypes from '@account/store/types'
import { IEDate, uGetDateFormat } from '@appbase/util/date-helper';
import { dispatchEventBus } from '@appbase/util/eventBus';
import localStorageHelper from '@appbase/util/local-storage-helper';
import * as aes from '@appbase/util/aes-encrypt'
import { getGUID } from '@appbase/util/guid';
import { open, OpenType } from '@board/open-handler'; 

import { EumEditor } from '@appbase/components/editor'
import AttachFileField from '@board/components/board/readComponents/AttachFileField.vue';
import CommentBox from '@board/components/board/readComponents/CommentBox.vue';
import RecommendBox from '@board/components/board/readComponents/RecommendBox.vue';
import CheckBox from '@board/components/common/CheckBox.vue'

import TextField from '@board/components/board/readComponents/TextField.vue'
import TextAreaField from '@board/components/board/readComponents/TextAreaField.vue'
import SelectField from '@board/components/board/readComponents/SelectField.vue'
import DatetimeField from '@board/components/board/readComponents/DatetimeField.vue'
import TimeSpanField from '@board/components/board/readComponents/TimeSpanField.vue'
import MailSendField from '@board/components/board/readComponents/MailSendField.vue'
import TagField from '@board/components/board/readComponents/TagField.vue';
import SelectUserField from '@board/components/board/readComponents/SelectUserField.vue'
import PasswordCheck from '@board/components/common/PasswordCheck.vue';
import ArticleHistoryList from '@board/components/board/readComponents/ArticleHistoryList.vue'
import { LoadingBar } from '@appbase/components/loading';
import api from '@board/api';
import { cache } from '@appbase/util';
import menuHelper from '@appbase/util/menu-helper'


var ids: common.BoardFieldSetting = (window as any).eumBoardIds as common.BoardFieldSetting;

@Component({
    components: {
        AttachFileField,
        CommentBox,
        RecommendBox,
        TagField,
        PasswordCheck,
        LoadingBar,
        ArticleHistoryList,
        EumEditor
    },

    computed: mapGetters({
        user: accountTypes.USER,
        current: accountTypes.CURRENT,
        popupBaseUrl: types.POPUP_BASEURL
    }),

    methods: mapActions({
        fetchReadConfig: types.FETCH_READCONFIG,
        readArticle: types.READ_ARTICLE,
        createViewLog: types.CREATE_VIEWLOG,
        createRecommend: types.CREATE_RECOMMEND,            
        deleteRecommend: types.DELETE_RECOMMEND,
        deleteArticle: types.DELETE_ARTICLE
    })
})
export default class BoardRead  extends Vue {
    @Prop({ type: Boolean, default: false }) isModal!: boolean;
    @Prop({ type: Object }) webpartParam!: any | undefined;

    fetchReadConfig!: (boardId: number) => Promise<board.ReadConfig>;
    readArticle!: (articleSet: article.ArticleFetchSet) => Promise<article.ArticleDetail>;
    createViewLog!: (set: article.CreateOptionSet) => Promise<number>;
    createRecommend!: (set: article.CreateOptionSet) => Promise<number>;    
    deleteRecommend!: (set: article.DeleteRecommendSet) => Promise<number>;
    deleteArticle!: (set: article.DeleteArticle) => Promise<number>;

    user!: account.User;
    current!: account.TCurrent;
    popupBaseUrl!: string;

    fieldLoaded: boolean = false;
    photoLoaded: boolean = false;

    currentBoard: number = 0;
    currentArticle: number = 0;    
    currentLookahead: string | null = null;
    readConfig: board.ReadConfig | null = null;
    contentFields: board.ContentField[] = [];
    componentValues: (Object | null)[] = [];
    currentCheckingFor: 'edit' | 'delete' = 'edit';

    mBoardName: string = "";
    mUserPhoto: string = "";
    mArticle: article.ArticleDetail | null = null;
    mViewCount: number = 0;
    mCommentCount: number = 0;
    mRecommendCount: number = 0;
    mHasRecommended: boolean = false;
    mFileCode: string | null = null;    
    mCreatedDate: string = "";
    mCreatedTime: string = "";
    mTags: string = "";        
    mShowPasswordCheck: boolean = false;

    mShowComment: boolean = true;
    mShowRecommend: boolean = false;
    mUseReply: boolean = false;
    mUseComment: boolean = false;
    mUseRecommend: boolean = false;
    mPrintMode: boolean = false;
    mUseTag: boolean = false;
    mUseNameless: boolean = false;
    mUseArticleHistory: boolean = false;
    mUseContent: boolean = false;
    mUseAnonComment: boolean = false;
    mUseAttachmentLog: boolean = false;

    currentArticleVersion: number = 0;
    showArticleHistory: boolean = false;
    articleHistoryList: article.ArticleHistoryItem[] = [];
    systemAuth: article.SystemAuth[] = [];
    canUpdate: boolean = false;
    canDelete: boolean = false;
    isWebpartRender: boolean = false;
    isAdmin: boolean = false

    // 지누스 익명게시판 커스텀
    isSparkBoard: boolean = false;

    tinyOptions: any = {
        content_style: `
                    p { font-family: dotum; }
                    body {font-family: dotum; font-size: 10pt;}
                    body#tinymce p {
                        font-family: dotum; 
                        margin-top: 0.3em;
                        margin-bottom: 0.3em;
                    }`,     
        height: 500,
    };

    dext5Options: editor.dext5.DEXT5Config = {
        Mode: 'view',
        EditorBorder: '0',
        ViewModeAutoHeight: '1',
        // Width: '100%',
        // Height: '100%'
        Lang: 'ko_KR'
    }

    created() {
        ids = (window as any).eumBoardIds;

        if(this.webpartParam) {
          
            this.currentArticle = this.webpartParam.articleId;
            this.currentBoard = this.webpartParam.boardId;
            this.isWebpartRender = true;
        } else {
            var { SYSID, LOOKAHEAD, ARTICLEID, TEAMSPRINT } = this.$route.query;

            if (SYSID) this.currentBoard = Number(SYSID);
            if (ARTICLEID) this.currentArticle = Number(ARTICLEID);
            if (LOOKAHEAD) this.currentLookahead = LOOKAHEAD.toString();
            if (TEAMSPRINT && TEAMSPRINT === 'true') this.mPrintMode = true;
        }
       
        this.dext5Options.Lang = this.current.userConfig.language
        this.isAdmin = (this.current.permissions.isSuperAdmin || this.current.permissions.isCompanyManager) ? true : false        

        // 20230705 익명 게시판(SPARK) 게시판 여부
        // var appConfigBoardId = this.getAppConfig('Z_Board_SPARK_ID') 
        // if (appConfigBoardId && this.currentBoard && this.currentBoard == Number(appConfigBoardId)){
        //     this.isSparkBoard = true;
        // }

        // 20230816 익명 게시판 로직 수정 : Custom 익명 게시판인 경우에는 추천수 목록이 표시되지 않도록 수정
        var customNamelessBoardList = this.getAppConfig('Z_Board_CustomNameless_List');
        if (customNamelessBoardList){
            var jsonData = JSON.parse(customNamelessBoardList);
            var jObj: Array<any> = (jsonData && jsonData["NamelessBoardList"] && jsonData["NamelessBoardList"].length > 0 ? jsonData["NamelessBoardList"] : null);
            if (jObj){
                var namelessBoardInfo = jObj.find(x =>  x.BoardId == this.currentBoard.toString());
                if (namelessBoardInfo){
                    this.isSparkBoard = true;
                }
            }
        }

        this.mountAction();
    }

    mountAction() {
        var mountPromise: Promise<any>[] = [];
        var lookaheadArticle: article.ArticleDetail;

        mountPromise.push(api.board.getReadConfig(this.currentBoard));
        mountPromise.push(api.article.getSystemAuth(this.currentBoard));

        if (this.currentLookahead == null) {
            if (this.currentArticleVersion == 0) {
                mountPromise.push(this.readArticle({ boardId: this.currentBoard, articleId: this.currentArticle }));
            }
            else {
                mountPromise.push(this.articleHistoryDetail());
            }
        } else {
            var mLookahead = localStorageHelper.get(`$board/${this.currentLookahead}/lookahead`);
            localStorageHelper.set(`$board/${this.currentLookahead}/lookahead`, null);

            lookaheadArticle = JSON.parse(mLookahead) as article.ArticleDetail;

            if (lookaheadArticle.printMode) {
                this.mPrintMode = lookaheadArticle.printMode;
            }

            this.mArticle = lookaheadArticle;
            this.mFileCode = lookaheadArticle.fileCode;
        }

        Promise.all(mountPromise)
            .then(res => {
                this.readConfig = res[0] as board.ReadConfig;
                this.systemAuth = res[1];
                var articleModel = this.currentLookahead == null ?
                    res[2] as article.ArticleDetail :
                    lookaheadArticle;

                this.getUserPhoto(articleModel.createdUser);
                this.mBoardName = this.readConfig.boardName;
                this.mUseComment = this.readConfig.useComment;
                this.mUseRecommend = this.readConfig.useRecommend;
                this.mUseReply = this.readConfig.useReply;
                this.mUseTag = this.readConfig.useTag;
                this.mUseNameless = this.readConfig.useNameless;
                this.mUseArticleHistory = this.readConfig.useArticleHistory;
                this.mUseContent = this.readConfig.useContent;
                this.mUseAnonComment = this.readConfig.useAnonComment;
                this.mUseAttachmentLog = this.readConfig.useAttachmentLog;

                this.contentFields = this.readConfig.contentFields.filter(f => ids.contentFieldFilter(f));
                this.loadArticleDetail(articleModel);
            });
    }

    getUserPhoto(personCode: string) {       
        if(personCode === 'MIG') {
            this.mUserPhoto = '/cm/gres/images/ghost.png';
            this.photoLoaded = true;
        } else {
            cache.avatar.get(personCode)
                .then(url => {
                    if(url && url != null) {
                        this.mUserPhoto = url;
                    } else {
                        this.mUserPhoto = '/cm/gres/images/ghost.png';
                    }
                    
                    this.photoLoaded = true;
                });
        }                
    }

    getFieldComponent(field: board.ContentField) {
        switch (field.fieldType) {
            case "TEXT": return TextField;
            case "TEXTAREA": return TextAreaField;
            case "DATETIME": return DatetimeField;
            case "SELECT": return SelectField;
            case "TIMESPAN": return TimeSpanField;
            case "MAILSEND": return MailSendField;
            case 'SELECTUSER': return SelectUserField;
        }
    }

    loadArticleDetail(article: article.ArticleDetail) {
        this.mArticle = article;
        this.canUpdate = article.createdUser === this.user.personCode ||
            this.systemAuth.findIndex(a => a.update == true || a.isMenuAdmin) >= 0 ||
            this.current.permissions.isSuperAdmin;
        this.canDelete = article.createdUser === this.user.personCode ||
            this.systemAuth.findIndex(a => a.delete == true || a.isMenuAdmin) >= 0 ||
            this.current.permissions.isSuperAdmin;
        this.mViewCount = article.viewCount;
        this.mCommentCount = article.commentCount;
        this.mRecommendCount = article.recommendCount;
        this.mHasRecommended = article.recommendedDate != null;
        this.mFileCode = article.fileCode;
        this.mTags = article.tags;
        this.mCreatedDate = uGetDateFormat('yyyy-MM-dd', IEDate(article.createdDate));
        this.mCreatedTime = uGetDateFormat('H:i:s', IEDate(article.createdDate));
        this.fillComponentValues(article);
        this.fieldLoaded = true;
        this.$emit('articleLoaded', this.readConfig, this.mArticle);

        if (this.mPrintMode) {
            this.$nextTick(() => {
                window.print();
                setTimeout(function () { window?.close(); }, 1);
            });
        } else if (this.currentLookahead == null) {
            this.createViewLog({
                articleId: this.currentArticle,
                userCode: this.user.personCode,
                userName: this.user.displayName,
                userName_En: this.user.displayDeptNameEn,
                userMail: this.user.email,
                userDept: this.user.deptCode,
                userPhone: this.user.officeTel,
                deptName: this.user.deptName,
                deptName_En: this.user.deptNameEn,
                userJobTitle: this.user.titleName,
                userJobTitle_En: this.user.titleNameEn
            })
        }
    }

    fillComponentValues(article: article.ArticleDetail) {
        var passVal: Object | undefined | null = null;
        this.contentFields.forEach(c => {
            var find = article.fieldValues.find(f => f.fieldId == c.fieldId);
            passVal = find?.value;

            if (passVal) {
                var type = (typeof passVal).toString();
                passVal = type !== 'object' ? passVal.toString() : passVal;
                this.componentValues.push(passVal);
            } else {
                this.componentValues.push(null);
            }
        });
    }

    toggleShowComment() {
        this.mShowComment = !this.mShowComment;

        if (this.mShowComment) {
            this.mShowRecommend = false;
        }
    }

    toggleRecommend() {
        // 익명게시판의 경우 추천 사용자 목록을 표시하지 않는다.
        var showRecommend = true;

        if (this.checkCustomBoard()){
            showRecommend = false;
        }

        if (showRecommend){
            this.mShowRecommend = !this.mShowRecommend;

            if (this.mShowRecommend) {
                this.mShowComment = false;
            }
        }
    }

    recommendArticle() {
        var userset = <article.CreateOptionSet>{
            articleId: this.currentArticle,
            deptName: this.user.deptName,
            deptName_En: this.user.deptNameEn,
            userCode: this.user.personCode,
            userJobTitle: this.user.titleName,
            userJobTitle_En: this.user.titleNameEn,
            userName: this.user.displayName,
            userName_En: this.user.displayDeptNameEn
        }

        var recommendPromise = this.mHasRecommended ?
            this.deleteRecommend(userset) :
            this.createRecommend(userset);

        recommendPromise.then(res => { this.mHasRecommended = !this.mHasRecommended; });
    }

    onCommentMounted(comments: article.ArticleComment[], count: number) {
        this.mCommentCount = count;
    }

    onRecommendMounted(recommends: article.RecommendSet[], count: number) {
        this.mRecommendCount = count;
    }

    onPasswordCheckConfirm(password: string) {
        if (this.currentCheckingFor === 'edit') {
            var encrypt = aes.aesEncrypt(password);
            localStorageHelper.set(`eumboard-nameless-article-${this.currentArticle}`, encrypt);

            this.handleEdit();
        } else if (this.currentCheckingFor === 'delete') {
            this.handleDelete();
        }
    }

    onPasswordCheckCancel() {
        this.mShowPasswordCheck = false;
    }

    onEditClick() {
        if(this.mUseNameless) {
            // Custom 게시판 여부 체크
            if (this.checkCustomBoard()) {
                // Custom 게시판인 경우 관리자 여부 체크
                // 관리자인 경우 바로 수정, 관리자가 아닌 경우 비밀번호 입력 
                api.board.getCustomBoardManagerCheck(this.currentBoard, this.user.personCode).then((res) => {
                    if (res){
                        this.handleEdit();
                    }
                    else {
                        this.currentCheckingFor = 'edit';
                        this.mShowPasswordCheck = true;                     
                    }
                });
            }
            else {
                this.currentCheckingFor = 'edit';
                this.mShowPasswordCheck = true;
            }
        } 
        else {
            this.handleEdit();
        }               
    }

    onDeleteClick() {
        if(this.mUseNameless) {
            // Custom 게시판 여부 체크
            if (this.checkCustomBoard()){
                // Custom 게시판인 경우 관리자 여부 체크
                // 관리자인 경우 바로 삭제, 관리자가 아닌 경우 비밀번호 입력
                api.board.getCustomBoardManagerCheck(this.currentBoard, this.user.personCode).then((res) => {
                    if (res){
                        this.handleDelete();
                    }
                    else {
                        this.currentCheckingFor = 'delete';
                        this.mShowPasswordCheck = true;                        
                    }
                });
            }
            else {
                this.currentCheckingFor = 'delete';
                this.mShowPasswordCheck = true;
            }
        } 
        else {
            this.handleDelete();
        }        
    }

    handleEdit() {
        open(OpenType.ModalEdit, {
            boardId: this.currentBoard,
            articleId: this.currentArticle,
            baseUrl: this.popupBaseUrl
        })
    }

    async handleDelete() {
        let deleteMessage: string = this.$t('M_BB_DeleteMessage') as string;
        var returnMessage = await(window as any).confirmAsync(deleteMessage);

        if(returnMessage) {
            this.deleteArticle(<article.DeleteArticle>{
                articleId: this.currentArticle,
                deleteUser: {
                    userCode: this.user.personCode,
                    userName: this.user.displayName,
                    userName_En: this.user.displayDeptNameEn,
                    deptName: this.user.titleName,
                    deptName_En: this.user.titleNameEn,
                    userJobTitle: this.user.deptName,
                    userJobTitle_En: this.user.deptNameEn
                }
            }).then(res => {
                var eventTarget = this.isModal ? window : window.opener;
                dispatchEventBus(eventTarget, 'boardlist-reload', '');
                this.onCloseClick();
            })
        }
    }

    // 지누스 익명게시판 여부 체크
    checkCustomBoard(){
        var isCustomBoard: boolean = false;
        var customBoardList = this.getAppConfig("Z_Board_CustomNameless_List");
        
        if (customBoardList){
            var jsonData = JSON.parse(customBoardList);
            var jObj: Array<any> = (jsonData && jsonData["NamelessBoardList"] && jsonData["NamelessBoardList"].length > 0 ? jsonData["NamelessBoardList"] : null);
            var customBoardInfo = jObj.find(x => x.BoardId == this.currentBoard.toString());
            if (customBoardInfo){
                isCustomBoard = true;
            }
        }

        return isCustomBoard;
    }

    onReplyClick() {
        if(this.readConfig == null)
            return;

        var getReplyHeadRawHtml = function(article: article.ArticleDetail | null) {
            if(article == null)
                return "";

            var returnHtml = `
            <p>&nbsp;</p>
            <hr style="width: 98%; display: inline-block;" tabindex="-1" />
            <div id="divRplyFwdMsg" dir="ltr">    
                <span style="font-size: 10pt; color: #000000; font-family: dotum;"
                    data-mce-style="font-size: 10pt; color: #000000; font-family: dotum;">`;
                    returnHtml += `
                    <strong>작성자:</strong> ${article.createdUserName} / ${article.createdUserJobTitle} / ${article.createdDeptName}<br>
                    <strong>작성일:</strong> ${article.createdDate} <br>`
                    returnHtml += `
                    <strong>제목:</strong> ${article.title}</span>
                <div>&nbsp;</div>
            </div>`;
            
            return returnHtml;
        }

        var dispContent = `${getReplyHeadRawHtml(this.mArticle)}${this.mArticle?.content}`;

        localStorageHelper.set(`$board/${this.currentArticle}/title`, this.mArticle?.title);
        localStorageHelper.set(`$board/${this.currentArticle}/preface`, this.mArticle?.prefaceId);
        localStorageHelper.set(`$board/${this.currentArticle}/content`, dispContent);         
        
        open(!this.isTeams && this.readConfig.usePopup ? 
            OpenType.PopupReply : OpenType.ModalReply, 
            {
                boardId: this.readConfig.boardId,
                parentArticle: this.currentArticle,
                baseUrl: this.popupBaseUrl
            });
    }

    onPrintClick() {
        if(this.mArticle != null) {        
            if(this.isTeams) {                
                var printFrame = this.$refs.iPrintFrame as HTMLIFrameElement;
                printFrame.src = `${window.location.origin}${this.popupBaseUrl}readPopup?SYSID=${this.currentBoard}&ARTICLEID=${this.mArticle.articleId}&TEAMSPRINT=true`;
            } else {
                var lookaheadId = getGUID(false);
                var mlookahead: article.ArticleDetail = {
                    ...this.mArticle,
                    printMode: true
                }
        
                var strArticle = JSON.stringify(mlookahead);
                localStorageHelper.set(`$board/${lookaheadId}/lookahead`, strArticle);

                window.open(
                    `${this.popupBaseUrl}readPopup?SYSID=${this.currentBoard}&LOOKAHEAD=${lookaheadId}`,
                    "_blank",
                    "width=900, height=760, resuzable=true");
            }            
        }
    }

    onCloseClick() {
        if(this.isModal) {
            this.onCloseModal();
        } else {
            window.close();
        }        
    }

    onCloseModal() {
        var closePath = this.$route.path.replace('/modal/read', '');
        this.$router.push({
            path: closePath,
            query: {
                SYSID: this.currentBoard.toString()
            }
        });
    }

    onHistoryClick(){
        api.article.getArticleHistoryList(this.currentArticle).then((res) =>{
            if (res){
                this.articleHistoryList = res;
                this.showArticleHistory = true;
            }
        });
    }

    onCloseHistory(){
        this.articleHistoryList = [];
        this.showArticleHistory = false;
    }

    onHistoryItemClick(articleVersion: number, fileCode: string){
        this.currentArticleVersion = articleVersion;
        this.mFileCode = fileCode;
        this.onCloseHistory();
        this.mountAction();
    }

    articleHistoryDetail(): Promise<article.ArticleDetail>{
       return api.article.getArticleDetailHistory(this.currentBoard, this.currentArticle, this.currentArticleVersion);
    }
    
    //게시판명 다국어 처리
    //상단 헤더 표시되는 게시판명
    getBoardName(boardName: string) {
        let boardId = this.$route.query.SYSID as string
        let menu = menuHelper.findOneBySysId(parseInt(boardId), 'BB')
        if (menu) {
            return this.$t(menu.menuName)
        }
    }
}