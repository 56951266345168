import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
import TreeList from '@gnb/components/Common/TreeList.vue';
import FolderItem from '@gnb/components/Common/FolderItem.vue';

@Component({
    components: { TreeList, FolderItem },
})
export default class FolderList extends Vue {    
    //
    // ────────────────────────────────────────────────────────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    @Prop({ type: Array }) items!: orgmap.TreeListItem[];
    folderId:string = '';

   
    // 메뉴 Item 클릭 이벤트 처리
    fnFolderItemClick(folderItem: orgmap.TreeListItem) {
        if (folderItem.id){
            this.folderId = folderItem.id.toString();
            this.$emit('select', folderItem)
        }
    }

    // 도구 및 기능 Expand 이벤트 처리
    fnExpandTree(itemId: Number, treeType: String) {
        var iObj = document.getElementById((treeType == "M" ? "iMn_" : "iFunc_") + itemId);
        var nodeObj = document.getElementById((treeType == "M" ? "divNodeMn_" : "divNodeFunc_") + itemId);
        var mSubObj = nodeObj?.querySelector(".m-sub");

        if (iObj?.classList.contains("icon-fux-down")) {
            iObj?.classList.remove("icon-fux-down");
            iObj?.classList.add("icon-fux-right");
            mSubObj?.setAttribute("style", "display:none");
        }
        else {
            iObj?.classList.remove("icon-fux-right");
            iObj?.classList.add("icon-fux-down");
            mSubObj?.setAttribute("style", "display:");
        }
    }
}
