import { FlowEventNames } from '.'
import { FlowChunk } from './FlowChunk'
import { FlowFile } from './FlowFile'
import { FlowOptions } from './FlowOptions'

// ie10+
const ie10plus = (window.navigator as any).msPointerEnabled

/**
 * Default read function using the webAPI
 *
 * @function webAPIFileRead(fileObj, startByte, endByte, fileType, chunk)
 * @param fileType MIME type(now properly called "media type", but also sometimes "content type")
 *                 is a string sent along with a file indicating the type of the file
 *                 (describing the content format, for example, a sound file might be labeled audio/ogg,
 *                 or an image file image/png).
 *                 https://developer.mozilla.org/en-US/docs/Glossary/MIME_type
 */
function webAPIFileRead(
  fileObj: FlowFile,
  startByte: number,
  endByte: number,
  fileType: string,
  chunk: FlowChunk
) {
  var function_name = 'slice'

  if (fileObj.file.slice) function_name = 'slice'
  else if (fileObj.file.mozSlice) function_name = 'mozSlice'
  else if (fileObj.file.webkitSlice) function_name = 'webkitSlice'

  chunk.readFinished(fileObj.file[function_name](startByte, endByte, fileType))
}

const initialOptions: FlowOptions = {
  chunkSize: 1024 * 1024,
  forceChunkSize: false,
  simultaneousUploads: 3,
  singleFile: false,
  fileParameterName: 'file',
  progressCallbacksInterval: 500,
  speedSmoothingFactor: 0.1,
  query: {},
  headers: {},
  withCredentials: false,
  preprocess: null,
  changeRawDataBeforeSend: null,
  method: 'multipart',
  testMethod: 'GET',
  uploadMethod: 'POST',
  prioritizeFirstAndLastChunk: false,
  allowDuplicateUploads: false,
  target: '/',
  testChunks: true,
  generateUniqueIdentifier: null,
  maxChunkRetries: 0,
  chunkRetryInterval: null,
  permanentErrors: [404, 413, 415, 500, 501],
  successStatuses: [200, 201, 202],
  onDropStopPropagation: false,
  initFileFn: null,
  readFileFn: webAPIFileRead,
}

/**
 * Flow.js is a library providing multiple simultaneous, stable and
 * resumable uploads via the HTML5 File API.
 * @param [opts]
 * @constructor
 * @example
 * A new Flow object is created with information of what and where to post:
 *    var flow = new Flow({
 *      target:'/api/photo/redeem-upload-token',
 *      query:{upload_token:'my_token'}
 *    });
 *    // Flow.js isn't supported, fall back on a different method
 *    if(!flow.support) location.href = '/some-old-crappy-uploader';
 *
 * To allow files to be either selected and drag-dropped, you'll assign drop target and a DOM item to be clicked for browsing:
 *    flow.assignBrowse(document.getElementById('browseButton'));
 *    flow.assignDrop(document.getElementById('dropTarget'));
 *
 * After this, interaction with Flow.js is done by listening to events:
 *    flow.on('fileAdded', function(file, event){
 *      console.log(file, event);
 *    });
 *    flow.on('fileSuccess', function(file,message){
 *      console.log(file,message);
 *    });
 *    flow.on('fileError', function(file, message){
 *      console.log(file, message);
 *    });
 */
export class Flow {
  //
  // ──────────────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P E R T I E S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────
  //
  /**
   * Supported by browser?
   * A boolean value indicator whether or not Flow.js is supported by the current browser.
   * @type {boolean}
   */
  support!: boolean

  /**
   * Check if directory upload is supported
   * A boolean value, which indicates if browser supports directory uploads.
   * @type {boolean}
   */
  supportDirectory!: boolean

  /**
   * List of FlowFile objects
   * An array of FlowFile file objects added by the user (see full docs for this object type below).
   * @type {Array.<FlowFile>}
   */
  files!: FlowFile[]

  /**
   * Default options for flow.js
   * @type {Object}
   */
  defaults: FlowOptions = initialOptions

  /**
   * Current options
   * A hash object of the configuration of the Flow.js instance.
   * @type {Object}
   */
  opts: FlowOptions = Object.create(null)

  /**
   * List of events:
   *  key stands for event name
   *  value array list of callbacks
   * @type {}
   */
  events: { [key: string]: any } = {}

  //
  // ────────────────────────────────────────────────────────────── I ──────────
  //   :::::: C O N S T R U C T O R : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────────
  //

  constructor(opts: FlowOptions, files: FlowFile[] = []) {
    console.log('initialize flow with', opts)
    this.support =
      typeof File !== 'undefined' &&
      typeof Blob !== 'undefined' &&
      typeof FileList !== 'undefined' &&
      (!!Blob.prototype.slice || !!Blob.prototype.webkitSlice || !!Blob.prototype.mozSlice || false) // slicing files support
    if (!this.support) {
      return
    }
    this.supportDirectory =
      /Chrome/.test(window.navigator.userAgent) ||
      /Firefox/.test(window.navigator.userAgent) ||
      /Edge/.test(window.navigator.userAgent)
    this.opts = Flow.extend({}, this.defaults, opts || Object.create(null))

    // assign event handlers
    const eventHandlerContext = this.opts.on as any
    FlowEventNames.forEach((event: string) => {
      if (!eventHandlerContext[event]) return
      this.on(event, eventHandlerContext[event].bind(eventHandlerContext))
    })
    this.files = files
  }

  //
  // ────────────────────────────────────────────────────────────────────── I ──────────
  //   :::::: P R I V A T E   M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────────────────
  //

  /**
   * On drop event
   * @function
   * @param {MouseEvent} event
   */
  private onDrop(event: any) {
    // console.log('flow', 'onDrop', this.opts)
    if (this.opts.onDropStopPropagation) {
      event.stopPropagation()
    }
    event.preventDefault()
    var dataTransfer = event.dataTransfer
    if (dataTransfer.items && dataTransfer.items[0] && dataTransfer.items[0].webkitGetAsEntry) {
      this.webkitReadDataTransfer(event)
    } else {
      this.addFiles(dataTransfer.files, event)
    }
  }

  /**
   * Prevent default
   * @function
   * @param {MouseEvent} event
   */
  private preventEvent(event: any) {
    // console.log('flow', 'preventEvent', event)
    event.preventDefault()
  }

  //
  // ──────────────────────────────────────────────────────────────────── I ──────────
  //   :::::: P U B L I C   M E T H O D S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────────────
  //

  /**
   * Set a callback for an event, possible events:
   *  - fileSuccess(file, message, chunk) A specific file was completed. First argument file is instance of FlowFile, second argument message contains server response. Response is always a string. Third argument chunk is instance of FlowChunk. You can get response status by accessing xhr object chunk.xhr.status.
   *  - fileProgress(file, chunk) Uploading progressed for a specific file.
   *  - fileAdded(file, event) This event is used for file validation. To reject this file return false. This event is also called before file is added to upload queue, this means that calling flow.upload() function will not start current file upload. Optionally, you can use the browser event object from when the file was added.
   *  - filesAdded(array, event) Same as fileAdded, but used for multiple file validation.
   *  - filesSubmitted(array, event) Same as filesAdded, but happens after the file is added to upload queue. Can be used to start upload of currently added files.
   *  - fileRemoved(file) The specific file was removed from the upload queue. Combined with filesSubmitted, can be used to notify UI to update its state to match the upload queue.
   *  - fileRetry(file, chunk) Something went wrong during upload of a specific file, uploading is being retried.
   *  - fileError(file, message, chunk) An error occurred during upload of a specific file.
   *  - uploadStart() Upload has been started on the Flow object.
   *  - complete() Uploading completed.
   *  - progress() Uploading progress.
   *  - error(message, file, chunk) An error, including fileError, occurred.
   *  - catchAll(event, ...) Listen to all the events listed above with the same callback function.
   * @function
   * @param {string} event
   * @param {Function} callback
   */
  on(event: string, callback: Function) {
    event = event.toLowerCase()
    if (!this.events.hasOwnProperty(event)) {
      this.events[event] = []
    }
    this.events[event].push(callback)
  }

  /**
   * Remove event callback
   * @function
   * @param {string} [event] removes all events if not specified
   * @param {Function} [fn] removes all callbacks of event if not specified
   */
  off(event: string, fn: Function) {
    if (event !== undefined) {
      event = event.toLowerCase()
      if (fn !== undefined) {
        if (this.events.hasOwnProperty(event)) {
          Flow.arrayRemove(this.events[event], fn)
        }
      } else {
        delete this.events[event]
      }
    } else {
      this.events = {}
    }
  }

  /**
   * Fire an event
   * @function
   * @param {string} event event name
   * @param {...} args arguments of a callback
   * @return {bool} value is false if at least one of the event handlers which handled this event
   * returned false. Otherwise it returns true.
   */
  fire(event: any, ...args: any[]): boolean {
    // `arguments` is an object, not array, in FF, so:
    args = Array.prototype.slice.call(arguments)
    event = event.toLowerCase()
    var preventDefault = false
    if (this.events.hasOwnProperty(event)) {
      Flow.each(
        this.events[event],
        (callback: Function) => {
          preventDefault = callback.apply(this, args.slice(1)) === false || preventDefault
        },
        this
      )
    }
    if (event != 'catchall') {
      args.unshift('catchAll')
      preventDefault = this.fire.apply(this, args as any) === false || preventDefault
    }
    return !preventDefault
  }

  /**
   * Read webkit dataTransfer object
   * @param event
   */
  webkitReadDataTransfer(event: any) {
    var $ = this
    var queue = event.dataTransfer.items.length
    var files: File[] = []

    /**
     * retrieves the directory entries within the directory being read and delivers them in an array.
     * @param {FileSystemDirectoryReader} reader
     */
    const readDirectory = (reader: any) => {
      const successCallback = (entries: any[]) => {
        if (entries.length) {
          queue += entries.length
          Flow.each(entries, (entry: any) => {
            if (entry.isFile) {
              var fullPath = entry.fullPath
              entry.file((file: File) => {
                fileReadSuccess(file, fullPath)
              }, readError)
            } else if (entry.isDirectory) {
              readDirectory(entry.createReader())
            }
          })
          readDirectory(reader)
        } else {
          decrement()
        }
      }
      reader.readEntries(successCallback, readError)
    }

    const fileReadSuccess = (file: File | null, fullPath: string) => {
      if (!file) throw new Error('ARG_NULL_ERROR : file')
      // relative path should not start with "/"
      file.relativePath = fullPath.substring(1)
      files.push(file)
      decrement()
    }

    const readError = (fileError: any) => {
      decrement()
      throw fileError
    }

    const decrement = () => {
      if (--queue == 0) {
        this.addFiles(files, event)
      }
    }

    Flow.each(event.dataTransfer.items, (item: DataTransferItem) => {
      var entry = item.webkitGetAsEntry()
      if (!entry) {
        decrement()
        return
      }

      if (entry.isFile) {
        // due to a bug in Chrome's File System API impl - #149735
        fileReadSuccess(item.getAsFile(), entry.fullPath)
      } else {
        readDirectory(entry.createReader())
      }
    })
  }

  /**
   * Generate unique identifier for a file
   * @function
   * @param {FlowFile} file
   * @returns {string}
   */
  generateUniqueIdentifier(file: File) {
    var custom = this.opts.generateUniqueIdentifier
    if (typeof custom === 'function') {
      return custom(file)
    }
    const now = new Date()

    // Some confusion in different versions of Firefox
    var relativePath: string =
      file.relativePath || file.webkitRelativePath || file.fileName || file.name
    // return file.size + '-' + relativePath.replace(/[^0-9a-zA-Z_-]/gim, '')
    return (
      now.getTime() +
      '-' +
      file.size +
      '-' +
      relativePath.replace(/[^0-9a-zA-Zㄱ-ㅎ|ㅏ-ㅣ|가-힣_-]/gim, '')
    )
  }

  /**
   * Upload next chunk from the queue
   * @function
   * @returns {boolean}
   * @private
   */
  uploadNextChunk(preventEvents?: any): boolean {
    // In some cases (such as videos) it's really handy to upload the first
    // and last chunk of a file quickly; this let's the server check the file's
    // metadata and determine if there's even a point in continuing.
    var found = false
    if (this.opts.prioritizeFirstAndLastChunk) {
      Flow.each(this.files, (file: FlowFile) => {
        if (!file.paused && file.chunks.length && file.chunks[0].status() === 'pending') {
          file.chunks[0].send()
          found = true
          return false
        }
        if (
          !file.paused &&
          file.chunks.length > 1 &&
          file.chunks[file.chunks.length - 1].status() === 'pending'
        ) {
          file.chunks[file.chunks.length - 1].send()
          found = true
          return false
        }
      })
      if (found) {
        return found
      }
    }

    // Now, simply look for the next, best thing to upload
    Flow.each(this.files, (file: FlowFile) => {
      if (!file.paused) {
        Flow.each(file.chunks, (chunk: FlowChunk) => {
          if (chunk.status() === 'pending') {
            chunk.send()
            found = true
            return false
          }
        })
      }
      if (found) {
        return false
      }
    })
    if (found) {
      return true
    }

    // The are no more outstanding chunks to upload, check is everything is done
    var outstanding = false
    Flow.each(this.files, (file: FlowFile) => {
      if (!file.isComplete()) {
        outstanding = true
        return false
      }
    })
    if (!outstanding && !preventEvents) {
      // All chunks have been uploaded, complete
      Flow.async(() => {
        this.fire('complete')
      }, this)
    }
    return false
  }

  /**
   * 선택된 파일 삭제 액션을 하나 또는 이상의 DOM 노드에 할당합니다.
   * @param {Element|Array.<Element>} domNodes
   */
  assignDeleteSelectedFiles(domNodes: Element[] | Element, msgNosel: string) {
    if (domNodes instanceof Element) {
      domNodes = [domNodes]
    }

    Flow.each(
      domNodes,
      (domNode: HTMLInputElement) => {
        domNode.addEventListener('click', async (ev) => {
          const files = this.files.filter((x) => x.selected)
          if (files.length > 0) {
            for (var file of files) {
              this.removeFile(file)
            }
          } else {
            await (window as any).alertAsync(msgNosel)
          }
        })
      },
      this
    )
  }

  /**
   * Assign a browse action to one or more DOM nodes.
   * Note: avoid using a and button tags as file upload buttons, use span instead.
   * @function
   * @param {Element|Array.<Element>} domNodes
   * @param {boolean} isDirectory Pass in true to allow directories to
   * @param {boolean} singleFile prevent multi file upload
   * @param {Object} attributes set custom attributes:
   *  http://www.w3.org/TR/html-markup/input.file.html#input.file-attributes
   *  eg: accept: 'image/*'
   * be selected (Chrome only).
   */
  assignBrowse(
    domNodes: Element[] | Element,
    isDirectory?: boolean,
    singleFile?: boolean,
    attributes?: Object
  ) {
    if (domNodes instanceof Element) {
      domNodes = [domNodes]
    }

    Flow.each(
      domNodes,
      (domNode: HTMLInputElement) => {
        var input: HTMLInputElement
        if (domNode.tagName === 'INPUT' && domNode.type === 'file') {
          input = domNode
        } else {
          input = document.createElement('input')
          input.setAttribute('type', 'file')
          // display:none - not working in opera 12
          Flow.extend(input.style, {
            visibility: 'hidden',
            position: 'absolute',
            width: '1px',
            height: '1px',
          })
          // for opera 12 browser, input must be assigned to a document
          domNode.appendChild(input)
          // https://developer.mozilla.org/en/using_files_from_web_applications)
          // event listener is executed two times
          // first one - original mouse click event
          // second - input.click(), input is inside domNode
          domNode.addEventListener(
            'click',
            () => {
              input.click()
            },
            false
          )
        }
        if (!this.opts.singleFile && !singleFile) {
          input.setAttribute('multiple', 'multiple')
        }
        if (isDirectory) {
          input.setAttribute('webkitdirectory', 'webkitdirectory')
        }
        Flow.each(attributes, (value: string, key: string) => {
          input.setAttribute(key, value)
        })
        // When new files are added, simply append them to the overall list
        var $ = this
        input.addEventListener(
          'change',
          (e: any) => {
            if (e.target?.value) {
              this.addFiles([...e.target.files], e)
              e.target.value = ''
            }
          },
          false
        )
      },
      this
    )
  }

  /**
   * Assign one or more DOM nodes as a drop target.
   * @function
   * @param {Element|Array.<Element>} domNodes
   */
  assignDrop(domNodes: Element | Element[]) {
    let nodes: Element[]
    if (typeof (domNodes as Element[]).length === 'undefined') {
      nodes = [domNodes as Element]
    } else {
      nodes = domNodes as Element[]
    }
    nodes.forEach((domNode) => {
      domNode.addEventListener('dragover', this.preventEvent, false)
      domNode.addEventListener('dragenter', this.preventEvent, false)
      domNode.addEventListener('drop', this.onDrop.bind(this), false)
    })
  }

  /**
   * Un-assign drop event from DOM nodes
   * @function
   * @param domNodes
   */
  unAssignDrop(domNodes: Element | Element[]) {
    let nodes: Element[]
    if (typeof (domNodes as Element[]).length === 'undefined') {
      nodes = [domNodes as Element]
    } else {
      nodes = domNodes as Element[]
    }
    Flow.each(
      nodes,
      (domNode: Element) => {
        domNode.removeEventListener('dragover', this.preventEvent)
        domNode.removeEventListener('dragenter', this.preventEvent)
        domNode.removeEventListener('drop', this.onDrop)
      },
      this
    )
  }

  /**
   * Returns a boolean indicating whether or not the instance is currently
   * uploading anything.
   * @function
   * @returns {boolean}
   */
  isUploading() {
    var uploading = false
    Flow.each(this.files, (file: FlowFile) => {
      if (file.isUploading()) {
        uploading = true
        return false
      }
    })
    return uploading
  }

  /**
   * should upload next chunk
   * @function
   * @returns {boolean|number}
   */
  _shouldUploadNext(): false | number {
    var num = 0
    var should = true
    var simultaneousUploads = this.opts.simultaneousUploads
    Flow.each(this.files, (file: FlowFile) => {
      Flow.each(file.chunks, (chunk: FlowChunk) => {
        if (chunk.status() === 'uploading') {
          num++
          if (num >= simultaneousUploads) {
            should = false
            return false
          }
        }
      })
    })
    // if should is true then return uploading chunks's length
    return should && num
  }

  /**
   * Start or resume uploading.
   * @function
   */
  upload() {
    // Make sure we don't start too many uploads at once
    var ret = this._shouldUploadNext()
    if (ret === false) {
      return
    }
    // Kick off the queue
    this.fire('uploadStart')
    var started = false
    for (var num = 1; num <= this.opts.simultaneousUploads - ret; num++) {
      started = this.uploadNextChunk(true) || started
    }
    if (!started) {
      Flow.async(() => {
        this.fire('complete')
      }, this)
    }
  }

  /**
   * Resume uploading.
   * @function
   */
  resume() {
    Flow.each(this.files, (file: FlowFile) => {
      if (!file.isComplete()) {
        file.resume()
      }
    })
  }

  /**
   * Pause uploading.
   * @function
   */
  pause() {
    Flow.each(this.files, (file: FlowFile) => {
      file.pause()
    })
  }

  /**
   * Cancel upload of all FlowFile objects and remove them from the list.
   * @function
   */
  cancel() {
    for (var i = this.files.length - 1; i >= 0; i--) {
      this.files[i].cancel()
    }
  }

  /**
   * Returns a number between 0 and 1 indicating the current upload progress
   * of all files.
   * @function
   * @returns {number}
   */
  progress() {
    var totalDone = 0
    var totalSize = 0
    // Resume all chunks currently being uploaded
    Flow.each(this.files, (file: FlowFile) => {
      totalDone += file.progress() * file.size
      totalSize += file.size
    })
    return totalSize > 0 ? totalDone / totalSize : 0
  }

  /**
   * Add a HTML5 File object to the list of files.
   * @function
   * @param {File} file
   * @param {Event} [event] event is optional
   */
  addFile(file: File, event: any) {
    this.addFiles([file], event)
  }

  /**
   * Add a HTML5 File object to the list of files.
   * @function
   * @param {FileList|Array} fileList
   * @param {Event} [event] event is optional
   */
  addFiles(fileList: File[], event: any) {
    // console.log(
    //   'flow',
    //   'addFiles',
    //   fileList.length,
    //   fileList[0],
    //   fileList,
    //   event
    // )
    var files: FlowFile[] = []
    if (this.fire('filesAdding', fileList, event) == false) {
      return
    }
    fileList.forEach((file) => {
      // https://github.com/flowjs/flow.js/issues/55
      if (
        (!ie10plus || (ie10plus && file.size > 0)) &&
        !(file.size % 4096 === 0 && (file.name === '.' || file.fileName === '.'))
      ) {
        var uniqueIdentifier = this.generateUniqueIdentifier(file)
        if (this.opts.allowDuplicateUploads || !this.getFromUniqueIdentifier(uniqueIdentifier)) {
          var f = new FlowFile(this, file, uniqueIdentifier)
          if (this.fire('fileAdded', f, event)) {
            files.push(f)
          }
        }
      }
    })
    if (this.fire('filesAdded', files, event)) {
      files.forEach((file: FlowFile) => {
        if (this.opts.singleFile && this.files.length > 0) {
          this.removeFile(this.files[0])
        }
        this.files.push(file)
      })
      this.fire('filesSubmitted', files, event)
    }
  }

  /**
   * Cancel upload of a specific FlowFile object from the list.
   * @function
   * @param {FlowFile} file
   */
  removeFile(file: FlowFile) {
    for (var i = this.files.length - 1; i >= 0; i--) {
      if (this.files[i] === file) {
        this.files.splice(i, 1)
        file.abort()
        this.fire('fileRemoved', file)
      }
    }
  }

  /**
   * Look up a FlowFile object by its unique identifier.
   * @function
   * @param {string} uniqueIdentifier
   * @returns {boolean|FlowFile} false if file was not found
   */
  getFromUniqueIdentifier(uniqueIdentifier: string): boolean | FlowFile {
    var ret: boolean | FlowFile = false
    Flow.each(this.files, (file: FlowFile) => {
      if (file.uniqueIdentifier === uniqueIdentifier) {
        ret = file
      }
    })
    return ret
  }

  /**
   * Returns the total size of all files in bytes.
   * @function
   * @returns {number}
   */
  getSize() {
    var totalSize = 0
    Flow.each(this.files, (file: FlowFile) => {
      totalSize += file.size
    })
    return totalSize
  }

  /**
   * Returns the total size uploaded of all files in bytes.
   * @function
   * @returns {number}
   */
  sizeUploaded() {
    var size = 0
    Flow.each(this.files, (file: FlowFile) => {
      size += file.sizeUploaded()
    })
    return size
  }

  /**
   * Returns remaining time to upload all files in seconds. Accuracy is based on average speed.
   * If speed is zero, time remaining will be equal to positive infinity `Number.POSITIVE_INFINITY`
   * @function
   * @returns {number}
   */
  timeRemaining() {
    var sizeDelta = 0
    var averageSpeed = 0
    Flow.each(this.files, (file: FlowFile) => {
      if (!file.paused && !file.error) {
        sizeDelta += file.size - file.sizeUploaded()
        averageSpeed += file.averageSpeed
      }
    })
    if (sizeDelta && !averageSpeed) {
      return Number.POSITIVE_INFINITY
    }
    if (!sizeDelta && !averageSpeed) {
      return 0
    }
    return Math.floor(sizeDelta / averageSpeed)
  }

  //
  // ──────────────────────────────────────────────────────────────────── I ──────────
  //   :::::: S T A T I C   M E T H O D S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────────────
  //

  /**
   * Remove value from array
   * @param array
   * @param value
   */
  static arrayRemove(array: any, value: any) {
    var index = array.indexOf(value)
    if (index > -1) {
      array.splice(index, 1)
    }
  }

  /**
   * If option is a function, evaluate it with given params
   * @param {*} data
   * @param {...} args arguments of a callback
   * @returns {*}
   */
  static evalOpts(data: any, ...args: any[]) {
    if (typeof data === 'function') {
      // `arguments` is an object, not array, in FF, so:
      args = Array.prototype.slice.call(arguments)
      data = data.apply(null, args.slice(1))
    }
    return data
  }

  /**
   * Execute function asynchronously
   * @param fn
   * @param context
   */
  static async(fn: any, context: any) {
    setTimeout(fn.bind(context), 0)
  }

  /**
   * Extends the destination object `dst` by copying all of the properties from
   * the `src` object(s) to `dst`. You can specify multiple `src` objects.
   * @function
   * @param {Object} dst Destination object.
   * @param {...Object} src Source object(s).
   * @returns {Object} Reference to `dst`.
   */
  static extend(dst: any, ...args: any[]) {
    Flow.each(arguments as any, (obj: any) => {
      if (obj !== dst) {
        Flow.each(obj, (value: any, key: any) => {
          dst[key] = value
        })
      }
    })
    return dst
  }

  /**
   * Iterate each element of an object
   * @function
   * @param {Array|Object} obj object or an array to iterate
   * @param {Function} callback first argument is a value and second is a key.
   * @param {Object=} context Object to become context (`this`) for the iterator function.
   */
  static each(obj: any, callback: any, context?: any) {
    if (!obj) {
      return
    }
    var key
    // Is Array?
    // Array.isArray won't work, not only arrays can be iterated by index https://github.com/flowjs/ng-flow/issues/236#
    if (typeof obj.length !== 'undefined') {
      for (key = 0; key < obj.length; key++) {
        if (callback.call(context, obj[key], key) === false) {
          return
        }
      }
    } else {
      for (key in obj) {
        if (obj.hasOwnProperty(key) && callback.call(context, obj[key], key) === false) {
          return
        }
      }
    }
  }

  /**
   * FlowFile constructor
   * @type {FlowFile}
   */
  static FlowFile = FlowFile

  /**
   * FlowFile constructor
   * @type {FlowChunk}
   */
  static FlowChunk = FlowChunk

  /**
   * Library version
   * @type {string}
   */
  static version: string = '<%= version %>'
}
