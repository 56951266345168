import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex';
import { DepartmentListContainer } from '../../containers'
import * as types from '@orgmap/store/types'
import HrUserListView from './HrUserListView.vue';

@Component({
  name:'HrView',
  components: { DepartmentListContainer, HrUserListView },
  computed: mapGetters({
    userCurrent: types.ACCOUNT_CURRENT
  })
})
export default class HrView extends Vue {

  @Prop({ type: Boolean, default: false }) addableDept!: boolean;

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  userCurrent!: orgmap.account.TCurrent;

  dept: orgmap.TDepartmentTreeItem | null = null;
  
  get userlang (){
    let lang = 'ko-kr'
    if (this.userCurrent){
      lang = this.userCurrent.userConfig.language.toLowerCase()
    }
    return lang
  }

  select(item: orgmap.TDepartmentTreeItem | null){
    this.dept = item;
    this.$emit('selectDept', this.dept);
  }

  getDepartments(items: orgmap.TDepartmentTreeItem[] | null) {
    this.$emit('getDepartments', items);
  }
}
