import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';


@Component({
    components: {

    }
})
export default class ListHeaderRight extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    @Prop({type: Boolean, default: true}) pagingShow?: boolean;
    @Prop({type: Boolean, default: true}) refreshShow?: boolean;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    Prev(){
        this.$emit('Prev');
    }
    Next(){
        this.$emit('Next');
    }
    Refresh(){
        this.$emit('Refresh');
    }
}

