import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import AttendanceGrid from '@attendance/components/ManageList/AttendanceManageWeek.vue'
import AttendanceWeekModal from '@attendance/views/Modal/AttendanceWeekListModalView.vue'
import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import * as dateutil from '@appbase/util/date-helper'
import api from '@attendance/api/index'
import approval from '@/modules/reservedb/api/approval'

@Component({
    components: { AttendanceGrid, AttendanceWeekModal, DatePicker }
})
export default class AttendanceManageWeekView extends Vue {
    startDate: string = ''
    endDate: string = ''
    searchFilter: number = 0
    searchValue: string = ''
    pageNum: number = 1
    pageSize: number = 20

    list: attendance.AttendanceWeekItem[]= []
    totalCount: number = 0
    detailList: attendance.AttendanceItem[] = []
    isDisplayModal: boolean = false;

    // 재직/퇴직 여부
    personStatusFilter:number = -1;

    // 상세조회
    showDetailList(personCode: string, stDate:string, edDate:string){
        api.attendance.getAttendaceList(1, 10, stDate, edDate, 0, personCode, 0, this.personStatusFilter, 0).then(res => {
            if (res){
                this.detailList = res.attendanceList;
                this.isDisplayModal = true;
            }
        });
    }

    modalClose(){
        this.isDisplayModal = false;
    }

    @Watch("startDate")
    async onChangeStartDate(newDate:string, bfDate:string){
        if(new Date(newDate) >= new Date(this.endDate)){
            await (window as any).alertAsync(this.$t('시간선택오류').toString())
            this.$nextTick(()=>{
                this.startDate = dateutil.uGetDateFormat('yyyy-MM-DD', new Date(bfDate))
            })
        }
        else if(new Date(newDate).getDay()!=1)
            this.setDateRange(new Date(newDate));
    }

    @Watch("endDate")
    async onChangeEndDate(newDate:string, bfDate:string){
        if(new Date(this.startDate) >= new Date(newDate)){
            await (window as any).alertAsync(this.$t('시간선택오류').toString())
            this.$nextTick(()=>{
                this.endDate = dateutil.uGetDateFormat('yyyy-MM-DD', new Date(bfDate))
            })
        }
        else if(new Date(newDate).getDay()!=0)
            this.setDateRange(new Date(this.startDate));
    }

    onSearch(){
        this.getList(1)    
    }

    excelDownload(){
        let url = `/api/z/attendance/weekdownload?startDate=${this.startDate}&endDate=${this.endDate}&searchFilter=${this.searchFilter}&searchValue=${this.searchValue}&personStatusFilter=${this.personStatusFilter}`;
        api.attendance.downloadListExcel(url);
    }

    getList(page: number){
        this.pageNum = page;
        api.attendance.getAttendanceWeekList(this.startDate, this.endDate, this.pageNum, this.pageSize, this.searchFilter, this.searchValue, this.personStatusFilter).then(res => {
            this.list = res.attendanceWeekList;
            this.totalCount = res.totalCount;
        });
    }

    setDateRange(date: Date){
        let stDate = dateutil.uGetWeekStartDate(new Date(date), 1);
        let edDate = new Date(this.endDate==''?date:this.endDate);
        if(edDate.getDay()!=0){
            edDate = dateutil.uGetWeekEndDate(edDate, 6);
            edDate.setDate(edDate.getDate() + 2);
        }
        this.startDate = dateutil.uGetDateFormat('yyyy-MM-DD', stDate);
        this.endDate = dateutil.uGetDateFormat('yyyy-MM-DD', edDate);
    }

    mounted(){
        this.setDateRange(new Date());
        this.getList(this.pageNum);
    }

    created(){
        this.setPage({title:this.$t('주간근무현황관리')});
    }
}