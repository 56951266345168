import { RouteConfig } from "vue-router";
import {
  UserInfoWebpartTest,
  ListTabWebpartTest,
  CalendarWebpartTest,
  ListSummaryWebpartTest,
  ListSuveyWebpartTest,
  ApproveWebpartTest,
  ListDefaultWebpartTest,
  ApproveMailWebpartTest,
  ListImageWebpartTest,
  InboxWebpartTest,
  TotalBoardWebpartTest,
  ShortcutsWebpartTest,
  ListImageSwipeWebpartTest,
  UserProfileWebpartTest,
  UserProfileSetting,
  PendingBoxWebpartTest,
  ZinusCalendar,
  ZinusCompanyNews,
  ZinusNotice,
  ZinusShortCut,
  ZinusWorldTime,
  ZinusTestMaster
} from "../views";
const Webparts = () =>
  import(/* webpackChunkName: "webparts" */ "@webparts/Index.vue");
const WebpartsMaster = () =>
  import(/* webpackChunkName: "webparts" */ "@webparts/views/Master.vue");
 
var children: any[] = [];
if (process.env.NODE_ENV === "development") {
  children = [
    ...children,
    ...[
      {
        path: "UserInfoWebpartTest",
        component: UserInfoWebpartTest,
      },
      {
        path: "ListTabWebpartTest",
        component: ListTabWebpartTest,
      },
      {
        path: "CalendarWebpartTest",
        component: CalendarWebpartTest,
      },
      {
        path: "ListSummaryWebpartTest",
        component: ListSummaryWebpartTest,
      },
      {
        path: "ListSuveyWebpartTest",
        component: ListSuveyWebpartTest,
      },
      {
        path: "ApproveWebpartTest",
        component: ApproveWebpartTest,
      },
      {
        path: "ListDefaultWebpartTest",
        component: ListDefaultWebpartTest,
      },
      {
        path: "ApproveMailWebpartTest",
        component: ApproveMailWebpartTest,
      },
      {
        path: "ListImageWebpartTest",
        component: ListImageWebpartTest,
      },
      {
        path: "InboxWebpartTest",
        component: InboxWebpartTest,
      },
      {
        path: "TotalBoardWebpartTest",
        component: TotalBoardWebpartTest,
      },
      {
        path: "ShortcutsWebpartTest",
        component: ShortcutsWebpartTest,
      },
      {
        path: "ListImageSwipeWebpartTest",
        component: ListImageSwipeWebpartTest,
      },
      {
        path: "UserProfileWebpartTest",
        component: UserProfileWebpartTest,
      },
      {
        path: "PendingBoxWebpartTest",
        component: PendingBoxWebpartTest,
      },
      {
        path: "",
        redirect: "UserInfoWebpartTest",
      },
    ],
  ];
}

const routes: RouteConfig[] = [
  {
    path: "webparts",
    component: Webparts,
    children: [
      {
        path: "",
        component: WebpartsMaster,
        children: children,
      },
      {
        path:"zinus",
        component : ZinusTestMaster,
        children:[
          {
            path : "",
            redirect : "worldtime",
          },
          {
            path: "worldtime",
            component: ZinusWorldTime,
            name : "zinus-worldtime"
          },
          {
            path : "shortcut",
            component  : ZinusShortCut,
            name : "zinus-shortcut"
          },
          {
            path : "calendar",
            component  : ZinusCalendar,
            name : "zinus-calendar"
          },
          {
            path : "company",
            component  : ZinusCompanyNews,
            name : "zinus-companynews"
          },
          {
            path : "notice",
            component  : ZinusNotice,
            name : "zinus-notice"
          },
          
        ]
      },
     
      {
        path: "profile-setting",
        component: UserProfileSetting,
        name: "user-profile-setting",
      },
    ],
  },
];
export default routes;
