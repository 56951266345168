import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import api from '@board/api'

import ModalLayout from '@appbase/templates/ModalLayout/ModalLayout.vue'

@Component({
    components: {
        ModalLayout
    }
})
export default class PasswordCheckComment extends Vue {
    password: string = '';

    onConfirm() {
        this.$emit('confirm', this.password);
        this.password = "";
    }

    onCancel() { 
        this.password = "";
        this.$emit('cancel');
    }
}