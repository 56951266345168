import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import PageNavigationBar from '@appbase/components/Paginate/PageNavigationBar.vue'
import api from '@attendance/api'
import * as dateutil from '@appbase/util/date-helper'
import * as attHelper from '@/util/attendance-helper'

@Component({
    components: {
        PageNavigationBar
    },
})

export default class AttendanceGrid extends Vue {
    @Prop({ type: Array }) list!: attendance.AttendanceItem[]
    @Prop({ type: Number }) totalCount!: number
    @Prop({ type: Number }) pageNum!: number
    @Prop({ type: Number }) pageSize!: number

    pageRange: number = 5

    setDisplayDate(date: Date){
        return attHelper.uSetDisplayDate(date);
    }

    setDisplayTime(date: Date | null){
        if (date) {
            return attHelper.uSetDisplayTime(date);
        } else {
            return null;
        }
    }

    setDisplayWorkingTime(workingTime: any | null, hrTime: any | null){
        return attHelper.uSetDisplayWorkingTime(workingTime, hrTime);
    }

    setDisplayAttendanceResult(result: number | null){
        // debugger
        return attHelper.uDisplayAttendanceResult(result);
    }

    pageMove(pageNum : number){
        this.$emit('pageMove', pageNum)
    }
}
