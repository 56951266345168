export const invalidateUrl = function(path: string): string {
    var root = '/cm';
    var url = '';
    if (path.startsWith('/')) {
        url = root + path;
    } else if (path.startsWith('http://') || path.startsWith('https://')) {
        url = path;
    } else {
        url = path;
    }
    return url;
};

export const invalidateSiteUrl = function(path: string): string {
    var root = process.env.NODE_ENV === 'development' ? '' : '/workflow';
    var url = '';
    if (path.startsWith('/')) {
        url = root + path;
    } else if (path.startsWith('http://') || path.startsWith('https://')) {
        url = path;
    } else {
        url = path;
    }
    return url;
}; 
