import Vue from 'vue'
import { Component, Watch} from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown,Checkbox} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import { mapActions, mapGetters } from 'vuex';
import * as accountTypes from '@account/store/types'
import api from '@gnb/api/common'

@Component({
    components:{ModalHeader,ModalContent,ModalFooter,ContentItem,PreferenceContentTemplate,Dropdown,Checkbox},
    methods:{
        ...mapActions({
        })
    },
    computed:{
        ...mapGetters({
            user: accountTypes.USER

        })
    }
})
export default class Board extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    allChecked: boolean = false;
    noticeAlarmChecked: boolean | null = null;
    boardAlarmChecked: boolean | null = null;
    serveyAlarmChecked: boolean | null = null;
    name1: string = this.$t('게시판').toString();
    name2: string = this.$t('게시판_전체_알림').toString();
    name3: string = this.$t('공지사항').toString();
    name4: string = this.$t('자유게시판').toString();
    name5: string = this.$t('설문조사').toString();

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    user!: account.User;

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Watch('noticeAlarmChecked')
    onCheckNotice(){
        if(this.noticeAlarmChecked && this.boardAlarmChecked && this.serveyAlarmChecked){
            this.allChecked = true;
        }else{
            this.allChecked = false;
        }
    }

    @Watch('boardAlarmChecked')
    onCheckBoard(){
        if(this.noticeAlarmChecked && this.boardAlarmChecked && this.serveyAlarmChecked){
            this.allChecked = true;
        }else{
            this.allChecked = false;
        }
    }

    @Watch('serveyAlarmChecked')
    onCheckServey(){
        if(this.noticeAlarmChecked && this.boardAlarmChecked && this.serveyAlarmChecked){
            this.allChecked = true;
        }else{
            this.allChecked = false;
        }
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    onChangeAllChecked(item:boolean){
        this.allChecked = item;
        if(this.allChecked){
            this.noticeAlarmChecked = true;
            this.boardAlarmChecked = true;
            this.serveyAlarmChecked = true;
        }else{
            this.noticeAlarmChecked = false;
            this.boardAlarmChecked = false;
            this.serveyAlarmChecked = false;
        }
    }

    onChangeNoticeChecked(item:boolean){
        this.noticeAlarmChecked = item;
    }

    onChangeBoardChecked(item:boolean){
        this.boardAlarmChecked = item;
    }

    onChangeServeyCHecked(item:boolean){
        this.serveyAlarmChecked = item;
    }

    onChagedAlarmConfig(){
        api.getAlarmConfig(this.user.comCode, this.user.personCode).then((res)=>{
            if(res.length > 0){
                this.noticeAlarmChecked = res[0].noticeAlarm;
                this.boardAlarmChecked = res[0].boardAlarm;
                this.serveyAlarmChecked = res[0].serveyAlarm;
            }else{
                this.noticeAlarmChecked = false;
                this.boardAlarmChecked = false;
                this.serveyAlarmChecked = false;

            }
        })
    }

    save(){
        api.setAlarmConfig({
            comCode: this.user.comCode,
            personCode: this.user.personCode,
            noticeAlarm: this.noticeAlarmChecked,
            boardAlarm: this.boardAlarmChecked,
            serveyAlarm: this.serveyAlarmChecked,
            newAlarm: null,
            scheduleAlarm: null
        } as gnb.AlarmConfig).then(async ()=>{
            this.onChagedAlarmConfig();
            await (window as any).alertAsync(this.$t('M_Expense_SavedAlert').toString())
        });
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //
    created(){
        this.onChagedAlarmConfig();
    }
    
    
  
}