import { Flow } from './Flow'
import { FlowChunk } from './FlowChunk'
declare global {
  interface File {
    [key: string]: any
    fileName: string
    relativePath: string
    slice: Function
    mozSlice: Function
    webkitSlice: Function
  }
  interface Blob {
    webkitSlice: Function
    mozSlice: Function
  }
  interface FileSystemEntry {
    createReader: () => any
  }
}
/**
 * FlowFile class
 * @name FlowFile
 * @param {Flow} flowObj
 * @param {File} file
 * @param {string} uniqueIdentifier
 * @constructor
 */
export class FlowFile {
  /**
   * Reference to parent Flow instance
   * @type {Flow}
   */
  flowObj!: Flow

  /**
   * Reference to file
   * @type {File}
   */
  file!: File

  /**
   * File name. Some confusion in different versions of Firefox
   * @type {string}
   */
  name!: string

  /**
   * File size
   * @type {number}
   */
  size!: number

  /**
   * Relative file path
   * @type {string}
   */
  relativePath!: string

  /**
   * File unique identifier
   * @type {string}
   */
  uniqueIdentifier!: string

  /**
   * Size of Each Chunk
   * @type {number}
   */
  chunkSize: number = 0

  /**
   * List of chunks
   * @type {Array.<FlowChunk>}
   */
  chunks: FlowChunk[] = []

  /**
   * Indicated if file is paused
   * @type {boolean}
   */
  paused: boolean = false

  /**
   * Indicated if file has encountered an error
   * @type {boolean}
   */
  error: boolean = false

  /**
   * Average upload speed
   * @type {number}
   */
  averageSpeed: number = 0

  /**
   * Current upload speed
   * @type {number}
   */
  currentSpeed: number = 0

  /**
   * upload percentages
   */
  percent: number = 0

  /**
   * is selected
   */
  selected: boolean = false

  /**
   * Date then progress was called last time
   * @type {number}
   * @private
   */
  private _lastProgressCallback: number = Date.now()

  /**
   * Previously uploaded file size
   * @type {number}
   * @private
   */
  private _prevUploadedSize: number = 0

  /**
   * Holds previous progress
   * @type {number}
   * @private
   */
  private _prevProgress: number = 0

  //
  // ──────────────────────────────────────────────────────────────── I ──────────
  //   :::::: C O N S T R U C T O R S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────────
  //

  constructor(flowObj: Flow, file: File, uniqueIdentifier: string) {
    this.flowObj = flowObj
    this.file = file
    this.name = file.fileName || file.name
    this.size = file.size
    this.relativePath =
      file.relativePath || file.webkitRelativePath || this.name
    this.uniqueIdentifier =
      uniqueIdentifier === undefined
        ? flowObj.generateUniqueIdentifier(file)
        : uniqueIdentifier
    this.bootstrap()
  }

  //
  // ────────────────────────────────────────────────────────────────────── I ──────────
  //   :::::: P R I V A T E   M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────────────────
  //

  //
  // ──────────────────────────────────────────────────────────────────── I ──────────
  //   :::::: P U B L I C   M E T H O D S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────────────
  //

  /**
   * Update speed parameters
   * @link http://stackoverflow.com/questions/2779600/how-to-estimate-download-time-remaining-accurately
   * @function
   */
  measureSpeed() {
    var timeSpan = Date.now() - this._lastProgressCallback
    if (!timeSpan) {
      return
    }
    var smoothingFactor = this.flowObj.opts.speedSmoothingFactor
    var uploaded = this.sizeUploaded()
    // Prevent negative upload speed after file upload resume
    this.currentSpeed = Math.max(
      ((uploaded - this._prevUploadedSize) / timeSpan) * 1000,
      0
    )
    this.averageSpeed =
      smoothingFactor * this.currentSpeed +
      (1 - smoothingFactor) * this.averageSpeed
    this._prevUploadedSize = uploaded
  }

  /**
   * For internal usage only.
   * Callback when something happens within the chunk.
   * @function
   * @param {FlowChunk} chunk
   * @param {string} event can be 'progress', 'success', 'error' or 'retry'
   * @param {string} [message]
   */
  chunkEvent(chunk: FlowChunk, event: string, message: string) {
    switch (event) {
      case 'progress':
        if (
          Date.now() - this._lastProgressCallback <
          this.flowObj.opts.progressCallbacksInterval
        ) {
          break
        }
        this.measureSpeed()
        this.flowObj.fire('fileProgress', this, chunk)
        this.flowObj.fire('progress')
        this._lastProgressCallback = Date.now()
        break
      case 'error':
        this.error = true
        this.abort(true)
        this.flowObj.fire('fileError', this, message, chunk)
        this.flowObj.fire('error', message, this, chunk)
        break
      case 'success':
        if (this.error) {
          return
        }
        this.measureSpeed()
        this.flowObj.fire('fileProgress', this, chunk)
        this.flowObj.fire('progress')
        this._lastProgressCallback = Date.now()
        if (this.isComplete()) {
          this.currentSpeed = 0
          this.averageSpeed = 0
          this.flowObj.fire('fileSuccess', this, message, chunk)
        }
        break
      case 'retry':
        this.flowObj.fire('fileRetry', this, chunk)
        break
    }
  }

  /**
   * Pause file upload
   * @function
   */
  pause(): void {
    this.paused = true
    this.abort()
  }

  /**
   * Resume file upload
   * @function
   */
  resume(): void {
    this.paused = false
    this.flowObj.upload()
  }

  /**
   * Abort current upload
   * @function
   */
  abort(reset: boolean = false) {
    this.currentSpeed = 0
    this.averageSpeed = 0
    var chunks = this.chunks
    if (reset) {
      this.chunks = []
    }
    Flow.each(
      chunks,
      (c: FlowChunk) => {
        if (c.status() === 'uploading') {
          c.abort()
          this.flowObj.uploadNextChunk()
        }
      },
      this
    )
  }

  /**
   * Cancel current upload and remove from a list
   * @function
   */
  cancel() {
    this.flowObj.removeFile(this)
  }

  /**
   * Retry aborted file upload
   * @function
   */
  retry() {
    this.bootstrap()
    this.flowObj.upload()
  }

  /**
   * Clear current chunks and slice file again
   * @function
   */
  bootstrap() {
    if (typeof this.flowObj.opts.initFileFn === 'function') {
      var ret = this.flowObj.opts.initFileFn(this)
      if (ret && 'then' in ret) {
        ret.then(this._bootstrap.bind(this))
        return
      }
    }
    this._bootstrap()
  }

  _bootstrap() {
    this.abort(true)
    this.error = false
    // Rebuild stack of chunks from file
    this._prevProgress = 0
    var round = this.flowObj.opts.forceChunkSize ? Math.ceil : Math.floor
    this.chunkSize = Flow.evalOpts(this.flowObj.opts.chunkSize, this)
    var chunks = Math.max(round(this.size / this.chunkSize), 1)
    for (var offset = 0; offset < chunks; offset++) {
      this.chunks.push(new FlowChunk(this.flowObj, this, offset))
    }
  }

  /**
   * Get current upload progress status
   * @function
   * @returns {number} from 0 to 1
   */
  progress() {
    if (this.error) {
      return 1
    }
    if (this.chunks.length === 1) {
      this._prevProgress = Math.max(
        this._prevProgress,
        this.chunks[0].progress()
      )
      return this._prevProgress
    }
    // Sum up progress across everything
    var bytesLoaded = 0
    Flow.each(this.chunks, (c: FlowChunk) => {
      // get chunk progress relative to entire file
      bytesLoaded += c.progress() * (c.endByte - c.startByte)
    })
    var percent = bytesLoaded / this.size
    // We don't want to lose percentages when an upload is paused
    this._prevProgress = Math.max(
      this._prevProgress,
      percent > 0.9999 ? 1 : percent
    )
    return this._prevProgress
  }

  /**
   * Indicates if file is being uploaded at the moment
   * @function
   * @returns {boolean}
   */
  isUploading() {
    var uploading = false
    Flow.each(this.chunks, (chunk: FlowChunk) => {
      if (chunk.status() === 'uploading') {
        uploading = true
        return false
      }
    })
    return uploading
  }

  /**
   * Indicates if file is has finished uploading and received a response
   * @function
   * @returns {boolean}
   */
  isComplete() {
    var outstanding = false
    Flow.each(this.chunks, (chunk: FlowChunk) => {
      var status = chunk.status()
      if (
        status === 'pending' ||
        status === 'uploading' ||
        status === 'reading' ||
        chunk.preprocessState === 1 ||
        chunk.readState === 1
      ) {
        outstanding = true
        return false
      }
    })
    return !outstanding
  }

  /**
   * Count total size uploaded
   * @function
   * @returns {number}
   */
  sizeUploaded() {
    var size = 0
    Flow.each(this.chunks, (chunk: FlowChunk) => {
      size += chunk.sizeUploaded()
    })
    return size
  }

  /**
   * Returns remaining time to finish upload file in seconds. Accuracy is based on average speed.
   * If speed is zero, time remaining will be equal to positive infinity `Number.POSITIVE_INFINITY`
   * @function
   * @returns {number}
   */
  timeRemaining() {
    if (this.paused || this.error) {
      return 0
    }
    var delta = this.size - this.sizeUploaded()
    if (delta && !this.averageSpeed) {
      return Number.POSITIVE_INFINITY
    }
    if (!delta && !this.averageSpeed) {
      return 0
    }
    return Math.floor(delta / this.averageSpeed)
  }

  /**
   * Get file type
   * @function
   * @returns {string}
   */
  getType() {
    return this.file.type && this.file.type.split('/')[1]
  }

  /**
   * Get file extension
   * @function
   * @returns {string}
   */
  getExtension() {
    return this.name
      .substr((~-this.name.lastIndexOf('.') >>> 0) + 2)
      .toLowerCase()
  }

  //
  // ──────────────────────────────────────────────────────────────────── I ──────────
  //   :::::: S T A T I C   M E T H O D S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────────────
  //
}
