import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import  { MenuInfo } from '../components/Preference/menu'

@Component({
})
export default class NavigationTab extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @PropSync('items', { type: Array }) itemsModel!: MenuInfo[];

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    itemClick(item: MenuInfo){
        this.itemsModel.map((i)=>{
            if(i.name === item.name){
                i.selected = true;
            }else{
                i.selected = false;
                if(i.children){
                    i.children.map((c:MenuInfo)=>{
                        c.selected = false;
                    })
                }
            }
        })

        this.$emit('changed', this.itemsModel);
    }

}