import { RouteConfig } from 'vue-router'

const BoardList = () =>
  import(/* webpackChunkName: "board" */ '@board/components/board/BoardList.vue')
const Home = () => import(/* webpackChunkName: "home" */ '@home/Index.vue')
const HomeMaster = () => import(/* webpackChunkName: "home" */ '@home/views/Master.vue')
const HomeView = () => import(/* webpackChunkName: "home" */ '@home/views/HomeView.vue')
const ProxyView = () => import(/* webpackChunkName: "home" */ '@home/views/ProxyView.vue')
const WebPartView = () => import(/* webpackChunkName: "home" */ '@home/views/WebPartView.vue')
const TestMaster = () => import(/* webpackChunkName: "home" */ '@home/views/TestMaster.vue')
const SearchView = () => import(/* webpackChunkName: "home" */ '@home/views/SearchView.vue')
// const MyPageView = () => import(/* webpackChunkName: "home" */ '@home/views/MyPageView.vue')
const MyPageView = () => import(/* webpackChunkName: "home" */ '@home/views/MyPage/MyPageView.vue')
const RecentArticle = () => import(/* webpackChunkName: "home" */ '@home/views/RecentArticleView.vue')
const PopupMaster = () => import('@home/views/PopupViewMaster.vue')
const PopupNoticeView = () => import('@home/views/NoticePopup/NoticePreviewView.vue')

const routes: RouteConfig[] = [
  {
    path: '/home',
    component: Home,

    children: [
      {
        path: 'test',
        component: TestMaster,
        children: [
          {
            path: 'board',
            component: BoardList,
          },
        ],
      },
      {
        path: '',
        component: HomeMaster,
        children: [
          {
            path: '',
            component: HomeView,
            name: 'Home',
          },
          {
            path: 'mypage',
            component: MyPageView,
            // props: (route) => ({ query: {name: 'MYPAGE'} }),
            name: 'MyPage',
            children :[]
          },
          {
            path: 'proxy',
            name: 'menu-proxy',
            component: ProxyView,
            children: [
            ],
          },
          {
            path: 'recentarticle',
            name: 'recent-article',
            component: RecentArticle
          },
          {
            path: 'search',
            name: 'total-search',
            component: SearchView,
            children: [],
          },
          {
            path: 'WebPart',
            component: WebPartView,
          },
        ],
      },
      {
        path: 'popup',
        component: PopupMaster,
        children : [
          {
            path: 'notice',
            name:'popup-notice',
            component: PopupNoticeView,
            children : [
              
            ]
          }
        ]
      },
    ],
  },
]
export default routes
