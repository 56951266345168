import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { CalendarDayInfo } from './type'
import CalendarDayColor from './CalendarDayColor.vue'

@Component({
  components: { CalendarDayColor },
})
export default class CalendarDay extends Vue {
  @Prop({ type: Object }) item!: CalendarDayInfo
  @Prop({ type: Function }) onSelected!: (item: CalendarDayInfo) => void

  get colors() {
   
    return this.item.colors.slice(0, 2)
  }
}
