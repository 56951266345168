import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { WishListContainer } from '../containers'

@Component({
  components: { WishListContainer },
})
export default class MainView extends Vue {
  
  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  options: orgmap.TWishContainerOptionItem[] = [
    {
      id: 0,
      name: '받는사람',
      height: '40%',
    },
    {
      id: 1,
      name: '참조',
      height: '30%',
    },
    {
      id: 2,
      name: '숨은참조',
      height: '30%',
    },
  ]

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  handleOk() {
    console.log('clicked ok button.')
  }

  handleCancel() {
    console.log('clicked cancel button.')
  }
}
