import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import * as types from '@gnb/store/types';


@Component({
   name: 'MailApproval',
   components: { },
   computed: {
        ...mapGetters({
      }),
    },
   methods: {
        ...mapActions({
        }),
        ...mapMutations({
        }),
    },
})
export default class MailApproval extends Vue {

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //  

    @Prop({ type: Object }) value !: gnb.TA_MYSETTING;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //   

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M U T A T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    get receiveNextMail() {
        return this.value.isReceiveNextMail;
    }

    set receiveNextMail(value: boolean) {
        this.value.isReceiveNextMail = value;
        this.$emit('update:value', this.value);
    }

    get receiveBeforeMail() {
        return this.value.isReceiveBeforeMail;
    }

    set receiveBeforeMail(value: boolean) {
        this.value.isReceiveBeforeMail = value;
        this.$emit('update:value', this.value);
    }

    get receiveAfterMail() {
        return this.value.isReceiveAfterMail;
    }

    set receiveAfterMail(value: boolean) {
        this.value.isReceiveAfterMail = value;
        this.$emit('update:value', this.value);
    }

    get receiveRefMail() {
        return this.value.isReceiveRefMail;
    }

    set receiveRefMail(value: boolean) {
        this.value.isReceiveRefMail = value;
        this.$emit('update:value', this.value);
    }

    get receiveCompleteMail() {
        return this.value.isReceiveCompleteMail;
    }

    set receiveCompleteMail(value: boolean) {
        this.value.isReceiveCompleteMail = value;
        this.$emit('update:value', this.value);
    }

    get receiveCompleteApproveMail() {
        return this.value.isReceiveCompleteApproveMail;
    }

    set receiveCompleteApproveMail(value: boolean) {
        this.value.isReceiveCompleteApproveMail = value;
        this.$emit('update:value', this.value);
    }

    get receiveApproveMail() {
        return this.value.isReceiveApproveMail;
    }

    set receiveApproveMail(value: boolean) {
        this.value.isReceiveApproveMail = value;
        this.$emit('update:value', this.value);
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    mounted() {
        
    }

    created() {
        console.log(this.value);
    }
}