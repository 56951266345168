import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import AutoDeliveryView from '@gnb/components/Preference/Mail/AutoDelivery/AutoDeliveryView.vue'
import { mapGetters,mapActions } from 'vuex'
import * as types from '@gnb/store/types'

@Component({
    components:{
      ModalHeader,
      ModalContent,
      ModalFooter,
      ContentItem,
      PreferenceContentTemplate,
      AutoDeliveryView
    },
    methods:{
      ...mapActions({
          loading: types.COMMON_LOADDING
      })
    },
    computed:{
        ...mapGetters({
            isLoading: types.COMMON_LOADDING
        })
    }
})
export default class AutoDelivery extends Vue {
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: A C T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  loading!: (option: boolean) => void;
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  isLoading !: boolean;
  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  get contentComponents() {
    return [AutoDeliveryView]
  }

  header:string = this.$t('W_Mail_AutoForwarder').toString();

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  handleSave() {
    const contens = this.$refs.contents as any[]
    contens.forEach((x) => {
      x.save()
    })
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  created(){
    this.loading(true);
  }
}