import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator'
import { mapMutations } from 'vuex'
import * as types from '@orgmap/store/types'

@Component({
    name: 'User',
    methods: {
        ...mapMutations({
            selectUser: types.ORG_USER_SELECT
        })
    }
})
export default class User extends Vue {

    domain: string = `${location.protocol}//${location.host}`

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    @Prop({ type: Object }) user!: orgmap.TUser

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: M U T A T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    selectUser!: (payload: orgmap.TUser) => void

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    select(user: orgmap.TUser) {
        user.selected = !user.selected
        this.selectUser(user)
    }

    errorPhoto() {
        if (
            (this.$refs.photo as HTMLImageElement) != undefined &&
            (this.$refs.photo as HTMLImageElement).src !== window.location.href
        ) {
            (this.$refs.photo as HTMLImageElement).src =
                '/cm/gres/faux/images/ghost.png'
        }
    }
}
