import { AddPopupParam, AddRedirectParam, GetFormAuthParam, invalidateSiteUrl, openPopup } from ".";
import $ from 'jquery'

export const uShowForm = function (Item: any) {
    let url: string = ``;
    let storekey = `s_${$.now()}`;
    if (Item.ApmId) 
    {
        url = `/page/form?apmid=${Item.ApmId}&appid=${Item.ApplicationId}&storekey=${storekey}`;        
    } 
    else 
    {
        url = `/page/form?appid=${Item.ApplicationId}&storekey=${storekey}`;
    }

    // 전체 미결함에서 여는 경우
    if(window.location.href.indexOf('?dqn=1021') > -1){
        var authParam = GetFormAuthParam();
        url = url + `&authkey=${authParam}`;

        // console.log('authParam', Date.parse(atob(authParam)), Date.parse(atob(authParam)));
    }

    if (Item.IsRedirect) {
        let newUrl = invalidateSiteUrl(url);
        newUrl = AddPopupParam(newUrl);
        newUrl = AddRedirectParam(newUrl);
        // ISFRAME
        window.location.href = newUrl;
    } else {
        openPopup(Item.ApmId?.toString(), url, {
            width: 1100,
            height: 1000
        });
    }
}

export const uGetValue = function(ColumnInfo: any, Item: any) {
    if (ColumnInfo) {
        switch (ColumnInfo.ColumnKey) {
            case 'COL0': // NO
                return Item.ApmId;
            case 'COL1': // 문서상태
                return uApmStatus(Item.APMStatus);
            case 'COL2': // 결재단계
                return `${Item.CurrentStep} / ${Item.MaxStep}`;
            case 'COL3': // 문서번호
                return `${Item.DocNumber}`;
            case 'COL4': // 양식명
                return `${Item.ApplicationName}`;
            case 'COL5': // 제목
                return Item.Subject;
            case 'COL6': // 기안자명
                return Item.ReqName;
            case 'COL7': // 기안일시
                return uGetDateTime(Item.DraftDate);
            case 'COL8': // 마지막 결재일시
                return uGetDateTime(Item.LastApprovedDate);
            case 'COL9': // 기안부서
                return Item.ReqDeptName;
            case 'COL10': // 현재 결재자
                return Item.CurrentApproverName;
            case 'COL11': // 생성일
                return uGetDateTime(Item.CreatedDate);
            case 'COL12': // 수정일
                return uGetDateTime(Item.UpdatedDate);
            case 'COL13': // 증빙일자
                return Item.EtcField5;
            case 'COL14': // 전표번호
                return Item.EtcField2;
            case 'COL15': // 금액
                return Item.EtcField4;
            case 'COL16': // 통화기호
                return Item.EtcField3;
            case 'COL17': // 공문 문서번호
                return Item.ExtField1;
            case 'COL18': // 회람문서 열람상태
                return uGetARApprovedFlag(Item.ApprovedDate);
            case 'COL19': // 회람한 사람
                return Item.DisplayName;
            case 'COL20': // 회람한 날짜
                return uGetDateTime(Item.ARDate);
        }
    }
    return '';
}


export const uApmStatus = function (value?: string) {
    value = ('' + value).toUpperCase();
    switch (value) {
        case 'DD': return '영구삭제됨';
        case 'DL': return '거부';
        case 'DR': return '수신대기';
        case 'DT': return '기결';//'수신됨';
        case 'M0': return '부결';
        case 'M1': return '기결';
        case 'M2': return '진행';
        case 'M5': return '임시저장';
        case 'M6': return '삭제됨';
        case 'M7': return '보류';
        case 'M8': return '수정요청';
        case 'M9': return '폐기';
        case 'RT': return '회수';
    }
    return '기안';
}

export const uApmStatusClass = function (value?: string) {
    value = ('' + value).toUpperCase();
    switch (value) {
        case 'DD': return 'fx-sta-darkgray';
        case 'DL': return 'fx-sta-darkgray';
        case 'DR': return 'fx-sta-yellow';
        case 'DT': return 'fx-sta-green';//'fx-sta-yellow';
        case 'M0': return 'fx-sta-darkgray';
        case 'M1': return 'fx-sta-green';
        case 'M2': return 'fx-sta-yellow';
        case 'M5': return 'fx-sta-darkgray';
        case 'M6': return 'fx-sta-darkgray';
        case 'M7': return 'fx-sta-purple';
        case 'M8': return 'fx-sta-purple';
        case 'M9': return 'fx-sta-darkgray';
        case 'RT': return 'fx-sta-darkgray';
    }
    return 'fx-sta-darkgray';
}

export const uApprovalStatus = function (value?: string) {
    value = ('' + value).toUpperCase();
    switch (value) {
        case 'A0': return '부결';
        case 'A1': return '승인';
        case 'A2': return '진행';
        case 'A3': return '대기';
        case 'A4': return '임시저장';
        case 'A5': return '스킵';
        case 'A6': return '대결진행';
        case 'A7': return '대결승인';
        case 'A8': return '대결부결';
        case 'A9': return '대결폐기';
        case 'AC': return '참조';
        case 'AD': return '폐기';
        case 'DE': return '삭제';
        case 'HD': return '보류';
        case 'HE': return '수정요청';
        case 'DR': return '수신대기'; // 공문 수신대기
        case 'DU': return '수신'; // 공문 수신
        case 'DL': return '거부'; // 공문 거절
        case 'AA': return '전결';
        case 'AP': return '기안';
        case 'DA': return '접수'; // 부서협조 접수 (재사용개념)
        case 'D2': return '진행'; // 부서협조 진행
    }
    return 'N/A';
}

export const uApprovalStatusClass = function (value?: string) {
    value = ('' + value).toUpperCase();
    switch (value) {
        case 'A0': return 'badge-lightgray';
        case 'A1': return 'badge-green';
        case 'A2': return 'badge-yellow';
        case 'A3': return 'badge-lightgray';
        case 'A4': return 'badge-lightgray';
        case 'A5': return 'badge-green';
        case 'A6': return 'badge-yellow';
        case 'A7': return 'badge-green';
        case 'A8': return 'badge-lightgray';
        case 'A9': return 'badge-lightgray';
        case 'AC': return 'badge-lightgray';
        case 'AD': return 'badge-lightgray';
        case 'DE': return 'badge-lightgray';
        case 'HD': return 'badge-purple';
        case 'HE': return 'badge-purple';
        case 'DR': return 'badge-lightgray'; // 공문 수신대기
        case 'DU': return 'badge-green'; // 공문 수신
        case 'DL': return 'badge-lightgray'; // 공문 거절
        case 'AA': return 'badge-green'; // 전결
        case 'AP': return 'badge-green'; // 기안
        case 'DA': return 'badge-green'; // 부서협조 접수 (재사용개념)
        case 'D2': return 'badge-yellow'; // 부서협조 진행
    }
    return 'badge-lightgray';
}

export const uApprovalLineType = function (value?: string) {
    value = ('' + value).toUpperCase();
    switch (value) {
        case 'AC': return '후참조';
        case 'AG': return '합의';
        case 'AL': return '결재';
        case 'AR': return '회람';
        case 'BC': return '전참조';
        case 'CC': return '숨은참조';
        case 'DC': return '부서숨은참조';
        case 'DR': return '부서수신';
        case 'RV': return '수신';
        case 'SG': return '합의';        
    }
    return '결재';
}

export const uActionType = function (value?: string) {
    switch(value) {
        case 'AA': return '전결';
        case 'AP': return '상신';
        case 'AV': return '승인';
        case 'DA': return '부서 접수';
        case 'DD': return '휴지통 삭제';
        case 'DL': return '거부';
        case 'DP': return '폐기';
        case 'DU': return '수신부서 수신';
        case 'ED': return '수정';
        case 'HD': return '보류';
        case 'HE': return '보류 수정요청';
        case 'RE': return '재사용';
        case 'RJ': return '반려';
        case 'RS': return '복원';
        case 'RT': return '회수';
        case 'SK': return '스킵';
        case 'TD': return '임시저장 삭제';
        case 'TS': return '임시저장';
        case 'TV': return '임시저장 저장';
    }
    return '-';
}

export const uGetDateTime = function (value?: any) {
    value = value?.toString();
    if (value) {
        return value?.split('T')[0]?.split(' ')[0] + ' ' + (value?.toString()?.split('T')[1]).substr(0, 5);
    } else {
        return '-';
    }
}

export const uGetARApprovedFlag = function(value?: any){
    if(value){
        return '확인';
    }
    else {
        return '미확인'
    }
}
