import api from '@/api';
import { ContactsStoredType } from './favorite';
export default{
    /**
     * 주소록 목록 데이터를 조회합니다.
     * @param {string} parentId 폴더 root ID
     */
    getContactTree(parentId?: string) {
        return api
            .post({
                url: `/api/contact/folder/list?type=DB&folderType=PC&shareType=notShared`,
                data: {
                    fields: [
                        { key: 'Id' },
                        { key: 'DisplayName' },
                        { key: 'TotalCount' },
                        { key: 'ChildFolderCount' },
                        { key: 'WellKnownFolderName' },
                        { key: 'USER_IS_FAVORITE' },
                        { key: 'USER_RENDER_ORDER' },
                    ],
                    filters: [
                        {
                            key: 'FolderClass',
                            value: 'IPF.Contact',
                            condition: 'isEqualTo'
                        }
                    ],
                    parentId: parentId,
                    wellKnownParentName: 'Contacts',
                    type: 'Folder',
                    traversal: 'Shallow',
                },
            })
            .then((res: any) =>
                res.items.map((item: orgmap.PersonalFolder) => {
                    var rlt = {
                        ...item,
                        children: null,
                        expanded: false,
                        selected: false,
                        storedType: ContactsStoredType.DB
                    };
                    return rlt;
                })
            );
    },
}