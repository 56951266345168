import { render, staticRenderFns } from "./PopupLayout.vue?vue&type=template&id=cb1f3d6a"
import script from "./PopupLayout.ts?vue&type=script&lang=ts&external"
export * from "./PopupLayout.ts?vue&type=script&lang=ts&external"
import style0 from "./PopupLayout.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports