var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tab-pane",class:{ active: _vm.showComment }},[(_vm.useAnonComment)?_c('PasswordCheckComment',{directives:[{name:"show",rawName:"v-show",value:(_vm.mShowPasswordCheck),expression:"mShowPasswordCheck"}],on:{"confirm":_vm.onPasswordCheckConfirm,"cancel":_vm.onPasswordCheckCancel}}):_vm._e(),_c('div',{staticClass:"comment-write-wrap"},[_c('div',{staticClass:"thumb user m-r-s"},[(_vm.useAnonComment)?_c('img',{attrs:{"src":_vm.mAnonPhoto,"onerror":"this.src='/cm/gres/faux/images/ghost.png'"}}):_c('img',{attrs:{"src":_vm.mUserPhoto,"onerror":"this.src='/cm/gres/faux/images/ghost.png'"}})]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.mComment),expression:"mComment"}],staticClass:"fm-def no-resize",attrs:{"rows":"2","placeholder":_vm.$t('M_BB_CommentIsNull')},domProps:{"value":(_vm.mComment)},on:{"input":function($event){if($event.target.composing)return;_vm.mComment=$event.target.value}}}),_c('button',{staticClass:"btn page-btn btn-green-outline",on:{"click":function($event){return _vm.createArticleComment()}}},[_vm._v(" "+_vm._s(_vm.$t("작성"))+" ")])]),(_vm.commentsModel.length > 0)?_c('div',{staticClass:"space-l"}):_vm._e(),_c('ul',{staticClass:"list-thumb ft-m"},_vm._l((_vm.commentsModel),function(comment,idx){return _c('li',{key:comment.commentId,ref:"refUpdateComment",refInFor:true,class:{ reply: comment.replyTo != null }},[_c('div',{staticClass:"w-100 p-t-l p-b-l"},[_c('div',{staticClass:"dp-flex vertical-top"},[_c('div',{staticClass:"thumb user"},[(_vm.commentPhotoMounted)?_c('img',{attrs:{"src":_vm.commnetPhotos[idx].photoUrl,"onerror":"this.src='/cm/gres/faux/images/ghost.png'"}}):_c('img',{attrs:{"src":_vm.mAnonPhoto}})]),_c('div',{staticClass:"thumb-r flex-l",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"dp-flex"},[_c('div',{staticClass:"mr-auto"},[(
                                        _vm.useAnonComment &&
                                        comment.isReplyModel
                                    )?_c('b',{staticClass:"ft-black"},[_vm._v(_vm._s(_vm.anonName ? _vm.anonName : _vm.$t("익명"))+" ")]):(comment.isAnonymous)?_c('b',{staticClass:"ft-black"},[_vm._v(_vm._s(_vm.$t("W_BB_Anonymous"))+" ")]):_c('b',{staticClass:"ft-black"},[_vm._v(_vm._s(comment.userName)),_c('span',{staticClass:"divide-hor divide-gray m-l-xs m-r-xs"}),_vm._v(_vm._s(comment.userDeptName)),_c('span',{staticClass:"divide-hor divide-gray m-l-xs m-r-xs"}),_vm._v(_vm._s(comment.userJobTitle)+" ")])]),_c('div',{staticClass:"ml-auto ft-s"},[(comment.onEdit)?_c('a',{staticClass:"ft-red",on:{"click":function($event){return _vm.onCommentEditCancel(comment)}}},[_vm._v(_vm._s(_vm.$t("취소")))]):(_vm.getCommentEditable(comment))?_c('a',{staticClass:"ft-gray",on:{"click":function($event){return _vm.onClickUpdateComment(comment, idx)}}},[_vm._v(_vm._s(_vm.$t("수정")))]):_vm._e(),_c('span',{staticClass:"divide-hor divide-gray m-l-xs m-r-xs"}),(
                                        _vm.getCommentEditable(comment) &&
                                        !comment.isReplyModel
                                    )?_c('a',{staticClass:"ft-gray",on:{"click":function($event){return _vm.deleteArticleComment(
                                            comment.commentId
                                        )}}},[_vm._v(_vm._s(_vm.$t("삭제")))]):_vm._e()])]),(comment.onEdit)?_c('div',{staticClass:"comment-write-wrap m-t-m m-b-s"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(comment.contentAfter),expression:"comment.contentAfter"}],staticClass:"fm-def no-resize",attrs:{"rows":"2","placeholder":_vm.$t('M_BB_CommentIsNull')},domProps:{"value":(comment.contentAfter)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(comment, "contentAfter", $event.target.value)}}}),_c('button',{staticClass:"btn page-btn btn-green-outline",on:{"click":function($event){return _vm.updateArticleComment(comment)}}},[_vm._v(" "+_vm._s(_vm.$t("작성"))+" ")])]):_vm._e(),(!comment.onEdit)?[_c('p',{staticClass:"ft-black m-t-m m-b-s",staticStyle:{"white-space":"pre-wrap"}},[(comment.replyTo != null && !comment.isAnonymous)?_c('b',{staticClass:"ft-blue m-r-s"},[_vm._v("@"+_vm._s(comment.replyToName))]):_vm._e(),_vm._v(" "+_vm._s(comment.content)+" ")]),_c('div',{staticClass:"m-b-s"},[_c('span',{staticClass:"ft-s ft-gray m-r-s"},[_vm._v(_vm._s(_vm.getCreatedDate(comment.createdDate)))]),_c('span',{staticClass:"ft-s ft-gray m-r-s"},[_vm._v(_vm._s(_vm.getCreateTime(comment.createdDate)))])]),_c('a',{staticClass:"ft-gray",on:{"click":function($event){return _vm.replyArticleComment(
                                        comment.commentId,
                                        idx
                                    )}}},[_vm._v(_vm._s(_vm.$t("M_BB_Reply")))])]:_vm._e()],2)])])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }