<template>
  <textarea
    :type="type"
    :name="name"
    :class="['fm-def', w100 ? 'w-100' : '', resize === false ? 'no-resize' : '']"
    rows="2"
    :value="value"
    :required="required"
    :placeholder="placeholder"
    :readonly="readonly"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    name: null,
    type: 'text',
    required: false,
    w100: false,
    value: '',
    placeholder: '',
    readonly: false,
    resize: true,
  },
}
</script>

<style></style>
