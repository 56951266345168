<template>
  <div id="wrapper">
    <!-- <UserInfoWebpart /> -->
    <PendingBoxWebpart :option="webPartItem"/>
  </div>
</template>

<script>

import PendingBoxWebpart from '../PendingBoxWebpart/PendingBoxWebpart.vue'
export default {
  components: { PendingBoxWebpart },
  data() {
    return {
      webPartItem: {
      
      }
    }
  }
}

</script>

<style></style>
