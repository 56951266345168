import api from '@/api'

export default {
  getCurrentUser(): Promise<orgmap.TUser> {
    return api.get<orgmap.TUser>({ url: '/api/account/user/current' })
  },

  getPhotoUrl(address: string): Promise<string> {
    return api.get({
      url: `/api/account/photo/${address}`,
    })
  },

  getPhotoUrlByCodes(codes: string[]): Promise<orgmap.EmployeePhoto[]> {
    return api.post({
      url: '/api/account/photo/byCodes',
      data: codes,
    })
  },

  getUser(email: string): Promise<orgmap.TUser> {
    return api.get({
      url: `/api/hr/person`,
      params: {
        email,
      },
    })
  },

  getUserByPersonCode(personCode: string): Promise<orgmap.TUser> {
    return api.get({
      url: `/api/hr/department/person`,
      params: {
        personCode,
      },
    })
  },
}
