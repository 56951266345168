import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import * as dateutil from '@appbase/util/date-helper'

@Component
export default class GnbWorkFeed extends Vue {
    @Prop({ type: Array }) workFeedItems!: gnb.WorkFeedItem[];
    @Prop({ type: String }) userLang!: string
    workfeedType: string = ''

    fnSetWorkFeedType(workfeedType: string){
        this.workfeedType = workfeedType;
    }

    getWorkFeedType(alarmType: string){
        let sysName = "";
        switch(alarmType){
            case "WF":
                sysName = this.$t('결재').toString();
                break;
            case "BB":
                sysName = this.$t('게시').toString();
                break;
            default:
                sysName = this.$t('알림').toString() 
                break;
        }
        return sysName;
    }

    getWorkFeedStyle(alarmType: string){
        let className = ''
        switch(alarmType){
            case "WF":
                className = 'ft-blue';
                break;
            case "BB":
                className = 'ft-green';
                break;
            default:
                className = 'ft-orange'
                break;    
        }
        return className
    }

    getWorkFeedImage(alarmType: string){
        let sysImg = "";
        switch(alarmType){
            case "WF":
                sysImg = '/cm/gres/faux/images/ico_approval.png';
                break;
            case "BB":
                sysImg = '/cm/gres/faux/images/ico_board.png';
                break;
            default:
                sysImg = '/cm/gres/faux/images/ico_notice_default.png'
                break;
        }
        return sysImg;
    }

    fnSetDisplayDate(createDate: Date){
        let displayDate = "";
        let dtNow = new Date();
        let dtCreate = new Date(createDate);
        
        //if (dtCreate.toISOString().slice(0, 10) == dtNow.toISOString().slice(0, 10)){
        if (dateutil.uGetDateFormat('yyyy-MM-DD', dtCreate) == dateutil.uGetDateFormat('yyyy-MM-DD', dtNow)){
            displayDate = [(dtCreate.getHours() > 9 ? dtCreate.getHours() : '0' + dtCreate.getHours()), 
                           (dtCreate.getMinutes() > 9 ? dtCreate.getMinutes() : '0' + dtCreate.getMinutes())].join(' : ');
        }
        else{
            displayDate = dateutil.uGetDateFormat('yyyy-MM-DD', dtCreate);
        }        

        return displayDate;
    }
}