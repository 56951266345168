import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'
import { Prop } from 'vue-property-decorator'

import * as types from '@appbase/store/types'

@Component({
    computed: {
        ...mapGetters({
            page: types.PAGE_CURRENT
        })
    }
})
export default class PopupLayout extends Vue {
    @Prop({type:Boolean,default:false}) useHeader!:boolean
}
