import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
// components
import { DepartmentList } from '../components';
import api from '@orgmap/api';
import { mapGetters } from 'vuex';
import * as accountTypes from '@account/store/types'
@Component({
    name: 'MobileContainer',
    components: { DepartmentList },
    computed: {
        ...mapGetters({
            user: accountTypes.USER
        })
    }
})
export default class MobileContainer extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    departments: orgmap.TDepartmentTreeItem[] = []

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    user!: account.User; 

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    selectItem(dept: any) {
        this.$emit('selectItem', dept);
    }

    /**
     * 자신의 부서까지 트리를 확장하고 부서원 목록을 표시합니다.
     */
    getUserDept() {
        api.hr.getDeptPath({
            comCode: this.user.comCode,
            deptCode: this.user.deptCode,
        })
        .then(async (res) => {
            var pathArr = res.items[0].path.split('/').slice(1)
            var findDept: orgmap.TDepartmentTreeItem[] = []

            for (var path of pathArr) {
                var dept: orgmap.TDepartmentTreeItem | null = null
                dept = this.departments.filter(
                    (x: orgmap.TDepartmentTreeItem) => x.deptCode === path
                )[0]

                if (!dept && findDept.length) {
                    dept = findDept.filter((x: orgmap.TDepartmentTreeItem) => x.deptCode === path)[0]
                }

                if (dept) {
                    console.log('dept', dept)
                    await this.toggleDepartmentAsync(dept)
                        findDept = []
                        dept?.children?.map((x) => {
                        findDept.push(x)
                    })
                }
            }
        })
    }

    /**
     * 부서 노드를 토글합니다.
     * 부서를 확장하여 하위 부서 및 사용자 목록을 표시합니다.
     * @param dept 클릭한 부서 객체
     */
    async toggleDepartmentAsync(dept: orgmap.TDepartmentTreeItem) {
        dept.expanded = !dept.expanded
        if (!dept.users) {
            const result = await api.hr.getEmployeeList({
                level: 2,
                comCode: dept.comCode,
                deptCode: dept.deptCode,
                parentDeptCode: '',
            } as orgmap.DepartmentOption)
            dept.users = result.items.map((user: any) => ({
                ...user,
                selected: false,
            }))
        }
        if (!dept.children) {
            dept.children = await this.getOrgTreeAsync(dept.deptCode, dept.comCode)
        }
    }

    /**
     * 부서 트리를 로드합니다.
     */
    async getOrgTreeAsync(
        parentDeptCode: string = '',
        compCode: string
    ): Promise<Array<orgmap.TDepartmentTreeItem>> {
        if (!compCode) compCode = 'FL'
            const defaultDeptState = {
                expanded: false,
                selected: false,
                children: null,
                users: null,
            }
            const result = await api.hr.getOrgTree({
                level: 2,
                comCode: compCode,
                deptCode: '',
                parentDeptCode: parentDeptCode,
            })
            console.log('result', result)
            const mapResult = (dept: any) => ({ ...dept, ...defaultDeptState })
            const departments = result?.map(mapResult) as Array<orgmap.TDepartmentTreeItem>
            console.log('departments', departments)
            return departments.length ? this.flatToHierarchy(departments) : []
    }

    /**
     * 플랫한 구조의 데이터를 트리 구조로 변환합니다.
     * @param flat 플랫한 구조의 데이터
     * @returns 트리 구조로 정재된 데이터
     */
    flatToHierarchy(flat: Array<orgmap.TDepartmentTreeItem>) {
        console.log('flat', flat)
        var roots: any[] = []
        var all: any = {}
        var flatArr: Array<orgmap.TDepartmentTreeItem> = []
        for (var item of flat) {
            all[item.id] = {
                ...item,
                children: null,
                expanded: false,
                selected: false,
            } as orgmap.TDepartmentTreeItem
            flatArr.push(all[item.id])
        }
        flatArr.forEach(function(item) {
            console.log('item', item)
            const parentCode = item.parentDeptCode
            if (!parentCode) {
                roots.push(item)
            } else if (parentCode in all) {
                var p = all[parentCode] as orgmap.TDepartmentTreeItem
                if (!('children' in p) || !p.children) {
                    p.children = []
                }
                p.children.push(item)
            } else {
                roots.push(item)
            }
        })

        return roots
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    async created() {
        this.departments = await this.getOrgTreeAsync('', this.user.comCode)
        this.getUserDept()
    }
}
