import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import store from '@/store'
import * as msTeams from '@microsoft/teams-js'
import * as types from './types'

// Microsoft Teams Setting
msTeams.initialize(() => {
  store.commit(types.TEAMS, true)
})

// initial state
const state: appbase.state.AppbaseState = {
  modal: null,
  teams: false,
  page: {
    current: Object.create(null),
  },
}

export default {
  state,
  actions,
  getters,
  mutations,
}
