import api from '@/api'
export default {
  /**
   * 공용연락처 목록 조회
   */
  getPublicContactsList(data: orgmap.DLGroupApiOption): Promise<orgmap.DLGroupItemContainer> {
    return api
      .post({
        url: '/api/ma/DistributionGroup/GetDistributionGroupList',
        data: data,
      });
  },

  /**
   * 공용연락처 구성원 조회
   * @param item 공용연락처
   */
  getPublicContactsMembersList(
    data: orgmap.DLGroupApiOption
  ): Promise<orgmap.PagedItemList<orgmap.UserItem>> {
    return api
      .post({
        url: '/api/ma/DistributionGroup/GetDistributionGroupMemberList',
        data: data,
      })
      .then((res: any) => {
        let result = {} as orgmap.PagedItemList<orgmap.UserItem>
        result.items = res.data
        result.totalCount = res.totalCount

        return result
      })
  },

  // getPublicContactsList(data: orgmap.api.DynamicApiOptions): Promise<orgmap.PagedItemList<orgmap.ContactItem>>{
  //     return api.post({
  //         url: '/api/contact/public/list',
  //         data: {
  //             fields: [
  //                 { key: 'Id'},
  //                 { key: 'Name'}
  //             ],
  //             filters: data.filters,
  //             pageSize: data.pageSize,
  //             offset: data.offset,
  //             sorts: data.sorts,
  //             type: data.type
  //         }
  //     }).then((res) => {
  //         let rlt = res as orgmap.PagedItemList<orgmap.ContactItem>;
  //         let list = rlt.items;
  //         rlt.items = list.map((item) => {
  //             return {
  //                 ...item,
  //                 selected: false,
  //                 contactType: 'P'
  //             }
  //         });
  //         return rlt;
  //     });
  // },

  // /**
  //  * 공용연락처 구성원 조회
  //  * @param item 공용연락처
  //  */
  // getPublicContactsMembersList(item: orgmap.ContactItem): Promise<orgmap.PagedItemList<orgmap.ContactItem>>{
  //     return api.get({
  //         url: '/api/contact/public/member?id='+ item.id
  //     }).then((res) => {
  //         let rlt = res as orgmap.PagedItemList<orgmap.ContactItem>;
  //         let list = rlt.items;
  //         rlt.items = list.map((item) => {
  //             return {
  //                 ...item,
  //                 parentFolderId: item.id,
  //                 id: item.email,
  //                 selected: false,
  //                 photoPath: undefined,
  //                 contactType: 'C'
  //             }
  //         });
  //         rlt.items = list;

  //         return rlt;
  //     });
  // },

  /**
   * 직원코드로 정보 조회
   * @param personCode 직원코드
   */
  getPersonInfo(personCode: string) {
    return api.get({
      url: '/api/hr/department/person?personCode=' + personCode,
    })
  },
}
