import Vue from 'vue'
import { Component, Watch, Prop } from 'vue-property-decorator'
import * as dateutil from '@appbase/util/date-helper';
import { mapActions } from 'vuex';
import * as types from '@board/store/types'
import { getLangProperty } from '@appbase/util';

@Component({
    computed: {

    },

    methods: {
        ...mapActions({
            getRecommendList: types.GET_RECOMMENDS
        })
    }
})
export default class RecommendBox extends Vue {
    @Prop({ type: Number }) articleId!: number;
    @Prop({ type: Boolean }) showRecommend!: boolean;
    @Prop({ type: Boolean }) userRecommended!: boolean;

    getRecommendList!: (articleId: number) => Promise<article.RecommendSet[]>;

    recommends: article.RecommendSet[] = [];
    recommendCount: number = 0;

    @Watch('showRecommend')
    onShowChanged(newVal: boolean) {
        if (newVal) {
            this.mountRecommend();    
        }
    }

    @Watch('userRecommended')
    onRecommendChanged(newVal: boolean) {
        this.mountRecommend();
    }

    langProperty(obj: Object, propKey: string) {
        return getLangProperty(obj, propKey);
    }

    mountRecommend() {
        this.getRecommendList(this.articleId)
            .then(res => {
                console.log('recommends', res);
                this.recommends = res;
                this.recommendCount = res.length;
                this.$emit('recommendMounted', res, res.length);
            })
    }

    getRecommendDate(recommend: article.RecommendSet) {
        var date = new Date(recommend.createdDate);
        return dateutil.uGetDateFormat('yyyy-MM-DD', date);
    }
}