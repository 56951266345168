import { dispatchEventBus } from './eventBus'
import api from '@appbase/api'

export interface ShowViewerOption {
  show: boolean
  filename: string
  url: string 
}

const SHOW_DOC_VIEWER = `evtbus-show-document-viewer`
const md5 = require('md5')

/**
 * Job API는 문서를 변환하기 위한 API입니다.
 * 문서뷰어 서버에디션에서 접근 가능한 로컬 경로나, FTP, URL을 이용하여 문서를 가져오고, 변환할 수 있습 니다.
 * JSON또는 GET/POST를 통해 API 사용이 가능하며 JSON일경우 아래와 같은 형태로 지정하여 사용하면 됩니다.
 *
 * [참고] GET 방식 전달 시 Request 파라미터는 반드시 URL Encoding이 적용된 값이어야 합니다.
 */
interface RequestParam {
  /**
   * 문서 고유 유일키 설정입니다. "fid" 가 동일한 문서 원본이 서버에 존재하면 성능을 위해
   * 문서를 다시 수집하지 않습니다 "fid" 는 공백을 제외한 255자 이하의 문자열로 지정해야 합니다
   */
  fid: string
  fileType: 'Local' | 'URL' | 'FTP'
  filePath: string
  /**
   * 스킨에서 표시할 문서의 제목을 설정합니다 설정하지 않는 경우 파일명을 제목으로 사용합니다
   */
  title?: string
  /**
   * 0 : HTML 형식으로 변환합니다 1 : IMAGE 형식으로 변환합니다
   */
  convertType?: '0' | '1'
  /**
   * 원본 문서 파일 출처 표시
   *
   * ? API 문서상에서 `referUrl`와 `refererUrl`를 혼용해서 사용하고 있는데
   * ? 나중에 이 속성이 필요할 경우 확인 필요
   */
  refererUrl?: string
  /**
   * 원본 문서 파일 다운로드 URL을 지정합니다. 값이 있으면 스킨에서
   * 다운로드 버튼이 활성화됩니다.
   */
  downloadUrl?: string
  /**
   * true : 기존 변환 결과를 사용하지 않고 강제로 재변환합니다
   * false : 변환 결과가 있는 경우 재변환하지 않고 기존 변환 결과를 재사용합니다
   * @default false
   */
  force?: boolean
  /**
   * true : 변환 요청 후 변환이 완료되면 뷰어 URL을 반환합니다(응답 코드: 302)
   * false : 변환 요청 후 변환 성공과 상관 없이 JSON을 반환합니
   * @default false
   */
  sync?: boolean
  /**
   * 원본 URL의 인코딩 타입을 지정합니다.
   * UTF-8 : filePath URL의 Encoding 값이 UTF-8 인 경우 사용합니다
   * EUC-KR : filePath URL의 Encoding 값이 EUC-KR 인 경우 사용합니다
   *
   * [응답 예시]
   *  - `key` :  변환 요청에 대한 식별자입니다
   *  - `status` :  API 등 다른 API 동작 시 사용합니다
   *  - `fileName` :  변환 원본 문서의 파일명입니다 resultDirPath 서버 내 변환 결과 저장 경로입니다
   */
  urlEncoding?: 'UTF-8' | 'EUC-KR'
  /**
   * 워터마크에 표시되는 문자열을 설정합니다 관리자 설정에서 워터마크 타입을
   * 'TEXT' 로 설정할 때만 사용 가능합니다
   */
  watermarkText?: string
  /**
   * 변환 요청 시, 인증에 대한 Token 값을 설정합니다 관리자 설정에서 API Key
   * 사용 설정했을 때만 동작합니다
   */
  apiToken?: string
  /**
   * Base64인코딩된 JSON형태의 쿠 키 값
   * fileType이 URL 형식으로 지정된 경우에만 동작합니다 원본 파일 URL 인증 시
   * 사용할 포함할 쿠키 데이터를 설정합니다 POST 방식에서만 지원됩니다
   */
  accessCookieData?: string
  /**
   * HTML 문서의 자바스크립트 코드 실행 여부를 설정합니다
   * true : 자바스크립트를 실행한 후 변환합니다
   * false : 자바스크립트를 실행하지 않고 변환합니다
   * @default true
   */
  useJavascript?: boolean
  /**
   * 변환 시 사용할 언어 정보를 설정합니다 문서 변환 시 참조하여 문서를 분석하고
   * 변환합니다 언어에 따라 한자 모양이 달라질 수 있습니다 설정에 따라 스킨 UI의
   * 언어가 변경됩니다 값이 없는 경우 관리자에서 설정한 기본값을 사용합니다
   */
  convertLocale?: 'ko_KR' | 'en_US' | 'ja_JP' | 'zh_CN'
  /**
   * 변환할 HTML의 페이지 가로 크기를 설정합니다 크기는 정수이고 단위는 px 입니다
   * 설정하지 않으면 기본값인 -1을 사용합니다
   * @default -1
   */
  htmlWidth?: number
  /**
   * 변환할 HTML의 페이지 가로 크기를 설정합니다 크기는 정수이고 단위는 px 입니다
   * 설정하지 않으면 기본값인 -1을 사용합니다
   * @default -1
   */
  htmlWait?: number
}

/**
 * 뷰어 접근 제한이 활성화된 경우 뷰어 접근 인증 쿠키가 응답에 포함됩니다.
 * 뷰어 접근 제한이 활성화된 경우는 이후 API 사용 시 해당 쿠키 정보를 Request에 포함해야 합니다.
 * sync 파라미터가 true 로 설정된 경우 302 응답을 통해 뷰어가 자동으로 실행됩니다. .. code-block:
 * HTTP/1.1 302 Location: http://{Server Address}/SynapDocViewServer/viewer/doc.html?
 *                        key={key}&contextPath=/SynapDocViewServer
 */
class DocumentViewerHelper {
  /**
   * 오픈하려는 문서를 문서뷰어와 연동하여 로드합니다.
   * @param filename 파일이름
   * @param url 다운로드 가능한 파일의 URL
   */
  async open(filename: string, url: string, useEventBus: boolean): Promise<void> {
    const host = location.host.indexOf('localhost') > -1 || location.host == 'zone.zinus.com' ? 'https://docviewer.zinus.com:8445' : 'https://docviewer.zinus.com:8445';
    const szServiceUrl = host + '/SynapDocViewServer/job';

    const szFileId = md5(filename + url);

    if (url.startsWith('http://') || url.startsWith('https://')) {
      url = url
    } else if (url.startsWith('/')) {
      // url = `${location.protocol}//${location.host}${url}`
      url = `https://localhost:8447${url}`
    } else {
      url = `https://localhost:8447/${url}`
    }

    const options: { [key: string]: any } = <RequestParam>{
      fid: szFileId,
      filePath: url,
      convertType: '1',
      fileType: 'URL',
    }

    var params: string[] = []
    Object.keys(options).forEach(function(key) {
      var value = options[key]
      if (typeof value === 'object') {
        value = JSON.stringify(value)
      }
      params.push(key + '=' + encodeURIComponent(value))
    })

    var queryString = params.join('&')
    const requestUrl = `${szServiceUrl}?${queryString}`
    // window.location.href = requestUrl;
    // window.open(requestUrl, 'DocPreview')

    var docurl = ''
    var data = {
      Headers: [{
        Key: 'ContentType',
        Value: 'application/json'
      }],
      Url: requestUrl,
      Data: null,
      Method: 'get',
      WithHeaders: false,
      IgnoreWithHeaders: null
    }
    await api.file.docViewerKeyRequest(data).then((res: any) => {
      var obj = JSON.parse(res);
      docurl = host + '/SynapDocViewServer/viewer/doc.html?key='+ obj.key + '&contextPath=/SynapDocViewServer';
    })

    if (szServiceUrl.split('//')[0] == location.protocol && useEventBus) {
      console.log('docurl', docurl)
      const payload: ShowViewerOption = {
        show: true,
        url: docurl,
        filename: filename,
      }
      dispatchEventBus(window, SHOW_DOC_VIEWER, payload)
    } else {
      window.open(docurl)
    }
  }
}

export default new DocumentViewerHelper()
