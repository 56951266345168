<template>
  <div id="wrapper">
      <ListImageSwipeWebpart :option="webPartItem" />
  </div>
</template>

<script>

import ListImageSwipeWebpart from '../ListImageSwipeWebpart/ListImageSwipeWebpart.vue'
export default {
  components: { ListImageSwipeWebpart },
  data() {
    return {
      webPartItem: {
        tabs: [
          {
            boardId: 23,
            tabTitle: this.$t('교보악사_뉴스레터').toString(),
            displayCount: 1,
            isPaging: false,
          },
        ],
      },
    };
  },
}

</script>

<style></style>
