<template>
  <div id="wrapper">
    <!-- <UserInfoWebpart /> -->
    <ListSummaryTest :option="webPartItem"/>
  </div>
</template>

<script>

import ListSummaryTest from '../ListSummaryWebpart/ListSummaryWebpart.vue'
export default {
  components: { ListSummaryTest },
  data() {
    return {
      webPartItem: {
        tabs: [
          {
            boardId:29,
            displayCount:3,
            isContentShow:false,
            isImage:false,
            isNoticeShow:false,
            isPaging:false,
            tabTitle:this.$t('W_Summary').toString()
          },
        ],
      },
    }
  }
}

</script>

<style></style>
