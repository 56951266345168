import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import PageNavigationBar from '@appbase/components/Paginate/PageNavigationBar.vue'
import api from '@attendance/api'
import * as attHelper from '@/util/attendance-helper'

@Component({
    components: {
        PageNavigationBar
    }
})
export default class AttendanceManage extends Vue {
    @Prop({ type: Array }) list!: attendance.AttendanceItem[]
    @Prop({ type: Number }) totalCount!: number
    @Prop({ type: Number }) pageNum!: number
    @Prop({ type: Number }) pageSize!: number
    @Prop({ type: Function }) changeAttendanceItems!: (items: []) => void;

    pageRange: number = 5
    checkAttendanceItems:number[] = []
    isAllChecked: boolean = false;

    setDisplayDate(date: Date){
        if (date){
            return attHelper.uSetDisplayDate(date);
        }
        else {
            return '';
        }
    }

    setDisplayTime(date: Date | null){
        if (date) {
            return attHelper.uSetDisplayTime(date);
        } else {
            return null;
        }
    }

    // Working Time 설정
    setDisplayWorkingTime(workingTime: any | null, hrTime: any | null){
        return attHelper.uSetDisplayWorkingTime(workingTime, hrTime);
    }

    setDisplayAttendanceResult(result: number | null){
        return attHelper.uDisplayAttendanceResult(result);
    }

    pageMove(pageNum : number){
        this.$emit('pageMove', pageNum)
    }

    onAllCheck(obj: any){
        this.checkAttendanceItems = [];
        if (obj.target.checked){
            this.list.forEach(item => {
                if(!(item.inDateTime!=null && item.outDateTime!=null) && !(item.hrLeave!=null && item.attendanceResult == 0))
                    this.checkAttendanceItems.push(item.attendanceId);
                this.isAllChecked = true;
            });
        }
        else{
            this.isAllChecked = false;
        }
    }

    @Watch("checkAttendanceItems")
    changeItem(){
        this.$emit("changeAttendanceItems", this.checkAttendanceItems);
    }

    @Watch('list')
    changeList(){
        this.checkAttendanceItems = [];
        this.isAllChecked = false;
    }
}