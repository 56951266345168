import Vue from 'vue'
import { Component, Prop, Watch, PropSync } from 'vue-property-decorator'

declare global {
    namespace gridView {
        export type Header = {
            displayName: string;
            rowKey: string;
            align?: string;
            width?: string;
            slotName?: string;
        }

        export type ModelItem<T extends any> = T & {
            selected?: boolean
        }
    }
}

@Component({

})
export default class GridView extends Vue {
    @Prop({ type: Array }) model!: Array<any>;
    @Prop({ type: Array }) headers!: Array<gridView.Header>;
    @Prop({ type: Boolean }) selectable!: boolean;
    @Prop({ type: Boolean }) showHeader!: boolean;
    @Prop({ type: String, default: 'grd'}) headerClass!: string;
    @Prop({ type: String }) width!: string;
    @Prop({ type: String }) height!: string;
    @Prop({ type: Boolean, default: true }) useScroll!: boolean;
    @Prop({ type: Boolean }) multiChoice!: boolean;
    @PropSync('selectedItems', { type: Array }) selectedItemsSync!: Array<any>;

    mHeaders: gridView.Header[] = [];
    mRows: Array<any> = [];
    mSelectable: boolean = false;
    mChkAll: boolean = false;
    mMultiChoice: boolean = true;
    mSelectedItems: Array<any> = [];
    mShowHeader: boolean = true;

    @Watch('model')
    onModelChanged(newVal: Array<any>, oldVal: Array<any>) {                
        if (newVal) {
            this.mountHeader();
            this.mRows = newVal.map(m => <any>{
                ...m,
                selected: m.selected ? true : false
            });

            this.mSelectedItems = this.mRows.filter(f => f.selected);

            if (this.selectedItemsSync) {
                this.selectedItemsSync = this.mSelectedItems;
            }
        }
        
        this.mChkAll = false;        
    }    

    get styleWidth() {
        if (this.width) {
            if(this.height.indexOf('%') >= 0 || this.height.indexOf('px') >= 0) {
                return this.width;
            } else {
                return `${this.width}px`
            }            
        } else {
            return '100%';
        }
    }

    get styleHeight() {
        if (this.height) {
            if(this.height.indexOf('%') >= 0) {
                return this.height;
            } else {
                return `${Number(this.height) - 43}px`
            }            
        } else {
            return 'auto';
        }
    }

    get isNoData() {
        if (this.mRows.length == 0) {
            return true;
        } else {
            return false;
        }
    }

    created() {
        if (this.showHeader) {
            this.mShowHeader = this.showHeader;
        }

        if (this.model) {
            this.mountHeader();
            this.mRows = this.model.map(m => <any>{
                ...m,
                selected: false
            });
        }
        
        this.mMultiChoice = this.multiChoice;        
    }

    getTdWidth(header: gridView.Header) {
        if (header.width) {
            if(header.width.indexOf('%') >= 0 || header.width.indexOf('px') >= 0) {
                return header.width;
            } else {
                return `${header.width}px`;
            }
        } else {
            return 'auto'
        }
    }

    getTdAlign(header: gridView.Header) {
        if (header.align) {
            return header.align;
        } else {
            return 'left'
        }
    }

    mountHeader() {
        if (this.headers) {
            this.mHeaders = this.headers;
        } else {
            if (this.model != null
                && this.model.length > 0) {
                this.mHeaders = Object
                    .keys(this.model[0])
                    .map(k => <gridView.Header>{
                        displayName: k,
                        rowKey: k
                    });
            }
        }
    }

    getRowValue(row: any, header: gridView.Header) {
        return row[header.rowKey];
    }

    isHeaderUseSlot(header: gridView.Header) {
        if (header.slotName) {
            return true;
        } else {
            return false;
        }
    }

    onCheckAllChange() {
        this.mRows.forEach(r => r.selected = this.mChkAll);
        this.mSelectedItems = this.mRows.filter(f => f.selected);

        if (this.selectedItemsSync) {
            this.selectedItemsSync = this.mSelectedItems;
        }

        this.$emit('selected', this.mSelectedItems);
    }

    onRowClick(idx: number) {
        this.mRows[idx].selected = !this.mRows[idx].selected;
        this.onRowCheckChange(idx);
    }

    onRowDblClick(idx: number) {
        this.$emit('dblclick', this.mRows[idx]);
    }

    onRowCheckChange(idx: number) {
        if (!this.mMultiChoice) {
            this.mRows.forEach((r, i) => {
                if (i != idx)
                    r.selected = false;
           })
        }

        this.mSelectedItems = this.mRows.filter(f => f.selected);

        if (this.selectedItemsSync) {
            this.selectedItemsSync = this.mSelectedItems;
        }

        this.$emit('selected', this.mSelectedItems);
    }
}