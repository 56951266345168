import { RouteConfig } from 'vue-router'
const Reserve = () => import(/* webpackChunkName: "reserve" */ '@reservedb/Index.vue')
const ReserveMaster = () => import(/* webpackChunkName: "reserve" */ '@reservedb/views/Master.vue')
const CalendarMaster = () => import(/* webpackChunkName: "reserve" */ '@reservedb/views/CalendarMaster.vue')
const PopupMaster = () => import(/* webpackChunkName: "reserve" */ '@reservedb/views/PopupMaster.vue')

const MonthView = () => import(/* webpackChunkName: "reserve" */ '@/modules/reservedb/components/MonthView/MonthViewDb.vue')
const WeekView = () => import(/* webpackChunkName: "reserve" */ '@/modules/reservedb/components/WeekView/WeekViewDb.vue')
const DayView = () => import(/* webpackChunkName: "reserve" */ '@/modules/reservedb/components/DayView/DayViewDb.vue')

//db버전에서 searchview 제거
const SearchView = () => import(/* webpackChunkName: "reserve" */ '@reservedb/components/SearchView/SearchView.vue')

const ReserveResource = () => import(/* webpackChunkName: "reserve" */ '@reservedb/components/ReserveResource/ReserveResourceDb.vue')

const ApprovalView = () => import(/* webpackChunkName: "reserve" */ '@reservedb/components/ApprovalView/ApprovalView.vue')

const routes: RouteConfig[] = [
    {
        path: '/reservedb',
        component: Reserve,
        children: [
            {
                path: '',
                component: ReserveMaster,                        
                children: [
                    {
                        path: '',
                        component: CalendarMaster,
                        redirect: 'month',
                        children: [
                            {
                                path: 'month',
                                name: 'reservedb-month',
                                component: MonthView
                            },
                            {
                                path: 'week',
                                name: 'reservedb-week',
                                component: WeekView
                            },
                            {
                                path: 'day',
                                name: 'reservedb-day',
                                component: DayView
                            },
                        ]
                    },
                    {
                        path: 'write',
                        name: 'reservedb-write',
                        component: ReserveResource,
                        props: { isPopup: false }    
                    },
                    {
                        path: 'search',
                        name: 'reservedb-search',
                        component: SearchView
                    },
                   
                ],
            },
            //바닥 템플릿이 달라서 따로 뺌 
            {
                path:"approval",
                name:"reserve-approval",
                component: ApprovalView
            }
           
        ],
    },
    {
        path: 'reserveDbPopup',
        component: PopupMaster,
        children: [
            {
                path: 'write',
                component: ReserveResource,
                props: { isPopup: true }            
            }
        ]
    }
]
export default routes
