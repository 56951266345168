import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import * as types from '@gnb/store/types';


@Component({
   name: 'MessageApproval',
   components: { },
   computed: {
        ...mapGetters({
      }),
    },
   methods: {
        ...mapActions({
        }),
        ...mapMutations({
        }),
    },
})
export default class MessageApprovalView extends Vue {

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //  

    @Prop({ type: Object }) value !: gnb.TA_MYSETTING;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //   

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M U T A T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //    

    get tureceiveNextMail() {
        return this.value.isTUReceiveNextMail;
    }

    set tureceiveNextMail(value: boolean) {
        this.value.isTUReceiveNextMail = value;
        this.$emit('update:value', this.value);
    }

    get tureceiveBeforeMail() {
        return this.value.isTUReceiveBeforeMail;
    }

    set tureceiveBeforeMail(value: boolean) {
        this.value.isTUReceiveBeforeMail = value;
        this.$emit('update:value', this.value);
    }

    get tureceiveAfterMail() {
        return this.value.isTUReceiveAfterMail;
    }

    set tureceiveAfterMail(value: boolean) {
        this.value.isTUReceiveAfterMail = value;
        this.$emit('update:value', this.value);
    }

    get tureceiveRefMail() {
        return this.value.isTUReceiveRefMail;
    }

    set tureceiveRefMail(value: boolean) {
        this.value.isTUReceiveRefMail = value;
        this.$emit('update:value', this.value);
    }

    get tureceiveCompleteMail() {
        return this.value.isTUReceiveCompleteMail;
    }

    set tureceiveCompleteMail(value: boolean) {
        this.value.isTUReceiveCompleteMail = value;
        this.$emit('update:value', this.value);
    }

    get tureceiveCompleteApproveMail() {
        return this.value.isTUReceiveCompleteApproveMail;
    }

    set tureceiveCompleteApproveMail(value: boolean) {
        this.value.isTUReceiveCompleteApproveMail = value;
        this.$emit('update:value', this.value);
    }

    get tureceiveApproveMail() {
        return this.value.isTUReceiveApproveMail;
    }

    set tureceiveApproveMail(value: boolean) {
        this.value.isTUReceiveApproveMail = value;
        this.$emit('update:value', this.value);
    }


    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    mounted() {
        
    }

    created() {
        console.log(this.value);
    }
}