<template>
  <div class="wpart" style="height: 300px">
    <!--템플릿 :: 아무속성 없음-->
    <div class="wpart-templale3">
      <UserProfileWebpart />
    </div>
  </div>
</template>

<script>
import UserProfileWebpart from '../UserProfileWebpart/UserProfileWebpart.vue'
export default {
  components: { UserProfileWebpart },
}
</script>

<style></style>
