const BASE = '$survey'

export const SURVEY_LIST = `${BASE}/surveyList`
export const SURVEY_LIST_TOTALCOUNT = `${BASE}/surveyListTotalCount`
export const SURVEY_CREATED_SURVEYID = `${BASE}/createdSurveyId`
// export const SURVEY_MAIN_STEP01 = `${BASE}/surveys01`
// export const SURVEY_MAIN_STEP02 = `${BASE}/surveys01`


export const SURVEY_RESORTING_QUESTION = `${BASE}/surveyQuestionResorting`
export const SURVEY_QUESTION = `${BASE}/surveyQuestion`
export const SURVEY_EXAMPLE = `${BASE}/surveyExample`

//질문
export const SURVEY_INIT_QUESTION = `${BASE}/surveyQuestionInit`
export const SURVEY_ADD_QUESTION = `${BASE}/surveyQuestionAdd`
export const SURVEY_UPDATE_QUESTION = `${BASE}/surveyQuestionUpdate`
export const SURVEY_DELETE_QUESTION = `${BASE}/surveyQuestionDelete`
export const SURVEY_COPY_QUESTION = `${BASE}/surveyQuestionCopy`


//보기
export const SURVEY_ADD_EXAMPLE = `${BASE}/surveyExampleAdd`
export const SURVEY_UPDATE_EXAMPLE = `${BASE}/surveyExampleUpdate`
export const SURVEY_DELETE_EXAMPLE = `${BASE}/surveyExampleDelete`
export const SURVEY_RESORTING_EXAMPLE = `${BASE}/surveyExampleResorting`

//응답
export const SURVEY_ANSWER = `${BASE}/surveyAnswers`
export const SURVEY_DELETE_ALL_ANSWER = `${BASE}/surveyDeleteAllAnswers`
export const SURVEY_DELETE_ANSWER = `${BASE}/surveyDeleteAnswers`



//통계
export const SURVEY_STATISTICS = `${BASE}/surveyStatistics`

//메뉴권한
export const SURVEY_MENU_PERMISSIONS = `${BASE}/surveyMenuPermissions`
export const SURVEY_ADD_MENU_PERMISSIONS = `${BASE}/surveyAddMenuPermissions`
export const SURVEY_UPDATE_MENU_PERMISSIONS = `${BASE}/surveyUpdateMenuPermissions`
