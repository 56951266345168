import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {ListHeaderRight} from './index';

@Component({
    components: {
        ListHeaderRight
    }
})
export default class ListTabHeader extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    @Prop({type: Array}) headers!: any;
    @Prop({type: Boolean, default: true}) pagingShow?: boolean;
    @Prop({type: Boolean, default: true}) refreshShow?: boolean;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    activeIndex: number = 0;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    TitleClick(index: number, boardId: number){
        if(this.activeIndex != index){
            this.activeIndex = index;
            this.$emit('TabClick', boardId);
        }
    }
    More(boardId: number){        
        this.$router.push(`/Board?SYSID=${boardId}`);
    }
    Prev(){
        this.$emit('Prev');
    }
    Next(){
        this.$emit('Next');
    }
    Refresh(){
        this.$emit('Refresh');
    }
}

