import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as dateutil from '../../util/date-helper'
import * as cutil from '../../util/component-helper'

// components

@Component({
  name: "DatePicker",
})
export default class DatePicker extends Vue {
  @Prop({ type: Number, default: 20 }) YearMinMax!: Number;
  @Prop({
    type: String,
    default: dateutil.uGetDateFormat("yyyy-MM-DD", new Date()),
  })
  value!: string;
  @Prop({ type: String }) placeholder!: string;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: true }) isShowInput!: boolean;
  @Prop({ type: String, default: "full" }) viewMode!:
    | "full"
    | "year"
    | "yearmonth";
  @Prop({ type: Boolean, default: true }) isAllowPlainText!: boolean;

  MonthMatrix: Array<Array<any>> = [];

  YearArray: Array<number> = [];
  MonthArray: Array<number> = [];

  SelectItems: Array<Date> = [];

  baseValue: string | null = "";

  isShowPicker: boolean = false;
  isInMouse: boolean = false;

  posX: number = 0;
  posY: number = 0;

  strToday: string = "오늘";

  renderflag: boolean = true;

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  get WeekList() {
    let weekarray = [
      this.$t("일").toString(),
      this.$t("월").toString(),
      this.$t("화").toString(),
      this.$t("수").toString(),
      this.$t("목").toString(),
      this.$t("금").toString(),
      this.$t("토").toString(),
    ];
    return weekarray;
  }
  get YearDateFormat() {
    let strYear = this.$t("년").toString();
    return dateutil.uGetDateFormat("yyyy" + strYear, this.dateValue);
  }
  get MonthDateFormat() {
    let strMonth = this.$t("월").toString();
    return dateutil.uGetDateFormat("MM" + strMonth, this.dateValue);
  }

  get dateValue(): Date {
    return dateutil.IEDate(this.baseValue);
  }

  toggleShowHide() {
    this.isShowPicker = !this.isShowPicker;

    if (this.isShowPicker) {
      (window as any).PickerCollection.forEach((x: DatePicker) => {
        if (x != this) {
          x.hidePicker();
        }
      });
    }
  }

  setPosition(event: any) {
    let x: number = -(event.srcElement.clientWidth / 2);
    let y: number = event.offsetY - event.srcElement.clientHeight / 2;

    this.posX = x;
    this.posY = y;
  }

  //년도 클릭 시 년도 선택 할 수 있게 출력
  setShowYears() {
    let yearArray = [];
    let date = new Date();
    let currentYear = date.getFullYear();
    let yearMinMax = this.YearMinMax as any;
    let min = currentYear - yearMinMax;
    let max = currentYear + yearMinMax;

    for (let year = min; year < max; year++) {
      yearArray.push(year);
    }

    this.YearArray = yearArray;
  }
  //월 클릭 시 월 선택 할 수 있게 출력
  setShowMonths() {
    let monthArray = [];
    let date = new Date();
    for (let month = 1; month <= 12; month++) {
      monthArray.push(month);
    }
    this.MonthArray = monthArray;
  }
  // 월 날짜 출력
  setMonthMatrix() {
    let datePickerMatrix = [];
    let Rows = [];
    let today = new Date();
    let daysList = dateutil.uGetDaysInMonth(this.dateValue);

    for (let i = 0; i < daysList.length; i++) {
      let date = daysList[i];

      let isToday =
        date.getFullYear() == today.getFullYear() &&
        date.getMonth() == today.getMonth() &&
        date.getDate() == today.getDate();

      let sDate = dateutil.uGetDateFormat("yyyy-MM-DD", date);
      let cDate = dateutil.uGetDateFormat(
        "yyyy-MM-DD",
        dateutil.IEDate(this.baseValue)
      );

      let dateItem = {
        date: date,
        selected: sDate == cDate,
        isToday: isToday,
        isSun: date.getDay() == 0,
        isSat: date.getDay() == 6,
        isOut: date.getMonth() != dateutil.IEDate(this.baseValue).getMonth(),
      };

      Rows.push(dateItem);

      if ((i + 1) % 7 == 0) {
        let NewRows = new Array();
        NewRows = Object.assign(NewRows, Rows);
        datePickerMatrix.push(NewRows);
        Rows = [];
      }
    }

    this.MonthMatrix = datePickerMatrix;
  }
  // '오늘' 버튼 클릭 시 해당 날짜 바인딩
  setToday() {
    let date = new Date();
    this.baseValue = dateutil.uGetDateFormat("yyyy-MM-DD", date);
    this.$emit("select", date);
    this.renderPicker();
    this.toggleShowHide();
  }
  // 선택한 년도로 가져오기
  setYear(event: any) {
    let selValue: number = Number(
      event.srcElement.options[event.srcElement.selectedIndex].value
    );
    let copyDate = new Date(
      this.baseValue == null ? 0 : (this.baseValue as string)
    );
    copyDate.setFullYear(selValue);
    this.baseValue = dateutil.uGetDateFormat("yyyy-MM-DD", copyDate);
    this.$emit("inputYear", selValue, copyDate);
    this.renderPicker();
  }
  // 월 증가/감소
  setMonthByCount(count: number) {
    let copyDate = new Date(
      this.baseValue == null ? 0 : (this.baseValue as string)
    );
    let selValue: number = Number(copyDate.getMonth() + 1 + count);
    copyDate.setMonth(selValue - 1);
    this.baseValue = dateutil.uGetDateFormat("yyyy-MM-DD", copyDate);
    this.$emit("inputMonth", selValue, copyDate);
    this.renderPicker();
  }
  // 선택한 월로 가져오기
  setMonth(event: any) {
    let selValue: number = Number(
      event.srcElement.options[event.srcElement.selectedIndex].value
    );
    let copyDate = new Date(
      this.baseValue == null ? 0 : (this.baseValue as string)
    );
    copyDate.setMonth(selValue - 1);
    this.baseValue = dateutil.uGetDateFormat("yyyy-MM-DD", copyDate);
    //this.value = copyDate;
    this.$emit("inputMonth", selValue, copyDate);
    this.renderPicker();
  }
  renderPicker() {
    this.renderflag = false;
    const _this = this;
    setTimeout(() => {
      let newvalue = dateutil.IEDate(_this.baseValue);
      if (!isNaN(Number(newvalue))) {
        _this.setShowYears();
        _this.setShowMonths();
        _this.setMonthMatrix();
      }
      _this.renderflag = true;
    }, 1);
   
  }

  selectDate(date: Date) {
    this.baseValue = dateutil.uGetDateFormat("yyyy-MM-DD", date);
    this.$emit("select", date);
    //this.value = date;
    this.renderPicker();
    this.toggleShowHide();
  }

  togglePicker(e: Event) {
    if (this.disabled) return true;

    if (!Date.parse(this.baseValue!.toString())) {
      this.baseValue = dateutil.uGetDateFormat("yyyy-MM-DD", new Date());
    }

    this.isShowPicker = !this.isShowPicker;
    var _this = this;
    setTimeout(function () {
      if (_this.isShowPicker) {
        let $this = cutil.findParent(
          e.srcElement as HTMLElement,
          ".calendar-wrap"
        );
        if ($this) {
          let $parent = cutil.getScrollParent($this as HTMLElement, false);
          if ($parent) {
            if (
              $this.offsetTop + $this.clientHeight + 100 >
                $parent.clientHeight + $parent.scrollTop &&
              $parent.clientHeight < $parent.scrollHeight
            ) {
              $parent.scroll($parent.scrollLeft, $this.offsetTop - 60);
            }
          }
        }
      }
    }, 0);
  }

  @Watch("value")
  onValueChanged(value: string | null, oldValue: string | null) {
    let newvalue = dateutil.IEDate(value);
    if (!isNaN(Number(newvalue))) {
      this.baseValue = value;
      this.$emit("change", this, value, oldValue);
    }
  }

  @Watch("baseValue")
  onBaseValueChanged(value: string) {
    if (value) {
      value = dateutil.DateAndTimeSplitor(value)[0];
    }
    this.$emit("input", value);
    this.renderPicker();
  }

  mounted() {
    this.renderPicker();
  }

  hidePicker() {
    this.isShowPicker = false;
  }

  created() {
    //필드에서 초기화도 안되있는 상태에서 값을 정할라그러네.. 하..
    //created 함수로 이동
    this.baseValue = this.isAllowPlainText
      ? this.value
      : dateutil.uGetDateFormat("yyyy-MM-DD", dateutil.IEDate(this.value));

    //다국어 오늘 적용
    this.strToday = this.$t("W_Schedule_Today").toString();

    if (!(window as any).PickerCollection) {
      (window as any).PickerCollection = [];
      (window as Window).addEventListener(
        "click",
        (e) => {
          let elem: HTMLElement = e.srcElement as HTMLElement;
          let isContain: boolean =
            cutil.findParent(elem, ".calendar-wrap") != null;

          if (!isContain) {
            (window as any).PickerCollection.forEach((x: DatePicker) => {
              x.hidePicker();
            });
          }
        },
        false
      );
    }

    (window as any).PickerCollection.push(this);
  }
}
