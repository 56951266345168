import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@gnb/store/types';
import api from '@gnb/api/common'

@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        Dropdown
    },
    methods: {
        ...mapActions({
        timeLoad: types.TIME_LOAD,
        timeChange: types.LANGUAGE_TIME_CHANGE,
        loading: types.COMMON_LOADDING
        }),
    },
    computed: {
        ...mapGetters({
            time: types.TIME_LOAD,
        })
    }
})
export default class Time extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    
    @Prop({ type: Object }) user!: account.User;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    selectedTime: string = ''
    name: string = this.$t('W_Settings_CurrentTime').toString();

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    loading!: (option: boolean) => void;
    timeLoad!: () => void;
    timeChange!: (item: {personCode:string, timeZone:string}) => Promise<void>;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    time!: gnb.TimeFields;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    select(selectedItem: string){
        this.selectedTime = selectedItem;
    }

    save() {
        this.timeChange({
            personCode: this.user.personCode, 
            timeZone: this.selectedTime}).then(async ()=>{
                await (window as any).alertAsync(this.$t('M_Expense_SavedAlert').toString())
            }).then(()=>{
                this.$emit('cancel')
            })

    }

    getUserTime(){
        api.getUserConfig(this.user.personCode).then((res)=>{
            this.selectedTime = res[0].timeZone;
            if(this.selectedTime){
                this.loading(false);
            }
        });
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    created(){
        this.timeLoad();
        this.getUserTime();
      
    }
}