import { render, staticRenderFns } from "./InboxWebpart.vue?vue&type=template&id=3b7d6882"
import script from "./InboxWebpart.ts?vue&type=script&lang=ts&external"
export * from "./InboxWebpart.ts?vue&type=script&lang=ts&external"
import style0 from "./InboxWebpart.vue?vue&type=style&index=0&id=3b7d6882&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports