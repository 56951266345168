import api from '@/api'
import * as dateutil from '@appbase/util/date-helper';

interface ResponseWrapper<TResult> {
  result: TResult
  code: string
  message: string
  error: string | null
}

export interface CalendarEvent {
  id:               string;
  parentFolderId:   string;
  appointmentType:  string;
  subject:          string;
  location:         null;
  start:            Date;
  end:              Date;
  importance:       string;
  organizer:        Organizer;
  isAllDayEvent:    boolean;
  isCancelled:      boolean;
  isRecurring:      boolean;
  isFromMe:         boolean;
  displayTo:        string;
  displayCc:        null;
  scheduleDuration: string;
  iCalRecurrenceId: Date;
  hasAttachments:   boolean;
}

export interface Organizer {
  address: string;
  name:    string;
}

const convertAppointmentTypeToClient = function(value: number) {
	switch (value) {
		case 1: return 'Single';
		case 2: return 'RecurringMaster';
		case 3: return 'Occurrence';
		case 4: return 'Exception';
	}
	return 'Single';
}

const convertEventToClient = function(item: any):CalendarEvent {
  let event: CalendarEvent = {
      ...item,
      id: item.id || item.ID,               
      parentFolderId: item.parentFolderID || item.ParentFolderID,
      appointmentType: convertAppointmentTypeToClient(item.appointmentType || item.AppointmentType),
      subject: item.subject || item.Subject,
      location: item.location || item.Location,
      start: ((item.startDate || item.StartDate) + '').replace('T', ' '),
      end: ((item.endDate || item.EndDate) + '').replace('T', ' '),
      importance: item.importance || item.Importance,
      organizer: {
        name: item.organizerName || item.OrganizerName,
        address: item.organizerAddress || item.OrganizerAddress,
      },
      isAllDayEvent: (item.isAllDayEvent || item.IsAllDayEvent) ? true : false,
      isCancelled: item.isCancelled || item.IsCancelled,
      isRecurring: 'False',
      isFromMe: 'False',
      displayTo: '',       
      displayCc: '',       
      scheduleDuration: '',
      iCalRecurrenceId: null,
      hasAttachments: item.isAttachment || item.IsAttachment
    } as CalendarEvent;
  return event;
}

export default {
  //지누스 법인게시판 정보 조회
  findCoperationBoardInfo(): Promise<Array<webparts.CoperationBoard>> {
    return api.get({
      url: `/api/z/boardproxy`
    })
  },




  // 테스트용
  getBoardContents(boardId: number, displayCount: number, pageNumber: number) {
    return api.get({
      url: `/Common/Webpart/api/boardContents?boardId=${boardId}&displayCount=${displayCount}&pageNumber=${pageNumber}`,
    })
  },

  getArticles(
    boardId: number,
    displayCount: number,
    pageNumber: number,
    includeContent: boolean,
    useNotice: boolean
  ) {
    // 공지 표시시 UseNotice true, SortField = 4, SortOrder = 0
    // 공지 미표시시 UseNotice false SortField = 4 SortOrder = 0 THEN
    return api.post({
      url: '/api/BoardApi/Article/getArticles',
      data: {
        BoardId: boardId,
        IncludeContent: false,
        UseNotice: true,
        Page: pageNumber,
        ItemsPerPage: displayCount,
        SortField: 0,
        SortOrder: false,
      },
    })
  },

  getComNoticeWebpartList(itemCount: number){
    return api.get({
      url: `/api/Z/BoardProxy/comnoticewebpart?ListItemCount=${itemCount}`
    })
  },

  // 테스트용
  getEvents(comCode: string, personCode: string, month: number) {
    return api.get({
      url: `/Common/Webpart/api/Events?comCode=${comCode}&personCode=${personCode}&month=${month}`,
    })
  },

  // AWS 데이터 가져오기
  getEWSData<TResult>(request: awsRequest.request): Promise<TResult> {
    //, listRequest: awsRequest.request){
    return api.post<TResult>({
      url: '/api/ews/Dynamic/list',
      data: request,
    })
  },

  // 전자결재 데이터 가져오기
  getAPMList(documentQueryNumber: number, personCode: string, startDate: string, endDate: string) {
    return api.post({
      url: '/workflow/document/api/list',
      data: {
        documentQueryNumber: Number(documentQueryNumber),
        itemsPerPage: 20,
        page: 1,
        personCode: personCode,
        searchType: -1,
        sortValue: 2,
        startDate: startDate,
        endDate: endDate,
      },
    })
  },

  // 나의 전자결재 가져오기
  getMyAPM(personCode: string, deptCode: string): Promise<any> {
    return api.get({
      url: `/workflow/document/api/count?personCode=${personCode}&deptCode=${deptCode}`,
    })
  },

  // 게시판 대표 이미지 가져오기
  getBoardDefaultImage(boardId: number) {
    return api.get({
      url: `/CommonWebpartBoard/WebpartBoard/api/defaultImage/${boardId}`,
    })
  },

  // 메일 주소로 사진 가져오기(미사용)
  getPhotoByAddress(address: string) {
    return api.get({
      url: `/api/account/photo/${address}`,
    })
  },

  // 설문조사리스트 가져오기
  getSurveyLists(params: any) {
    return api.get({
      url: `/commonsurvey/survey/api/Lists?SurveyStatus=${params.surveyStatus}&RowCount=${params.rowCount}&PageNumber=${params.pageNumber}&MenuId=${params.menuId}`,
    })
  },

  getCalendarEvents(request: webparts.calendarRequlest) {
    return api.post<CalendarEvent[]>({
        url: '/api/ews/Calendar/list?funcName=getCalendarEventListWithRecurrence ',
        data: request
    });
  },

  getCalendarEventList(item: any) {
    return api.post<CalendarEvent[]>({
        url: '/api/calendar/Calendar/getevents?funcName=getCalendarEventList',
        data: {
            FolderID: item.FolderID,
            StartDate: dateutil.uGetDateFormat('yyyy-MM-DDT00:00:00', item.StartDate),
            EndDate: dateutil.uGetDateFormat('yyyy-MM-DDT00:00:00', item.EndDate),
        }
    }).then((res) => {
        return res.map((data) => {
            let newItem = convertEventToClient(data);
            let result = {
                ...newItem                    
            };
            return result;
        });
    });;
  },

  getWebpartItems(comCode: string, webpartType: string): Promise<Array<webparts.WebpartItem>> {
    return api.get({
      url: `/api/Z/homewebpart/webpartitems?ComCode=${comCode}&WebpartType=${webpartType}`
    });
  },
}