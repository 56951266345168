import client from '@/api'

export default {
  login(email: string, password: string) {
    return client.post({
      url: '/api/Account/authjwt/login',
      data: { email, password },
    })
  },
}
