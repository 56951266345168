import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ModalLayout } from '@appbase/templates'
import * as dateutil from '@appbase/util/date-helper'
import api from '@attendance/api'
import apiHr from '@hr/api';
import * as attHelper from '@/util/attendance-helper'
import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import * as accountTypes from '@account/store/types'
import { createEventBus, removeEventBus } from '@appbase/util/eventBus';
import { openPopup } from '@appbase/util';

export interface TWishContainerOptionItem {
    id: number
    name: string
    height: string
    items? : orgmap.TWishContainerItem[]
}

export interface TWishContainerOptions {
    addableDept: boolean,
    containers: TWishContainerOptionItem[]
}

export interface TWishItemPropsOptions {
    props: string[]
}

const ATTENDANCE_ORG_POP = '$attendancemanage/org/popup'

@Component({
    components: {
        ModalLayout,
        DatePicker
    },
    computed: mapGetters({
        user: accountTypes.USER
   })
})
export default class AttendanceDetailModalView extends Vue {
    @Prop({ type: Boolean }) show!: boolean
    @Prop({ type: Object }) value!: attendance.AttendanceItem

    user!: account.TUser;

    attendanceDate: string = ''
    staggeredHours: any = null
    inTime: any = null
    inApplyTime: any = null
    outDate: any = null
    outTime: any = null
    outApplyTime: any = null
    workingTime: string = ''
    etc: string | null = null
    attendanceResult: string = ''

    codes: Array<any> = [];
    attendanceResults: Array<any> = [];
    workingTimeHour: number = 8;
    workingTimeMinute: number = 0;
    hrLeaveTimeHour: number = 0;
    hrLeaveMinute: number = 0;
    inDate: any = null;

    close(reload:boolean) {
        this.$emit('close', reload);
    }

    onChangeTime(obj: any){
        var hrLeave = this.value.hrLeaveTime?.value.hours??0
        // 근무시간 계산
        var iTime = dateutil.IEDate(this.attendanceDate + ' ' + this.inApplyTime)
        // var oTime = dateutil.IEDate(this.attendanceDate + ' ' + this.outApplyTime)
        var oTime = dateutil.IEDate(this.outDate + ' ' + this.outApplyTime)
        var checkMinute = dateutil.uGetTotalDiffMinutes(iTime, oTime)
        checkMinute = checkMinute - (hrLeave<4?60:0)    // 기본 근무 차감시간 적용
        checkMinute += hrLeave*60
        // 근무시간이 마이너스인경우 0으로 처리
        var hour = (checkMinute > 0 ? Math.floor(checkMinute / 60) : 0);
        var minute = (checkMinute > 0 ? (checkMinute % 60) : 0);

        this.workingTime = ((hour > 9 ? hour : `0${hour}`) + ":" + (minute > 9 ? minute : `0${minute}`))
    }

    // 신규 데이터 생성
    async save(){        
        // 유효성 검사
        // 1. 날짜 입력 여부 확인
        if (!this.inDate || this.inDate == '')
        {
            await (window as any).alertAsync("근태일자를 입력하세요.");
            return;
        }        
        
        // 2. 사용자 선택 여부
        if (this.value.personCode == ''){
            await (window as any).alertAsync('선택된 사용자가 없습니다.');
            return;
        }

        if (await(window as any).confirmAsync(this.$t('저장하시겠습니까?').toString())){
            var workingTime = {
                hours: Math.trunc((this.workingTimeMinute / 60)),
                minutes: (this.workingTimeMinute % 60)
            }

            var data: attendance.RequestAttendanceAdminCreate = {
                attendanceDate: this.inDate,
                personCode: this.value.personCode,
                displayName: this.value.displayName,
                displayDeptName: this.value.displayDeptName,
                displayTitleName: this.value.displayTitleName,
                staggeredTime: this.staggeredHours,
                inDateTime: this.inTime ? dateutil.IEDate(this.attendanceDate + ' ' + this.inTime) : null,
                inApplyDateTime: this.inApplyTime ? dateutil.IEDate(this.attendanceDate + ' ' + this.inApplyTime) : null,
                outDateTime: this.outTime && this.outDate ? dateutil.IEDate(this.outDate + ' ' + this.outTime) : null,
                outApplyDateTime: this.outApplyTime && this.outDate ? dateutil.IEDate(this.outDate + ' ' + this.outApplyTime) : null,
                workingTime: `${(this.workingTimeHour > 9 ? this.workingTimeHour : '0' + this.workingTimeHour)}:${(this.workingTimeMinute > 9 ? this.workingTimeMinute : '0' + this.workingTimeMinute)}`,
                etc: (this.etc ? this.etc : ''),
                hrLeaveCode: this.value.hrLeaveCode,
                hrLeave: (this.value.hrLeaveCode ? this.codes.find(x => x.id == this.value.hrLeaveCode).name : ''),
                hrLeaveTime: `${this.hrLeaveTimeHour > 9 ? this.hrLeaveTimeHour : '0' + this.hrLeaveTimeHour}:${this.hrLeaveMinute > 9 ? this.hrLeaveMinute : '0' + this.hrLeaveMinute}`,
                attendanceResult: this.value.attendanceResult
            }

            api.attendance.createAttendance(data).then(async (res) => {
                var message = (res ? this.$t("저장_하였습니다").toString() : this.$t("저장에_실패_하였습니다").toString());
                await (window as any).alertAsync(message);
                this.close(res);
            });
        }
    }

    async update() {
        if (await(window as any).confirmAsync(this.$t("수정하시겠습니까").toString())){
            var calcWorkingHour = Number(this.workingTime.split(':')[0]??0) - Number(this.value.hrLeaveTime?.value.hours??0)
            var data: attendance.RequestAttendanceUpdate = {
                attendanceId : this.value.attendanceId,
                staggeredTime: this.staggeredHours,
                inDateTime: this.inTime ? dateutil.IEDate(this.attendanceDate + ' ' + this.inTime) : null,
                inApplyDateTime: this.inApplyTime ? dateutil.IEDate(this.attendanceDate + ' ' + this.inApplyTime) : null,
                // outDateTime: this.outTime ? dateutil.IEDate(this.attendanceDate + ' ' + this.outTime) : null,
                // outApplyDateTime: this.outApplyTime ? dateutil.IEDate(this.attendanceDate + ' ' + this.outApplyTime) : null,
                outDateTime: this.outTime && this.outDate ? dateutil.IEDate(this.outDate + ' ' + this.outTime) : null,
                outApplyDateTime: this.outApplyTime && this.outDate ? dateutil.IEDate(this.outDate + ' ' + this.outApplyTime) : null,
                workingTime: `${calcWorkingHour}:${this.workingTime.split(':')[1]}`,
                etc: this.etc,

            }
    
            api.attendance.updateAttendance(data).then(async res   => {
                var message = '';
                message = (res ? this.$t("수정_하였습니다").toString() : this.$t("수정에_실패_하였습니다").toString());

                await (window as any).alertAsync(message);
                this.close(res);    
            });
        }
    }

    // 삭제
    async onDelete() {
        if (await(window as any).confirmAsync(this.$t("삭제_하시겠습니까").toString())){
            api.attendance.deleteAttendance(this.value.personCode, this.value.attendanceId).then(async res => {
                if (res > -1){
                    await (window as any).alertAsync(this.$t("삭제_하였습니다"));
                    this.close(true);
                }
                else{
                    await (window as any).alertAsync(this.$t('삭제에_실패하였습니다').toString());
                }
            });
        }
    }

    async onReset(){
        if (await(window as any).confirmAsync(this.$t('근태_초기화').toString())){
            api.attendance.resetAttendance(this.value.attendanceId, this.value.personCode, this.user.personCode).then(async res => {
                if (res > -1){
                    await (window as any).alertAsync(this.$t("수정_하였습니다").toString());
                    this.close(true);
                }
                else{
                    await (window as any).alertAsync(this.$t("수정에_실패_하였습니다").toString());
                }
            });
        }
    }
    
    setDisplayDate(date: Date){
        if (date){
            return attHelper.uSetDisplayDate(date);
        }
        else{
            return "";
        }
    }

    setDisplayTime(date: Date | null){
        if (date) {
            return attHelper.uSetDisplayTime(date);
        } else {
            return null;
        }
    }

    setDisplayHours(hours: number | null){
        if (hours) {
            return (hours.toString().length == 1 ? '0' : '') + hours + ':00';
        } else {
            return null;
        }
    }

    setDisplayWorkingTime(workingTime: any | null, hrTime: any | null){
        return attHelper.uSetDisplayWorkingTime(workingTime, hrTime);
    }

    onOpenOrg(){
        const orgmapOption: TWishContainerOptions = {
            addableDept: false,
            containers: [
               { id: 0, name: this.$t('사용자').toString(), height: '1' }               
            ] 
        }

        const props: TWishItemPropsOptions = {
            props: ['id', 'name', 'personCode', 'deptName', 'deptCode', 'title'],
        }

        openPopup('reserveRecipients', '/orgmap', 
            { event: `${ATTENDANCE_ORG_POP}`, option: orgmapOption, props }, 
            { width: 1024, height: 768 }
        );
    }

    @Watch('value')
    onChangedValue(value: attendance.AttendanceItem) {
        this.attendanceDate = this.setDisplayDate(this.value.attendanceDate)
        this.staggeredHours = this.setDisplayHours(this.value.staggeredTime.hours)
        this.inTime = this.setDisplayTime(value.inDateTime)
        this.inApplyTime = this.setDisplayTime(value.inApplyDateTime)
        this.outDate = this.setDisplayDate(value.outApplyDateTime)
        this.outTime = this.setDisplayTime(value.outDateTime)
        this.outApplyTime = this.setDisplayTime(value.outApplyDateTime)
        this.workingTime = this.setDisplayWorkingTime(this.value.workingTime, this.value.hrLeaveTime)
        this.etc = this.value.etc
        this.attendanceResult = attHelper.uDisplayAttendanceResult(this.value.attendanceResult)
        this.inDate = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(new Date()));
    }

    handlePopupResult(data: any){
        if (data && data.length > 0){
            var orgDataResult: any[] = data[0].items;
            if (orgDataResult && orgDataResult.length > 0){
                this.value.personCode = orgDataResult[0].personCode;
                this.value.displayName = orgDataResult[0].name;
                this.value.displayDeptName = orgDataResult[0].deptName;
                this.value.displayTitleName = orgDataResult[0].title;
            }
        }    
    }

    beforeDestroy() {
        removeEventBus(window, `${ATTENDANCE_ORG_POP}`, this.handlePopupResult);
    }

    created() {
        this.onChangedValue(this.value)
        createEventBus(window, `${ATTENDANCE_ORG_POP}`, this.handlePopupResult);
    
        // 판정
        this.attendanceResults.push({ code: 0, value: this.$t('정상').toString() });
        this.attendanceResults.push({ code: 1, value: this.$t('지각').toString() });
        this.attendanceResults.push({ code: 2, value: this.$t('출근이상').toString() });
        this.attendanceResults.push({ code: 3, value: this.$t('퇴근이상').toString() });
        this.attendanceResults.push({ code: 4, value: this.$t('근무시간_미달').toString() });
        this.attendanceResults.push({ code: 5, value: this.$t('근무시간_초과').toString() });

        // 근태 구분
        apiHr.leave.getLeaveCodes(this.user.comCode).then(res=>{
            this.codes = res as any[];
        })
    }
}
