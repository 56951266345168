import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { PersonalMemberListContainer } from '@orgmap/containers'

@Component({
    components: { PersonalMemberListContainer }
})
export default class PersonalMembersView extends Vue {

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //  

    @Prop({ type: Object, default: null }) dept!: orgmap.TDepartmentTreeItem;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //   

    id: string = ''
    deptName: string = ''
    type: string = ''

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M U T A T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    @Watch('dept')
    onCurrentDeptChanged(newValue: orgmap.TDepartmentTreeItem, oldValue: orgmap.TDepartmentTreeItem) {
        if(newValue){
            this.id = newValue.id
            this.deptName = newValue.deptName
            this.type = newValue.type || '';
        }
        else{
            this.id = '';
            this.deptName = '';
            this.type = '';
        }
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    mounted() {
        
    }

    created() {

    }

}
