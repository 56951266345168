import storage from './local-storage-helper'

type AppConfigKeys =
    // server config
    'TokenExpiresMinutes'
    |'RejectUploadFileExts';

export default (function () {
    const STORAGE_KEY = 'EUM_WORKFLOW_APP_CONFIG';
    //var config: mail.AppConfig = {};
    const defaultConfig = {
        MailSplitSize: {
            horizontal: 35,
            vertical: 35
        }
    }
    const invalidateConfig = (config: any) => {
        return {
            ...defaultConfig,
            ...config
        }; 
    }
    return {
        //set(update: common.AppConfig) {
        set(update: any) {
            let config = invalidateConfig(storage.get(STORAGE_KEY)) //as common.AppConfig
            const newConfig = {
                ...config,
                ...update
            };
            //console.log('app-config', 'set', config, newConfig);
            storage.set(STORAGE_KEY, newConfig);
        },

        get(key: AppConfigKeys): any {
            let config = invalidateConfig(storage.get(STORAGE_KEY)) //as common.AppConfig;
            return config[key];
        }
    }
})();