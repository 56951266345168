import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

export default abstract class WebpartBase extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //
  @Prop({ type: Object }) option!: appbase.cache.TWebPartItem
  @Prop({ type: Boolean, default: false }) isPreview!: boolean

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  firstRender: boolean = true
  loading: boolean = false
  testing: boolean = false

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //

  /**
   *
   * @param type
   * 1: 게시판
   * 2: 메일함
   * @returns
   */
  getLoadingData(type: number = 1) {
    var listArticles = []
    var nowDate = new Date()
    switch (type) {
      case 2:
        {
          for (var i = 0; i < this.option.displayCount!; i++) {
            listArticles.push({
              from: `사용자`,
              dateTimeCreated: nowDate,
              subject: '받은 메일함의 제목란 입니다.',
            })
          }
        }
        break
      default:
        {
          for (var i = 0; i < this.option.tabs![0]!.displayCount; i++) {
            listArticles.push({
              title: `${this.option.tabs![0].tabTitle} 게시판의 제목란 입니다.`,
              createdDate: nowDate,
              isNew: false,
            })
          }
        }
        break
    }

    return listArticles
  }
}
