import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
  ContentItem,
} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import RuleView from '@gnb/components/Preference/Mail/Rule/RuleView.vue'
import AddRule from '@gnb/components/Preference/Mail/Rule/AddRule.vue'
import api from '@gnb/api/common'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@gnb/store/types'

@Component({
  components: {
    ModalHeader,
    ModalContent,
    ModalFooter,
    ContentItem,
    PreferenceContentTemplate,
    RuleView,
    AddRule,
  },
  methods: {
    ...mapActions({
      loading: types.COMMON_LOADDING,
    }),
  },
  computed: {
    ...mapGetters({
      isLoading: types.COMMON_LOADDING,
    }),
  },
})
export default class Rule extends Vue {
  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  header: string = this.$t('W_Settings_Rule').toString()
  rules: gnb.rules.Rule[] = []
  isShow: boolean = false
  selectedRule: gnb.rules.Rule = {
    id: '',
    name: '',
    priority: 1,
    isEnabled: true,
    operation: '',
    conditions: [],
    actions: [],
    exceptions: [],
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: A C T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  loading!: (option: boolean) => void

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  isLoading!: boolean

  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: W A T C H : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Watch('isShow')
  onChangedShow() {
    if (!this.isShow) {
      this.getRules()
    }
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  addNewRule() {
    this.selectedRule = {
      id: '',
      name: '',
      priority: 1,
      isEnabled: true,
      operation: '',
      conditions: [],
      actions: [],
      exceptions: [],
    }
    this.isShow = true
  }

  selectRule(rule: gnb.rules.Rule) {
    this.isShow = true
    this.selectedRule = rule
  }

  move(from: number, to: number) {
    this.rules.forEach((r, index) => {
      if (index === from) {
        r.priority = to + 1
        r.operation = 'set'
      } else if (index === to) {
        r.priority = from + 1
        r.operation = 'set'
      }
    })
  }

  changeRules(rules: any) {
    this.rules = rules
  }
  changeIsEnable(rule: gnb.rules.Rule) {
    this.rules.forEach((r) => {
      if (r.id === rule.id) {
        r.isEnabled = rule.isEnabled
        r.operation = 'set'
      }
    })
  }

  save() {
    //this.rules.forEach((rule:gnb.rules.Rule)=>{
    // if(rule.operation == null){
    //     rule.operation = 'set';
    // }
    //})
    this.rules.map((r) => {
      if (
        r.actions.findIndex(
          (a: gnb.rules.RuleAction) => a.key === 'RedirectToRecipients'
        ) < 0
      ) {
        return r
      } else {
        var rule = r
        rule.actions = r.actions.map((action: gnb.rules.RuleAction) => {
          if (action.key === 'RedirectToRecipients') {
            return {
              ...action,
              value: [action.value[0].address],
            }
          } else {
            return action
          }
        })
      }
      return rule
    })

    this.rules.map((r) => {
      if (r.conditions.findIndex((c: any) => c.key === 'SentToAddresses') < 0) {
        return r
      } else {
        var rule = r
        rule.conditions = r.conditions.map(
          (condition: gnb.rules.RuleCondition) => {
            if (condition.key === 'SentToAddresses') {
              return {
                ...condition,
                value: condition.value.map((v: any) => {
                  return v.address
                }),
              }
            } else {
              return condition
            }
          }
        )
      }
      return rule
    })
    api
      .setRules(this.rules)
      .then(async (res: any) => {
        if (res) {
          await (window as any).alertAsync(this.$t('M_Expense_SavedAlert').toString())
          this.getRules()
          this.isShow = false
          this.$emit('cancel')
        } else {
          await (window as any).alertAsync(
            this.$t('저장되지않았습니다__규칙설정을_확인해주세요').toString()
          )
        }
      })
      .catch(async (err) => {
        await (window as any).alertAsync(this.$t('문제가 발생했습니다').toString())
      })
  }

  async saveNewRule() {
    //규칙 유효성 검사(이름,폴더)
    if (this.selectedRule.name == '' || null || undefined) {
      await (window as any).alertAsync(this.$t('규칙_이름을_지정해주세요_').toString())
      return false
    }

    var selectedFolder = this.selectedRule.actions.map((a: any) => {
      a.key === 'MoveToFolder'
    })

    if (this.selectedRule.actions.length == 0 || selectedFolder.length == 0) {
      await (window as any).alertAsync(this.$t('이동할_폴더를_지정해주세요_').toString())
      return false
    }

    this.rules.map((r) => {
      if (
        r.actions.findIndex(
          (a: gnb.rules.RuleAction) => a.key === 'RedirectToRecipients'
        ) < 0
      ) {
        return r
      } else {
        var rule = r
        rule.actions = r.actions.map((action: gnb.rules.RuleAction) => {
          if (action.key === 'RedirectToRecipients') {
            return {
              ...action,
              value: [action.value[0].address],
            }
          } else {
            return action
          }
        })
      }
      return rule
    })

    this.rules.map((r) => {
      if (
        r.conditions.findIndex(
          (c: gnb.rules.RuleCondition) => c.key === 'SentToAddresses'
        ) < 0
      ) {
        return r
      } else {
        var rule = r
        rule.conditions = r.conditions.map((condition: any) => {
          if (condition.key === 'SentToAddresses') {
            return {
              ...condition,
              value: condition.value.map((v: any) => {
                if (typeof v === 'object') {
                  return v.address
                } else {
                  return v
                }
              }),
            }
          } else {
            return condition
          }
        })
      }

      return rule
    })

    if (this.selectedRule.id) {
      this.selectedRule.conditions.map((con: gnb.rules.RuleCondition) => {
        if (
          con.key === 'ContainsSenderStrings' &&
          typeof con.value === 'string'
        ) {
          con.value = [con.value]
        }
      })

      this.selectedRule.operation = 'set'

      let idx = this.rules.findIndex((r: gnb.rules.Rule) => {
        return r.id === this.selectedRule.id
      })

      this.rules.splice(idx, 1, this.selectedRule)
    } else {
      this.selectedRule.operation = 'create'
      this.rules.push(this.selectedRule)
    }
    api.setRules(this.rules).then(async () => {
      await (window as any).alertAsync(this.$t('M_Expense_SavedAlert').toString())
      this.getRules()
      this.isShow = false
    })
  }

  closeNewRule() {
    this.selectedRule = {
      id: '',
      name: '',
      priority: 1,
      isEnabled: true,
      operation: '',
      conditions: [],
      actions: [],
      exceptions: [],
    }
    this.isShow = false
  }

  getRules() {
    api.getRules().then((res: any) => {
      if (res.length > 0) {
        this.rules = res.rules
      }
    })
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  created() {
    this.loading(true)
    this.getRules()
  }
}
