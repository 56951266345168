import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import PopupModal from '@gnb/components/Common/PopupModal.vue';
import api from '@gnb/api/common';

@Component({
    components: {
        PopupModal
    },
})
export default class CategoryModal extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({type: Boolean}) show!: false;
    @Prop({type: Object}) item!: gnb.CategoryItem;

     //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    name:string = '';
    color:string = '';
    colors: string[] = [
        'Red','Orange','Yellow','Green','Blue','Purple'
    ];
    title: string = this.$t('새_범주').toString();

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Watch('item')
    ChangeValue(){
        if(this.item){
            this.name = this.item.name;
            this.color = this.item.color;
        }
    }
    

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //


    save(item: gnb.CategoryItem){
        if(item.id){
            api.updateCategory({id: this.item.id, name: this.name, color:this.color, isFavorite: false}as gnb.CategoryItem).then(async (res)=>{
                await (window as any).alertAsync('수정되었습니다.');
                this.$emit('cancel');
            })
        }else{
            api.createCategory({name: this.name, color:this.color, isFavorite: false}as gnb.CategoryItem).then(async (res)=>{
                await (window as any).alertAsync('저장되었습니다.');
                this.$emit('cancel');
            })
        }
      
    }

    convertToClass(color: string){
        let index;

        switch(color){
            case 'Blue':
                index = 1;
                break; 
            case 'Green':
                index = 2;
                break;
            case 'Red':
                index = 3;
                break;
            case 'Orange':
                index = 4;
                break;
            case 'Yellow':
                index = 5;
                break;
            case 'Purple':
                index = 9;
                break; 
        }
        return index;
    }

}
