import clinet from '@/api'

export default {
  check() {
    const { pathname, search } = window.location
    const url = pathname + search
    return clinet.get<boolean>({
      url: '/common/accesscontrol/api',
      params: {
        ep: url,
      },
    })
  },
}
