import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import PopupModal from '@gnb/components/Common/PopupModal.vue'


@Component({
    components: {
        PopupModal
    },
})
export default class SignatureModal extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({type: Boolean}) show!: false;
    @Prop({type: Object}) item!: gnb.UserBusinessCard;
    @Prop({type: String}) lang!: '';

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    title:string = this.$t('미리보기').toString();

}
