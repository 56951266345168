import { RouteConfig } from 'vue-router'
import GnbTop from '../containers/GlobalNavigationBarLeft/GnbTop.vue'
const Gnb = () => import(/* webpackChunkName: "gnb" */ '@gnb/Index.vue')
const GnbMaster = () => import(/* webpackChunkName: "gnb" */ '@gnb/views/Master.vue')
const GlobalNavigationBar = () =>
  import(/* webpackChunkName: "gnb" */ '@gnb/views/GlobalNavigationBar/GlobalNavigationBar.vue')
// const QuickMenuUserSet = () => import('@gnb/views/GlobalNavigationBar/QuickMenuUserSetView.vue')
const GlobalNavigationBarLeft = () => import('@gnb/views/GnbLeft/GnbLeftView.vue')
const QuickMenuUserSetPop = () => import(/* webpackChunkName: "gnb" */ '@gnb/views/GnbLeft/QuickMenuSetPopView.vue')
const SignPreview = () => import('@gnb/views/SignPreview/SignPreview.vue')

const routes: RouteConfig[] = [
  {
    path: 'gnb',
    component: Gnb,
    children: [
      {
        path: 'test',
        component: GnbTop, //GlobalNavigationBar,
        children: [
         
        ]
      },
      {
        path: "left",
        component: GlobalNavigationBarLeft,
        children: [
          
        ]
      },
      {
        path: '',
        redirect: 'test',
      },
      {
        path: 'setquickmenu',
        name: 'quickmenu-userset',
        component: QuickMenuUserSetPop,
      },
     
      {
        path: '',
        component: GnbMaster,
        children: [
          
        ],
      },

      {
        name: 'sign-preview',
        path: '/preview/sign/:lang',
        component: SignPreview,
        children: [],
      },
    ],
  },
]
export default routes
