import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import * as dateutil from '@appbase/util/date-helper'
import api from '../api/index'
import { openPopup } from '@appbase/util/popup-helper'
@Component({
    components: {

    }
})
export default class InboxArticle extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    @Prop({ type: Object }) article!: any;

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //
    get createdDate() {
        var nowDateValue = new Date();
        var createdDateValue = new Date(this.article.dateTimeCreated);

        var nowDateStr = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(nowDateValue));
        var createdDateStr = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(createdDateValue));

        if (nowDateStr == createdDateStr) {
            var hour = createdDateValue.getHours();
            var value;
            
            if (hour <= 12) {
                value = this.$t('W_AM').toString();
            }
            else {
                value = this.$t('W_PM').toString();
            }

            return value + dateutil.uGetDateFormat('H:mm', dateutil.IEDate(createdDateValue));
        }
        else {
            return createdDateStr;
        }
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    articleClick() {
        openPopup('InboxArticle', `https://v2.teamsplus.kr/mail/mailpopup/read?id=${encodeURIComponent(this.article.id)}`, {}, {
            width: 1024,
            height: 700,
        },() =>{
            this.$emit('titleClick');
        });
    }
}
