import { RawLocation } from 'vue-router'
import { i18n } from '@appbase/util/i18n-setup'
type VisibleHandler = (menu: MenuInfo) => boolean
export interface MenuInfo {
  name: string
  to?: RawLocation
  visible?: boolean | VisibleHandler
  children?: MenuInfo[]
  selected?: boolean
}
const menues: MenuInfo[] = [
  // {
  //     name: i18n.t('W_Settings_General').toString(),
  //     selected: true,
  //     children: [
  //       { name: i18n.t('M_Settings_Msg36').toString(), selected: true, to: { name: 'language' } },
  //       // { name: i18n.t('알림').toString(), selected: false, to:{ name: 'notice'}},
  //     ],
  // },
  {
    name: i18n.t('W_Mail').toString(),
    selected: true,
    children: [
      {
        name: i18n.t('레이아웃').toString(),
        selected: false,
        to: { name: 'layout' },
      },
      {
        name: i18n.t('서명').toString(),
        selected: false,
        to: { name: 'signature' },
      },
      {
        name: i18n.t('W_Settings_Rule').toString(),
        selected: false,
        to: { name: 'rule' },
      },
      {
        name: i18n.t('W_Delete').toString(),
        selected: false,
        to: { name: 'delete' },
      },
      {
        name: i18n.t('부재중_설정').toString(),
        selected: false,
        to: { name: 'missed' },
      },
      {
        name: i18n.t('W_Mail_AutoForwarder').toString(),
        selected: false,
        to: { name: 'autoDelivery' },
      },
      // { name: '자동회신', selected: false, to: { name: 'autoReply' } },
      {
        name: i18n.t('W_Mail_Category').toString(),
        selected: false,
        to: { name: 'category' },
      },
      {
        name: i18n.t('W_Settings_Storage').toString(),
        selected: false,
        to: { name: 'storage' },
      },
    ],
  },
  {
    name: i18n.t('W_Hours_Schedule').toString(),
    selected: false,
    children: [
      {
        name: i18n.t('일정보기').toString(),
        selected: false,
        to: { name: 'calendarView' },
      },
      // { name: '일정공유', selected: false, to: { name: 'calendarShared' }}
    ],
  },
  {
    name: i18n.t('W_Approval').toString(),
    selected: false,
    children: [
      {
        name: i18n.t('W_Settings').toString(),
        selected: false,
        to: { name: 'setting' },
      },
      {
        name: i18n.t('대결자_설정').toString(),
        selected: false,
        to: { name: 'confrontationView' },
      },
      {
        name: i18n.t('수신_관리').toString(),
        selected: false,
        to: { name: 'reception' },
      },
      // { name: '결재 비밀번호 설정', selected: false, to: { name: 'changeApprovalPassword' }},
    ],
  },
]
export default menues
