import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ListHeaderRight, ListSuveyArticle } from '../../components/index';
import WebpartBase from '@webparts/views/WebpartBase'
import api from '../../api/index'
import { openPopup } from '@appbase/util/popup-helper'
@Component({
    components: {
        ListHeaderRight,
        ListSuveyArticle
    }
})
export default class ListSuveyWebpart extends WebpartBase {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    activeBoardId!: number;
    nowPageNumber: number = 1;
    totalCount!: number;
    listArticles?: any = [];

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    TabClick(boardId: number) {
        if (this.activeBoardId != boardId) {
            this.nowPageNumber = 1;
            this.activeBoardId = boardId
            this.getArticles();
        }
    }

    TabMore() {
        if(!this.isPreview)
            this.$router.push(`/survey/list?MENUID=${this.option.tabs![0].boardId}`);
    }

    Prev() {
        if (this.nowPageNumber > 1) {
            this.nowPageNumber--;
            this.getArticles();
        }
    }

    Next() {
        if (Math.ceil(this.totalCount / this.option.tabs![0].displayCount) > this.nowPageNumber) {
            this.nowPageNumber++;
            this.getArticles();
        }

    }

    Refresh() {
        this.nowPageNumber = 1;
        this.getArticles();
    }

    TitleClick() {

    }

    load() {
        // if (this.option.tabs != null && this.option.tabs.length > 0)
        //     this.activeBoardId = this.option.tabs[0].boardId;          
        
        this.getArticles();
    }

    getArticles() {
        let params = {
            surveyStatus: 2,
            rowCount: this.option.tabs![0].displayCount,
            pageNumber: this.nowPageNumber,
            menuId: this.option.tabs![0].boardId
        }
        api.webPart.getSurveyLists(params).then((res: any) => {
            this.listArticles = res.surveyLists;
            this.totalCount = res.totalCount;
        }).catch((err: any) => {
        });
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E S T Y L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //
    mounted() {
        this.load();
    }
}
