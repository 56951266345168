import * as types from './types';
import { MutationTree } from 'vuex';

export default <MutationTree<boardModule.state.BoardState>>{  
    
    [types.COPERATION_BOARD_INFO](state: boardModule.state.BoardState, payload : Array<board.CoperationBoard>) {
        state.coperationBoardInfo = payload
    },

    
    [types.BOARD_LOADDING](state: boardModule.state.BoardState, flag: boolean) {
        state.boardLoading = flag;
    },
    [types.ARTICLE_LOADDING](state: boardModule.state.BoardState, flag: boolean) {
        state.articleLoading = flag;
    },
    [types.FETCH_BOARD](state: boardModule.state.BoardState, payload: board.Board){
        state.boardConfig = payload
    },
    [types.FETCH_TOTALARTICLES](state: boardModule.state.BoardState, payload: article.Article[]){
        state.articleList = payload;
    },
    [types.FETCH_ARTICLES](state: boardModule.state.BoardState, payload: article.Article[]){
        state.articleList = payload;
    },
    [types.FETCH_BLOGARTICLES](state: boardModule.state.BoardState, payload: article.BlogArticleItem[]){
        state.blogArticleList = payload;
    },
    [types.FETCH_PAGESET](state: boardModule.state.BoardState, payload: boardModule.state.PageSet){        
        state.pageSet = payload
    },
    [types.FETCH_CONTENTFIELD](state: boardModule.state.BoardState, payload: board.ContentField[]){
        state.contentField = payload
    },
    [types.FETCH_LISTFIELD](state: boardModule.state.BoardState, payload: board.ListField[]){
        state.listField = payload
    },
    [types.FETCH_BLOGCOMMENT](state: boardModule.state.BoardState, payload: article.CommentFetchResult) {
        var articleId = payload.articleId;
        var article = state.blogArticleList.find(i => i.articleId == articleId);
        if(article) {
            article.articleId = payload.articleId;
            article.comments = payload.comments;
            article.commentCount = payload.comments.length;
        }
    },
    [types.TOGGLE_COMMENT](state: boardModule.state.BoardState, payload: number) {
        var article = state.blogArticleList.find(i => i.articleId == payload);
        if(article) {
            article.showComment = !article.showComment
        }
    },
    [types.TOGGLE_RECOMMEND] (state: boardModule.state.BoardState, payload: number) {
        var article = state.blogArticleList.find(i => i.articleId == payload);
        if(article) {
            article.showRecommend = !article.showRecommend
        }
    },
    [types.TOGGLE_RECCOMENDICON] (state: boardModule.state.BoardState, payload: number) {
        var article = state.blogArticleList.find(i => i.articleId == payload);
        if(article) {
            article.recommendIcon = !article.recommendIcon
        }
    },
    [types.TOGGLE_FILE](state: boardModule.state.BoardState, payload: number) {
        var article = state.blogArticleList.find(i => i.articleId == payload);
        if(article) {
            article.showFile = !article.showFile
        }
    },

    [types.Z_HELPDESK_CATEGORIES](state: boardModule.state.BoardState, payload: Array<helpdesk.Category>){        
        state.helpdeskCategories = payload
    },
};
