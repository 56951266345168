import { GetterTree } from 'vuex';
import * as types from './types'

export default <GetterTree<reservedb.state.ReserveState, RootState>>{
    [types.CALENDAR_LOADING](state) {
        return state.isLoading;
    },

    [types.CALENDAR_DAILYEVENT_LOADING](state) {
        return state.isDailyEventLoading;
    },

    [types.CALENDAR_DATE_CURRENT](state) {
        return state.currentCalendarDate;
    },

    [types.CALENDAR_RIGHTPANE_OPEN](state) {
        return state.rightPaneOpen;
    },

    [types.CALENDAR_LEFTPANE_OPEN](state) {
        return state.leftPaneOpen;
    },

    [types.CALENDAR_VIEWMODE](state) {
        return state.calendarMode;
    },

    [types.MINI_CALENDAR_MONTH_MATRIX_LIST](state) {
        return state.miniCalendarMatrix;
    },

    [types.CALENDAR_MONTH_MATRIX_LIST](state) {
        return state.monthMatrix;
    },

    [types.CALENDAR_WEEK_MATRIX_LIST](state) {
        return state.weekMatrix;
    },

    [types.CALENDAR_DATE_MATRIX_LIST](state) {
        return state.dateMatrix;
    },

    [types.CALENDAR_SETTING](state) {
        return state.calendarSetting;
    },

    // [types.EWS_RESOURCEGROUPS_TREE](state) {
    //     return state.resourceGroupsTree;
    // },

    // [types.EWS_RESOURCEGROUPS_LIST](state) {
    //     return state.resourceGroupsList;
    // },

    // [types.SELECTED_RESOURCE_KEYS](state) {
    //     return state.selectedResourceKeys;
    // },

    // [types.SELECTED_RESOURCE_LIST](state) {
    //     return state.selectedResources;
    // },

    // [types.CALENDAR_DAILY_EVENT_LIST](state) {
    //     return state.resourceDailyEvents;
    // },

    // [types.CALENDAR_SEARCH_EVENTS](state) {
    //     return state.searchEvents;
    // },

    [types.CALENDAR_ENTIRE_EVENT_LIST](state) {
        return state.resourceEvents;
    },

    [types.CALENDAR_SEARCHSET](state) {
        return state.searchSet;
    },

    [types.MAIN_LISTVIEW_OPEN](state) {
        return state.listViewOpen;
    },


    //database 버전
    [types.RESOURCE_CHECK_APPROVER](state){
        return state.isApprover
    },

    [types.RESOURCE_SELECTED_RESOURCEIDS](state){
        return state.selectedResourceIds
    },

    [types.RESOURCE_BOOKING_LIST](state){
        return state.bookingList.sort((a:reservedb.ResponseBookingItem,b:reservedb.ResponseBookingItem)=>{
            return +new Date(`${a.startDate}T${a.startTime}+09:00`) - +new Date(`${b.startDate}T${b.startTime}+09:00`)
        })




        // this.displayItems.sort((a:reservedb.ResponseBookingItem,b:reservedb.ResponseBookingItem)=>{
        //     return +new Date(`${a.startDate}T${a.startTime}+09:00`) - +new Date(`${b.startDate}T${b.startTime}+09:00`)
        // })
    },

    [types.RESOURCE_COLOR_MAPITEM](state){
        return state.colorMapItems
    },
 
 
    [types.RESOURCE_ALLDAY_EVENTS](state) {
        return state.allDayEvents  
    },

    [types.PREVIOUSE_PAYLOAD](state) {
        return state.previousRequestParam  
    },

    
    

};
