import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import  modalHelper  from '@appbase/util/modal-helper'

@Component({
})
export default class ModalFooter extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    @Prop({ type:Boolean }) isPreview!: false;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    // cancel(){
    //     modalHelper.close()
    // }

}