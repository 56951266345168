import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import * as usertypes from '@account/store/types'
import { mapGetters, mapActions } from 'vuex'
import { openPopup } from '@appbase/util/popup-helper'
import api from '@gnb/api'

// components
import GnbSearch from '@gnb/components/GlobalNavigationBarLeft/GnbSearch.vue'
import GnbWorkFeed from '@gnb/components/GlobalNavigationBarLeft/GnbWorkFeed.vue'
import GnbBookmark from '@gnb/components/GlobalNavigationBarLeft/GnbBookmark.vue'
import GnbSiteMap from '@gnb/components/GlobalNavigationBarLeft/GnbSiteMap.vue'
import menuHelper from '@appbase/util/menu-helper'
import SubGlobalNavigationBar from '@gnb/components/GlobalNavigationBar/SubGlobalNavigationBar.vue'

//새로만듬
import modalHelper from '@appbase/util/modal-helper'
import ZinusQuickMenu from '@gnb/components/Zinus/ZinusQuickMenu.vue'
import PreferenceModal from '@gnb/components/Preference/PreferenceModal.vue'
import accountApi from '@account/api'
import * as types from '@gnb/store/types'
import OrgModalView from '@orgmap/views/OrgmapModalView.vue'
import ZinusBoardModal from '@webparts/views/Zinus/Modal/ZinusBoardModal.vue'

export enum GoToContext {
  changePassword = 'cp',
  settings = 'st',
  logout = 'lg',
  admin = 'ad',
  mypage = 'mp',
}

@Component({
  components: {
    SubGlobalNavigationBar,
    GnbSearch,
    GnbWorkFeed,
    GnbBookmark,
    GnbSiteMap,
    ZinusQuickMenu,
  },
  computed: {
    ...mapGetters({
      user:usertypes.USER, 
      userCurrent: usertypes.CURRENT,
      //isChangeLangFlag: types.SET_CHANGE_LANG_FLAG
    }),
  },
  methods: {
    ...mapActions({
      langChange: types.LANGUAGE_TIME_CHANGE,
      // setChangeLangFlag: types.SET_CHANGE_LANG_FLAG
    }),
  },
})
export default class GnbTop extends Vue {
  user!: account.User;

  userCurrent!: account.TCurrent
  siteMapItems: appbase.cache.TMenuItem[] = []
  menuPermission: account.TPermissionMenu[] = []
  userLang: string = ''

  // Components Data
  orgList: orgmap.UserItem[] = []
  articleList: gnbarticle.Article[] = []
  articleCount: number = 0
  workFeedItems: gnb.WorkFeedItem[] = []
  bookMarkItems: gnb.BookMarkItem[] = []
  quickMenuItems: gnb.QuickMenuItem[] = []
  //
  concurrences: account.TUser[] = [];
  //현재 부서코드(겸직전환 시 사용)
  concurrenceCode: string = "";
  concurrenceComName: string ="";

  // 검색 관련
  searchkeyword: string = ''

  // 알림 관련
  UnReadWorkFeedStatus: boolean = false

  // Gnb Item Display Option
  dispGnbSearch: boolean = false
  dispGnbWorkFeed: boolean = false
  dispGnbBookmark: boolean = false
  dispGnbSitemap: boolean = false
  dispGnbQuickMenu: boolean = false

  showSubnavi: boolean = false
  
  // 겸직
  isConcurrentUser: boolean = false;

  //관리자 체크
  isAdmin: boolean = false

  //시스템 다국어 처리  flag
  // isChangeLangFlag!: boolean
  //setChangeLangFlag!: (flag: boolean) => void
  langChange!: (item: { personCode: string; language: string }) => Promise<void>

  mounted() {
    window.document.body.addEventListener('mouseleave', () => {
      this.showSubnavi = false
    })
  }

  fnGoHome() {
    menuHelper.goHome()
  }
  // 메뉴 클릭 이벤트 처리
  fnGnbClick(item: appbase.cache.TMenuItem) {
    if (item) {
      if (item.isPopup){
        window.open(item.targetUrl?.toString());
      }
      else{
        // Menu URL이 있는 경우 Menu 이동
        if (item.targetUrl) {
          menuHelper.next(item)
        } else {
          if (
            item.hasChildren &&
            item.children.length > 0 &&
            item.children[0].targetUrl
          ) {
            menuHelper.next(item.children[0])
          } else if (
            item.children[0].hasChildren &&
            item.children[0].children.length > 0 &&
            item.children[0].children[0].targetUrl
          ) {
            menuHelper.next(item.children[0].children[0])
          } else if (
            item.children[0].children[0].hasChildren &&
            item.children[0].children[0].children.length > 0 &&
            item.children[0].children[0].children[0].targetUrl
          ) {
            menuHelper.next(item.children[0].children[0].children[0])
          }
        }
      }
    }
    this.showSubnavi = false
  }
  // 검색창 Display 열기
  fnOpenGnbSearch(event: string) {
    this.dispGnbSearch = event == 'O' ? true : false
    this.dispGnbWorkFeed = false
    this.dispGnbBookmark = false
    this.dispGnbSitemap = false
    this.dispGnbQuickMenu = false

    if (event == 'O') {
      this.orgList = []
      this.articleList = []
    }
  }

  // Gnb 검색
  fnGnbSearch(event: KeyboardEvent) {
    if (event) event.preventDefault()
    let keyWord = (<HTMLInputElement>document.getElementById('txtGnbSearch'))
      .value
    if (keyWord != '') {
      this.searchkeyword = keyWord
      // 검색 데이터 초기화
      this.orgList = []
      this.articleList = []

      // 조직 데이터 검색
      var orgOption = {
        query: keyWord,
        includeAllFlag: false,
        retiredFlag: false,
      } as gnborgmap.SearchOptions
      api.gnb
        .searchOrg(orgOption, this.userCurrent.company.comCode)
        .then((res: any) => {
          const { items, totalCount } = res
          if (items) {
            this.orgList = items
          }
        })

      // 게시판 데이터 검색
      var boardOption = {
        userCode: this.userCurrent.user.personCode,
        includeContent: true,
        useNotice: false,
        page: 1,
        itemsPerPage: 5,
        searchField: 30,
        searchFieldValue: keyWord,
        sortField: 0,
        sortOrder: false,
      } as gnbarticle.TotalArticleSearchSet
      api.gnb.getTotalArticles(boardOption).then((res: any) => {
        const { data, totalCount } = res
        if (data) {
          this.articleList = data
          this.articleCount = totalCount
        }
      })
    }
  }

  fnMoreSearch(searchType: string) {
    if (location.pathname.toLowerCase().startsWith('/po/')) {
      this.$router.push({
        name: 'total-search',
        query: {
          SearchText: this.searchkeyword,
          Target: searchType,
        },
      })
    } else {
      document.location.href =
        '/po/home/search?SearchText=' +
        this.searchkeyword +
        '&Target=' +
        searchType
    }
  }

  // 조직도 Open
  fnOrgPop() {
    let options = {
      personal: false,
      public: false,
      single: true,
      containers: [],
    }
    if (this.isTeams) {
      modalHelper.show({
        component: OrgModalView,
        force: true,
        value: {
          options: options,
        },
      })
    } else {
      openPopup(
        'gnb',
        '/orgmap',
        { event: 'OrgPopup', option: options },
        {
          width: 1200,
          height: 700,
        }
      )
    }
  }

  // Workfeed
  fnOpenGnbWorkFeed(event: string) {
    this.dispGnbSearch = false
    this.dispGnbWorkFeed = event == 'O' ? true : false
    this.dispGnbBookmark = false
    this.dispGnbSitemap = false
    this.dispGnbQuickMenu = false

    if (event == 'O') {
      // Workfeed 데이터 조회
      this.fnGetWorkFeedList()
    }
  }

  // WorkFeed 데이터 조회
  fnGetWorkFeedList() {
    api.gnb
      .getWorkFeedList(
        this.userCurrent.user.personCode,
        this.userCurrent.user.comCode
      )
      .then((res: any) => {
        if (res) {
          this.workFeedItems = res
        } else {
          this.workFeedItems = []
        }

        this.UnReadWorkFeedStatus = this.workFeedItems.length > 0 ? true : false
      })
  }

  // 읽지 않은 WorkFeed 건수 조회
  fnGetUnreadWorkFeedCount() {
    api.gnb
      .getWorkFeedUnreadCount(
        this.userCurrent.user.personCode,
        this.userCurrent.user.comCode
      )
      .then((res: any) => {
        this.UnReadWorkFeedStatus = res > 0 ? true : false
      })
  }

  // WorkFeed Item Click
  fnReadWorkfeed(workfeedItem: gnb.WorkFeedItem) {
    // Link가 존재하는 경우 호출
    if (workfeedItem.itemUrl != '' && this.isTeams == false) {
      this.fnOpenWindow(workfeedItem.itemUrl, workfeedItem.alarmText, 1024, 900)
    } else if (workfeedItem.alarmType == 'BB' && this.isTeams == true) {
      let boardParam = this.getArgs(workfeedItem.itemUrl)
      // {SYSID: '17', ARTICLEID: '12956'}
      modalHelper.show({
        component: ZinusBoardModal,
        force: true,
        value: {
          boardId: boardParam.SYSID,
          articleId: boardParam.ARTICLEID,
          title: workfeedItem.alarmText,
        },
      })
    }
    else if (workfeedItem.alarmType == "WF" && this.isTeams == true){
      openPopup(`workfeed_${workfeedItem.alarmId}`, workfeedItem.itemUrl,
      { },{
        width: 1024,
        height: 900,
        isFrame: this.isTeams,
        top: 50,
        noinvalidateUrl: true
      } 
      );
    }

    this.fnChangeWorkfeedStatus(workfeedItem.alarmId, true);
  }

  // Workfeed 알림상태 변경
  fnChangeWorkfeedStatus(alarmId: number, readStatus: boolean)
  {
    api.gnb
      .setWorkFeedReadStatus(alarmId, readStatus)
      .then((res: any) => {
        if (res > -1 && readStatus) {
          let nIdx = this.workFeedItems.findIndex(
            (x) => x.alarmId == alarmId
          )
          if (nIdx > -1) this.workFeedItems.splice(nIdx, 1)
          if (this.workFeedItems.length == 0) this.UnReadWorkFeedStatus = false
        }
      })
  }

  // 전체삭제
  async fnDeleteAllWorkfeed(){
    if(await(window as any).confirmAsync(this.$t('삭제하시겠습니까').toString())){
      api.gnb.setWorkFeedAllReadStatus(this.userCurrent.user.personCode, this.userCurrent.user.comCode).then((res : any) => {
        this.fnGetWorkFeedList();
      })
    }
  }

  getArgs(src: string) {
    let args = new Object() as any
    let query = src.split('?')[1] //파람만있으면됨
    let pairs = query.split('&') //&위치에서 분리

    for (let i = 0; i < pairs.length; i++) {
      let pos = pairs[i].indexOf('=') // "이름 = 값" 을 찾는다.
      if (pos == -1) continue //찾지 못했다면 건너뛴다.
      let argname = pairs[i].substring(0, pos) //이름을 추출
      let value = pairs[i].substring(pos + 1) //값을 추출
      value = decodeURIComponent(value) //필요하다면 디코딩 수행
      args[argname] = value //객체의 프로퍼티로 저장
    }

    return args //객체를 반환
  }

  // 즐겨찾기 Display
  fnOpenGnbBook(event: string) {
    this.dispGnbSearch = false
    this.dispGnbWorkFeed = false
    this.dispGnbBookmark = event == 'O' ? true : false
    this.dispGnbSitemap = false
    this.dispGnbQuickMenu = false

    if (event == 'O') {
      this.fnGetBookMarkList()
    }
  }

  // 즐겨찾기 목록 조회
  fnGetBookMarkList() {
    api.gnb
      .getBookMarkList(
        this.userCurrent.user.personCode,
        this.userCurrent.user.comCode
      )
      .then((res: any) => {
        if (res) {
          this.bookMarkItems = res
        } else {
          this.bookMarkItems = []
        }
      })
  }

  // 즐겨찾기 클릭 이벤트 처리
  async fnBookmarkClick(bookmarkItem: gnb.BookMarkItem) {
    if (bookmarkItem) {
      if (bookmarkItem.itemId) {
        // itemId의 경우 menuId 값이며, menuId가 존재할 경우 menuHelper를 실행한다.
        let item: appbase.cache.TMenuItem | null = menuHelper.findOne(
          bookmarkItem.itemId
        )
        if (item) {
          this.fnOpenGnbBook('C')
          menuHelper.next(item)
        } else {
          // 즐겨찾기된 메뉴가 삭제된 경우
          let msg =
            this.$t('즐겨찾기_메뉴_없음_알림').toString() +
            '\r\n' +
            this.$t('삭제하시겠습니까').toString()
          if (await(window as any).confirmAsync(msg)) {
            this.delBookmarkItem(bookmarkItem, true)
          }
        }
      } else {
        if (bookmarkItem.itemUrl != '') {
          switch (bookmarkItem.itemType) {
            case 'MU':
              document.location.href = bookmarkItem.itemUrl
              break
            case 'FO':
              this.fnOpenGnbBook('C')
              this.fnOpenWindow(
                bookmarkItem.itemUrl,
                bookmarkItem.itemText,
                1024,
                900
              )
              break
          }
        }
      }
    }
  }

  async delBookmarkItem(bookmarkItem: gnb.BookMarkItem, isConfirm: boolean = false) {
    if (!isConfirm) isConfirm = await(window as any).confirmAsync(this.$t('삭제하시겠습니까').toString())

    if (isConfirm) {
      api.gnb
        .deleteBookMark(
          bookmarkItem.bookMarkId,
          this.userCurrent.user.personCode,
          this.userCurrent.company.comCode
        )
        .then((res: any) => {
          if (res > -1) {
            this.fnGetBookMarkList()
          }
        })
    }
  }

  fnOpenGnbSiteMap(event: string) {
    this.dispGnbSearch = false
    this.dispGnbWorkFeed = false
    this.dispGnbBookmark = false
    this.dispGnbSitemap = event == 'O' ? true : false
    this.dispGnbQuickMenu = false
  }

  async fnSiteMapClick(siteMap: appbase.cache.TMenuItem) {
    if (siteMap.targetUrl) {
      this.fnOpenGnbSiteMap('C')
      menuHelper.next(siteMap)
    } else {
      if (
        siteMap.hasChildren &&
        siteMap.children.length > 0 &&
        siteMap.children[0].targetUrl
      ) {
        this.fnOpenGnbSiteMap('C')
        menuHelper.next(siteMap.children[0])
      } else {
        await (window as any).alertAsync(this.$t('메뉴_등록된_URL_없음').toString())
      }
    }
  }

  // 퀵메뉴 Open 설정
  fnOpenGnbQuickMenu(event: string) {
    this.dispGnbSearch = false
    this.dispGnbWorkFeed = false
    this.dispGnbBookmark = false
    this.dispGnbSitemap = false
    this.dispGnbQuickMenu = event == 'O' ? true : false
  }

  // 퀵메뉴 목록 조회
  //캐시로 변경하고 여기서 조회 하지 않음
  // fnGetQuickMenuItems() {
  //   api.gnb.getUserQuickMenuList(this.userCurrent.user.personCode, this.userCurrent.company.comCode, 'U').then((res: any) => {
  //     debugger
  //     if (res) {
  //       this.quickMenuItems = res;
  //     }
  //   });
  // }

  // fnInsertQuickMenuUserSet(quickMenuIds: string) {
  //   api.gnb.insertQuickMenuUserSet(this.userCurrent.user.personCode, this.userCurrent.user.comCode, quickMenuIds).then((res: any) => {
  //     if (res > -1) {
  //       alert(this.$t('저장_하였습니다').toString());
  //       this.fnGetQuickMenuItems();
  //     }
  //     else {
  //       alert(this.$t('저장_실패').toString());
  //     }
  //   });
  // }

  // 퀵메뉴 클릭 이벤트 처리
  // fnQuickMenuClick(item: gnb.QuickMenuItem) {
  //   if (item) {
  //     const { title, linkUrl, moveType, width, height } = item
  //     if (linkUrl) {
  //       switch (moveType) {
  //         case 0:     // 페이지 이동
  //           document.location.href = linkUrl.toString()
  //           break;
  //         case 1:     // 새탭으로 열기
  //           window.open(linkUrl.toString(), '_blank');
  //           break;
  //         case 2:     // 팝업
  //           this.fnOpenWindow(linkUrl.toString(), title.toString(), Number(width), Number(height));
  //           break;
  //       }
  //     }
  //   }
  // }

  fnOpenWindow(url: string, title: string, width: number, height: number) {
    if (url != '') {
      let nWidth = width ? width : 1024
      let nHeight = height ? height : 900
      let nLeft = (screen.width - nWidth) / 2
      let nTop = (screen.height - nHeight) / 2
      let property =
        'left=' +
        nLeft +
        ',top=' +
        nTop +
        ',height=' +
        nWidth +
        ',width=' +
        nHeight +
        ',toolbar=no,scrollbars=no,resizable=yes'
      window.open(url, title, property)
    }
  }

  // 메뉴 데이터 생성
  fnMakeMenuItem(
    childItems: appbase.cache.TMenuItem[],
    item: appbase.cache.TMenuItem,
    itemType: string
  ) {
    for (let i = 0; i < childItems.length; i++) {
      let isPermission =
        (itemType == 'S' ? true : childItems[i].isTopVisible) &&
        this.menuPermission.find((x) => x.id == childItems[i].menuId)
          ? true
          : false && childItems[i].callType == 'USER'

      //메뉴명 다국어처리 ?
      // if (this.isChangeLangFlag == false) {
      //     childItems[i].menuName = this.changeLocalLanguage(childItems[i].menuName)
      // }

      if (isPermission && childItems[i].hasChildren) {
        this.fnMakeMenuItem(childItems[i].children, childItems[i], itemType)
      }

      if (i == 0) {
        item.children = []
        item.hasChildren = false
      }

      //isTopVisivle 체크
      if (isPermission && childItems[i].isTopVisible == true) {
        item.children.push(childItems[i])
      }
    }

    // debugger

    if (item.children.length > 0) {
      item.hasChildren = true
    }
  }

  //미사용 리소스 다국어 처리하면서 사용하지 않음
  changeLocalLanguage(str: string) {
    //let menuName = str.replaceAll(" ", "_").replaceAll('(', "").replaceAll(')', "").replaceAll("/", "_").replaceAll("&", "_")
    return this.$t(str).toString()
  }

  created() {
    //관리자 체크
    if (
      this.userCurrent.permissions.isCompanyManager == true ||
      this.userCurrent.permissions.isSuperAdmin == true
    )
      this.isAdmin = true

    const { menu } = this.portalCache
    const excepts = ['HOME', 'MYPAGE']
    let mItems: appbase.cache.TMenuItem[] = menuHelper.setMenuParam(
      menu.items,
      this.userCurrent.user
    )
    this.menuPermission = this.userCurrent.permissions.menues

    for (let i = 0; i < mItems.length; i++) {
      let mItem: appbase.cache.TMenuItem = mItems[i]
      if (
        !excepts.includes(mItem.callType) &&
        this.menuPermission.find((x) => x.id == mItem.menuId)
      ) {
        // if (this.isChangeLangFlag == false) {
        // mItem.menuName = this.changeLocalLanguage(mItem.menuName)
        // }
        this.siteMapItems.push(mItem)
        // debugger
        if (mItem.hasChildren) {
          this.fnMakeMenuItem(mItem.children, mItem, 'S')
        }
      }
    }

    //시스템 다국어 메뉴 처리 완료
    //this.setChangeLangFlag(true)

    // Workfeed 데이터 건수 조회
    this.fnGetUnreadWorkFeedCount()
    // 퀵메뉴 데이터 조회
    //this.fnGetQuickMenuItems()

    if (this.user.concurrentUsers && this.user.concurrentUsers.length > 0) {
      this.setConcurrences(this.user.concurrentUsers);
    }
  }

  setConcurrences(concurrences: account.TUser[]) {
    this.concurrences = [this.user, ...concurrences];

    this.concurrenceCode = this.user.deptCode;
    this.isConcurrentUser = true;
  }

  onClickContextMenu(value: GoToContext) {
    if (value == GoToContext.changePassword) {
      window.location.href =
        'https://account.activedirectory.windowsazure.com/ChangePassword.aspx'
    } else if (value == GoToContext.settings) {
      modalHelper.show({ component: PreferenceModal, force: true })
    } else if (value == GoToContext.logout) {
      let comCode = this.userCurrent.company.comCode
      let prefix = process.env.NODE_ENV === 'production' ? '/po' : ''

      accountApi.auth.logout().then((result) => {
        let loginPath = 'login'
        if (comCode == 'MELLOW') loginPath = 'mllw-login'
        window.location.href = `${window.location.origin}${prefix}/account/${loginPath}`
      })
    } else if (value == GoToContext.admin) {
      window.location.href = '/admin'
    } else if(value == GoToContext.mypage){
      let prefix = process.env.NODE_ENV === 'production' ? '/po' : ''
      window.location.href = `${window.location.origin}${prefix}/home/mypage`
    }
  }

  async changeLanguage(language: 'en-US' | 'zh-CN' | 'ko-KR') {
    console.log('11111', language, this.userCurrent.user.personCode)
    await this.langChange({
      personCode: this.userCurrent.user.personCode,
      language: language,
    })
    window.location.reload();
    

  }

  getPhotoUrl() {
    if (
      this.userCurrent.user.photoPath &&
      this.userCurrent.user.photoPath != ''
    ) {
      return this.userCurrent.user.photoPath
    } else {
      return '/cm/gres/faux/images/ghost.png'
    }
  }

  onConcurrenceChanged() {
    var after = this.concurrences.find(
      (c) => c.deptCode === this.concurrenceCode
    );

    if (after) {
      var dispString = `${after.deptName}/${after.concurrenceComName}`;

      // this.$confirm(`선택한 겸직부서로 전환하시겠습니까?\n${dispString}`, '', undefined, Vue.prototype.$confirmOptions).then((res: boolean) =>{
      //   if(res) {
      //     var state = encodeURIComponent(
      //       `${window.location.pathname}${window.location.search}`
      //     );
      //     console.log("state", state);
      //     window.location.href = `/cm/CommonAccount/Auth/api/switchConcurrence?deptCode=${this.concurrenceCode}&state=${state}`;
      //   }
      
      //   Vue.prototype.$confirmOptions.html = ''
      // })

      if (confirm(`선택한 겸직부서로 전환하시겠습니까?\n${dispString}`)) {
        var state = encodeURIComponent(
          `${window.location.pathname}${window.location.search}`
        );
        console.log("state", state);
        window.location.href = `/cm/CommonAccount/Auth/api/switchConcurrence?deptCode=${this.concurrenceCode}&state=${state}`;
      }
    }
  }
}
