import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import WebpartBase from '@webparts/views/WebpartBase'
import * as dateutil from '@appbase/util/date-helper'
import { mapGetters } from 'vuex'
import * as typesAccount from '@account/store/types'
import { Calendar, EventList } from '@webparts/components/Calendar'
import {
  CalendarEventColor,
  CalendarFolderInfo,
} from '@webparts/components/Calendar/type'
import api from '@webparts/api'
import { EwsCalendarFolderColorInfo } from '../../CalendarWebpart/CalendarWebpart'

export const COMMON_AUTO_COLOR_ARRAY = [
  '#ff4b09',
  '#ffc609',
  '#e0ff03',
  '#bdff09',
  '#29e1bf',
  '#0942ff',
  '#241eb4',
  '#c609ff',
  '#ff119a',
  '#c9dbdb',
]

const folderRequest: awsRequest.request = {
  fields: [
    {
      key: 'Id',
    },
    {
      key: 'USER_COLOR',
    },
  ],
  filters: [
    {
      operator: 'or',
      filters: [
        {
          key: 'FolderClass',
          value: 'IPF.Appointment',
          condition: 'isEqualTo',
        },
        {
          key: 'FolderClass',
          value: 'IPF.Appointment.Birthday',
          condition: 'isEqualTo',
        },
      ],
    },
    {
      key: 'PR_ATTR_HIDDEN',
      value: 'false',
      condition: 'isEqualTo',
    },
  ],
  wellKnownParentName: 'MsgFolderRoot',
  type: 'Folder',
  Traversal: 'Deep',
}

@Component({
  components: { Calendar, EventList },
  computed: {
    ...mapGetters({
      current: typesAccount.CURRENT,
    }),
  },
})
export default class ZinusCalendarBase extends WebpartBase {
  @Prop({
    type: String,
    default: dateutil.uGetDateFormat('yyyy-MM-DD', new Date()),
  })
  value!: string

  baseValue: string | null = dateutil.uGetDateFormat('yyyy-MM-DD')
  weeks: string[] = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
  folders: CalendarFolderInfo[] = []

  async created() {
    // api.webPart
    //   .getEWSData<any>(folderRequest)
    //   .then((m: any) => m.items)
    //   .then((items: { id: string; colorCode: string | 'none' }[]) => {
    //     this.folders = items.map((item) => {
    //       const defaultColorCode = Math.floor(
    //         Math.random() * (10 - 1) + 1
    //       ).toString()
    //       let color: CalendarEventColor = Object.create({})
    //       color.hasColor =
    //         item.colorCode !== undefined &&
    //         item.colorCode !== null &&
    //         item.colorCode !== 'none'
    //       color.code = color.hasColor ? item.colorCode : defaultColorCode
    //       return { id: item.id, color } as CalendarFolderInfo
    //     })
    //   })

    this.folders = await api.webPart
      .getEWSData<{ items: EwsCalendarFolderColorInfo[] }>(folderRequest)
      .then((x) => x.items)
      .then((items) => {
        return items.map((item, index) => {
        //   const defaultColorCode = Math.floor(
        //     Math.random() * (10 - 1) + 1
        //   ).toString()
          let color: CalendarEventColor = {
            hasColor: true,
            code: item.colorCode || COMMON_AUTO_COLOR_ARRAY[index],
            category: `bg-category-${index}`,
          }
          // color.hasColor =
          //   item.colorCode !== undefined && item.colorCode !== 'none'
          return { id: item.id, folderType: 'EWS', color } as CalendarFolderInfo
        })
      })

      let color: CalendarEventColor = {
        hasColor: true,
        code: (COMMON_AUTO_COLOR_ARRAY[this.folders.length] ? COMMON_AUTO_COLOR_ARRAY[this.folders.length] : COMMON_AUTO_COLOR_ARRAY[0]),
        category: `bg-category-${this.folders.length}`,
      }

      // 회사일정 폴더 추가
      this.folders.push({ id: '1', folderType: 'DB', color });
  }
}
