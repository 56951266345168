import api from "@/api";

import axios from "axios";

export default {
  getHelpdeskCategories(boardId: number): Promise<Array<helpdesk.Category>> {
    return api.get({
      url: `/api/z/helpdesk/categories/${boardId}`,
    });
  },

  //생성 & 수정 함께씀
  createHelpdeskCase(data: helpdesk.RequestHelpdeskCase): Promise<boolean> {
    return api.post({
      url: "/api/z/helpdesk",
      data: data,
    });
  },

  getHelpdeskCase(articleId: number): Promise<helpdesk.ResponseHelpdeskCase> {
    return api.get({
      url: `/api/z/helpdesk/${articleId}`,
    });
  },

  //삭제 SOFT DELETE
  deleteHelpdeskCase(articleId: number): Promise<boolean> {
    return api.delete({
      url: `/api/z/helpdesk/${articleId}`,
    });
  },

  sendCommentNotification(
    articleId: number,
    comment: string
  ): Promise<boolean> {
    return api.post({
      url: `/api/z/helpdesk/notification/comment`,
      data: {
        articleId,
        comment,
      },
    });
  },

  getHelpdeskNotice(boardId: number): Promise<helpdesk.HelpdeskNoticeOption> {
    return api.get({
      url: `/api/z/helpdesk/noticeoption/${boardId}`,
    });
  },

  getHelpdeskNotiList(
    boardId: number
  ): Promise<helpdesk.HelpdeskNoticeOption[]> {
    return api.get({
      url: `/api/z/helpdesk/noticeoptionlist/${boardId}`,
    });
  },

  downloadListExcel(
    boardId: number,
    userCode: string,
    helpdeskStatus: number,
    helpdeskCategory: number,
    helpdeskDateType: number,
    startDate: string,
    endDate: string,
    searchFieldValue: string,
    searchField: string
  ) {
    const url = `/api/z/helpdesk/download?boardId=${boardId}&userCode=${userCode}&helpdeskStatus=${helpdeskStatus}&helpdeskCategory=${helpdeskCategory}&helpdeskDateType=${helpdeskDateType}&startDate=${startDate}&endDate=${endDate}&searchFieldValue=${searchFieldValue}&searchField=${searchField}`;
    return axios({
      method: "get",
      url: api.invalidateUrl(url),
      responseType: "arraybuffer",
    }).then(function (response) {
      console.log("response", response);
      const disposition = response.headers["content-disposition"];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      var fileName = "";
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
        if (fileName) {
          fileName = decodeURIComponent(fileName);
        }
      }
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      //IE 경우
      const navigator = window.navigator as any;
      if (window.navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}`;
        link.click();
        setTimeout(() => link.remove(), 100);
      }
    });
  },
};
