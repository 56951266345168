import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { LoadingBar } from '@appbase/components/loading'
import { mapGetters } from 'vuex';
import * as types from '@gnb/store/types';

@Component({
   components: {LoadingBar},
   computed: {
      ...mapGetters({
         isLoading: types.COMMON_LOADDING,

      })
   }
})
export default class PreferenceContentTemplate extends Vue {
   //
   // ────────────────────────────────────────────────── I ──────────
   //   :::::: P R O P S : :  :   :    :     :        :          :
   // ────────────────────────────────────────────────────────────
   //

   @Prop({type: Boolean}) isRule!: false;
   //
   // ──────────────────────────────────────────────── I ──────────
   //   :::::: D A T A : :  :   :    :     :        :          :
   // ──────────────────────────────────────────────────────────
   //

   loadingMessage: string = this.$t('로딩중입니다__잠시만_기다려주세요').toString();
   
   //
   // ────────────────────────────────────────────────────── I ──────────
   //   :::::: G E T T E R S : :  :   :    :     :        :          :
   // ────────────────────────────────────────────────────────────────
   //
   
   isLoading !: boolean;
 
}