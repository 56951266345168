const COLOR_ARRAY = [
    'bg-category-1-o',
    'bg-category-2-o',
    'bg-category-3-o',
    'bg-category-4-o',
    'bg-category-5-o',
    'bg-category-6-o',
    'bg-category-7-o',
    'bg-category-8-o',
    'bg-category-9-o',
    'bg-category-10-o'
]; 

const getHashCode = function(plainText: string) {
    var hash = 0, i, chr;
    if (plainText.length === 0) return hash;
    for (i = 0; i < plainText.length; i++) {
      chr   = plainText.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

export const getResourceColor = function(uniqueId: string) {
    return COLOR_ARRAY[Math.abs(getHashCode(uniqueId)) % COLOR_ARRAY.length];
}