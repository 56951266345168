import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import AttendanceGrid from '@attendance/components/ManageList/AttendanceManage.vue'
import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import AttendanceDetailModalView from '@attendance/views/Modal/AttendanceDetailModalView.vue'
import * as dateutil from '@appbase/util/date-helper'
import api from '@attendance/api/index'

@Component({
    components: { AttendanceGrid, DatePicker, AttendanceDetailModalView }
})
export default class AttendanceManageView extends Vue {
    startDate: string = ''
    endDate: string = ''
    attendanceFilter: number = 0
    searchFilter: number = 0
    searchValue: string = ''
    pageNum: number = 1
    pageSize: number = 20

    list: attendance.AttendanceItem[]= []
    totalCount: number = 0

    attendanceItem: attendance.AttendanceItem | null = null
    isShowModal: boolean = false
    isDispSendMail: boolean = false

    attendanceFilterVal: number = 0
    checkAttendanceItems:[] = []

    // 재직/퇴직 여부
    personStatusFilter:number = -1;

    // 근무형태
    workingTypeList:stagger.StaggerAttendanceType[] =[]
    attendanceTypeFilter:number = 0

    // 년도 검색
    yearList:number[] = []
    yearFilter:number = 0

    excelDownload(){
        let url = `/api/z/attendance/download?startDate=${this.startDate}&endDate=${this.endDate}&searchFilter=${this.searchFilter}&searchValue=${this.searchValue}&attendanceFilter=${this.attendanceFilterVal}&personStatusFilter=${this.personStatusFilter}&attendanceTypeFilter=${this.attendanceTypeFilter}`;
        api.attendance.downloadListExcel(url);
    }

    showModal(item: attendance.AttendanceItem) {
        this.attendanceItem = item
        this.isShowModal = true
    }

    closeModal(reload:boolean) {
        this.isShowModal = false
        if (reload){
            this.getList(1);
        }
    }

    onSearch(){
        this.getList(1)    
    }

    // 근태 상태 Select 변경 이벤트 처리
    // 출근이상, 퇴근이상인 경우에만 메일발송 버튼을 활성화한다.
    onChangeState(obj: any){
        this.attendanceFilterVal = obj.target.value

        if (this.attendanceFilterVal == 2 || this.attendanceFilterVal == 3){
            this.isDispSendMail = true;
        }
        else{
            this.isDispSendMail = false;
        }

        // 목록 다시 조회
        this.getList(1);
    }

    changeAttendanceItems(items:[]){
        this.checkAttendanceItems = items;
    }

    async sendMail(){
        if (this.checkAttendanceItems.length == 0){
            await (window as any).alertAsync(this.$t('선택된_항목_없음'));
        }
        else{
            var msg = this.$t('알림메일_발송').toString()
            var attendanceType = this.attendanceFilterVal == 2 ? "I" : "O";
            if (await(window as any).confirmAsync(msg)){
                api.attendance.adminSendMail(this.checkAttendanceItems.toString(), attendanceType).then(async res => {
                    let rMsg = `총 ${res}건 메일 발송 하였습니다.`;
                    await (window as any).alertAsync(rMsg);
                });
            }
        }
    }

    onOpenAttendancePop(){
        var nowDate = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(new Date()));
        var attendanceItem: attendance.AttendanceItem = {
            attendanceId : 0,
            attendanceDate : nowDate,
            personCode : '',
            displayName: '',
            displayDeptName: '',
            displayTitleName: '',
            staggeredTime: {
                hours: 9
            },
            inDateTime: `${nowDate} 09:00`,
            inApplyDateTime: `${nowDate} 09:00`,
            outDateTime: `${nowDate} 18:00`,
            outApplyDateTime: `${nowDate} 18:00`,
            workingTime: {
                value: {
                    hours: 8,
                    minutes: 0
                }
            },
            etc: '',
            inIP: '',
            outIP: '',
            hrLeaveCode: 0,
            hrLeave: '',
            hrLeaveTime: {
                value: {
                    hours: 0,
                    minutes: 0
                }
            },
            attendanceResult: 0
        }

        this.attendanceItem = attendanceItem;
        this.isShowModal = true;
    }

    @Watch('personStatusFilter')
    @Watch('attendanceTypeFilter')
    changeFilterValue(val:number){
        this.getList(1)
    }

    // 근무이력 조회
    getList(pageNum: number){
        this.checkAttendanceItems = [];
        this.pageNum = pageNum;
        api.attendance.getAttendaceList(this.pageNum, this.pageSize, this.startDate, this.endDate, this.searchFilter, this.searchValue, this.attendanceFilterVal, Number(this.personStatusFilter), Number(this.attendanceTypeFilter)).then(res => {
            this.list = res.attendanceList;
            this.totalCount = res.totalCount;
        });
    }

    
    @Watch('yearFilter')
    changeFilter(val:number){
        if(val>0){
        this.startDate = dateutil.uGetDateFormat('yyyy-MM-dd',new Date(val,0,1));
        this.endDate = dateutil.uGetDateFormat('yyyy-MM-dd',new Date(val,11,31));
        }
        else
        this.dateReset()
        this.getList(1)
    }
    
    dateReset(){
        let date = new Date();
        date.setDate(date.getDate() - 7);
        this.startDate = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(date))
        this.endDate = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(new Date()))
    }

    mounted(){
        this.dateReset()
        this.getList(this.pageNum)
    }

    created(){
        api.stagger.getStaggerAttendanceTypeList().then(res=>{
            this.workingTypeList = res
          })
        let _year = new Date().getFullYear()
        for(var i =2022;i<=_year+1;i++ ){
            this.yearList.push(i)
        }
        this.setPage({title:this.$t('근무현황관리')});
    }
}