import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
    name: 'TreeListItem',
})
export default class TreeListItem extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({ type: Object }) item!: orgmap.TreeListItem;
    @Prop({ type: String, default: 'name' }) headerField!: string;
    @Prop({ type: String, default: 'children' }) childrenField!: string;
    @Prop({ type: [String, Function] }) hasChildrenField!:
        | string
        | orgmap.EvaluateTreeListItemHasChildrenFuction;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    expanded: boolean = false;


    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Watch('expanded')
    onExpnded(newValue: boolean): void {
        if (newValue != undefined && newValue === true) {
            this.$emit('expand', this.item);
        }
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    select() {
        this.$emit('select', this.item);
    }

    hasChildren(): boolean {
        if (typeof this.hasChildrenField === 'string') {
            return (this.item as any)[this.hasChildrenField];
        } else {
            return this.hasChildrenField(this.item);
        }
    }
}
