import Vue, { Component as VueComponent } from 'vue'

import { Component, Watch } from 'vue-property-decorator'
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
  ContentItem,
  Dropdown,
} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import * as accountTypes from '@account/store/types'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@gnb/store/types'
import { EumEditor } from '@appbase/components/editor'
import SignatureModal from './Modals/SignatureModal.vue'
@Component({
  components: {
    ModalHeader,
    ModalContent,
    ModalFooter,
    ContentItem,
    PreferenceContentTemplate,
    Dropdown,
    EumEditor,
    SignatureModal
  },
  computed: {
    ...mapGetters({
      user: accountTypes.USER,
      userCurrent: accountTypes.CURRENT,
      cardState: types.GET_CARDSTATE,
    }),
  },
  methods: {
    ...mapActions({
      getCardState: types.GET_CARDSTATE,
      getCardInfo: types.GET_CARD,
      setCardInfo: types.UPDATE_CARD,
      loading: types.COMMON_LOADDING
    }),
  },
})
export default class SignatureView extends Vue {
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: A C T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  getCardState!: () => Promise<gnb.UserBusinessCardState>
  getCardInfo!: (personCode: string) => Promise<gnb.UserBusinessCard>
  setCardInfo!: (item: gnb.UserBusinessCard) => Promise<number>
  loading!: (option: boolean) => void;

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

    userCurrent!: account.TCurrent;
    cardState!: gnb.UserBusinessCardState[];
    
  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //
  name:string = this.$t('명함_수정').toString();
  tab: string = 'Korean'
  userBusinessCardStateList: gnb.UserBusinessCardState[] = []
  signInfo: gnb.UserBusinessCard = {
    personCode: '',
    companyName: '',
    companyNameEng: '',
    deptName: '',
    deptNameEng: '',
    titleName: '',
    titleNameEng: '',
    roleName: '',
    roleNameEng: '',
    jobGroupName: '',
    jobGroupNameEng: '',
    displayName: '',
    displayNameEng: '',
    homeAddress: '',
    homeAddressEng: '',
    officeTel: '',
    officeFax: '',
    mobileTel: '',
    email: '',
    homePage: '',
    template: '',
    freeContent: '',
    usageState: 'Default',
    autoSign: false,
    replyAutoSign: false,
    officeAddress: '', //2022.08.08
    officeAddressEng: '' //2022.08.08
  }

  freeContent: string = ''

  tinyopts: any = {
    menubar: false,
    inline: false,
    height: 500,
    relative_urls: false,
    remove_script_host: true,
    document_base_url: location.origin,
    language: 'ko_KR',
    content_style: `
        body {font-family: arial; font-size: 11pt; line-height: 1.2;}
        body#tinymce p {
            margin-top: 0.3em;
            margin-bottom: 0.3em;
        }`,
    setup: (e: any) => {
      e.on('init', (ed: any) => {
        ed.target.editorCommands.execCommand('fontSize', false, '10pt')

  
      })
    },
    auto_focus: '',
  }

  dext5opts: editor.dext5.DEXT5Config = {
    EditorBorder: '0',
    Lang: 'ko_KR'
  }

  show: boolean = false;

  isLoading: boolean = true ///2022.08.08

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  user!: account.User;

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //
 
    get stateList() {
        // if(this.cardState){
        //     return this.cardState.slice(1);
        // }
        var tempCardState = [{
            description: this.$t('W_Mail_UserBusinessCardState_1').toString(),
            displayOrder: 1,
            enumString: "Korean",
            resourceId: this.$t('한글').toString(),
            usageState: "Korean",
            },
            {
                description: this.$t('W_Mail_UserBusinessCardState_2').toString(),
                displayOrder: 2,
                enumString: "English",
                resourceId: this.$t('영문').toString(),
                usageState: "English",
            },
            {
                description: this.$t('W_Mail_UserBusinessCardState_3').toString(),
                displayOrder: 3,
                enumString: "Free",
                resourceId: this.$t('자유').toString(),
                usageState: "Free"
            }]
      return tempCardState;
    }

    
  @Watch('signInfo')
  onChange(){
    if(this.signInfo){
      this.loading(false);
    }
  }

  
  @Watch('isLoading')
  TestB(newVal: boolean) {
    console.log('testb', 'isLoading', newVal)
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  cancel(){
    this.show = false;
  }

  onClick(item: string) {
    this.tab = item;

    //2022.08.08
    if(this.tab === 'Free') {
      this.setLoading(false)
    }
  }


  getUserInfo() {
    this.getCardInfo(this.user.personCode).then((res: any) => {
      if (res){
        this.signInfo = res;

        if(res.freeContent) {
          this.freeContent = res.freeContent
        }

       
      }
      this.loading(false);
    })
  }

  onValueChanged(value: string) {
    this.signInfo.freeContent = value;
  }

  save() {
    if (this.signInfo.personCode == '') this.signInfo.personCode = this.user.personCode

    if(this.tab == 'Free'){
      this.signInfo.freeContent = this.loadEditorContent()
    }
    this.setCardInfo(this.signInfo).then(async (res) => {
      await (window as any).alertAsync(this.$t('M_Expense_SavedAlert').toString());
    })
  }

  preview() {
    if(this.tab == 'Free'){
      this.signInfo.freeContent = this.loadEditorContent()
    }
    this.show = true;
  }

  loadEditorContent() {
    const editor: editor.IEditorProxy = this.$refs.editor as any 
    return editor.getEditor().getBodyValue();
  }

  //2022.08.08
  setLoading(busy: boolean = true) {
    if (busy) {
      this.isLoading = busy
    } else {
      setTimeout(() => {
        this.isLoading = busy
      }, 500)
    }
  }


  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  mounted() {
    this.loading(true);
    this.getCardState();
    this.getUserInfo();
  }

  created() {
    this.dext5opts.Lang = this.userCurrent.userConfig.language
  }
}
