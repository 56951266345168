/**
 * 페이지 유형 열거자
 */
export enum MenuPageTypes {
  /** 전체 빈 페이지 */
  FE = 'FE',
  /** 전체 페이지 웹파트 */
  FW = 'FW',
  /** 좌측 메뉴 + 빈 페이지*/
  LE = 'LE',
  /** 좌측 메뉴 + 웹파트 */
  LW = 'LW',
}

export enum MenuCallTypes {
  /** 예약된 메뉴: 메인 홈 화면 */
  HOME = 'HOME',
  /** 마이페이지 */
  MYPAGE = 'MYPAGE',
  /** 시스템 메뉴 */
  SYS = 'SYS',
  /** 사용자 지정 메뉴 */
  USER = 'USER',
}

export enum ModalComponentTypes{
  /** 게시판(모달) */
  BOARD = 'BOARD',
  /** 전자결재(IFrame) */
  WORKFLOW = 'WORKFLOW',
  /** 메일(IFrame) */
  MAIL = 'MAIL',
  /** 캘린더(IFrame) */
  CALENDAR = 'CALENDAR',
}