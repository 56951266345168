
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import api from '@gnb/api/common'
import StorageModal from './Modals/StorageModal.vue'
import FolderMoveModal from './Modals/FolderMoveModal.vue'
import { readablizeBytes } from '@appbase/util'
import * as dateutil from '@appbase/util/date-helper'
import { mapActions } from 'vuex'
import * as types from '@gnb/store/types'

@Component({
    components:{FolderMoveModal,ModalHeader,ModalContent,ModalFooter,ContentItem,PreferenceContentTemplate,Dropdown,StorageModal}
    ,methods:{
        ...mapActions({
            download: types.DOWNLOAD_PC,
            loading: types.COMMON_LOADDING
        })
    }
})
export default class StorageView extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    item: gnb.StorageItem = {
        checked: false,
        start: dateutil.uGetDateFormat('yyyy-MM-DD', new Date()),
        end: dateutil.uGetDateFormat('yyyy-MM-DD', new Date()),
    };

    folderItem: gnb.FolderItem = {
        children: null,
        displayName: '',
        hasChildren: false,
        id: '',
        isFavorite: false,
        parentFolderId: '',
        totalCount: 0,
        totalSize: 0,
        unreadCount: 0,
        wellKnownFolderName: '',
        userWellKnownName:''
    } as gnb.FolderItem;

    id: string = '';
    size: number = 0;
    fileName: string = '';
    showModal: boolean = false;
    using: string = '30MB/100MB(10% 사용중)';
    pcModalShow: boolean = false;
    folderModalShow: boolean = false;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    loading!: (option: boolean) => void;
    download!: (item: gnb.downloadOption) => Promise<void>;


    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    get folderList() {
        let target: gnb.FolderItem[] = [];
        const getFolderList = function (item: any): gnb.FolderItem[] {
            for (let i = 0; i < item.length; i++) {
                target.push(item[i]);
                if (item[i].hasChildren) {
                    getFolderList(item[i].children);
                }
            }
            return target;
        };
        return getFolderList(this.folderItem);
    }

    // get quota() {
    //     let value = this.quotaConfig?.quota;
    //     if (typeof value === 'number' && value) {
    //         return readablizeBytes(value);
    //     } else {
    //         return value || 0;
    //     }
    // }

    // get usage() {
    //     let value = this.quotaConfig?.totalItemSize;
    //     if (typeof value === 'number' && value) {
    //         return readablizeBytes(value);
    //     } else {
    //         return value || 0;
    //     }
    // }

 
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    
    readablizeBytesE(bytes: number) {
        return bytes ? readablizeBytes(bytes) : '-';
    }

    changePeriodType(type: boolean) {
        this.item.checked = type;
    }

    
    open(item: gnb.FolderItem) {
        this.id = item.id;
        this.fileName = item.displayName;
        this.size = item.totalSize;
        this.pcModalShow = true;

    }

    openFolder(item: gnb.FolderItem) {
        this.id = item.id;
        this.fileName = item.displayName;
        this.size = item.totalSize;
        this.folderModalShow = true;

    }
    closeFolderModal(){
        this.folderModalShow = false;
    }

    closePCModal(){
        this.pcModalShow = false;
    }

    async savePCModal(){
        let endDate = new Date(this.item.end);
        endDate.setDate(endDate.getDate() + 1);
        this.item.end = dateutil.uGetDateFormat('yyyy-MM-DD', endDate);

        if (this.size > 0) {
            const process = (result: any) => {
                const { succeed, resultCode, exception, maxSize, transitionSize } = result;
                if (succeed) {
                    this.reset();
                    this.pcModalShow = false;
                }
            };

            if (this.item.checked === false) {
                this.download({
                    folderId: this.id,
                    fileName: this.fileName,
                }as gnb.downloadOption).then(process);
            } else {
                this.download({
                    folderId: this.id,
                    start: this.item.start,
                    end: this.item.end,
                    fileName: this.fileName,
                }as gnb.downloadOption).then(process);
            }
        } else {
            await (window as any).alertAsync(this.$t('M_Settings_Msg16'));
            this.reset();
        }
        this.showModal = false;
    }

    reset() {
        this.item.checked = false;
        this.id = '';
        this.item.start = dateutil.uGetDateFormat('yyyy-MM-DD', new Date());
        this.item.end = dateutil.uGetDateFormat('yyyy-MM-DD', new Date());
        this.fileName = '';
    }

    async deleteItem(item: gnb.FolderItem) {
        if (item.wellKnownFolderName !== 'deleteditems') {
            const message = this.$t('M_Settings_Msg15').toString();
            if (item.totalSize > 0) {
                if (await(window as any).confirmAsync(message) == false) {
                    return;
                } else {
                    api.delete({
                        id: item.id,
                        mode: 'MoveToDeletedItems',
                        deleteSubFolders: false,
                    }).then(async ()=>{
                        await (window as any).alertAsync(this.$t('삭제_되었습니다').toString());
                        this.getFolder();
                    });
                }
            } else {
                await (window as any).alertAsync(this.$t('M_Mail_DeleteError1'));
            }
        }
        else {
            this.deleteCompletely(item);
        }
    }

    async deleteCompletely(item: gnb.FolderItem) {
        const message =
            // '이 항목은 영구적으로 삭제됩니다.\r\n\r\n계속하시겠습니까?';
            this.$t('M_Mail_Msg10').toString() +
            '\r\n\r\n' +
            this.$t('M_Mail_Msg11').toString();
        if (item.totalSize > 0) {
            if (await(window as any).confirmAsync(message) == false) {
                return;
            } else {
                api.delete({
                    id: item.id,
                    mode: 'HardDelete',
                    deleteSubFolders: false,
                }).then(async ()=>{
                    await (window as any).alertAsync(this.$t('영구삭제_되었습니다').toString());
                    this.getFolder();
                });
            }
        } else {
            await (window as any).alertAsync(this.$t('M_Mail_DeleteError1'));
        }
    }

    getFolder(){
        api.getFolderInfo().then((res:gnb.FolderItem)=>{
            this.folderItem = res;
            if(this.folderItem){
                this.loading(false);
            }
        });
    }
    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    created(){
        this.getFolder();
    }
  
}