import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import ChangeApprovalOrder from './ChangeApprovalOrder.vue'
import DocumentMaintenance from './DocumentMaintenance.vue'
import { mapActions, mapGetters} from 'vuex'
import * as types from '@gnb/store/types'
import Signature from './Signature.vue'
@Component({
    components:{
      ModalHeader,
      ModalContent,
      ModalFooter,
      ContentItem,
      PreferenceContentTemplate,
      ChangeApprovalOrder,
      DocumentMaintenance,
      Signature
    },
    methods: {
      ...mapActions({
        load: types.SETTINGS_MYSETTING_LOAD,
        loading: types.COMMON_LOADDING,
        saveSetting: types.SETTINGS_MYSETTING_SAVE,

      }),
    },
    computed: {
      ...mapGetters({
        isLoading: types.COMMON_LOADDING
      })
    }
})
export default class Setting extends Vue {

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  settingInfo: gnb.TA_MYSETTING = {} as gnb.TA_MYSETTING;
  header:string = this.$t('W_Common_Settings').toString();

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  get contentComponents() {
    return [ChangeApprovalOrder, DocumentMaintenance, Signature]
  }
  isLoading !: boolean;

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: A C T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  loading!: (option: boolean) => void;
  load!: () => Promise<gnb.CM_MySetting>;
  saveSetting!: (payload: { data: gnb.TA_MYSETTING, isFirst: boolean }) => Promise<boolean>;
  
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  order(value: any){
    this.settingInfo.tabOrder = value;
  }


  save(info:any){
    this.saveSetting({ data: this.settingInfo, isFirst: false })
    .then(async (res: boolean) => {
    if (res) {
      await (window as any).alertAsync(this.$t('적용되었습니다').toString());
        this.$emit('cancel');
    }
    else {
      await (window as any).alertAsync(this.$t('적용하는_도중에_문제가_발생했습니다').toString());
    }
    });

  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //
    created(){
      this.loading(true);
      this.load().then((res)=>{
          this.settingInfo = res.mySetting;
          if(this.settingInfo){
            this.loading(false);
          }
      });
      
    }
}