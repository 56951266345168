import { ActionTree } from 'vuex';
import * as types from './types'
import api from '@survey/api'


export default <ActionTree<survey.state.SurveyState, RootState>>{
    [types.SURVEY_LIST](action, payload: survey.SurveyListRequest) {

        api.survey.getSurveyLists(payload)
            .then(res => {
                action.commit(types.SURVEY_LIST, res)
                action.commit(types.SURVEY_LIST_TOTALCOUNT, res.totalCount)
            })
    },

    [types.SURVEY_CREATED_SURVEYID](action, payload?: number) {
        action.commit(types.SURVEY_CREATED_SURVEYID, payload)
    },


    [types.SURVEY_QUESTION](action, payload: survey.SurveyQuestionRequest) {

        api.survey.insertSurveyQuestion(payload)
            .then(result => {

                //예외 경우 질문유형이 주관식(대답형)인 경우
                // TYPE 2(주관식)
                //기타옵션 해제
                //화면상에서는 숨김처리하고
                if (payload.questionInfo.questionType == 2) {
                    payload.questionInfo.isAdditionalOption = false
                }


                //생성
                if (payload.questionInfo.surveyQuestionId == null) {
                    payload.questionInfo.surveyQuestionId = result.surveyQuestionId
                    action.commit(types.SURVEY_ADD_QUESTION, payload.questionInfo)
                } else { //수정
                    action.commit(types.SURVEY_UPDATE_QUESTION, payload.questionInfo)
                }
            })
    },

    [types.SURVEY_INIT_QUESTION](action, payload: number) {
        return api.survey.surveyDetail(payload)
            .then(result => {
                action.commit(types.SURVEY_INIT_QUESTION, result.questions)
            })

    },

    [types.SURVEY_DELETE_QUESTION](action, payload: number) {
        api.survey.deleteSurveyQuestion(payload)
            .then(result => {

                action.commit(types.SURVEY_DELETE_QUESTION, payload)
            })
    },


    [types.SURVEY_EXAMPLE](action, payload: survey.SurveyExampleRequest) {
        return api.survey.insertSurveyExamlple(payload)
            .then(result => {
                //생성


                if (payload.exampleInfo.surveyExampleId == null) {
                    payload.exampleInfo.surveyExampleId = result.surveyExampleId
                    action.commit(types.SURVEY_ADD_EXAMPLE, payload.exampleInfo)
                } else { //수정
                    action.commit(types.SURVEY_UPDATE_EXAMPLE, payload.exampleInfo)
                }


            })
    },

    [types.SURVEY_DELETE_EXAMPLE](action, payload: number) {
        api.survey.deleteSurveyExample(payload)
            .then(result => {

                action.commit(types.SURVEY_DELETE_EXAMPLE, payload)
            })
    },

    [types.SURVEY_COPY_QUESTION](action, payload: survey.SurveyQuestionDetail) {



        return api.survey.insertSurveyQuestion(payload)
            .then(result => {

                payload.questionInfo.surveyQuestionId = result.surveyQuestionId
                action.commit(types.SURVEY_ADD_QUESTION, payload.questionInfo)

                payload.examples.forEach(exam => {
                    action.dispatch(types.SURVEY_EXAMPLE, {
                        exampleInfo: {
                            surveyId: payload.questionInfo.surveyId,
                            surveyQuestionId: result.surveyQuestionId,
                            exampleText: exam.exampleText,
                            exampleOrders: exam.exampleOrders,
                            fileCode: exam.fileCode,
                        }
                    })

                })
                //재정렬 딜레이가 없으면 정렬 안됨
                setTimeout(() => {
                    action.commit(types.SURVEY_RESORTING_EXAMPLE, result.surveyQuestionId)
                }, 500);

                return result.surveyQuestionId


            })
    },

    [types.SURVEY_ANSWER](action, payload: survey.SurveyAnswerItem) {
        action.commit(types.SURVEY_ANSWER, payload)
    },


    [types.SURVEY_MENU_PERMISSIONS](action, payload: survey.MenuPermissions) {
       
        
        action.commit(types.SURVEY_ADD_MENU_PERMISSIONS, payload)
        //하나만 있어도 될듯하네
         
        // if(action.state.permissions.length == 0){
        //     //ADD
        //     action.commit(types.SURVEY_ADD_MENU_PERMISSIONS, payload)
        // }else{
        //     //우선 뭔가 있는 상태
        //     let isExists = action.state.permissions.filter(item =>{
        //         return item.MenuId == payload.MenuId
        //     })

        //     if(isExists.length == 0){
        //         //ADD
        //         action.commit(types.SURVEY_ADD_MENU_PERMISSIONS, payload)
        //     }else{
        //         //UPDATE
        //         action.commit(types.SURVEY_UPDATE_MENU_PERMISSIONS, payload)
        //     }
        // }
    },
};
