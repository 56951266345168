import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import * as types from '@orgmap/store/types';
import * as userTypes from '@account/store/types';

import { OrgMemberSelectMutationPayload } from '@orgmap/store/mutations';

import { DepartmentInfo, MemberList } from '@orgmap/components'
import PageNavigationBar from '@appbase/components/Paginate/PageNavigationBar.vue'
import { i18n } from '@appbase/util/i18n-setup'



@Component({
   name: 'PublicAddressMemberListContainer',
   components: { DepartmentInfo, MemberList, PageNavigationBar },
   computed: {
        ...mapGetters({
          members: types.ORG_MEMBER_LIST,
      }),
    },
   methods: {
        ...mapActions({
          load: types.ORG_PUBLIC_MEMBER_LIST,
        }),
        ...mapMutations({
          select: types.ORG_MEMBER_SELECT,
          selectAll: types.ORG_MEMBER_SELECT_ALL,
        }),
    },
})
export default class PublicAddressMemberListContainer extends Vue {

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //  

    @Prop({ type: String, default: '' }) id!: string;
    @Prop({ type: String, default: '' }) deptName!: string;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //   

    loading: boolean = false
    searching: boolean = false;
    keyword: string = '';
    pageRange: number = 10;
    currentPage: number = 1;
    itemsPerPage: number = 50;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    members!: orgmap.TMemberList;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    load!: (payload: orgmap.DLGroupApiOption) => void;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M U T A T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    select!: (payload: OrgMemberSelectMutationPayload) => void
    selectAll!: (payload: boolean) => void

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    get totalCount() {
      return this.members?.totalCount || 0
    }
  
    get name(){
      return this.searching ? i18n.t('검색결과') : this.deptName;
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    initParam(){
      this.keyword = '';
      this.currentPage = 1;
      this.searching = false;
    }

    async loadData(){
      var payload = {
        pageNum: this.currentPage,
        rowSize: this.itemsPerPage,
        searchFilter: 0,
        searchWord: this.keyword,
        identity: this.id
      } as orgmap.DLGroupApiOption
      try{
        this.loading = true;
        await this.load(payload);
      }
      catch(ex){
        throw ex
      }

      this.loading = false;
    }

    handleSelect(user: orgmap.TMemberItem) {
      this.select({
        items: [user],
        select: !user.selected,
      })
    }
  
    handleSelectAll(select: boolean) {
      this.selectAll(select)
    }

    search(){
      if(this.keyword.length){
        this.loadData()
      }
    }

    refresh(){
      this.keyword = '';
      this.searching = false;
      this.loadData();
    }

    /**
     * 페이지 번호 클릭 시 호출됩니다.
     * @param pageNum 
     */
    pageMove(pageNum: number){
      this.currentPage = pageNum;
      this.loadData();
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    @Watch('id')
    onIdChanged(newValue: string, oldValue: string){
      if(newValue){
        this.initParam();
        this.loadData();
      }
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    async mounted() {
      this.initParam();
      await this.loadData();        
    }

    created() {

    }
}