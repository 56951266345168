import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { PersonalFolderListContainer } from '@orgmap/containers'
import PersonalMembersView from './PersonalMembersView.vue';

@Component({
    components: { PersonalFolderListContainer, PersonalMembersView }
})
export default class PersonalAddressBookView extends Vue {

    @Prop({ type: Boolean, default: false }) addableDept!: boolean;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    dept: orgmap.TDepartmentTreeItem | null = null;
    

    select(item: orgmap.TDepartmentTreeItem | null){
        this.dept = item;
    }

}
