import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { ModalLayout } from '@appbase/templates'
import NavigationTab from '../NavigationTab.vue'
import { MenuInfo } from './menu'
import { LanguageTime } from '@gnb/components/Preference/General/LanguageTime'
import { Notice } from '@gnb/components/Preference/General/Notice'
import { Missed } from '@gnb/components/Preference/Mail/Missed'
import { Layout } from '@gnb/components/Preference/Mail/Layout'
import { Signature } from '@gnb/components/Preference/Mail/Signature'
import { Rule } from '@gnb/components/Preference/Mail/Rule'
import { Delete } from '@gnb/components/Preference/Mail/Delete'
import { AutoDelivery } from '@gnb/components/Preference/Mail/AutoDelivery'
import { AutoReply } from '@gnb/components/Preference/Mail/AutoReply'
import { Category } from '@gnb/components/Preference/Mail/Category'
import { Storage } from '@gnb/components/Preference/Mail/Storage'
import { CalendarShared, Calendar } from '../../components/Preference/Calendar'
import { Setting, Confrontation, ChangeApprovalPW, Reception } from '../../components/Preference/Approval'
import  modalHelper  from '@appbase/util/modal-helper'
import AddRule from '@gnb/components/Preference/Mail/Rule/AddRule.vue'

@Component({
    components: { 
        ModalLayout,
        NavigationTab, 
        'language':LanguageTime, 
        'notice':Notice, 
        'missed': Missed, 
        'layout':Layout, 
        'signature':Signature,
        'rule':Rule,
        'delete':Delete,
        'autoDelivery':AutoDelivery,
        'autoReply':AutoReply,
        'category':Category,
        'storage':Storage,
        'calendarView':Calendar,
        'calendarShared':CalendarShared,
        'setting':Setting,
        'confrontationView':Confrontation,
        'changeApprovalPassword':ChangeApprovalPW,
        'addRule': AddRule,
        'reception' : Reception
    }
})
export default class PreferneceModal extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    tmenues: MenuInfo[] = [];
    mchildren: MenuInfo[] = [];
    component: String = "language";

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Watch('mchildren')
    changedMenues(){
        this.clickChildren(this.mchildren);
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    changed(items:MenuInfo[]){
        this.tmenues = items;
        this.getChildrenMenues();
    }

    getChildrenMenues(){
        var isFirst:boolean = false;
        this.tmenues.find((menu)=>{
            if(menu.selected && menu.children){
                this.mchildren = menu.children;
            }
        });
        this.mchildren.forEach((c)=>{
            if(c.selected){
                return;
            }else{
               isFirst = true;
            }
        })
        if(isFirst){
            this.mchildren[0].selected = true;
        }
    }

    clickChildren(items:MenuInfo[]){
        const { to }:any = items.find((i)=>{
            if(i.selected){
                return i;
            }
        });
        if (to) {
            this.component = to.name;
        }
    }

    open(componentName:string){
        this.component = componentName;
    }

    cancel(){
        this.tmenues.forEach((m)=>{
            if(m.name==='일반'){
                m.selected = true;
            }else{
                m.selected = false;
            }
            if(m.children){
                m.children.forEach((c)=>{
                    c.selected = false;
                })
            }
        });
        
        modalHelper.close();
        this.$router.go(0);
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //
    mounted() {
        this.tmenues = require('./menu').default;
        this.getChildrenMenues();
    }
}