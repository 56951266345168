<template>
  <div id="wrapper">
    <!-- <UserInfoWebpart /> -->
    <ApproveMailWebpart :option="webPartItem" />
  </div>
</template>

<script>
import ApproveMailWebpart from "../ApproveMailWebpart/ApproveMailWebpart.vue";
export default {
  components: { ApproveMailWebpart },
  data() {
    return {
      webPartItem: {
      },
    };
  },
};
</script>

<style></style>
