import client from '@/api'

export default {
  login(username: string, password: string): Promise<account.TCurrent> {
    return client.post({
      url: '/commonaccount/auth/api/login',
      data: { userName: username, password },
    })
  },

  loginex(username: string): Promise<account.TCurrent> {
    return client.post({
      url: '/commonaccount/auth/api/loginex',
      data: { username },
    })
  },

  loginHint(loginHint: string): Promise<account.TCurrent> {
    return client.post({
      url: '/commonaccount/auth/api/loginHint',
      data: { username: loginHint },
    })
  },

  logout(): Promise<void> {
    // logout은 api의 ResponseWrapper에 의해 정제된 데이터가 아니므로 HTTP 사용
    return client.get({ url: '/commonaccount/auth/api/logout' })
  },
}
