import { render, staticRenderFns } from "./BaseLayout.vue?vue&type=template&id=10780b9d"
import script from "./BaseLayout.ts?vue&type=script&lang=ts&external"
export * from "./BaseLayout.ts?vue&type=script&lang=ts&external"
import style0 from "./BaseLayout.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports