import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import * as types from '@orgmap/store/types';
import * as userTypes from '@account/store/types';

import { DeptTree } from '@orgmap/components'

@Component({
   name: 'PublicAddressFolderListContainer',
   components: { DeptTree },
   computed: {
        ...mapGetters({
      }),
    },
   methods: {
        ...mapActions({
          load: types.ORG_PUBLIC_FOLDER_LIST,
        }),
        ...mapMutations({
          add: types.ORG_CONTAINER_DEPT_ITEM_ADD
        }),
    },
})
export default class PublicAddressFolderListContainer extends Vue {

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //  

    @Prop({ type: Boolean, default: false }) addableDept!: boolean;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //   

    items: orgmap.TDepartmentTreeItem[] = []
    dept: orgmap.TDepartmentTreeItem | null = null;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    load!: (payload: orgmap.DLGroupApiOption) => Promise<orgmap.DLGroupItemContainer>;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M U T A T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    
    add!: (payload: orgmap.TWishContainerItem) => void;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    async loadData(){
      const payload = {
        pageNum: 1,
        rowSize: 100,
      } as orgmap.DLGroupApiOption;
      const { data, totalCount } = await this.load(payload);
      this.items = data.map((x, idx) => {
        return {
          id: x.identity,
          comCode: x.comCode || '',
          deptCode: x.manageDeptCode,
          deptName: x.displayName,
          deptNameEn: x.displayName,
          dept_Level: '',
          email: x.emailAddress,
          hasChildren: false,
          level: 0,
          parentDeptCode: '',
          children: null,
          expanded: false,
          selected: false,
        } as orgmap.TDepartmentTreeItem
      });
      this.selectDept(this.items[0]);
    }

    selectDept(item: orgmap.TDepartmentTreeItem | null){
      this.dept = item;
      this.$emit('select', item);
    }
  
    addDept(item: orgmap.TDepartmentTreeItem){
      if(this.addableDept){
        const newItem = {
          ...item,
          name: item.deptName,
          title: '',
          personCode: '',
          text: item.deptName,
          value: item.email, 
          selected: false,
        } as orgmap.TWishContainerItem;
    
        this.add(newItem);
      }
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    mounted() {
        this.loadData();
    }

    created() {

    }
}