import { RouteConfig } from 'vue-router'
const Example = () => import(/* webpackChunkName: "example" */ '@example/Index.vue')
const ExampleMaster = () => import(/* webpackChunkName: "example" */ '@example/views/Master.vue')
import I18nExampleView from '../views/I18nExampleView.vue'



const routes: RouteConfig[] = [
  {
    path: '/example',
    component: Example,
    children: [
      {
        path: '',
        component: ExampleMaster,
        children: [
          {
            path: 'i18n',
            name: '',
            component: I18nExampleView,
          },
          {
            path: '',
            redirect: 'i18n',
          },
        ],
      },
    ],
  },
]
export default routes
 