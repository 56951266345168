import Vue from 'vue'
import { Component,Prop } from 'vue-property-decorator'
import ModalLayout from '@appbase/templates/ModalLayout/ModalLayout.vue'
import BoardRead from '@board/components/board/BoardRead.vue'
 

@Component({
    components: { 
        ModalLayout,
        BoardRead
    }
})
export default class ZinusBoardModal extends Vue {
    @Prop({ type: Object }) value!: any;  

    boardTitle: string = "";
    
  
    created(){
        this.boardTitle = this.value.title
    }
}
