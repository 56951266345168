import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapMutations } from 'vuex'
import routerHelper from '../../util/router-helper'
import * as types from '@orgmap/store/types'

@Component({
  name: 'dept-tree-item',
  methods : {
    ...mapMutations({
      setSelectTree : types.ORG_SELECT_TREE_ITEM
    })
  }
})
export default class DeptTreeItem extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: Object }) item!: orgmap.TDepartmentTreeItem
  @Prop({ type: Function }) onExpand!: (item: orgmap.TDepartmentTreeItem) => void
  @Prop({ type: Object, default: null }) current!: orgmap.TDepartmentTreeItem
  @Prop({ type: String }) userlang!: string


  setSelectTree! : (data : boolean)=>void
  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  click: number = 0
  delay: number = 200
  timer: any = null

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //

  /**
   * 부서 클릭시 호출됩니다.
   * @param item
   */
  selectDept(item: orgmap.TDepartmentTreeItem) {
    const _this = this
    this.click++
    if (this.click === 1) {
      this.timer = setTimeout(() => {
        _this.$emit('selectDept', item)
        _this.click = 0
      }, this.delay)

      _this.setSelectTree(true)

    } else {
      clearTimeout(this.timer)
      this.$emit('addDept', item)
      this.click = 0
    }
  }

  /**
   * 부서를 컨테이너에 추가합니다.
   * @param item
   */
  addDept(item: orgmap.TDepartmentTreeItem) {
    this.$emit('addDept', item)
  }
}
