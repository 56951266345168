import api from '@/api'
export enum ContactsStoredType {
  /**
   * database 버전
   */
  DB,
  /**
   * exchage 버전
   */
  EWS,
}
export default {
  getFavoriteFolderList(parentId?: string) {
    return api
      .post({
        url: '/api/contact/folder/list?type=' + ContactsStoredType.DB,
        data: {
          fields: [
            { key: 'Id' },
            { key: 'DisplayName' },
            { key: 'TotalCount' },
            { key: 'ChildFolderCount' },
            { key: 'WellKnownFolderName' },
            { key: 'USER_IS_FAVORITE' },
            { key: 'USER_RENDER_ORDER' },
          ],
          filters: [
            {
              key: 'FolderClass',
              value: 'IPF.Contact',
              condition: 'isEqualTo',
            },
            {
              key: 'USER_IS_FAVORITE',
              value: 'true',
              condition: 'isEqualTo',
            },
          ],
          parentId: parentId,
          wellKnownParentName: 'Contacts',
          type: 'Folder',
          traversal: 'Shallow',
        },
      })
      .then((res: any) => {
        const lists = res.items.map((item: any) => {
          return {
            ...item,
            children: null,
            expanded: false,
            selected: false,
            storedType: ContactsStoredType.DB,
            isFavorite: true,
            type: 'Favorite',
          }
        })
        return lists
      })
  },

  getPersonalFavoriteFolderList(wellKnownParentName: string, parentId?: string) {
    return api
      .post({
        url: '/api/contact/folder/list?type=' + ContactsStoredType.EWS,
        data: {
          fields: [
            { key: 'Id' },
            { key: 'DisplayName' },
            { key: 'TotalCount' },
            { key: 'ChildFolderCount' },
            { key: 'WellKnownFolderName' },
            { key: 'USER_IS_FAVORITE' },
            { key: 'USER_RENDER_ORDER' },
          ],
          filters: [
            {
              key: 'FolderClass',
              value: 'IPF.Contact',
              condition: 'isEqualTo',
            },
            {
              key: 'USER_IS_FAVORITE',
              value: 'true',
              condition: 'isEqualTo',
            },
          ],
          parentId: parentId,
          wellKnownParentName: wellKnownParentName,
          type: 'Folder',
          traversal: 'Shallow',
        },
      })
      .then((res: any) => {
        const lists = res.items.map((item: any) => {
          return {
            ...item,
            children: null,
            expanded: false,
            selected: false,
            storedType: ContactsStoredType.EWS,
            isFavorite: true,
            type: 'Favorite',
          }
        })
        return lists
      })
  },
}
