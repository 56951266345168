
import Vue from 'vue'
import Component from 'vue-class-component'
import api from '../../api'
import { RoleGroup } from '../../api/role'
import { ModalLayout } from '../../templates'
import VueDraggable from '../common/VueDraggable/VueDraggable'
import { TextBoxField } from '../forms'
import { Prop, Watch } from 'vue-property-decorator'
import { ROLE_GROUP_MODAL } from './modal-names'

@Component({
    name: ROLE_GROUP_MODAL,
    components: { ModalLayout, draggable: VueDraggable, TextBoxField },
})
export default class RoleGroupModal extends Vue {
    @Prop({ type: Array, default: () => [] }) initialRoles!: RoleGroup[]

    loading: boolean = false
    modalSize: string = 'width: 800px; height: 600px;'
    /** 사용 가능한 권한그룹 목록 */
    availableRoles: RoleGroup[] = []
    /** 선택된 권한목록에 추가된 권한그룹 목록 */
    selectedRoles: RoleGroup[] = []
    keyword: string = ''

    get dragOptions() {
        return {
            animation: 0,
            group: 'roles',
            //     disabled: !this.editable,
            'ghost-class': 'ghost',
        }
    }

    get roles() {
        return this.availableRoles.filter((x) =>
            x.roleName.includes(this.keyword)
        )
    }

    async created() {
        const selectedIds = this.initialRoles.map((x) => x.roleId)
        const roles = await api.role.getRoleGroups()
        this.availableRoles = roles.filter(
            (x) => !selectedIds.includes(x.roleId)
        )
        this.selectedRoles = roles.filter((x) => selectedIds.includes(x.roleId))
    }

    clear() {
        this.availableRoles.push(...this.selectedRoles)
        this.selectedRoles = []
    }
}
