import { i18n } from '@appbase/util'
import { DirectiveOptions } from 'vue'
import SynapEditorWrapper from './SynapEditorWrapper'

declare var synapEditorConfig: editor.synap.SynapEditorConfig
declare var SynapEditor: any

var callbacks: { [key: string]: Function } = {}
var loadedEditorIds: string[] = []

/** SynapEditor Editor 초기화된 상태 */
export function loaded(id: string) {
    return loadedEditorIds.includes(id)
}

export function unload(id: string) {
    const idx = loadedEditorIds.indexOf(id)
    if (idx >= 0) {
        loadedEditorIds = [
            ...loadedEditorIds.slice(0, idx),
            ...loadedEditorIds.slice(idx + 1),
        ]
    }
    SynapEditor.destroy(id)
}

const editorDirective: DirectiveOptions = {
    inserted: (el, binding, vnode) => {
        const ctx = vnode.context as SynapEditorWrapper
        const { editorId, initialValue, synapopts: options, mode } = ctx
        const { rawName, value } = binding as any
        console.log('inserted', editorId, rawName, value)
        if (rawName === 'v-synap:loaded') {
            callbacks[editorId] = value
        }

        synapEditorConfig = {
            ...synapEditorConfig,
            ...options,
        }

        // 읽기 모드인 경우 툴바 기능 제거
        if (mode === 'view') {
            synapEditorConfig['editor.toolbar'] = []
        }
        synapEditorConfig['editor.lang'] =
            i18n.locale === 'cn' ? 'zh' : i18n.locale

        console.log('synapEditorConfig', synapEditorConfig)

        const eventListeners = {
            initialized: function (e: any) {
                console.log('synap-directive', 'initialized', e)
              var editor = e.editor
                if (mode === 'view') {
                    editor.setMode('preview')
                }
                //loadedEditorIds.push(editor.ID as string)
                loadedEditorIds.push(editorId as string)
                callbacks[editorId](editor)
                if ((window as any).editor_inited) {
                    (window as any).editor_inited(e)
                }
            },
            initializedSync: function (e: any) {
                var editor = e.editor
            },
            afterEdit: function(e: any) {
                if ((window as any).editor_changed) {
                    (window as any).editor_changed(e)
                }
            }
        }

        console.log('editor initialValue size', initialValue.length)
        var editor = new SynapEditor(
            editorId,
            synapEditorConfig,
            initialValue,
            eventListeners
        )
    },
}
export default editorDirective
