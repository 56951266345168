import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import * as dateutil from '@appbase/util/date-helper'
import { openPopup } from '@appbase/util/popup-helper'

@Component({
    components: {

    }
})
export default class TotalBoardListArticle extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    @Prop({ type: Object }) article!: any;
    @Prop({ type: Boolean, default: false }) isPreview!: boolean;

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //
    get createdDate() {
        return dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(new Date(this.article.createdDate)));
    }

    get isNew() {
        if (this.createdDate == dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(new Date()))) {
            return true;
        }
        else {
            return false;
        }
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    articleClick() {
        if (!this.isPreview) {
            openPopup('TotalBoardListArticle', `/BoardPopup/readPopup?SYSID=${this.article.boardId}&ARTICLEID=${this.article.articleId}`, {}, {
                width: 1024,
                height: 700,
            });
        }
    }
}
