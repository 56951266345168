import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import WishListItem from './WishListItem.vue'

@Component({
  components: { WishListItem },
})
export default class WishList extends Vue {
  @Prop({ type: Object }) info!: orgmap.TWishContainer
  @Prop({ type: Function }) onAdd!: () => void
  @Prop({ type: Function }) onDelete!: (item?: orgmap.TWishContainerItem) => void
  @Prop({ type: Function }) onSelect!: (item: orgmap.TWishContainerItem) => void
  @Prop({ type: Function }) onDeleteOne!: (item?: orgmap.TWishContainerItem) => void
}
