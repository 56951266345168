import Vue from 'vue'
import storage from './local-storage-helper'
const KEY = 'EventBus';

export const createEventBus = function (obj: any, eventName: string, callback: (data: any) => void) {
    if (!obj.eventBus) {
        obj.eventBus = new Vue();
    }
    
     
    console.log('createEventBus', eventName, obj.eventBus);
    obj.eventBus.$on(eventName, callback);
   
   
}

export const removeEventBus = function (obj: any, eventName: string, callback: (data: any) => void) {
    if (!obj.eventBus) {
        obj.eventBus = new Vue();
    }
    console.log('removeEventBus', eventName, obj.eventBus);
    obj.eventBus.$off(eventName, callback);
}

export const dispatchEventBus = function (obj: any, eventName: string, data: any) {
    try {
        if (obj.eventBus) {
            console.log('dispatchEvent', eventName, obj.eventBus);
            var dataset = storage.get(KEY) || {};
            dataset[eventName] = data;
            storage.set(KEY, dataset);

            obj.eventBus.$emit(eventName, data);
        }
    } catch (e) {
        //��Ʋ������ â���� �� CORS����
        console.log('dispatchEventError', e);
    }
}

export const listenEvent = function (obj: any, eventName: string, callback: (data: any) => void) {
    if (obj.eventBus) {
        console.log('listenEvent', eventName, obj.eventBus);
        obj.eventBus.$on(eventName, callback);
    }
}

export const getDispatchData = function (eventName: string): any {
    var dataset = storage.get(KEY) || {};
    return dataset[eventName];
}