import api from '@/api'
import axios from 'axios'
 
export default {
  // 출퇴근 목록 조회(사용자)
  getAttendaceUserList(pageNum: number, pageSize: number, startDate: string, endDate: string, attendanceTypeFilter:number, attendanceFilter:number) : Promise<attendance.ResponseAttendanceList>{
    return api.get({
        url: `/api/z/attendance/userlist?pageNum=${pageNum}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&attendanceTypeFilter=${attendanceTypeFilter}&attendanceFilter=${attendanceFilter}`
      });
  },

  getAttendaceList(pageNum: number, pageSize: number, startDate: string, endDate: string, searchFilter: number, searchValue: string | null, attendanceFilter: number, personStatusFilter:number, attendanceTypeFilter:number) : Promise<attendance.ResponseAttendanceList>{
    return api.get({
        url: `/api/z/attendance/list?pageNum=${pageNum}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&searchFilter=${searchFilter}&searchValue=${searchValue}&attendanceFilter=${attendanceFilter}&personStatusFilter=${personStatusFilter}&attendanceTypeFilter=${attendanceTypeFilter}`
      });
  },

  getAttendanceWeekList(startDate: string, endDate: string, pageNum: number, pageSize: number, searchFilter: number, searchText: string, personStatusFilter:number) : Promise<attendance.AttendanceWeekListResponse> {
    return api.get({
      url: `/api/z/attendance/weeklist?StartDate=${startDate}&EndDate=${endDate}&PageNum=${pageNum}&PageSize=${pageSize}&SearchFilter=${searchFilter}&SearchText=${searchText}&PersonStatusFilter=${personStatusFilter}`
    });
  },

  createAttendance(data: attendance.RequestAttendanceAdminCreate): Promise<boolean>{
    return api.post({
      url: `/api/z/attendance/createadmin`,
      data: data
    });
  },

  updateAttendance(data: attendance.RequestAttendanceUpdate): Promise<boolean>{
    return api.post({
      url: `/api/z/attendance/update`,
      data: data
    })
  },

  // 출퇴근 기록 삭제
  deleteAttendance(personCode: string, attendanceId: number): Promise<number>{
    return api.get({
      url: `/api/z/attendance/deleteattendance?personCode=${personCode}&attendanceId=${attendanceId}`
    })
  },

  resetAttendance(attendanceId: number, personCode: string, updatedId: string): Promise<number> {
    return api.get({
      url: `/api/z/attendance/resetattendance?AttendanceId=${attendanceId}&PersonCode=${personCode}&UpdatedId=${updatedId}`
    })
  },

  adminSendMail(attendanceIds: string, attendanceType: string): Promise<number> {
    return api.get({
      url: `/api/z/attendance/adminsendmail?attendanceIds=${attendanceIds}&attendanceType=${attendanceType}`
    })
  },

  // Excel 다운로드
  //downloadListExcel(startDate: string, endDate: string, searchFilter: number, searchValue: string | null, attendanceFilter: number) {
  downloadListExcel(apiUrl: string) {
    return axios({
      method: "get",
      url: api.invalidateUrl(apiUrl),
      responseType: "arraybuffer",
    }).then(function (response) {
      console.log("response", response);
      const disposition = response.headers["content-disposition"];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      var fileName = "";
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
        if (fileName) {
          fileName = decodeURIComponent(fileName);
        }
      }
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      //IE 경우
      const navigator = window.navigator as any;
      if (window.navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}`;
        link.click();
        setTimeout(() => link.remove(), 100);
      }
    });
  },
}