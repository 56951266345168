<template>
  <div id="wrapper">
    <!-- <UserInfoWebpart /> -->
    <ListSuveyWebpart :option="webPartItem"/>
  </div>
</template>

<script>

import ListSuveyWebpart from '../ListSuveyWebpart/ListSuveyWebpart.vue'
export default {
  components: { ListSuveyWebpart },
  data() {
    return {
      webPartItem: {
      tabs:[{
        boardId:44,
        displayCount:3,
        isContentShow:false,
        isImage:false,
        isNoticeShow:false,
        isPaging:false,
        tabTitle:"설문조사입니당",
      }

      ]
      }
    }
  }
}

</script>

<style></style>
