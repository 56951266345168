import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import * as dateutil from '@appbase/util/date-helper'
import { openPopup } from '@appbase/util/popup-helper'
@Component({
    components: {

    }
})
export default class ListImageArticle extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    @Prop({type: Object}) article!: any;
    @Prop({type: Object}) boardDefaultImage!: string;
    @Prop({type: Number}) displayCount!: number;
    @Prop({type: String}) webpartType!: string;
    @Prop({type: Boolean, default:false}) isContentShow!: boolean

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //
    get createdDate(){
        return dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(new Date(this.article.createdDate)));
    }

    get isNew(){
        if(this.createdDate == dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(new Date()))){
            return true;
        }
        else{
            return false;
        }
    }

    get getWidth(){
        return `col-${Math.floor(12/this.displayCount)}`
    }

    get getThumnailType(){
        return this.webpartType == 'tab' ? 'thumnail-type-2' : 'thumnail-type-1'
    }

    get InnerText(){
        var divElem = document.createElement('div');
        divElem.innerHTML = this.article.content;
        return divElem.textContent || divElem.innerText || "";
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    articleClick(){
        openPopup('ListImageWebpart', `/BoardPopup/readPopup?SYSID=${this.article.boardId}&ARTICLEID=${this.article.articleId}`, {}, {
            width: 1024,
            height: 700,
        });
    }
}
