declare var tinymce: any
declare var FileReader: any

const pickerCallback = function (cb: any, value: any, meta: any) {
  console.log('picker callback', value, meta)
  var input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('accept', 'image/*')
  input.onchange = function () {
    var file = (this as any).files[0]

    var reader = new FileReader()
    reader.onload = function () {
      var id = 'blobid' + new Date().getTime()
      var blobCache = tinymce.activeEditor.editorUpload.blobCache
      var base64 = reader.result.split(',')[1]
      var blobInfo = blobCache.create(id, file, base64)
      blobCache.add(blobInfo)

      cb(blobInfo.blobUri(), { title: file.name })
    }

    reader.readAsDataURL(file)
    document.removeChild(input)
  }

  document.body.appendChild(input)
  input.click()
}

const checkInTableSelect = function (editor: any) {
  var node = editor.selection.getNode()
  if (node) {
    if (
      node.nodeName.toLowerCase() === 'table' ||
      node.nodeName.toLowerCase() === 'tr' ||
      node.nodeName.toLowerCase() === 'td'
    ) {
      return true
    }
  }

  return false
}

const tinyWriterOptions = {
  content_style: `
@import url('/cm/gres/faux/fonts/zinus/index.css');

p { font-family: arial; }
body {font-family: arial; font-size: 11pt;}
body#tinymce p {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
}`,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor textcolor',
    'searchreplace visualblocks advcode fullscreen charmap',
    'insertdatetime  table contextmenu  powerpaste', //mediaembed media
    'formatpainter permanentpen pageembed checklist casechange', // 요청으로 wordcount만 제외
  ],
  font_formats:
    // `Deng=Deng;
    // msyh=msyh;
    // simsun=simsun;` +
    `等线=Deng;
    雅黑=msyh;
    宋体=simsun;` +
    '맑은 고딕=Malgun Gothic;' +
    '굴림=gulim;' +
    '굴림체=GulimChe;' +
    '궁서=Gungsuh;' +
    '궁서체=GungsuhChe;' +
    '돋움=dotum;' +
    '돋움체=DotumChe;' +
    '바탕=Batang;' +
    '바탕체=BatangChe;' +
    '새굴림=ngulim;' +
    '함초롬돋움=HCR Dotum;' +
    '함초롬바탕=HCR Batang;' +
    'Arial=arial,helvetica,sans-serif;' +
    'Arial Black=arial black,avant garde;' +
    'Comic Sans MS=comic sans ms, sans-serif;' +
    'Courier New=courier new, courier;' +
    'Times New Roman=times new roman,times;',
  toolbar1:
    'undo redo | fontselect fontsizeselect forecolor backcolor bold italic underline ' +
    '| alignleft aligncenter alignright alignjustify ',
  toolbar2: 'table bullist numlist outdent indent | link image charmap code',
  fontsize_formats:
    '7pt 8pt 9pt 10pt 11pt 12pt 14pt 15pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 36pt 48pt 72pt',
  inline_styles: true,
  automatic_uploads: true,
  file_picker_types: 'image',
  file_picker_callback: pickerCallback,
  relative_urls: false,
  contextmenu: 'link | cut copy paste delete | table',
  powerpaste_html_import: 'merge',
  powerpaste_word_import: 'merge',
  powerpaste_keep_unsupported_src: true,
  powerpaste_allow_local_images: true,
  //convert_urls: false,
  //contextmenu: 'cut copy paste', // 브라우저가 클립보드 접근을 지원하지 않습니다. Ctrl+X/C/V 단축키를 이용하십시오. <== contextmenu_never_use_native 추가 필요
  //contextmenu_never_use_native: true
}
export default tinyWriterOptions
