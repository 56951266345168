import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as dateutil from '@appbase/util/date-helper'
import api from '../../api'
import {
  CalendarDayInfo,
  CalendarEvent,
  CalendarEventColor,
  CalendarFolderInfo,
} from './type'
import CalendarDay from './CalendarDay.vue'
import { timers } from 'jquery'
import { debounce } from '@/modules/@appbase/util/debounce'

const folderRequest: awsRequest.request = {
  fields: [
    {
      key: 'Id',
    },
    {
      key: 'USER_COLOR',
    },
  ],
  filters: [
    {
      operator: 'or',
      filters: [
        {
          key: 'FolderClass',
          value: 'IPF.Appointment',
          condition: 'isEqualTo',
        },
        {
          key: 'FolderClass',
          value: 'IPF.Appointment.Birthday',
          condition: 'isEqualTo',
        },
      ],
    },
    {
      key: 'PR_ATTR_HIDDEN',
      value: 'false',
      condition: 'isEqualTo',
    },
  ],
  wellKnownParentName: 'MsgFolderRoot',
  type: 'Folder',
  Traversal: 'Deep',
}

/**
 *
 * @param folder
 * @param szStart 2021-10-01
 * @returns
 */

const getRequestData = (
  folders: CalendarFolderInfo[],
  szStart: Date,
  szEnd: Date
): webparts.calendarRequlest => {
  return <webparts.calendarRequlest>{
    EwsFolderIDArray: [...folders.filter(x => x.folderType == 'EWS').map((f) => f.id)],
    StartDate: dateutil.uGetDateFormat('yyyy-MM-DDT00:00:00', szStart),
    EndDate: dateutil.uGetDateFormat('yyyy-MM-DDT00:00:00', szEnd),
  }
}
// const getRequestData = (folder: CalendarFolderInfo, szStart: string) => {
//   // 해당 월의 마지막 날짜를 구함
//   const dateEnd = dateutil.IEDate(szStart)
//   dateEnd.setMonth(dateEnd.getMonth() + 1)
//   dateEnd.setDate(dateEnd.getDate() - 1)
//   const szEnd = dateutil.uGetDateFormat('yyyy-MM-dd', dateEnd)
//   // 요청 데이터
//   var listRequest: awsRequest.request = {
//     fields: [
//       { key: 'Id' },
//       { key: 'ParentFolderId' },
//       { key: 'Start', format: 'yyyy-MM-dd HH:mm:ss' },
//       { key: 'End', format: 'yyyy-MM-dd HH:mm:ss' },
//     ],
//     filters: [
//       {
//         operator: 'AND',
//         filters: [
//           {
//             key: 'Start',
//             value: szStart,
//             valueType: 'datetime',
//             valueFormat: 'yyyy-MM-dd',
//             condition: 'IsGreaterThanOrEqualTo',
//           },
//           {
//             key: 'End',
//             value: szEnd,
//             valueType: 'datetime',
//             valueFormat: 'yyyy-MM-dd',
//             condition: 'IsLessThanOrEqualTo',
//           },
//         ],
//       },
//     ],
//     parentId: folder.id,
//     sorts: [{ field: 'Start', dir: 'desc' }],
//     type: 'Calendar',
//   }

//   return listRequest
// }

@Component({
  components: { CalendarDay },
})
export default class Calendar extends Vue {
  monthNames: Array<string> = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  constructor() {
    super()
    this.getEvents = debounce(this.getEvents, 1000) as any
  }
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: String }) value!: string
  @Prop({ type: Array }) folders!: Array<CalendarFolderInfo>
  @Prop({ type: Array }) weeks!: Array<string>

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  MonthMatrix: Array<Array<CalendarDayInfo>> = []
  selectedEvent: Array<CalendarEvent> = []

  matrixStart: Date = new Date()
  matrixEnd: Date = new Date()

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //

  get date(): Date {
    return dateutil.IEDate(this.value)
  }

  get YearMonthDateFormat() {
    const monthName = this.monthNames[this.date.getMonth() - 1]
    return dateutil.uGetDateFormat(`${monthName} yyyy`, this.date)
  }

  createHeaderTitle() {
    const year = this.date.getFullYear()
    const monthName = this.monthNames[this.date.getMonth()]

    return `${monthName} <span>${year}</span>`
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: W A T C H E S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  @Watch('value')
  onValueChanged(newVal: string, oldVal: string) {
    const oldDate = dateutil.IEDate(oldVal)
    const newDate = dateutil.IEDate(newVal)
    const newMonth = newDate.getMonth()
    const newDay = newDate.getDate()
    if (oldDate.getMonth() !== newMonth) {
      this.calcMatrix()
    } else {
      for (var row of this.MonthMatrix) {
        for (var day of row) {
          if (day.selected) day.selected = false
          if (day.month === newMonth && day.day === newDay) day.selected = true
        }
      }
    }
  }

  @Watch('folders')
  onFoldersChanged(folders: Array<CalendarFolderInfo>) {
    this.getEvents(folders)
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E S T Y L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  async created() {
    // this.folders = await api.webPart
    //   .getEWSData(folderRequest)
    //   .then((m: any) => m.items)
    //   .then((items: { id: string; colorCode: string | 'none' }[]) => {
    //     return items.map((item) => {
    //       const defaultColorCode = Math.floor(Math.random() * (10 - 1) + 1).toString()
    //       let color: CalendarEventColor = Object.create({})
    //       color.hasColor = item.colorCode !== undefined && item.colorCode !== 'none'
    //       color.code = color.hasColor ? item.colorCode : defaultColorCode
    //       return { id: item.id, color } as CalendarFolderInfo
    //     })
    //   })

    this.calcMatrix()
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  // ────────────────────────────────────────────────────────────────────────────────
  // event handlers

  handleSelected(day: CalendarDayInfo) {
    this.$emit('input', day.dateValue)
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // calc & render calendar

  /**
   * 달력에 출력할 month 기준 day 를 이차원 배열로 정리
   */
  calcMatrix() {
    let datePickerMatrix = []
    let rows = []
    let today = new Date()
    let daysList = dateutil.uGetDaysInMonth(this.date, true, 0, true)

    this.matrixStart = daysList[0]
    this.matrixEnd = daysList[daysList.length - 1]

    for (let i = 0; i < daysList.length; i++) {
      let date = daysList[i]

      let isToday =
        date.getFullYear() == today.getFullYear() &&
        date.getMonth() == today.getMonth() &&
        date.getDate() == today.getDate()

      let sDate = dateutil.uGetDateFormat('yyyy-MM-DD', date)
      let cDate = dateutil.uGetDateFormat(
        'yyyy-MM-DD',
        dateutil.IEDate(this.value)
      )

      let dateItem: CalendarDayInfo = {
        date: date,
        dateValue: sDate,
        month: date.getMonth(),
        day: date.getDate(),
        colors: [],
        selected: sDate == cDate,
        isToday: isToday,
        isSun: date.getDay() == 0,
        isSat: date.getDay() == 6,
        isOut: date.getMonth() != dateutil.IEDate(this.value).getMonth(),
      }

      rows.push(dateItem)

      if ((i + 1) % 7 == 0) {
        datePickerMatrix.push(rows)
        rows = []
      }
    }

    this.MonthMatrix = datePickerMatrix
    if (this.folders) {
      this.getEvents(this.folders)
    }
  }

  async getEvents(folders: Array<CalendarFolderInfo>, abort: boolean = false) {
    if (abort) {
      return
    }

    // matrix에서 시작 ~ 종료 기간 사이에 있는 일수를 조회
    const findDays = (start: Date, end: Date): CalendarDayInfo[] => {
      //  debugger
      // const dates = dateutil.getDates(start, end)  //여기서 잘못체크 되네 //2022-06-16
      const dates = dateutil.uGetDateFormat('yyyy-MM-DD', start)
      const days: CalendarDayInfo[] = []
      for (let week of this.MonthMatrix) {
        for (let day of week) {
          if (dates.includes(day.dateValue)) {
            days.push(day)
          }
        }
      }
      return days
    }

    // EWS 데이터 조회
    const request = getRequestData(folders, this.matrixStart, this.matrixEnd)
    var results = await api.webPart.getCalendarEvents(request)

    // DB 데이터 조회
    var compCalendarId = folders.find(x => x.folderType == 'DB') ? Number(folders.find(x => x.folderType == 'DB')?.id) : 0;
    if (compCalendarId > 0){
      var item = {
        FolderID: compCalendarId,
        StartDate: this.matrixStart,
        EndDate: this.matrixEnd
      }

      const dbResults = await api.webPart.getCalendarEventList(item)
      results = [...results, ...dbResults]
    }

    results
      .map((e) => {
        return {
          scheduleId: e.id,
          isAllDay: e.isAllDayEvent,
          title: e.subject,
          place: e.location,
          // AllDayEvent의 경우 end로 할 때 다음날로 인식함.
          // start: e.isAllDayEvent
          //   ? dateutil.IEDate(e.end)
          //   : dateutil.IEDate(e.start),
          start: dateutil.IEDate(e.start),
          end: dateutil.IEDate(e.end),
          parentFolderId: e.parentFolderId,
          color: this.findColor(e.parentFolderId)!,
          // color: {
          //   hasColor: this.findColorCode(e.parentFolderId) ? true : false,
          //   code: this.findColorCode(e.parentFolderId),
          // },
        }
      })
      .forEach((item) => {
        // debugger
        const days = findDays(item.start, item.end)
        days.forEach((day, index) => day.colors.push(item.color))
      })
    // if (abort) {
    //   return
    // }

    // // console.log(Calendar.name, this.getEvents.name)
    // // this.renderPicker()

    // // matrix에서 시작 ~ 종료 기간 사이에 있는 일수를 조회
    // const findDays = (start: Date, end: Date): CalendarDayInfo[] => {
    //   const dates = dateutil.getDates(start, end)
    //   const days: CalendarDayInfo[] = []
    //   for (let week of this.MonthMatrix) {
    //     for (let day of week) {
    //       if (dates.includes(day.dateValue)) {
    //         days.push(day)
    //       }
    //     }
    //   }
    //   return days
    // }

    // const requests = folders.map((folder) => {
    //   const szDate = `${dateutil.uGetDateFormat('yyyy-MM', this.date)}-01`
    //   const request = getRequestData(folder, szDate)
    //   return api.webPart.getEWSData(request)
    // })

    // const results = await Promise.all(requests).then((results) => results.map((x: any) => x.items))
    // for (let items of results) {
    //   items.forEach((item: any) => {
    //     const folder = this.folders.find((x) => x.id === item.parentFolderId)
    //     const days = findDays(item.start, item.end)
    //     days.forEach((day) => {
    //       if (folder) {
    //         day.colors.push(folder.color)
    //       }
    //     })
    //   })
    // }
  }
  
  findColor(folderId: string) {
    return this.folders.find((item) => {
      return item.id == folderId
    })?.color
  }

  findColorCode(folderId: string) {
    return this.folders.find((item) => {
      return item.id
    })?.color.code
  }

  changeMonth(count: number) {
    const copyDate = new Date(this.date)
    copyDate.setMonth(copyDate.getMonth() + count)
    this.changeValue(copyDate)
  }

  changeValue(date: Date) {
    this.$emit('input', dateutil.uGetDateFormat('yyyy-MM-DD', date))
  }
}
