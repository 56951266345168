import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class UserListItem extends Vue {
  @Prop({ type: Object }) item!: orgmap.TWishContainerItem
  @Prop({ type: Function }) onSelect!: (item: orgmap.TWishContainerItem) => void
  @Prop({ type: Function }) onDelete!: (item?: orgmap.TWishContainerItem) => void
  @Prop({ type: Function }) onDeleteOne!: (item?: orgmap.TWishContainerItem) => void
}
