import auth from './auth'
import account from './account'
import ac from './access-control'

const apiAccount = {
    auth,
    account,
    ac
}
export default apiAccount
