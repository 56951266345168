<template>
  <tr>
    <th class="vertical-top">{{ name }}<span v-if="required" class="required">*</span></th>
    <td>
      <slot></slot>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    name: null,
    required: false,
  },
}
</script>

<style></style>
