
import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        Dropdown
    }
})
export default class CalendarSharedView extends Vue {
  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  placeholder:string = this.$t('전자메일_주소_또는_이름을_입력하세요').toString();
}