import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import PageNavigationBar from '@appbase/components/Paginate/PageNavigationBar.vue'
import api from '@attendance/api'
import * as attHelper from '@/util/attendance-helper'

@Component({
    components: {
        PageNavigationBar
    }
})
export default class AttendanceManageWeek extends Vue {
    @Prop({ type: Array }) list!: attendance.AttendanceWeekItem[]
    @Prop({ type: Number }) totalCount!: number
    @Prop({ type: Number }) pageNum!: number
    @Prop({ type: Number }) pageSize!: number

    pageRange: number = 5

    // 시작일 종료일 기준으로 최대 근무시간 표시
    setDisplayMaxWorkingTime(stDate: string, edDate: string){
        if (stDate && edDate){
            let dtStDate = new Date(stDate);
            let dtEdDate = new Date(edDate);
            let diffDay = Math.round((Number(dtEdDate) - Number(dtStDate))/(1000*60*60*24)) + 1;
            return diffDay * 8;
        }
        else{
            return 0
        }        
    }

    setDisplayTime(minute: number){
        let nHour = Math.floor(minute / 60);
        let nMinute = minute % 60;

        return ((nHour > 9 ? nHour : `0${nHour}`) + ":" + (nMinute > 9 ? nMinute : `0${nMinute}`))
    }

    setDisplayTimeResult(maxHour:number, stDate: string, edDate: string, minute1: number, minute2: number){
        // let maxWorkingHour = this.setDisplayMaxWorkingTime(stDate, edDate);
        let totalMinute = minute1 + minute2;
        let minuteResult = totalMinute - (maxHour * 60);
        let minusFlag = (minuteResult < 0 ? true : false);
        let displayTime = this.setDisplayTime(minuteResult * (minusFlag ? -1 : 1));
        return (minusFlag ? `-${displayTime}` : `${displayTime}`);    
    }

    
    pageMove(pageNum : number){
        this.$emit('pageMove', pageNum)
    }
}