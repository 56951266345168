<template>
  <div id="inner-wrap" :class="[isFull ? '' : 'sub-content']">
    <div v-if="!page.viewType" class="sub-content-padding" :class="{['sub-content-h-full']: page.full === true}">
      <!-- 서브 페이지 타이틀 -->
      <div class="subpage-header dp-flex">
        <h3 class="ft-darkgreen m-r-m" v-if="page">{{page.title}}</h3>
        <!-- 알람 사용 여부 -->
        <a v-if="isBoard && isAlarm" @click="fnSetAlarm(true)" class="icon-toggle on ft-l"><i class="icon-fux-bell-fill"></i></a>
        <a v-if="isBoard && !isAlarm" @click="fnSetAlarm(false)" class="icon-toggle off ft-l"><i class="icon-fux-bell"></i></a>
        <!-- 즐겨찾기 사용 여부 -->
        <a v-if="isBookmark" @click="fnSetBookmark(true)" class="icon-toggle on m-l-s ft-l" v-bind:title="$t('즐겨찾기해제').toString()"><i class="icon-fux-star-fill"></i></a>
        <a v-if="!isBookmark" @click="fnSetBookmark(false)" class="icon-toggle off m-l-s ft-l" v-bind:title="$t('즐겨찾기에추가').toString()"><i class="icon-fux-star"></i></a>
      </div>
      <!-- // 서브 페이지 타이틀 -->
      <div class="subpage-body">
        <slot name="body" />
      </div>
    </div>
    <slot v-else-if="page.viewType == 1" name="body" />
    <slot v-else name="body" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as types from '../store/types'
import api from '../api'
import * as usertypes from '@account/store/types'
import menuHelper from '@appbase/util/menu-helper'

export default {
  props: { isBoard: { type: Boolean, default: false } , isFull : { type: Boolean, default: false}},
  computed: {
    ...mapGetters({
      page: types.PAGE_CURRENT,
      userCurrent: usertypes.CURRENT
    }),
  },
  data(){
    return{
      userInfo: (this.userCurrent && this.userCurrent.user ? this.userCurrent.user : null),
      isBookmark: false,
      isAlarm: false,
      bookMarkId: 0,
      menuId: this.$route.query.MENUID,
      sysId: (this.$route.query.SYSID ? this.$route.query.SYSID : 0),
      locationPath: document.location.pathname,
      locationSearch: document.location.search
    }
  },
  watch:{
    $route(to, from){
      if (to.query.MENUID != from.query.MENUID){
        this.userInfo = this.userCurrent.user
        this.isBookmark = false        
        this.isAlarm = false
        this.bookMarkId = 0        
        this.menuId = this.$route.query.MENUID
        this.sysId = (this.$route.query.SYSID ? this.$route.query.SYSID : 0)
        this.locationPath = document.location.pathname
        this.locationSearch = document.location.search

        this.fnCheckBookmark();
      }
    }
  },
  methods:{
    // Bookmark 사용 여부 체크
    fnCheckBookmark(){
      // Bookmark 여부 체크
      api.subcontent.getCheckBookmark(this.userInfo.personCode, this.userInfo.comCode, this.locationPath, this.locationSearch, this.menuId).then((res) => {
        if (res > 0){
          this.bookMarkId = res;
          this.isBookmark = true;          
        }
        else
        {
          this.bookMarkId = 0;
          this.isBookmark = false;
        }
      });
    },
    // Bookmark 설정/해제
    fnSetBookmark(flag){
      if (flag){
        // 즐겨찾기 설정 해제
        api.subcontent.deleteBookmark(this.userInfo.personCode, this.userInfo.comCode, this.bookMarkId).then((res) => {
          if (res > -1){
            this.bookMarkId = 0;
            this.isBookmark = false;
          } 
          else{
            console.log("Bookmark 해제 Error")
          }
        });
      }
      else
      {
        let menuItem;
        // 즐겨찾기 설정
        if (this.menuId != 0){
          menuItem = menuHelper.findOne(Number(this.menuId));
        }

        api.subcontent.insertBookmark(this.userInfo.personCode, this.userInfo.comCode, this.menuId, 
                  (menuItem? menuItem.menuName : ''), (menuItem? menuItem.menuNameEn : ''), 'MU', this.locationPath).then((res) => {
                    if (res > 0){
                      this.isBookmark = true;
                      this.bookMarkId = res;
                    }
                  });
      }
    },
    // 알림 사용 여부 체크
    fhCheckAlarm(){
    },
    // 알림 설정/해제
    fnSetAlarm(flag){
      if (flag){
      }
      else
      {
      }
    },
  },
  created(){
    // 변수 초기화
    this.userInfo = this.userCurrent.user
    this.isBookmark = false
    this.isAlarm = false
    this.bookMarkId = 0
    this.menuId = this.$route.query.MENUID
    this.sysId = (this.$route.query.SYSID ? this.$route.query.SYSID : 0)
    this.locationPath = document.location.pathname
    this.locationSearch = document.location.search

    // 즐겨찾기 여부
    this.fnCheckBookmark();
    
    // 알림 사용 여부
    if (this.isBoard && this.sysId != 0){
      this.fhCheckAlarm();
    }
  }
}
</script>

<style></style>
