const path = '$reservedb';

/* getters */
export const MAIN_LISTVIEW_OPEN = `${path}/resource/mainListView/open`; 
export const CALENDAR_LOADING = `${path}/resource/isLoading`; 
export const CALENDAR_DAILYEVENT_LOADING = `${path}/resource/dailyEventLoading`;
export const CALENDAR_SEARCHSET = `${path}/resource/searchSet`;
export const CALENDAR_SEARCH_EVENTS =  `${path}/resource/searchEvents`;
export const CALENDAR_VIEWMODE = `${path}/resource/viewmode`; 
export const SELECTED_RESOURCE_KEYS = `${path}/resourcegroups/selectedkeylist`;
export const SELECTED_RESOURCE_LIST = `${path}/resourcegroups/selectedresourcelist`;
export const CALENDAR_SETTING = `${path}/resource/setting`;
export const CALENDAR_DATE_CURRENT = `${path}/resource/currentdate`;
export const EWS_RESOURCEGROUPS_TREE = `${path}/resourcegroups/tree`;
export const EWS_RESOURCEGROUPS_LIST = `${path}/resourcegroups/list`;
export const MINI_CALENDAR_MONTH_MATRIX_LIST = `${path}/minicalendar/month/list`;
export const CALENDAR_MONTH_MATRIX_LIST = `${path}/resource/month/list`;
export const CALENDAR_WEEK_MATRIX_LIST = `${path}/resource/week/list`;
export const CALENDAR_DATE_MATRIX_LIST = `${path}/resource/date/list`;
export const CALENDAR_ENTIRE_EVENT_LIST = `${path}/resource/entireEventlist`; 
export const CALENDAR_DAILY_EVENT_LIST = `${path}/resource/dailyEventlist`;
export const CALENDAR_RIGHTPANE_OPEN = `${path}/resource/rightPaneOpen`;
export const CALENDAR_LEFTPANE_OPEN = `${path}/resource/leftPaneOpen`;

/* action & mutations */
export const TOGGLE_MAIN_LISTVIEW = `${path}/resource/mainlistview/toggle`
export const MINI_CALENDAR_MONTH_MATRIX_LOAD = `${path}/minicalendar/month/load`;
export const CALENDAR_MONTH_MATRIX_LOAD = `${path}/resource/month/load`;
export const CALENDAR_WEEK_MATRIX_LOAD = `${path}/resource/week/load`;
export const CALENDAR_DATE_MATRIX_LOAD = `${path}/resource/date/load`;
export const CALENDAR_EVENTLIST_LOAD =  `${path}/resource/eventlist/load`;
export const CALENDAR_DAILY_EVENT_LOAD = `${path}/resource/dailyevent/load`;
export const CALENDAR_DATE_SELECT = `${path}/resource/dateselect`;
export const CALENDAR_DATE_SELECTRELOAD = `${path}/resource/dateselectReload`;
export const EWS_RESOURCEGROUPS_LOAD = `${path}/resourcegroups/load`;
export const TOGGLE_RESOURCE_SELECT = `${path}/resourcegroups/toggleselect`;
export const TOGGLE_RESOURCEGROUP_SELECT = `${path}/resourcegroups/togglegroupselect`;
export const CALENDAR_EVENT_CREATE = `${path}/resource/create`;
export const CALENDAR_EVENT_UPDATE = `${path}/resource/update`;
export const CALENDAR_EVENT_CANCEL = `${path}/resource/cancel`;
export const CALENDAR_TOGGLE_RIGHTPANE = `${path}/resource/toggleRightPane`;
export const CALENDAR_TOGGLE_LEFTPANE = `${path}/resource/toggleLeftPane`;
export const CALENDAR_SEARCH_EVENTS_LOAD = `${path}/resource/searchEventsLoad`;
export const CALENDAR_SEARCHSET_UPDATE = `${path}/resource/searchSetUpdate`;


//database 버전
export const EVTBUS_RESOURCE_DETAIL_SHOW =`${path}/resourceDetail/show`

export const RESOURCE_CHECK_APPROVER =`${path}/resourcedb/checkapprover`


export const RESOURCE_SET_ISAPPROVER =`${path}/resourcedb/setisapprover`


export const RESOURCE_SELECTED_RESOURCEIDS =`${path}/resourcedb/selectedResourceIds`


export const RESOURCE_BOOKING_LIST =`${path}/resourcedb/bookinglist`

export const RESOURCE_BOOKING_LIST_TODAY =`${path}/resourcedb/bookinglisttoday`

export const RESOURCE_COLOR_MAPITEM =`${path}/resourcedb/resourcecolormapitem`
export const RESOURCE_MAP_MATRIX =`${path}/resourcedb/resoucemapmatrix`


export const RESOURCE_ALLDAY_EVENTS =`${path}/resourcedb/resourceAlldayEvnets`



export const PREVIOUSE_PAYLOAD=`${path}/resourcedb/previouspayload`




export const RESOURCE_PERMISSIONS=`${path}/resourcedb/resourcepermissions`