import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import CalendarSharedView from './CalendarSharedView.vue'
@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        CalendarSharedView
    }
})
export default class CalendarShared extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    header:string = this.$t('W_Schedule_ShareSchedule').toString();

}