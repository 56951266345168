
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({

})
export default class CheckBox  extends Vue {
    @Prop({ type: Boolean }) value!: boolean;
    @Prop({ type: Boolean, default: false }) readonly!: boolean;
    @Prop({ type: String, default: "" }) label!: string;

    checkBoxModel: boolean = false;

    @Watch('value')
    onValueChange(newVal: Boolean, oldVal: Boolean) {
        this.checkBoxModel = this.value;
    }

    created() {       
        this.checkBoxModel = this.value;
    }

    onChange() {
        this.$emit('input', this.checkBoxModel);
        this.$emit('change');
    }
}
