import store from '@/store'
import axios from 'axios'

import { NavigationGuard } from 'vue-router'

let isSetSkin: boolean = false
const CssSwitchingGuard: NavigationGuard = async (to, from, next) => {

    if (isSetSkin == true) {
        next()
        return
    }
    //최초 헤더에 link 스타일시트를 추가하는 방법으로 진행
    //시점차로 인한 스타일 적용 안된 HTML 이 사용자에게 노출 됨(최초로드 or 캐시를 사용하지 않는 경우)
    //비동기 처리로 변경해서 다운로드 -> Style 태그로 추가 



    const loadCss = (fileName: string): Promise<string> => {
        return axios({
            method: 'GET',
            url: `/cm/gres/faux/css/${fileName}`,
        }).then((res) => res.data)
    }


    const insertStyle = (content: string) => {
        let style = document.createElement("style") as HTMLStyleElement
        style.type = "text/css"
        style.textContent = content
        document.head.appendChild(style)
    }


    let fileNames: Array<string> = []


    //로그인 페이지인 경우
    //포탈로 바로 진입하는 경우일 때도 추가해야됨
    if (to.name == "mllw-account-login" ||
        store.state.$account?.current?.user.comCode === "MELLOW") {
        fileNames = ["skin/skinMellow_login.css", "skin/skinMellow.css"]
    }
 


    for (var name of fileNames) {

        let strCss = await loadCss(name)

        //CSS 파일 내부에 @import 작동이 안되어서 정규식으로 @import 제거하고 개별적으로 style 태그에 추가 함
        if (name === "style.css") {
            strCss = strCss.replace(/^@import.*$/gm, '');
        }

        insertStyle(strCss)
    }

    isSetSkin = true
    next()


}

export default CssSwitchingGuard