import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex';
import WebpartBase from '@webparts/views/WebpartBase'
import * as dateHelper from '@appbase/util/date-helper'
import * as usertypes from '@account/store/types';
import api from '@webparts/api'

export interface TimezoneInfo {
    orders: number
    codeName: string
    counturyName: string
    cityName: string
    utc: number
    timezone:string
}

@Component({
    computed: {
        ...mapGetters({
            user: usertypes.USER
        })
    }
})
export default class ZinusWorldTimeWebpart extends WebpartBase {
    user!: account.TUser

    timezoneInfo: Array<TimezoneInfo> = []
    // CSS 수정사항
    // 팝업 헤더 삭제
    // part-time p-body overflow {overflow : auto}
    // p-body {flex-direction 삭제}

    timmer: number = 0
    toggle: boolean = true
    now: Date  = this.getUtc()
    time: string = dateHelper.uGetDateFormat("H:i")

    created() {
        //this.initData()
        let comCode = this.user.personCode.split("_")[0];

        if(this.user.comCode =="MELLOW")
        {
            comCode = "C033";
        }

        api.webPart.getWebpartItems(comCode, 'WORLDTIME').then(res => {
            if (res && res.length > 0){
                res.forEach(x => {
                    this.timezoneInfo.push({ orders: x.sortOrder, timezone: x.extField01, codeName: x.shortCode, counturyName: x.itemName, cityName: x.extField02, utc: parseInt(x.extField03) });
                });
            }
            else {
                // 조회되는 데이터가 없는 경우 초기 데이터를 사용한다.
                this.initData();
            }
            
            // 초기화 완료 후 타이머 등록
            this.setTimmer()
        });
    }

    setTimmer(){
        
        //타이머 등록
        this.timmer = setInterval(() => {
            this.now = this.getUtc()
        }, 1000 * 60)
    }

    getUtc(){
        const baseDatetime = new Date()
       return new Date(baseDatetime.getTimezoneOffset() * 60 * 1000 + baseDatetime.getTime() )
        
    }

    onClickedBtn() {
        this.toggle = !this.toggle

        clearInterval(this.timmer)

        if(this.toggle == true){
            this.setTimmer()
        }
    }

    onChangedTime(evt: any) {
        clearInterval(this.timmer)
        const strTime = evt.target.value as string
        
        const baseDatetime = new Date()

        this.now = new Date(baseDatetime.getFullYear(), baseDatetime.getMonth(), baseDatetime.getDate(), parseInt(strTime.split(":")[0]), parseInt(strTime.split(":")[0]), 0 )
        



    }

    calculateTime(utc: number) {

        const baseDatetime = new Date(this.now.getTime() + (utc * 60 * 60 * 1000) )
        const formatWithDatetime = new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long' }).formatToParts(baseDatetime)
        // 0: {type: 'weekday', value: 'Thursday'}
        // 1: {type: 'literal', value: ', '}
        // 2: {type: 'day', value: '21'}
        // 3: {type: 'literal', value: ' '}
        // 4: {type: 'month', value: 'April'}
        // 5: {type: 'literal', value: ' '}
        // 6: {type: 'year', value: '2022'}
        // 7: {type: 'literal', value: ' at '}
        // 8: {type: 'hour', value: '03'}
        // 9: {type: 'literal', value: ':'}
        // 10: {type: 'minute', value: '44'}
        // 11: {type: 'literal', value: ':'}
        // 12: {type: 'second', value: '28'}
        // 13: {type: 'literal', value: ' '}
        // 14: {type: 'timeZoneName', value: 'GMT+9'}

        const findValue = (type:string)=>{
            return formatWithDatetime.find(format=>{
                return format.type == type
            })?.value
        }



        return `${findValue("weekday")}, ${findValue("day")} ${findValue("month")} ${findValue("year")}`
    }

    getClassNo(index: number, utc: number) {
        const baseDatetime = new Date(this.now.getTime() + (utc * 60 * 60 * 1000) )
        const strNow = dateHelper.uGetDateFormat("His", baseDatetime)
        return `no${strNow[index - 1]}`
    }


    initData() {
        //추후 api ? 설정이 필요한 부분이면 db 저장하고 api 콜
        // this.timezoneInfo.push({ orders: 1, timezone:"KST", codeName: "KR", counturyName: "Korea", cityName: "Seoul", utc: 9 })
        // this.timezoneInfo.push({ orders: 2, timezone:"EDT", codeName: "USA", counturyName: "USA", cityName: "McDonough", utc: -4 })
        // this.timezoneInfo.push({ orders: 3, timezone:"PDT", codeName: "USA", counturyName: "USA", cityName: "Tracy", utc: -7 })
        // this.timezoneInfo.push({ orders: 4, timezone:"CST", codeName: "CN", counturyName: "China", cityName: "Fujian", utc: 8 })
        // this.timezoneInfo.push({ orders: 5, timezone:"WIB", codeName: "ID", counturyName: "Indonesia", cityName: "Jakarta", utc: 7 })
        // this.timezoneInfo.push({ orders: 6, timezone:"AEST", codeName: "AU", counturyName: "Australia", cityName: "Brisbane", utc: 10 })
        // this.timezoneInfo.push({ orders: 7, timezone:"BST", codeName: "UK", counturyName: "United Kingdom", cityName: "London", utc: 1 })
        // this.timezoneInfo.push({ orders: 8, timezone:"EDT", codeName: "CA", counturyName: "Canada", cityName: "Toronto", utc: -4 })
        // this.timezoneInfo.push({ orders: 9, timezone:"SGT", codeName: "SG", counturyName: "Singapore", cityName: "Singapore", utc: 8 })
        // this.timezoneInfo.push({ orders: 10, timezone:"ICT", codeName: "VN", counturyName: "Vietnam", cityName: "Ho Chi Minh", utc: 7 })
        // this.timezoneInfo.push({ orders: 11, timezone:"JST", codeName: "JP", counturyName: "Japan", cityName: "Tokyo", utc: 9 })
        // this.timezoneInfo.push({ orders: 12, timezone:"CLT", codeName: "CH", counturyName: "Chile", cityName: "Santiago", utc: -4 })

        this.timezoneInfo.push({ orders: 1, timezone:"PDT", codeName: "USA", counturyName: "USA", cityName: "Tracy", utc: -8 })
        this.timezoneInfo.push({ orders: 2, timezone:"WIB", codeName: "ID", counturyName: "Indonesia", cityName: "Jakarta", utc: 7 })
        this.timezoneInfo.push({ orders: 3, timezone:"EDT", codeName: "USA", counturyName: "USA", cityName: "McDonough", utc: -5 })
        this.timezoneInfo.push({ orders: 4, timezone:"CST", codeName: "CN", counturyName: "China", cityName: "Fujian", utc: 8 })
        this.timezoneInfo.push({ orders: 5, timezone:"EDT", codeName: "CA", counturyName: "Canada", cityName: "Toronto", utc: -5 })
        this.timezoneInfo.push({ orders: 6, timezone:"SGT", codeName: "SG", counturyName: "Singapore", cityName: "Singapore", utc: 8 })
        this.timezoneInfo.push({ orders: 7, timezone:"CLT", codeName: "CH", counturyName: "Chile", cityName: "Santiago", utc: -3 })
        this.timezoneInfo.push({ orders: 8, timezone:"KST", codeName: "KR", counturyName: "Korea", cityName: "Seoul", utc: 9 })
        this.timezoneInfo.push({ orders: 9, timezone:"BST", codeName: "UK", counturyName: "United Kingdom", cityName: "London", utc: 0 })
        this.timezoneInfo.push({ orders: 10, timezone:"JST", codeName: "JP", counturyName: "Japan", cityName: "Tokyo", utc: 9 })
        this.timezoneInfo.push({ orders: 11, timezone:"ICT", codeName: "VN", counturyName: "Vietnam", cityName: "Ho Chi Minh", utc: 7 })
        this.timezoneInfo.push({ orders: 12, timezone:"AEST", codeName: "AU", counturyName: "Australia", cityName: "Brisbane", utc: 10 })
    }
}

