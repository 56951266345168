import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import WebpartBase from '@webparts/views/WebpartBase';
import { ListHeader, ListImageArticle } from '../../components/index';
import api from '../../api/index'
import { openPopup } from '@appbase/util/popup-helper'
import { mapGetters } from 'vuex';
import * as typesAccount from '@account/store/types'
@Component({
    components: {
        ListHeader,
        ListImageArticle
    },
    computed: {
        ...mapGetters({
            current: typesAccount.CURRENT,
        }),
    },
})
export default class ListImageWebpart extends WebpartBase {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    activeBoardId!: number;
    nowPageNumber: number = 1;
    totalCount!: number;
    listArticles?: any = [];
    boardDefaultImage: string = "";
    current!: account.TCurrent

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    Refresh() {
        this.nowPageNumber = 1;
        this.getArticles();
    }

    Prev() {
        if (this.nowPageNumber > 1) {
            this.nowPageNumber--;
            this.getArticles();
        }
    }

    Next() {
        if (Math.ceil(this.totalCount / this.option.tabs![0].displayCount!) > this.nowPageNumber) {
            this.nowPageNumber++;
            this.getArticles();
        }
    }

    async load() {
        if (this.option.tabs != null && this.option.tabs.length > 0)
            this.activeBoardId = this.option.tabs[0].boardId;

        this.boardDefaultImage = await api.webPart.getBoardDefaultImage(this.activeBoardId).then((m: any) => m);
        this.getArticles();
    }

    async getArticles() {
        var res = await api.webPart.getArticles(this.activeBoardId, this.option.tabs![0].displayCount!, this.nowPageNumber, false, false).then((m: any) => m);
        
        this.listArticles = res.data;
        this.totalCount = res.totalCount;
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E S T Y L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //
    mounted() {
        this.option.tabs![0].isPaging = true;
        this.load();
    }
}
