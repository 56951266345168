import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// initial state
const state: home.state.HomeState = {
    noticePopupItems: [],
    noticeModalPopupItem : Object.create(null)
};

export default {
    state,
    actions,
    getters,
    mutations,
};
