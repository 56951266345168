
import resourcedb from './resourcedb'

import reqeustdb from './reqeustdb'
import approval from './approval'
import booking from './booking'

export default{
    approval,
    resourcedb,
    reqeustdb,
    booking
}