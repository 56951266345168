export enum EnumCalendarViewType {
    /**
     * 일별 보기
     */
    Daily = 0,

    /**
     * 주별 보기
     */
    Week = 1,

    /**
     * 월별 보기
     */
    Month = 2,
}

export enum ReserveResourceMode {
    Write = 0,
    Edit = 1,
    Read = 2,
    Preview = 3
}



export enum ApprovalStatus
{
    Wating = 0,
    Accept = 1,
    Reject = 2,
    All = 999
}

export enum SendMailStatus
{
    Wating = 0,
    Send = 1,
    Fail = 2
}


export enum SearchFilter
{
    Title = 0,
    ResourceName = 1,
    RequesterName = 2
}



export enum ReserveRecuringType
{
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
    Yearly = 4 // DATABASE 에서는 년단위는 사용 X

}

export enum ReserveRecuringEndType
{
    NotSet = 1,
    SetCount = 2,
    SetDate = 3,

}


export enum BookingRequestType
{
    Daily = 0,
    Weekly = 1,
    Monthly = 2,
}


export enum ReserveCancelTarget
{
    Request = 0,
    Booking = 1,
     
}