class OfficeHelper {
  outside() {
    return true
  }

  notify(
    message: string,
    title: string = '',
    type: 'default' | 'success' | 'error' | 'warning' | 'severe-warning' = 'default'
  ): any {
    if (this.outside()) {
      console.log(title, message, type)
    } else {
      //       return OfficeHelpers.UI.notify(message, title, type)
      return console.log(message, title, type)
    }
  }
}

export default new OfficeHelper()
