//i18n-setup.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios, { AxiosRequestConfig } from 'axios'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  // locale: 'en', // set locale
  // fallbackLocale: 'en',
})

const loadedLanguages = ['en'] // our default language that is preloaded

export function setI18nLanguage(lang: string, dic: { [key: string]: string }) {
  var dicString = JSON.stringify(dic);
  console.log(Date.now(), 'before');
  //dicString = dicString.replace(/\\\\r\\\\n/g,'\n').replace(/\\\\n/g,'\n');
  var cdic = JSON.parse(dicString);
  i18n.setLocaleMessage(lang, cdic)
  console.log(Date.now(), 'after');
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html')?.setAttribute('lang', lang)
  if (!loadedLanguages.includes(lang)) loadedLanguages.push(lang)
  return lang
}

export function getLangData(item: any, key: string) {
  if (i18n.locale == 'ko') {
    return item[key];
  } else {
    return item[key + 'En'] || item[key + 'Eng'] || item[key];
  }
}