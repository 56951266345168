import api from '@/api'

export default {
    getResourceBoards: (comCode : string, id?: number ): Promise<Array<reservedb.ResponseResourceBoardItem>> => {
        var strParam = `${comCode}`
        if(id){
            strParam = strParam + `?id=${id}`
        }
        
        
        return api.get({
            url: `/CommonReserveDb/boardDb/api/${strParam}`,
        });
    },

    getResourceDetail: (id: number ): Promise<reservedb.ResponseResourceDetailItem> => {
        
        // /CommonReserveDb/ResourceDb/api/
        return api.get({
            url: `/CommonReserveDb/ResourceDb/api/detail/${id}`,
        });
    },

    getResourceImageFileInfo: (fileCode: string) : Promise<any> => {
     
        return api.get({
            url: `/file/store/${fileCode}`
        })
      
    },

    getResourceFolder:(id : number) : Promise<Array<reservedb.ResponseResourceFolderItem>> =>{
         
        
        return api.get({
            url: `/CommonReserveDb/boardDb/api/folder/${id}`,
        });
    },


    getResourcePermissions:(id : number) : Promise<Array<reservedb.ResourcePermission>> =>{
         
        
        return api.get({
            url: `/CommonReserveDb/ResourceDb/api/permissions/${id}`,
        });
    }
}