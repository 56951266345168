<template>
  <div id="wrapper">
    <!-- <UserInfoWebpart /> -->
    <ListDefaultWebpart :option="webPartItem" />
  </div>
</template>

<script>
import ListDefaultWebpart from "../ListDefaultWebpart/ListDefaultWebpart.vue";
export default {
  components: { ListDefaultWebpart },
  data() {
    return {
      webPartItem: {
        tabs: [
          {
            boardId: 17,
            displayCount: false,
            displayCount:5,
            isContentShow:false,
            isImage:false,
            isNoticeShow:true,
            isPaging:true,
            tabTitle:this.$t('단순목록').toString(),
          },
        ],
      },
    };
  },
};
</script>

<style></style>
