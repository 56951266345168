import Vue from 'vue';
import { MutationTree } from 'vuex';
import * as types from './types'

export default <MutationTree<survey.state.SurveyState>>{
    [types.SURVEY_LIST](state, payload: survey.SurveyListResponse) {
        state.listGrid = payload
    },

    [types.SURVEY_LIST_TOTALCOUNT](state, payload: number) {
        state.listTotalCount = payload
    },

    [types.SURVEY_CREATED_SURVEYID](state, payload?: number) {
        if (payload == null) {
            state.createdSurveyId = Object.create(null)
        } else {
            state.createdSurveyId = payload
        }
    },

    [types.SURVEY_INIT_QUESTION](state, payload: Array<survey.SurveyQuestionDetail>) {

        state.createdQuestions = payload
    },

    //질문생성
    [types.SURVEY_ADD_QUESTION](state, payload: survey.SurveyQuestionItem) {

        let pushItem = {
            questionInfo: payload,
            examples: []
        } as survey.SurveyQuestionDetail

        state.createdQuestions.push(pushItem)
    },

    //질문수정
    [types.SURVEY_UPDATE_QUESTION](state, payload: survey.SurveyQuestionItem) {
        //보류
        let questions = state.createdQuestions

        questions.map(obj => {
            if (obj.questionInfo.surveyQuestionId == payload.surveyQuestionId) {
                obj.questionInfo.questionOrders = payload.questionOrders
                obj.questionInfo.questionType = payload.questionType
                obj.questionInfo.isAdditionalOption = payload.isAdditionalOption
                obj.questionInfo.questionText = payload.questionText
            }
        })
    },

    //질문삭제
    [types.SURVEY_DELETE_QUESTION](state, payload: number) {

        state.createdQuestions = state.createdQuestions.filter(item => {
            return item.questionInfo.surveyQuestionId != payload
        })
    },

    //보가생성
    [types.SURVEY_ADD_EXAMPLE](state, payload: survey.SurveyExampleItem) {

        state.createdQuestions.forEach(item => {
            if (item.questionInfo.surveyQuestionId == payload.surveyQuestionId) {
                item.examples.push(payload)
                return
            }
        })

    },

    //보기수정
    [types.SURVEY_UPDATE_EXAMPLE](state, payload: survey.SurveyExampleItem) {
        state.createdQuestions.forEach(item => {
            if (item.questionInfo.surveyQuestionId == payload.surveyQuestionId) {
                item.examples.map(e => {
                    if (e.surveyExampleId == payload.surveyExampleId) {
                        e.exampleText = payload.exampleText
                        e.exampleOrders = payload.exampleOrders
                        e.fileCode = payload.fileCode
                    }
                })
                return
            }
        })
    },

    //보기삭제
    [types.SURVEY_DELETE_EXAMPLE](state, payload: number) {

        state.createdQuestions = state.createdQuestions.filter(item => {
            return item.examples = item.examples.filter(exam => {
                return exam.surveyExampleId != payload
            })
        })
    },

    [types.SURVEY_ANSWER](state, payload: survey.SurveyAnswerItem) {
        //debugger
        //설문 응답 추가
        //우선 제거하고 추가? 따로 로직 없어도 될듯?

        //주관식 객관식 구분해서 변경해야 될듯 ? 수정에 대한 identity 가 없음 주관식은 XXXXXX
        //주/객 구분이 아니라 다중 선택만 구분하면 될듯?
        //주관식
        if (payload.isMultipleAnswer) {
            //다중 답변인데 기타 추가 답변인 경우는 교체 함
            if(payload.isAdditionalAnswer == true){
                //제거 대상은 해당 질문에 기타 답변만 
                state.answers = state.answers.filter(item=>{
                    return !((item.surveyQuestionId == payload.surveyQuestionId) && item.isAdditionalAnswer == true)
                })
            } 

            state.answers.push(payload)
           
        }
        //객관식
        else {
            //제거하고 추가
            state.answers = state.answers.filter(item => {
                return (item.surveyQuestionId != payload.surveyQuestionId)
            })

            state.answers.push(payload)
        }
    },

    [types.SURVEY_DELETE_ALL_ANSWER](state, payload?: any) {

        state.answers = []
    },

    [types.SURVEY_DELETE_ANSWER](state, payload: any ) {
        // payload {
        //     questionId
        //     answers
        // }
//debugger
        //복수 선택에 경우 answers 는 example 아이디만 올 수 있음
        //answers 가 숫자가 아닌 경우는 기타 답변 체크 해제 시 'on'으로 옴
        if(isNaN(parseInt(payload.answers))){
            state.answers = state.answers.filter(item => {
                return !(item.surveyQuestionId == payload.questionId && item.isAdditionalAnswer == true)
            })
        }else{

            state.answers = state.answers.filter(item => {
                return item.surveyAnswer != payload.answers
            })
        }

    },

    [types.SURVEY_STATISTICS](state, payload: survey.SurveyDetailResponse) {
        state.statisticsDetail = payload
    },

    [types.SURVEY_RESORTING_QUESTION](state, payload?: any) {
       
        state.createdQuestions.sort((a, b): any => {
            if (a.questionInfo.questionOrders > b.questionInfo.questionOrders)
                return 1
            if (a.questionInfo.questionOrders < b.questionInfo.questionOrders)
                return -1
            return 0
        })
    },

    //payload : questionId
    [types.SURVEY_RESORTING_EXAMPLE](state, payload: number) {
        let targetQuestion = state.createdQuestions.filter(item =>{
            return item.questionInfo.surveyQuestionId == payload
        })

        if(targetQuestion.length > 0){
            targetQuestion[0].examples.sort((a, b): any => {
                if (a.exampleOrders  > b.exampleOrders)
                    return 1
                if (a.exampleOrders < b.exampleOrders)
                    return -1
                return 0
            })
        }

         
    },

    [types.SURVEY_ADD_MENU_PERMISSIONS](state, payload: survey.MenuPermissions) {
        //추가면 Array Push
        state.permissions  = payload
    },

    // [types.SURVEY_UPDATE_MENU_PERMISSIONS](state, payload: survey.MenuPermissions) {
    //     //우선 삭제하고
    //     state.permissions = state.permissions.filter(item =>{
    //         return item.MenuId == payload.MenuId
    //     })

    //     state.permissions.push(payload)
    // },



};
