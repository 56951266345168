import api from '@/api'

export default {
  /**
   * 검색어로 부서 또는 직원을 조회합니다.
   * @param {object} options 검색옵션
   */
  searchOrg: (
    options: orgmap.SearchOptions,
    comCode: string
  ): Promise<orgmap.PagedItemList<orgmap.UserItem>> => {
    let { query } = options
    if (query) {
      query = encodeURIComponent(query)
    }
    var data = {
      ...options,
      comCode: comCode,
    }
    return api.post({
      url: '/api/hr/department/search?searchVal=' + query,
      data: data,
    })
  },
}
