import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import {SignatureView, SignatureOptionView} from '@gnb/components/Preference/Mail/Signature'

@Component({
    components:{ModalHeader,ModalContent,ModalFooter,ContentItem,PreferenceContentTemplate,SignatureView,SignatureOptionView}
})
export default class Signature extends Vue {

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  get contentComponents() {
    return [SignatureOptionView,SignatureView]
  }

  header: string = this.$t('서명').toString();

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  handleSave() {
    const contens = this.$refs.contents as any[]
    contens.forEach((x) => {
      x.save()
    })
  }

  handlePreview(){
    const contens = this.$refs.contents as any[]
    contens.forEach((x) => {
      x.preview()
    })
  }
 
}