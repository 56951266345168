import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import DeptTreeItem from './DeptTreeItem.vue'

import { mapGetters } from 'vuex'
import * as types from '@orgmap/store/types'

@Component({
    name: 'dept-tree',
    components: { DeptTreeItem },
    computed: mapGetters({
        options: types.ORG_OPTION_GET
    })
})
export default class DeptTree extends Vue {

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({ type: Array }) items!: orgmap.TDepartmentTreeItem[]
    @Prop({ type: Function }) onExpand!: (item: orgmap.TDepartmentTreeItem) => void
    @Prop({ type: Object, default: null }) current!: orgmap.TDepartmentTreeItem;
    @Prop({ type: Array }) companies!: orgmap.Company[];
    @Prop({ type: Boolean, default: true }) showCompany!: boolean;
    @Prop({ type: String }) userlang!: string;



    options!: orgmap.TWishContainerOptions
    isFixedComcode: boolean = false


    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    currentCompany: orgmap.Company = {} as any;



    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    selectDept(item: orgmap.TDepartmentTreeItem) {
        this.$emit('selectDept', item);
    }

    addDept(item: orgmap.TDepartmentTreeItem) {
        this.$emit('addDept', item);
    }

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Watch('currentCompany')
    onCurrentCompanyChanged(newValue: orgmap.Company, oldValue: orgmap.Company) {
        if (oldValue.comCode && newValue.comCode && oldValue.comCode !== newValue.comCode) {
            this.$emit('selCompany', newValue);
        }
    }

    @Watch('companies')
    onCompaniesChanged(newValue: orgmap.Company[]) {

        const _this = this
        const defaultLogic = function () {
            //기존 로직
            _this.currentCompany = newValue.filter(x => x.selected === true)[0];
        }

        if (newValue) {
            //2022-01-10 조직도 회사코드 고정 기능 추가
            //옵션으로 유효한 회사코드 입력 시 입력된 회사로 선택 후 고정
            //여기서부터 분기해야겠네
            if (this.options.selectFixedComcode != '') {

                //여기서 수정되어야겠네?
                //기본 로직 실행되고 -> 입력된 코드로 선택
                //옵션 유무 체크 
                //우선 유효한 Comcode 인지 확인
                const comCodes = newValue.filter(com => {
                    return com.comCode == this.options.selectFixedComcode
                })

                //유효한 코드
                if (comCodes.length > 0) {
                    //회사 선택 드롭다운 읽기전용 Flag
                    this.isFixedComcode = true
                    this.currentCompany = comCodes[0]
                    this.$emit('selCompany', comCodes[0]);
                } else {
                    defaultLogic()
                }


            } else {
                defaultLogic()
            }
        }
    }



}
