import Vue from 'vue'
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import * as types from '@gnb/store/types'
import { mapActions, mapGetters } from 'vuex'
import MailApprovalView from './MailApprovalView.vue'
@Component({
    components:{ModalHeader,ModalContent,ModalFooter,ContentItem,PreferenceContentTemplate,Dropdown,MailApprovalView}
    ,computed:{
        ...mapGetters({
            mySetting: types.SETTINGS_MYSETTING_DATA,
        })
    }
    ,methods:{
        ...mapActions({
        saveSetting: types.SETTINGS_MYSETTING_SAVE,
        })
    }
})
export default class MailApproval extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    name: string = this.$t('메일_수신여부').toString();
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({ type: Object }) settingInfo!: gnb.TA_MYSETTING;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
   
    saveSetting!: (payload: { data: gnb.TA_MYSETTING, isFirst: boolean }) => Promise<boolean>;
    mySetting!: gnb.CM_MySetting;
    


    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //


    save(){
        this.settingInfo.updatedDate = new Date();
        this.saveSetting({ data: this.settingInfo, isFirst: false })
        .then(() => {
        });
    }
    
    
}