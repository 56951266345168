import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import { EumEditor } from '@appbase/components/editor'

@Component({
    components:{
      ModalHeader,
      ModalContent,
      ModalFooter,
      ContentItem,
      PreferenceContentTemplate,
      Dropdown,
      EumEditor
    }
})
export default class MissedAutoReply extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({ type: Object }) value!: gnb.MissedAutoReplyItem;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    name:string = this.$t('부재중_자동_회신_보내기').toString();

    tinyopts = {
        menubar: false,
        inline: false,
        height: 500,
        relative_urls: false,
        remove_script_host: true,
        document_base_url: location.origin,
        language: 'ko_KR',
        content_style: `
        body {font-family: arial; font-size: 11pt; line-height: 1.2;}
        body#tinymce p {
            margin-top: 0.3em;
            margin-bottom: 0.3em;
        }`,
        setup: (e: any) => {
          e.on('init', (ed: any) => {
            ed.target.editorCommands.execCommand('fontSize', false, '10pt')
          })
        },
        auto_focus: '',
      }

      onValueChanged(value: string) {
         this.value.replyContent = value;
    }

    dext5opts: editor.dext5.DEXT5Config = {
      EditorBorder: '0',
    } 
  

    save(){

    }
  
}