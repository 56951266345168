export const invalidateUrl = function(path: string): string {
  var url = ''
  if (path.toLowerCase().startsWith('/cm')) {
    url = path
  } else if (path.startsWith('/')) {
    url = process.env.BASE_URL + path.substring(1)
  } else if (path.startsWith('http://') || path.startsWith('https://')) {
    url = path
  } else {
    url = path
  }
  return url
}
