import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex'
import * as orgmapTypes from '@orgmap/store/types'
import orgmapStorage from '@orgmap/util/storage-helper'

import User from './User.vue';
@Component({
    name: 'UserList',
    components: { User },
    computed: {
        ...mapGetters({
            selectUsers: orgmapTypes.ORG_USER_SELECT
        })
    }
})
export default class UserList extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    @Prop({ type: Object }) item!: orgmap.TDepartmentTreeItem

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    selectUsers!: orgmap.TUser[];

    // get filteredUsers() {
    //     return this.users.items.filter((item: any) => {
    //         return item.deptCode === this.deptCode;
    //     });
    // }

    // selectUser(user: any) {
    //     this.$emit('selectUser', user);
    // }

    updated() {
        var selectUserFlat = this.selectUsers.flatMap((user: orgmap.TUser) => user.personCode)

        if (this.item.users) {
            this.item.users?.forEach((user: any) => {
                if (selectUserFlat.includes(user.personCode)) {
                    user.selected = true
                }
            })
        }
        
    }
}
