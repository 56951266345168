import { RouteConfig } from 'vue-router'

const Index = () => import(/* webpackChunkName: "board" */ '@board/Index.vue')
const BoardMaster = () => import(/* webpackChunkName: "board" */ '@board/views/Master.vue')
const ModalMaster = () => import(/* webpackChunkName: "board" */ '@board/views/ModalMaster.vue')

const BoardList = () =>
    import(/* webpackChunkName: "board" */ '@board/components/board/BoardList.vue')

const BoardAdminList = () =>
    import(/* webpackChunkName: "board" */ '@board/components/board/BoardAdminList.vue')

const BoardAlbum = () =>
    import(/* webpackChunkName: "board" */ '@board/components/board/BoardAlbum.vue')

const BoardSummary = () =>
    import(/* webpackChunkName: "board" */ '@board/components/board/BoardSummary.vue')

const BoardWrite = () =>
    import(/* webpackChunkName: "board" */ '@board/components/board/BoardWrite.vue')

const BoardRead = () =>
    import(/* webpackChunkName: "board" */ '@board/components/board/BoardRead.vue')
const BoardViewLog = () =>
    import(/* webpackChunkName: "board" */ '@board/components/board/BoardViewLog.vue')
const PopupMaster = () => import('@board/views/PopupMaster.vue')


//지누스헬프데스크
const HelpdeskList = () =>
    import(/* webpackChunkName: "board" */ '@board/components/board/Helpdesk/HelpdeskList.vue')
const HelpdeskWrite = () =>
    import(/* webpackChunkName: "board" */ '@board/components/board/Helpdesk/HelpdeskWrite.vue')
const HelpdeskRead = () =>
    import(/* webpackChunkName: "board" */ '@board/components/board/Helpdesk/HelpdeskRead.vue')




const BoardProxyView = () => import('@board/views/BoardPropxyView.vue')
const ProxyComponent = () => import('@board/components/Proxy/ProxyComponent.vue')

const routes: RouteConfig[] = [
    {
        path: '/board',
        component: Index,
        children: [
            {
                path: '',
                component: BoardMaster,
                redirect: 'list',
                name: 'boardmodule-main',
                children: [
                    {
                        path: 'list',
                        component: BoardList,
                        name: 'boardmodule-listview',
                        children: [
                            {
                                path: 'modal',
                                component: ModalMaster,
                                children: [
                                    {
                                        path: 'write',
                                        component: BoardWrite,
                                    },
                                    {
                                        path: 'read',
                                        component: BoardRead,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'album',
                        component: BoardAlbum,
                        name: 'boardmodule-albumview',
                        children: [
                            {
                                path: 'modal',
                                component: ModalMaster,
                                children: [
                                    {
                                        path: 'write',
                                        component: BoardWrite,
                                    },
                                    {
                                        path: 'read',
                                        component: BoardRead,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'summary',
                        component: BoardSummary,
                        name: 'boardmodule-summaryview',
                        children: [
                            {
                                path: 'modal',
                                component: ModalMaster,
                                children: [
                                    {
                                        path: 'write',
                                        component: BoardWrite,
                                    },
                                    {
                                        path: 'read',
                                        component: BoardRead,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'admin',
                        component: BoardAdminList,
                        name: 'boardmodule-adminlistview',
                    },
                    {
                        path: 'helpdesk',
                        component: HelpdeskList,
                        name: 'boardmodule-helpdesk',
                        children: [
                            {
                                path: 'modal',
                                component: ModalMaster,
                                children: [
                                    {
                                        path: 'write',
                                        component: HelpdeskWrite,
                                    },
                                    {
                                        path: 'read',
                                        component: HelpdeskRead,
                                    },
                                ],
                            },
                        ],
                    }
                ],
            },

        ],
    },
    {
        path: '/boardpopup',
        component: PopupMaster,
        children: [
            {
                path: 'writepopup',
                component: BoardWrite,
            },
            {
                path: 'readpopup',
                component: BoardRead,
            },
            {
                path: 'viewlogpopup',
                component: BoardViewLog,
            },

            {
                path: 'helpdeskWrite',
                name: "boardpopup-helpdesk-write",
                component: HelpdeskWrite,
            },

            {
                path: 'helpdeskRead',
                name: "boardpopup-helpdesk-read",
                component: HelpdeskRead,
            },
        ],
    },
    {
        path: '/boardproxy',
        name: 'boardproxy',
        component: Index,
        children: [
            {
                path: '',
                redirect: 'proxy'
            },
            {
                path: 'proxy',
                component: ProxyComponent
            },
            {
                path: 'board',
                component: BoardProxyView,
                children: [
                    {
                        path: 'list',
                        component: BoardList,
                        name: 'boardproxy-listview',
                        children: [
                            {
                                path: 'modal',
                                component: ModalMaster,
                                children: [
                                    {
                                        path: 'write',
                                        component: BoardWrite,
                                    },
                                    {
                                        path: 'read',
                                        component: BoardRead,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'album',
                        component: BoardAlbum,
                        name: 'boardproxy-albumview',
                        children: [
                            {
                                path: 'modal',
                                component: ModalMaster,
                                children: [
                                    {
                                        path: 'write',
                                        component: BoardWrite,
                                    },
                                    {
                                        path: 'read',
                                        component: BoardRead,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'summary',
                        component: BoardSummary,
                        name: 'boardproxy-summaryview',
                        children: [
                            {
                                path: 'modal',
                                component: ModalMaster,
                                children: [
                                    {
                                        path: 'write',
                                        component: BoardWrite,
                                    },
                                    {
                                        path: 'read',
                                        component: BoardRead,
                                    },
                                ],
                            },
                        ],
                    }
                ],
            }

        ]
    }
]
export default routes
