import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import * as typesGlobal from '@/store/types'

@Component({
    name:'sub-gnbitem'
})
export default class SubGlobalNavigationBar extends Vue {
    @Prop({ type: Array }) menuItems!: appbase.cache.TMenuItem[]
    @Prop({ type: Number }) menuLevel!: Number

    created(){
    }
}