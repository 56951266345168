import { RouteConfig } from 'vue-router';
const Hr = () =>
    import(/* webpackChunkName: "hr" */ '@hr/Index.vue');
const HrMaster = () =>
    import(
        /* webpackChunkName: "hr" */ '@hr/views/Master.vue'
    );

const LeaveManageAdminView = () => import('@hr/views/LeaveManageAdminView.vue');
const LeaveAdminView = () => import('@hr/views/LeaveAdminView.vue');
const LeaveCodeView = () => import('@hr/views/LeaveCodeView.vue');

const PopupMaster = () => import(/* webpackChunkName: "reserve" */ '@hr/views/PopupMaster.vue')
const LeaveManageAdminModify = () => import(/* webpackChunkName: "reserve" */ '@hr/components/LeaveManageAdmin/Popup/Modify.vue')
const LeaveDetail = () => import(/* webpackChunkName: "reserve" */ '@hr/components/LeaveAdmin/Popup/Detail.vue')
const LeaveCodeAdminModify = () => import(/* webpackChunkName: "reserve" */ '@hr/components/LeaveCode/Popup/Modify.vue')
const LeaveCodeAdminAdd = () => import(/* webpackChunkName: "reserve" */ '@hr/components/LeaveCode/Popup/Add.vue')


const routes: RouteConfig[] = [
	{
		path: '/hr',
		component: Hr,
		children: [
			{
				path: '',
				component: HrMaster,
				children: [
					{
						path: 'leavemanageadmin',
						name: 'leavemanage-admin',
						component: LeaveManageAdminView,
						props: {isManagerView: false}
					},
					{
						path: 'leavemanagemanager',
						name: 'leavemanage-manager',
						component: LeaveManageAdminView,
						props: {isManagerView: true}
					},
					{
						path: 'leaveadmin',
						name: 'leave-admin',
						component: LeaveAdminView,
						props: {isPersonView: false}
					},
					{
						path: 'leave',
						name: 'leave',
						component: LeaveAdminView,
						props: {isPersonView: true}
					},
					{
						path: 'leavecodeadmin',
						name: 'leavecode-admin',
						component: LeaveCodeView
					},
				]
			}
		]
	},
	{
		path: 'leavemanageadminPopup',
		component: PopupMaster,
		children: [
			{
				path: 'modify',
				component: LeaveManageAdminModify,
				props: {isPopup: true}
			}
		]
	},
	{
		path: 'leavePopup',
		component: PopupMaster,
		children: [
			{
				path: 'detail',
				component: LeaveDetail,
				props: {isPopup: true}
			}
		]
	},
	{
		path: 'leavecodePopup',
		component: PopupMaster,
		children: [
			{
				path: 'modify',
				component: LeaveCodeAdminModify,
				props: {isPopup: true}
			},
			{
				path: 'add',
				component: LeaveCodeAdminAdd,
				props: {isPopup: true}
			}
		]
	},
];
export default routes;
