import api from '@/api'

function flatToHierarchy(flat: Array<orgmap.TDepartment>): Array<orgmap.TDepartmentTreeItem> {
  var roots: orgmap.TDepartmentTreeItem[] = []
  var all: { [key: string]: orgmap.TDepartmentTreeItem } = {}
  flat.forEach(function (item) {
    all[item.deptCode] = {
      ...item,
      hasChildren: item.hasChildren === 'TRUE' ? true : false,
      children: null,
      expanded: false,
      selected: false,
      users: null
    }
  })
  Object.keys(all).forEach(function (id) {
    var item = all[id]
    const parentCode = item.parentDeptCode
    if (!parentCode) {
      roots.push(item)
    } else if (parentCode in all) {
      var p = all[parentCode]
      if (!('children' in p) || !p.children) {
        p.children = []
      }
      p.children.push(item)
    } else {
      roots.push(item)
    }
  })
  return roots
}

export default {
  /**
   * 조직도 부서 목록을 조회합니다.
   * @param {object} data
   *              { parentDeptCode: {string}, // 상위 부서 코드
   *                comCode: {string}, // 회사 코드
   *                level: {number} // 조회할 트리 레벨 }
   */
  getOrgTree: function (data: orgmap.DepartmentOption): Promise<orgmap.TDepartmentTreeItem[]> {
    return api
      .post<{ items: orgmap.TDepartment[] }>({
        url: '/api/hr/organization/list',
        data: data,
      })
      .then((res) => flatToHierarchy(res.items))
  },
  /**
   * 부서 코드로 조직도 내 부서 경로를 조회합니다.
   * @param {object} data
   *          { comCode: {string}, // 회사 코드
   *            deptCode: {string} // 부서 코드 }
   */
  getDeptPath: function (data: orgmap.DepartmentOption): Promise<any> {
    return api.post({
      url: '/api/hr/organization/path',
      data: data,
    })
  },
  getDeptInfo: function (comCode: string, deptCode: string): Promise<orgmap.TDepartment> {
    return api.get({
      url: '/api/hr/organization/dept',
      params: {
        comCode,
        deptCode,
      },
    })
  },
  /**
   * 사용자의 메일주소로 부서정보를 조회합니다.
   */
  getUserInfo: function (data: any): Promise<any> {
    return api.get({
      url: '/api/hr/department/info',
      params: { email: data },
    })
  },
  /**
   * 회사코드와 부서코드로 부서원을 조회합니다.
   * @param {object} data
   *              { deptCode: {string}, // 부서 코드
   *                comCode: {string} // 회사 코드
   *               }
   */
  getEmployeeList: function (
    data: orgmap.DepartmentOption
  ): Promise<orgmap.PagedItemList<orgmap.UserItem>> {
    return api.post({
      url: '/api/hr/department/list',
      data: data,
    })
  },
  /**
   * 검색어로 부서 또는 직원을 조회합니다.
   * @param {object} options 검색옵션
   */
  searchOrg: function (
    options: orgmap.SearchOptions,
    comCode: string
  ): Promise<orgmap.PagedItemList<orgmap.UserItem>> {
     
    let { query } = options
    if (query) {
      query = encodeURIComponent(query)
    }
    var data = {
      ...options,
      comCode: comCode
    }
    return api.post({
      url: '/api/hr/department/search?searchVal=' + query,
      data: data,
    })
  },

  /**
   * 회사 목록을 가져옵니다.
   * @returns
   */
  getCompanies: function (): Promise<Array<orgmap.Company>> {
    return api.post({
      url: '/api/hr/organization/getcompanies',
    })
  },



}
