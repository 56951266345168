import Vue from 'vue'
import { Component,Mixins } from 'vue-property-decorator'
// import WebpartBase from '@webparts/views/WebpartBase'
import ZinusBoardBase from '../Base/ZinusBoardBase'
import * as typesAccount from '@account/store/types'
import { mapGetters } from 'vuex'

@Component({
    computed: {
        ...mapGetters({
          current: typesAccount.CURRENT,
        }),
    }
}) 
export default class ZinusNoticeWebpart extends Mixins(ZinusBoardBase) {

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    current!: account.TCurrent

    created(){
        //board 정보를 찾아옴 
        //comCode , deptCode 를 이용해서 법인 게시판부터 찾기 
        //이후에 탭정보 초기화

        //탭정보 초기화 해야 됨
        //this.values.push({isShow: true, tabNo : 0, boardId: 1114, tabName: this.$t("ZINUS_공지사항").toString(),articles: []})
        //this.values.push({isShow: true, tabNo : 1, boardId: 1116, tabName: this.$t("NEW_PRODUCT").toString(),articles: []})
        //this.values.push({isShow: true, tabNo : 2, boardId: 1117, tabName: this.$t("ZINUS_NEWS").toString(),articles: []})
        //this.values.push({isShow: false, tabNo : 3, boardId: 1116, tabName:"Learning & Development",articles: []})
        //this.values.push({isShow: false, tabNo : 4, boardId: 1116, tabName:"Customer Review Report",articles: []})

        this.values.push({isShow: true, tabNo : 0, boardId: 1117, tabName: this.$t("ZINUS_NEWS").toString(),articles: [], isNew: false})
        this.values.push({isShow: true, tabNo : 1, boardId: 1179, tabName: "ZINUS FAMILY",articles: [], isNew: false})
        //this.values.push({isShow: true, tabNo : 2, boardId: 1118, tabName:"L&D",articles: [], isNew: false})

        // 20230626 익명 게시판(SPARK) 게시판 ID 조회
        // var appConfigBoardId = this.getAppConfig('Z_Board_SPARK_ID')
        // if (appConfigBoardId){
        //     this.values.push({isShow: true, tabNo : 3, boardId: Number(appConfigBoardId), tabName:"SPARK",articles: [], isNew: false})
        // }

        // 20230816 익명 게시판 로직 수정
        var customNamelessBoardList = this.getAppConfig('Z_Board_CustomNameless_List');
        if (customNamelessBoardList){
            var jsonData = JSON.parse(customNamelessBoardList);
            var jObj: Array<any> = (jsonData && jsonData["NamelessBoardList"] && jsonData["NamelessBoardList"].length > 0 ? jsonData["NamelessBoardList"] : null);
            var userComCode = this.current.user.personCode.split('_')[0];

            if(this.current.user.comCode == "MELLOW")
            {
                userComCode = "C033";
            }

            if (jObj){
                var namelessBoardInfo = jObj.find(x =>  x.ComCode == userComCode);
                if (namelessBoardInfo){
                    this.values.push({isShow: true, tabNo : 2, boardId: Number(namelessBoardInfo.BoardId), tabName:"SPARK", articles: [], isNew: false})
                }
            }
        }

        //여기서 표시 대상이 아니면 제외 함
        this.values = this.values.filter(item=>{
            return item.isShow == true
        })

        this.initSkeleton(false)



        // 게시물 추가할 때 tabno 로 안해서 중간에 isShow false 인경우 문제가 발생하고 있네?
        this.initArticleData()

        
         //임시데이터 추가
        //  if (process.env.NODE_ENV === "development") {
             
        //     this.initTestData() 
        // }

    }

    onClickedTab(tabNo : number){
        
        this.selectedTabNo = tabNo
    }
 
     
    
}
