import { i18n } from './i18n-setup'
import {i18n as lang} from '@appbase/util'
//Object 내 프로퍼티를 NameKey로 참조하기위한 유틸
export const getProperty = function<T>(obj: T, propkey: string | number) {
    var key = propkey as (keyof T)
    return obj[key];
}

export const setProperty = function<T>(obj: T, propkey: string | number, value: any) {
    var key = propkey as (keyof T);
    obj[key] = value;
}

export const deepCopy = function<T>(obj: T) {    
    if(obj == null || typeof(obj) !== "object" || Array.isArray(obj)) {
        return obj;
    }

    var copy = {} as T;

    for(var key in obj) {
        setProperty(copy, key, deepCopy(obj[key]));
    }

    return copy;
}

export const getNullSafeString = function(obj: any) {
    if (obj == null)
        return "";
    else
        return String(obj);
}

export const getLangProperty = function<T>(obj: T, propkey: string): string {
    //아래 무쓸모임 아래 방법처럼 langproperty 를 가져오면 db schema에 해당 방법과 같이 추가 되어야함
    //해당 property (스키마) 가 없는 경우에는 빈값이 표시 됨
    //language resource 를 활용해서 가져오기

    //#region not use
    // var appender = "";
    // var appenderDif = ""; 
    
    // if(i18n.locale === 'en') {
    //     appender = "_En";
    //     appenderDif = "En";
    // } else if(i18n.locale === 'cn') {
    //     appender = "_Cn";
    //     appenderDif = "Cn";
    // } else if(i18n.locale === 'jp') {
    //     appender = "_Jp"
    //     appenderDif = "Jp";
    // }

    // var key = `${propkey}${appender}` as (keyof T);
    // var returnValue = obj[key];

    // if(returnValue == undefined) {
    //     var keyDif = `${propkey}${appenderDif}` as (keyof T);
    //     returnValue = obj[keyDif] as any;
    // }

    // if(returnValue == undefined)
    //     return "";
    // else
    //     return String(returnValue);

    //#endregion

    return  lang.t(obj[propkey as keyof T] as any).toString()
}