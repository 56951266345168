
import Vue from 'vue'
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import api from '@gnb/api/common'
import * as accountTypes from '@account/store/types'
import { mapGetters,mapActions } from 'vuex'
import * as types from '@gnb/store/types';

type SortField = {
    key: string;
    name: string;
    contraction: string;
    selected: boolean;
};

@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        Dropdown
    },
    computed: {
        ...mapGetters({
          user: accountTypes.USER
        })
    },
    methods: {
        ...mapActions({
         loading: types.COMMON_LOADDING
        }),
    },
})

export default class CalendarView extends Vue {

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    user!: account.User;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    
    loading!: (option: boolean) => void;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    name:string = this.$t('W_Settings_CalendarFirstday').toString();
    firstday: string = '';
    selected: string = 'monday';
    calendarList: gnb.CalendarInfo = {
        actionType: '',
        config: null,
        createdDate: '',
        createdId: '',
        eumNoCondition: null,
        eumPageNumber: null,
        eumRowsPerPage: null,
        eumSearchCategory: null,
        eumSearchKey: null,
        firstDay: 0,
        firstDayMax: null,
        firstDayMin: null,
        isCreate: false,
        isDelete: false,
        isRead: false,
        isUpdate: false,
        persoN_CODE: '',
        rowNum: 0,
        seq: 0,
        updatedDate: '',
        updatedId: '',
        usE_TA_CONFIG: false,
        viewMode: 0,
        viewModeMax: null,
        viewModeMin: null,
        workingEndTime: '',
        workingStartTime: '',
        workingWeek: '',
    };
    calendar: SortField[] = [
        {
            key: '1',
            name: this.$t('W_Settings_Monday').toString(),
            contraction: this.$t('W_Mail_Mon').toString(),
            selected: false,
        },
        {
            key: '2',
            name: this.$t('W_Settings_Tuesday').toString(),
            contraction: this.$t('W_Mail_Tue').toString(),
            selected: false,
        },
        {
            key: '3',
            name: this.$t('W_Settings_Wednesday').toString(),
            contraction: this.$t('W_Mail_Wed').toString(),
            selected: false,
        },
        {
            key: '4',
            name: this.$t('W_Settings_Thursday').toString(),
            contraction: this.$t('W_Mail_Thur').toString(),
            selected: false,
        },
        {
            key: '5',
            name: this.$t('W_Settings_Friday').toString(),
            contraction: this.$t('W_Mail_Fri').toString(),
            selected: false,
        },
        {
            key: '6',
            name: this.$t('W_Settings_Saturday').toString(),
            contraction: this.$t('W_Mail_Sat').toString(),
            selected: false,
        },
        {
            key: '0',
            name: this.$t('W_Settings_Sunday').toString(),
            contraction: this.$t('W_Mail_Sun').toString(),
            selected: false,
        },
    ];

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    selectDay(value: string) {
        if (value) {
            this.firstday = value;
        }
    }
    
    save(){
        this.calendarList.firstDay = parseInt(this.firstday);
        api.setCalendarInfo(this.calendarList).then(async (succeed)=>{
            if(succeed){
                await (window as any).alertAsync('저장되었습니다.');
                this.$emit('cancel');
            }
        })
    }

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    @Watch('calendarList')
    onChanged(){
        this.firstday = this.calendarList.firstDay.toString();
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    created(){
        api.getCalendarInfo(this.user.personCode).then((res)=>{
            this.calendarList = res;
            if(this.calendarList){
                this.loading(false);
            }
        })
    }

}