import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import WebpartBase from '@webparts/views/WebpartBase'
import { ListHeader, ListArticle } from '../../components/index'
import api from '../../api/index'
import { LoadingBar } from '@appbase/components/loading'
import { debounce } from '@appbase/util/debounce'
import { NoData } from '../../components/common'

@Component({
  components: {
    ListHeader,
    ListArticle,
    LoadingBar,
    NoData
  },
})
export default class ListDefaultWebpart extends WebpartBase {
  constructor() {
    super()
    this.getArticles = debounce(this.getArticles, 300) as any
  }

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //
  activeBoardId!: number
  nowPageNumber: number = 1
  totalCount!: number
  listArticles?: any = []
  text: string = this.$t('등록된 데이터가 없습니다.').toString();

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  Prev() {
    if (this.nowPageNumber > 1) {
      this.nowPageNumber--
      this.getArticlesWrapper()
    }
  }

  Next() {
    if (Math.ceil(this.totalCount / this.option.tabs![0].displayCount) > this.nowPageNumber) {
      this.nowPageNumber++
      this.getArticlesWrapper()
    }
  }

  Refresh() {
    this.getArticlesWrapper()
  }

  load() {
    if (this.option.tabs != null && this.option.tabs.length > 0)
      this.activeBoardId = this.option.tabs[0].boardId

    this.getArticlesWrapper()
  }

  getArticlesWrapper() {
    this.loading = true
    this.getArticles()
  }

  async getArticles() {
    await api.webPart
      .getArticles(
        this.activeBoardId,
        this.option.tabs![0]!.displayCount,
        this.nowPageNumber,
        false,
        this.option.tabs![0]!.isNoticeShow
      )
      .then((res: any) => {
        this.listArticles = res.data || []
        this.totalCount = res.totalCount
        this.loading = true
      })
      .catch((err: any) => {
        this.listArticles = []
      })

    setTimeout(() => (this.firstRender = this.loading = false), 200)
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E S T Y L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  mounted() {
    this.listArticles = this.getLoadingData()
    this.load()
  }
}
