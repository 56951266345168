import { invalidateSiteUrl } from './invalidate-url';

type PopupOptions = {
    scrollbars?: 'yes' | 'no';
    toolbar?: number;
    directories?: number;
    menubar?: number;
    resizable?: number;
    status?: number | string;
    width?: number;
    height?: number;
    top?: number;
    left?: number;
    noinvalidateUrl?: boolean;
    help?: number | string;
    scroll?: number | string;
    //
    // modal perperties
    //
    modal?: boolean;
    frame?: boolean;
    dialogWidth?: number | string;
    dialogHeight?: number | string;
};

const handleMessage = function (event: MessageEvent) {
    if (event.origin !== window.origin) {
        return;
    }
    const data = event.data as PopupResponseModel;
    if (data.header === 'eum-popup-response') {
        console.log('popup-response', event);
        // callback(event.data as any);
    }
};

window.addEventListener('message', handleMessage);

// export const isPopup = () => {
//     return router.currentRoute.path?.startsWith('/mailpopup');
// };

var handleSmsSend: any = null;

export const openPopupByType = (id: string, type: 'mail_compose' | 'contacts' | 'orgmap' | 'mail_read' | 'contact-card',
    query: { [key: string]: any; } | null = null, closed?: () => void) => {
    var url: string;
    const combindWithQuery = (url: string): string => {
        console.log('combindWithQuery', url, query)
        let keys: string[];
        if (query && (keys = Object.keys(query)).length > 0) {
            return url += '?' + keys.map(key => `${key}=${encodeURIComponent(query[key])}`).join('&');
        } else {
            return url;
        }
    }
    switch (type) {
        // case 'mail_compose':
        //     url = combindWithQuery(`/mailpopup/compose`);
        //     if (!handleSmsSend) {
        //         handleSmsSend = (data: any) => {
        //             var szData = localStorage?.getItem(eventTypes.SEND_SMS);
        //             console.log('handleSmsSend', szData);
        //             if (szData) {
        //                 //localStorage?.removeItem(eventTypes.SEND_SMS);
        //                 var addrs = JSON.parse(szData);
        //                 openMailSms(addrs as string[]);
        //             }
        //         }
        //         createEventBus(window, eventTypes.SEND_SMS, handleSmsSend);
        //     }
        //     //openPopup(id, url, { width: 1050, height: 760 }, () => {
        //     var newWindow = openPopup(id, url, { width: 810, height: 900, resizable: 1 }, () => {
        //         if (closed) {
        //             closed();
        //         }
        //     });
        //     newWindow.focus();
        //     break;

        // case 'mail_read':
        //     break;

        case 'orgmap':
            url = combindWithQuery(`/orgmap`);
            openPopup(id, url, { width: 1000, height: 760, resizable: 1 }, closed);
            break;

        // case 'contacts':
        //     url = combindWithQuery(`/contacts`);
        //     openPopup(id, url, {
        //         width: 1024,
        //         left: (screen.width - 1024) / 2,
        //         height: 650,
        //         top: (screen.height - 650) / 2,
        //         scrollbars: 'no',
        //         resizable: 1
        //     }, closed);
        //     break;

        // case 'contact-card':
        //     url = combindWithQuery(`/contact-card`);
        //     openPopup(id, url, {
        //         scrollbars: 'no',
        //         resizable: 0,
        //         modal: true,
        //         dialogWidth: '380px',
        //         dialogHeight: '600px',
        //         status: 'no',
        //         help: 'no',
        //         scroll: 'no'

        //     }, closed);
        //     break;
    }
}

export const openPopup = (
    id: string,
    url: string,
    options: PopupOptions,
    closed?: () => void
) => {
    const style = buildStyle(options);
    let newUrl = options.noinvalidateUrl === true ? url : invalidateSiteUrl(url);
    console.log('openPopup', newUrl, style);
    var newWindow: any;

    //options.frame = true; // 전역설정 ISFRAME

    if (options?.frame === true && (window as any).showiFrameWindow) {
        newUrl = AddFrameParam(newUrl);
        newWindow = (window as any).showiFrameWindow(newUrl, `popup_${id}`, style);
    }
    else if (options?.modal === true && (window as any).showModalDialog) {
        newUrl = AddPopupParam(newUrl);
        newWindow = (window as any).showModalDialog(newUrl, `popup_${id}`, style);
    } else {        
        newUrl = AddPopupParam(newUrl);
        newWindow = window.open(newUrl, `popup_${id}`, style);
        newWindow?.focus();
    }

    // if (callback) {

    if (newWindow) {
        var createTimeout = function () {
            return setTimeout(() => {
                if (newWindow?.closed) {
                    clearTimeout(timeout);
                    if (closed) {
                        closed();
                    }
                } else {
                    timeout = createTimeout();
                }
            }, 10);
        };
        var timeout = createTimeout();
    }
    // }
    return newWindow;
};

export const openPopupWithPost = (
    id: string,
    url: string,
    options: PopupOptions,
    data: any
) => {
    // const style = buildStyle(options);
    // var form = document.createElement('form');
    // form.setAttribute('method', 'post');
    // form.setAttribute('action', url);
    // form.setAttribute('target', name);
    // for (var i in params) {
    //     if (params.hasOwnProperty(i)) {
    //         var input = document.createElement('input');
    //         input.type = 'hidden';
    //         input.name = i;
    //         input.value = params[i];
    //         form.appendChild(input);
    //     }
    // }
    // document.body.appendChild(form);
    // //note I am using a post.htm page since I did not want to make double request to the page
    // //it might have some Page_Load call which might screw things up.
    // window.open('post.htm', name, windowoption);
    // form.submit();
    // document.body.removeChild(form);
    // var newWindow = window.open(invalidateSiteUrl(url), `popup_${ id }`, style);
    // newWindow?.focus();
    // return newWindow;
};

const buildStyle = (options: PopupOptions) => {
    const defaultOptions: PopupOptions = {
        width: 100,
        height: 100,
        scrollbars: 'yes',
        resizable: 1
    };
    const finalOptions: PopupOptions = {
        ...defaultOptions,
        ...options,
    };

    const currentWindowWidth = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
            ? document.documentElement.clientWidth
            : screen.width;
    const currentWindowHeight = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : screen.height;

    const systemZoom = currentWindowWidth / window.screen.availWidth;
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY;
    if (!finalOptions.left && finalOptions.width) {
        finalOptions.left =
            (currentWindowWidth - finalOptions.width) / 2 / systemZoom +
            dualScreenLeft;
    }
    if (!finalOptions.top && finalOptions.height) {
        finalOptions.top =
            (currentWindowHeight - finalOptions.height) / 2 / systemZoom +
            dualScreenTop;
    }

    if (options.modal) {
        return Object.keys(finalOptions)
            .map((key) => `${key}: ${(finalOptions as any)[key]};`)
            .join('');
    }
    else {
        const style = Object.keys(finalOptions)
            .map((key) => `${key} = ${(finalOptions as any)[key]} `)
            .join(',');
        return style;
    }
};

export const AddPopupParam = (url: string): string => {
    if (url?.toLowerCase().indexOf('pop=1') == -1) {
        if (url?.indexOf('?') == -1) {
            url = url + '?';
        }
        url = (url + '&pop=1');
    }
    return url;
};

export const AddFrameParam = (url: string): string => {
    if (url?.toLowerCase().indexOf('pop=2') == -1) {
        if (url?.indexOf('?') == -1) {
            url = url + '?';
        }
        url = (url + '&pop=2');
    }
    return url;
};

export const AddRedirectParam = (url: string): string => {
    if (url?.toLowerCase().indexOf('redirected=1') == -1) {
        if (url?.indexOf('?') == -1) {
            url = url + '?';
        }
        url = (url + '&redirected=1');
    }
    return url;
};