import Vue from 'vue'
import store from '@/store'
import { NavigationGuard } from 'vue-router'

const SkinGuard: NavigationGuard = async (to, from, next) => {
  console.log(`Evaluating ${SkinGuard.name}...`)
  const current = (store.state as any).$account?.current as account.TCurrent | null | undefined
  if (current) {
    // ─────────────────────────────────────────────────────────────────
    // 시스템 스킨(테마) 적용
    if (current.theme) {
      let link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href = current.theme
      document.head.appendChild(link)
    }
    next()
  } else {
    throw new Error(
      `[INVALID_OPERATION_ERROR] ${SkinGuard.name} : store.state.$account is not initialized.`
    )
  }
}

export default SkinGuard
