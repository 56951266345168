import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { uApmStatusClass, uGetValue, uShowForm } from '../../util';




@Component({
    name: 'DocumentField',
    components: {},
    computed: {
        ...mapGetters({
        }),
    },
    methods: {
        ...mapActions({
        }),
        ...mapMutations({
        }),
    },
})
export default class DocumentField extends Vue {

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //  

    @Prop({ type: Object, default: {} }) Item !: any;
    @Prop({ type: Object, default: {} }) ColumnInfo !: any;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //   

    uGetValue: any = uGetValue;
    uShowForm: any = uShowForm;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    get ApmStatusClass(): string {
        return uApmStatusClass(this.Item.APMStatus);
    }

    get ApmStatusClass2(): string {
        return uApmStatusClass(this.Item.APMStatus).replace('-sta', '-status');
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M U T A T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    getLeftPercent() {
        let gap: number = 100 / this.Item.MaxStep;
        return (gap * (this.Item.CurrentStep - 1)) + '%';
    }

    getCurrentPercent() {
        let gap: number = 100 / this.Item.MaxStep;
        return (gap * 1) + '%';
    }

    getRightPercent() {
        let gap: number = 100 / this.Item.MaxStep;
        return (gap * (this.Item.MaxStep - this.Item.CurrentStep)) + '%';
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    mounted() {

    }

    created() {

    }
}
