import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import * as dateutil from '@appbase/util/date-helper'
import { openPopup } from '@appbase/util/popup-helper'
@Component({
  components: {},
})
export default class ListImageSwipeArticle extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //
  @Prop({ type: Object }) article!: any
  @Prop({ type: Object }) boardDefaultImage!: string
  @Prop({ type: Number }) displayCount!: number
  @Prop({ type: String }) webpartType!: string

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //
  get createdDate() {
    return dateutil.uGetDateFormat(
      'yyyy-MM-DD',
      dateutil.IEDate(new Date(this.article.createdDate))
    )
  }

  get isNew() {
    if (this.createdDate == dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(new Date()))) {
      return true
    } else {
      return false
    }
  }

  get getWidth() {
    return `col-${Math.floor(12 / this.displayCount)}`
  }

  get getThumnailType() {
    return this.webpartType == 'tab' ? 'thumnail-type-2' : 'thumnail-type-1'
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  articleClick() {
    openPopup(
      'ListImageWebpart',
      `/BoardPopup/readPopup?SYSID=${this.article.boardId}&ARTICLEID=${this.article.articleId}`,
      {},
      {
        width: 1024,
        height: 700,
      }
    )
  }

  getOrginInUrl() {
    if (process.env.NODE_ENV === 'production') {
      const currentUrl = new URL(window.location.href)
      return `${currentUrl.origin}/cm`
    } else {
      //개발
      return `http://localhost:5000`
    }
  }

  getThumbnail() {
    var baseUrl = this.getOrginInUrl()
    if (!baseUrl.endsWith('/')) baseUrl += '/'

    const imageUrl = this.article.imageFilePath
      ? '/cm/api/BoardApi/Article/thumbnail?fileName=' + this.article.imageFilePath
        : '/cm/gres/faux/images/img_not_found.png'
      //: this.boardDefaultImage
    // const url =
    //   baseUrl +
    //   `CommonSurvey/thumbnail/api?Width=120&Height=100&ImgUrl=${encodeURIComponent(imageUrl)}`
    // return url
    return imageUrl
  }
}
