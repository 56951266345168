import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem} from '@gnb/components/Common'
import DatePicker  from '@appbase/components/DatePicker/DatePicker.vue'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import AutoReplyView from "@gnb/components/Preference/Mail/AutoReply/AutoReplyView.vue"
@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        DatePicker,
        PreferenceContentTemplate,
        AutoReplyView
    }
})
export default class AutoReply extends Vue {
 //
 // ──────────────────────────────────────────────── I ──────────
 //   :::::: D A T A : :  :   :    :     :        :          :
 // ──────────────────────────────────────────────────────────
 //

 header:string = this.$t('자동회신').toString();
}