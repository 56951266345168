import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import ConfrontationView from './ConfrontationView.vue'
import { mapGetters,mapActions } from 'vuex'
import * as types from '@gnb/store/types';
@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        ConfrontationView
    },
    methods: {
        ...mapActions({
         loading: types.COMMON_LOADDING
        }),
      },
      computed: {
        ...mapGetters({
          isLoading: types.COMMON_LOADDING
        })
    }
})
export default class Confrontation extends Vue {
  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  header:string = this.$t('대결자_설정').toString();
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: A C T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  
  loading!: (option: boolean) => void;

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  isLoading !: boolean;

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  created(){
    this.loading(true);
  }

}