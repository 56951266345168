import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import ChangeApprovalPWView from './ChangeApprovalPWView.vue'
@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        ChangeApprovalPWView
    }
})
export default class ChangeApprovalPW extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    header:string = this.$t('결재_비밀번호_설정').toString();

}