import Vue from 'vue';
import * as types from '@board/store/types'

import { Component, Prop, Watch } from 'vue-property-decorator';
import { readablizeBytes } from '@appbase/util/size-helper';
import { mapActions } from 'vuex';
import api from '@board/api';
import fileViewer from '@appbase/util/doc-viewer-helper'

const images: { [key: string]: string[] } = {
    doc: ['docx', 'docm', 'doc', 'dotx', 'dotm', 'dot', 'rtf'],
    excel: ['xlsx', 'xlsm', 'xlsb', 'xls', 'csv', 'xltx'],
    ppt: ['pptx', 'pptm', 'ppt', 'potx', 'potm', 'pot', 'ppsx', 'ppsm', 'pps'],
    img: ['png', 'jpg', 'jpeg', 'gif'],
    pdf: ['pdf'],
    txt: ['txt', 'log', 'xml'],
    zip: ['zip', '7z', 'gz'],
};


@Component({
    methods: {
        ...mapActions({
            fetchFile: types.FETCH_FILE,
        })
    }
})

export default class AttachFileField extends Vue {
    @Prop({ type: Number }) articleId!: number;
    @Prop({ type: Boolean }) useAttachmentLog!: boolean;
    @Prop({ type: String }) fileCode!: string;
    @Prop({ type: Number }) articleVersion!: number

    fetchFile!: (code: string) => Promise<article.FileStore[]>;

    files: article.FileStore[] = [];
    fileCount: number = 0;
    showFile: boolean = false;
    attachIconClass: common.DynamicClass = {
        'icon-down': true,
        'icon-up': false
    }

    beforeMount() {
        if(this.fileCode != null && this.fileCode.length > 0) {
           if (this.articleVersion == 0){
               this.fetchFile(this.fileCode).then(res =>{
                   this.files = res;
                   this.fileCount = res.length;
                });
           }
           else{
               this.getFileHistory();               
           }
        }        
    }

    toggleFile(){
        this.showFile = !this.showFile;

        if (this.showFile) {
            this.attachIconClass["icon-up"] = true;
            this.attachIconClass["icon-down"] = false;
        } else {
            this.attachIconClass["icon-up"] = false;
            this.attachIconClass["icon-down"] = true;
        }
    }

    getSize(size: number) {
        return readablizeBytes(size);
    }

    download(path: string, idx: number) {   
        if(this.useAttachmentLog) {
            api.article.createAttachmentLog({
                articleId: this.articleId,
                fileCode: this.fileCode,
                fileId: this.files[idx].id,
                fileName: this.files[idx].name
            })
        }
                
        var iframeAttachments = this.$refs.refAttachment as HTMLIFrameElement[];
        var iframeAttachment = iframeAttachments[idx];

        iframeAttachment.src = path;
    }

    imageUrl(file: boardmail.EwsAttachment) {
        const ext = this.getExtensions(file.name);
        var aa = 'default';
        if (ext) {
            var key = Object.keys(images).find((key: string) => {
                var supports = images[key];
                return supports.includes(ext?.toLowerCase());
            });
            if (key) {
                aa = key;
            }
        }
        return `/cm/gres/img/type_${aa}.png`;
    }

    getExtensions(filename: string) {
        return filename.split('.').pop();
    }

    @Watch('articleVersion')
    getFileHistory(){
        api.article.getArticleFileHistory(this.fileCode, this.articleVersion).then(res => {
            this.files = res;
            this.fileCount = res.length;            
        });
    }

    openViewer(name: string, path: string) {
        fileViewer.open(name, path.replace("https://zone.zinus.com", "https://localhost:8447"), false)
    }
}