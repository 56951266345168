import { MutationTree } from 'vuex';
import * as types from '@home/store/types'

export default <MutationTree<home.state.HomeState>>{

    [types.NOTICEPOPUP_SET](state, payload: Array<noticepopup.NoticeItem>) {
        state.noticePopupItems = payload
    },

    [types.NOTICEPOPUP_MODAL_SET](state, payload: noticepopup.ModalNoticeItem) {
        state.noticeModalPopupItem = payload
    },
};
