import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({

})
export default class SelectUserField  extends Vue {
    @Prop ({ type: String }) displayName!: string;
    @Prop({ type: String }) fieldValue!: string; 

    displayUserText: string = "";

    created() {
        if(this.fieldValue != null && this.fieldValue.length > 0) {
            var initObject = JSON.parse(this.fieldValue);
            this.displayUserText = `${initObject.name} ${initObject.title}`;
        }
    }
}