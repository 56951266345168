import store from '@/store'
import * as types from '@appbase/store/types'
const _ = require('lodash')

const showModal: appbase.ShowModalDelegate = <TValue>(
    options: appbase.TModalInfo<TValue>
) => {
    console.log('eum-appbase', 'showModal', options)
    const { force, ...etc } = options
    if (force) {
        // 항상 모달로만 표시
    }
    store.commit(
        types.MODAL_CURRENT,
        {
            ...etc,
            // name: _.kebabCase(etc.name),
        } || null
    )
}

const closeModal = () => {
    store.commit(types.MODAL_CURRENT, null)
}

const modalHelper = {
    show: showModal,
    close: closeModal,
}

export default modalHelper
