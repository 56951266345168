<template>
    <!-- <UserInfoWebpart /> -->
    <InboxWebpart :option="webPartItem" />
</template>

<script>
import InboxWebpart from "../InboxWebpart/InboxWebpart.vue";
export default {
  components: { InboxWebpart },
  data() {
    return {
      webPartItem: {
        displayCount:7
      },
    };
  },
};
</script>

<style></style>
  @Prop({ type: Boolean, default: true }) isArrow!: boolean
