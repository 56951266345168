import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import StorageView from '@gnb/components/Preference/Mail/Storage/StorageView.vue'
import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import { ModalLayout } from '@appbase/templates'
import PopupModal from '@gnb/components/Common/PopupModal.vue'


@Component({
    components:{
        PopupModal,
        ModalLayout,
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        StorageView,
        DatePicker
    }
})
export default class StorageModal extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    
    @Prop({type: Boolean}) show!: false;
    @Prop({type: Object}) value!: gnb.StorageItem;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    
    checkDisabled: boolean = true;

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    

    @Watch('value.checked')
    onChecked() {
        if (this.value.checked) {
            //false일때
            this.checkDisabled = false;
        } else if (!this.value.checked) {
            this.checkDisabled = true;
        }
    }
}