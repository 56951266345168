import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { i18n } from '@appbase/util/i18n-setup'

import * as types from '@orgmap/store/types';
import * as userTypes from '@account/store/types';

import { OrgMemberSelectMutationPayload } from '@orgmap/store/mutations';
import { ContactsStoredType, FilterComparisonModes, FilterConditions, FilterContainmentModes } from '@orgmap/types/enums';

import { DepartmentInfo, MemberList } from '@orgmap/components'
import PageNavigationBar from '@appbase/components/Paginate/PageNavigationBar.vue'

@Component({
   name: 'PersonalMemberListContainer',
   components: { DepartmentInfo, MemberList, PageNavigationBar },
   computed: {
        ...mapGetters({
          members: types.ORG_MEMBER_LIST,
      }),
    },
   methods: {
        ...mapActions({
          load: types.ORG_PERSONAL_MEMBER_LIST,
          loadMembers: types.ORG_PERSONAL_GROUPMEMBER_LIST,
        }),
        ...mapMutations({
          select: types.ORG_MEMBER_SELECT,
          selectAll: types.ORG_MEMBER_SELECT_ALL,
        }),
    },
})
export default class PersonalMemberListContainer extends Vue {

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //  

    @Prop({ type: String, default: '' }) id!: string;
    @Prop({ type: String, default: '' }) deptName!: string;
    @Prop({ type: String, default: '0' }) type!: string;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //   
    
    loading: boolean = false
    searching: boolean = false;
    keyword: string = '';
    pageRange: number = 10;
    currentPage: number = 1;
    itemsPerPage: number = 50;

    apiOption: orgmap.api.DynamicApiOptions = {
      fields: {},
        filters: [
          {
            operator: 'or',
            filters: [
                {
                    key: 'ItemClass',
                    value: 'IPM.Contact',
                    condition: FilterConditions.IsEqualTo
                },
                {
                    key: 'ItemClass',
                    value: 'IPM.DistList',
                    condition: FilterConditions.IsEqualTo
                }
            ]
          },
          
        ],
        sorts: [
            { field: 'DisplayName', 'dir': 'asc' },
        ],
        pageSize: this.itemsPerPage,
        offset: (this.currentPage -1) * this.itemsPerPage,
        type: 'Contact',
    } as orgmap.api.DynamicApiOptions;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    members!: orgmap.TMemberList;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    load!: (payload: { data: orgmap.api.DynamicApiOptions, type: ContactsStoredType }) => void;
    loadMembers!: (payload: { item: orgmap.TMemberItem, type: ContactsStoredType }) => void;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M U T A T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    select!: (payload: OrgMemberSelectMutationPayload) => void
    selectAll!: (payload: boolean) => void

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    get totalCount() {
      return this.members?.totalCount || 0
    }
  
    get name(){
      return this.searching ? i18n.t('검색결과').toString() : this.deptName;
    }

    get storedType(){
      return this.type === '0' ? ContactsStoredType.DB : ContactsStoredType.EWS ;
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    initParam(){
      this.keyword = '';
      this.currentPage = 1;
      this.searching = false;
    }

    async loadData(){
      var payload = {
        ... this.apiOption,
        pageSize: this.itemsPerPage,
        offset: (this.currentPage -1) * this.itemsPerPage,
        parentId: this.id,
      } as orgmap.api.DynamicApiOptions
      try{
        this.loading = true;
        await this.load({ data: payload, type: this.storedType });
      }
      catch(ex){
        throw ex
      }

      this.loading = false;
    }

    handleSelect(user: orgmap.TMemberItem) {
      this.select({
        items: [user],
        select: !user.selected,
      })
    }
  
    handleSelectAll(select: boolean) {
      const _this = this;
      this.selectAll(select);
    }

    async search(){
      if(this.keyword.length){
        var payload = {
          ... this.apiOption,
          filters: this.apiOption.filters?.concat({
            operator: 'or',
            filters: [
              {
                key: 'DisplayName',
                value: this.keyword,
                condition: FilterConditions.ContainsSubstring,
                containmentMode: FilterContainmentModes.Substring,
                comparisonMode: FilterComparisonModes.IgnoreCase
              },
              {
                key: 'CompanyName',
                value: this.keyword,
                condition: FilterConditions.ContainsSubstring,
                containmentMode: FilterContainmentModes.Prefixed,
                comparisonMode: FilterComparisonModes.IgnoreCase
              },
              {
                key: 'PidLidEmail1OriginalDisplayName',
                value: this.keyword,
                condition: FilterConditions.ContainsSubstring,
                containmentMode: FilterContainmentModes.Prefixed,
                comparisonMode: FilterComparisonModes.IgnoreCase
              },
              {
                key: 'MobilePhone',
                value: this.keyword,
                condition: FilterConditions.ContainsSubstring,
                containmentMode: FilterContainmentModes.Prefixed,
                comparisonMode: FilterComparisonModes.IgnoreCase
              },
              {
                key: 'JobTitle',
                value: this.keyword,
                condition: FilterConditions.ContainsSubstring,
                containmentMode: FilterContainmentModes.Prefixed,
                comparisonMode: FilterComparisonModes.IgnoreCase
              }
            ]
          }),
          parentId: this.id,
        } as orgmap.api.DynamicApiOptions;
        
        try{
          this.loading = true;
          await this.load({ data: payload, type: this.storedType });
        }
        catch(ex){
          throw ex
        }
  
        this.loading = false;
      }

    }

    refresh(){
      this.keyword = '';
      this.searching = false;
      this.loadData();
    }


    /**
     * 페이지 번호 클릭 시 호출됩니다.
     * @param pageNum 
     */
    pageMove(pageNum: number){
      this.currentPage = pageNum;
      this.loadData();
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    
    @Watch('id')
    onIdChanged(newValue: string, oldValue: string){
      if(newValue){
        this.initParam();
        this.loadData();
      }
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    async mounted() {
      this.initParam();
      await this.loadData();        
    }

    created() {

    }
}