import { GetterTree } from 'vuex';
import * as types from '@home/store/types'

export default <GetterTree<home.state.HomeState, RootState>>{
    
    [types.NOTICEPOPUP_GET](state) {
        return state.noticePopupItems
    },

    [types.NOTICEPOPUP_MODAL_GET](state) {
        return state.noticeModalPopupItem
    },
};
