import { ActionContext, ActionTree } from 'vuex';
import * as types from './types'
import api from '@reserve/api'
import * as dateutil from '@appbase/util/date-helper';
import { EnumCalendarViewType } from '../types';
import { getResourceColor } from './consts'

//캘린더 뷰 타입에 따른 시작날짜를 불러옵니다
const getCurrentStartDate = function (date: Date, mdate: Date, viewType: EnumCalendarViewType, action: ActionContext<reserve.state.ReserveState, RootState>) {
    switch (viewType) {
        case EnumCalendarViewType.Daily:
            return date;
        case EnumCalendarViewType.Week:
            return dateutil.uGetWeekStartDate(date, action.state.calendarSetting.firstDay);
        case EnumCalendarViewType.Month:
            return dateutil.uGetDaysInMonth(mdate, true, action.state.calendarSetting.firstDay)[0];
    }
    return date;
}
//캘린더 뷰 타입에 따른 끝날짜를 불러옵니다
const getCurrentEndDate = function (date: Date, mdate: Date, viewType: EnumCalendarViewType, action: ActionContext<reserve.state.ReserveState, RootState>) {
    switch (viewType) {
        case EnumCalendarViewType.Daily:
            return date;
        case EnumCalendarViewType.Week:
            return dateutil.uGetWeekEndDate(date, action.state.calendarSetting.firstDay);
        case EnumCalendarViewType.Month:
            var arr = dateutil.uGetDaysInMonth(mdate, true, action.state.calendarSetting.firstDay);
            return arr[arr.length - 1];
    }
    return date;
}

const sortFn = function (a: any, b: any): number {
    return a == b ? 0 : a < b ? -1 : 1;
}

const isValidReloadFromChangedDate = function (targetDate: reserve.DateItem, state: reserve.state.ReserveState, viewType?: EnumCalendarViewType): boolean {
    let flag: boolean = false;

    const backDate: Date = state.backupDate.date;
    const curDate: Date = targetDate.date;
    const vmode: EnumCalendarViewType = viewType || state.calendarMode;    

    switch (vmode) {
        case EnumCalendarViewType.Month:
            let mStart = dateutil.uGetMonthStartDate(backDate);
            let mEnd = dateutil.uGetMonthEndDate(backDate);

            console.log('check', dateutil.uGetDateFormat('yyyy-MM-dd', curDate));
            console.log('checkStart', dateutil.uGetDateFormat('yyyy-MM-dd', mStart));
            console.log('checkEnd', dateutil.uGetDateFormat('yyyy-MM-dd', mEnd));

            flag = dateutil.uGetIsInDate(curDate, mStart, mEnd);
            break;
        case EnumCalendarViewType.Week:
            let wStart = dateutil.uGetWeekStartDate(backDate, state.calendarSetting.firstDay);
            let wEnd = dateutil.uGetWeekEndDate(backDate, state.calendarSetting.firstDay);
            flag = dateutil.uGetIsInDate(curDate, wStart, wEnd);
            break;
        case EnumCalendarViewType.Daily:
            flag = dateutil.uGetIsInDate(curDate, backDate, backDate);
            break;
    }

    return flag;
}

export default <ActionTree<reserve.state.ReserveState, RootState>>{
    [types.CALENDAR_VIEWMODE](action, viewMode: EnumCalendarViewType) {
        action.commit(types.CALENDAR_VIEWMODE, viewMode);
    },
    
    [types.MINI_CALENDAR_MONTH_MATRIX_LOAD](action) {
        action.commit(types.MINI_CALENDAR_MONTH_MATRIX_LOAD);
    },

    [types.CALENDAR_TOGGLE_RIGHTPANE](action, payload) {
        action.commit(types.CALENDAR_TOGGLE_RIGHTPANE, payload);
    },

    [types.CALENDAR_TOGGLE_LEFTPANE](action, payload) {
        action.commit(types.CALENDAR_TOGGLE_LEFTPANE, payload);
    },

    [types.EWS_RESOURCEGROUPS_LOAD](action) {
        return api.resource.getEwsResources()
            .then(res => {
                res.forEach(r => { if(r.uniqueId != null && r.colorCode == null) r.colorCode = getResourceColor(r.uniqueId); });
                
                //정렬 기능이 없어서 이름순으로 정렬함
                let sortedRes = res.sort((a : ewsResource.EwsResourceItem,b : ewsResource.EwsResourceItem) => { 
                    return a.displayName > b.displayName ? 1 : -1
                })

                action.commit(types.EWS_RESOURCEGROUPS_LOAD, sortedRes);
                return res;
            });
    },

    [types.EWS_RESOURCEBOOKMARK_LOAD](action, payload: { personCode: string, comCode: string }) {
        const { personCode, comCode } = payload;
        if (personCode && comCode){
            return api.resource.getResourceBookmarkList(personCode, comCode).then(res => {
                action.commit(types.EWS_RESOURCEBOOKMARK_LOAD, res);
                return res;
            });
        }
        return null;
    },

    [types.TOGGLE_RESOURCE_SELECT](action, payload) {
        action.commit(types.TOGGLE_RESOURCE_SELECT, payload);                
    },

    [types.TOGGLE_RESOURCEGROUP_SELECT](action, payload) {
        action.commit(types.TOGGLE_RESOURCEGROUP_SELECT, payload);
    },

    [types.CALENDAR_MONTH_MATRIX_LOAD](action) {
        action.commit(types.CALENDAR_MONTH_MATRIX_LOAD);
    },

    [types.CALENDAR_WEEK_MATRIX_LOAD](action) {
        action.commit(types.CALENDAR_WEEK_MATRIX_LOAD);
    },

    [types.CALENDAR_DATE_MATRIX_LOAD](action) {
        action.commit(types.CALENDAR_DATE_MATRIX_LOAD);
    },

    [types.CALENDAR_EVENTLIST_LOAD](action, forceViewMode?: EnumCalendarViewType) {
        action.state.isLoading = true;
        const { date } = action.state.currentDate;
        action.state.resourceEvents = [];
        
        var promiseArr: Promise<any>[] = [];
        var events = [] as reserve.ItemList<reserve.CalendarItem>;
        var selectedResources = action.state.selectedResources.filter(r => r.mailboxType === 'Mailbox')
        
        const start = dateutil.IEDate(getCurrentStartDate(date, action.state.monthDate, forceViewMode || action.state.calendarMode, action));
        const end = dateutil.IEDate(getCurrentEndDate(date, action.state.monthDate, forceViewMode || action.state.calendarMode, action));
        end.setDate(end.getDate() + 1);       
        
        selectedResources.forEach(r => promiseArr.push(api.resource.getCalendarEventListWithRecurrence({
                EwsFolderIDArray: [r.calendarFolderId],
                StartDate: dateutil.uGetDateFormat('yyyy-MM-DDT00:00:00', start),
                EndDate: dateutil.uGetDateFormat('yyyy-MM-DDT00:00:00', end)
            } as reserve.SearchOptions, r.address)));

        return Promise.all(promiseArr).then(res => {
            for(var i = 0; i < selectedResources.length; i++) {
                var resource = selectedResources[i];
                var eventResult = res[i];

                for(var event of eventResult) {
                    const existID = events.find((e) => event.id == e.id && (event.appointmentType != 'Occurrence' && event.appointmentType != 'Exception'));

                    if(existID) continue;

                    event.color = resource.colorCode == null ? getResourceColor(resource.uniqueId) : resource.colorCode ;
                    event.resourceUpn = resource.address;
                    events.push(event);
                }
            }

            events.sort((a, b) =>
                    sortFn(Number(dateutil.IEDate(a.start)), Number(dateutil.IEDate(b.start))) ||
                    sortFn(Number(a.dateTimeCreated), Number(b.dateTimeCreated))
                );
            
            action.commit(types.CALENDAR_EVENTLIST_LOAD, events);  
            action.commit(types.MINI_CALENDAR_MONTH_MATRIX_LOAD);
            action.commit(types.CALENDAR_MONTH_MATRIX_LOAD);
            action.commit(types.CALENDAR_WEEK_MATRIX_LOAD);
            action.commit(types.CALENDAR_DATE_MATRIX_LOAD);
            return events;          
        }).finally(() => { action.state.isLoading = false; });
    },

    [types.CALENDAR_DATE_SELECT](action, dateItem) {       
        var reload = !isValidReloadFromChangedDate(dateItem, action.state);        
        action.commit(types.CALENDAR_DATE_SELECT, dateItem);
        action.commit(types.MINI_CALENDAR_MONTH_MATRIX_LOAD);

        if(reload) {
            return Promise.all([
                action.dispatch(types.CALENDAR_DAILY_EVENT_LOAD),
                action.dispatch(types.CALENDAR_EVENTLIST_LOAD)        
            ]);  
        } else {
            action.commit(types.CALENDAR_MONTH_MATRIX_LOAD);
            action.commit(types.CALENDAR_WEEK_MATRIX_LOAD);
            action.commit(types.CALENDAR_DATE_MATRIX_LOAD);
            return action.dispatch(types.CALENDAR_DAILY_EVENT_LOAD);
        }                   
    },

    [types.CALENDAR_DATE_SELECTRELOAD](action, dateItem) {
        action.commit(types.CALENDAR_DATE_SELECT, dateItem);
        action.commit(types.MINI_CALENDAR_MONTH_MATRIX_LOAD);

        return Promise.all([
            action.dispatch(types.CALENDAR_DAILY_EVENT_LOAD),
            action.dispatch(types.CALENDAR_EVENTLIST_LOAD)        
        ]);  
    },

    [types.CALENDAR_EVENT_CREATE](action, item) {
        const fields: ewsResource.api.FieldOption[] = [
            //{ key: 'ParentFolderId', value: item.parentId },
            { key: 'Importance', value: item.importance },
            { key: 'EVENT_STATUS', value: item.status },
            { key: 'Subject', value: item.subject },
            { key: 'Location', value: item.location },
            { key: 'Start', value: item.start + 'T' + item.starttime },
            { key: 'End', value: item.end + 'T' + item.endtime },
            { key: 'IsAllDayEvent', value: item.isAllDayEvent },
            { key: 'Body', value: item.body },
            { key: 'RequiredAttendees', value: item.requiredAttendees },
            { key: 'OptionalAttendees', value: item.optionalAttendees },
            { key: 'Recurrence', value: item.recurrence },
            { key: 'Attachments', value: item.attachments },
            { key: 'Resources', value: item.resources },
            { key: 'AttachmentKey', value: item.code },
            { key: 'DelegateUser', value: item.delegateUser },
            { key: 'ReminderMinutesBeforeStart', value: item.reminderMinutesBeforeStart }
        ]

        const data: ewsResource.api.DynamicApiOptions = {
            fields: fields,
            parentId: item.parentId,
            type: 'Calendar'
        };
        
        return api.ewsCalendar.createEvent(data)
            .then(res => {
                return action.dispatch(types.CALENDAR_EVENTLIST_LOAD)
            });      
    },

    [types.CALENDAR_EVENT_UPDATE](action, item) {
        const fields: ewsResource.api.FieldOption[] = [
            //{ key: 'ParentFolderId', value: item.parentId },
            { key: 'Importance', value: item.importance },
            { key: 'EVENT_STATUS', value: item.status },
            { key: 'Subject', value: item.subject },
            { key: 'Location', value: item.location },
            { key: 'Start', value: item.start + 'T' + item.starttime },
            { key: 'End', value: item.end + 'T' + item.endtime },
            { key: 'IsAllDayEvent', value: item.isAllDayEvent },
            { key: 'Body', value: item.body },
            { key: 'RequiredAttendees', value: item.requiredAttendees },
            { key: 'OptionalAttendees', value: item.optionalAttendees },
            { key: 'Recurrence', value: item.recurrence },
            { key: 'Attachments', value: item.attachments },
            { key: 'Resources', value: item.resources },
            { key: 'AttachmentKey', value: item.code },
            { key: 'DelegateUser', value: item.delegateUser },
            { key: 'ReminderMinutesBeforeStart', value: item.reminderMinutesBeforeStart },
        ];

        const data: ewsResource.api.DynamicApiOptions = {
            fields: fields,
            id: item.id,
            type: 'Calendar'
        };

        return api.ewsCalendar.editEvent(data)
            .then(res => {
                return action.dispatch(types.CALENDAR_EVENTLIST_LOAD)
            }); 
    },

    [types.CALENDAR_EVENT_CANCEL](action, id: string) {       
        return api.ewsCalendar.cancelEvent(id).then((res) => {
            return action.dispatch(types.CALENDAR_EVENTLIST_LOAD)
        })            
    },

    [types.CALENDAR_DAILY_EVENT_LOAD](action) {
        action.state.isDailyEventLoading = true;
        action.state.resourceDailyEvents = [];
        const currentDate = action.state.currentCalendarDate;
        const { date } = currentDate;

        var promiseArr: Promise<any>[] = [];
        var events = [] as reserve.ItemList<reserve.CalendarItem>;
        var selectedResources = action.state.selectedResources.filter(r => r.mailboxType === 'Mailbox')
                
        let endDate: Date = new Date(date);
        endDate.setDate(endDate.getDate() + 1);

        selectedResources.forEach(r => promiseArr.push(api.resource.getCalendarEventListWithRecurrence({
            EwsFolderIDArray: [r.calendarFolderId],
            StartDate: dateutil.uGetDateFormat('yyyy-MM-DDT00:00:00', date),
            EndDate: dateutil.uGetDateFormat('yyyy-MM-DDT00:00:00', endDate)
        } as reserve.SearchOptions, r.address)));

        return Promise.all(promiseArr).then(res => {
            for(var i = 0; i < selectedResources.length; i++) {
                var resource = selectedResources[i];
                var eventResult = res[i];
                var dtString = dateutil.uGetDateFormat('yyyy-MM-dd', dateutil.IEDate(date));
              
                if (eventResult.length > 0){
                    eventResult = (eventResult as reserve.ItemList<reserve.CalendarItem>)
                                    .filter(x  => dateutil.uGetDateFormat('yyyy-MM-dd', dateutil.IEDate(x.start)) === dtString);
                }

                for(var event of eventResult) {
                    const existID = events.find((e) => event.id == e.id && (event.appointmentType != 'Occurrence' && event.appointmentType != 'Exception'));

                    if(existID) continue;

                    event.color = resource.colorCode == null ? getResourceColor(resource.uniqueId) : resource.colorCode ;
                    event.resourceUpn = resource.address;
                    events.push(event);
                }
            }

            events.sort((a, b) =>
                sortFn(Number(dateutil.IEDate(a.start)), Number(dateutil.IEDate(b.start))) ||
                sortFn(Number(a.dateTimeCreated), Number(b.dateTimeCreated))
            );
            
            action.commit(types.CALENDAR_DAILY_EVENT_LOAD, events);
            return events;
        }).finally(() => { action.state.isDailyEventLoading = false; });
    },

    [types.CALENDAR_SEARCH_EVENTS_LOAD](action, searchSet?: reserve.ReserveSearchSet) {
        var set = searchSet || action.state.searchSet;
        action.state.isLoading = true;
        const { date } = action.state.currentDate;
        action.state.searchEvents = [];

        var promiseArr: Promise<any>[] = [];
        var events = [] as reserve.ItemList<reserve.CalendarItem>;
        var selectedResources = action.state.selectedResources.filter(r => r.mailboxType === 'Mailbox');

        selectedResources.forEach(r => promiseArr.push(
            api.resource.searchCalendarEventList(<reserve.SearchOptions>{            
                StartDate: set.searchStart,
                EndDate: set.searchEnd,
                OrganizerName: set.reserverName,
                Subject: set.reserveTitle,
                Keyword: set.reserveContent
            }, r.address)));
        
        Promise.all(promiseArr)
            .then(res => {
                for(var i = 0; i < selectedResources.length; i++) {
                    var resource = selectedResources[i];
                    var eventResult = res[i];
    
                    for(var event of eventResult) {
                        const existID = events.find((e) => event.id == e.id && (event.appointmentType != 'Occurrence' && event.appointmentType != 'Exception'));
    
                        if(existID) continue;
    
                        event.color = resource.colorCode == null ? getResourceColor(resource.uniqueId) : resource.colorCode ;
                        event.resourceUpn = resource.address;
                        events.push(event);
                    }
                }
    
                events.sort((a, b) =>
                        sortFn(Number(dateutil.IEDate(a.start)), Number(dateutil.IEDate(b.start))) ||
                        sortFn(Number(a.dateTimeCreated), Number(b.dateTimeCreated))
                    );

                action.commit(types.CALENDAR_SEARCH_EVENTS_LOAD, events);
                return events;
            }).finally(() => { action.state.isLoading = false; });
    },

    [types.CALENDAR_SEARCHSET_UPDATE](action, payload: reserve.ReserveSearchSet) {
        action.commit(types.CALENDAR_SEARCHSET_UPDATE, payload);
    },

    [types.TOGGLE_MAIN_LISTVIEW](action, payload: boolean) {
        action.commit(types.TOGGLE_MAIN_LISTVIEW, payload);
    }
};
