import { RouteConfig } from 'vue-router'
import { MainView, PopupView, MobileView } from '../views'

const routes: RouteConfig[] = [
  {
    path: 'orgmap',
    component: () => import(/* webpackChunkName: "orgmap" */ '@orgmap/Index.vue'),
    children: [
      {
        path: 'main',
        component: MainView,
      },
      {
        path: 'popup',
        component: PopupView,
      },
      {
        path: 'mobile',
        component: MobileView,
      },
      {
        path: '',
        redirect: 'popup',
      },
    ],
  },
]
export default routes
