import Vue from 'vue'
import { loadSript } from './script-loader'

export const initializeAsync = () => {
  return new Promise<void>((resolve) =>
    loadSript('/cm/gres/js/boardFieldSetting.js', 'eum-board-setting', resolve)
  )
}
export { default as routes } from './router'
export { default as store } from './store'
