import api from '@/api';
import { FilterConditions } from '../types/enums';
import { ContactsStoredType } from './favorite';
export default {
    /**
     * 기본 주소록 폴더를 조회합니다.
     */
    getBasicContactFolder: function() {
        return api
            .post({
                url: '/api/contact/folder/list?type='+ContactsStoredType.EWS,
                data: {
                    fields: [
                        { key: 'Id' },
                        { key: 'DisplayName' },
                        { key: 'TotalCount' },
                        { key: 'WellKnownFolderName' },
                        { key: 'USER_IS_FAVORITE' },
                        { key: 'USER_RENDER_ORDER' },
                    ],
                    filters: [
                        {
                            key: 'FolderClass',
                            value: 'IPF.Contact',
                            condition: 'isEqualTo',
                        },
                        {
                            key: 'PR_ATTR_HIDDEN',
                            value: 'false',
                            condition: 'isEqualTo',
                        },
                    ],
                    parentId: null,
                    wellKnownParentName: 'MsgFolderRoot',
                    type: 'Folder',
                    traversal: 'Shallow',
                },
            })
            .then((res: any) =>
                res.items.map((item: orgmap.PersonalFolder) => {
                    var rlt = {
                        ...item,
                        selected: false,
                        storedType: ContactsStoredType.EWS,
                    };
                    return rlt;
                })
            );
    },
    /**
     * 주소록 목록 데이터를 조회합니다.
     * @param {string} parentId 폴더 root ID
     */
    getContactTree: function(parentId?: string) {
        return api
            .post({
                url: '/api/contact/folder/list?type='+ContactsStoredType.EWS,
                data: {
                    fields: [
                        { key: 'Id' },
                        { key: 'DisplayName' },
                        { key: 'TotalCount' },
                        { key: 'ChildFolderCount' },
                        { key: 'WellKnownFolderName' },
                        { key: 'USER_IS_FAVORITE' },
                        { key: 'USER_RENDER_ORDER' },
                    ],
                    filters: [
                        {
                            key: 'FolderClass',
                            value: 'IPF.Contact',
                            condition: 'isEqualTo',
                        },
                    ],
                    parentId: parentId,
                    wellKnownParentName: 'Contacts',
                    type: 'Folder',
                    traversal: 'Shallow',
                },
            })
            .then((res: any) =>
                res.items.map((item: orgmap.PersonalFolder) => {
                    var rlt = {
                        ...item,
                        children: null,
                        expanded: false,
                        selected: false,
                        storedType: ContactsStoredType.EWS,
                    };
                    return rlt;
                })
            );
    },
    /**
     * 연락처 목록을 조회합니다.
     * @param {object} data
     */
    getContactsList(
        data: orgmap.api.DynamicApiOptions, type: ContactsStoredType
    ): Promise<orgmap.PagedItemList<orgmap.ContactItem>> {
        return api
            .post({
                url: '/api/ews/Contact/list?type=' + type,
                data: {
                    fields: [
                        { key: 'Id' },
                        { key: 'DisplayName' },
                        { key: 'CompanyName' },
                        { key: 'Department' },
                        { key: 'JobTitle' },
                        { key: 'OfficeLocation' },
                        { key: 'ItemClass' },
                        { key: 'HasPicture' },
                        {
                            key: 'PhoneNumbers',
                            indexedKey: 'MobilePhone',
                            as: 'mobile',
                        },
                        {
                            key: 'PhoneNumbers',
                            indexedKey: 'BusinessPhone',
                            as: 'businessPhone',
                        },
                        // {
                        //     key: 'EmailAddresses',
                        //     indexedKey: 'EmailAddress1',
                        //     format: '{address}',
                        //     as: 'email',
                        // },
                        { key: 'PidLidEmail1OriginalDisplayName', as: 'email'},
                        {
                            key: 'PhysicalAddresses',
                            indexedKey: 'Business',
                            serialize: true,
                            as: 'businessAddress',
                        },
                        {
                            key: 'Attachments',
                            indexedKey: 'Attachments',
                            serialize: true,
                            filters: [
                                {
                                    key: 'isContactPhoto',
                                    value: true,
                                    operator: 'eq',
                                },
                            ],
                        },
                        // ...data.fields,
                    ],
                    filters: data.filters,
                    parentId: data.parentId,
                    sorts: data.sorts,
                    pageSize: data.pageSize,
                    offset: data.offset,
                    wellKnownParentName: data.wellKnownParentName,
                    type: data.type,
                } as orgmap.api.DynamicApiOptions,
            })
            .then((res) => {
                let rlt = res as orgmap.PagedItemList<any>;
                let list = rlt.items;
                // console.log('list', list);
                rlt.items = rlt.items.map(i => {
                    return {
                        ...i,
                        selected: false,
                        contactType: i.type
                    }
                });

                return rlt;
            });
    },
};
