import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class GnbSearch extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop ({ type: Array }) orgSearchItems!: gnb.OrgPersonSearch[];
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    placeholoder: string = this.$t('이름_또는_검색어를_입력하세요').toString();
}