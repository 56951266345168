import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { MemberListContainer } from '../../containers'
import { mapGetters } from 'vuex'
import api from '@orgmap/api'
import * as accountTypes from '@account/store/types'

@Component({
  name: 'HrUserListView',
  components: { MemberListContainer },
  computed: {
    ...mapGetters({
      user: accountTypes.USER,
    }),
  },
})
export default class HrUserListView extends Vue {

  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //
  
  @Prop({ type: Object, default: null }) dept!: orgmap.TDepartmentTreeItem;
  @Prop({ type: String }) userlang!: string

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  user!: account.User

  deptCode: string = ''
  comCode: string = ''
  deptName: string = ''
  companyName: string = ''

  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: W A T C H : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //
  
  @Watch('dept')
  onCurrentDeptChanged(newValue: orgmap.TDepartmentTreeItem, oldValue: orgmap.TDepartmentTreeItem) {
    if(newValue){
      this.deptCode = newValue.deptCode
      this.comCode = newValue.comCode
      //언어 관계 없이 영문만 표시
      // this.deptName = (this.userlang == 'ko-kr' ? newValue.deptName : newValue.deptNameEn)
      this.deptName =   newValue.deptNameEn
    }
    else{
      this.deptCode = '';
      this.comCode = '';
      this.deptName = '';
    }
  }

  @Watch('comCode')
  onCurrentComCodeChanged(comCode: string) {
    api.hr.getCompanies().then((res: orgmap.Company[]) => {
      var company = res.find(x => x.comCode == comCode)
      if (company)
        this.companyName = (this.userlang == 'ko-kr' ? company.companyName : company.companyNameEng)
    })
  }

  created(){
    this.comCode = this.user.comCode;
  }
}
