import api from '@/api';
import { ContactsStoredType } from './favorite';
export default {
    /**
     * 그룹연락처 구성원을 조회합니다.
     * @param id 그룹연락처 ID
     * @param type database/exchange 구분
     */
    getGroupMembers(id: string, type: ContactsStoredType): Promise<orgmap.PagedItemList<orgmap.ContactItem>>{
        return api.post({
            url: '/api/contact/groupcontact/list?type=' + type,
            data:{
                parentId: id.toString()
            }
        });
    },
}