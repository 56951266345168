import api from '@/api';

export default {
    //게시판의 모든 article 가져오기
    getTotalArticles(searchSet: article.TotalArticleSearchSet): Promise<article.PagedData<article.Article>> {
        return api.post<article.PagedData<article.Article>>({
            url: `/api/BoardApi/Article/getTotalArticles`,
            data: searchSet
        });
    },
    //article 가져오기
    //헬프데스크 리스트와 병합해서 가져옴
    //call signature 가 변경되어 controller 부터 다시 구성
    //주소 변경 `/api/BoardApi/Article/getArticles` -> `/api/z/article/list`
    getArticles(searchSet: article.SearchSet): Promise<article.PagedData<article.Article>> {
       
        return api.post<article.PagedData<article.Article>>({
            url: `/api/z/article/list`,
            data: searchSet
        });
    },



    //관리자 article 가져오기
    getAdminArticles(searchSet: article.SearchSet): Promise<article.PagedData<article.Article>> {
        return api.post<article.PagedData<article.Article>>({
            url: `/api/BoardApi/BoardAdmin/getArticles`,
            data: searchSet
        });
    },
    //Blog Article 가져오기
    getBlogArticles(searchSet: article.SearchSet): Promise<article.PagedData<article.BlogArticleItem>> {
        return api.post<article.PagedData<article.BlogArticleItem>>({
            url: `/api/BoardApi/Article/getBlogArticles`,
            data: searchSet
        });
    },
    //글 작성
    createArticle(set: article.ArticleQuerySet): Promise<common.CreateResultSet> {
        return api.post<common.CreateResultSet>({
            url: `/api/BoardApi/Article/createArticle`,
            data: set
        })
    },
    //작성글 보기
    getArticleDetail(boardId: number, articleId: number): Promise<article.ArticleDetail> {
        return api.get<article.ArticleDetail>({
            url: `/api/BoardApi/Article?boardId=${boardId}&articleId=${articleId}`,
        })
    },
    //게시글 이력 조회
    getArticleDetailHistory(boardId: number, articleId: number, articleVersion: number): Promise<article.ArticleDetail> {
        return api.get<article.ArticleDetail>({
            url: `/api/BoardApi/Article?boardId=${boardId}&articleId=${articleId}&articleVersion=${articleVersion}`,
        })
    },
    //작성글 수정
    updateArticle(set: article.ArticleQuerySet): Promise<common.UpdateResultSet> {
        return api.post<common.UpdateResultSet>({
            url: `/api/BoardApi/Article/updateArticle`,
            data: set
        })
    },
    //작성글 삭제
    deleteArticle(set: article.DeleteArticle): Promise<number> {
        return api.post<number>({
            url: `/api/BoardApi/Article/deleteArticle`,
            data: set
        })
    },

    deleteArticles(set: article.DeleteArticleSet): Promise<number> {
        return api.post<number>({
            url: `/api/BoardApi/BoardAdmin/deleteArticles`,
            data: set
        })
    },

    restoreArticles(set: number[]): Promise<number> {
        return api.post<number>({
            url: `/api/BoardApi/BoardAdmin/restoreArticles`,
            data: set
        })
    },

    toggleArticlesSecret(set: number[]): Promise<number> {
        return api.post<number>({
            url: `/api/BoardApi/BoardAdmin/toggleArticlesSecret`,
            data: set
        })
    },


    //게시글 권한
    createPermission(set: article.PermissionSet): Promise<article.ArticleDetail> {
        return api.post<article.ArticleDetail>({
            url:`/api/BoardApi/Article/createPermission`,
            data: set
        })
    },
    createArticleFile(set: article.FileCreateSet): Promise<number> {
        return api.post<number>({
            url: `/api/db/article/createFile`,
            data: set
        });
    },
    //첨부파일 
    getArticleFile(fildCode: string): Promise<article.FileStore[]> {
        return api.get<article.FileStore[]>({
            url: `/File/Store/${fildCode}`
        })
    },
    getArticleFileHistory(fileCode: string, articleVersion: number): Promise<article.FileStore[]> {
        return api.get<article.FileStore[]>({
            url: `/api/BoardApi/Article/articlefilehistory?fileCode=${fileCode}&articleVersion=${articleVersion}`
        })
    },
    //댓글 가져오기
    getArticleComments(set: article.CommentFetchSet): Promise<article.PagedData<article.ArticleComment>> {
        return api.post<article.PagedData<article.ArticleComment>>({
            url: `/api/BoardApi/Article/getComments`,
            data: set
        })
    },
    //조회수
    createViewLog(set: article.CreateOptionSet): Promise<article.ArticleDetail> {
        return api.post<article.ArticleDetail>({
            url: `/api/BoardApi/Article/createViewLog`,
            data: set
        })
    },
    //댓글 작성
    createComment(set: article.CreateComment): Promise<article.ArticleComment> {
        return api.post<article.ArticleComment>({
            url: `/api/BoardApi/Article/createComment`,
            data: set
        })
    },
    //댓글 삭제
    deleteComment(commentId: number): Promise<number> {
        return api.get<number>({
            url: `/api/BoardApi/Article/deleteComment?commentId=${commentId}`
        })
    },
    //추천수 +1
    createRecommend(set: article.CreateOptionSet): Promise<number> {
        return api.post<number>({
            url: `/api/BoardApi/Article/createRecommend`,
            data: set
        })
    },
    //추천수 -1
    deleteRecommend(set: article.DeleteRecommendSet): Promise<number> {
        return api.get<number>({
            url: `/api/BoardApi/Article/deleteRecommend?articleId=${set.articleId}&userCode=${set.userCode}`
        })
    },
    //추천인 리스트
    getRecommendList(articleId: number): Promise<article.RecommendSet[]> {
        return api.get<article.RecommendSet[]>({
            url: `/api/BoardApi/Article/recommends?articleId=${articleId}`
        })
    },
    //조회한 사용자 리스트
    getViewLogList(articleId: number): Promise<article.ViewLogSet[]> {
        return api.get<article.ViewLogSet[]>({
            url:`/api/BoardApi/Article/viewlogs?articleId=${articleId}`
        })
    },
    
    sendArticleMailAlarm(set: article.ArticleMailAlarmSet): Promise<string> {
        return api.post<string>({
            url:`/api/BoardApi/Article/sendMailAlaram`,
            data: set
        });
    },

    checkArticlePassword(set: article.PasswordCheckSet): Promise<boolean> {
        return api.post<boolean>({
            url: '/api/BoardApi/Article/checkPassword',
            data: set
        });
    },

    checkArticleCommentPassword(set: article.CommentPasswordCheckSet): Promise<boolean> {
        return api.post<boolean>({
            url: '/api/BoardApi/Article/checkCommentPassword',
            data: set
        });
    },

    getArticleHistoryList(articleId: number): Promise<article.ArticleHistoryItem[]> {
        return api.get<article.ArticleHistoryItem[]>({
            url: `/api/BoardApi/Article/articlehistory?articleId=${articleId}`
        })
    },

    getSystemAuth(systemId: number): Promise<article.SystemAuth[]> {
        return api.get<article.SystemAuth[]>({
            url: '/common/menu/api/menu/personsystemlist',
            params: {
                systemId: systemId
            }
        })
    },

    createAttachmentLog(log: article.AttachmentLog): Promise<number> {
        return api.post<number>({
            url: '/api/BoardApi/Article/attachmentHistory',
            data: log
        });
    }
}