
import { Component, Prop, Vue } from 'vue-property-decorator'
import Dext5Editor from './Dext5Editor/Dext5Editor.vue'
import SynapEditorWrapper from './SynapEditor/SynapEditorWrapper.vue'
import TinyEditor from './TinyEditor/TinyEditor.vue'

// 전체 솔루션에 일괄적용하려면 이 상수를 수정하세요.
const DEFAULT_EDITOR: editor.EditorTypes = 'dext5'

@Component
export default class EditorProxy extends Vue {
  @Prop({ type: String }) type!: editor.EditorTypes
  @Prop({ type: String, default: 'editor', required: true }) mode!: editor.EditorMode

  get classNames() {
    const { type, mode } = this
    const globalType = this.getAppConfig('EditorType') as editor.EditorTypes
    return ['eum-editor-type-' + (type || globalType || DEFAULT_EDITOR), 'eum-editor-mode-' + mode]
  }

  get editorComponent() {
    const { type } = this
    const globalType = this.getAppConfig('EditorType') as editor.EditorTypes
    switch (type || globalType || DEFAULT_EDITOR) {
      case 'dext5':
        return Dext5Editor

      case 'tiny':
        return TinyEditor

      case 'synap':
        return SynapEditorWrapper

      default:
        throw new Error('[NOT_SUPPORTED_ERROR]: ' + type)
    }
  }

  public getEditor(): editor.IEumEditor {
    return this.$refs.editor as any as editor.IEumEditor
  }

  created() {
    console.log('111', this)
  }
}
