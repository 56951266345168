import Vue from 'vue'
import router from '@/router'
import { MenuCallTypes } from '@appbase/types/enum'
import { NavigationGuardNext } from 'vue-router'
import { Dictionary } from 'vue-router/types/router'
import regexHelper from './regex-helper'
import { aesEncrypt } from './aes-encrypt'

class MenuHelper {
  //
  // ──────────────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P E R T I E S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────
  //

  get cache(): appbase.cache.TPortalCache {
    return Vue.prototype.portalCache
  }

  get menu() {
    return this.cache.menu
  }

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: C O R E : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  /**
   * Recursive finds the menu item by id from the cache
   * @param menuId MENUID
   * @param cacheItems cached menu items
   * @returns found menu item
   */
  findOne(menuId: number, cacheItems?: appbase.cache.TMenuItem[]): appbase.cache.TMenuItem | null {
    if (!cacheItems) cacheItems = this.menu.items
    for (var cacheItem of cacheItems!) {
      if (cacheItem.menuId === menuId) return cacheItem
      else if (cacheItem.hasChildren) {
        const found = this.findOne(menuId, cacheItem.children)
        if (found) return found
      }
    }
    return null
  }

  findOneBySysId(
    id: number,
    type: string,
    cacheItems?: appbase.cache.TMenuItem[]
  ): appbase.cache.TMenuItem | null {
    if (!cacheItems) cacheItems = this.menu.items
    for (var cacheItem of cacheItems!) {
      if (cacheItem.systemId === id && cacheItem.menuType === type) return cacheItem
      else if (cacheItem.hasChildren) {
        const found = this.findOneBySysId(id, type, cacheItem.children)
        if (found) return found
      }
    }
    return null
  }

  // Left Menu 표시 데이터 목록 조회
  getLeftMenuList(menuId: number): appbase.cache.TMenuItem | null {
    let menuItem: appbase.cache.TMenuItem | null = this.findOne(menuId)

    if (menuItem) {
      if (menuItem.parentMenuId == 0) {
        return menuItem
      } else {
        let isSearch: boolean = true
        while (isSearch) {
          if (menuItem) {
            menuItem = this.findOne(menuItem.parentMenuId)
            if (menuItem) {
              if (menuItem.parentMenuId == 0) {
                isSearch = false
              }
            } else {
              isSearch = false
            }
          } else {
            isSearch = false
          }
        }
        return menuItem
      }
    }
    return null
  }

  // MenuId 기준 최상위 Menu 까지 MenuId Path를 반환
  getMenuIdFullPath(menuId: number): Array<number> {
    let arrMenuPath = new Array()
    if (menuId && menuId != 0){
      let isLoop: boolean = true
      while(isLoop){
        let menuItem: appbase.cache.TMenuItem | null
        menuItem = this.findOne(menuId)
        if (menuItem){
          arrMenuPath.push(menuItem.menuId)
          menuId = menuItem.parentMenuId
          if (menuId == 0){
            isLoop = false
          }
        }
        else{
          isLoop = false;
        }
      }
    }
    return arrMenuPath.reverse()
  }

  // 현재 조회중인 MenuId의 2 Level MenuId 조회
  getSubMenuId(menuId: number): number | 0 {
    let menuIds: number[] = []
    let menuItem: appbase.cache.TMenuItem | null
    let retMenuId = menuId ? menuId : 0
    if (retMenuId != 0) {
      let isLoop: boolean = true
      menuIds.push(retMenuId)

      while (isLoop) {
        if (retMenuId == 0) return 0
        menuItem = this.findOne(retMenuId)
        if (menuItem) {
          if (menuItem?.parentMenuId == 0) {
            isLoop = false
          } else {
            menuIds.push(menuItem.parentMenuId)
            retMenuId = menuItem.parentMenuId
          }
        } else {
          isLoop = false
        }
      }
      return menuIds && menuIds.length > 0 ? menuIds[menuIds.length - 2] : 0
    }
    return 0
  }

  findOneByCalltype = (type: MenuCallTypes) => this.menu.items.find((x) => x.callType === type)

  next(item: appbase.cache.TMenuItem, next?: NavigationGuardNext) {
    var baseUrl = (item.baseUrl || '').toLowerCase()
    var path = (item.targetUrl || '').trim().toLowerCase()
    // 기본적으로 항상 MENUID 전달
    var query: Dictionary<any> = { MENUID: item.menuId }
    // system id가 없을 경우 쿼리 스트링에 포함하지 않음
    if (item.systemId) query.SYSID = item.systemId

    // ─────────────────────────────────────────────────────────────────
    // 메뉴가 페이지 타입일 경우
    if (item.menuType === 'PA') {
      if (this._isSameSite(baseUrl, path)) {
        if (item.callType === MenuCallTypes.HOME) return router.push({ path })
        else return this._proxy(item, query)
      } else {
        let locationPath = location.pathname.toLowerCase()
        if (locationPath.startsWith('/po/')) {
          this._proxy(item, query)
        } else {
          let url = this._combindWithQuery(path, query)
          switch (baseUrl.toLowerCase()) {
            case '/mail':
            case '/workflow':
            case '':
              baseUrl = '/po'
              break
          }
          window.location.href = process.env.NODE_ENV === 'production' ? baseUrl + url : url
        }
      }
    } else {
      // ────────────────────────────────────────────────────────────────────────────────
      // 메뉴가 페이지 타입이 아닐 경우
      if (path) {
        // Path에 MENUID, SYSID 값이 포함되어 있는 경우 처리한다.
        let isIncludeParam: boolean = true
        if (path.includes('menuid=') || path.includes('sysid=')){
          isIncludeParam = false;
          path = path.replace('menuid=', 'MENUID=')
          path = path.replace('sysid=', 'SYSID=')
        }

        // router guard에서 next를 제공한 경우, router guard에 라우트를 위임한다.
        if (next) return next({ path, query })
        else {
          if (this._isSameSite(baseUrl, path)) {
            // 동일 사이트일 경우, router.push를 이용하여 라우트
            // Home인경우 파라메터를 전달하지 않음
            if (item.callType === MenuCallTypes.HOME) return router.push({ path })
            else {
              if (document.referrer !== location.href) {
                var query = {...query, _t: Date.now()} as Dictionary<any>;
              }
              if (isIncludeParam) return router.push({ path, query })
              else return router.push({ path });
            } 
          } else {
            // 외부 사이트일 경우, window.location.href를 이용하여 페이지 전환
            let url = ''
            if (isIncludeParam) url = this._combindWithQuery(path, query)
            else url = path

            window.location.href = process.env.NODE_ENV === 'production' ? baseUrl + url : url
          }
        }
      } else {
        throw new Error('[NULL_REFERENCE_ERROR] : path can not be null')
      }
    }
  }

  private _proxy(item: appbase.cache.TMenuItem, query: Dictionary<any>) {
    return router.push({
      name: 'menu-proxy',
      query,
    })
  }

  private _isSameSite(baseUrl: string, path: string): boolean {
    // location.href 로 페이지 전환이 필요한 경우, BASE URL을 가지고 현재 사이트가 라우트 대상 사이트와 동일 사이트인지 계산하여
    // full url인 경우 페이지 전환을 위해 false 리턴
    if (regexHelper.uri(path)) return false

    if (process.env.NODE_ENV === 'production') {
      // 라우트 대상 경로가 현재 페이지 라우트 영역에 포함되어 있는지 검사
      const relativeUrl = location.pathname.toLowerCase()
      // 현재 사이트의 url이 메뉴에서 설정된 baseUrl로 시작할 경우 동일 라우트 안에 있다고 판정.
      if (baseUrl && baseUrl != ''){
        return relativeUrl.startsWith(baseUrl)
      }
      else{
        return false
      }
    } else {
      // 코딩 타임에서는 항상 동일 라우트 영역에 있다고 가정
      return true
    }
  }

  private _combindWithQuery = (url: string, query: { [key: string]: any }): string => {
    console.log('combindWithQuery', url, query)
    let keys: string[]
    if (query && (keys = Object.keys(query)).length > 0) {
      if (url.indexOf('?') < 0) url += '?'
      else url += '&'
      url += keys
        .map((key) => {
          var value = query[key]
          if (typeof value === 'object') {
            value = JSON.stringify(value)
          }
          return `${key}=${encodeURIComponent(value)}`
        })
        .join('&')
      return url
    } else {
      return url
    }
  }

  //
  // ──────────────────────────────────────────────────────────────────── I ──────────
  //   :::::: H E L P E R   M E T H O D S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────────────
  //

  goHome(next?: NavigationGuardNext) {
    const homeMenu = this.findOneByCalltype(MenuCallTypes.HOME)
    if (homeMenu) {
      this.next(homeMenu, next)
    } else {
      throw new Error(`[NOT_FOUND_ERROR] : Failed to retrieve menu item with calltype 'HOME'.`)
    }
  }

  setMenuParam(menu: appbase.cache.TMenuItem[], account: appbase.account.TUser){
    let menuItems: appbase.cache.TMenuItem[] = menu
    this._setParam(menuItems, account);
    return menuItems
  }

  private _setParam(items: appbase.cache.TMenuItem[], account: appbase.account.TUser){
    for(let i = 0; i < items.length; i++){
      const { targetUrl, frameUrl } = items[i]
      items[i].targetUrl = (targetUrl ? this._replaceUrl(targetUrl, account) : '') 
      items[i].frameUrl = (frameUrl ? this._replaceUrl(frameUrl, account) : '') 

      if (items[i].hasChildren && items[i].children.length > 0){
        this._setParam(items[i].children, account)
      }
    }
  }

  private _replaceUrl(url: string, account: appbase.account.TUser){
    if (url){
      Object.keys(account).forEach(key => url = url.replace(`{${key}}`, aesEncrypt(Object(account)[key])))
    }
    return url
  }
}

const menuHelper = new MenuHelper()

export default menuHelper
