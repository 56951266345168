import { GetterTree } from 'vuex';
import * as types from './types'

export default <GetterTree<survey.state.SurveyState, RootState>>{
    [types.SURVEY_LIST](state){
        return state.listGrid
    },

    [types.SURVEY_CREATED_SURVEYID](state) {
        return state.createdSurveyId  
    },

    [types.SURVEY_QUESTION](state){
        return state.createdQuestions
    },

    [types.SURVEY_ANSWER](state){
        return state.answers
    },

    [types.SURVEY_STATISTICS](state){
        return state.statisticsDetail
    },

    [types.SURVEY_MENU_PERMISSIONS](state){
        return state.permissions
    },
    
    [types.SURVEY_LIST_TOTALCOUNT](state){
        return state.listTotalCount
    }
};


 