import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class GnbSiteMap extends Vue {
    @Prop({ type: Array }) siteMapItems!: appbase.cache.TMenuItem[]
    @Prop({ type: String }) userLang!: string
    siteMapSearchText: string = ''


    // displayItems : Array<appbase.cache.TMenuItem> = []

    // created(){
    //     this.displayItems = this.siteMapItems.map(item=>{
    //         return {
    //             ...item,
    //             menuName : this.$t(item.menuName).toString(),
    //             menuNameEn : this.$t(item.menuName).toString()
    //         }
    //     })
    // }

    fnExpandSubMenu(menuId: number) {
        let divSubObj = document.getElementById("divSiteMapSub_" + menuId);
        if (divSubObj !== undefined && divSubObj !== null) {
            if (divSubObj.style.display == "none") {
                divSubObj.style.display = "";
            }
            else {
                divSubObj.style.display = "none";
            }
        }
    }

    fnGetMenuName(menuItem: appbase.cache.TMenuItem) {
        //2022-06-08 다국어 리소스 활용하여 다국어 처리 한국어/영문 구분이 아닌 리소스 코드로 처리
        //메뉴명은 코드화되어있음
        // return (this.userLang && this.userLang == 'ko-KR' ? menuItem.menuName : menuItem.menuNameEn)
        return this.$t(menuItem.menuName).toString()
    }

    fnSearchSiteMap() {
        // 메뉴명 없이 검색 시 모든 Sitemap 데이터 표시
        this.siteMapSearchText = (<HTMLInputElement>document.getElementById('txtSearchSiteMap')).value;
    }

    @Watch('siteMapSearchText')
    fnIsDisplay(menuItem: appbase.cache.TMenuItem, level: number) {
        if (this.siteMapSearchText == '') {
            return "";
        }
        else {
            if (menuItem.menuName) {
                if (this.fnCheckMenuName(menuItem, level)) {
                    return '';
                }
            }
        }

        return "display:none"
    }

    fnCheckMenuName(menuItem: appbase.cache.TMenuItem, menuLevel: number) {
        // if (this.userLang.toLowerCase() == 'ko-kr' ? (menuItem.menuName.indexOf(this.siteMapSearchText) > -1)
        //     : (menuItem.menuNameEn.indexOf(this.siteMapSearchText) > -1)) {
        //검색 대소문자 구분없이 적용
        if(this.$t(menuItem.menuName).toString().toLowerCase().indexOf(this.siteMapSearchText.toLowerCase() )> -1){
            return true;
        }
        else if (menuLevel < 3 && menuItem.hasChildren && menuItem.children.length > 0) {
            for (let i = 0; i < menuItem.children.length; i++) {
                let isChecke = this.fnCheckMenuName(menuItem.children[i], menuLevel + 1);
                if (isChecke) return true
            }
        }

        return false
    }
}