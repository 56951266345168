import { Flow } from './Flow'
import { FlowFile } from './FlowFile'
import { FlowRequestQueryParams } from './FlowRequestQueryParams'

/**
 * Class for storing a single chunk
 * @name FlowChunk
 * @param {Flow} flowObj
 * @param {FlowFile} fileObj
 * @param {number} offset
 * @constructor
 */
export class FlowChunk {
  /**
   * Reference to parent flow object
   * @type {Flow}
   */
  flowObj: Flow

  /**
   * Reference to parent FlowFile object
   * @type {FlowFile}
   */
  fileObj: FlowFile

  /**
   * File offset
   * @type {number}
   */
  offset: number

  /**
   * Indicates if chunk existence was checked on the server
   * @type {boolean}
   */
  tested = false

  /**
   * Number of retries performed
   * @type {number}
   */
  retries = 0

  /**
   * Pending retry
   * @type {boolean}
   */
  pendingRetry = false

  /**
   * Preprocess state
   * @type {number} 0 = unprocessed, 1 = processing, 2 = finished
   */
  preprocessState = 0

  /**
   * Read state
   * @type {number} 0 = not read, 1 = reading, 2 = finished
   */
  readState = 0

  /**
   * Used to store the bytes read
   * @type {Blob|string}
   */
  bytes?: Blob | string = undefined

  /**
   * Bytes transferred from total request size
   * @type {number}
   */
  loaded = 0

  /**
   * Total request size
   * @type {number}
   */
  total = 0

  /**
   * Size of a chunk
   * @type {number}
   */
  chunkSize: number

  /**
   * Chunk start byte in a file
   * @type {number}
   */
  startByte: number

  /**
   * A specific filename for this chunk which otherwise default to the main name
   * @type {string}
   */
  filename = null

  /**
   * Chunk end byte in a file
   * @type {number}
   */
  endByte: number

  /**
   * XMLHttpRequest
   * @type {XMLHttpRequest}
   */
  xhr: XMLHttpRequest | null = null

  constructor(flowObj: Flow, fileObj: FlowFile, offset: number) {
    this.flowObj = flowObj
    this.fileObj = fileObj
    this.offset = offset
    this.chunkSize = this.fileObj.chunkSize
    this.startByte = this.offset * this.chunkSize
    this.endByte = this.computeEndByte()
  }

  //
  // ─── PRIVATE METHODS ────────────────────────────────────────────────────────────
  //

  /**
   * Compute the endbyte in a file
   *
   */
  private computeEndByte(): number {
    var endByte = Math.min(
      this.fileObj.size,
      (this.offset + 1) * this.chunkSize
    )
    if (
      this.fileObj.size - endByte < this.chunkSize &&
      !this.flowObj.opts.forceChunkSize
    ) {
      // The last chunk will be bigger than the chunk size,
      // but less than 2 * this.chunkSize
      endByte = this.fileObj.size
    }
    return endByte
  }

  /**
   * Send chunk event
   * @param event
   * @param {...} args arguments of a callback
   */
  private event(event: any, args: any) {
    args = Array.prototype.slice.call(arguments)
    args.unshift(this)
    this.fileObj.chunkEvent.apply(this.fileObj, args)
  }

  /**
   * Catch progress event
   * @param {ProgressEvent} event
   */
  private progressHandler(event: any) {
    if (event.lengthComputable) {
      this.loaded = event.loaded
      this.total = event.total
    }
    this.event('progress', event)
  }

  /**
   * Catch test event
   * @param {Event} event
   */
  private testHandler(event: any) {
    var status = this.status(true)
    if (status === 'error') {
      this.event(status, this.message())
      this.flowObj.uploadNextChunk()
    } else if (status === 'success') {
      this.tested = true
      this.event(status, this.message())
      this.flowObj.uploadNextChunk()
    } else if (!this.fileObj.paused) {
      // Error might be caused by file pause method
      // Chunks does not exist on the server side
      this.tested = true
      this.send()
    }
  }

  /**
   * Upload has stopped
   * @param {Event} event
   */
  private doneHandler(event: any) {
    var status = this.status()
    if (status === 'success' || status === 'error') {
      delete (this as any).data
      this.event(status, this.message())
      this.flowObj.uploadNextChunk()
    } else if (!this.fileObj.paused) {
      this.event('retry', this.message())
      this.pendingRetry = true
      this.abort()
      this.retries++
      var retryInterval = this.flowObj.opts.chunkRetryInterval
      if (retryInterval !== null) {
        setTimeout(() => {
          this.send()
        }, retryInterval)
      } else {
        this.send()
      }
    }
  }

  //
  // ─── PUBLIC METHODS ─────────────────────────────────────────────────────────────
  //

  /**
   * Get params for a request
   * @function
   */
  getParams(): FlowRequestQueryParams {
    return {
      flowChunkNumber: this.offset + 1,
      flowChunkSize: this.chunkSize,
      flowCurrentChunkSize: this.endByte - this.startByte,
      flowTotalSize: this.fileObj.size,
      flowIdentifier: this.fileObj.uniqueIdentifier,
      flowFilename: this.fileObj.name,
      flowRelativePath: this.fileObj.relativePath,
      flowTotalChunks: this.fileObj.chunks.length,
    }
  }

  /**
   * Get target option with query params
   * @function
   * @param params
   * @returns {string}
   */
  getTarget(target: any, params: any) {
    if (params.length == 0) {
      return target
    }

    if (target.indexOf('?') < 0) {
      target += '?'
    } else {
      target += '&'
    }
    return target + params.join('&')
  }

  /**
   * Makes a GET request without any data to see if the chunk has already
   * been uploaded in a previous session
   * @function
   */
  test() {
    // Set up request and listen for event
    this.xhr = new XMLHttpRequest()
    this.xhr.addEventListener('load', this.testHandler, false)
    this.xhr.addEventListener('error', this.testHandler, false)
    var testMethod = Flow.evalOpts(
      this.flowObj.opts.testMethod,
      this.fileObj,
      this
    )
    var data = this.prepareXhrRequest(testMethod, true)
    this.xhr.send(data)
  }

  /**
   * Finish preprocess state
   * @function
   */
  preprocessFinished() {
    // Re-compute the endByte after the preprocess function to allow an
    // implementer of preprocess to set the fileObj size
    this.endByte = this.computeEndByte()

    this.preprocessState = 2
    this.send()
  }

  /**
   * Finish read state
   * @function
   */
  readFinished(bytes: any) {
    this.readState = 2
    this.bytes = bytes
    this.send()
  }

  /**
   * Uploads the actual data in a POST call
   * @function
   */
  send() {
    var preprocess = this.flowObj.opts.preprocess
    var read = this.flowObj.opts.readFileFn
    if (typeof preprocess === 'function') {
      switch (this.preprocessState) {
        case 0:
          this.preprocessState = 1
          preprocess(this)
          return
        case 1:
          return
      }
    }
    switch (this.readState) {
      case 0:
        this.readState = 1
        read(
          this.fileObj,
          this.startByte,
          this.endByte,
          this.fileObj.file.type,
          this
        )
        return
      case 1:
        return
    }
    if (this.flowObj.opts.testChunks && !this.tested) {
      this.test()
      return
    }

    this.loaded = 0
    this.total = 0
    this.pendingRetry = false

    // Set up request and listen for event
    this.xhr = new XMLHttpRequest()
    this.xhr.upload.addEventListener('progress', this.progressHandler.bind(this), false)
    this.xhr.addEventListener('load', this.doneHandler.bind(this), false)
    this.xhr.addEventListener('error', this.doneHandler.bind(this), false)

    var uploadMethod = Flow.evalOpts(
      this.flowObj.opts.uploadMethod,
      this.fileObj,
      this
    )
    var data = this.prepareXhrRequest(
      uploadMethod,
      false,
      this.flowObj.opts.method,
      this.bytes as Blob
    )
    var changeRawDataBeforeSend = this.flowObj.opts.changeRawDataBeforeSend
    if (typeof changeRawDataBeforeSend === 'function') {
      data = changeRawDataBeforeSend(this, data)
    }
    this.xhr.send(data)
  }

  /**
   * Abort current xhr request
   * @function
   */
  abort() {
    // Abort and reset
    var xhr = this.xhr
    this.xhr = null
    if (xhr) {
      xhr.abort()
    }
  }

  /**
   * 현재 청크 업로드 상태 검색
   * @function
   * @returns {string} 'pending', 'uploading', 'success', 'error'
   */
  status(isTest?: boolean) {
    if (this.readState === 1) {
      return 'reading'
    } else if (this.pendingRetry || this.preprocessState === 1) {
      // 재 시도를 보류하는 경우 실제로 업로드하는 것과 동일합니다.
      // 재 시도가 시작되기 전에 약간의 지연이있을 수 있습니다.
      return 'uploading'
    } else if (!this.xhr) {
      return 'pending'
    } else if (this.xhr.readyState < 4) {
      // 상태는 실제로 'OPENED', 'HEADERS_RECEIVED'또는 'LOADING'입니다. 즉, 상황이 발생하고 있음을 의미합니다.
      return 'uploading'
    } else {
      if (this.flowObj.opts.successStatuses.indexOf(this.xhr.status) > -1) {
        // HTTP 200, perfect
        // HTTP 202 Accepted - 처리를 위해 요청이 승인되었지만 처리가 완료되지 않았습니다.
        return 'success'
      } else if (
        this.flowObj.opts.permanentErrors.indexOf(this.xhr.status) > -1 ||
        (!isTest && this.retries >= this.flowObj.opts.maxChunkRetries)
      ) {
        // HTTP 413/415/500/501, permanent error
        return 'error'
      } else {
        // 이런 일이 발생해서는 안되지만 503 서비스를 사용할 수없는 경우 재 시도를 재설정하고 대기열에 추가합니다.
        this.abort()
        return 'pending'
      }
    }
  }

  /**
   * Get response from xhr request
   * @function
   * @returns {String}
   */
  message() {
    return this.xhr ? this.xhr.responseText : ''
  }

  /**
   * Get upload progress
   * @function
   * @returns {number}
   */
  progress() {
    if (this.pendingRetry) {
      return 0
    }
    var s = this.status()
    if (s === 'success' || s === 'error') {
      return 1
    } else if (s === 'pending') {
      return 0
    } else {
      return this.total > 0 ? this.loaded / this.total : 0
    }
  }

  /**
   * Count total size uploaded
   * @function
   * @returns {number}
   */
  sizeUploaded() {
    var size = this.endByte - this.startByte
    // can't return only chunk.loaded value, because it is bigger than chunk size
    if (this.status() !== 'success') {
      size = this.progress() * size
    }
    return size
  }

  /**
   * Prepare Xhr request. Set query, headers and data
   * @param {string} method GET or POST
   * @param {bool} isTest is this a test request
   * @param {string} [paramsMethod] octet or form
   * @param {Blob} [blob] to send
   * @returns {FormData|Blob|Null} data to send
   */
  prepareXhrRequest(
    method: any,
    isTest: boolean,
    paramsMethod?: any,
    blob?: Blob
  ) {
    // Add data from the query options
    var query = Flow.evalOpts(
      this.flowObj.opts.query,
      this.fileObj,
      this,
      isTest
    )
    query = Flow.extend(query || {}, this.getParams())

    var target = Flow.evalOpts(
      this.flowObj.opts.target,
      this.fileObj,
      this,
      isTest
    )
    var data: any = null
    if (method === 'GET' || paramsMethod === 'octet') {
      // Add data from the query options
      var params: any[] = []
      Flow.each(query, (v: any, k: any) => {
        params.push([encodeURIComponent(k), encodeURIComponent(v)].join('='))
      })
      target = this.getTarget(target, params)
      data = blob || null
    } else {
      // Add data from the query options
      data = new FormData()
      Flow.each(query, (v: any, k: any) => {
        data.append(k, v)
      })
      if (typeof blob !== 'undefined') {
        data.append(
          this.flowObj.opts.fileParameterName,
          blob,
          this.filename || this.fileObj.file.name
        )
      }
    }

    if (!this.xhr) throw new Error('NULL_REF_ERROR: xhr')
    const xhr: XMLHttpRequest = this.xhr

    xhr.open(method, target, true)
    xhr.withCredentials = this.flowObj.opts.withCredentials

    // Add data from header options
    Flow.each(
      Flow.evalOpts(this.flowObj.opts.headers, this.fileObj, this, isTest),
      (v: any, k: any) => {
        xhr.setRequestHeader(k, v)
      },
      this
    )

    return data
  }
}
