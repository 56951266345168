import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import DatePicker  from '@appbase/components/DatePicker/DatePicker.vue'

@Component({
    components:{ModalHeader,ModalContent,ModalFooter,ContentItem,PreferenceContentTemplate,Dropdown,DatePicker}
})
export default class AutoReplyView extends Vue {
  
}