import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// initial state
const state: orgmap.state.OrgmapState = {
  user: Object.create(null),
  wish: [],
  members: {
    items: [],
    selectedAll: false,
    totalCount: 0,
  },
  selections: {
    dept: null,
    users: [],
  },
  departments: [],
  options: {
    useRetireSearch: false,
    addableDept: false,
    single: false,
    personal: true,
    selectFixedComcode : '',
    public: true,
    containers: [],
  },
  selectTreeItem : false
}

export default {
  state,
  actions,
  getters,
  mutations,
}
