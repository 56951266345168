export const getGUID = function(upperCase: boolean = true): string {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }

  var guid = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()

  if (upperCase) {
    return guid.toUpperCase()
  } else {
    return guid.toLowerCase()
  }
}
