import { RouteConfig } from 'vue-router'
export { default as PortalCacheGuard } from './guards/portal-cache.guard'
export { default as SkinGuard } from './guards/skin.guard'
export { default as I18nGuard } from './guards/i18n.guard'
export { default as multiguard } from './vue-router-multiguard.helper'
export { default as CssSwitchingGuard } from './guards/css-guard'
const Appbase = () => import(/* webpackChunkName: "appbase" */ '@appbase/Index.vue')
const AppbaseMaster = () => import(/* webpackChunkName: "appbase" */ '@appbase/views/Master.vue')

const routes: RouteConfig[] = [
  {
    path: 'appbase',
    component: Appbase,
    children: [
      {
        path: '',
        component: AppbaseMaster,
        children: [],
      },
    ],
  },
]
export default routes
