import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
//import * as types from '@/store/types'

@Component({
    
})

export default class SelectField extends Vue {
    @Prop ({ type: String }) displayName!: string;
    @Prop({ type: String }) fieldValue!: string; 

    selectedLevel: string ="";

    created(){
        console.log('displayName', this.displayName);
        console.log('fieldValue', this.fieldValue);

        if(this.fieldValue != null){
            var level = JSON.parse(this.fieldValue.toString())
            this.selectedLevel = level.TEXT;
        }
    }
}