const path = '$orgmap'
const ACCOUNT = '$account'

export const ORG_ME = `${path}/me`
export const ORG_INIT = `${path}/init`
export const ORG_LOADING = `${path}/loading`
export const ORG_EVENT_TYPE = `${path}/event/type`;

export const ORG_MEMBER_LIST = `${path}/member/list`
export const ORG_MEMBER_SELECT = `${path}/member/select`
export const ORG_MEMBER_SELECT_ALL = `${path}/member/select/all`
export const ORG_MEMBER_SEARCH = `${path}/member/search`

export const ORG_CONTAINER_REGISTER = `${path}/container/register`
export const ORG_CONTAINER_UPDATE = `${path}/container/update`
export const ORG_CONTAINER_ITEM_ADD = `${path}/container/item/add`
export const ORG_CONTAINER_ITEM_DEL = `${path}/container/item/del`
export const ORG_CONTAINER_ITEM_SELECT = `${path}/container/item/select`
export const ORG_CONTAINER_ITEM_SELECT_ALL = `${path}/container/item/select/all`
export const ORG_CONTAINER_DEPT_ITEM_ADD = `${path}/container/dept/item/add`
export const ORG_CONTAINER_ACTIVATE = `${path}/container/activate`
export const ORG_CONTAINER_CLEAR = `${path}/container/clear`

export const ORG_PUBLIC_FOLDER_LIST = `${path}/public/folder/list`
export const ORG_PUBLIC_MEMBER_LIST = `${path}/public/member/list`

export const ORG_PERSONAL_FOLDER_LIST = `${path}/personal/folder/list`;
export const ORG_PERSONAL_MEMBER_LIST = `${path}/personal/member/list`;
export const ORG_PERSONAL_GROUPMEMBER_LIST = `${path}/personal/group-member/list`;

export const ORG_USER_SELECT = `${path}/user/select`;
export const ORG_USER_SELECT_CLEAR = `${path}/user/select/clear`;

export const ORG_OPTION_SET = `${path}/option/set`;
export const ORG_OPTION_GET = `${path}/option/get`;

export const ACCOUNT_CURRENT = `${ACCOUNT}/current`



export const ORG_SELECT_TREE_ITEM = `${path}/treeitem/select`

