import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import * as cache from '@appbase/api'

export enum OpenType{
    popup = 1,
    move = 2,
    modal = 3
}

export interface FixedQuickMenu{
    id : number,
    type : OpenType,
    link : string
    displayName : string
    displayNameEng : string
    imgLink : string
}


@Component
export default class ZinusQuickMenu extends Vue {
    items: Array<appbase.cache.TMenuItem> = [] 
    
    //고정 메뉴 5개 정보
    fixedItems : Array<FixedQuickMenu> = [
        {id : 1, imgLink:"/cm/gres/faux/images/gnb_quick_authentication.png", type:OpenType.move, link: "auth", displayName : "통합인증", displayNameEng : "Integrated certification"},
        {id : 2, imgLink:"/cm/gres/faux/images/gnb_quick_webmail.png", type:OpenType.move, link: "mail", displayName : "웹메일", displayNameEng : "Email"},
        {id : 3, imgLink:"/cm/gres/faux/images/gnb_quick_board.png", type:OpenType.move, link: "personal", displayName : "인사e-열린방", displayNameEng : "Personnel Board"},
        {id : 4, imgLink:"/cm/gres/faux/images/gnb_quick_documentbox.png", type:OpenType.move, link: "document", displayName : "문서함", displayNameEng : "Documents"},
        {id : 5, imgLink:"/cm/gres/faux/images/gnb_quick_security.png", type:OpenType.move, link: "security-portal", displayName : "보안포탈", displayNameEng : "Security Portal"},
    ]


    created() {
        //캐쉬 정보 입력
       this.items = this.portalCache.quick.items
    }

    async onClickedMenu( link:string, type: OpenType = OpenType.popup){
        
        //임시환경
        if(true){
            await (window as any).alertAsync(`링크 이동 : ${link}\nOpenType : ${type}`)
            return
        }



        if(type == OpenType.popup){
            this.$router.push({
                path: link
            })
        }else if(type==OpenType.move){
            window.open(link)
        }
    }
}