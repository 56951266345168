import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ModalLayout } from '@appbase/templates'
import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import * as dateutil from '@appbase/util/date-helper'
import api from '@attendance/api'
import * as accountTypes from '@account/store/types'
import { createEventBus, removeEventBus } from '@appbase/util/eventBus';
import { openPopup } from '@appbase/util';

export interface TWishContainerOptionItem {
    id: number
    name: string
    height: string
    items? : orgmap.TWishContainerItem[]
}

export interface TWishContainerOptions {
    addableDept: boolean,
    containers: TWishContainerOptionItem[]
}

export interface TWishItemPropsOptions {
    props: string[]
}

const ATTENDANCE_ORG_POP = '$attendance/org/popup'

@Component({
    components: {
        ModalLayout,
        DatePicker
    },
    computed: mapGetters({
        user: accountTypes.USER
   })
})
export default class AttendanceStaggerDetailModalView extends Vue {
    @Prop({ type: Number }) staggeredId!: number
    @Prop({ type: Object }) staggerItem!: stagger.StaggerItem
    @Prop({ type: String }) viewType!: String
    @Prop({ type: Array }) workingTypeList!: stagger.StaggerAttendanceType[]

    user!: account.TUser;

    // arrIntime = [ 6, 7, 8, 9, 10 ];
    // arrOutTime = [ 15, 16, 17, 18, 19 ];
    attendanceStartDate: string = '';
    attendanceEndDate: string = '';
    inTime: number = 0;
    outTime: number = 0;
    workingTime: number = 8;
    etc: string | null = null;
    personCode : string = '';
    displayName: string = '';
    displayDeptName: string = '';
    displayTitleName: string = '';

    //근무형태 추가
    workingType:number = 1;

    close(reload: boolean) {
        this.$emit('close', reload)
    }

    // 조직도 오픈
    onOpenOrg(){
        const orgmapOption: TWishContainerOptions = {
            addableDept: false,
            containers: [
               { id: 0, name: this.$t('사용자').toString(), height: '1' }               
            ] 
        }

        const props: TWishItemPropsOptions = {
            props: ['id', 'name', 'personCode', 'deptName', 'deptCode', 'title'],
        }

        openPopup('reserveRecipients', '/orgmap', 
            { event: `${ATTENDANCE_ORG_POP}`, option: orgmapOption, props }, 
            { width: 1024, height: 768 }
        );
    }

    async onSaveClick() {
        // 유효성 검사
        if (this.attendanceStartDate == '')
        {
            await (window as any).alertAsync(this.$t('시작일자를_선택하시기_바랍니다').toString());
            return;
        }
        if (this.attendanceEndDate == ''){
            await (window as any).alertAsync(this.$t('종료일자를_선택하시기_바랍니다').toString());
            return;
        }
        if (this.attendanceStartDate > this.attendanceEndDate){
            await (window as any).alertAsync(this.$t('시작일이_종료일보다_이후일_수_없습니다'));
            return;
        }
        if (this.viewType == 'A' && this.staggeredId == 0 && this.personCode == ''){
            await (window as any).alertAsync(this.$t('선택한_사용자가_없습니다'));
            return;
        }

        // 중복 체크
        var pCode = this.staggeredId == 0 ? (this.viewType == 'A' ? this.personCode : this.user.personCode) : this.personCode;
        api.stagger.getStaggerCheckDate(pCode, this.staggeredId, this.attendanceStartDate, this.attendanceEndDate).then(async res => {
            if (res > 0){
                await (window as any).alertAsync("중복된 일자가 존재합니다.");
            }
            else{
                var confirmMessage = (this.staggeredId == 0 ? this.$t('저장_하시겠습니까') : this.$t('수정하시겠습니까')).toString();
                if (await(window as any).confirmAsync(confirmMessage))
                {
                    var data: stagger.StaggerItemRequest = {
                        staggeredId: this.staggeredId,
                        attendanceStartDate: this.attendanceStartDate,
                        attendanceEndDate: this.attendanceEndDate,
                        inTime: this.inTime,
                        outTime: this.outTime,
                        //workingTime: this.workingTime ? this.setDisplayHours(this.workingTime) : null,
                        workingTime: this.workingTime,
                        etc: this.etc,
                        personCode: (this.staggeredId == 0 ? (this.viewType == 'A' ? this.personCode : this.user.personCode) : this.personCode),
                        displayName: (this.staggeredId == 0 ? (this.viewType == 'A' ? this.displayName : this.user.displayName) : this.displayName),
                        displayDeptName: (this.staggeredId == 0 ? (this.viewType == 'A' ? this.displayDeptName : this.user.deptName) : this.displayDeptName),
                        displayTitleName: (this.staggeredId == 0 ? (this.viewType == 'A' ? this.displayTitleName : this.user.titleName) : this.displayTitleName),
                        staggerAttendanceTypeId: this.workingType
                    }
            
                    api.stagger.createUpdateStagger(data).then(async res => {
                        var msg = '';
                        
                        if (res > -1) {
                            if (this.staggeredId > 0) msg = this.$t('수정_하였습니다').toString(); 
                            else msg = this.$t('저장_하였습니다').toString();
                            await (window as any).alertAsync(msg);
                            
                            // 종료 및 Reload
                            this.close(true);
                        }
                        else {
                            if (this.staggeredId > 0) msg = this.$t('수정에_실패_하였습니다').toString(); 
                            else msg = this.$t('저장에_실패하였습니다').toString();
                            await (window as any).alertAsync(msg);
                        }
                    })
                }
            }
        });
    }

    // 등록된 근무시간 설정 삭제
    async onDeleteClick(){
        if (await(window as any).confirmAsync(this.$t("삭제_하시겠습니까").toString()))
        {
            var pCode = this.viewType == 'A' ? this.personCode : this.user.personCode;
            api.stagger.deleteStagger(pCode, this.staggeredId).then(async res => {
                if (res > -1){
                    await (window as any).alertAsync(this.$t("삭제_하였습니다"));
                    this.close(true);
                }
                else{
                    await (window as any).alertAsync(this.$t("삭제에_실패하였습니다"));
                }
            });
        }
    }

    setDisplayDate(date: Date){
        var displayDate = new Date(date.toString());
        return dateutil.uGetDateFormat('yyyy-MM-DD', displayDate);
    }

    setDisplayTime(date: Date | null){
        if (date) {
            var displayDate = new Date(date.toString());
            return dateutil.uGetDateFormat('H:i', displayDate);
        } else {
            return null;
        }
    }

    setDisplayHours(hours: number | null){
        if (hours) {
            return (hours.toString().length == 1 ? '0' : '') + hours + ':00';
        } else {
            return null;
        }
    }

    // 출근시간, 퇴근시간 변경 이벤트 처리
    // obj : Event
    // onChangeTime(obj: any){
    //     var selIndex = obj.target.selectedIndex;
    //     this.inTime = this.arrIntime[selIndex];
    //     this.outTime = this.arrOutTime[selIndex];
    // }

    // 출근시간, 퇴근시간 변경 이벤트 Watch 감지로 변경
    @Watch('inTime')
    changeIntime(val:number){
        if((this.outTime+this.workingTime+1) != this.outTime)
        this.outTime = val+this.workingTime+1
    }

    @Watch('outTime')
    changeOuttime(val:number){
        this.inTime = val-this.workingTime-1
    }

    @Watch('workingType')
    changeType(val:number){
        this.workingTime = this.workingTypeList.find((t:stagger.StaggerAttendanceType)=>t.typeId == val)?.basicWorkingHours!
        this.outTime = this.inTime+this.workingTime+1
    }

    created() {
        if (this.staggeredId > 0 && this.staggerItem){
            this.attendanceStartDate = this.staggerItem.attendanceStartDate;
            this.attendanceEndDate =  this.staggerItem.attendanceEndDate;
            this.inTime = this.staggerItem.inTime.hours;
            this.outTime = this.staggerItem.outTime.hours;
            this.workingTime = this.staggerItem.workingTime.hours;
            this.etc = this.staggerItem.etc;
            this.personCode  = this.staggerItem.personCode ? this.staggerItem.personCode : '';
            this.displayName = this.staggerItem.displayName ? this.staggerItem.displayName : '';
            this.displayDeptName = this.staggerItem.displayDeptName ? this.staggerItem.displayDeptName : '';
            this.displayTitleName = this.staggerItem.displayTitleName ? this.staggerItem.displayTitleName : '';
            this.workingType = this.staggerItem.staggerAttendanceTypeId ? this.staggerItem.staggerAttendanceTypeId : 1;
        }
        else{
            this.workingTime = this.workingTime
            this.inTime = 6
        }

        createEventBus(window, `${ATTENDANCE_ORG_POP}`, this.handlePopupResult);
    }

    beforeDestroy() {
        removeEventBus(window, `${ATTENDANCE_ORG_POP}`, this.handlePopupResult);
    }

    handlePopupResult(data: any){
        if (data && data.length > 0){
            var orgDataResult: any[] = data[0].items;
            if (orgDataResult && orgDataResult.length > 0){
                this.personCode = orgDataResult[0].personCode;
                this.displayName = orgDataResult[0].name;
                this.displayDeptName = orgDataResult[0].deptName;
                this.displayTitleName = orgDataResult[0].title;
            }
        }    
    }
}