import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import UserList from './UserList.vue';

@Component({
    name: 'DepartmentItem',
    components: { UserList },
})
export default class DepartmentItem extends Vue {
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    users!: orgmap.PagedItemList<orgmap.UserItem>;
    depts!: any;
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    loadUsers!: () => void;
    expand!: (data: {
        deptInfo: orgmap.Department;
        emailList: string[];
    }) => void;
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    // @Prop({ type: Object }) item!: orgmap.TreeListItem;
    @Prop({ type: Object }) item!: any;
    @Prop({ type: String, default: 'name' }) headerField!: string;
    @Prop({ type: String, default: 'children' }) childrenField!: string;
    @Prop({ type: [String, Function] }) hasChildrenField!:
        | string
        | orgmap.EvaluateTreeListItemHasChildrenFuction;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    delay: number = 200;
    clicks: number = 0;
    timer: any = null;

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    // get FilteredUsers() {
    //     return this.users.items.filter((item: any) => {
    //         return item.deptCode === this.item.deptCode;
    //     });
    // }

    // get isSelected() {
    //     const { selected } = this.item;

    //     let isSelected = false;
    //     if (typeof selected === 'boolean') {
    //         isSelected = selected;
    //     } else if (typeof selected === 'function') {
    //         isSelected = selected(this.item);
    //     }

    //     // if (isSelected) {
    //     //     this.item.expanded = true;
    //     // } else {
    //     //     this.item.expanded = false;
    //     // }

    //     return isSelected;
    // }

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Watch('item.expanded')
    onExpanded(newValue: boolean): void {
        if (newValue != undefined && newValue === true) {
            this.$emit('expand', this.item);
        }
    }

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    hasChildren(): boolean {
        if (typeof this.hasChildrenField === 'string') {
            return (this.item as any)[this.hasChildrenField];
        } else {
            return this.hasChildrenField(this.item);
        }
    }

    select(item: orgmap.TreeListItem) {
        this.$emit('select', item);
    }
}
