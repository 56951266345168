import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import StorageView from '@gnb/components/Preference/Mail/Storage/StorageView.vue'
import { ModalLayout } from '@appbase/templates'
import SimpleFolderList from '@gnb/components/Common/SimpleFolderList.vue'
import PopupModal from '@gnb/components/Common/PopupModal.vue'
import api from '@gnb/api/common'
@Component({
    components:{
        PopupModal,
        ModalLayout,
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        StorageView,
        SimpleFolderList
    }
})
export default class FolderMoveModal extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    selectedFolder: gnb.MailboxFolder = {}as gnb.MailboxFolder;
    title: string = this.$t('W_Mail_MoveFolder').toString();

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //


    @Prop({type: Boolean}) show!: false;
    @Prop({type: String}) id!: '';

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    select(folder: gnb.MailboxFolder) {
        this.selectedFolder = folder;
    }

    move(){
        api.moveFolders(
           [this.id],this.selectedFolder.id,
        ).then(async (succeed:boolean) => {
            if (succeed) {
                await (window as any).alertAsync(this.$t('이동되었습니다').toString());
                this.$emit('cancel');
            }
        });
    }

 
}