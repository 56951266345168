import Vue from 'vue'
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import * as types from '@gnb/store/types'
import { mapActions, mapGetters } from 'vuex'
import MessageApprovalView from './MessageApprovalView.vue'
@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        Dropdown,
        MessageApprovalView}
    ,computed:{
        ...mapGetters({
            mySetting: types.SETTINGS_MYSETTING_DATA,
        })
    }
    ,methods:{
            ...mapActions({
            saveSetting: types.SETTINGS_MYSETTING_SAVE,
        })
    }
})
export default class MessageApproval extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    name:string = this.$t('메신저_수신여부').toString();
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({ type: Object }) settingInfo!: gnb.TA_MYSETTING;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
   
    saveSetting!: (payload: { data: gnb.TA_MYSETTING, isFirst: boolean }) => Promise<boolean>;
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    mySetting!: gnb.CM_MySetting;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    save(){
        this.settingInfo.updatedDate = new Date();
        this.saveSetting({ data: this.settingInfo, isFirst: false })
        .then(async (res: boolean) => {
            if (res) {
                await (window as any).alertAsync(this.$t('적용되었습니다').toString());
                this.$emit('cancel');
            }
            else {
                await (window as any).alertAsync(this.$t('적용하는_도중에_문제가_발생했습니다').toString());
            }
            });
    }

    created(){
        console.log('SettingInfo', this.settingInfo)
    }
    
    
}