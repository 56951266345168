import { ActionTree } from 'vuex'
import * as types from './types'
import api from '../api'
import router from '@/router'

export interface LoginActionPayload {
  username: string
  password: string
}

export default <ActionTree<account.state.AccountState, RootState>>{
  async [types.LOGIN](action, payload: LoginActionPayload) {
    const result = await api.auth.login(payload.username, payload.password)
    action.commit(types.CURRENT, result)
    return result
  },

  async [types.LOGINEX](action, payload: LoginActionPayload) {
    const result = await api.auth.loginex(payload.username)
    action.commit(types.CURRENT, result)
    return result
  },

  async [types.LOGINHINT](action, payload: LoginActionPayload) {
    const result = await api.auth.loginHint(payload.username)
    action.commit(types.CURRENT, result)
    return result
  },

  async [types.CURRENT](action, payload) {
    const result = await api.account.getCurrentUser()
    action.commit(types.CURRENT, result)
    return result
  },

  async [types.LOGOUT](action, payload) {
    const result = await api.auth.logout()
    action.commit(types.CURRENT, null)
    router.push({ name: 'account-login' })
    return result
  },
}
