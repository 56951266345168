import { GetterTree } from 'vuex'
import * as types from './types'

export default <GetterTree<account.state.AccountState, RootState>>{
  [types.CURRENT](state) {
    return state.current
  },

  [types.USER](state) {
    return state.current?.user
  },
}
