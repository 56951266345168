import Vue from 'vue'
import api from '../../api'
import store from '@/store'
import { NavigationGuard } from 'vue-router'

const PortalCacheGuard: NavigationGuard = async (to, from, next) => {
  console.log(`Evaluating ${PortalCacheGuard.name}...`)
  const current = (store.state as any).$account?.current as account.TCurrent | null | undefined
  if (current) {
    const comCode = current.user.comCode
    const [menu, quick, webparts] = await Promise.all([
      api.cache.loadMenuCache(comCode, current.cacheVersion.menu || 0),
      api.cache.loadQuickMenuCache(comCode, current.cacheVersion.quickMenu || 0),
      api.cache.loadWebpartCache(comCode, current.cacheVersion.webpart || 0),
    ])
    const cache: appbase.cache.TPortalCache = {
      menu,
      quick,
      webparts,
    }
    Vue.prototype.portalCache = {
      ...Vue.prototype.portalCache,
      ...cache,
    }
    next()
  } else {
    throw new Error(
      `[INVALID_OPERATION_ERROR] ${PortalCacheGuard.name} : store.state.$account is not initialized.`
    )
  }
}

export default PortalCacheGuard
