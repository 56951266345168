import { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import api from '../api'
export { default as AuthGuard } from './auth-guard'
const Account = () => import(/* webpackChunkName: "account" */ '@account/Index.vue')
const AccountMaster = () => import(/* webpackChunkName: "account" */ '@account/views/Master.vue')
const LoginView = () =>
  import(/* webpackChunkName: "account" */ '@account/views/LoginView/LoginView.vue')

const MllwLoginView = () =>
import(/* webpackChunkName: "account" */ '@account/views/LoginView/MllwLoginView.vue')

  
const NotAuthView = () => import(/* webpackChunkName: "account" */ '@account/views/NotAuthView.vue')

const guardAccessControl = (to: Route, from: Route, next: NavigationGuardNext) => {
  api.ac
    .check()
    .then((allowed) => {
      console.log('check ac : ' + allowed)
      // if (allowed) next()
      // else window.location.href = '/cm/noauth'
    })
    .catch((err) => {
      console.error(err)
      next('/noauth')
    })
}

const getRoutes = (root: boolean = false) => {
    return [
        {
          path: (root ? '/' : '') + 'account',
          component: Account,
          children: [
            {
              path: '',
              component: AccountMaster,
              children: [
                {
                  path: 'login',
                  name: 'account-login',
                  // beforeEnter: guardAccessControl,
                  component: LoginView,
                },
                {
                  path: 'mllw-login',
                  name: 'mllw-account-login',
                  // beforeEnter: guardAccessControl,
                  component: MllwLoginView,
                },
                {
                  path: 'noauth',
                  component: NotAuthView,
                },
                {
                  path: '',
                  redirect: 'login',
                },
              ],
            },
          ],
        },
      ]
}

const routes: RouteConfig[] = getRoutes(false);
export const rootRoutes = getRoutes(true);
export default routes
