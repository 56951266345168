import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ListHeader, ListImageArticle } from '../../components/index';
import WebpartBase from '@webparts/views/WebpartBase'
import api from '../../api/index'
import { openPopup } from '@appbase/util/popup-helper'
@Component({
    components: {
        ListHeader,
        ListImageArticle
    }
})
export default class ListSummaryWebpart extends WebpartBase {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    activeBoardId!: number;
    nowPageNumber: number = 1;
    totalCount!: number;
    listArticles?: any = [];
    boardDefaultImage: string = "";

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    TabClick(boardId: number) {
        if (this.activeBoardId != boardId) {
            this.nowPageNumber = 1;
            this.activeBoardId = boardId
            this.getArticles();
        }
    }

    Prev() {
        if (this.nowPageNumber > 1) {
            this.nowPageNumber--;
            this.getArticles();
        }
    }

    Next() {
        if (Math.ceil(this.totalCount / this.option.tabs![0].displayCount) > this.nowPageNumber) {
            this.nowPageNumber++;
            this.getArticles();
        }

    }

    Refresh() {
        this.nowPageNumber = 1;
        this.getArticles();
    }

    async load() {
        if (this.option.tabs != null && this.option.tabs.length > 0)
            this.activeBoardId = this.option.tabs[0].boardId;

        this.boardDefaultImage = await api.webPart.getBoardDefaultImage(this.activeBoardId).then((m: any) => m);
        this.getArticles();
    }

    async getArticles() {
        var res = await api.webPart.getArticles(this.activeBoardId, this.option.tabs![0].displayCount, this.nowPageNumber, true, false).then((m: any) => m);

        this.listArticles = res.data;
        this.totalCount = res.totalCount;
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E S T Y L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //
    mounted() {
        this.option.tabs![0].isPaging = true;
        this.option.tabs![0].isContentShow = true;
        this.load();
    }
}