<template>
  <PageLayout>
    <!-- <Header /> -->
    <!-- <GlobalNavigationBar /> -->
    <GnbLeftView />
    <!---------- 시작 : OuterWrap ---------->
    <div id="outer-wrap">
      <LeftMenu />
      <SubContentTemplate>
        <template v-slot:body>
          <slot></slot>
        </template>
      </SubContentTemplate>
    </div>
    <!---------- 끝 : wrapper ---------->
  </PageLayout>
</template>

<script>
import { PageLayout } from '@appbase/templates'
import GlobalNavigationBar from '@gnb/views/GlobalNavigationBar/GlobalNavigationBar.vue'
import { LeftMenu } from '../components/common'
import SubContentTemplate from './SubContentTemplate.vue'
import GnbLeftView from '@gnb/views/GnbLeft/GnbLeftView.vue'
export default {
  components: { PageLayout, GlobalNavigationBar, LeftMenu, SubContentTemplate, GnbLeftView },
  props: { useLeft: { type: Boolean, default: true } },
}
</script>

<style></style>
