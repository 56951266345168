import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { openPopup } from '@appbase/util/popup-helper'
import * as dateutil from '@appbase/util/date-helper'
@Component({
    components: {

    }
})
export default class ListSuveyArticle extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    @Prop({type: Object}) board: any;
    @Prop({ type: Object }) article!: any;
    @Prop({ type: Boolean, default: false }) isPreview!: boolean;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    articleClick(surveyId: number) {
        if (!this.isPreview) {
            openPopup('ListSuveyWebpart', `/survey/popup/Read?surveyId=${surveyId}&MENUID=${this.board.boardId}`, {}, {
                width: 1024,
                height: 700,
            });
        }
    }
    convertDate(date: string) {
        return dateutil.uGetDateFormat("yyyy.MM.dd", new Date(date))
    }

    convertStatus(data: any, isClass: boolean = false) {
        var returnString = ""
        switch (data.surveyStatus) {
            case 1:
                returnString = isClass ? "badge-lightgray" : this.$t('게시대기').toString();
                break
            case 2:
                let spanDays = this.getSpanDays(new Date(data.endDate))

                if (spanDays > -1 && spanDays < 4) {
                    returnString = isClass ? `badge-yellow` : `D-${spanDays}`
                } else { //진행중일 때 종료3일전부터는 d-3 으로 표시
                    returnString = isClass ? "badge-green" : this.$t('진행중').toString();
                }
                break
            case 3:
                returnString = isClass ? "badge-lightgray" : this.$t('종료').toString();
                break
        }

        return returnString
    }

    getSpanDays(endDate: Date) {
        endDate.setHours(23, 59, 59, 999); //endOfDays
        const now = new Date()

        var diff = endDate.getTime() - now.getTime();
        let days = Math.floor(diff / (1000 * 60 * 60 * 24));

        return days
    }
}
