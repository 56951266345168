import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import {ModalHeader, ModalContent, ModalFooter,ContentItem,Dropdown} from '@gnb/components/Common'
import PreferenceContentTemplate from '@gnb/templates/PreferenceContentTemplate.vue'
import api from '@gnb/api/common'
import { mapActions } from 'vuex'
import * as types from '@gnb/store/types'

@Component({
    components:{
        ModalHeader,
        ModalContent,
        ModalFooter,
        ContentItem,
        PreferenceContentTemplate,
        Dropdown
    },
    methods:{
        ...mapActions({
            loading: types.COMMON_LOADDING
        })
    }
})
export default class ListCount extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    @Prop({ type: Object }) user!: account.User;
    
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    name:string = this.$t('목록_수').toString();
    selectedCount: string = '';
    count = [
        {
            name: '10' + this.$t('개').toString(),
            key: '10'
        },
        {
            name: '30' + this.$t('개').toString(),
            key: '30'
        },
        {
            name: '50' + this.$t('개').toString(),
            key: '50'
        },
    ]

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    loading!: (option: boolean) => void;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //


    save(){
        api.setUserConfig({personCode: this.user.personCode, mailListCount: parseInt(this.selectedCount)} as gnb.UserConfig).then(async ()=>{
            await (window as any).alertAsync('저장되었습니다.')
        }).then(()=>{
            this.$emit('cancel')
        });
    }

    select(selectedItem: string){
        this.selectedCount = selectedItem;
    }

    getMailListCount(){
        api.getUserConfig(this.user.personCode).then((res)=>{
            if(res[0].mailListCount){
                this.selectedCount = res[0].mailListCount.toString();
                if(this.selectedCount){
                    this.loading(false);
                }
            }
         })
    }

    //
    // ──────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E Y : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────────
    //

    created(){
        this.getMailListCount();
    }
}