import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'DepartmentInfo',
})
export default class DepartmentInfo extends Vue {
  @Prop({ type: String }) name!: string
  @Prop({ type: Number }) count!: number
}
