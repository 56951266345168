import router from "@/router";

export type OpenOption = {
    articleId?: number;
    boardId?: number;
    parentArticle?: number;
    baseUrl: string;
}

export const enum OpenType { 
    ModalRead, 
    PopupRead, 
    ModalWrite, 
    PopupWrite,
    ModalEdit,
    PopupEdit,
    ModalReply,
    PopupReply
};



export const open = function(type: OpenType, option: OpenOption) {                     
    if(type == OpenType.PopupRead) {                
        window.open(
            `${option.baseUrl}readPopup?SYSID=${option.boardId}&ARTICLEID=${option.articleId}`,
            `BoardRead-${option.articleId}`,
            "width=900,height=760,resizable=yes");
    }

    if(type == OpenType.ModalRead) {
        router.push({
            path: 'modal/read',
            append: true,
            query: {                  
                SYSID: option.boardId?.toString(),
                ARTICLEID: option.articleId?.toString()
            }
        })
    }

    if(type == OpenType.PopupWrite) {
        window.open(
            `${option.baseUrl}writePopup?SYSID=${option.boardId}`,
            "BoardWrite",
            "width=900,height=760,resizable=yes");       
    }

    if(type == OpenType.ModalWrite) {
        router.push({
            path: 'modal/write',
            append: true,
            query: {                  
                SYSID: option.boardId?.toString(),               
            }
        })
    }

    if(type == OpenType.PopupEdit) {
        var path = router.currentRoute.path.replace('readPopup', 'writePopup');

        router.push({
            path: path,
            query: router.currentRoute.query
        });
    }

    if(type == OpenType.ModalEdit) {
        var path = router.currentRoute.path.replace('read', 'write');

        router.push({
            path: path,
            query: router.currentRoute.query
        })
    }

    if(type == OpenType.PopupReply) {
        var path = router.currentRoute.path.replace('readPopup', 'writePopup');

        router.push({
            path: path,
            query: {
                SYSID: option.boardId?.toString(),
                PARENTARTICLE: option.parentArticle?.toString()
            }
        });
    }

    if(type == OpenType.ModalReply) {
        var path = router.currentRoute.path.replace('read', 'write');

        router.push({
            path: path,
            query: {
                SYSID: option.boardId?.toString(),
                PARENTARTICLE: option.parentArticle?.toString()
            }
        })
    }
}

