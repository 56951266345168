import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// initial state
const state: webparts.state.WebpartsState = {
    awsRequest: undefined,
    awsResponse: undefined,
};

export default {
    state,
    actions,
    getters,
    mutations,
};
