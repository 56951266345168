import api from "@/api";
import { uGetDateForDisplayMonth } from "@appbase/util/date-helper";
import { combindWithQuery } from "@appbase/util";
import axios from "axios";

interface ResponseWrapper<TResult> {
  result: TResult;
  code: string;
  message: string;
  error: string | null;
}

export default {
  /**
   * LEAVE MANAGE ADMIN
   */

  getLeaveManageAdminYears() {
    return api.get({
      url: `/ZLeave/Z_Leave/api/leavemaster/years`,
    });
  },

  getLeaveManageAdminList(
    searchFilter: string,
    searchWord?: string,
    pageNum?: number,
    rowSize?: number,
    searchYear?: string,
    isManagerReq?: boolean,
    comCode?: string
  ) {
    if (searchWord == "" || null || undefined) {
      searchFilter = "";
    }

    return api.get({
      url: `/ZLeave/Z_Leave/api/leavemaster/list?searchFilter=${searchFilter}&searchWord=${searchWord}
        &pageNum=${pageNum}&rowSize=${rowSize}&searchYear=${searchYear}&isManagerReq=${isManagerReq}&comCode=${comCode}`,
    });
  },

  getLeaveManageAdmin(id: string) {
    return api.get({
      url: `/ZLeave/Z_Leave/api/leavemaster/item?id=${id}`,
    });
  },

  setLeaveManageAdmin(payload: any) {
    return api.post({
      url: `/ZLeave/Z_Leave/api/leavemaster/modify`,
      data: payload,
    });
  },

  // addLeaveManage(personCode: string, year: number, assignDay: number, extraSpend: number): Promise<boolean> {
  addLeaveManage(payload: Leave.leaveManageReq): Promise<boolean> {
    return api.post({
      url: '/ZLeave/Z_Leave/api/leavemaster/add',
      data: payload
    })
  },


  /**
   * LEAVE ADMIN
   */

  getLeaveCodes(comCode: string) {
    if (comCode == "" || null || undefined) {
      comCode = "";
    }
    return api.get({
      url: `/ZLeave/Z_Leave/api/leave/codes?comCode=${comCode}`,
    });
  },

  getLeaveCategories() {
    return api.get({
      url: '/ZLeave/Z_Leave/api/leave/leaveCategories'
    })
  },

  getLeaveAdminList(
    searchFilter: string,
    searchWord?: string,
    pageNum?: number,
    rowSize?: number,
    startDate?: string,
    endDate?: string,
    isPersonReq?: boolean,
    comCode?: string,

    leaveCategory?: string,
    leaveCode?: number,
    leaveType?: string,
    leaveStatus?: string,
    workingStatus?: string
  ) {
    if (searchWord == "" || null || undefined) {
      searchFilter = "";
    }

    return api.get({
      url: `/ZLeave/Z_Leave/api/leave/list?searchFilter=${searchFilter}&searchWord=${searchWord}&pageNum=${pageNum}
        &rowSize=${rowSize}&startDate=${startDate}&endDate=${endDate}&isPersonReq=${isPersonReq}&comCode=${comCode}
        &leaveCategory=${leaveCategory}&leaveCode=${leaveCode}&leaveType=${leaveType}&leaveStatus=${leaveStatus}&workingStatus=${workingStatus}`,
    });
  },

  getLeaveAdmin(id: string) {
    return api.get({
      url: `/ZLeave/Z_Leave/api/leave/item?id=${id}`,
    });
  },

  getLeaveSummary(year: number): Promise<Array<Leave.leaveSummary>> {
    return api.get({
      url: `/ZLeave/Z_Leave/api/leave/summary?year=${year}`
    })
  },

  /**
   * LEAVE CODE
   */

  getLeaveCodeList(pageNum?: number, rowSize?: number, comCode?: string) {
    return api.get({
      url: `/ZLeave/Z_Leave/api/leavecode/list?pageNum=${pageNum}&rowSize=${rowSize}&comCode=${comCode}`,
    });
  },

  getLeaveCode(id: string) {
    return api.get({
      url: `/ZLeave/Z_Leave/api/leavecode/item?id=${id}`,
    });
  },

  setLeaveCode(payload: any) {
    return api.post({
      url: `/ZLeave/Z_Leave/api/leavecode/modify`,
      data: payload,
    });
  },

  addLeaveCode(payload: any) {
    return api.post({
      url: `/ZLeave/Z_Leave/api/leavecode/add`,
      data: payload,
    });
  },

  /**
   * Excel
   */

  downloadManageListExcel(searchFilter: string | null,searchWord?: string,searchYear?: string, isManagerReq: boolean = false, comCode?: string) {
    const url = `/ZLeave/Z_Leave/api/leavemanage/download?searchFilter=${searchFilter}&searchWord=${searchWord}&searchYear=${searchYear}
      &isManagerReq=${isManagerReq}&comCode=${comCode}`;
    return axios({
      method: "get",
      url: api.invalidateUrl(url),
      responseType: "arraybuffer",
    }).then(function (response) {
      console.log("response", response);
      const disposition = response.headers["content-disposition"];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      var fileName = "";
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
        if (fileName) {
          fileName = decodeURIComponent(fileName);
        }
      }
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      //IE 경우
      const navigator = window.navigator as any;
      if (window.navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}`;
        link.click();
        setTimeout(() => link.remove(), 100);
      }
    });
  },

  downloadListExcel(
    searchFilter: string | null,
    searchWord?: string,
    startDate?: string,
    endDate?: string,
    comCode?: string,
    leaveCategory?: string,
    leaveCode?: number,
    leaveType?: string,
    leaveStatus?: string,
    workingStatus?: string
  ) {
    const url = `/ZLeave/Z_Leave/api/leave/download?searchFilter=${searchFilter}&searchWord=${searchWord}&startDate=${startDate}&endDate=${endDate}&leaveCode=${leaveCode}&comCode=${comCode}&leaveCategory=${leaveCategory}&leaveType=${leaveType}&leaveStatus=${leaveStatus}&workingStatus=${workingStatus}`;
    return axios({
      method: "get",
      url: api.invalidateUrl(url),
      responseType: "arraybuffer",
    }).then(function (response) {
      console.log("response", response);
      const disposition = response.headers["content-disposition"];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      var fileName = "";
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
        if (fileName) {
          fileName = decodeURIComponent(fileName);
        }
      }
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      //IE 경우
      const navigator = window.navigator as any;
      if (window.navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}`;
        link.click();
        setTimeout(() => link.remove(), 100);
      }
    });
  },

  uploadManageListExcel(fileform:FormData, successfn:Function, errorfn:Function, params?:any){
    const url = `/ZLeave/Z_Leave/api/leavemanage/upload`;
    console.log(fileform.get('file'))
    return axios({
      method: "post",
      url: api.invalidateUrl(url),
      data: fileform,
      //responseType: 'json'
    }).then(async res=>{
      await (window as any).alertAsync(res.data);
      console.log(res.data);
    }).catch(async res=>{
      await (window as any).alertAsync(res);
    });


    //   $.ajax({
    //     type: "POST",
    //     url: url,
    //     data: fileform,
    //     enctype: 'multipart/form-data',
    //     cache: false,
    //     processData: false,
    //     contentType: false,
    //     success: function (data) {
    //         successfn();
    //     },
    //     error: function (request, status, error) {
    //       errorfn("code:" + request.status + "\n" + "message:" + request.responseText + "\n" + "error:" + error);
    //         // alert("code:" + request.status + "\n" + "message:" + request.responseText + "\n" + "error:" + error);
    //     }
    // });
  },

  /**
   * 휴가신청양식 정보조회
   * @param data 
   * @returns 
   */
  getApplicationInfo(data: { comCode: string, applicationCode: string }): Promise<workflow.TA_APPLICATION>{
    return api.post<workflow.TA_APPLICATION>({
      url: '/ZinusWorkflow/ZINUS_Common/api/getapplicationbycode',
      data: data
    })
  }
};
