import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import WebpartBase from '@webparts/views/WebpartBase';
import * as dateutil from '@appbase/util/date-helper'
import * as typesAccount from '@account/store/types'
import api from '../../api/index'
import { mapGetters } from 'vuex';
import { i18n } from '@appbase/util';
import DocumentField from '../../components/DocumentList/DocumentField.vue'
@Component({
    components: {
        DocumentField,
    },
    computed: {
        ...mapGetters({
            current: typesAccount.CURRENT,
        }),
    },
})
export default class ApproveWebpart extends WebpartBase {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    tabs: Array<any> = [
        {
            tabTitle: this.$t('내가_기안한_문서함').toString(),
            boardId: "102"
        },
        {
            tabTitle: this.$t('W_Pending_box').toString(),
            boardId: "101"
        },
        {
            tabTitle: this.$t('W_Approval_box').toString(),
            boardId: "106"
        },
    ]
    activeIndex: number = 0;
    activeBoardId: number = 101;
    columns: Array<any> = [];
    rows: Array<any> = [];

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    current!: account.TCurrent


    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    TabClick(index: number, boardId: number) {
        this.activeBoardId = boardId;
        if (this.activeIndex != index) {
            this.activeIndex = index;
            this.getAPMList(boardId);
        }
    }

    TabMore(boardId: number) {
        if(!this.isPreview)
            window.location.href = 'https://v2.teamsplus.kr/workflow';
    }

    async getAPMList(id: number) {
        var nowDateValue = new Date();
        var last30DateValue = new Date(new Date().setDate(nowDateValue.getDate() - 30));
        var res = await api.webPart.getAPMList(id,
            this.current.user.personCode,
            dateutil.uGetDateFormat('yyyy-MM-DD', last30DateValue),
            dateutil.uGetDateFormat('yyyy-MM-DD', nowDateValue))
            .then((m: any) => m);

        var response = JSON.parse(res) as APMResponse;
        if (response.Table2.length > 0) {
            this.columns = [];
            this.columns?.push({
                ColumnKey: response.Table2[0].Column0,
                ColumnValue: response.Table1.find(x => x.ColumnKey == response.Table2[0].Column0)?.ColumnName!,
            });
            this.columns?.push({ ColumnKey: response.Table2[0].Column1, ColumnValue: response.Table1.find(x => x.ColumnKey == response.Table2[0].Column1)?.ColumnName! });
            this.columns?.push({ ColumnKey: response.Table2[0].Column2, ColumnValue: response.Table1.find(x => x.ColumnKey == response.Table2[0].Column2)?.ColumnName! });
            this.columns?.push({ ColumnKey: response.Table2[0].Column3, ColumnValue: response.Table1.find(x => x.ColumnKey == response.Table2[0].Column3)?.ColumnName! });
            this.columns?.push({ ColumnKey: response.Table2[0].Column4, ColumnValue: response.Table1.find(x => x.ColumnKey == response.Table2[0].Column4)?.ColumnName! });
            this.rows = [];
            if (this.testing) {
                this.rows = [
                    {
                        "ApmId": 716122,
                        "ApplicationId": 2,
                        "APMStatus": "M2",
                        "CurrentStep": 3,
                        "MaxStep": 5,
                        "DocNumber": "APP_001_716122",
                        "ApplicationName": "일반기안문",
                        "ApplicationNameEn": "Proposal",
                        "Subject": "대결재 에러 테스트 ㅠ 5",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-08-19T14:35:12.677",
                        "CreatedDate": "2021-08-19T05:34:26.78",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": null,
                        "CurrentApproverName": "총무팀(박한규)",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 716119,
                        "ApplicationId": 10294,
                        "APMStatus": "M2",
                        "CurrentStep": 2,
                        "MaxStep": 2,
                        "DocNumber": "APP_NO_716119",
                        "ApplicationName": "대내공문",
                        "ApplicationNameEn": "Official Notice",
                        "Subject": "대결재 에러 테스트 ㅠ 2",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-08-19T13:45:45.22",
                        "CreatedDate": "2021-08-19T04:45:20.693",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": null,
                        "CurrentApproverName": "유승화",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 716118,
                        "ApplicationId": 10294,
                        "APMStatus": "M2",
                        "CurrentStep": 1,
                        "MaxStep": 1,
                        "DocNumber": "APP_NO_716118",
                        "ApplicationName": "대내공문",
                        "ApplicationNameEn": "Official Notice",
                        "Subject": "대결재 에러 테스트 ㅠ",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-08-19T13:44:54.237",
                        "CreatedDate": "2021-08-19T04:44:29.113",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": "2021-08-19T13:44:54.237",
                        "CurrentApproverName": "김경석",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 716020,
                        "ApplicationId": 10037,
                        "APMStatus": "M2",
                        "CurrentStep": 1,
                        "MaxStep": 1,
                        "DocNumber": "REQAN_716020",
                        "ApplicationName": "명찰신청",
                        "ApplicationNameEn": "명찰신청",
                        "Subject": "명찰신청",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-08-05T09:48:56.67",
                        "CreatedDate": "2021-08-05T00:46:56.25",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": "2021-08-05T09:48:56.67",
                        "CurrentApproverName": "임광석",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 716019,
                        "ApplicationId": 2,
                        "APMStatus": "M2",
                        "CurrentStep": 1,
                        "MaxStep": 3,
                        "DocNumber": "APP_001_716019",
                        "ApplicationName": "일반기안문",
                        "ApplicationNameEn": "Proposal",
                        "Subject": "ㅎㅈㅎㅈㅎ222",
                        "ReqName": "조성현",
                        "ReqNameEn": "",
                        "DraftDate": "2021-08-04T17:13:28.483",
                        "CreatedDate": "2021-08-04T08:13:07.99",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": "2021-08-04T17:13:28.483",
                        "CurrentApproverName": "총무팀(박한규)",
                        "CurrentApproverNameEn": "",
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 716009,
                        "ApplicationId": 2,
                        "APMStatus": "M2",
                        "CurrentStep": 1,
                        "MaxStep": 3,
                        "DocNumber": "APP_001_716009",
                        "ApplicationName": "일반기안문",
                        "ApplicationNameEn": "Proposal",
                        "Subject": "테스트 3 입니다",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-08-04T17:00:23.787",
                        "CreatedDate": "2021-08-04T08:00:06.55",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": "2021-08-04T17:00:23.787",
                        "CurrentApproverName": "총무팀(박한규)",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 716005,
                        "ApplicationId": 2,
                        "APMStatus": "M2",
                        "CurrentStep": 1,
                        "MaxStep": 3,
                        "DocNumber": "APP_001_716005",
                        "ApplicationName": "일반기안문",
                        "ApplicationNameEn": "Proposal",
                        "Subject": "테스트 333",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-08-04T15:58:06.023",
                        "CreatedDate": "2021-08-04T06:57:16.56",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": "2021-08-04T15:58:06.023",
                        "CurrentApproverName": "총무팀(박한규)",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 716003,
                        "ApplicationId": 10294,
                        "APMStatus": "M2",
                        "CurrentStep": 1,
                        "MaxStep": 1,
                        "DocNumber": "APP_NO_716003",
                        "ApplicationName": "대내공문",
                        "ApplicationNameEn": "Official Notice",
                        "Subject": "회람 변경 테스트용",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-08-04T10:46:41.29",
                        "CreatedDate": "2021-08-04T01:46:13.113",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": "2021-08-04T10:46:41.29",
                        "CurrentApproverName": "송하빈",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 715795,
                        "ApplicationId": 10294,
                        "APMStatus": "M2",
                        "CurrentStep": 1,
                        "MaxStep": 2,
                        "DocNumber": "APP_NO_715795",
                        "ApplicationName": "대내공문",
                        "ApplicationNameEn": "Official Notice",
                        "Subject": "테스트",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-07-28T08:10:12.257",
                        "CreatedDate": "2021-07-27T23:09:19.54",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": "2021-07-28T08:10:12.257",
                        "CurrentApproverName": "이광민",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 715663,
                        "ApplicationId": 10294,
                        "APMStatus": "M2",
                        "CurrentStep": 2,
                        "MaxStep": 2,
                        "DocNumber": "APP_NO_715663",
                        "ApplicationName": "대내공문",
                        "ApplicationNameEn": "Official Notice",
                        "Subject": "(테스트) 미결건 테스트용2",
                        "ReqName": "포탈테스트01",
                        "ReqNameEn": null,
                        "DraftDate": "2021-07-27T20:37:33.117",
                        "CreatedDate": "2021-07-27T11:37:00.55",
                        "LastApprovedDate": null,
                        "ReqDeptName": "삼호전산",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": null,
                        "CurrentApproverName": "이광민",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 715660,
                        "ApplicationId": 10294,
                        "APMStatus": "M2",
                        "CurrentStep": 1,
                        "MaxStep": 1,
                        "DocNumber": "APP_NO_715660",
                        "ApplicationName": "대내공문",
                        "ApplicationNameEn": "Official Notice",
                        "Subject": "(테스트) 메일알림 테스트용3",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-07-27T20:27:31.323",
                        "CreatedDate": "2021-07-27T11:27:02.14",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": "2021-07-27T20:27:31.323",
                        "CurrentApproverName": "이광민",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 715654,
                        "ApplicationId": 10294,
                        "APMStatus": "M2",
                        "CurrentStep": 1,
                        "MaxStep": 1,
                        "DocNumber": "APP_NO_715654",
                        "ApplicationName": "대내공문",
                        "ApplicationNameEn": "Official Notice",
                        "Subject": "(테스트) 메일알림 테스트용2",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-07-27T20:22:28.613",
                        "CreatedDate": "2021-07-27T11:21:48.163",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": "2021-07-27T20:22:28.613",
                        "CurrentApproverName": "이광민",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 705426,
                        "ApplicationId": 10294,
                        "APMStatus": "M2",
                        "CurrentStep": 1,
                        "MaxStep": 4,
                        "DocNumber": "APP_NO_705426",
                        "ApplicationName": "대내공문",
                        "ApplicationNameEn": "Official Notice",
                        "Subject": "테스트 공문",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-07-23T10:07:54.433",
                        "CreatedDate": "2021-07-23T01:06:35.687",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": "2021-07-23T10:07:54.433",
                        "CurrentApproverName": "이광민",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 317164,
                        "ApplicationId": 2,
                        "APMStatus": "M2",
                        "CurrentStep": 1,
                        "MaxStep": 6,
                        "DocNumber": "APP_001_317164",
                        "ApplicationName": "일반기안문",
                        "ApplicationNameEn": "Proposal",
                        "Subject": "sdfasdfas",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-07-18T15:47:33.89",
                        "CreatedDate": "2021-07-18T06:47:06.84",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": "2021-07-18T15:47:33.89",
                        "CurrentApproverName": "김정균",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 317162,
                        "ApplicationId": 2,
                        "APMStatus": "M2",
                        "CurrentStep": 3,
                        "MaxStep": 3,
                        "DocNumber": "APP_001_317162",
                        "ApplicationName": "일반기안문",
                        "ApplicationNameEn": "Proposal",
                        "Subject": "수신 테스트용2",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-07-16T20:06:43.133",
                        "CreatedDate": "2021-07-16T11:06:04.053",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": null,
                        "CurrentApproverName": "사업기획부",
                        "CurrentApproverNameEn": "",
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 275,
                        "ApplicationId": 2,
                        "APMStatus": "M2",
                        "CurrentStep": 1,
                        "MaxStep": 3,
                        "DocNumber": "APP_001_275",
                        "ApplicationName": "일반기안문",
                        "ApplicationNameEn": "Proposal",
                        "Subject": "결재정보 테스트용2",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-07-06T14:06:21.107",
                        "CreatedDate": "2021-07-06T05:05:49.853",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": "2021-07-06T14:06:21.107",
                        "CurrentApproverName": "총무팀(박한규)",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 274,
                        "ApplicationId": 2,
                        "APMStatus": "M2",
                        "CurrentStep": 2,
                        "MaxStep": 3,
                        "DocNumber": "APP_001_274",
                        "ApplicationName": "일반기안문",
                        "ApplicationNameEn": "Proposal",
                        "Subject": "xcvzx",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-07-06T13:35:22.223",
                        "CreatedDate": "2021-07-06T04:35:00.33",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": null,
                        "CurrentApproverName": "김재창",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 269,
                        "ApplicationId": 2,
                        "APMStatus": "M2",
                        "CurrentStep": 2,
                        "MaxStep": 2,
                        "DocNumber": "APP_001_269",
                        "ApplicationName": "일반기안문",
                        "ApplicationNameEn": "Proposal",
                        "Subject": "ㅁㅁㅁㄴㅇㄻ",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-07-06T13:09:19.757",
                        "CreatedDate": "2021-07-06T04:08:42.697",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": null,
                        "CurrentApproverName": "원가관리팀(오정석)",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 268,
                        "ApplicationId": 2,
                        "APMStatus": "M2",
                        "CurrentStep": 1,
                        "MaxStep": 2,
                        "DocNumber": "APP_001_268",
                        "ApplicationName": "일반기안문",
                        "ApplicationNameEn": "Proposal",
                        "Subject": "ㅁㅁㅁㄴㅇㄻ",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-07-06T13:08:56.89",
                        "CreatedDate": "2021-07-06T04:08:42.697",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": "2021-07-06T13:08:56.89",
                        "CurrentApproverName": "총무팀(박한규)",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    },
                    {
                        "ApmId": 266,
                        "ApplicationId": 2,
                        "APMStatus": "M2",
                        "CurrentStep": 4,
                        "MaxStep": 10,
                        "DocNumber": "APP_001_266",
                        "ApplicationName": "일반기안문",
                        "ApplicationNameEn": "Proposal",
                        "Subject": "AAAAㄴㄹㄴㄹ",
                        "ReqName": "조성현",
                        "ReqNameEn": null,
                        "DraftDate": "2021-07-06T12:43:57.367",
                        "CreatedDate": "2021-07-06T03:42:38.32",
                        "LastApprovedDate": null,
                        "ReqDeptName": "정보기술부",
                        "CurrentApprovalStatus": "A2",
                        "CurrentApprovedDate": null,
                        "CurrentApproverName": "총무팀(박한규)",
                        "CurrentApproverNameEn": null,
                        "IsAttach": null,
                        "CurrentPage": 1,
                        "TotalCount": 41
                    }
                ]
            }
            else {
                this.rows = response.Table3;
            }
        }



    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E S T Y L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //
    mounted() {
        this.getAPMList(this.activeBoardId);
    }


}

class APMResponse {
    Table1!: Array<ColumnDefine>
    Table2!: Array<ColumnHeader>
    Table3!: Array<any>
}

class ColumnDefine {
    ColumnKey!: string
    ColumnName!: string
    ResourceId!: string
    Style?: string | null
}

class ColumnHeader {
    Column0?: string
    Column1?: string
    Column2?: string
    Column3?: string
    Column4?: string
}
