import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
 
import GnbTop from '@gnb/containers/GlobalNavigationBarLeft/GnbTop.vue'

@Component({
    components: {
 
        GnbTop
    }
})
export default class GnbLeftView extends Vue {

}