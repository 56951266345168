import api from '@/api'

interface ResponseWrapper<TResult> {
    result: TResult
    code: string
    message: string
    error: string | null
}

export default {
    getMyCalendarFolderId(): Promise<string> {
        return api.get<string>({
            url: '/api/Reservation/myCalendarFolder'
        })
    },

    getEwsResources(): Promise<ewsResource.EwsResourceItem[]> {
        return api.get<ewsResource.EwsResourceItem[]>({
            url: '/api/Reservation/resources'
        })
    },

    getAvailableRooms(param: ewsResource.AvailableResourceRequest): Promise<ewsResource.EwsResourceItem[]> {
        return api.post<ewsResource.EwsResourceItem[]>({
            url: '/api/Reservation/availableResources',
            data: param
        })
    },

    getResourceAvailable(param: ewsResource.AvailableResourceRequest): Promise<ewsResource.ResourceAvailibility> {
        return api.post<ewsResource.ResourceAvailibility>({
            url: '/api/Reservation/resourceAvailable',
            data: param
        })
    },

    /**
     * 폴더의 일정들을 가져옵니다 (반복일정 포함)
     * @param {string} parentId root folder ID
     * @param {string} startdate 시작일
     * @param {string} enddate 종료일
     */
     getCalendarEventListWithRecurrence(item: reserve.SearchOptions, resourceUpn: string) {
        return api.post<Array<any>>({
            url: `/api/reservation/resourceEvents?resource=${encodeURIComponent(resourceUpn)}`,
            data: {
                EwsFolderIDArray: item.EwsFolderIDArray || null,
                EwsFolderID: item.ParentFolderID || null, 
                StartDate: item.StartDate || null, 
                EndDate: item.EndDate || null, 
                Status: item.Status || null,
                Subject: item.Subject || null,
                Location: item.Location || null, 
                OrganizerName: item.OrganizerName || null,
                OrganizerAddress: item.OrganizerAddress || null, 
                PersonCode: item.PersonCode, 
                RequiredAttendees: null, 
                OptionalAttendees: null, 
                EumNoCondition: false, 
                EumPageNumber: item.EumPageNumber || 1, 
                EumRowsPerPage: item.EumRowsPerPage || 9999, 
            }
        }).then((res) => {
            return res.map((data) => {
                var subject = data.subject as string;
                var organizerName = data.organizer.name as string;
                var findIdx = subject.indexOf(organizerName);

                if(findIdx == 0) {
                    subject = subject.replace(organizerName, '');
                }

                let result = {
                    ...data,
                    subject: subject,
                    isEWS: true,
                };
                return result;
            });
        });;
    },

    searchCalendarEventList(item: reserve.SearchOptions, resourceUpn: string) {
        return api.post<Array<any>>({
            url: `/api/reservation/searchEvents?resource=${encodeURIComponent(resourceUpn)}`,
            data: {
                EwsFolderIDArray: item.EwsFolderIDArray || null,
                EwsFolderID: item.ParentFolderID || null, 
                StartDate: item.StartDate || null, 
                EndDate: item.EndDate || null,
                Keyword: item.Keyword || null, 
                Status: item.Status || null,
                Subject: item.Subject || null,
                Location: item.Location || null, 
                OrganizerName: item.OrganizerName || null,
                OrganizerAddress: item.OrganizerAddress || null, 
                PersonCode: item.PersonCode, 
                RequiredAttendees: null, 
                OptionalAttendees: null, 
                EumNoCondition: false, 
                EumPageNumber: item.EumPageNumber || 1, 
                EumRowsPerPage: item.EumRowsPerPage || 9999, 
            }
        }).then((res) => {
            return res.map((data) => {
                var subject = data.subject as string;
                var organizerName = data.organizer.name as string;
                var findIdx = subject.indexOf(organizerName);

                if(findIdx == 0) {
                    subject = subject.replace(organizerName, '');
                }

                let result = {
                    ...data,
                    subject: subject,
                    isEWS: true,
                };
                return result;
            });
        });
    },

    getResourceInfo: (resourceId: string): Promise<reserve.ResourceInfo> =>{
        return api.get({
            url: `/ZReserve/Z_Reserve/api/resource/info?guid=${resourceId}`,
        });
    },
    
    getResourceBookmarkList:(personCode: string, comCode: string): Promise<reserve.ResourceBookmark[]> =>{
        return api.get({
            url: `/api/Reservation/getBookmarkList?personCode=${personCode}&comCode=${comCode}`
        });
    },

    insertResourceBookmark:(ewsGuid: string, personCode: string, comCode: string, personName: string) =>{
        return api.get({
            url: `/api/Reservation/insertBookmark?EwsGuid=${ewsGuid}&PersonCode=${personCode}&ComCode=${comCode}&CreatorName=${personName}`
        });
    },

    deleteResourceBookmark:(bookmarkId: number, personCode: string, comCode: string) =>{
        return api.get({
            url: `/api/Reservation/deleteBookmark?BookmarkId=${bookmarkId}&PersonCode=${personCode}&ComCode=${comCode}`
        });
    },

    checkResourceBookmark:(personCode: string, comCode: string, ewsGuid: string): Promise<reserve.ResourceBookmark> => {
        return api.get({
            url: `/api/Reservation/checkBookmark?PersonCode=${personCode}&ComCode=${comCode}&EwsGuid=${ewsGuid}`
        });
    },
}