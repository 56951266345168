import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import WebpartBase from '@webparts/views/WebpartBase'
import { InboxArticle } from '../../components/index'
import api from '../../api/index'
import { debounce } from '@/modules/@appbase/util/debounce'
import { LoadingBar } from '@appbase/components/loading'
import { NoData } from '../../components/common'
import menuHelper from '@appbase/util/menu-helper'

@Component({
  components: {
    InboxArticle,
    LoadingBar,
    NoData,
  },
})
export default class InboxWebpart extends WebpartBase {
  constructor() {
    super()
    this.loadEmailList1 = debounce(this.loadEmailList1, 300)
  }

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  listArticles?: any = []
  isUnreadOnly: boolean = true
  loading: boolean = false
  text: string = this.$t('읽지_않은_우편이_없습니다').toString()

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  handleTitleClicked() {
    if (this.isPreview) return // window.location.href = 'https://v2.teamsplus.kr/mail/mail/master'
    const menu = menuHelper.findOne(4)
    if (menu) {
      menuHelper.next(menu)
    }
  }

  handleRefresh() {
    this.loadEmailList()
  }

  onIsUnreadOnlyChange(e: any) {
    this.loadEmailList()
  }

  TitleClick() {
    this.loadEmailList()
  }

  loadEmailList() {
    try {
      this.$nextTick(() => (this.loading = true))
      this.$nextTick(() => this.loadEmailList1())
    } catch (err) {
      console.error(err);
    }
  }

  loadEmailList1() {
    const filters: awsRequest.filters[] = []
    var request: awsRequest.request = {
      fields: [
        { key: 'Id' },
        { key: 'Subject' },
        { key: 'DateTimeCreated', format: 'yyyy-MM-dd hh:mm:ss' },
        { key: 'From', format: 'Name' },
        { key: 'From', format: '{Address}', as: 'Address' },
        { key: 'IsRead' },
      ],
      wellKnownFolderName: 'inbox',
      pageSize: this.option.displayCount!,
      offset: 0,
      sorts: [{ field: 'DateTimeCreated', dir: 'desc' }],
      type: 'EmailMessage',
      filters: [...filters],
    }
    if (this.isUnreadOnly) {
      request.filters = [
        {
          key: 'IsRead',
          value: 'false',
          condition: 'IsEqualTo',
        },
      ]
    }

    api.webPart
      .getEWSData<any>(request)
      .then((res: any) => {
        this.listArticles = res.items || []
      })
      .then(() => {
        this.firstRender = this.loading = false
      })
      .catch(() => {
        this.listArticles = []
      })
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E S T Y L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //
  mounted() {
    this.listArticles = this.getLoadingData(2)
    this.loadEmailList()
  }
}
