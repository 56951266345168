import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { PublicAddressFolderListContainer } from '@orgmap/containers';
import PublicMembersView from './PublicMembersView.vue';


@Component({
    components: { PublicAddressFolderListContainer, PublicMembersView }
})
export default class PublicAddressBookView extends Vue {

    @Prop({ type: Boolean, default: false }) addableDept!: boolean;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    dept: orgmap.TDepartmentTreeItem | null = null;
    

    select(item: orgmap.TDepartmentTreeItem | null){
        this.dept = item;
    }
}
