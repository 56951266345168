import Vue from "vue";
import store from "@/store";
import * as types from "./store/types";
import * as accountTypes from "@account/store/types";
//모달추가
import { IFRAME_MODAL, ROLE_GROUP_MODAL } from "./components/modal/modal-names";

import modalHelper from "./util/modal-helper";
import EumModal from "./components/modal/EumModal.vue";
import RoleGroupModal from "./components/modal/RoleGroupModal.vue";
import IframeModalV3 from "./components/modal/IframeModalV3.vue";

import { i18n } from '@appbase/util'
import axios from "axios";

export const initializeAsync = () => {
  return new Promise<void>((resolve, reject) => {
    try {
      //모달추가
      Vue.component("eum-modal", EumModal);
      Vue.component(ROLE_GROUP_MODAL, RoleGroupModal);
      Vue.component(IFRAME_MODAL, IframeModalV3);

      // [2023-12-15 김지훈] alertAsync, confirmAsync
      (function (window, document) {
        var _alertBase = window.alert;
        var _confirmBase = window.confirm;

        var _CONST_MODAL_CLASS = "_eum_runtime_modal_";
        var _CONST_MODAL_MSG_CLASS = "_eum_runtime_msg_";
        var _CONST_BUTTON_OK_CLASS = "_eum_runtime_btn_ok_";
        var _CONST_BUTTON_CANCEL_CLASS = "_eum_runtime_btn_cancel_";
        var _CONST_CONFIRM_AREA_CLASS = "_eum_runtime_confirm_area_";

        var modalHtml = '';
          var axiosClient = axios.create();
          axiosClient.request({
            url: '/cm/template/common/AlertConfirm.html?v=' + Date.now(),
            method: 'get',
            responseType: 'text',
          }).then((res: any) => {
            modalHtml = res?.data;

            var _baseFunc = (type: 'alert' | 'confirm', msg: string, callback: any) => {
              // var elems = document.querySelectorAll('._eum_runtime_modal_');
              // if (elems) {
              //   elems.forEach(function (elem, idx) {
              //     elem.remove();
              //   });
              // }
    
              var parser = new DOMParser();
              var parsedHtml = parser.parseFromString(modalHtml, 'text/html');
              var elem = parsedHtml.body.firstChild;
    
              if (elem) {

                if (type == 'confirm') {
                  (elem as any).querySelectorAll(`.${_CONST_CONFIRM_AREA_CLASS}`).forEach((el: any) => {
                    el.style.display = 'inline-block';
                  });
                }

                var elemMsg = (elem as any).querySelector(`.${_CONST_MODAL_MSG_CLASS}`);
                if (elemMsg) {
                  elemMsg.textContent = msg;
                }
    
                var elemOk = (elem as any).querySelector(`.${_CONST_BUTTON_OK_CLASS}`);
                if (elemOk) {
                  elemOk.textContent = i18n.t("확인").toString();
                  elemOk.onclick = function (e: any) {
                    var elem = e.target || e.srcElement;
                    var parent = elem.closest(`.${_CONST_MODAL_CLASS}`);
                    if (parent) {
                      parent.remove();
                    }
    
                    if (callback) {
                      callback(true);
                    }
                  }
                }
    
                var elemClose = (elem as any).querySelector(`.${_CONST_BUTTON_CANCEL_CLASS}`);
                if (elemClose) {
                  elemClose.textContent = i18n.t("취소").toString();
                  elemClose.onclick = function (e: any) {
                    var elem = e.target || e.srcElement;
                    var parent = elem.closest(`.${_CONST_MODAL_CLASS}`);
                    if (parent) {
                      parent.remove();
                    }
    
                    if (callback) {
                      callback(false);
                    }
                  }
                }
    
                document.body.append(elem);
              }
    
              return false;
            }

            (window as any).alertAsync = (msg: string) => {
              return new Promise(resolve => {
                _baseFunc('alert', msg, resolve);
              })
            }

            (window as any).confirmAsync = (msg: string) => {
              return new Promise(resolve => {
                _baseFunc('confirm', msg, resolve);
              })
            }
          });        
      })(window, document);

      resolve();
    } catch (err) {
      reject(err);
    }
  });
};

Vue.prototype.setPage = (page: appbase.TPageInfo) => {
  store.commit(types.PAGE_CURRENT, page);
};

//모달추가
Vue.prototype.closeCurrentModal = function () {
  this.$emit("close-current-eum-modal", this);
};

Vue.prototype.getAppConfig = (key: string): string | undefined | null => {
  const current = store.getters[accountTypes.CURRENT] as account.TCurrent;
  const config = current.appConfig.find((x) => x.key === key)?.value as
    | string
    | undefined
    | null;
  return config;
};
Object.defineProperty(Vue.prototype, "isTeams", {
  get: function isTeams() {
    return store.getters[types.TEAMS] as boolean;
  },
  set: function isTeams(value: boolean) {
    store.dispatch(types.TEAMS, value);
  },
});
