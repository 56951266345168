import api from '@/api'

export default {
    getOrgPersonSearch: (searchText: string, comCode: string): Promise<gnb.OrgPersonSearch[]> => {
        return api.get({
            url: '/Common/gnb/api/gnbsearch',
            params:{
                searchText: searchText,
                comCode: comCode
            }
        });
    },
    getWorkFeedList:(personCode: string, comCode: string): Promise<gnb.WorkFeedItem[]> => {
        return api.get({
            url: '/Common/gnb/api/workfeed/list',
            params:{
                personCode: personCode,
                comCode: comCode,
                readFlag: 'N'
            }
        });
    },
    getWorkFeedUnreadCount:(personCode: string, comCode: string): any => {
        return api.get({
            url: '/Common/gnb/api/workfeed/workfeedcount',
            params: {
                personCode: personCode,
                comCode: comCode
            }
        }).then((res: any) => {
            return res;
        });
    },
    setWorkFeedReadStatus:(alarmId: number, isRead: boolean): any => {
        return api.get({
            url: '/Common/gnb/api/workfeed/updateread',
            params:{
                AlarmId: alarmId,
                IsRead: isRead
            }
        }).then((res: any) => {
            return res;
        });
    },
    setWorkFeedAllReadStatus:(personCode: string, comCode: string): any => {
        return api.get({
            url: '/Common/gnb/api/workfeed/updateallread',
            params:{
                PersonCode: personCode,
                ComCode: comCode
            }
        }).then((res: any) => {
            return res;
        });
    },
    getBookMarkList:(personCode: string, comCode: string): any => {
        return api.get({
            url: '/Common/gnb/api/bookmark/list',
            params: {
                PersonCode: personCode,
                ComCode: comCode
            }
        });
    },
    deleteBookMark:(bookMarkId: number, personCode: string, comCode: string): any => {
        return api.get({
            url: '/Common/gnb/api/bookmark/delete',
            params: {
                BookMarkId: bookMarkId,
                PersonCode: personCode,
                ComCode: comCode
            }
        }).then((res: any) => {
            return res;
        });
    },
    getUserQuickMenuList:(personCode: string, comCode: string, searchType: string): any => {
        return api.get({
            url: '/Common/gnb/api/quickmenu/list',
            params: {
                PersonCode: personCode,
                ComCode: comCode,
                SearchType: searchType
            }
        })
    },
    insertQuickMenuUserSet:(personCode: string, comCode: string, quickMenuIds: string): any => {
        return api.get({
          url: '/Common/gnb/api/quickmenu/userset',
          params: {
              PersonCode: personCode, 
              ComCode: comCode,
              QuickMenuIds: quickMenuIds
          }  
        })
    },
    getTotalArticles(searchSet: gnbarticle.TotalArticleSearchSet): Promise<gnbarticle.PagedData<gnbarticle.Article>> {
        return api.post<gnbarticle.PagedData<gnbarticle.Article>>({
            url: `/api/BoardApi/Article/getTotalArticles`,
            data: searchSet
        });
    },
    searchOrg: function(
        options: gnborgmap.SearchOptions,
        comCode: string
      ): Promise<gnborgmap.PagedItemList<gnborgmap.UserItem>> {
        let { query } = options
        if (query) {
          query = encodeURIComponent(query)
        }
        var data = {
          ...options,
          comCode: comCode
        }
        return api.post({
          url: '/api/hr/department/search?searchVal=' + query,
          data: data,
        })
    },
}