import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import WebpartBase from '@webparts/views/WebpartBase'
import * as dateutil from '@appbase/util/date-helper'
import * as typesAccount from '@account/store/types'
import api from '../../api/index'
import { mapGetters } from 'vuex'
import menuHelper from '@appbase/util/menu-helper'

const unreadMailRequest = {
  fields: [{ key: 'Subject' }, { key: 'DateTimeCreated', format: 'yyyy-MM-dd' }],
  filters: [
    {
      key: 'IsRead',
      value: 'false',
      condition: 'isEqualTo',
    },
  ],
  pageSize: 0,
  offset: 0,
  type: 'EmailMessage',
}

@Component({
  components: {},
  computed: {
    ...mapGetters({
      current: typesAccount.CURRENT,
    }),
  },
})
export default class ApproveMailWebpart extends WebpartBase {
  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //
  unreadMailCNT: number = 0
  myAPMCNT: number = 0

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  current!: account.TCurrent

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //
  get user() {
    return this.current.user
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  async load() {
    const [res1, res2] = await Promise.all([
      api.webPart.getEWSData<any>(unreadMailRequest),
      api.webPart.getMyAPM(this.user.personCode, this.user.deptCode),
    ])

    // 읽지 않은 메일
    if (res1 && res1.items) {
      this.unreadMailCNT = res1.items.length
    }

    // 결재
    this.myAPMCNT = res2.reduce((prev: any, next: any) => {
      return prev + next.count
    }, 0)

    setTimeout(() => (this.firstRender = false), 200)
  }

  moreMail() {
    if (this.isPreview) return
    const menu = menuHelper.findOne(4)
    if (menu) {
      menuHelper.next(menu)
    }
  }

  moreApproval() {
    if (this.isPreview) return
    const menu = menuHelper.findOne(9)
    if (menu) {
      menuHelper.next(menu)
    }
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E S T Y L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //
  mounted() {
    this.load()
  }
}
