import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class SiteMap extends Vue {
    @Prop({ type: Array }) siteMapItems!: appbase.cache.TMenuItem[]

    fnExpandSubMenu(menuId: number){
        let divSubObj = document.getElementById("divSiteMapSub_" + menuId);
        if (divSubObj !== undefined && divSubObj !== null){
            if (divSubObj.style.display == "none"){
                divSubObj.style.display = "";
            }
            else{
                divSubObj.style.display = "none";
            }
        }
    }
}