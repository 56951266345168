import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
    name: 'leftmenu-item'
})
export default class LeftMenuItem extends Vue {
    @Prop({type:Object}) item!: appbase.cache.TMenuItem;
    @Prop({type:Array}) menuPermission!: account.TPermissionMenu[];
    @Prop({type:Array}) menuItemPath!: number[];
    @Prop({type:String}) userLang!: string;

    expandCustomFlag: boolean = false;

    get selected() {
        var menuId = Number(this.$route.query.MENUID)
        return menuId === this.item.menuId
    }

    get expandflag(){
        return this.menuItemPath.includes(this.item.menuId)
    }


    get isPermission(){
        let isPermission: boolean = false;
        if (this.menuPermission && this.menuPermission.find(x => x.id == this.item.menuId))        
            isPermission = true;
        return isPermission;
    }

    get customClass(){
        if (this.item.customClass){
            return this.item.customClass;
        }
        return ""; 
    }

    mounted(){
        var menuId = Number(this.$route.query.MENUID)
        if (menuId == this.item.menuId){
            // 현재 메뉴 위치로 Scroll을 이동한다.
            this.$el.scrollIntoView()
        }
    }
}