import api from '@/api'
import axios from 'axios'

export default {
    
    getSurveyLists(params :survey.SurveyListRequest) : Promise<survey.SurveyListResponse> {
        
        
        return api.get({
            url: `/commonsurvey/survey/api/Lists?SurveyStatus=${params.surveyStatus}&Filter=${params.filter}&RowCount=${params.rowCount}&Keyword=${params.keyword}&PageNumber=${params.pageNumber}&isComposing=${params.isComposing}&MenuId=${params.menuId}` 
        })
    },

    deleteSurvey(surveyId :number) : Promise<boolean> {
        return api.delete({
            url: `/commonsurvey/survey/api/${surveyId}`
        })
    },

    /* 
    create & update
    surveyId 유무로 create & update 판단
    */
    insertSurveyMainInfo(params:survey.SurveyRequest) : Promise<survey.SurveyResponse>{
        return api.post({
            url: `/commonsurvey/survey/api`,
            data : params
        })
    },

    getSurveyById(surveyId :number) : Promise<survey.SurveyRequest> {
        return api.get({
            url: `/commonsurvey/survey/api/main/${surveyId}`
        })
    },

    deleteHardSurvey(surveyId :number) : Promise<boolean> {
        return api.delete({
            url: `/commonsurvey/survey/api/hard/${surveyId}`
        })
    },

    insertSurveyQuestion(params:survey.SurveyQuestionRequest) : Promise<survey.SurveyQuestionResponse>{
          
        return api.post({
            url: `/commonsurvey/Question/api/`,
            data : params
        })
    },

    surveyDetail(surveyId: number) : Promise<survey.SurveyDetailResponse>{
        return api.get({
            url: `/commonsurvey/survey/api/${surveyId}`
        }) 
    },

    deleteSurveyQuestion(surveyQuestionId : number): Promise<boolean>{
        return api.delete({
            url: `/commonsurvey/Question/api/${surveyQuestionId}`
        })
    },

 
    insertSurveyExamlple(params : survey.SurveyExampleRequest ): Promise<survey.SurveyExampleResponse>{
        return api.post({
            url: `/commonsurvey/Example/api/`,
            data : params
        })
    },

    deleteSurveyExample(surveyExampleId : number): Promise<boolean>{
        return api.delete({
            url: `/commonsurvey/Example/api/${surveyExampleId}`
        })
    },

    setSurveyNowPosting(params: number):Promise<boolean>{
        return api.post({
            url: `/commonsurvey/survey/api/NowPosting`,
            data : {surveyId : params}
        })
    },

    setSurveyNowClosing(params: number):Promise<boolean>{
        return api.post({
            url: `/commonsurvey/survey/api/NowClosing`,
            data : {surveyId : params}
        })
    },

    setSurveyComposingStatus(params:survey.SurveySetComposingRequest):Promise<boolean>{
        return api.post({
            url: `/commonsurvey/survey/api/Composing`,
            data : params
        })
    },


    insertSurveyAnswer(params:survey.SurveyAnswerRequest):Promise<survey.SurveyAnswerResponse>{
        return api.post({
            url: `/commonsurvey/Answer/api/`,
            data : params
        })
    },

    getSurveyAnswerLists(params:number, personCode?: string) : Promise<Array<survey.SurveyAnswerListResponse>> {
        return api.get({
            url: `/commonsurvey/Answer/api/lists/${params}` + `${personCode ? `?personCode=${personCode}`:''}`
        })
    },

    getSurveyCompletionLists(params:number): Promise<survey.SurveyCompletionListResponse> {
        return api.get({
            url: `/commonsurvey/Survey/api/Completion/lists/${params}` 
        })
    },

  
    getSurveyStatisticsLists(params:number) : Promise<survey.SurveyStatisticsListResponse> {
        return api.get({
            url: `/commonsurvey/Answer/api/Statistics/lists/${params}`
        })
    },

    getSurveyStatisticsDetailLists(params:number) : Promise<survey.SurveyStatisticsListDetailResponse> {
        return api.get({
            url: `/commonsurvey/Answer/api/StatisticsDetail/lists/${params}`
        })
    },

    getParticipantCount(params: number): Promise<number> {
        return api.get({
            url: `/commonsurvey/Answer/api/Participant/${params}`
        })
    },

    getSurveyAttachmentList(fileCode : string): Promise<any>{
        return api.get({
            url: `/file/store/${fileCode}`
        })
    },

    uploadSurveyExampleFile(params:survey.UploadFileRequest) : Promise<any>{
     
       let formData = new FormData()
       formData.append("code",params.Code)
       formData.append("CodeValues", params.CodeValues)
       formData.append("file", params.File)
       formData.append("flowIdentifier", params.FlowIdentifier)
       formData.append("flowFilename", params.FlowFilename)
       formData.append("flowRelativePath", params.FlowRelativePath)
    
        return api.post({
            url: `/file/store/flow`,
            data : formData ,
            //form-data 헤더추가
            headers : { 'Content-Type': 'multipart/form-data'},
        }) 
    },


    downloadSurveyStatisticsExcelData(params:number) : void {
        const url = `${process.env.NODE_ENV === 'production' ? '/cm' : 'http://localhost:5000' }/commonsurvey/Answer/api/Statistics/exportToExcel/${params}`
        //const url = `http://localhost:5000/commonsurvey/Answer/api/Statistics/exportToExcel/${params}`
        window.open(url)
    },

}