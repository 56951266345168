import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({

})
export default class TagField  extends Vue {
    @Prop({ type: String }) tagString!: string;

    mTags: string[] = [];

    @Watch('tagString')
    onTagStringChanged(newVal: string) {
        this.mTags = newVal.split(',').filter(t => t != null && t.trim().length > 0);
    }

    created() {
        if(this.tagString != null) {
            this.onTagStringChanged(this.tagString);
        }
    }
}