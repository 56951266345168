import { Component } from "vue";
import { dispatchEventBus } from "./eventBus";
import { invalidateUrl } from "./invalidate-url";
import modalHelper from "./modal-helper";
import { getUrlParamToDictionary } from "./url-helper";

export type PopupOptions = {
  scrollbars?: "yes" | "no";
  toolbar?: number;
  directories?: number;
  menubar?: number;
  resizable?: number;
  status?: number | string;
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  noinvalidateUrl?: boolean;
  help?: number | string;
  scroll?: number | string;
  //
  // modal perperties
  //
  modal?: boolean;
  dialogWidth?: number | string;
  dialogHeight?: number | string;
  componet?: Component;
  componetValue?: any;
  isFrame?: boolean;
};

export const openPopup = (
  id: string,
  url: string,
  data: { [key: string]: any },
  options: PopupOptions,
  closed?: () => void,
  eventBus?: { key: string; value: any }
) => {
  if (url == "/orgmap") {
    if (data && data.option && data.option.containers) {
      var copyContainers = JSON.stringify(data.option.containers);

      (window as any).getContainersItems = function () {
        return copyContainers;
      };

      for (var i = 0; i < data.option.containers.length; i++) {
        if (data.option.containers[i].items) {
          data.option.containers[i].items = undefined;
        }
      }
    }
  }

  url = combindWithQuery(url, data);
  const style = buildStyle(options);
  const newUrl = options.noinvalidateUrl === true ? url : invalidateUrl(url);
  console.log("openPopup", newUrl, style);
  var newWindow: any;

  if (options?.modal === true && (window as any).showModalDialog) {
    newWindow = (window as any).showModalDialog(newUrl, `${id}`, style);
  } else if (options.isFrame) {
    // TODO
    //newUrl = AddFrameParam(newUrl);
    newWindow = (window as any).showiFrameWindow(
      newUrl,
      `popup_${id}`,
      options
    );

    var createTimeout = function () {
      return setTimeout(() => {
        if ((window as any).iframeClosed) {
          clearTimeout(timeout);
          if (closed) {
            closed();
          }
        } else {
          timeout = createTimeout();
        }
      }, 10);
    };
    var timeout = createTimeout();
  } else if (options.componet) {
    modalHelper.show({
      component: options.componet,
      force: true,
      value: {
        ...getUrlParamToDictionary(url),
        ...options.componetValue,
        width: options.width,
        height: options.height,
      },
    });
  } else {
    newWindow = window.open(newUrl, `${id}`, style);
    newWindow?.focus();
  }

  // if (callback) {

  if (newWindow) {
    var createTimeout = function () {
      return setTimeout(() => {
        if (newWindow?.closed || newWindow?.iframeClosed) {
          clearTimeout(timeout);
          if (closed) {
            closed();
          }
        } else {
          timeout = createTimeout();
        }
      }, 10);
    };
    var timeout = createTimeout();

    var setEventBus = function () {
      return setTimeout(() => {
        if (eventBus) {
          if (newWindow.eventBus) {
            dispatchEventBus(newWindow, eventBus.key, eventBus.value);
            clearTimeout(timeoutBus);
          } else {
            timeoutBus = setEventBus();
          }
        } else {
          clearTimeout(timeoutBus);
        }
      }, 50);
    };
    var timeoutBus = setEventBus();
  }
  // }
  return newWindow;
};

const combindWithQuery = (
  url: string,
  query: { [key: string]: any }
): string => {
  console.log("combindWithQuery", url, query);
  let keys: string[];
  if (query && (keys = Object.keys(query)).length > 0) {
    url += "?";
    return (url += keys
      .map((key) => {
        var value = query[key];
        if (typeof value === "object") {
          value = JSON.stringify(value);
        }
        return `${key}=${encodeURIComponent(value)}`;
      })
      .join("&"));
  } else {
    return url;
  }
};

/**
 * 스크린 기준이 아닌  부모 창의  중앙에 표시
 * @param options
 */
const buildStyle = (options: PopupOptions) => {
  const defaultOptions: PopupOptions = {
    width: 100,
    height: 100,
    scrollbars: "yes",
    resizable: 1,
  };
  const finalOptions: PopupOptions = {
    ...defaultOptions,
    ...options,
  };

  const nLeft =
    window.screenX + window.outerWidth / 2 - finalOptions.width! / 2;
  const nTop =
    window.screenY + window.outerHeight / 2 - finalOptions.height! / 2;

  finalOptions.left = nLeft;
  finalOptions.top = nTop;

  if (options.modal) {
    return Object.keys(finalOptions)
      .map((key) => `${key}: ${(finalOptions as any)[key]};`)
      .join("");
  } else {
    const style = Object.keys(finalOptions)
      .map((key) => `${key} = ${(finalOptions as any)[key]} `)
      .join(",");
    return style;
  }
};

const buildStyle_old = (options: PopupOptions) => {
  const defaultOptions: PopupOptions = {
    width: 100,
    height: 100,
    scrollbars: "yes",
    resizable: 1,
  };
  const finalOptions: PopupOptions = {
    ...defaultOptions,
    ...options,
  };

  const currentWindowWidth = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const currentWindowHeight = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = currentWindowWidth / window.screen.availWidth;
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;
  if (!finalOptions.left && finalOptions.width) {
    finalOptions.left =
      (currentWindowWidth - finalOptions.width) / 2 / systemZoom +
      dualScreenLeft;
  }
  if (!finalOptions.top && finalOptions.height) {
    finalOptions.top =
      (currentWindowHeight - finalOptions.height) / 2 / systemZoom +
      dualScreenTop;
  }

  if (options.modal) {
    return Object.keys(finalOptions)
      .map((key) => `${key}: ${(finalOptions as any)[key]};`)
      .join("");
  } else {
    const style = Object.keys(finalOptions)
      .map((key) => `${key} = ${(finalOptions as any)[key]} `)
      .join(",");
    return style;
  }
};
