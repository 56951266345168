import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { openPopup } from '@appbase/util/popup-helper' 
import modalHelper from '@appbase/util/modal-helper';
import ZinusBoardModal from "@webparts/views/Zinus/Modal/ZinusBoardModal.vue"

@Component
export default class GnbSearch extends Vue {
    @Prop({type:Array}) orgList!: orgmap.UserItem[]
    @Prop({type:Array}) articleList!: gnbarticle.Article[]
    @Prop({type:Number}) articleCount!: number
    @Prop({type:String}) userLang!: string

    fnDisplayDate(date: Date){
        let dtDate = new Date(date);
        return dtDate.toISOString().slice(0, 10);
    }

    fnVieArticle(articleId: number, boardId: number, title:string){
        if(this.isTeams){
            modalHelper.show({ component: ZinusBoardModal, force: true, value: { boardId: boardId, articleId: articleId, title:title } });
        }
        else{
            openPopup('', `/BoardPopup/readPopup?SYSID=${boardId}&ARTICLEID=${articleId}`, {}, {
                width: 1024,
                height: 700,
            });
        }
       
    }
}