import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import * as types from '@orgmap/store/types';
import * as userTypes from '@account/store/types';

import { DeptTree } from '@orgmap/components'
import api from '@orgmap/api';

@Component({
   name: 'PersonalFolderListContainer',
   components: { DeptTree },
   computed: {
        ...mapGetters({
      }),
    },
   methods: {
        ...mapActions({
          load: types.ORG_PERSONAL_FOLDER_LIST,
        }),
        ...mapMutations({
          add: types.ORG_CONTAINER_DEPT_ITEM_ADD
        }),
    },
})
export default class PersonalFolderListContainer extends Vue {

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //  


    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //   

    items: orgmap.TDepartmentTreeItem[] = []
    dept: orgmap.TDepartmentTreeItem | null = null;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    load!: () => Promise<Array<orgmap.PersonalFolder>>;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M U T A T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    add!: (payload: orgmap.TWishContainerItem) => void;

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: C O M P U T E D : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    async loadData(){
      const data = await this.load()
      this.items = data.map((x) => {
        return {
          id: x.id,
          comCode: '',
          deptCode: '',
          deptName: x.displayName,
          deptNameEn: x.displayName,
          dept_Level: '',
          email: '',
          hasChildren: x.childFolderCount ? x.childFolderCount > 0 : false,
          level: 0,
          parentDeptCode: '',
          children: null,
          expanded: false,
          selected: false,
          type: x.storedType.toString()
        } as orgmap.TDepartmentTreeItem
      });

      this.selectDept(this.items[0]);

    }

    selectDept(item: orgmap.TDepartmentTreeItem | null){
      this.dept = item;
      this.$emit('select', item);
    }

    async handleExpand(item: orgmap.TDepartmentTreeItem) {
      const dept = this.findDept(this.items, item.id)
      if (dept) {
        dept.expanded = !dept.expanded
        if (!dept.children || dept.children.length === 0) {
          var items: Array<orgmap.PersonalFolder> = [];
          switch(item.type){
            case '0': // ContactsStoredType.DB
              items = await api.local.getContactTree(item.id)
              break;
            case '1': // ContactsStoredType.EWS
              items = await api.personal.getContactTree(item.id)
              break;
          }

          dept.children = items.map((x: orgmap.PersonalFolder) => {
            return {
              id: x.id,
              comCode: '',
              deptCode: '',
              deptName: x.displayName,
              deptNameEn: x.displayName,
              dept_Level: '',
              email: '',
              hasChildren: x.childFolderCount ? x.childFolderCount > 0 : false,
              level: 0,
              parentDeptCode: '',
              children: null,
              expanded: false,
              selected: false,
              type: x.storedType.toString()
            } as orgmap.TDepartmentTreeItem
          })
        }
      }
    }

    findDept(parent: Array<orgmap.TDepartmentTreeItem>, id: string) {
      const _self = this
      const result = parent.find((dept) => dept.id === id)
      if (result) {
        return result
      } else {
        var foundDept = null
        parent.forEach((dept) => {
          if (dept.children && dept.children.length > 0) {
            foundDept = _self.findDept(dept.children, id)
          }
        })
        return foundDept
      }
    }
  
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: W A T C H : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    async mounted() {
        await this.loadData();
    }

    created() {

    }
}