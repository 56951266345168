import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import WebpartBase from '@webparts/views/WebpartBase'

@Component
export default class ZinusTestMaster extends WebpartBase {

    webpartStyle : string =""

    created(){
        const routerName = this.$route.name
 
        if(routerName == "zinus-worldtime"){
            this.webpartStyle = "height: 280px; width: 882px;padding-top: 30px;"
        }
        else if(routerName ==="zinus-calendar"){
            this.webpartStyle = "height:620px; width: 388px; padding-top: 30px;"
        }
        else if(routerName==="zinus-shortcut"){
            this.webpartStyle = "height:280px; width: 388px; padding-top: 30px;"
        }
        else if(routerName==="zinus-companynews" || routerName==="zinus-notice"){
            this.webpartStyle = "height:310px; width: 882px; padding-top: 30px;"
        }
        
    }
}
