import Vue, { VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapGetters, mapMutations } from 'vuex'
import api from '@orgmap/api'
import * as types from '@orgmap/store/types'
import * as accountTypes from '@account/store/types'

import { DeptTree } from '@orgmap/components'

const REQUEST_LEVEL = 5

@Component({
  name: 'DepartmentListContainer',
  components: { DeptTree },
  computed: {
    ...mapGetters({
      user: accountTypes.USER,
    }),
  },
  methods: {
    ...mapMutations({
      add: types.ORG_CONTAINER_DEPT_ITEM_ADD,
    }),
  },
})
export default class DepartmentListContainer extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: Boolean, default: false }) addableDept!: boolean
  @Prop({ type: String }) userlang!: string
  
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  user!: account.User

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: M U T A T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  add!: (payload: orgmap.TWishContainerItem) => void

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  companies: orgmap.Company[] = []
  items: orgmap.TDepartmentTreeItem[] = []
  dept: orgmap.TDepartmentTreeItem | null = null
  currentCompany: orgmap.Company | null = null

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  async created() {
    this.loadData()

    this.companies = await this.loadCompanies()
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  async loadData() {
    const { comCode, deptCode } = this.user
    var dept

    // 선택한 계열사가 있는 경우
    if (this.currentCompany) {
      this.items = await api.hr.getOrgTree({
        parentDeptCode: null,
        comCode: this.currentCompany.comCode,
        level: REQUEST_LEVEL
      })

      if (this.items.length) {
        if (this.currentCompany.comCode === comCode) {
          dept = await this.expandToMyDept(comCode, deptCode)
        } else {
          dept = await this.expandToMyDept(this.currentCompany.comCode, this.getDefaultDeptCode(this.items, Math.min(REQUEST_LEVEL, 3)))
        }

        if (dept) {
          this.selectDept({
            ...dept,
            selected: true,
          })
        }
      } else {
        this.selectDept(null)
      }
    }
    // 현재 선택한 계열사가 없는 경우( 처음 조회하는 경우 )
    else {
      this.items = await api.hr.getOrgTree({
        parentDeptCode: null,
        comCode: comCode,
        level: REQUEST_LEVEL,
      })
      const { com, id } = this.$route.params
      if (com && id) {
        await this.expandToMyDept(com, id)
      } else {
        const { comCode, deptCode } = this.user
        dept = await this.expandToMyDept(comCode, deptCode)
        if (dept) {
          this.selectDept({
            ...dept,
            selected: true,
          })
        }
      }      
    }
    this.$emit('getDepartments', this.items);
  }

  async handleExpand(item: orgmap.TDepartmentTreeItem) {
    console.log('000', item)
    const dept = this.findDept(this.items, item.deptCode)
    console.log('111', dept)
    if (dept) {
      dept.expanded = !dept.expanded
      if (!dept.children || dept.children.length === 0) {
        dept.children = await api.hr.getOrgTree({
          parentDeptCode: dept.deptCode,
          comCode: dept.comCode,
          level: REQUEST_LEVEL,
        })
      }
    }
  }

  async expandToMyDept(comCode: string, deptCode: string) {
    const _self = this
    const currentDepts = this.items
    var paths = await api.hr
      .getDeptPath({ comCode, deptCode })
      .then((res) => res.items)
      .then<string[]>((items) => items[0].path.split('/').slice(1))
    paths = paths.reverse()
    var dept: orgmap.TDepartmentTreeItem
    var updatableDept: orgmap.TDepartmentTreeItem
    const load = async (path?: string): Promise<orgmap.TDepartmentTreeItem> => {
      if (path) {
        // a. 부서 검색
        if (dept) {
          if (dept.children) {
            dept.expanded = true
            dept = dept.children.filter((dept) => dept.deptCode === path)[0]
          }
        } else {
          dept = currentDepts.filter((dept) => dept.deptCode === path)[0]
        }
        // b. children 없을 시 조회
        if (dept.hasChildren && (!dept.children || dept.children.length === 0)) {
          const items = await api.hr.getOrgTree({
            parentDeptCode: path,
            comCode: comCode,
            level: 1,
          })
          dept.children = items
          if (!updatableDept) {
            updatableDept = dept
          } else {
            if (updatableDept.deptCode === dept.parentDeptCode) {
              updatableDept = dept
              const tdept = _self.findDept(_self.items, updatableDept.deptCode)
              if (tdept) {
                tdept.expanded = true
                tdept.children = updatableDept.children
              } else {
                updatableDept.children?.forEach((item) => (item.expanded = true))
                _self.items = updatableDept.children || []
              }
            }
          }
          return load(paths.pop())
        } else {
          return load(paths.pop())
        }
      } else {
        // c. commit list
        if (updatableDept) {
          const dept = _self.findDept(_self.items, updatableDept.deptCode)
          if (dept) {
            dept.expanded = true
            dept.children = updatableDept.children
          } else {
            updatableDept.children?.forEach((item) => (item.expanded = true))
            _self.items = updatableDept.children || []
          }
        }
        return dept
        // action.dispatch(types.HR_TREE_CURRENT, dept)
      }
    }
    return await load(paths.pop())
  }

  async loadCompanies() {
    const { comCode } = this.user
    return await api.hr.getCompanies().then((res: orgmap.Company[]) => {
      return res.map((x) => {
        return {
          ...x,
          selected: x.comCode === comCode,
        }
      })
    })
  }

  /**
   * 적절한 갯수가 보여질 때까지 부서트리의 하위 부서코드를 탐색합니다
   * @param depts 부서트리 루트 배열
   * @param minLength 표시 최소 갯수 제한 (기본 3)
   * @param deptCode 부서코드 (내부변수)
   * @returns 
   */
  getDefaultDeptCode(depts: orgmap.TDepartmentTreeItem[], minLength = 3, deptCode = ''): string {
    if (depts && depts.length) {
      if (depts.length < minLength) {
        for (var i=0; i<depts.length; i++) {
          var dept = depts[i];
          deptCode = dept.deptCode;
          dept.expanded = true;
          if (dept.children && dept.children.length && dept.children.length < minLength) {              
              deptCode = this.getDefaultDeptCode(dept.children, minLength, deptCode);            
          } else if (dept.children?.length) {
            deptCode = dept.children[0].deptCode;
          }
        }
      } else {
        deptCode = depts[0].deptCode;
      }
    }
    return deptCode;
  }

  findDept(
    parents: Array<orgmap.TDepartmentTreeItem>,
    parentDeptCode: string
  ): orgmap.TDepartmentTreeItem | undefined {
    const _self = this
    for (var dept of parents) {
      if (dept.deptCode === parentDeptCode) {
        return dept
      } else if (dept.children && dept.children.length > 0) {
        const foundDept = _self.findDept(dept.children, parentDeptCode)
        if (foundDept) return foundDept
      }
    }
  }

  selectDept(item: orgmap.TDepartmentTreeItem | null) {
    this.dept = item
    this.$emit('select', item)
  }

  addDept(item: orgmap.TDepartmentTreeItem) {
    if (this.addableDept) {
      const newItem = {
        ...item,
        name: item.deptName,
        title: '',
        personCode: '',
        text: item.deptName,
        value: item.email,
        selected: false,
      } as orgmap.TWishContainerItem

      this.add(newItem)
    }
  }

  async selectCompany(item: orgmap.Company) {
    this.currentCompany = item
    await this.loadData()
  }
}
