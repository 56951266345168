import { ActionContext, ActionTree } from 'vuex';
import * as types from './types'
import api from '@reservedb/api'
import * as dateutil from '@appbase/util/date-helper';
import { EnumCalendarViewType } from '../types';
import { getResourceColor } from './consts'

import { BookingRequestType } from '@reservedb/types/enums'
import router from '@/router'

// //캘린더 뷰 타입에 따른 시작날짜를 불러옵니다
// const getCurrentStartDate = function (date: Date, mdate: Date, viewType: EnumCalendarViewType, action: ActionContext<reservedb.state.ReserveState, RootState>) {
//     switch (viewType) {
//         case EnumCalendarViewType.Daily:
//             return date;
//         case EnumCalendarViewType.Week:
//             return dateutil.uGetWeekStartDate(date, action.state.calendarSetting.firstDay);
//         case EnumCalendarViewType.Month:
//             return dateutil.uGetDaysInMonth(mdate, true, action.state.calendarSetting.firstDay)[0];
//     }
//     return date;
// }
//캘린더 뷰 타입에 따른 끝날짜를 불러옵니다
const getCurrentEndDate = function (date: Date, mdate: Date, viewType: EnumCalendarViewType, action: ActionContext<reservedb.state.ReserveState, RootState>) {
    switch (viewType) {
        case EnumCalendarViewType.Daily:
            return date;
        case EnumCalendarViewType.Week:
            return dateutil.uGetWeekEndDate(date, action.state.calendarSetting.firstDay);
        case EnumCalendarViewType.Month:
            var arr = dateutil.uGetDaysInMonth(mdate, true, action.state.calendarSetting.firstDay);
            return arr[arr.length - 1];
    }
    return date;
}

const sortFn = function (a: any, b: any): number {
    return a == b ? 0 : a < b ? -1 : 1;
}

// const isValidReloadFromChangedDate = function (targetDate: reservedb.DateItem, state: reservedb.state.ReserveState, viewType?: EnumCalendarViewType): boolean {
//     let flag: boolean = false;

//     const backDate: Date = state.backupDate.date;
//     const curDate: Date = targetDate.date;
//     const vmode: EnumCalendarViewType = viewType || state.calendarMode;

//     switch (vmode) {
//         case EnumCalendarViewType.Month:
//             let mStart = dateutil.uGetMonthStartDate(backDate);
//             let mEnd = dateutil.uGetMonthEndDate(backDate);

//             console.log('check', dateutil.uGetDateFormat('yyyy-MM-dd', curDate));
//             console.log('checkStart', dateutil.uGetDateFormat('yyyy-MM-dd', mStart));
//             console.log('checkEnd', dateutil.uGetDateFormat('yyyy-MM-dd', mEnd));

//             flag = dateutil.uGetIsInDate(curDate, mStart, mEnd);
//             break;
//         case EnumCalendarViewType.Week:
//             let wStart = dateutil.uGetWeekStartDate(backDate, state.calendarSetting.firstDay);
//             let wEnd = dateutil.uGetWeekEndDate(backDate, state.calendarSetting.firstDay);
//             flag = dateutil.uGetIsInDate(curDate, wStart, wEnd);
//             break;
//         case EnumCalendarViewType.Daily:
//             flag = dateutil.uGetIsInDate(curDate, backDate, backDate);
//             break;
//     }

//     return flag;
// }



const isBookingListReload = function (currentPayload: reservedb.RequestBookingParam, previousPayload: reservedb.RequestBookingParam) {

    const currentRouter = router.currentRoute

    if (currentRouter.name == "reservedb-write") {
        //Monthview 에서 아이템 더블클릭 시 시점상 문제로 write 창에서 booking 리스트가 조회되는 현상 확인 
        //write 인경우 바로 넘겨버리고 previousePayload 초기화
        previousPayload = Object.create(null)
        return false
    }
    //이전 요청값이 있는지
    if (previousPayload.requestType == null ||
        previousPayload.resourceIds == null ||
        currentPayload.requestType != previousPayload.requestType) {
        return true
    }

    //요청타입비교
    if (currentPayload.requestType != previousPayload.requestType) {
        return true
    }

    //선택자원비교
    if (currentPayload.resourceIds.length != previousPayload.resourceIds.length) {
        return true
    }

    const equals = (a: number[], b: number[]) =>
        a.length === b.length &&
        a.every((v, i) => v === b[i])

    if (!equals(currentPayload.resourceIds, previousPayload.resourceIds)) {
        return true
    }

    //basedate 비교 요청타입에 따라 따로 비교 해야됨


    if (currentPayload.requestType == BookingRequestType.Daily) {
        if (currentPayload.baseDatetime != previousPayload.baseDatetime) {
            return true
        }

    }
    else if (currentPayload.requestType == BookingRequestType.Weekly) {

        let wStart = dateutil.uGetWeekStartDate(previousPayload.baseDatetime, 0);
        let wEnd = dateutil.uGetWeekEndDate(previousPayload.baseDatetime, 0);
        if (!dateutil.uGetIsInDate(<Date>currentPayload.baseDatetime, wStart, wEnd)) {
            return true
        }

    }
    else if (currentPayload.requestType == BookingRequestType.Monthly) {
        let mStart = dateutil.uGetMonthStartDate(previousPayload.baseDatetime);
        let mEnd = dateutil.uGetMonthEndDate(previousPayload.baseDatetime);


        if (!dateutil.uGetIsInDate(<Date>currentPayload.baseDatetime, mStart, mEnd)) {
            return true
        }
    }


    return false
}

export default <ActionTree<reservedb.state.ReserveState, RootState>>{
    [types.CALENDAR_VIEWMODE](action, viewMode: EnumCalendarViewType) {
        action.commit(types.CALENDAR_VIEWMODE, viewMode);
    },

    [types.MINI_CALENDAR_MONTH_MATRIX_LOAD](action) {
        action.commit(types.MINI_CALENDAR_MONTH_MATRIX_LOAD);
    },

    [types.CALENDAR_TOGGLE_RIGHTPANE](action, payload) {
        action.commit(types.CALENDAR_TOGGLE_RIGHTPANE, payload);
    },

    [types.CALENDAR_TOGGLE_LEFTPANE](action, payload) {
        action.commit(types.CALENDAR_TOGGLE_LEFTPANE, payload);
    },



    [types.TOGGLE_RESOURCE_SELECT](action, payload) {
        action.commit(types.TOGGLE_RESOURCE_SELECT, payload);
    },

    [types.TOGGLE_RESOURCEGROUP_SELECT](action, payload) {
        action.commit(types.TOGGLE_RESOURCEGROUP_SELECT, payload);
    },

    [types.CALENDAR_MONTH_MATRIX_LOAD](action) {
        action.commit(types.CALENDAR_MONTH_MATRIX_LOAD);
    },

    [types.CALENDAR_WEEK_MATRIX_LOAD](action) {
        action.commit(types.CALENDAR_WEEK_MATRIX_LOAD);
    },

    [types.CALENDAR_DATE_MATRIX_LOAD](action) {
        action.commit(types.CALENDAR_DATE_MATRIX_LOAD);
    },



    [types.CALENDAR_DATE_SELECT](action, dateItem) {

        action.commit(types.CALENDAR_DATE_SELECT, dateItem);
        action.commit(types.MINI_CALENDAR_MONTH_MATRIX_LOAD);

    },

    [types.CALENDAR_DATE_SELECTRELOAD](action, dateItem) {

        action.commit(types.CALENDAR_DATE_SELECT, dateItem);
        action.commit(types.MINI_CALENDAR_MONTH_MATRIX_LOAD);

        // return Promise.all([
        //     action.dispatch(types.CALENDAR_DAILY_EVENT_LOAD),
        //     action.dispatch(types.CALENDAR_EVENTLIST_LOAD)        
        // ]);  
    },

    // [types.CALENDAR_EVENT_CREATE](action, item) {
    //     const fields: ewsResource.api.FieldOption[] = [
    //         //{ key: 'ParentFolderId', value: item.parentId },
    //         { key: 'Importance', value: item.importance },
    //         { key: 'EVENT_STATUS', value: item.status },
    //         { key: 'Subject', value: item.subject },
    //         { key: 'Location', value: item.location },
    //         { key: 'Start', value: item.start + 'T' + item.starttime },
    //         { key: 'End', value: item.end + 'T' + item.endtime },
    //         { key: 'IsAllDayEvent', value: item.isAllDayEvent },
    //         { key: 'Body', value: item.body },
    //         { key: 'RequiredAttendees', value: item.requiredAttendees },
    //         { key: 'OptionalAttendees', value: item.optionalAttendees },
    //         { key: 'Recurrence', value: item.recurrence },
    //         { key: 'Attachments', value: item.attachments },
    //         { key: 'Resources', value: item.resources },
    //         { key: 'AttachmentKey', value: item.code },
    //         { key: 'DelegateUser', value: item.delegateUser },
    //         { key: 'ReminderMinutesBeforeStart', value: item.reminderMinutesBeforeStart }
    //     ]

    //     const data: ewsResource.api.DynamicApiOptions = {
    //         fields: fields,
    //         parentId: item.parentId,
    //         type: 'Calendar'
    //     };

    //     return api.ewsCalendar.createEvent(data)
    //         .then(res => {
    //             return action.dispatch(types.CALENDAR_EVENTLIST_LOAD)
    //         });
    // },

    // [types.CALENDAR_EVENT_UPDATE](action, item) {
    //     const fields: ewsResource.api.FieldOption[] = [
    //         //{ key: 'ParentFolderId', value: item.parentId },
    //         { key: 'Importance', value: item.importance },
    //         { key: 'EVENT_STATUS', value: item.status },
    //         { key: 'Subject', value: item.subject },
    //         { key: 'Location', value: item.location },
    //         { key: 'Start', value: item.start + 'T' + item.starttime },
    //         { key: 'End', value: item.end + 'T' + item.endtime },
    //         { key: 'IsAllDayEvent', value: item.isAllDayEvent },
    //         { key: 'Body', value: item.body },
    //         { key: 'RequiredAttendees', value: item.requiredAttendees },
    //         { key: 'OptionalAttendees', value: item.optionalAttendees },
    //         { key: 'Recurrence', value: item.recurrence },
    //         { key: 'Attachments', value: item.attachments },
    //         { key: 'Resources', value: item.resources },
    //         { key: 'AttachmentKey', value: item.code },
    //         { key: 'DelegateUser', value: item.delegateUser },
    //         { key: 'ReminderMinutesBeforeStart', value: item.reminderMinutesBeforeStart },
    //     ];

    //     const data: ewsResource.api.DynamicApiOptions = {
    //         fields: fields,
    //         id: item.id,
    //         type: 'Calendar'
    //     };

    //     return api.ewsCalendar.editEvent(data)
    //         .then(res => {
    //             return action.dispatch(types.CALENDAR_EVENTLIST_LOAD)
    //         });
    // },

    // [types.CALENDAR_EVENT_CANCEL](action, id: string) {
    //     return api.ewsCalendar.cancelEvent(id).then((res) => {
    //         return action.dispatch(types.CALENDAR_EVENTLIST_LOAD)
    //     })
    // },

    // [types.CALENDAR_DAILY_EVENT_LOAD](action) {
    //     action.state.isDailyEventLoading = true;
    //     action.state.resourceDailyEvents = [];
    //     const currentDate = action.state.currentCalendarDate;
    //     const { date } = currentDate;

    //     var promiseArr: Promise<any>[] = [];
    //     var events = [] as reservedb.ItemList<reservedb.CalendarItem>;
    //     var selectedResources = action.state.selectedResources.filter(r => r.mailboxType === 'Mailbox')

    //     let endDate: Date = new Date(date);
    //     endDate.setDate(endDate.getDate() + 1);

    //     selectedResources.forEach(r => promiseArr.push(api.resource.getCalendarEventListWithRecurrence({
    //         EwsFolderIDArray: [r.calendarFolderId],
    //         StartDate: dateutil.uGetDateFormat('yyyy-MM-DDT00:00:00', date),
    //         EndDate: dateutil.uGetDateFormat('yyyy-MM-DDT00:00:00', endDate)
    //     } as reservedb.SearchOptions, r.address)));

    //     return Promise.all(promiseArr).then(res => {
    //         for (var i = 0; i < selectedResources.length; i++) {
    //             var resource = selectedResources[i];
    //             var eventResult = res[i];

    //             for (var event of eventResult) {
    //                 const existID = events.find((e) => event.id == e.id && (event.appointmentType != 'Occurrence' && event.appointmentType != 'Exception'));

    //                 if (existID) continue;

    //                 event.color = resource.colorCode == null ? getResourceColor(resource.uniqueId) : resource.colorCode;
    //                 event.resourceUpn = resource.address;
    //                 events.push(event);
    //             }
    //         }

    //         events.sort((a, b) =>
    //             sortFn(Number(dateutil.IEDate(a.start)), Number(dateutil.IEDate(b.start))) ||
    //             sortFn(Number(a.dateTimeCreated), Number(b.dateTimeCreated))
    //         );

    //         action.commit(types.CALENDAR_DAILY_EVENT_LOAD, events);
    //         return events;
    //     }).finally(() => { action.state.isDailyEventLoading = false; });
    // },

    // [types.CALENDAR_SEARCH_EVENTS_LOAD](action, searchSet?: reservedb.ReserveSearchSet) {
    //     var set = searchSet || action.state.searchSet;
    //     action.state.isLoading = true;
    //     const { date } = action.state.currentDate;
    //     action.state.searchEvents = [];

    //     var promiseArr: Promise<any>[] = [];
    //     var events = [] as reservedb.ItemList<reservedb.CalendarItem>;
    //     var selectedResources = action.state.selectedResources.filter(r => r.mailboxType === 'Mailbox');

    //     selectedResources.forEach(r => promiseArr.push(
    //         api.resource.searchCalendarEventList(<reservedb.SearchOptions>{
    //             StartDate: set.searchStart,
    //             EndDate: set.searchEnd,
    //             OrganizerName: set.reserverName,
    //             Subject: set.reserveTitle,
    //             Keyword: set.reserveContent
    //         }, r.address)));

    //     Promise.all(promiseArr)
    //         .then(res => {
    //             for (var i = 0; i < selectedResources.length; i++) {
    //                 var resource = selectedResources[i];
    //                 var eventResult = res[i];

    //                 for (var event of eventResult) {
    //                     const existID = events.find((e) => event.id == e.id && (event.appointmentType != 'Occurrence' && event.appointmentType != 'Exception'));

    //                     if (existID) continue;

    //                     event.color = resource.colorCode == null ? getResourceColor(resource.uniqueId) : resource.colorCode;
    //                     event.resourceUpn = resource.address;
    //                     events.push(event);
    //                 }
    //             }

    //             events.sort((a, b) =>
    //                 sortFn(Number(dateutil.IEDate(a.start)), Number(dateutil.IEDate(b.start))) ||
    //                 sortFn(Number(a.dateTimeCreated), Number(b.dateTimeCreated))
    //             );

    //             action.commit(types.CALENDAR_SEARCH_EVENTS_LOAD, events);
    //             return events;
    //         }).finally(() => { action.state.isLoading = false; });
    // },

    [types.CALENDAR_SEARCHSET_UPDATE](action, payload: reservedb.ReserveSearchSet) {
        action.commit(types.CALENDAR_SEARCHSET_UPDATE, payload);
    },

    [types.TOGGLE_MAIN_LISTVIEW](action, payload: boolean) {
        action.commit(types.TOGGLE_MAIN_LISTVIEW, payload);
    },


    [types.RESOURCE_BOOKING_LIST](action, payload: reservedb.RequestBookingParam) {

        //데이터 다시 가져와야되는지 확인



        if (!isBookingListReload(payload, action.state.previousRequestParam)) {
            //매트릭스 + 매핑만 하고 종료
            // action.state.previousRequestParam = payload
            // action.commit(types.MINI_CALENDAR_MONTH_MATRIX_LOAD);
            action.dispatch(types.RESOURCE_MAP_MATRIX, payload.requestType)
            return
        }



        action.state.isLoading = true

        //모두 선택해제 케이스임
        if (payload.resourceIds.length == 0) {
            action.commit(types.MINI_CALENDAR_MONTH_MATRIX_LOAD);
            action.commit(types.CALENDAR_MONTH_MATRIX_LOAD);
            action.commit(types.CALENDAR_WEEK_MATRIX_LOAD);
            action.commit(types.CALENDAR_DATE_MATRIX_LOAD);
            action.commit(types.RESOURCE_BOOKING_LIST, [])
            action.commit(types.RESOURCE_ALLDAY_EVENTS, [])
            action.state.isLoading = false
            return
        }


        let promiseItems: Array<Promise<Array<reservedb.ResponseBookingItem>>> = []
        payload.resourceIds.forEach(id => {
            promiseItems.push(api.booking.GetBookingList({
                resourceId: id,
                requestType: payload.requestType,
                baseDatetime: payload.baseDatetime ? payload.baseDatetime : new Date()
            }))
        })
        Promise.all(promiseItems).then(result => {
            let sumResult: Array<reservedb.ResponseBookingItem> = []
            result.forEach(bookingList => {
                sumResult = sumResult.concat(bookingList)
            })
            sumResult.sort((a: reservedb.ResponseBookingItem, b: reservedb.ResponseBookingItem) => {
                return +new Date(a.startDate) - +new Date(b.startDate)
            })


            action.commit(types.MINI_CALENDAR_MONTH_MATRIX_LOAD);
            action.commit(types.RESOURCE_BOOKING_LIST, sumResult)
            action.dispatch(types.RESOURCE_MAP_MATRIX, payload.requestType)

            let json = JSON.stringify(payload)

            action.commit(types.PREVIOUSE_PAYLOAD, JSON.parse(json))
            //완료 후 현재 요청 param 저장



        }).finally(() => { action.state.isLoading = false });



    },


    [types.RESOURCE_PERMISSIONS](action, payload: number) {
        return api.resourcedb.getResourcePermissions(payload).then(result => {
debugger
        })
    },


    [types.RESOURCE_MAP_MATRIX](action, payload: BookingRequestType) {

        let bookingList: Array<reservedb.ResponseBookingItem> = []
        Object.assign(bookingList, action.state.bookingList)
        let allDayEvents: Array<reservedb.ResponseBookingItem> = []

        const curDate = action.state.currentDate.date
        const strCurDate = dateutil.uGetDateFormat("yyyy-MM-DD", curDate)


        if (payload == BookingRequestType.Daily) {
            action.commit(types.CALENDAR_DATE_MATRIX_LOAD);

            let dayMatrix: reservedb.DateItem[][] = []
            Object.assign(dayMatrix, action.state.dateMatrix)




            bookingList.forEach(event => {
                //종일 이벤트인경우는 메트릭스에 포함하지 않고 따로 관리 함
                //종일 이벤트 조건 1 startDate == endDate
                // 조건2 startTime = "00:00:00"
                //조건 3 endTime = "23:59:59" 면 종일 이벤트로 봄

                // if(event.reserveSubject == "왜실패"){
                //     debugger
                // }
                //같은일인 경우에는 우선 제외
                // event.startDate == event.endDate &&
                if (event.startTime == "00:00:00" && event.endTime == "23:59:59") {

                    allDayEvents.push(event)

                } else {
                    if (event.startDate == strCurDate) {
                        let strStartTime: string | number = event.startTime.substring(0, 2)
                        //30분 단위 시작인경우 변경
                        //console.log(event.startTime.substring(3,2))
                        if (event.startTime.substring(3, 5) == "30") {
                            strStartTime = parseInt(strStartTime) + 0.5
                        }

                        let selectItem = dayMatrix.filter(item => {

                            if (item[0].ui_days_week_time_text == strStartTime) {
                                return item
                            }
                        })

                        if (selectItem.length > 0) {
                            selectItem[0][1].events.push(event as any)
                        }


                    }
                }


            })


        }
        else if (payload == BookingRequestType.Weekly) {
            action.commit(types.CALENDAR_WEEK_MATRIX_LOAD);

            let weekMatrix: reservedb.DateItem[][] = []
            Object.assign(weekMatrix, action.state.weekMatrix)


            const weeklist = weekMatrix[0].filter(f => f.date != null).map(x => <any>{
                date: `${x.date.getDate().toString().length == 1 ? "0" : ""}${x.date.getDate()}`
            });

            let dayOfWeekBookingList: Array<Array<reservedb.ResponseBookingItem>> = []

            weeklist.forEach(week => {
                let inputData = bookingList.filter(item => {
                    return item.startDate.substring(8, 10) == week.date
                })
                dayOfWeekBookingList.push(inputData)
            })

            let indexNum = 0
            dayOfWeekBookingList.forEach(filteredBookingList => {


                filteredBookingList.forEach(event => {

                    //종일 이벤트인경우는 메트릭스에 포함하지 않고 따로 관리 함
                    //종일 이벤트 조건 1 startDate == endDate
                    // 조건2 startTime = "00:00:00"
                    //조건 3 endTime = "23:59:59" 면 종일 이벤트로 봄

                    // if(event.reserveSubject == "왜실패"){
                    //     debugger
                    // }
                    //같은일인 경우에는 우선 제외
                    // event.startDate == event.endDate &&
                    if (event.startTime == "00:00:00" && event.endTime == "23:59:59") {

                        allDayEvents.push(event)

                    } else {
                        // if (event.startDate == strCurDate) {
                        let strStartTime: string | number = event.startTime.substring(0, 2)
                        //30분 단위 시작인경우 변경
                        //console.log(event.startTime.substring(3,2))
                        if (event.startTime.substring(3, 5) == "30") {
                            strStartTime = parseInt(strStartTime) + 0.5
                        }

                        let selectItem = weekMatrix.filter(item => {

                            if (item[0].ui_days_week_time_text == strStartTime) {
                                return item
                            }
                        })

                        if (selectItem.length > 0) {
                            selectItem[0][indexNum + 1].events.push(event as any)
                        }

                    }

                })


                indexNum++
            })




        }
        else if (payload == BookingRequestType.Monthly) {
            action.commit(types.CALENDAR_MONTH_MATRIX_LOAD);


            let monthMatrix: reservedb.DateItem[][] = []
            Object.assign(monthMatrix, action.state.monthMatrix)


            let monthlyBookingList: Array<Array<Array<reservedb.ResponseBookingItem>>> = []

            for (let i = 0; i < 5; i++) {
                //한달은 5주니깐 5번 루프 돌려서 한주씩 입력
                //달이 바뀌는 주 때문에 달 까지 기록
                const weeklist = monthMatrix[i].filter(f => f.date != null).map(x => <any>{
                    month: `${(x.date.getMonth() + 1).toString().length == 1 ? "0" : ""}${(x.date.getMonth() + 1)}`,
                    date: `${x.date.getDate().toString().length == 1 ? "0" : ""}${x.date.getDate()}`
                });



                let dayOfWeekBookingList: Array<Array<reservedb.ResponseBookingItem>> = []
                weeklist.forEach(week => {


                    let inputData = bookingList.filter(item => {
                        return (item.startDate.substring(8, 10) == week.date &&
                            item.startDate.substring(5, 7) == week.month)
                    })

                    //월간 뷰는 최대 2개 아이템 빠른 시간대로해서 2개까지만 표시 한다
                    if (inputData.length > 0) {

                        inputData = inputData.sort((a: reservedb.ResponseBookingItem, b: reservedb.ResponseBookingItem) => {
                            return +new Date(`${a.startDate}T${a.startTime}+09:00`) - +new Date(`${b.startDate}T${b.startTime}+09:00`)
                        })
                        //more count 가 있어서 우선 여기서 안자름
                        // }).slice(0, 2)
                    }


                    dayOfWeekBookingList.push(inputData)

                })

                monthlyBookingList.push(dayOfWeekBookingList)
            }

            //monthlyBookingList 데이터 준비는 완료
            //여기서 monthMatrix 에 동일 index 로 event map
            for (let weekIndex = 0; weekIndex < 5; weekIndex++) {

                for (let dayOfWeekIndex = 0; dayOfWeekIndex < 7; dayOfWeekIndex++) {

                    monthMatrix[weekIndex][dayOfWeekIndex].events = monthlyBookingList[weekIndex][dayOfWeekIndex]

                }

            }





        }

        //종일 이벤트가 있는 경우 commit
        if (allDayEvents.length > 0) {
            action.commit(types.RESOURCE_ALLDAY_EVENTS, allDayEvents)
        } else {
            action.commit(types.RESOURCE_ALLDAY_EVENTS, [])
        }
    }








    //database


};
