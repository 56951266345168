import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'
import store from '@/store'
import * as types from '../store/types'
import appbaseStore from '@appbase/store'
import * as msTeams from '@microsoft/teams-js'
import { LoginActionPayload } from '../store/actions'

//
// ──────────────────────────────────────────────────── I ──────────
//   :::::: C O M M O N : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────
//

const BYPASS_NAMES = ['account-login', 'redirect-page','mllw-account-login']

/**
 * 인증 상태를 확인합니다.
 */
const authenticated = (): boolean => {
  var current = store.state.$account?.current
  return current !== null
}

/**
 * 로그인 상태가 아닌경우, 이메일 주소만으로 로그인 처리합니다.
 * @param email 로그인할 이메일 주소
 * @returns true: 로그인 또는 토큰 정상, false: 인증 실패
 */
const authWithLoginHint = async (hint: string): Promise<boolean> => {
  if (!authenticated()) {
    var loginData = {
      username: hint,
      password: '',
    } as LoginActionPayload
    return store
      .dispatch(types.LOGINHINT, loginData)
      .then((result: account.TCurrent) => {
        if (result.user) {
          return true
        } else {
          throw new Error(`Login failed. Reason`)
        }
      })
      .catch((err) => {
        // TODO: 로그인 실패 페이지로 이동
        console.error(err)
        throw new Error(`Login failed. Reason : ${err}`)
      })
  } else {
    // 이미 로그인 되어 있음
    return true
  }
}

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: F O R   T E A M S : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

/**
 * Teams 내부에서 동작 중인지 여부
 * @returns true: teams에서 동작 중임, false: 일반 브라우저
 */
const isTeams = () => appbaseStore.state.teams

/**
 * 팀즈 SDK를 이용하여 로그인된 사용자의 Email 주소를 획득합니다.
 * @returns email address
 */
const getLoginHintFromTeams = () => {
  return new Promise<string>((resolve, reject) => {
    msTeams.getContext((context: any) => {
      if (context?.loginHint) {
        resolve(context.loginHint)
      } else {
        reject('Cannot get context information from Teams.')
      }
    })
  })
}

const processForTeams = async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  const hint = await getLoginHintFromTeams()
  // 암호 없이 이메일 주소만을 이용하여 인증을 수행합니다.
  if (hint && (await authWithLoginHint(hint))) {
    next()
  } else {
    // TODO: Teams 초기화 실패 페이지로 이동
    throw new Error('An error occurred while initializing Teams.')
  }
}

//
// ──────────────────────────────────────────────────── I ──────────
//   :::::: P U B L I C : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────
//

/**
 * 로그인을 위한 라우트를 재설정합니다.
 * @param to
 * @param from
 * @param next
 */
const resetRouteForLogin = async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  const { search } = window.location
  // 웹앱이 Teams 안에서 구동되는 경우
  if (isTeams()) {
    await processForTeams(to, from, next)
  }
  // 웹앱이 브라우저에서 구동되는 경우
  else {
    const useWindowsAuth = false
    // 여기서 분기처리 windows 인증 사용여부에 따라 EWA REDRIECT
    // Config 정보가 사용자  정보와 같이 전달되기 때문에 로그인전에는 Crrent == null
    // 웹 서버에 배포된 환경에서만 윈도우즈 인증을 지원
    if (process.env.NODE_ENV === 'production' && useWindowsAuth) {
      const url = `${window.location.origin}/ewa`
      const baseUrl = process.env.BASE_URL.slice(0, -1) // '/po/'에서 마지막 '/' 제거
      const returnUrl = encodeURIComponent(
        `${baseUrl}${to.path}${search}`
      )
      window.location.href = `${url}?returnUrl=${returnUrl}`
    }
    // 코딩 타임이나 윈도우즈 인증을 사용하지 않은 경우에는 로그인 페이지로 이동
    else {
      const routeName = location.hostname.toLowerCase() === 'mellow.zinus.com' ? 'mllw-account-login' : BYPASS_NAMES[0]
      next({
        name: routeName,
        query: {
          returnUrl: `${to.path}${search}`,
        },
      })
    }
  }
}

/**
 * 인증 상태를 확인하고 인증이 필요한 경우 라우트를 재 설정합니다.
 * @param to
 * @param from
 * @param next
 * @returns
 */
const authGuard: NavigationGuard = async (to, from, next) => {
  // ────────────────────────────────────────────────────────────────────────────────
  // 이미 로그인 되어 있거나 로그인 페이지로 향하는 경우 가드 통과
  if (BYPASS_NAMES.includes(to.name || '') || authenticated()) {
    console.log('auth-guard', 'route to : ', to.name, BYPASS_NAMES.includes(to.name || ''))
    next()
  }
  // ────────────────────────────────────────────────────────────────────────────────
  // 로그인 처리 위한 라우트 재지정
  else {
    try {
      console.log('auth-guard', 'reset route to : ', to.name)
      await store.dispatch(types.CURRENT)
      if (authenticated()) {
        next() // 인증이 되어 있는 경우 현재 가드 통과 처리
      } else {
        console.error('There is no current.')
        await resetRouteForLogin(to, from, next)
      }
    } catch (err) {
      console.error(err)
      await resetRouteForLogin(to, from, next)
    }
  }
}
export default authGuard
