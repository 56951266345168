import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { WishListContainer } from "../containers";
import * as types from "../store/types";
import { mapGetters, mapMutations } from "vuex";
import { OrgmapMainBase, TTabNames } from "./OrgmapMainBase";
import OrgTab from "../components/OrgTab.vue";
import {
  createEventBus,
  dispatchEventBus,
  getDispatchData,
  removeEventBus,
} from "@/modules/@appbase/util";
import ModalSlotLayout from "@/modules/@appbase/containers/ModalContainer/ModalSlotLayout.vue";
import modalHelper from "@/modules/@appbase/util/modal-helper";

@Component({
  components: { WishListContainer, OrgTab, ModalSlotLayout },
  computed: {
    ...mapGetters({
      containers: types.ORG_CONTAINER_REGISTER,
    }),
  },
  methods: {
    ...mapMutations({
      updateContainer: types.ORG_CONTAINER_UPDATE,
      setOptions: types.ORG_OPTION_SET,
    }),
  },
})
export default class PopupView extends OrgmapMainBase {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: Object }) value!: any;

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  isModal?: boolean = false;

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: M U T A T I O N S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  updateContainer!: (payload: orgmap.TWishContainerOptionItem) => void;
  setOptions!: (payload: orgmap.TWishContainerOptions) => void;

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //

  get hasContainers() {
    return this.options?.containers?.length > 0;
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  //#region 2023-08-14 수정
  created() {
    createEventBus(window, types.ORG_EVENT_TYPE, this.handleEventBus);

    var paramValue;
    if (this.value) {
      paramValue = this.value;
      this.isModal = true;
    } else {
      paramValue = this.$route.query;
    }

    const { option, event, props } = paramValue;

    if (option && typeof option === "string") {
      this.options = {
        ...this.options,
        ...(JSON.parse(option) as orgmap.TWishContainerOptions),
      };
    }

    if (option && typeof option === "string") {
      this.options = {
        ...this.options,
        ...(JSON.parse(option) as orgmap.TWishContainerOptions),
      };

      var windowCaller;
      if ((window as any).opener) {
        windowCaller = (window as any).opener;
      } else if (
        window.frameElement &&
        window.frameElement!.nodeName == "IFRAME" &&
        (window as any).parent
      ) {
        windowCaller = (window as any).parent;
      }

      if (windowCaller.getContainersItems) {
        var containersItemsString = windowCaller.getContainersItems();
        try {
          if (containersItemsString) {
            var containersItems = JSON.parse(
              containersItemsString
            ) as Array<any>;
            /**
             * id : ...
             * items : [...]
             */
            if (containersItems.length > 0) {
              for (var i = 0; i < this.options.containers.length; i++) {
                var findItem = containersItems.find(
                  (x) => x.id == this.options.containers[i].id
                );
                if (findItem) {
                  this.options.containers[i].items = findItem.items;
                }
              }

              this.options.containers.forEach((a) => {
                this.updateContainer(a);
              });
            }
          }
        } catch (err) {}
      }
    }

    this.setOptions(this.options);

    if (event && typeof event === "string") {
      this.event = event;
    }

    if (props && typeof props === "string") {
      console.log("props", props);
      this.propsOption = JSON.parse(props) as orgmap.TWishItemPropsOptions;
    }
  }
  //#endregion

  beforeDestroy() {
    removeEventBus(window, types.ORG_EVENT_TYPE, this.handleEventBus);
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  handleTabSelect(name: TTabNames) {
    this.processTabSelect.bind(this, name)();
  }

  handleTabActive(name: TTabNames) {
    return this.processTabActive.bind(this, name)();
  }

  handleOk() {
    this.processOk.bind(this, window.opener)();
  }

  handleClose() {
    if (this.isModal) {
      modalHelper.close();
    } else {
      window.close();
    }
  }

  handleEventBus() {
    var res: Array<{ id: number; items: orgmap.TWishContainerItem[] }> =
      getDispatchData(types.ORG_EVENT_TYPE);
    if (res.length) {
      this.options.containers
        .map((x) => {
          return {
            ...x,
            items: res.find((a) => a.id === x.id)?.items,
          };
        })
        .forEach((a) => {
          this.updateContainer(a);
        });
    }
  }
}
