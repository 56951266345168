import api from '@/api'

export default {


    GetBookingList: (data: reservedb.RequestBookingList): Promise<Array<reservedb.ResponseBookingItem>> => {

        return api.post({
            url: `/CommonReserveDb/booking/api/list`,
            data: data
        })

    },

    SearchBookingItems: (data: reservedb.RequestBookingsearch): Promise<Array<reservedb.ResponseBookingSearchItem>> => {

        return api.post({
            url: `/CommonReserveDb/booking/api/search`,
            data: data
        })

    },

     
    
}