import { MutationTree } from "vuex";
import * as types from "./types";
import api from "@orgmap/api";
import { uGetWeekEndDate } from "@/modules/@appbase/util";
import { TWishContainerValueType } from "@orgmap/types/enums";

export type TWishContainerSelectPayload = {
  id: number;
  item: orgmap.TWishContainerItem;
};
export type TWishContainerSelectAllPayload = {
  id: number;
  select: boolean;
};

export type TOrgContainerItemAddMutationPayload = {
  containerId: number;
};

export type TOrgContainerItemDelMutationPayload = {
  containerId: number;
};

export type TOrgContainerActivateMutationPayload = {
  containerId: number;
};

export interface OrgMemberListMutationPayload extends orgmap.TMemberList {}

export type OrgMemberSelectMutationPayload = {
  select: boolean;
  items: orgmap.TMemberItem[];
};

export default <MutationTree<orgmap.state.OrgmapState>>{
  [types.ORG_SELECT_TREE_ITEM](state, payload: boolean) {
    state.selectTreeItem = payload;
  },

  [types.ORG_ME](state, user: orgmap.TUser) {
    state.user = user;
  },

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: C O M M O N S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //

  [types.ORG_OPTION_SET](state, payload: orgmap.TWishContainerOptions) {
    state.options = payload;
  },

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E M B E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  [types.ORG_MEMBER_LIST](state, payload: OrgMemberListMutationPayload) {
    state.members = payload;
  },

  [types.ORG_MEMBER_SELECT](state, payload: OrgMemberSelectMutationPayload) {
    const { select, items } = payload;
    if (state.options.single) {
      state.members.items.forEach((x) => (x.selected = false));
    }
    items.forEach((item) => {
      const found = state.members.items.find(
        (x) =>
          x.id === item.id &&
          x.deptCode === item.deptCode &&
          x.roleCode === item.roleCode
      );
      if (found) {
        found.selected = select;
        if (state.options.single) {
          if (state.wish?.length) {
            var container = state.wish[0];
            if (container) {
              const selItems = state.members.items.filter((x) => {
                return (
                  // 선택된 아이템
                  x.selected
                );
              });

              const flatten = function (
                list: Array<orgmap.TMemberItem>
              ): Array<orgmap.TMemberItem> {
                const stack = [...list];
                const res: Array<orgmap.TMemberItem> = [];
                while (stack.length) {
                  const next = stack.pop();
                  if (next) {
                    if (["IPM.DistList", "G"].includes(next.type || "")) {
                      next.children?.forEach((x) => {
                        if (res.findIndex((a) => a.email === x.email) < 0) {
                          res.push(x);
                        }
                      });
                    } else {
                      if (res.findIndex((a) => a.email === next.email) < 0) {
                        res.push(next);
                      }
                    }
                  }
                }
                return res.reverse();
              };

              const flattened = flatten(selItems);
              const newItems = [
                ...flattened
                  .filter((a) => {
                    return (
                      // 중복 제거
                      container.items.findIndex(
                        (y) => y.value === a.email || y.id === a.id
                      ) < 0
                    );
                  })
                  .map<orgmap.TWishContainerItem>((x: any) => ({
                    ...x,
                    text: x.name,
                    value: x.email,
                    selected: false,
                  })),
              ];
              container.items = newItems;
              evalContainerSelectAll(container);
            }
          }
        }
      }
    });

    evalMembersSelectAll(state.members);
  },

  [types.ORG_MEMBER_SELECT_ALL](state, payload: boolean) {
    state.members.items = state.members.items.map((x) => ({
      ...x,
      selected: payload,
    }));
    state.members.selectedAll = payload;
  },

  //
  // ──────────────────────────────────────────────────────────────────── I ──────────
  //   :::::: W I S H   C O N T A I N E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────────────
  //

  [types.ORG_CONTAINER_REGISTER](
    state,
    payload: orgmap.TWishContainerOptionItem
  ) {
    if (state.wish.findIndex((x) => x.id === payload.id) < 0) {
      const containerItem = payload.items?.filter((item) => {
        return item.valueType == null;
      });

      const newContainer: orgmap.TWishContainer = {
        ...payload,
        selectedAll: false,
        items:
          containerItem?.map((x) => ({ ...x, selected: x.selected })) || [],
        activated: payload.id === 0,
      };

      const hasValueTypeItem = payload.items?.filter((item) => {
        return item.valueType != null;
      });

      if (hasValueTypeItem && hasValueTypeItem.length > 0) {
        let apiArr = [] as any[];

        hasValueTypeItem.forEach((item) => {
          //API 분기 필요 함
          //우선 /api/hr/department/search? 로 커버
          if (item.valueType == TWishContainerValueType.email) {
            //이메일
            // apiArr.push(api.hr.getUserInfo(item.value))
          } else if (item.valueType == TWishContainerValueType.personCode) {
            //personCode
            // apiArr.push(api.publics.getPersonInfo(item.value))
          } else if (item.valueType == TWishContainerValueType.deptCode) {
            //부서코드
          }
          // includeAllFlag: false
          // query: "fl_mark"
          // retiredFlag: false

          apiArr.push(
            api.hr.searchOrg(
              {
                includeAllFlag: true,
                query: item.value,
                retiredFlag: false,
              },
              ""
            )
          );
        });
        Promise.all(apiArr).then((result) => {
          result.forEach((r) => {
            var idxNo = result.indexOf(r);

            if (r.items.length > 0)
              newContainer.items.push({
                ...r.items[0],
                text: r.items[0].displayName,
                selected: hasValueTypeItem[idxNo].selected,
                personCode:
                  hasValueTypeItem[idxNo].valueType == 2 ? "" : r.items[0].id,
                value: r.items[0].email,
                title: r.items[0].titleName,
              });
          });
          //state.wish = [...state.wish, newContainer]
        });
      }

      state.wish = [...state.wish, newContainer];
    }
  },

  [types.ORG_CONTAINER_UPDATE](
    state,
    payload: orgmap.TWishContainerOptionItem
  ) {
    if (state.wish.findIndex((x) => x.id === payload.id) > -1) {
      var container = state.wish.find((x) => x.id === payload.id);
      if (container) {
        const newContainer: orgmap.TWishContainer = {
          ...container,
          items: payload.items?.map((x) => ({ ...x, selected: false })) || [],
        };

        state.wish = state.wish.map((a) => {
          if (a.id === newContainer.id) {
            return newContainer;
          } else {
            return a;
          }
        });
      }
    }
  },

  [types.ORG_CONTAINER_ITEM_SELECT](
    state,
    payload: TWishContainerSelectPayload
  ) {
    const { id, item } = payload;
    const container = state.wish.find((c) => c.id === id);
    if (container) {
      container.items = container.items.map((x) => {
        if (x.id === item.id) {
          // value 로 비교시 부서의 경우 고유키가 되지 못함
          return {
            ...x,
            selected: !x.selected,
          };
        } else return x;
      });
      evalContainerSelectAll(container);
    }
  },

  [types.ORG_CONTAINER_ITEM_SELECT_ALL](
    state,
    payload: TWishContainerSelectAllPayload
  ) {
    const { id, select } = payload;
    const container = state.wish.find((c) => c.id === id);
    if (container) {
      container.items = container.items.map((c) => ({
        ...c,
        selected: select,
      }));
      evalContainerSelectAll(container);
    }
  },

  [types.ORG_CONTAINER_CLEAR](state) {
    state.wish = [];
  },

  // [types.ORG_CONTAINER_ITEM_ADD](state, payload: number) {
  //   const container = state.wish.find((c) => c.id === payload)
  //   if (container) {
  //     container.items = [
  //       ...container.items,
  //       {
  //         text: 'test' + container.items.length + 1,
  //         value: 'test' + container.items.length + '@test.com',
  //         selected: false,
  //       },
  //     ]
  //     evalContainerSelectAll(container)
  //   }
  // },

  // [types.ORG_CONTAINER_ITEM_DEL](
  //   state,
  //   payload: { containerId: number; item?: orgmap.TWishContainerItem }
  // ) {
  //   const container = state.wish.find((c) => c.id === payload.containerId)
  //   if (container) {
  //     if (payload.item) {
  //       // 삭제 대상을 특정하는 경우
  //       container.items = container.items.filter((c) => c.value !== payload.item?.value)
  //     } else {
  //       container.items = container.items.filter((c) => !c.selected)
  //     }
  //     evalContainerSelectAll(container)
  //   }
  // },

  [types.ORG_CONTAINER_DEPT_ITEM_ADD](
    state,
    payload: orgmap.TWishContainerItem
  ) {
    const container = state.wish.find((c) => c.activated === true);

    if (container) {
      if (container.items.findIndex((x) => x.id === payload.id) < 0) {
        // 중복 제거
        const newItems = [...container.items, payload];
        container.items = newItems;
        evalContainerSelectAll(container);
      }
    }
  },

  [types.ORG_CONTAINER_ITEM_ADD](
    state,
    { containerId }: TOrgContainerItemAddMutationPayload
  ) {
    const container = state.wish.find((c) => c.id === containerId);
    if (container) {
      const selItems = state.members.items.filter((x) => {
        return (
          // 선택된 아이템
          x.selected &&
          // 중복 제거
          container.items.findIndex(
            (y) => y.value === x.email || y.id === x.id
          ) < 0
        );
      });

      const flatten = function (
        list: Array<orgmap.TMemberItem>
      ): Array<orgmap.TMemberItem> {
        const stack = [...list];
        const res: Array<orgmap.TMemberItem> = [];
        while (stack.length) {
          const next = stack.pop();
          if (next) {
            if (["IPM.DistList", "G"].includes(next.type || "")) {
              next.children?.forEach((x) => {
                if (res.findIndex((a) => a.email === x.email) < 0) {
                  res.push(x);
                }
              });
            } else {
              if (res.findIndex((a) => a.email === next.email) < 0) {
                res.push(next);
              }
            }
          }
        }
        return res.reverse();
      };

      const flattened = flatten(selItems);
      const newItems = [
        ...container.items,
        ...flattened
          .filter((a) => {
            return (
              // 중복 제거
              container.items.findIndex(
                (y) => y.value === a.email || y.id === a.id
              ) < 0
            );
          })
          .map<orgmap.TWishContainerItem>((x: any) => ({
            ...x,
            text: x.name,
            value: x.email,
            selected: false,
          })),
      ];
      container.items = newItems;
      evalContainerSelectAll(container);
      state.members.items = state.members.items.map((x) => ({
        ...x,
        selected: false,
      }));
      evalMembersSelectAll(state.members);
    }
  },

  [types.ORG_CONTAINER_ITEM_DEL](
    state,
    { containerId }: TOrgContainerItemDelMutationPayload
  ) {
    const container = state.wish.find((c) => c.id === containerId);
    if (container) {
      container.items = container.items.filter((x) => !x.selected);
      evalContainerSelectAll(container);
    }
  },

  [types.ORG_CONTAINER_ACTIVATE](
    state,
    { containerId }: TOrgContainerActivateMutationPayload
  ) {
    const container = state.wish.find((c) => c.id === containerId);
    if (container) {
      state.wish = state.wish.map((x) => {
        if (x === container) {
          return {
            ...x,
            activated: true,
          };
        } else {
          return {
            ...x,
            activated: false,
          };
        }
      });
    }
  },

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: M O B I L E : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //

  [types.ORG_USER_SELECT](state, payload: orgmap.TUser) {
    var personId = payload.personId;
    var index = state.selections.users.findIndex(
      (user) => user.personId == personId
    );
    if (index > -1) state.selections.users.splice(index, 1);
    else state.selections.users.push(payload);
  },

  [types.ORG_USER_SELECT_CLEAR](state) {
    state.selections.users = [];
  },
};

const evalMembersSelectAll = (members: orgmap.TMemberList) => {
  const stateItems = members.items;
  members.selectedAll =
    stateItems.length > 0 &&
    stateItems.filter((x) => x.selected).length === stateItems.length;
};

const evalContainerSelectAll = (container: orgmap.TWishContainer) => {
  container.selectedAll =
    container.items.length > 0 &&
    container.items.filter((x) => x.selected).length === container.items.length;
};
